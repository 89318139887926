import React from 'react';
import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { useStore } from '../../hook-store/store';
import { getUserDataForStore } from '../../util/user';

import GetWindowData from '../../components/UI/getWindowData';
import AdElementDisplay from '../../components/GroupTalk/GroupAdElements/AdElememtDisplay/AdElementDisplay';
import VideoAdElementTime from '../../components/GroupTalk/GroupAdElements/AdElememtDisplay/VideoAdElementTime';
import PageDescription from './PageDescription';

import { authPageLink, authSignupPageLink, BASE_URL } from '../../App';
import './NotPageFound.css';


import TalkIcon from '../../images/icons/talkIcon-white-48.png';
import FeedIcon from '../../images/icons/feedIcon-white-48.png';
import GroupIcon from '../../images/icons/groupIcon-white-48.png';
import LoginIcon from '../../images/icons/loginIcon-white-48.png';
import SignupIcon from '../../images/icons/signupIcon-white-48.png';

import { marks } from '../../images/marks';

const NotPageFound = props => {
  // console.log('need-to-login-props', props);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const videoAdList = store.adStore.videoAdList;

  const [showPageInfo, setShowPageInfo] = useState(false);

  // console.log('store in NotPageFound.js', store);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title=`watakura: ${t('title.text01', 'Share Photos and Store Photos. Connect with People, Friends, Family by Talk & Group Talk')}`;
  }, []);

  useEffect(() => {
    if (props.isAuth) {
      getUserDataForStore(BASE_URL, localStorage.getItem('token'))
      .then(result => {
        console.log(result);
  
        dispatch('SET_USERDATA', result.userData);
      })
      .catch(err => {
        console.log(err);
      });
    }
  }, [props.isAuth]);

  console.log(props.history);
  console.log(document.referrer);
  // const containAuthPage = document.referrer.includes('http://localhost:8501');
  
  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;
  // console.log(currentUrl);

  if (!queryParams.get('tokenForCasTGT') && !queryParams.get('casTGT')) {
    // props.history.push('/feed/posts');
  }

  if (props.isAuth) {
    // props.history.push('/feed/posts');
  }

  let body;
  if (props.isLoading) {
    body = (<div className="notPageFound__Loader">
      <Loader />
      </div>);
  } 
  if (queryParams.get('tokenForCasTGT') && !props.isAuth) {
    body = (<div className="notPageFound__Loader">
      <Loader />
    </div>);
  }
  else {
    const pageInfoButton = (              
      <span 
        onClick={() => {
          setShowPageInfo(true);
        }}
      >
        {marks.infoCircle}
      </span>
    );

    body = ( 
      <div className="notPageFound__container">
        {props.isAuth ?
            <div>
              <div className="notPageFound__textLink">
                {/* Where do you go today? */}
                {t('notFound.text4')}
              </div>
              {/* <Link to="/feed/posts">
                go to feed page
                {t('notFound.text2')}
              </Link> */}
  
            <div className="notPageFound__pageButton">
              <Link to="/feed/posts" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  <span className="notPageFound__ButtonNameContainer">
                    <img className="notPageFound__Icon" src={FeedIcon} alt='icon'/> 
                    <span>
                      {t("feed.text44", "media post feed")}
                    </span>
                  </span>
                </Button>
              </Link>
  
              <span>{pageInfoButton}</span>
            </div>
  
            <div className="notPageFound__pageButton">
              <Link to="/talk-page" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  <span className="notPageFound__ButtonNameContainer">
                    <img className="notPageFound__Icon" src={TalkIcon} alt='icon'/>  
                    <span>
                      {t("general.text24", "Talk")}
                    </span>
                  </span>
                </Button>
              </Link>
  
              <span>{pageInfoButton}</span>
            </div>
  
            <div className="notPageFound__pageButton">
              <Link to="/group-talk-page" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  <span className="notPageFound__ButtonNameContainer">
                    <img className="notPageFound__Icon" src={GroupIcon} alt='icon'/> 
                    <span>
                      {t("general.text25", "Group Talk")}
                    </span>
                  </span>
                </Button>
              </Link>
  
              <span>{pageInfoButton}</span>
  
            </div>
  
            {/* <div className="notPageFound__pageButton">
                <a 
                  href={'https://wpsite.watakura.xyz'}
                  rel="noopener noreferrer" target="_blank"
                >
                <Button
                  mode="raised" type="submit" design="success"
                  // disabled={!props.replyInput || props.commentLoading}
                  >
                    <span className="notPageFound__ButtonNameContainer">
                      <span>
                        create your site or blog {marks.externalLink}
                      </span>
                    </span>
                  </Button>
                </a>
              </div> */}
  
            </div>
          :
            <div className="notPageFound">
              <div className="notPageFound__linkButtonContainer">
                <a className="notPageFound__linkButton" href={authPageLink} >
                  <Button
                        mode="raised" type="submit" design="success"
                        // disabled={!props.replyInput || props.commentLoading}
                  >
                    {/* Login */}
                    {/* {t('general.text11')} */}
  
                    <span className="notPageFound__ButtonNameContainer">
                    <img className="notPageFound__Icon" src={LoginIcon} alt='icon'/> 
                    <span>{t('general.text11', 'Login')}</span>
                  </span>
  
                  </Button>
                </a>
  
                <a className="notPageFound__linkButton" href={authSignupPageLink} >
                  <Button
                        mode="raised" type="submit" design="success"
                        // disabled={!props.replyInput || props.commentLoading}
                  >
                    {/* Signup */}
                    {/* {t('general.text12')} */}
  
                    <span className="notPageFound__ButtonNameContainer">
                    <img className="notPageFound__Icon" src={SignupIcon} alt='icon'/> 
                    <span>{t('general.text12', 'Signup')}</span>
                    </span>
                  </Button>
                </a>
              </div>
  
              <div className="notPageFound__pageButton">
                <Link to="/feed/posts" className="notPageFound__linkButton">
                  <Button
                        mode="raised" type="submit" design="success"
                        // disabled={!props.replyInput || props.commentLoading}
                  >
                    <span className="notPageFound__ButtonNameContainer">
                      <img className="notPageFound__Icon" src={FeedIcon} alt='icon'/> 
                      <span>
                        {t("feed.text44", "media post feed")}
                        </span>
                    </span>
                  </Button>
                </Link>
  
                <span>{pageInfoButton}</span>
              </div>
  
              <div className="notPageFound__pageButton">
                <Link to="/group-talk-page" className="notPageFound__linkButton">
                  <Button
                        mode="raised" type="submit" design="success"
                        // disabled={!props.replyInput || props.commentLoading}
                  >
                    <span className="notPageFound__ButtonNameContainer">
                      <img className="notPageFound__Icon" src={GroupIcon} alt='icon'/> 
                      <span>
                        {/* Group Page */}
                        {t("general.text25", "Group Talk")}
                      </span>
                    </span>
                  </Button>
                </Link>
  
                <span>{pageInfoButton}</span>
              </div>
  
              <div className="notPageFound__pageButton">
                <Link to="/talk-page" className="notPageFound__linkButton">
                  <Button
                        mode="raised" type="submit" design="success"
                        // disabled={!props.replyInput || props.commentLoading}
                  >
                    <span className="notPageFound__ButtonNameContainer">
                      <img className="notPageFound__Icon" src={TalkIcon} alt='icon'/>  
                      <span>
                        {/* Talk Page */}
                        {t("general.text24", "Talk")}
                      </span>
                    </span>
                  </Button>
                </Link>
  
                <span>{pageInfoButton}</span>
              </div>
              
              {/* <div className="notPageFound__pageButton">
                <a 
                  href={'https://wpsite.watakura.xyz'}
                  rel="noopener noreferrer" target="_blank"
                >
                <Button
                  mode="raised" type="submit" design="success"
                  // disabled={!props.replyInput || props.commentLoading}
                  >
                    <span className="notPageFound__ButtonNameContainer">
                      <span>
                        create your site or blog {marks.externalLink}
                      </span>
                    </span>
                  </Button>
                </a>
              </div> */}
          </div>
        }
      </div>
      );
  }

  return (
    <div>
      <GetWindowData 
        setWindowValues={() => {}}
      />

      {store.windowValues && (store.windowValues.width < 768) && (
        <AdElementDisplay
          adType='300x65' 
          adPlaceId='toppage-top' 
        />
      )}
      {store.windowValues && (store.windowValues.width >= 768) && (
        // <AdElementDisplay 
        //   adType='300x300'
        //   adPlaceId='toppage-right' 
        // />
        <VideoAdElementTime 
          // adType='video'
          adType='video300'
          adPlaceId='toppage-right' 
        />
      )}
      
      {/* {store.windowValues && (store.windowValues.width >= 768) && videoAdList.length === 0 && (
        <AdElementDisplay 
          adType='300x300'
          adPlaceId='toppage-right' 
        />
      )} */}

      {body}

      {showPageInfo && (
        <PageDescription />
      )}

    </div>
  );
}

export default NotPageFound;