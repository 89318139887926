export const forTranslation = [
  {
    "LANGUAGE": "English",
    "CODE": "en",
    "test.text1": "Hello",
    "test.text2": "Hi",
    "test.text3": "Thank you",
    "test.text4": "map",
    "test.text5": "",
    "": "",
    "general.text1": "Cancel",
    "general.text2": "Accept",
    "general.text3": "Delete",
    "general.text4": "Deleted",
    "general.text5": "Edit",
    "general.text6": "Update",
    "general.text7": "Upload Image",
    "general.text8": "Delete Image",
    "general.text9": "Image",
    "general.text10": "Feed",
    "general.text11": "Login",
    "general.text12": "Signup",
    "general.text13": "User Info",
    "general.text14": "Logout",
    "general.text15": "file should be",
    "general.text16": "and less than",
    "general.text17": "Password",
    "general.text18": "Favorite Posts",
    "general.text19": "Posts",
    "general.text20": "Images",
    "general.text21": "Videos",
    "general.text22": "",
    "general.text23": "",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Show User Posts",
    "feed.text3": "Show Posts",
    "feed.text4": "view",
    "feed.text5": "edit",
    "feed.text6": "delete",
    "feed.text7": "Is is no problem to delete post completely?",
    "feed.text8": "Posted by",
    "feed.text9": "Created by",
    "feed.text10": "Title",
    "feed.text11": "Image",
    "feed.text12": "Content",
    "feed.text13": "public",
    "feed.text14": "private",
    "feed.text15": "Title for post",
    "feed.text16": "Content por post",
    "feed.text17": "delete",
    "feed.text18": "Is it no problem to delete image completely?",
    "feed.text19": "Deleted",
    "feed.text20": "Media File should be jpg, jpeg, png or mp4 file, and less than 3MB",
    "feed.text21": "public post",
    "feed.text22": "private post",
    "feed.text23": "Type for Search ....",
    "feed.text24": "Search User ...",
    "__2": "",
    "userInfo.text1": "User Information",
    "userInfo.text2": "name",
    "userInfo.text3": "image",
    "userInfo.text4": "creation date",
    "userInfo.text5": "Is it no problem to delete image completely?",
    "userInfo.text6": "New Name ...",
    "userInfo.text7": "Image file should be jpg, jpeg, or png file, and less than 1MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "About User",
    "userInfo.text10": "additional information about you, description bio etc... (less than 300 characters)",
    "userInfo.text11": "Language",
    "userInfo.text12": "Change",
    "userInfo.text13": "Notification",
    "__3": "",
    "auth.text1": "Your E-mail",
    "auth.text2": "Your Name",
    "auth.text3": "Password",
    "auth.text4": "Password should be 8 to 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&).",
    "auth.text5": "Signup",
    "auth.text6": "Login",
    "auth.text7": "Forget Password",
    "auth.text8": "Signup Page",
    "auth.text9": "E-mail address for password reset",
    "auth.text10": "send E-mail",
    "auth.text11": "Validation failed. Make sure the email address isn't used yet!",
    "auth.text12": "User login failed!",
    "auth.text13": "Invalid Password was Entered",
    "auth.text14": "Entered email address was not found",
    "auth.text15": "User creation failed!",
    "auth.text16": "The email address is already in use by another account",
    "auth.text17": "Show Entered Password",
    "auth.text18": "Hide Password",
    "auth.text19": "email for password rest was sent",
    "auth.text20": "Enterd email was not found",
    "auth.text21": "Password reset success",
    "auth.text22": "Password reset failed, Please go to Login page and send email for password reset again",
    "auth.text23": "Password reset page",
    "auth.text24": "New Password",
    "auth.text25": "Reset Password",
    "auth.text26": "Page is invalid or Password is already changed",
    "auth.text27": "Sending email for password reset failed, Please check email address",
    "auth.text28": "Email verification page",
    "auth.text29": "Email verification success, please go to login page shown above",
    "auth.text30": "Email verification failed. This page is expired, or verification is already finished.",
    "auth.text31": "Verification Mail was sent. Plese check your email and verify. (also chack in spam mail)",
    "auth.text32": "Email Verification is required to Login. Please send Email for verification to your Email address.",
    "auth.text33": "Send Email for verification",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Login",
    "nav.text3": "Signup",
    "nav.text4": "User Info",
    "nav.text5": "Logout",
    "nav.text6": "",
    "__8": "",
    "notFound.text1": "Page Not Found",
    "notFound.text2": "go to feed page",
    "notFound.text3": "go to login page",
    "notFound.text4": "Where do you go today?",
    "notFound.text5": "",
    "__9": "",
    "comment.text1": "Cancel",
    "comment.text2": "Delete",
    "comment.text3": "Post Comment",
    "comment.text4": "Show Reply",
    "comment.text5": "total",
    "comment.text6": "Write Reply",
    "comment.text7": "Hide Reply",
    "comment.text8": "Comments",
    "comment.text9": "Is it no problem to delete this comment and replies of this comment completely?",
    "comment.text10": "Is it no problem to delete this comment completely?",
    "comment.text11": "",
    "__10": "",
    "follow.text1": "Add to Favorite Post",
    "follow.text2": "Delete from favorite post",
    "follow.text3": "Add to favorite user",
    "follow.text4": "Delete from favorite user",
    "follow.text5": "show posts",
    "follow.text6": "Your Favorite Users",
    "follow.text7": "Post Favorite Users",
    "__11": "",
    "privacyPolicy.text1": "Privacy Policy",
    "privacyPolicy.text2": "This policy may be updated or revised without notice. It is the responsibility of the user to stay informed about privacy policy changes. Take note of other privacy issues that may affect you:",
    "privacyPolicy.text3": "This website might be compromised.",
    "privacyPolicy.text4": "Your computer might be compromised.",
    "privacyPolicy.text5": "This website is hosted on Firebase and DigitalOcean infrastructures. They may and do have their own tracking systems on their servers. Those services have their own privacy policies and they are not covered by this privacy policy.",
    "privacyPolicy.text6": "This website stores email, encrypted form of password, and other inputs of users at hosted Firebase and DigitalOcean infrastructures.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Terms of use",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Your privacy is important to us. Please read the",
    "termsOfUse.text4": "privacy policy",
    "termsOfUse.text5": "for more information.",
    "termsOfUse.text6": "Your own responsibilities",
    "termsOfUse.text7": "You, the user, are solely responsible for ensuring your own compliance with laws and taxes in your jurisdiction. You are solely responsible for your own security.",
    "termsOfUse.text8": "Disclaimer of warranty",
    "termsOfUse.text9": "This site is provided on an \"AS IS\" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online Users",
    "videoTalk.text4": "Start to talk",
    "videoTalk.text5": "Your are trying to talk",
    "videoTalk.text6": "No other online user exists",
    "videoTalk.text7": "You are talking to",
    "videoTalk.text8": "You started to talk",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "text input ....",
    "videoTalk.text11": "You are still talking. Do you want to stop talking?",
    "videoTalk.text12": "Calling user disconnected",
    "videoTalk.text13": "user is calling you. Do you accept this call?",
    "videoTalk.text14": "reject your call",
    "videoTalk.text15": "search online user...",
    "videoTalk.text16": "search user...",
    "__14": "",
    "groupTalk.text1": "creator",
    "groupTalk.text2": "Description",
    "groupTalk.text3": "creation date",
    "groupTalk.text4": "Edit group info",
    "groupTalk.text5": "Is it no problem to delete you from members of this group?",
    "groupTalk.text6": "Leave from Member",
    "groupTalk.text7": "Join Group",
    "groupTalk.text8": "Show Group Members",
    "groupTalk.text9": "Do You Want to Join This Group?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Back to List",
    "groupTalk.text12": "Confirm your input",
    "groupTalk.text13": "Create Group",
    "groupTalk.text14": "Update Group",
    "groupTalk.text15": "Exit from Group",
    "groupTalk.text16": "Go to Homepage to Login",
    "groupTalk.text17": "group name (5-100 characters)",
    "groupTalk.text18": "group description (less than 500 characters)"
  },
  {
    "LANGUAGE": "Chinese",
    "CODE": "zh",
    "test.text1": "你好",
    "test.text2": "你好",
    "test.text3": "谢谢",
    "test.text4": "地图",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "取消",
    "general.text2": "接受",
    "general.text3": "删除",
    "general.text4": "已删除",
    "general.text5": "编辑",
    "general.text6": "更新",
    "general.text7": "上传图片",
    "general.text8": "删除图片",
    "general.text9": "图片",
    "general.text10": "饲料",
    "general.text11": "登录",
    "general.text12": "注册",
    "general.text13": "用户信息",
    "general.text14": "登出",
    "general.text15": "文件应该是",
    "general.text16": "和小于",
    "general.text17": "密码",
    "general.text18": "喜欢的帖子",
    "general.text19": "帖子",
    "general.text20": "图片",
    "general.text21": "影片",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "最新帖子",
    "feed.text2": "显示用户的帖子",
    "feed.text3": "显示帖子",
    "feed.text4": "视图",
    "feed.text5": "编辑",
    "feed.text6": "删除",
    "feed.text7": "是否是删除后完全没问题？",
    "feed.text8": "发布者",
    "feed.text9": "由...制作",
    "feed.text10": "标题",
    "feed.text11": "图片",
    "feed.text12": "内容",
    "feed.text13": "上市",
    "feed.text14": "私人的",
    "feed.text15": "标题后",
    "feed.text16": "内容POR后",
    "feed.text17": "删除",
    "feed.text18": "它是彻底删除图像没有问题？",
    "feed.text19": "已删除",
    "feed.text20": "",
    "feed.text21": "",
    "feed.text22": "",
    "feed.text23": "键入搜索....",
    "feed.text24": "搜索用户...",
    "__2": "",
    "userInfo.text1": "用户信息",
    "userInfo.text2": "名称",
    "userInfo.text3": "图片",
    "userInfo.text4": "创立日期",
    "userInfo.text5": "它是彻底删除图像没有问题？",
    "userInfo.text6": "新名字 ...",
    "userInfo.text7": "",
    "userInfo.text8": "黑暗模式",
    "userInfo.text9": "关于用户",
    "userInfo.text10": "关于你更多的信息，说明生物等...（少于300个字）",
    "userInfo.text11": "语言",
    "userInfo.text12": "改变",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "你的邮件",
    "auth.text2": "你的名字",
    "auth.text3": "密码",
    "auth.text4": "密码应为8到20个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符（@ $！％*？＆）。",
    "auth.text5": "注册",
    "auth.text6": "登录",
    "auth.text7": "忘记密码",
    "auth.text8": "注册页面",
    "auth.text9": "E-mail地址的密码重置",
    "auth.text10": "发送电子邮件",
    "auth.text11": "验证失败。确保电子邮件地址尚未使用！",
    "auth.text12": "用户登录失败！",
    "auth.text13": "无效密码被输入",
    "auth.text14": "输入的电子邮件地址未找到",
    "auth.text15": "用户创建失败！",
    "auth.text16": "该电子邮件地址已被其他帐户",
    "auth.text17": "显示输入的密码",
    "auth.text18": "隐藏密码",
    "auth.text19": "电子邮件密码其余被送往",
    "auth.text20": "照耀处电子邮件没有被发现",
    "auth.text21": "密码重置成功",
    "auth.text22": "密码重置失败，请重新进入登录页面和发送电子邮件的密码重置",
    "auth.text23": "密码重置页面",
    "auth.text24": "新密码",
    "auth.text25": "重设密码",
    "auth.text26": "页无效或密码已被更改",
    "auth.text27": "发送电子邮件的密码重置失败，请检查电子邮件地址",
    "auth.text28": "电子邮件验证页面",
    "auth.text29": "电子邮件验证成功，请进入上图所示的登录页面",
    "auth.text30": "电子邮件验证失败。此页面已过期，或者验证已经完成。",
    "auth.text31": "验证邮件已发送。普莱舍查收电子邮件和验证。 （在垃圾邮件也焯芬）",
    "auth.text32": "电子邮件验证才能登录。请验证电子邮件发送到您的邮箱地址。",
    "auth.text33": "发送电邮验证",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "饲料",
    "nav.text2": "登录",
    "nav.text3": "注册",
    "nav.text4": "用户信息",
    "nav.text5": "登出",
    "nav.text6": "#VALUE!",
    "__8": "",
    "notFound.text1": "找不到网页",
    "notFound.text2": "去饲料页",
    "notFound.text3": "进入登录页面",
    "notFound.text4": "当你今天去做？",
    "notFound.text5": "",
    "__9": "",
    "comment.text1": "取消",
    "comment.text2": "删除",
    "comment.text3": "发表评论",
    "comment.text4": "显示回复",
    "comment.text5": "总",
    "comment.text6": "写回复",
    "comment.text7": "隐藏回复",
    "comment.text8": "评论",
    "comment.text9": "是否删除此评论完全的这一评论和答复没有问题？",
    "comment.text10": "它是完全删除这条评论有没有问题？",
    "comment.text11": "",
    "__10": "",
    "follow.text1": "加入收藏帖子",
    "follow.text2": "从最喜欢的后删除",
    "follow.text3": "加入收藏用户",
    "follow.text4": "从最喜欢的用户删除",
    "follow.text5": "显示帖子",
    "follow.text6": "您收藏的用户",
    "follow.text7": "帖子收藏的用户",
    "__11": "",
    "privacyPolicy.text1": "隐私政策",
    "privacyPolicy.text2": "这一政策可能会更新或恕不另行通知修订。这是用户随时了解隐私政策变化的责任。请注意那些可能会影响你的其他隐私问题：",
    "privacyPolicy.text3": "这个网站可能会受到损害。",
    "privacyPolicy.text4": "您的计算机可能会受到影响。",
    "privacyPolicy.text5": "该网站托管在火力地堡和DigitalOcean基础设施。他们可能与你有自己的服务器上自己的跟踪系统。这些服务有自己的隐私政策，它们不属于本隐私政策。",
    "privacyPolicy.text6": "本网站存储电子邮件，密码的加密形式，在托管火力地堡和DigitalOcean基础设施用户的其他投入。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "使用条款",
    "termsOfUse.text2": "隐私",
    "termsOfUse.text3": "您的隐私对我们很重要。请阅读",
    "termsOfUse.text4": "隐私政策",
    "termsOfUse.text5": "了解更多信息。",
    "termsOfUse.text6": "你自己的责任",
    "termsOfUse.text7": "你的用户，是确保自己符合您所在司法辖区的法律和税收承担全部责任。你是你自己的安全负责。",
    "termsOfUse.text8": "保修免责声明",
    "termsOfUse.text9": "本网站提供的“原样”的基础，没有担保或任何形式的条件。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "开始连接",
    "videoTalk.text2": "停止连接",
    "videoTalk.text3": "在线用户",
    "videoTalk.text4": "开口",
    "videoTalk.text5": "您正在试图说服",
    "videoTalk.text6": "没有其他在线用户存在",
    "videoTalk.text7": "你是说，以",
    "videoTalk.text8": "你开始谈论",
    "videoTalk.text9": "显示文本对话",
    "videoTalk.text10": "文本输入....",
    "videoTalk.text11": "你还在说话。你想停止说话？",
    "videoTalk.text12": "主叫用户断开连接",
    "videoTalk.text13": "用户呼叫你。你接受这个电话？",
    "videoTalk.text14": "拒绝您的来电",
    "videoTalk.text15": "在网上搜索用户...",
    "videoTalk.text16": "搜索用户...",
    "__14": "",
    "groupTalk.text1": "创造者",
    "groupTalk.text2": "描述",
    "groupTalk.text3": "创建日期",
    "groupTalk.text4": "编辑组信息",
    "groupTalk.text5": "它是没有问题的，从这个组的成员中删除吗？",
    "groupTalk.text6": "从会员离开",
    "groupTalk.text7": "加入组",
    "groupTalk.text8": "展集团成员",
    "groupTalk.text9": "你想加入这个集团？",
    "groupTalk.text10": "组名",
    "groupTalk.text11": "返回目录",
    "groupTalk.text12": "确认您输入",
    "groupTalk.text13": "创建组",
    "groupTalk.text14": "更新组",
    "groupTalk.text15": "从集团退出",
    "groupTalk.text16": "转到主页到登录",
    "groupTalk.text17": "组名称（5-100个字符）",
    "groupTalk.text18": "组描述（少于500个字符）"
  },
  {
    "LANGUAGE": "Afrikaans",
    "CODE": "af",
    "test.text1": "hallo",
    "test.text2": "Hi",
    "test.text3": "Dankie",
    "test.text4": "karteer",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "kanselleer",
    "general.text2": "aanvaar",
    "general.text3": "Delete",
    "general.text4": "verwyderde",
    "general.text5": "wysig",
    "general.text6": "Opdateer",
    "general.text7": "oplaai Image",
    "general.text8": "Delete Image",
    "general.text9": "Image",
    "general.text10": "Voer",
    "general.text11": "Teken aan",
    "general.text12": "Teken aan",
    "general.text13": "Gebruikerinligting",
    "general.text14": "Teken uit",
    "general.text15": "lêer moet wees",
    "general.text16": "en minder as",
    "general.text17": "wagwoord",
    "general.text18": "gunsteling Posts",
    "general.text19": "poste",
    "general.text20": "beelde",
    "general.text21": "video's",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Wys User Posts",
    "feed.text3": "Wys plasings",
    "feed.text4": "beskou",
    "feed.text5": "wysig",
    "feed.text6": "verwyder",
    "feed.text7": "Is is nie 'n probleem om te verwyder post heeltemal?",
    "feed.text8": "Gepos deur",
    "feed.text9": "Gemaak deur",
    "feed.text10": "Titel",
    "feed.text11": "Image",
    "feed.text12": "inhoud",
    "feed.text13": "openbare",
    "feed.text14": "privaat",
    "feed.text15": "Titel vir post",
    "feed.text16": "Inhoud por post",
    "feed.text17": "verwyder",
    "feed.text18": "Is dit nie 'n probleem delete beeld heeltemal te?",
    "feed.text19": "verwyderde",
    "feed.text20": "Media lêer moet jpg, jpeg, png of mp4 lêer wees, en minder as 3 MB",
    "feed.text21": "openbare post",
    "feed.text22": "private boodskap",
    "feed.text23": "Tik vir Search ....",
    "feed.text24": "Soek Gebruikers ...",
    "__2": "",
    "userInfo.text1": "gebruikerinligting",
    "userInfo.text2": "naam",
    "userInfo.text3": "beeld",
    "userInfo.text4": "skeppingsdatum",
    "userInfo.text5": "Is dit nie 'n probleem delete beeld heeltemal te?",
    "userInfo.text6": "Nuwe Naam ...",
    "userInfo.text7": "Beeld-lêer moet jpg, jpeg, of PNG wees, en minder as 1 MB",
    "userInfo.text8": "donker af",
    "userInfo.text9": "oor User",
    "userInfo.text10": "meer inligting oor jou, beskrywing bio ens ... (minder as 300 karakters)",
    "userInfo.text11": "Taal",
    "userInfo.text12": "verandering",
    "userInfo.text13": "kennisgewing",
    "__3": "",
    "auth.text1": "Jou epos",
    "auth.text2": "Jou naam",
    "auth.text3": "wagwoord",
    "auth.text4": "Wagwoord moet wees 8 tot 20 karakters, ten minste een hoofletter, een kleinletter, een getal en een spesiale karakter (@ $!% *? &).",
    "auth.text5": "Teken aan",
    "auth.text6": "Teken aan",
    "auth.text7": "vergeet wagwoord",
    "auth.text8": "aanmelding bladsy",
    "auth.text9": "E-pos adres vir wagwoordterugstelling",
    "auth.text10": "stuur e-pos",
    "auth.text11": "Validering misluk. Maak seker dat die e-posadres is nog nie gebruik!",
    "auth.text12": "Gebruiker login misluk!",
    "auth.text13": "Ongeldig wagwoord ingevoer",
    "auth.text14": "Aangegaan e-pos adres is nie gevind nie",
    "auth.text15": "Gebruiker het misluk!",
    "auth.text16": "Die e-pos adres is reeds in gebruik deur 'n ander rekening",
    "auth.text17": "Wys Aangegaan wagwoord",
    "auth.text18": "versteek wagwoord",
    "auth.text19": "e-pos vir wagwoord res gestuur",
    "auth.text20": "Enterd e-pos is nie gevind nie",
    "auth.text21": "Wagwoord in te stel sukses",
    "auth.text22": "Wagwoord herstel misluk, asseblief na Teken bladsy en stuur e-pos vir wagwoord herstel weer",
    "auth.text23": "Wagwoord in te stel bladsy",
    "auth.text24": "Nuwe Wagwoord",
    "auth.text25": "Herstel wagwoord",
    "auth.text26": "Page is ongeldig of wagwoord is reeds verander",
    "auth.text27": "Stuur e-pos vir wagwoord herstel nie, sien jy check e-pos adres",
    "auth.text28": "E-pos verifiëring bladsy",
    "auth.text29": "E-pos verifiëring sukses, gaan asseblief na aanteken bladsy hierbo",
    "auth.text30": "E-pos het misluk. Hierdie bladsy is verstryk het, of verifikasie is reeds klaar.",
    "auth.text31": "Verifikasie Mail gestuur. Plese check jou e-pos en verifieer. (Ook chack in spam pos)",
    "auth.text32": "E-pos verifiëring vereis om aan te meld. Stuur asseblief e-pos vir verifikasie om jou e-pos adres.",
    "auth.text33": "Stuur e-pos vir verifikasie",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Voer",
    "nav.text2": "Teken aan",
    "nav.text3": "Teken aan",
    "nav.text4": "Gebruikerinligting",
    "nav.text5": "Teken uit",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Bladsy nie gevind nie",
    "notFound.text2": "gaan na voer bladsy",
    "notFound.text3": "gaan na aanteken bladsy",
    "notFound.text4": "Waar kry jy vandag gaan?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "kanselleer",
    "comment.text2": "Delete",
    "comment.text3": "pos kommentaar",
    "comment.text4": "Wys antwoord",
    "comment.text5": "totale",
    "comment.text6": "Skryf antwoord",
    "comment.text7": "verberg antwoord",
    "comment.text8": "kommentaar",
    "comment.text9": "Is dit nie 'n probleem om hierdie kommentaar en antwoorde van hierdie opmerking heeltemal wil verwyder?",
    "comment.text10": "Is dit nie 'n probleem om hierdie opmerking heeltemal wil verwyder?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Voeg by gunsteling Post",
    "follow.text2": "Verwyder uit gunsteling post",
    "follow.text3": "Voeg by gunsteling gebruiker",
    "follow.text4": "Verwyder uit gunsteling gebruiker",
    "follow.text5": "Wys plasings",
    "follow.text6": "Jou gunsteling Gebruikers",
    "follow.text7": "Post Gunsteling Gebruikers",
    "__11": "",
    "privacyPolicy.text1": "Privaatheidsbeleid",
    "privacyPolicy.text2": "Hierdie beleid kan verander of aangepas sonder kennisgewing. Dit is die verantwoordelikheid van die gebruiker om ingelig te bly oor veranderinge privaatheidsbeleid. Let op ander privaatheid kwessies wat jy kan beïnvloed:",
    "privacyPolicy.text3": "Hierdie webwerf is dalk blootgestel.",
    "privacyPolicy.text4": "Jou rekenaar is dalk blootgestel.",
    "privacyPolicy.text5": "Hierdie webwerf word bedryf op Firebase en DigitalOcean infrastruktuur. Hulle kan en wil hul eie dop stelsels op hul bedieners. Diegene dienste het hul eie privaatheid beleid en hulle is nie gedek deur hierdie privaatheidsbeleid.",
    "privacyPolicy.text6": "Hierdie webwerf winkels e-pos, geïnkripteer vorm van wagwoord, en ander insette van gebruikers op die gasheer Firebase en DigitalOcean infrastruktuur.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Terme van gebruik",
    "termsOfUse.text2": "privaatheid",
    "termsOfUse.text3": "Jou privaatheid is belangrik vir ons. Lees asseblief die",
    "termsOfUse.text4": "Privaatheidsbeleid",
    "termsOfUse.text5": "vir meer inligting.",
    "termsOfUse.text6": "Jou eie verantwoordelikhede",
    "termsOfUse.text7": "U, die gebruiker, is self verantwoordelik vir die versekering van jou eie nakoming van wette en belastings in jou jurisdiksie. U is self verantwoordelik vir jou eie veiligheid.",
    "termsOfUse.text8": "Vrywaring van waarborg",
    "termsOfUse.text9": "Hierdie webwerf word verskaf op 'n \"voetstootse\" basis aangebied, SONDER WAARBORGE OF DELIVERY van enige aard.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "begin Connect",
    "videoTalk.text2": "stop Connect",
    "videoTalk.text3": "Online Gebruikers",
    "videoTalk.text4": "Begin om te praat",
    "videoTalk.text5": "Jou probeer praat",
    "videoTalk.text6": "Geen ander aanlyn gebruiker bestaan",
    "videoTalk.text7": "Jy praat",
    "videoTalk.text8": "Jy begin om te praat",
    "videoTalk.text9": "Wys teks Talk",
    "videoTalk.text10": "teks invoer ....",
    "videoTalk.text11": "Jy is nog steeds praat. Wil jy uitgepraat raak?",
    "videoTalk.text12": "Roeping gebruiker ontkoppel",
    "videoTalk.text13": "gebruiker roep jou. Het jy hierdie oproep te aanvaar?",
    "videoTalk.text14": "jou oproep te verwerp",
    "videoTalk.text15": "soek aanlyn gebruikers ...",
    "videoTalk.text16": "soek gebruiker ...",
    "__14": "",
    "groupTalk.text1": "skepper",
    "groupTalk.text2": "beskrywing",
    "groupTalk.text3": "skeppingsdatum",
    "groupTalk.text4": "groep wysig info",
    "groupTalk.text5": "Is dit nie 'n probleem om jou te verwyder uit lede van hierdie groep?",
    "groupTalk.text6": "Verlof van lid",
    "groupTalk.text7": "Sluit groep",
    "groupTalk.text8": "Wys Groep Lede",
    "groupTalk.text9": "Wil jy Teken in op hierdie groep?",
    "groupTalk.text10": "groep Naam",
    "groupTalk.text11": "Terug na lys",
    "groupTalk.text12": "Bevestig jou insette",
    "groupTalk.text13": "Skep Groep",
    "groupTalk.text14": "werk Group",
    "groupTalk.text15": "Uitgang uit Groep",
    "groupTalk.text16": "Gaan na die tuisblad aan te meld",
    "groupTalk.text17": "groepnaam (5-100 karakters)",
    "groupTalk.text18": "groep beskrywing (minder as 500 karakters)"
  },
  {
    "LANGUAGE": "Arabic",
    "CODE": "ar",
    "test.text1": "مرحبا",
    "test.text2": "مرحبا",
    "test.text3": "شكرا جزيلا",
    "test.text4": "خريطة",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "إلغاء",
    "general.text2": "قبول",
    "general.text3": "حذف",
    "general.text4": "تم الحذف",
    "general.text5": "تعديل",
    "general.text6": "تحديث",
    "general.text7": "تحميل الصور",
    "general.text8": "حذف صورة",
    "general.text9": "صورة",
    "general.text10": "تغذية",
    "general.text11": "تسجيل الدخول",
    "general.text12": "سجل",
    "general.text13": "معلومات المستخدم",
    "general.text14": "تسجيل خروج",
    "general.text15": "يجب أن يكون ملف",
    "general.text16": "وأقل من",
    "general.text17": "كلمه السر",
    "general.text18": "المشاركات المفضلة",
    "general.text19": "المشاركات",
    "general.text20": "صور",
    "general.text21": "أشرطة فيديو",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "منشور جديد",
    "feed.text2": "عرض العضو المشاركات",
    "feed.text3": "عرض المشاركات",
    "feed.text4": "رأي",
    "feed.text5": "تعديل",
    "feed.text6": "حذف",
    "feed.text7": "هل لا توجد مشكلة إلى آخر الحذف تماما؟",
    "feed.text8": "منشور من طرف",
    "feed.text9": "انشأ من قبل",
    "feed.text10": "عنوان",
    "feed.text11": "صورة",
    "feed.text12": "المحتوى",
    "feed.text13": "عامة",
    "feed.text14": "نشر",
    "feed.text15": "عنوان آخر",
    "feed.text16": "المحتوى آخر البرتغال",
    "feed.text17": "حذف",
    "feed.text18": "غير انه لا توجد مشكلة لصورة حذف تماما؟",
    "feed.text19": "تم الحذف",
    "feed.text20": "وينبغي أن تكون وسائل الإعلام ملف JPG، JPEG، PNG أو ملف mp4، وأقل من 3MB",
    "feed.text21": "الوظيفة العامة",
    "feed.text22": "آخر خاصة",
    "feed.text23": "اكتب للبحث ....",
    "feed.text24": "بحث العضو ...",
    "__2": "",
    "userInfo.text1": "معلومات المستخدم",
    "userInfo.text2": "اسم",
    "userInfo.text3": "صورة",
    "userInfo.text4": "تاريخ الإنشاء",
    "userInfo.text5": "غير انه لا توجد مشكلة لصورة حذف تماما؟",
    "userInfo.text6": "اسم جديد ...",
    "userInfo.text7": "يجب أن يكون ملف صورة JPG، JPEG، أو ملف بابوا نيو غينيا، وأقل من 1MB",
    "userInfo.text8": "الوضع الظلام",
    "userInfo.text9": "وشك العضو",
    "userInfo.text10": "معلومات إضافية عنك، وصف الحيوي الخ ... (أقل من 300 حرفا)",
    "userInfo.text11": "لغة",
    "userInfo.text12": "يتغيرون",
    "userInfo.text13": "تنبيه",
    "__3": "",
    "auth.text1": "بريدك الالكتروني",
    "auth.text2": "اسمك",
    "auth.text3": "كلمه السر",
    "auth.text4": "يجب أن تكون كلمة السر من 8 إلى 20 حرفا، حرف واحد على الأقل الأحرف الكبيرة، رسالة صغيرة واحدة، رقم واحد وطابع خاص واحد (@ $!٪ *؟ و).",
    "auth.text5": "سجل",
    "auth.text6": "تسجيل الدخول",
    "auth.text7": "نسيت كلمة المرور",
    "auth.text8": "الاشتراك الصفحة",
    "auth.text9": "عنوان البريد الإلكتروني لإعادة تعيين كلمة المرور",
    "auth.text10": "ارسل بريد الكتروني",
    "auth.text11": "فشل التحقق من الصحة. تأكد من عدم استخدام عنوان البريد الإلكتروني بعد!",
    "auth.text12": "اسم المستخدم فشل!",
    "auth.text13": "تم إدخال كلمة المرور غير صالحة",
    "auth.text14": "لم يتم العثور على عنوان البريد الإلكتروني دخلت",
    "auth.text15": "إنشاء المستخدم فشل!",
    "auth.text16": "عنوان البريد الإلكتروني قيد الاستخدام من قبل حساب آخر",
    "auth.text17": "تظهر كلمة دخلت",
    "auth.text18": "اخفاء كلمة المرور",
    "auth.text19": "تم إرسال البريد الإلكتروني للراحة كلمة المرور",
    "auth.text20": "لم يتم العثور على Enterd البريد الإلكتروني",
    "auth.text21": "نجاح إعادة تعيين كلمة المرور",
    "auth.text22": "إعادة تعيين كلمة المرور فشلت، يرجى الانتقال إلى صفحة تسجيل الدخول وإرسال البريد الإلكتروني لإعادة تعيين كلمة المرور مرة أخرى",
    "auth.text23": "كلمة المرور صفحة إعادة تعيين",
    "auth.text24": "كلمة مرور جديدة",
    "auth.text25": "إعادة تعيين كلمة المرور",
    "auth.text26": "الصفحة غير صالحة أو يتم تغيير كلمة المرور بالفعل",
    "auth.text27": "إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور فشل، يرجى عنوان البريد الإلكتروني الاختيار",
    "auth.text28": "صفحة التحقق من البريد الإلكتروني",
    "auth.text29": "البريد الإلكتروني التحقق النجاح، يرجى الذهاب إلى صفحة تسجيل الدخول هو مبين أعلاه",
    "auth.text30": "فشل التحقق من البريد الإلكتروني. هذه الصفحة تم منتهية الصلاحية، أو يتم الانتهاء من التحقق بالفعل.",
    "auth.text31": "أرسل بريد التحقق. يمكن Plese تحقق من بريدك الالكتروني والتحقق. (chack أيضا في البريد غير المرغوب فيه)",
    "auth.text32": "مطلوب التحقق من البريد الإلكتروني لتسجيل الدخول. يرجى ارسال البريد الالكتروني للتحقق من عنوان البريد الإلكتروني الخاص بك.",
    "auth.text33": "إرسال البريد الإلكتروني للتحقق",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "تغذية",
    "nav.text2": "تسجيل الدخول",
    "nav.text3": "سجل",
    "nav.text4": "معلومات المستخدم",
    "nav.text5": "تسجيل خروج",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "الصفحة غير موجودة",
    "notFound.text2": "انتقل إلى صفحة آر إس",
    "notFound.text3": "انتقل إلى صفحة تسجيل الدخول",
    "notFound.text4": "أين تذهب اليوم؟",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "إلغاء",
    "comment.text2": "حذف",
    "comment.text3": "أضف تعليقا",
    "comment.text4": "مشاهدة رد",
    "comment.text5": "مجموع",
    "comment.text6": "كتابة رد",
    "comment.text7": "إخفاء الرد",
    "comment.text8": "تعليقات",
    "comment.text9": "غير انه لا توجد مشكلة حذف هذا التعليق والردود من هذا التعليق تماما؟",
    "comment.text10": "غير انه لا توجد مشكلة حذف هذا التعليق تماما؟",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "أضف إلى المفضلة مشاركة",
    "follow.text2": "حذف من آخر المفضلة",
    "follow.text3": "أضف إلى المفضلة المستخدم",
    "follow.text4": "حذف من المستخدم المفضلة",
    "follow.text5": "المشاركات المعرض",
    "follow.text6": "المستخدمين المفضلة لديك",
    "follow.text7": "المستخدمين آخر المفضلة",
    "__11": "",
    "privacyPolicy.text1": "سياسة خاصة",
    "privacyPolicy.text2": "قد يتم تحديث هذه السياسة أو تعديل دون إشعار. وتقع على عاتق المستخدم إلى البقاء على علم بالتغييرات سياسة الخصوصية. يحيط علما قضايا الخصوصية الأخرى التي قد تؤثر عليك:",
    "privacyPolicy.text3": "هذا الموقع يمكن المساس بها.",
    "privacyPolicy.text4": "قد يجوز المساس بها جهاز الكمبيوتر الخاص بك.",
    "privacyPolicy.text5": "يتم استضافتها على هذا الموقع Firebase وDigitalOcean البنى التحتية. ويجوز لهم ولا لديها أنظمة التتبع الخاصة بهم على أجهزتهم. هذه الخدمات لديها سياسات الخصوصية الخاصة بها وأنها لا تغطيها سياسة الخصوصية.",
    "privacyPolicy.text6": "هذا البريد الإلكتروني موقع مخازن، شكل مشفرة من كلمة المرور، وغيرها من المدخلات من المستخدمين في استضافة البنى التحتية Firebase وDigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "تعليمات الاستخدام",
    "termsOfUse.text2": "خصوصية",
    "termsOfUse.text3": "خصوصيتك مهمة بالنسبة لنا. يرجى قراءة",
    "termsOfUse.text4": "سياسة خاصة",
    "termsOfUse.text5": "للمزيد من المعلومات.",
    "termsOfUse.text6": "المسؤوليات الخاصة بك",
    "termsOfUse.text7": "لكم، للمستخدم، هي وحدها المسؤولة عن ضمان الامتثال الخاص بك مع القوانين والضرائب في ولايتكم. أنت وحدك المسؤول عن الأمن الخاص.",
    "termsOfUse.text8": "تنويه من الضمان",
    "termsOfUse.text9": "يتم توفير هذا الموقع على أساس \"كما هي\" دون ضمانات أو شروط من أي نوع.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "بدء الاتصال",
    "videoTalk.text2": "توقف الاتصال",
    "videoTalk.text3": "مستخدمين على الهواء",
    "videoTalk.text4": "البدء في التحدث",
    "videoTalk.text5": "بك تحاول الحديث",
    "videoTalk.text6": "لا يوجد مستخدم آخر على الانترنت",
    "videoTalk.text7": "كنت تتحدث ل",
    "videoTalk.text8": "هل بدأ الحديث",
    "videoTalk.text9": "حواري النص",
    "videoTalk.text10": "إدخال النص ....",
    "videoTalk.text11": "كنت لا تزال تتحدث. هل ترغب في التوقف عن الحديث؟",
    "videoTalk.text12": "داعيا المستخدم قطع",
    "videoTalk.text13": "المستخدم يتصل بك. هل تقبل هذه الدعوة؟",
    "videoTalk.text14": "رفض المكالمة",
    "videoTalk.text15": "بحث المستخدم على الانترنت ...",
    "videoTalk.text16": "بحث المستخدم ...",
    "__14": "",
    "groupTalk.text1": "المنشئ",
    "groupTalk.text2": "وصف",
    "groupTalk.text3": "تاريخ الإنشاء",
    "groupTalk.text4": "معلومات تحرير مجموعة",
    "groupTalk.text5": "غير انه لا توجد مشكلة لحذف لك من أعضاء هذه المجموعة؟",
    "groupTalk.text6": "التحرك من الأعضاء",
    "groupTalk.text7": "إنضم للمجموعة",
    "groupTalk.text8": "عرض أعضاء المجموعة",
    "groupTalk.text9": "هل تريد الانضمام إلى هذه المجموعة؟",
    "groupTalk.text10": "أسم المجموعة",
    "groupTalk.text11": "الرجوع للقائمة",
    "groupTalk.text12": "تأكيد الإدخال",
    "groupTalk.text13": "إنشاء مجموعة",
    "groupTalk.text14": "المجموعة تحديث",
    "groupTalk.text15": "الخروج من المجموعة",
    "groupTalk.text16": "الذهاب إلى الصفحة الرئيسية لدخول",
    "groupTalk.text17": "اسم المجموعة (5-100 حرفا)",
    "groupTalk.text18": "وصف المجموعة (أقل من 500 حرفا)"
  },
  {
    "LANGUAGE": "Azeri (Latin)",
    "CODE": "az",
    "test.text1": "Salam",
    "test.text2": "Hi",
    "test.text3": "Çox sağ ol",
    "test.text4": "map",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "ləğv etmək",
    "general.text2": "qəbul",
    "general.text3": "Sil",
    "general.text4": "silinmiş",
    "general.text5": "redaktə etmək",
    "general.text6": "Update",
    "general.text7": "yüklə Image",
    "general.text8": "Sil Image",
    "general.text9": "təsvir",
    "general.text10": "yem",
    "general.text11": "Daxil ol",
    "general.text12": "Abunə olun",
    "general.text13": "İstifadəçi Info",
    "general.text14": "Çıxış",
    "general.text15": "fayl olmalıdır",
    "general.text16": "daha və daha az",
    "general.text17": "parol",
    "general.text18": "sevimli Posts",
    "general.text19": "Posts",
    "general.text20": "Şəkillər",
    "general.text21": "Video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "İstifadəçi Posts göstər",
    "feed.text3": "Show Posts",
    "feed.text4": "baxış",
    "feed.text5": "redaktə etmək",
    "feed.text6": "silmək",
    "feed.text7": "tamamilə sil vəzifəsinə heç bir problem deyil?",
    "feed.text8": "Posted by",
    "feed.text9": "Yaradılıb",
    "feed.text10": "başlıq",
    "feed.text11": "təsvir",
    "feed.text12": "məzmun",
    "feed.text13": "ictimai",
    "feed.text14": "xüsusi",
    "feed.text15": "vəzifəsinə Title",
    "feed.text16": "Məzmun por post",
    "feed.text17": "silmək",
    "feed.text18": "tamamilə sil image heç bir problem varmı?",
    "feed.text19": "silinmiş",
    "feed.text20": "Media File jpg, jpeg, png və ya mp4 fayl ola bilər, və daha az 3MB çox olmalıdır",
    "feed.text21": "ictimai post",
    "feed.text22": "Şəxsi post",
    "feed.text23": "Axtar yazın ....",
    "feed.text24": "İstifadəçi axtar ...",
    "__2": "",
    "userInfo.text1": "İstifadəçi İnformasiya",
    "userInfo.text2": "ad",
    "userInfo.text3": "təsvir",
    "userInfo.text4": "Yaradılma tarixi",
    "userInfo.text5": "tamamilə sil image heç bir problem varmı?",
    "userInfo.text6": "New Adı ...",
    "userInfo.text7": "Image fayl jpg, jpeg, və ya png fayl ola bilər, və daha az 1MB lazımdır",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "haqqında İstifadəçi",
    "userInfo.text10": "s haqqında əlavə məlumat, təsviri bio ... (az 300 simvol)",
    "userInfo.text11": "dil",
    "userInfo.text12": "dəyişiklik",
    "userInfo.text13": "xəbərdarlıq",
    "__3": "",
    "auth.text1": "E-mail",
    "auth.text2": "Adınız",
    "auth.text3": "parol",
    "auth.text4": "Şifrə 8 20 simvol, ən azı bir böyük məktub, bir kiçik məktub bir sıra və bir xüsusi xarakter olmalıdır (@ $!% *? &).",
    "auth.text5": "Abunə olun",
    "auth.text6": "Daxil ol",
    "auth.text7": "Şifrənizi unutmusunuz",
    "auth.text8": "Qeydiyyat səhifə",
    "auth.text9": "parol sıfırlama üçün E-mail ünvanı",
    "auth.text10": "E-mail göndərin",
    "auth.text11": "Qiymətləndirmə bilmədi. Əmin olun e-mail hələ istifadə deyil!",
    "auth.text12": "İstifadəçi girişi uğursuz!",
    "auth.text13": "Dək təvəllüd idi",
    "auth.text14": "Daxil e-mail tapılmadı",
    "auth.text15": "İstifadəçi yaradılması uğursuz!",
    "auth.text16": "e-mail bir hesabı istifadə olunur",
    "auth.text17": "Daxil Şifrə göstər",
    "auth.text18": "hide Şifrə",
    "auth.text19": "parol istirahət üçün e-poçt göndərildi",
    "auth.text20": "Enterd email tapılmadı",
    "auth.text21": "Parol reset uğur",
    "auth.text22": "Parol reset bilmədi, yenidən parol sıfırlama üçün Giriş səhifə və göndərmək e-poçt getmək edin",
    "auth.text23": "Parol reset səhifə",
    "auth.text24": "yeni şifrə",
    "auth.text25": "Parolu sıfırlamak",
    "auth.text26": "Page etibarsız və ya Password artıq dəyişib",
    "auth.text27": "parol sıfırlama üçün e-poçt uğursuz göndərilməsi, xahiş edirik çek e-mail",
    "auth.text28": "Email yoxlama səhifə",
    "auth.text29": "uğur yoxlama Email, yuxarıda göstərilən giriş səhifə getmək edin",
    "auth.text30": "Email yoxlama uğursuz oldu. Bu səhifə bitmiş, və ya yoxlama artıq başa çatmışdır.",
    "auth.text31": "Verification Mail göndərildi. Plese e-poçt yoxlamaq və yoxlamaq. (Həmçinin spam mail chack)",
    "auth.text32": "Email Doğrulama Giriş tələb olunur. E-poçt ünvanı yoxlanılması üçün e-poçt göndərin.",
    "auth.text33": "yoxlanılması üçün E-poçt göndər",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "yem",
    "nav.text2": "Daxil ol",
    "nav.text3": "Abunə olun",
    "nav.text4": "İstifadəçi Info",
    "nav.text5": "Çıxış",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Səhifə tapılmadı",
    "notFound.text2": "feed səhifə getmək",
    "notFound.text3": "giriş səhifə getmək",
    "notFound.text4": "Harada bu gün getmək yoxdur?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "ləğv etmək",
    "comment.text2": "Sil",
    "comment.text3": "Post Comment",
    "comment.text4": "Show Cavab",
    "comment.text5": "ümumi",
    "comment.text6": "write Cavab",
    "comment.text7": "hide Cavab",
    "comment.text8": "Şərhlər",
    "comment.text9": "tamamilə bu comment bu şərh və cavab silmək üçün heç bir problem varmı?",
    "comment.text10": "tamamilə bu şərh silmək üçün heç bir problem varmı?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Sevimli Post əlavə et",
    "follow.text2": "Sevimli vəzifəsindən sil",
    "follow.text3": "Sevimli istifadəçi əlavə",
    "follow.text4": "Sevimli istifadəçi sil",
    "follow.text5": "show posts",
    "follow.text6": "Sizin Sevimli İstifadəçilər",
    "follow.text7": "Post Sevimli İstifadəçilər",
    "__11": "",
    "privacyPolicy.text1": "Gizlilik Siyasəti",
    "privacyPolicy.text2": "Bu siyasət yenilənir və ya xəbərdarlıq etmədən dəyişdirilə bilər. Bu gizlilik siyasəti dəyişikliklər barədə məlumat qalmaq üçün istifadəçi məsuliyyət daşıyır. siz təsir edə bilər digər məxfilik məsələləri qeyd edin:",
    "privacyPolicy.text3": "Bu veb güzəşt edilə bilər.",
    "privacyPolicy.text4": "Sizin kompüter güzəşt edilə bilər.",
    "privacyPolicy.text5": "Bu veb Firebase və DigitalOcean infrastruktur ev sahibliyi edir. Onlar bilər və onların server öz izleme sistemi var. Həmin xidmətlər öz gizlilik siyasəti var və onlar bu məxfilik siyasəti ilə əhatə olunmur.",
    "privacyPolicy.text6": "Bu veb Mağazalar e-poçt, parol şifreli forma və ev sahibliyi Firebase və DigitalOcean infrastruktur istifadəçilər digər giriş.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "İstifadə qaydaları",
    "termsOfUse.text2": "Məxfilik",
    "termsOfUse.text3": "Sizin privacy bizim üçün vacibdir. oxuyun",
    "termsOfUse.text4": "Gizlilik Siyasəti",
    "termsOfUse.text5": "Daha ətraflı məlumat üçün.",
    "termsOfUse.text6": "Öz vəzifələri",
    "termsOfUse.text7": "Siz, istifadəçi, yurisdiksiyasında qanun və vergilər ilə öz riayət təmin etmək üçün cavabdehdir. Siz öz təhlükəsizlik üçün cavabdehdir.",
    "termsOfUse.text8": "zəmanət Disclaimer",
    "termsOfUse.text9": "Bu site bir təmin olunur ZƏMANƏT VƏ YA HƏR HANSI ŞƏRTLƏR OLMADAN ƏSASLARI \"olduğu kimi\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online İstifadəçilər",
    "videoTalk.text4": "dinmək",
    "videoTalk.text5": "Sizin müzakirəsi üçün çalışırıq",
    "videoTalk.text6": "No digər online user mövcuddur",
    "videoTalk.text7": "Siz gedir",
    "videoTalk.text8": "Siz danışmaq başladı",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "mətn daxil ....",
    "videoTalk.text11": "Siz hələ gedir. Siz söhbət dayandırmaq istəyirsiniz?",
    "videoTalk.text12": "Zəng istifadəçi qaralar",
    "videoTalk.text13": "istifadəçi zəng edir. Bu zəng qəbul edirsiniz?",
    "videoTalk.text14": "zəng rədd",
    "videoTalk.text15": "online istifadəçi axtarış ...",
    "videoTalk.text16": "istifadəçi axtarış ...",
    "__14": "",
    "groupTalk.text1": "yaradan",
    "groupTalk.text2": "təsvir",
    "groupTalk.text3": "Yaradılma tarixi",
    "groupTalk.text4": "Edit qrup info",
    "groupTalk.text5": "bu qrupun üzvləri sizi silmək üçün heç bir problem varmı?",
    "groupTalk.text6": "Üzvü tərk",
    "groupTalk.text7": "Qrup Qeydiyyat",
    "groupTalk.text8": "Show Group İstifadəçi",
    "groupTalk.text9": "Bu qrupa iştirak etmək üçün istəyirsiniz?",
    "groupTalk.text10": "Qrup adı",
    "groupTalk.text11": "Siyahısı Back",
    "groupTalk.text12": "giriş Təsdiq",
    "groupTalk.text13": "Qrup yarat",
    "groupTalk.text14": "Update Group",
    "groupTalk.text15": "Group Exit",
    "groupTalk.text16": "Giriş üçün Əsas gedin",
    "groupTalk.text17": "qrup adı (5-100 simvol)",
    "groupTalk.text18": "qrup təsviri (az 500 simvol)"
  },
  {
    "LANGUAGE": "Belarusian",
    "CODE": "be",
    "test.text1": "добры дзень",
    "test.text2": "прывітанне",
    "test.text3": "Дзякуй",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "адмяніць",
    "general.text2": "прымаць",
    "general.text3": "выдаліць",
    "general.text4": "выключаны",
    "general.text5": "рэдагаваць",
    "general.text6": "абнаўленне",
    "general.text7": "загрузіць малюнак",
    "general.text8": "выдаліць выява",
    "general.text9": "малюнак",
    "general.text10": "падача",
    "general.text11": "Увайсці",
    "general.text12": "Зарэгістравацца",
    "general.text13": "Інфармацыя пра карыстальніка",
    "general.text14": "выхад",
    "general.text15": "Файл павінен быць",
    "general.text16": "і менш",
    "general.text17": "пароль",
    "general.text18": "выбраныя паведамленні",
    "general.text19": "паведамленняў",
    "general.text20": "малюнкаў",
    "general.text21": "відэа",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "новае паведамленне",
    "feed.text2": "Паказаць паведамленні карыстальніка",
    "feed.text3": "паказаць паведамленні",
    "feed.text4": "выгляд",
    "feed.text5": "рэдагаваць",
    "feed.text6": "выдаляць",
    "feed.text7": "Хіба гэта не праблема, каб выдаліць паведамленне цалкам?",
    "feed.text8": "аўтар",
    "feed.text9": "створана",
    "feed.text10": "тытульны",
    "feed.text11": "малюнак",
    "feed.text12": "змест",
    "feed.text13": "грамадскасці",
    "feed.text14": "прыватны",
    "feed.text15": "загаловак паста",
    "feed.text16": "Змест Por паведамленне",
    "feed.text17": "выдаляць",
    "feed.text18": "Хіба гэта не праблема выдалення малюнка цалкам?",
    "feed.text19": "выключаны",
    "feed.text20": "Media File павінен быць JPG, JPEG, PNG або файл mp4, і менш, чым 3MB",
    "feed.text21": "грамадскае паведамленне",
    "feed.text22": "прыватнае паведамленне",
    "feed.text23": "Ўвядзіце для пошуку ....",
    "feed.text24": "Пошук карыстальніка ...",
    "__2": "",
    "userInfo.text1": "інфармацыя пра карыстальніка",
    "userInfo.text2": "імя",
    "userInfo.text3": "малюнак",
    "userInfo.text4": "дата стварэння",
    "userInfo.text5": "Хіба гэта не праблема выдалення малюнка цалкам?",
    "userInfo.text6": "Новае імя ...",
    "userInfo.text7": "Файл малюнка павінен быць JPG, JPEG ці PNG-файл, і менш за 1 МБ",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "Аб карыстальніку",
    "userInfo.text10": "Дадатковая інфармацыя аб Вас, апісанне бія і г.д ... (менш за 300 сімвалаў)",
    "userInfo.text11": "мова",
    "userInfo.text12": "змена",
    "userInfo.text13": "апавяшчэнне",
    "__3": "",
    "auth.text1": "Ваша электронная пошта",
    "auth.text2": "Ваша імя",
    "auth.text3": "пароль",
    "auth.text4": "Пароль павінен быць ад 8 да 20 знакаў, па меншай меры, адзін верхні рэгістр літар, адна маленькая літара, адзін нумар і адзін спецыяльны сімвал (@ $!% *? &).",
    "auth.text5": "Зарэгістравацца",
    "auth.text6": "Увайсці",
    "auth.text7": "забыцца пароль",
    "auth.text8": "рэгістрацыя Page",
    "auth.text9": "Адрас электроннай пошты для скіду пароля",
    "auth.text10": "адправіць электронную пошту",
    "auth.text11": "Праверка не ўдалася. Пераканайцеся, што адрас электроннай пошты яшчэ не выкарыстоўваецца!",
    "auth.text12": "Лагін карыстача не атрымалася!",
    "auth.text13": "Няправільны пароль быў уведзены",
    "auth.text14": "Уведзены адрас электроннай пошты не знойдзены",
    "auth.text15": "Стварэнне карыстальніка не атрымалася!",
    "auth.text16": "Адрас электроннай пошты ўжо выкарыстоўваецца іншы ўліковага запісу",
    "auth.text17": "Паказаць уведзены пароль",
    "auth.text18": "схаваць Пароль",
    "auth.text19": "электронная пошта для пароля адпачынку была адпраўлена",
    "auth.text20": "Enterd электроннай пошты не знойдзены",
    "auth.text21": "Поспех Аднаўленне пароля",
    "auth.text22": "Скід пароля няўдалы, калі ласка, перайдзіце на старонку ўваходу ў сістэму і адправіць па электроннай пошце для скіду пароля яшчэ раз",
    "auth.text23": "старонка Аднаўленне пароля",
    "auth.text24": "Новы пароль",
    "auth.text25": "Скід пароля",
    "auth.text26": "Старонка несапраўдная або пароль ўжо зменены",
    "auth.text27": "Адпраўка электроннай пошты для скіду пароля не прайшло, праверце адрас электроннай пошты",
    "auth.text28": "старонка праверкі электроннай пошты",
    "auth.text29": "Email пацверджання поспеху, калі ласка, перайдзіце на старонку ўваходу, паказаную вышэй",
    "auth.text30": "Email праверкі не ўдалося. Гэтая старонка мінуў, або праверка ўжо скончаная.",
    "auth.text31": "Пошта Праверка была адпраўлена. Plese праверыць сваю электронную пошту і праверыць. (Таксама Chack ў спам)",
    "auth.text32": "Пацверджаны адрас электроннай пошты неабходна ўвайсці ў сістэму. Калі ласка, дасылайце на e-mail для праверкі на ваш адрас электроннай пошты.",
    "auth.text33": "Адправіць Email для праверкі",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "падача",
    "nav.text2": "Увайсці",
    "nav.text3": "Зарэгістравацца",
    "nav.text4": "Інфармацыя пра карыстальніка",
    "nav.text5": "выхад",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Старонка не знойдзена",
    "notFound.text2": "перайсці на старонку падачы",
    "notFound.text3": "перайсці на старонку ўваходу",
    "notFound.text4": "Дзе вы сёння?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "адмяніць",
    "comment.text2": "выдаліць",
    "comment.text3": "паведамленне Каментарыі",
    "comment.text4": "паказаць адказ",
    "comment.text5": "агульны",
    "comment.text6": "напісаць адказ",
    "comment.text7": "схаваць Адказаць",
    "comment.text8": "каментары",
    "comment.text9": "Хіба гэта не праблема, каб выдаліць гэты каментар і адказы на гэты каментар цалкам?",
    "comment.text10": "Хіба гэта не праблема, каб цалкам выдаліць гэты каментар?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Дадаць у абранае паведамленне",
    "follow.text2": "Выдаліць з каханага паста",
    "follow.text3": "Дадаць у абранае карыстальнік",
    "follow.text4": "Выдаліць з каханага карыстальніка",
    "follow.text5": "паказаць паведамлення",
    "follow.text6": "Вашы любімыя Карыстальнікі",
    "follow.text7": "Паведамленне Любыя Карыстальнікі",
    "__11": "",
    "privacyPolicy.text1": "Палітыка прыватнасці",
    "privacyPolicy.text2": "Гэтая палітыка можа быць абноўлена або зменена без папярэдняга паведамлення. Гэта адказнасць карыстальніка Застацца інфармаваным пра змены палітыкі прыватнасці. Звярніце ўвагу на іншыя пытанні прыватнасці, якія могуць паўплываць на вас:",
    "privacyPolicy.text3": "Гэты вэб-сайт можа быць пастаўлена пад пагрозу.",
    "privacyPolicy.text4": "Ваш кампутар можа быць пастаўлена пад пагрозу.",
    "privacyPolicy.text5": "Гэты вэб-сайт размешчаны на Firebase і DigitalOcean інфраструктуры. Яны могуць і не мець свае ўласныя сістэмы сачэння на сваіх серверах. Гэтыя паслугі маюць сваю ўласную палітыку прыватнасці, і яны не ахоплены гэтай палітыкай прыватнасці.",
    "privacyPolicy.text6": "Гэты сайт захоўвае электронную пошту, зашыфраваным выглядзе пароля, а таксама іншыя ўваходы карыстальнікаў на размешчаныя Firebase і DigitalOcean інфраструктуры.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Умовы выкарыстання",
    "termsOfUse.text2": "сакрэтнасць",
    "termsOfUse.text3": "Ваша прыватнасць вельмі важная для нас. Калі ласка, прачытайце",
    "termsOfUse.text4": "Палітыка прыватнасці",
    "termsOfUse.text5": "для атрымання дадатковай інфармацыі.",
    "termsOfUse.text6": "Вашы ўласныя абавязкі",
    "termsOfUse.text7": "Вы, карыстальнік, несяце поўную адказнасць за забеспячэнне ўласнага выканання законаў і падаткамі ў вашай юрысдыкцыі. Вы несяце поўную адказнасць за сваю ўласную бяспеку.",
    "termsOfUse.text8": "Адмова ад гарантыі",
    "termsOfUse.text9": "Гэты сайт прадастаўляецца па прынцыпе «як ёсць», БЕЗ ГАРАНТЫЙ АБО УМОЎ ЯКІХ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "стоп Connect",
    "videoTalk.text3": "Карыстальнікі на сайце",
    "videoTalk.text4": "пачынаюць казаць",
    "videoTalk.text5": "Вы спрабуеце казаць",
    "videoTalk.text6": "Ні адзін іншы онлайн карыстальнік не існуе",
    "videoTalk.text7": "вы размаўляеце",
    "videoTalk.text8": "Вы пачалі гаварыць",
    "videoTalk.text9": "Паказаць тэкст Размовы",
    "videoTalk.text10": "увод тэксту ....",
    "videoTalk.text11": "Вы ўсё яшчэ кажаце. Вы хочаце, каб спыніць казаць?",
    "videoTalk.text12": "Выклік карыстальніка адключаны",
    "videoTalk.text13": "карыстальнік тэлефануе вам. Можа, вы думаеце гэты выклік?",
    "videoTalk.text14": "адхіліць выклік",
    "videoTalk.text15": "Пошук онлайн-карыстальнікаў ...",
    "videoTalk.text16": "Пошук карыстальнікаў ...",
    "__14": "",
    "groupTalk.text1": "стваральнік",
    "groupTalk.text2": "апісанне",
    "groupTalk.text3": "дата стварэння",
    "groupTalk.text4": "Рэдагаванне інфармацыі пра гурт",
    "groupTalk.text5": "Хіба гэта не праблема, каб выдаліць вас з членаў гэтай групы?",
    "groupTalk.text6": "Выходзім з члена",
    "groupTalk.text7": "Далучыцца да групы",
    "groupTalk.text8": "Паказаць Члены групы",
    "groupTalk.text9": "Ці вы хочаце далучыцца да гэтай групы?",
    "groupTalk.text10": "Назва групы",
    "groupTalk.text11": "Назад да спісу",
    "groupTalk.text12": "пацвердзіце ўвод",
    "groupTalk.text13": "стварыць групу",
    "groupTalk.text14": "група Update",
    "groupTalk.text15": "Выхад з групы",
    "groupTalk.text16": "Перайсці на галоўную старонку да Лагін",
    "groupTalk.text17": "Імя групы (5-100 знакаў)",
    "groupTalk.text18": "Апісанне групы (менш за 500 сімвалаў)"
  },
  {
    "LANGUAGE": "Bulgarian",
    "CODE": "bg",
    "test.text1": "Здравейте",
    "test.text2": "Здрасти",
    "test.text3": "Благодаря ти",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Отказ",
    "general.text2": "приемам",
    "general.text3": "Изтрий",
    "general.text4": "изтрити",
    "general.text5": "редактиране",
    "general.text6": "Актуализация",
    "general.text7": "Качи изображение",
    "general.text8": "Изтриване на изображението",
    "general.text9": "Изображение",
    "general.text10": "фураж",
    "general.text11": "Влизам",
    "general.text12": "Регистрирай се",
    "general.text13": "Информация за потребителя",
    "general.text14": "Излез от профила си",
    "general.text15": "файл трябва да бъде",
    "general.text16": "и по-малко от",
    "general.text17": "парола",
    "general.text18": "Предпочитани Публикации",
    "general.text19": "Публикации",
    "general.text20": "Снимки",
    "general.text21": "Видео",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Нова публикация",
    "feed.text2": "Показване на потребителя Публикации",
    "feed.text3": "В публикации",
    "feed.text4": "изглед",
    "feed.text5": "редактиране",
    "feed.text6": "Изтрий",
    "feed.text7": "Дали това не е проблем за изтриване на пост напълно?",
    "feed.text8": "Публикувано от",
    "feed.text9": "Създадено от",
    "feed.text10": "Заглавие",
    "feed.text11": "Изображение",
    "feed.text12": "съдържание",
    "feed.text13": "обществен",
    "feed.text14": "частен",
    "feed.text15": "Заглавие на мнение",
    "feed.text16": "Съдържание POR пост",
    "feed.text17": "Изтрий",
    "feed.text18": "Дали това не е проблем, за да изтриете изображение напълно?",
    "feed.text19": "изтрити",
    "feed.text20": "Media File трябва да бъде във формат JPG, JPEG, PNG или mp4 файл, и по-малко от 3 МБ",
    "feed.text21": "общественото мнение",
    "feed.text22": "лично мнение",
    "feed.text23": "Въведете за търсене ....",
    "feed.text24": "Търсене на потребителя ...",
    "__2": "",
    "userInfo.text1": "Информация за потребителя",
    "userInfo.text2": "име",
    "userInfo.text3": "изображение",
    "userInfo.text4": "дата на създаване",
    "userInfo.text5": "Дали това не е проблем, за да изтриете изображение напълно?",
    "userInfo.text6": "Името на новия ...",
    "userInfo.text7": "Графичният файл трябва да бъде във формат JPG, JPEG, PNG или файл, и по-малко от 1MB",
    "userInfo.text8": "Тъмно Mode",
    "userInfo.text9": "За потребителя",
    "userInfo.text10": "допълнителна информация за вас, описание био и т.н. ... (по-малко от 300 знака)",
    "userInfo.text11": "език",
    "userInfo.text12": "промяна",
    "userInfo.text13": "уведомление",
    "__3": "",
    "auth.text1": "Твоят имейл",
    "auth.text2": "Твоето име",
    "auth.text3": "парола",
    "auth.text4": "Паролата трябва да съдържа от 8 до 20 символа, най-малко една главна буква, една малка буква, един брой и един специален знак (@ $!% *? &).",
    "auth.text5": "Регистрирай се",
    "auth.text6": "Влизам",
    "auth.text7": "Забравена парола",
    "auth.text8": "Регистрация Page",
    "auth.text9": "Е-мейл адрес за нулиране на паролата",
    "auth.text10": "Изпратете имейл",
    "auth.text11": "Неуспешна проверка. Уверете се, че имейл адресът не се използва все още!",
    "auth.text12": "Потребителски вход неуспешно!",
    "auth.text13": "Невалидна парола е въведен",
    "auth.text14": "Въведеният имейл адрес не е намерен",
    "auth.text15": "създаване Потребителят не е!",
    "auth.text16": "Имейл адресът вече се използва от друг профил",
    "auth.text17": "Покажете въведената парола",
    "auth.text18": "Скрий парола",
    "auth.text19": "имейл за парола почивка е изпратен",
    "auth.text20": "Enterd имейл не е намерена",
    "auth.text21": "успех Промяната на паролата",
    "auth.text22": "Промяната на паролата се провали, Моля, посетете Вход страница и изпращане на имейл за нулиране на паролата отново",
    "auth.text23": "страница задаване на нова парола",
    "auth.text24": "нова парола",
    "auth.text25": "Възстановяване на паролата",
    "auth.text26": "Page е невалидна или парола вече е променен",
    "auth.text27": "Изпращане на електронна поща за нулиране на паролата не успя, моля проверете имейл адреса си",
    "auth.text28": "Страницата за потвърждаване Email",
    "auth.text29": "Имейл проверка успех, моля, посетете страницата за вход, показана по-горе",
    "auth.text30": "проверка Email провали. Тази страница е изтекъл, или проверка вече е завършена.",
    "auth.text31": "Писмо за потвърждение беше изпратен. Plese проверявате електронната си поща и да провери. (Също Chack в спам)",
    "auth.text32": "Потвърждаване на имейл адрес се изисква да Вход. Моля, изпратете имейл за потвърждение до вашия имейл адрес.",
    "auth.text33": "Изпрати е-мейл за потвърждение",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "фураж",
    "nav.text2": "Влизам",
    "nav.text3": "Регистрирай се",
    "nav.text4": "Информация за потребителя",
    "nav.text5": "Излез от профила си",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Страницата не е намерена",
    "notFound.text2": "отидете на страницата на фуражите",
    "notFound.text3": "отидете на страницата за вход",
    "notFound.text4": "Къде ходиш днес?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Отказ",
    "comment.text2": "Изтрий",
    "comment.text3": "Публикувай коментар",
    "comment.text4": "Покажи отговор",
    "comment.text5": "обща сума",
    "comment.text6": "Напиши коментар",
    "comment.text7": "Скрий отговор",
    "comment.text8": "Коментари",
    "comment.text9": "Дали това не е проблем да изтриете този коментар и отговорите на този коментар напълно?",
    "comment.text10": "Дали това не е проблем да изтриете този коментар напълно?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Добави към Любими Мнение",
    "follow.text2": "Изтрий от любимата пост",
    "follow.text3": "Добави към любимите на потребителя",
    "follow.text4": "Изтрий от любимите на потребителя",
    "follow.text5": "покажи мнения",
    "follow.text6": "Любимите си потребители",
    "follow.text7": "Мнение любими потребители",
    "__11": "",
    "privacyPolicy.text1": "Декларация за поверителност",
    "privacyPolicy.text2": "Тази политика може да се актуализира или преработен без предизвестие. Това е отговорност на потребителя е да бъдете информирани за промени в политиката за поверителност. Обърнете внимание на други въпроси, неприкосновеността на личния живот, които могат да са ви впечатлили:",
    "privacyPolicy.text3": "Този сайт може да се компрометира.",
    "privacyPolicy.text4": "Компютърът ви може да бъде компрометирана.",
    "privacyPolicy.text5": "Този сайт се хоства на Firebase и DigitalOcean инфраструктури. Те могат и да имат свои собствени системи за проследяване на техните сървъри. Тези услуги имат свои собствени правила за поверителност, и те не са обхванати от тази декларация за поверителност.",
    "privacyPolicy.text6": "Този уебсайт магазини имейл, шифрован вид на парола, както и други входове на потребители в хоствани Firebase и DigitalOcean инфраструктури.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Условия за ползване",
    "termsOfUse.text2": "поверителност",
    "termsOfUse.text3": "Вашият личен живот е важно за нас. Моля, прочетете",
    "termsOfUse.text4": "декларация за поверителност",
    "termsOfUse.text5": "за повече информация.",
    "termsOfUse.text6": "Вашите собствени отговорности",
    "termsOfUse.text7": "Вие, потребителят, носите отговорност за гарантиране на собствения си спазване на законите и данъците във вашата юрисдикция. Вие носите отговорност за собствената си сигурност.",
    "termsOfUse.text8": "Отказ от гаранция",
    "termsOfUse.text9": "Този сайт се предоставя на база \"КАКТО СА\", БЕЗ ГАРАНЦИИ ИЛИ УСЛОВИЯ ОТ КАКЪВТО И ДА Е ВИД.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Старт Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Онлайн потребители",
    "videoTalk.text4": "Започнете да говорите",
    "videoTalk.text5": "Вие се опитвате да говори",
    "videoTalk.text6": "Никоя друга онлайн съществува потребител",
    "videoTalk.text7": "Говорите",
    "videoTalk.text8": "Вие започнахте да говорите",
    "videoTalk.text9": "Показване на текста Talk",
    "videoTalk.text10": "въвеждане на текст ....",
    "videoTalk.text11": "Вие все още говорим. Искате ли да спрете да говорите?",
    "videoTalk.text12": "Повикване на потребителя изключен",
    "videoTalk.text13": "потребителското ви се обажда. Приемате ли този призив?",
    "videoTalk.text14": "отхвърли вашето обаждане",
    "videoTalk.text15": "търсене онлайн потребител ...",
    "videoTalk.text16": "Търсене потребител ...",
    "__14": "",
    "groupTalk.text1": "създател",
    "groupTalk.text2": "описание",
    "groupTalk.text3": "дата на създаване",
    "groupTalk.text4": "Редактиране на информацията за група",
    "groupTalk.text5": "Дали това не е проблем да изтриете от членове на тази група?",
    "groupTalk.text6": "Тръгване от държавите-",
    "groupTalk.text7": "Влезе в групата",
    "groupTalk.text8": "Показване на членовете на групата",
    "groupTalk.text9": "Искате ли да се присъедините към тази група?",
    "groupTalk.text10": "Име на групата",
    "groupTalk.text11": "Обратно към списъка",
    "groupTalk.text12": "Потвърдете вашия вход",
    "groupTalk.text13": "Създай група",
    "groupTalk.text14": "Актуализиране на групата",
    "groupTalk.text15": "Изход от група",
    "groupTalk.text16": "Отиди на страница за Вход",
    "groupTalk.text17": "име на групата (5-100 знака)",
    "groupTalk.text18": "описание група (по-малко от 500 знака)"
  },
  {
    "LANGUAGE": "Catalan",
    "CODE": "ca",
    "test.text1": "Hola",
    "test.text2": "Hola",
    "test.text3": "Gràcies",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Cancel · lar",
    "general.text2": "acceptar",
    "general.text3": "eliminar",
    "general.text4": "suprimit",
    "general.text5": "editar",
    "general.text6": "actualització",
    "general.text7": "pujant imatge",
    "general.text8": "esborrar imatge",
    "general.text9": "imatge",
    "general.text10": "penso",
    "general.text11": "iniciar Sessió",
    "general.text12": "Registra't",
    "general.text13": "Informació de l'usuari",
    "general.text14": "Tancar sessió",
    "general.text15": "arxiu ha d'estar",
    "general.text16": "i menys de",
    "general.text17": "contrasenya",
    "general.text18": "missatges favorits",
    "general.text19": "missatges",
    "general.text20": "imatges",
    "general.text21": "vídeos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "nou post",
    "feed.text2": "Mostra missatges dels usuaris",
    "feed.text3": "Missatges",
    "feed.text4": "vista",
    "feed.text5": "editar",
    "feed.text6": "esborrar",
    "feed.text7": "És no és un problema per després d'eliminar per complet?",
    "feed.text8": "publicat per",
    "feed.text9": "Creat per",
    "feed.text10": "títol",
    "feed.text11": "imatge",
    "feed.text12": "contingut",
    "feed.text13": "públic",
    "feed.text14": "privat",
    "feed.text15": "Títol per al post",
    "feed.text16": "Contingut per lloc",
    "feed.text17": "esborrar",
    "feed.text18": "És que no hi ha problema amb la imatge d'esborrar del tot?",
    "feed.text19": "suprimit",
    "feed.text20": "Arxiu multimèdia ha de ser jpg, jpeg, png o mp4, i menys de 3 MB",
    "feed.text21": "lloc públic",
    "feed.text22": "correu privat",
    "feed.text23": "Tipus de cerca ....",
    "feed.text24": "La recerca de usuaris ...",
    "__2": "",
    "userInfo.text1": "Informació de l'usuari",
    "userInfo.text2": "nom",
    "userInfo.text3": "imatge",
    "userInfo.text4": "data de creació",
    "userInfo.text5": "És que no hi ha problema amb la imatge d'esborrar del tot?",
    "userInfo.text6": "Nou nom ...",
    "userInfo.text7": "arxiu d'imatge ha de ser JPG, JPEG o PNG, i menys d'1 MB",
    "userInfo.text8": "Mode fosca",
    "userInfo.text9": "Sobre l'usuari",
    "userInfo.text10": "Informació addicional sobre tu, vista bio etc ... (a menys de 300 caràcters)",
    "userInfo.text11": "Llenguatge",
    "userInfo.text12": "canvi",
    "userInfo.text13": "Notificació",
    "__3": "",
    "auth.text1": "El teu email",
    "auth.text2": "El teu nom",
    "auth.text3": "contrasenya",
    "auth.text4": "La contrasenya ha de tenir entre 8 i 20 caràcters, a l'almenys una lletra majúscula, una lletra minúscula, un número i un caràcter especial (@ $!% *? I).",
    "auth.text5": "Registra't",
    "auth.text6": "iniciar Sessió",
    "auth.text7": "Oblit de la contrasenya",
    "auth.text8": "registrar Pàgina",
    "auth.text9": "Adreça de correu electrònic de restabliment de contrasenya",
    "auth.text10": "Envia un correu electrònic",
    "auth.text11": "Error de validació. Assegureu-vos que l'adreça de correu electrònic no s'utilitza encara!",
    "auth.text12": "Inicia sessió d'usuari no!",
    "auth.text13": "Contrasenya no vàlida s'ha introduït",
    "auth.text14": "No s'ha trobat l'adreça de correu electrònic introduïda",
    "auth.text15": "creació d'usuaris no!",
    "auth.text16": "La direcció de correu electrònic ja està en ús per un altre compte",
    "auth.text17": "Mostra introduït una contrasenya",
    "auth.text18": "Amaga contrasenya",
    "auth.text19": "correu electrònic per al descans contrasenya ser enviada",
    "auth.text20": "No s'ha trobat correu electrònic enterd",
    "auth.text21": "l'èxit de restabliment de contrasenya",
    "auth.text22": "contrasenya ha fallat, si us plau anar a la pàgina d'inici de sessió de correu electrònic i enviament per restablir la contrasenya de nou",
    "auth.text23": "la pàgina de restabliment de contrasenya",
    "auth.text24": "nova contrasenya",
    "auth.text25": "Restablir la contrasenya",
    "auth.text26": "La pàgina no és vàlid o contrasenya ja es canvia",
    "auth.text27": "L'enviament de correu electrònic de restabliment de contrasenya ha fallat, adreça de correu electrònic de verificació",
    "auth.text28": "pàgina de verificació de correu electrònic",
    "auth.text29": "Correu electrònic de verificació èxit, si us plau anar a la pàgina d'inici de sessió es mostra a sobre",
    "auth.text30": "verificació de correu electrònic va fallar. Aquesta pàgina ha caducat, o verificació ja està acabat.",
    "auth.text31": "El correu de verificació es va enviar. Plese consultar el seu correu electrònic i verificar. (També Chack al correu no desitjat)",
    "auth.text32": "Verificació de correu electrònic es requereix per iniciar sessió. Si us plau enviar per correu electrònic per a la verificació de la seva adreça de correu electrònic.",
    "auth.text33": "Envia per correu electrònic per a la verificació",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "penso",
    "nav.text2": "iniciar Sessió",
    "nav.text3": "Registra't",
    "nav.text4": "Informació de l'usuari",
    "nav.text5": "Tancar sessió",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Pàgina no trobada",
    "notFound.text2": "anar a la pàgina d'alimentació",
    "notFound.text3": "anar a la pàgina d'inici de sessió",
    "notFound.text4": "On anar avui?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Cancel · lar",
    "comment.text2": "eliminar",
    "comment.text3": "Escriu un comentari",
    "comment.text4": "Mostrar les respostes",
    "comment.text5": "total",
    "comment.text6": "respondre Escriure",
    "comment.text7": "Amaga Respondre",
    "comment.text8": "Comentaris",
    "comment.text9": "És que no hi ha problema per eliminar aquest comentari i les respostes d'aquest comentari complet?",
    "comment.text10": "És que no hi ha problema per eliminar aquest comentari complet?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Afegir a Favorits Publica",
    "follow.text2": "Eliminar des del post favorit",
    "follow.text3": "Afegir a favorits de l'usuari",
    "follow.text4": "Eliminar de l'usuari favorita",
    "follow.text5": "mostrar missatges",
    "follow.text6": "Els seus Usuaris favorits",
    "follow.text7": "Els usuaris de post favorites",
    "__11": "",
    "privacyPolicy.text1": "Política de privacitat",
    "privacyPolicy.text2": "Aquesta política pot ser actualitzada o modificat sense previ avís. És responsabilitat de l'usuari mantenir-se informat sobre els canvis de política de privacitat. Prendre nota d'altres qüestions de privacitat que poden afectar:",
    "privacyPolicy.text3": "Aquest lloc web pot estar en perill.",
    "privacyPolicy.text4": "El seu equip pot estar en perill.",
    "privacyPolicy.text5": "Aquest lloc web està allotjat en infraestructures de base de foc i digitalocean. Ells poden tenir i tenen els seus propis sistemes de seguiment en els seus servidors. Aquests serveis tenen les seves pròpies polítiques de privacitat i que no estan coberts per aquesta política de privacitat.",
    "privacyPolicy.text6": "Aquest lloc web emmagatzema correu electrònic, forma encriptada de la contrasenya i altres entrades dels usuaris en les infraestructures de base de foc i digitalocean allotjats.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Condicions d'ús",
    "termsOfUse.text2": "intimitat",
    "termsOfUse.text3": "La seva privacitat és important per a nosaltres. Si us plau llegir el",
    "termsOfUse.text4": "política de privacitat",
    "termsOfUse.text5": "per obtenir més informació.",
    "termsOfUse.text6": "Les seves pròpies responsabilitats",
    "termsOfUse.text7": "Vostè, l'usuari, és l'únic responsable d'assegurar el seu propi compliment de les lleis i els impostos en la seva jurisdicció. Vostè és l'únic responsable de la seva pròpia seguretat.",
    "termsOfUse.text8": "Renúncia de garantia",
    "termsOfUse.text9": "Aquest lloc es proporciona \"COM ÉS\", SENSE GARANTIES O CONDICIONS DE QUALSEVOL TIPUS.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "indicada",
    "videoTalk.text2": "connectar parada",
    "videoTalk.text3": "Usuaris en línia",
    "videoTalk.text4": "Començar a parlar",
    "videoTalk.text5": "La seva estan tractant de xerrada",
    "videoTalk.text6": "No hi ha cap altre usuari connectat",
    "videoTalk.text7": "Que està parlant",
    "videoTalk.text8": "Vostè va començar a parlar",
    "videoTalk.text9": "Talk show text",
    "videoTalk.text10": "l'entrada de text ....",
    "videoTalk.text11": "Encara s'està parlant. Vols deixar de parlar?",
    "videoTalk.text12": "L'usuari que truca desconnecta",
    "videoTalk.text13": "usuari que està trucant. Accepta aquesta crida?",
    "videoTalk.text14": "rebutjar la seva crida",
    "videoTalk.text15": "la recerca d'usuaris en línia ...",
    "videoTalk.text16": "la recerca d'usuaris ...",
    "__14": "",
    "groupTalk.text1": "creador",
    "groupTalk.text2": "descripció",
    "groupTalk.text3": "data de creació",
    "groupTalk.text4": "Edita la informació de el grup",
    "groupTalk.text5": "És que no hi ha problema perquè eliminar dels membres d'aquest grup?",
    "groupTalk.text6": "Deixa de membre",
    "groupTalk.text7": "Unir-se al grup",
    "groupTalk.text8": "Mostrar als membres de el Grup",
    "groupTalk.text9": "És vostè desitgi unir-se a aquest grup?",
    "groupTalk.text10": "Nom del grup",
    "groupTalk.text11": "Tornar a la llista",
    "groupTalk.text12": "Confirmeu l'entrada",
    "groupTalk.text13": "crear grup",
    "groupTalk.text14": "grup actualització",
    "groupTalk.text15": "Sortir de el Grup",
    "groupTalk.text16": "Anar a la pàgina principal per iniciar sessió",
    "groupTalk.text17": "nom de el grup (5-100 caràcters)",
    "groupTalk.text18": "Descripció grup (menys de 500 caràcters)"
  },
  {
    "LANGUAGE": "Czech",
    "CODE": "cs",
    "test.text1": "Ahoj",
    "test.text2": "Ahoj",
    "test.text3": "Děkuji",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "zrušení",
    "general.text2": "Akceptovat",
    "general.text3": "Vymazat",
    "general.text4": "vypouští",
    "general.text5": "Upravit",
    "general.text6": "Aktualizace",
    "general.text7": "Nahrát obrázek",
    "general.text8": "Delete Image",
    "general.text9": "obraz",
    "general.text10": "Krmit",
    "general.text11": "Přihlásit se",
    "general.text12": "Přihlásit se",
    "general.text13": "Uživatelské informace",
    "general.text14": "Odhlásit se",
    "general.text15": "Soubor by měl být",
    "general.text16": "a méně než",
    "general.text17": "Heslo",
    "general.text18": "Oblíbené příspěvky",
    "general.text19": "příspěvky",
    "general.text20": "snímky",
    "general.text21": "videa",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nový příspěvek",
    "feed.text2": "Ukázat Uživatelské příspěvky",
    "feed.text3": "Zobrazit příspěvky",
    "feed.text4": "Pohled",
    "feed.text5": "Upravit",
    "feed.text6": "vymazat",
    "feed.text7": "Je není žádný problém se smazat příspěvek úplně?",
    "feed.text8": "Přidal",
    "feed.text9": "Vytvořil",
    "feed.text10": "Titul",
    "feed.text11": "obraz",
    "feed.text12": "Obsah",
    "feed.text13": "veřejnost",
    "feed.text14": "soukromý",
    "feed.text15": "Hlava pro příspěvek",
    "feed.text16": "Obsah por příspěvek",
    "feed.text17": "vymazat",
    "feed.text18": "Je to není problém mazání snímku úplně?",
    "feed.text19": "vypouští",
    "feed.text20": "Media Soubor by měl být jpg, jpeg, png nebo MP4 souborů, a méně než 3 MB",
    "feed.text21": "veřejný příspěvek",
    "feed.text22": "Postprimární",
    "feed.text23": "Zadejte pro vyhledávání ....",
    "feed.text24": "Hledat uživatele ...",
    "__2": "",
    "userInfo.text1": "uživatelské informace",
    "userInfo.text2": "název",
    "userInfo.text3": "obraz",
    "userInfo.text4": "datum vzniku",
    "userInfo.text5": "Je to není problém mazání snímku úplně?",
    "userInfo.text6": "Nové jméno ...",
    "userInfo.text7": "Obrázek by měl být jpg, jpeg, nebo png souborů, a méně než 1 MB",
    "userInfo.text8": "tmavé Mode",
    "userInfo.text9": "o User",
    "userInfo.text10": "další informace o vás, popis bio atd ... (méně než 300 znaků)",
    "userInfo.text11": "Jazyk",
    "userInfo.text12": "Změna",
    "userInfo.text13": "Oznámení",
    "__3": "",
    "auth.text1": "Tvůj e-mail",
    "auth.text2": "Tvé jméno",
    "auth.text3": "Heslo",
    "auth.text4": "Heslo by mělo mít 8 až 20 znaků, alespoň jedno velké písmeno, jedno malé písmeno, jedno číslo a jeden speciální znak (@ $!% *? A).",
    "auth.text5": "Přihlásit se",
    "auth.text6": "Přihlásit se",
    "auth.text7": "Zapomenout heslo",
    "auth.text8": "Registrace Page",
    "auth.text9": "E-mailová adresa pro resetování hesla",
    "auth.text10": "poslat e-mailem",
    "auth.text11": "Ověření se nezdařilo. Ujistěte se, že e-mailová adresa není používán ještě!",
    "auth.text12": "Přihlášení uživatele se nezdařilo!",
    "auth.text13": "Neplatné heslo bylo zadáno",
    "auth.text14": "Zadaná e-mailová adresa nebyla nalezena",
    "auth.text15": "Vytvoření uživatele selhalo!",
    "auth.text16": "E-mailová adresa je již používán jiným účtem",
    "auth.text17": "Ukázat zadané heslo",
    "auth.text18": "skrýt Heslo",
    "auth.text19": "e-mail pro hesla zbytek byl odeslán",
    "auth.text20": "Enterd email nebyl nalezen",
    "auth.text21": "Úspěch resetování hesla",
    "auth.text22": "resetování hesla selhal, prosím, přejděte na přihlašovací stránku a poslat e-mail pro resetování hesla znovu",
    "auth.text23": "Stránka resetování hesla",
    "auth.text24": "nové heslo",
    "auth.text25": "Obnovit heslo",
    "auth.text26": "Stránka je neplatný nebo heslo je již změněn",
    "auth.text27": "Odesílání e-mailu pro resetování hesla se nezdařilo, zkontrolujte e-mailové adresy",
    "auth.text28": "Email ověření stránky",
    "auth.text29": "Email ověření úspěšnosti, přejděte na přihlašovací stránku je uvedeno výše",
    "auth.text30": "Ověření e-mailu se nezdařilo. Tato stránka je vypršel, nebo ověření je již dokončena.",
    "auth.text31": "Ověřovací e-mail byl odeslán. Plese zkontrolovat svůj e-mail a ověřit. (Také Chack ve spam)",
    "auth.text32": "Ověření e-mailu je nutné přihlášení. Prosím, pošlete e-mail k ověření vaší e-mailové adresy.",
    "auth.text33": "Odeslat e-mail pro ověření",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Krmit",
    "nav.text2": "Přihlásit se",
    "nav.text3": "Přihlásit se",
    "nav.text4": "Uživatelské informace",
    "nav.text5": "Odhlásit se",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Stránka nenalezena",
    "notFound.text2": "přejít na stránku krmiv",
    "notFound.text3": "přejít na přihlašovací stránku",
    "notFound.text4": "Kam jít dnes?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "zrušení",
    "comment.text2": "Vymazat",
    "comment.text3": "Přidat komentář",
    "comment.text4": "Show Reply",
    "comment.text5": "celkový",
    "comment.text6": "Psát odpověď",
    "comment.text7": "Skrýt odpověď",
    "comment.text8": "Komentáře",
    "comment.text9": "Je to není problém odstranit tento komentář a odpovědi na tento komentář úplně?",
    "comment.text10": "Je to není problém tento komentář smazat úplně?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Přidat do oblíbených položek Post",
    "follow.text2": "Odstranit z oblíbeného příspěvku",
    "follow.text3": "Přidat k oblíbeným uživatele",
    "follow.text4": "Odstranit z oblíbeného uživatele",
    "follow.text5": "zobrazit příspěvky",
    "follow.text6": "Tvůj oblíbený Uživatelé",
    "follow.text7": "Post Oblíbené Uživatelé",
    "__11": "",
    "privacyPolicy.text1": "Zásady ochrany osobních údajů",
    "privacyPolicy.text2": "Tato pravidla mohou být aktualizovány nebo revidovány bez předchozího upozornění. Je odpovědností uživatele, aby zůstali informováni o změnách zásad ochrany soukromí. Vezměte na vědomí dalším otázkám ochrany osobních údajů, které mohou mít vliv na:",
    "privacyPolicy.text3": "Tato webová stránka by mohla být ohrožena.",
    "privacyPolicy.text4": "Váš počítač může být ohrožen.",
    "privacyPolicy.text5": "Tento web je hostován v firebase a DigitalOcean infrastruktury. Mohou a mají své vlastní sledovací systémy na svých serverech. Tyto služby mají své vlastní zásady ochrany osobních údajů a nejsou pokryty těmito zásadami ochrany soukromí.",
    "privacyPolicy.text6": "Tato webová stránka ukládá e-maily, šifrovaná podoba hesla a další vstupy uživatelů na hostovaných firebase a DigitalOcean infrastruktury.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Podmínky použití",
    "termsOfUse.text2": "Soukromí",
    "termsOfUse.text3": "Vaše soukromí je pro nás důležité. Přečtěte si prosím",
    "termsOfUse.text4": "Zásady ochrany osobních údajů",
    "termsOfUse.text5": "Pro více informací.",
    "termsOfUse.text6": "Vaše vlastní odpovědnosti",
    "termsOfUse.text7": "Vy, uživatel, výhradně zodpovědný za svůj vlastní souladu se zákony a daních ve vaší jurisdikci. Nesete plnou odpovědnost za své vlastní bezpečnosti.",
    "termsOfUse.text8": "Vyloučení záruky",
    "termsOfUse.text9": "Tyto stránky jsou poskytovány „JAK JE“, BEZ ZÁRUKY NEBO PODMÍNKY JAKÉHOKOLIV DRUHU.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online uživatelé",
    "videoTalk.text4": "Začít mluvit",
    "videoTalk.text5": "Vaše se snaží mluvit",
    "videoTalk.text6": "Žádný jiný on-line uživatel existuje",
    "videoTalk.text7": "Mluvíte",
    "videoTalk.text8": "začal hovořit",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "vkládání textu ....",
    "videoTalk.text11": "Jste stále mluví. Chcete přestat mluvit?",
    "videoTalk.text12": "Volající uživatel odpojen",
    "videoTalk.text13": "Uživatel vám volá. Myslíte si, přijmout tuto výzvu?",
    "videoTalk.text14": "odmítnutí hovoru",
    "videoTalk.text15": "vyhledávat uživatele online ...",
    "videoTalk.text16": "vyhledávat uživatele ...",
    "__14": "",
    "groupTalk.text1": "tvůrce",
    "groupTalk.text2": "Popis",
    "groupTalk.text3": "datum vzniku",
    "groupTalk.text4": "Upravit informace o skupině",
    "groupTalk.text5": "Je to není problém, aby vám odstranit z členů této skupiny?",
    "groupTalk.text6": "Odjíždí z členských",
    "groupTalk.text7": "Přidat se ke skupině",
    "groupTalk.text8": "Ukázat Členové skupiny",
    "groupTalk.text9": "Chcete se přidat k této skupině?",
    "groupTalk.text10": "Skupinové jméno",
    "groupTalk.text11": "Zpět na seznam",
    "groupTalk.text12": "Potvrďte zadání",
    "groupTalk.text13": "Vytvořit skupinu",
    "groupTalk.text14": "Skupina aktualizace",
    "groupTalk.text15": "Exit ze skupiny",
    "groupTalk.text16": "Přejít na domovskou stránku Přihlášení",
    "groupTalk.text17": "Název skupiny (5-100 znaků)",
    "groupTalk.text18": "popis skupiny (méně než 500 znaků)"
  },
  {
    "LANGUAGE": "Danish",
    "CODE": "da",
    "test.text1": "Hej",
    "test.text2": "Hej",
    "test.text3": "tak skal du have",
    "test.text4": "kort",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Afbestille",
    "general.text2": "Acceptere",
    "general.text3": "Slet",
    "general.text4": "Slettet",
    "general.text5": "Redigere",
    "general.text6": "Opdatering",
    "general.text7": "Upload billede",
    "general.text8": "Slet billede",
    "general.text9": "Billede",
    "general.text10": "Foder",
    "general.text11": "Log på",
    "general.text12": "Tilmelde",
    "general.text13": "Bruger Info",
    "general.text14": "Log ud",
    "general.text15": "fil skal være",
    "general.text16": "og mindre end",
    "general.text17": "Adgangskode",
    "general.text18": "Favorit Indlæg",
    "general.text19": "Indlæg",
    "general.text20": "Billeder",
    "general.text21": "Videoer",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nyt opslag",
    "feed.text2": "Vis User Indlæg",
    "feed.text3": "Vis Indlæg",
    "feed.text4": "udsigt",
    "feed.text5": "redigere",
    "feed.text6": "slette",
    "feed.text7": "Er der ikke noget problem at slette indlæg helt?",
    "feed.text8": "Sendt af",
    "feed.text9": "Lavet af",
    "feed.text10": "Titel",
    "feed.text11": "Billede",
    "feed.text12": "Indhold",
    "feed.text13": "offentlig",
    "feed.text14": "privat",
    "feed.text15": "Titel til post",
    "feed.text16": "Indhold por indlæg",
    "feed.text17": "slette",
    "feed.text18": "Er det ikke noget problem at slette billedet helt?",
    "feed.text19": "Slettet",
    "feed.text20": "Media File skal være jpg, jpeg, png eller mp4-fil, og mindre end 3 MB",
    "feed.text21": "offentlige indlæg",
    "feed.text22": "privat indlæg",
    "feed.text23": "Skriv til søgning ....",
    "feed.text24": "Søg Bruger ...",
    "__2": "",
    "userInfo.text1": "Brugeroplysninger",
    "userInfo.text2": "navn",
    "userInfo.text3": "billede",
    "userInfo.text4": "Oprettelsesdato",
    "userInfo.text5": "Er det ikke noget problem at slette billedet helt?",
    "userInfo.text6": "Nyt navn ...",
    "userInfo.text7": "Billede fil skal være jpg, jpeg, eller png-fil, og mindre end 1 MB",
    "userInfo.text8": "Mørk tilstand",
    "userInfo.text9": "Om Bruger",
    "userInfo.text10": "Yderligere oplysninger om dig, beskrivelse bio osv ... (mindre end 300 tegn)",
    "userInfo.text11": "Sprog",
    "userInfo.text12": "Lave om",
    "userInfo.text13": "Notifikation",
    "__3": "",
    "auth.text1": "Din email",
    "auth.text2": "Dit navn",
    "auth.text3": "Adgangskode",
    "auth.text4": "Password skal være mellem 8 til 20 tegn, hvoraf mindst ét ​​stort bogstav, et lille bogstav, et tal og et specialtegn (@ $!% *? &).",
    "auth.text5": "Tilmelde",
    "auth.text6": "Log på",
    "auth.text7": "Glem adgangskode",
    "auth.text8": "Tilmeld Side",
    "auth.text9": "E-mail-adresse til nulstilling af adgangskode",
    "auth.text10": "Send e-mail",
    "auth.text11": "Validering mislykkedes. Sørg for, at e-mail-adresse ikke er brugt endnu!",
    "auth.text12": "Bruger login mislykkedes!",
    "auth.text13": "Ugyldig adgangskode blev indtastet",
    "auth.text14": "Indtastede e-mail-adresse blev ikke fundet",
    "auth.text15": "Bruger skabelse mislykkedes!",
    "auth.text16": "Den e-mail-adresse er allerede i brug af en anden konto",
    "auth.text17": "Vis indtastede password",
    "auth.text18": "Skjul adgangskode",
    "auth.text19": "e-mail til adgangskode resten blev sendt",
    "auth.text20": "Enterd e-mail blev ikke fundet",
    "auth.text21": "Password reset succes",
    "auth.text22": "Password reset mislykkedes, venligst gå til login side og send e-mail til nulstilling af adgangskode igen",
    "auth.text23": "Password reset side",
    "auth.text24": "nyt kodeord",
    "auth.text25": "Nulstille kodeord",
    "auth.text26": "Side er ugyldig eller adgangskode er allerede ændret",
    "auth.text27": "Sender email til nulstilling af adgangskode mislykkedes, tjekke e-mail-adresse",
    "auth.text28": "E-mail-bekræftelse side",
    "auth.text29": "E-mail bekræftelse succes, skal du gå til login-siden vist ovenfor",
    "auth.text30": "E-mail-bekræftelse mislykkedes. Denne side er udløbet, eller verificering er allerede færdig.",
    "auth.text31": "Verifikation Mail blev sendt. Plese tjekke din e-mail og bekræfte. (Også chack i spam-mail)",
    "auth.text32": "Email Verifikation er forpligtet til login. Send venligst E-mail til verifikation til din e-mail-adresse.",
    "auth.text33": "Send Email til verifikation",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Foder",
    "nav.text2": "Log på",
    "nav.text3": "Tilmelde",
    "nav.text4": "Bruger Info",
    "nav.text5": "Log ud",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Siden blev ikke fundet",
    "notFound.text2": "gå til foder side",
    "notFound.text3": "gå til login-siden",
    "notFound.text4": "Hvor går man hen i dag?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Afbestille",
    "comment.text2": "Slet",
    "comment.text3": "Skriv kommentar",
    "comment.text4": "Vis Svar",
    "comment.text5": "i alt",
    "comment.text6": "Skriv Svar",
    "comment.text7": "Skjul Svar",
    "comment.text8": "Kommentarer",
    "comment.text9": "Er det ikke noget problem at slette denne kommentar og svar på denne kommentar helt?",
    "comment.text10": "Er det ikke noget problem at slette denne kommentar helt?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Tilføj som Favorit Indlæg",
    "follow.text2": "Slet fra favorit indlæg",
    "follow.text3": "Tilføj til favorit bruger",
    "follow.text4": "Slet fra favorit bruger",
    "follow.text5": "Vis stillinger",
    "follow.text6": "Dine foretrukne Brugere",
    "follow.text7": "Indlæg Favorit Brugere",
    "__11": "",
    "privacyPolicy.text1": "Fortrolighedspolitik",
    "privacyPolicy.text2": "Denne politik kan opdateres eller revideres uden varsel. Det er brugerens ansvar at holde sig orienteret om privacy policy ændringer. Vær opmærksom på andre privatlivets fred, der kan påvirke dig:",
    "privacyPolicy.text3": "Denne hjemmeside kan blive kompromitteret.",
    "privacyPolicy.text4": "Din computer kan være kompromitteret.",
    "privacyPolicy.text5": "Denne hjemmeside er hostet på Firebase og DigitalOcean infrastrukturer. De kan og har deres egne sporingssystemer på deres servere. Disse tjenester har deres egne fortrolighedspolitik og de er ikke omfattet af denne fortrolighedspolitik.",
    "privacyPolicy.text6": "Denne hjemmeside gemmer e-mail, krypteret form for adgangskode og andre indgange på brugere på hostede Firebase og DigitalOcean infrastrukturer.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Betingelser for brug",
    "termsOfUse.text2": "Privatliv",
    "termsOfUse.text3": "Dit privatliv er vigtigt for os. Læs venligst",
    "termsOfUse.text4": "Fortrolighedspolitik",
    "termsOfUse.text5": "for mere information.",
    "termsOfUse.text6": "Dine egne ansvarsområder",
    "termsOfUse.text7": "Du, brugeren, er alene ansvarlig for at sikre din egen overholdelse af love og skatter i din jurisdiktion. Du er alene ansvarlig for din egen sikkerhed.",
    "termsOfUse.text8": "Fraskrivelse af garanti",
    "termsOfUse.text9": "Denne hjemmeside er til rådighed på et \"SOM DEN ER\", UDEN GARANTIER ELLER BETINGELSER AF NOGEN ART.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online brugere",
    "videoTalk.text4": "Start med at snakke",
    "videoTalk.text5": "Din forsøger at tale",
    "videoTalk.text6": "Ingen andre online bruger eksisterer",
    "videoTalk.text7": "Du taler til",
    "videoTalk.text8": "Du begyndte at tale",
    "videoTalk.text9": "Vis Tekst Talk",
    "videoTalk.text10": "tekstindtastning ....",
    "videoTalk.text11": "Du taler stadig. Har du lyst til at stoppe med at tale?",
    "videoTalk.text12": "Opkald bruger afbrudt",
    "videoTalk.text13": "bruger ringer til dig. Vil du acceptere dette opkald?",
    "videoTalk.text14": "afvise dit opkald",
    "videoTalk.text15": "søge online bruger ...",
    "videoTalk.text16": "søg bruger ...",
    "__14": "",
    "groupTalk.text1": "skaberen",
    "groupTalk.text2": "Beskrivelse",
    "groupTalk.text3": "Oprettelsesdato",
    "groupTalk.text4": "Rediger gruppe info",
    "groupTalk.text5": "Er det ikke noget problem at slette dig fra medlemmer af denne gruppe?",
    "groupTalk.text6": "Lad fra medlem",
    "groupTalk.text7": "Tilmeld gruppe",
    "groupTalk.text8": "Vis Gruppens medlemmer",
    "groupTalk.text9": "Ønsker du at deltage i denne Gruppen?",
    "groupTalk.text10": "Gruppe navn",
    "groupTalk.text11": "Tilbage til liste",
    "groupTalk.text12": "Bekræft dit input",
    "groupTalk.text13": "Opret gruppe",
    "groupTalk.text14": "Opdatering Group",
    "groupTalk.text15": "Afslut fra gruppe",
    "groupTalk.text16": "Gå til Hjemmeside til login",
    "groupTalk.text17": "gruppenavn (5-100 tegn)",
    "groupTalk.text18": "gruppebeskrivelse (mindre end 500 tegn)"
  },
  {
    "LANGUAGE": "German",
    "CODE": "de",
    "test.text1": "Hallo",
    "test.text2": "Hallo",
    "test.text3": "Dankeschön",
    "test.text4": "Karte",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Stornieren",
    "general.text2": "Akzeptieren",
    "general.text3": "Löschen",
    "general.text4": "Gelöscht",
    "general.text5": "Bearbeiten",
    "general.text6": "Aktualisieren",
    "general.text7": "Bild hochladen",
    "general.text8": "Lösche Bild",
    "general.text9": "Bild",
    "general.text10": "Futter",
    "general.text11": "Einloggen",
    "general.text12": "Anmelden",
    "general.text13": "Benutzerinformation",
    "general.text14": "Ausloggen",
    "general.text15": "Datei sollte",
    "general.text16": "und weniger als",
    "general.text17": "Passwort",
    "general.text18": "Bevorzugte Beiträge",
    "general.text19": "Beiträge",
    "general.text20": "Bilder",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Neuer Beitrag",
    "feed.text2": "Benutzer Beiträge",
    "feed.text3": "Zeige Beiträge",
    "feed.text4": "Aussicht",
    "feed.text5": "bearbeiten",
    "feed.text6": "löschen",
    "feed.text7": "Ist kein Problem zu löschen Post vollständig?",
    "feed.text8": "Geschrieben von",
    "feed.text9": "Erstellt von",
    "feed.text10": "Titel",
    "feed.text11": "Bild",
    "feed.text12": "Inhalt",
    "feed.text13": "Öffentlichkeit",
    "feed.text14": "Privat",
    "feed.text15": "Titel für die Post",
    "feed.text16": "Inhalt por Post",
    "feed.text17": "löschen",
    "feed.text18": "Ist es kein Problem zu löschen Bild vollständig?",
    "feed.text19": "Gelöscht",
    "feed.text20": "Media-Datei sollte jpg, jpeg, png oder mp4-Datei sein, und weniger als 3 MB",
    "feed.text21": "öffentlicher Beitrag",
    "feed.text22": "Privatpost",
    "feed.text23": "Geben Sie für Search ....",
    "feed.text24": "Nutzer suchen ...",
    "__2": "",
    "userInfo.text1": "Nutzerinformation",
    "userInfo.text2": "Name",
    "userInfo.text3": "Bild",
    "userInfo.text4": "Erstellungsdatum",
    "userInfo.text5": "Ist es kein Problem zu löschen Bild vollständig?",
    "userInfo.text6": "Neuer Name ...",
    "userInfo.text7": "Bilddatei sollte jpg, JPEG, PNG-Datei sein, und weniger als 1 MB",
    "userInfo.text8": "dunkel-Modus",
    "userInfo.text9": "Über den Nutzer",
    "userInfo.text10": "Weitere Informationen über Sie, Beschreibung bio etc ... (weniger als 300 Zeichen)",
    "userInfo.text11": "Sprache",
    "userInfo.text12": "Veränderung",
    "userInfo.text13": "Benachrichtigung",
    "__3": "",
    "auth.text1": "Deine E-Mail",
    "auth.text2": "Dein Name",
    "auth.text3": "Passwort",
    "auth.text4": "Passwort sollte 8-20 Zeichen lang sein, mindestens einen Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen (@ $!% *? &).",
    "auth.text5": "Anmelden",
    "auth.text6": "Einloggen",
    "auth.text7": "Passwort vergessen",
    "auth.text8": "Anmelden Seite",
    "auth.text9": "E-Mail-Adresse für Passwort-Reset",
    "auth.text10": "E-Mail senden",
    "auth.text11": "Überprüfung fehlgeschlagen. Stellen Sie sicher, dass die E-Mail-Adresse ist noch nicht verwendet!",
    "auth.text12": "Benutzeranmeldung fehlgeschlagen!",
    "auth.text13": "Ungültiges Passwort eingegeben wurde",
    "auth.text14": "Eingegebene E-Mail-Adresse wurde nicht gefunden",
    "auth.text15": "Benutzer konnte nicht erstellt werden!",
    "auth.text16": "Die E-Mail-Adresse wird bereits von einem anderen Konto",
    "auth.text17": "Zeigen eingegebene Passwort",
    "auth.text18": "Passwort verbergen",
    "auth.text19": "E-Mail für Passwort Rest wurde gesendet",
    "auth.text20": "Enterd E-Mail wurde nicht gefunden",
    "auth.text21": "Passwort-Reset-Erfolg",
    "auth.text22": "Passwort-Reset nicht, gehen Sie bitte auf Login-Seite und senden E-Mail für Passwort-Reset wieder",
    "auth.text23": "Passwort-Reset-Seite",
    "auth.text24": "Neues Kennwort",
    "auth.text25": "Passwort zurücksetzen",
    "auth.text26": "Seite ist ungültig oder Passwort bereits geändert",
    "auth.text27": "E-Mail wird für Passwort-Reset nicht, überprüfen Sie bitte, E-Mail-Adresse",
    "auth.text28": "E-Mail-Bestätigungsseite",
    "auth.text29": "E-Mail-Überprüfung Erfolg, gehen Sie bitte auf Login-Seite oben gezeigt",
    "auth.text30": "E-Mail-Bestätigung fehlgeschlagen. Diese Seite ist nicht mehr erhältlich oder Überprüfung bereits abgeschlossen ist.",
    "auth.text31": "Bestätigungs-Mail gesendet wurde. Plese überprüfen Sie Ihre E-Mail und überprüfen. (Chack auch in Spam-Mails)",
    "auth.text32": "E-Mail-Überprüfung ist Anmeldung erforderlich. Bitte senden Sie E-Mail zur Bestätigung an Ihre E-Mail-Adresse.",
    "auth.text33": "Senden Sie E-Mail zur Bestätigung",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Futter",
    "nav.text2": "Einloggen",
    "nav.text3": "Anmelden",
    "nav.text4": "Benutzerinformation",
    "nav.text5": "Ausloggen",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Seite nicht gefunden",
    "notFound.text2": "Gehe zu Seite Feed",
    "notFound.text3": "gehen auf Login-Seite",
    "notFound.text4": "Wohin gehst du heute?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Stornieren",
    "comment.text2": "Löschen",
    "comment.text3": "Kommentar hinzufügen",
    "comment.text4": "anzeigen Antworten",
    "comment.text5": "gesamt",
    "comment.text6": "Antwort schreiben",
    "comment.text7": "ausblenden Antworten",
    "comment.text8": "Bemerkungen",
    "comment.text9": "Ist es kein Problem, diesen Kommentar und Antworten des Kommentars vollständig zu löschen?",
    "comment.text10": "Ist es kein Problem, diesen Kommentar vollständig zu löschen?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Als Favorit hinzufügen Beitrag",
    "follow.text2": "Löschen von Favoriten Beitrag",
    "follow.text3": "Zu den Favoriten hinzufügen Benutzer",
    "follow.text4": "Löschen von Favoriten Benutzer",
    "follow.text5": "Beiträge anzeigen",
    "follow.text6": "Ihr Favoritenanwender",
    "follow.text7": "Benutzer Post Lieblings",
    "__11": "",
    "privacyPolicy.text1": "Datenschutz-Bestimmungen",
    "privacyPolicy.text2": "Diese Politik kann ohne vorherige Ankündigung aktualisiert oder überarbeitet werden. Es liegt in der Verantwortung des Benutzers zu bleiben über die Datenschutzrichtlinien Änderungen informiert. Beachten Sie andere Fragen der Privatsphäre, die Sie beeinflussen können:",
    "privacyPolicy.text3": "Diese Webseite könnte beeinträchtigt werden.",
    "privacyPolicy.text4": "Der Computer wird möglicherweise beeinträchtigt werden.",
    "privacyPolicy.text5": "Diese Website wird auf Firebase und DigitalOcean Infrastrukturen gehostet. Sie können und haben ihre eigenen Tracking-Systeme auf ihren Servern. Diese Dienste haben ihre eigenen Datenschutzrichtlinien und sie werden nicht von dieser Datenschutzrichtlinie abgedeckt.",
    "privacyPolicy.text6": "Diese Webseite speichert E-Mail, verschlüsselte Form von Passwort und andere Eingaben von Benutzern bei gehosteten Firebase und DigitalOcean Infrastrukturen.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Nutzungsbedingungen",
    "termsOfUse.text2": "Privatsphäre",
    "termsOfUse.text3": "Ihre Privatsphäre ist uns wichtig. Bitte lesen Sie die",
    "termsOfUse.text4": "Datenschutz-Bestimmungen",
    "termsOfUse.text5": "für mehr Informationen.",
    "termsOfUse.text6": "Ihre eigene Verantwortung",
    "termsOfUse.text7": "Sie, der Benutzer, sind allein verantwortlich für Ihre eigene Einhaltung von Gesetzen und Steuern in Ihrem Land zu gewährleisten. Sie sind für Ihre eigene Sicherheit verantwortlich.",
    "termsOfUse.text8": "Verzichtserklärung der Garantie",
    "termsOfUse.text9": "Diese Seite ist auf einer „AS IS“ BASIS OHNE GARANTIEN ODER BEDINGUNGEN IRGENDEINER ART.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Beenden Sie Connect",
    "videoTalk.text3": "Online Benutzer",
    "videoTalk.text4": "Starten Sie sprechen",
    "videoTalk.text5": "Sie versuchen zu reden",
    "videoTalk.text6": "Kein anderer Online-Benutzer vorhanden ist",
    "videoTalk.text7": "Sie sprechen mit",
    "videoTalk.text8": "Sie begann zu sprechen",
    "videoTalk.text9": "Text anzeigen Diskussion",
    "videoTalk.text10": "Text Eingabe ....",
    "videoTalk.text11": "Sie sind noch im Gespräch. Wollen Sie aufhören zu reden?",
    "videoTalk.text12": "Der Aufruf Benutzer getrennt",
    "videoTalk.text13": "Benutzer ruft dich. Sie diesen Anruf annehmen?",
    "videoTalk.text14": "ablehnen Ihren Anruf",
    "videoTalk.text15": "Online-Suche Benutzer ...",
    "videoTalk.text16": "Nutzer suchen ...",
    "__14": "",
    "groupTalk.text1": "Schöpfer",
    "groupTalk.text2": "Beschreibung",
    "groupTalk.text3": "Erstellungsdatum",
    "groupTalk.text4": "Gruppe bearbeiten info",
    "groupTalk.text5": "Ist es kein Problem, das Sie von den Mitgliedern dieser Gruppe zu löschen?",
    "groupTalk.text6": "Lassen Sie von dem Mitglied",
    "groupTalk.text7": "Gruppe beitreten",
    "groupTalk.text8": "Show Gruppenmitglieder",
    "groupTalk.text9": "Wollen Sie diese Gruppe Mitglied werden?",
    "groupTalk.text10": "Gruppenname",
    "groupTalk.text11": "Zurück zur Liste",
    "groupTalk.text12": "Bestätigen Sie Ihre Eingabe",
    "groupTalk.text13": "Gruppe erstellen",
    "groupTalk.text14": "Update-Gruppe",
    "groupTalk.text15": "Ausfahrt aus der Gruppe",
    "groupTalk.text16": "Zur Homepage zu Anmeldung",
    "groupTalk.text17": "Gruppennamen (5-100 Zeichen)",
    "groupTalk.text18": "Gruppenbeschreibung (weniger als 500 Zeichen)"
  },
  {
    "LANGUAGE": "Divehi",
    "CODE": "dv",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Greek",
    "CODE": "el",
    "test.text1": "γεια",
    "test.text2": "γεια",
    "test.text3": "Σας ευχαριστώ",
    "test.text4": "χάρτης",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Ματαίωση",
    "general.text2": "Αποδέχομαι",
    "general.text3": "Διαγράφω",
    "general.text4": "Διαγράφεται",
    "general.text5": "Επεξεργασία",
    "general.text6": "Εκσυγχρονίζω",
    "general.text7": "Ανεβάστε Εικόνα",
    "general.text8": "Διαγραφή εικόνας",
    "general.text9": "Εικόνα",
    "general.text10": "Ταίζω",
    "general.text11": "Σύνδεση",
    "general.text12": "Εγγραφείτε",
    "general.text13": "Πληροφορίες για το χρήστη",
    "general.text14": "Αποσύνδεση",
    "general.text15": "αρχείο θα πρέπει να είναι",
    "general.text16": "και λιγότερο από",
    "general.text17": "Κωδικός πρόσβασης",
    "general.text18": "Αγαπημένα Δημοσιεύσεις",
    "general.text19": "Δημοσιεύσεις",
    "general.text20": "εικόνες",
    "general.text21": "Βίντεο",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "νέα Δημοσίευση",
    "feed.text2": "Εμφάνιση μηνυμάτων του χρήστη",
    "feed.text3": "Εμφάνιση μηνυμάτων",
    "feed.text4": "θέα",
    "feed.text5": "επεξεργασία",
    "feed.text6": "διαγράφω",
    "feed.text7": "Είναι αυτό δεν είναι πρόβλημα για τη διαγραφή των υστέρων εντελώς;",
    "feed.text8": "αναρτήθηκε από",
    "feed.text9": "Δημιουργήθηκε από",
    "feed.text10": "Τίτλος",
    "feed.text11": "Εικόνα",
    "feed.text12": "Περιεχόμενο",
    "feed.text13": "δημόσιο",
    "feed.text14": "ιδιωτικός",
    "feed.text15": "Τίτλος για τη θέση",
    "feed.text16": "Το περιεχόμενο των υστέρων por",
    "feed.text17": "διαγράφω",
    "feed.text18": "Είναι κανένα πρόβλημα να διαγράψετε την εικόνα εντελώς;",
    "feed.text19": "Διαγράφεται",
    "feed.text20": "Media αρχείου θα πρέπει να είναι jpg, jpeg, png ή αρχείο mp4, και λιγότερο από 3MB",
    "feed.text21": "δημόσια ανάρτηση",
    "feed.text22": "ιδιωτικό ταχυδρομείο",
    "feed.text23": "Πληκτρολογήστε για αναζήτηση ....",
    "feed.text24": "Αναζήτηση Χρηστών ...",
    "__2": "",
    "userInfo.text1": "Πληροφορίες χρήστη",
    "userInfo.text2": "όνομα",
    "userInfo.text3": "εικόνα",
    "userInfo.text4": "ημερομηνία δημιουργίας",
    "userInfo.text5": "Είναι κανένα πρόβλημα να διαγράψετε την εικόνα εντελώς;",
    "userInfo.text6": "Νέο όνομα ...",
    "userInfo.text7": "Το αρχείο εικόνας θα πρέπει να είναι jpg, jpeg, png ή αρχείων, και λιγότερο από 1MB",
    "userInfo.text8": "σκούρο Λειτουργία",
    "userInfo.text9": "Σχετικά με το χρήστη",
    "userInfo.text10": "πρόσθετες πληροφορίες σχετικά με εσάς, την περιγραφή βιο κλπ ... (λιγότερο από 300 χαρακτήρες)",
    "userInfo.text11": "Γλώσσα",
    "userInfo.text12": "Αλλαγή",
    "userInfo.text13": "Γνωστοποίηση",
    "__3": "",
    "auth.text1": "Η διεύθυνση του ηλεκτρονικού σου ταχυδρομείου",
    "auth.text2": "Το όνομα σου",
    "auth.text3": "Κωδικός πρόσβασης",
    "auth.text4": "Κωδικός πρόσβασης θα πρέπει να είναι 8 έως 20 χαρακτήρες, τουλάχιστον ένα γράμμα κεφαλαίο, ένα γράμμα πεζό, έναν αριθμό και ένα ειδικό χαρακτήρα (@ $!% *; &).",
    "auth.text5": "Εγγραφείτε",
    "auth.text6": "Σύνδεση",
    "auth.text7": "Ξεχάστε τον κωδικό σας",
    "auth.text8": "Εγγραφή Σελίδα",
    "auth.text9": "η διεύθυνση e-mail για επαναφορά του κωδικού πρόσβασης",
    "auth.text10": "να στείλετε e-mail",
    "auth.text11": "Η επικύρωση απέτυχε. Βεβαιωθείτε ότι η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν χρησιμοποιείται ακόμα!",
    "auth.text12": "Είσοδος χρήστη απέτυχε!",
    "auth.text13": "Μη έγκυρος κωδικός πρόσβασης καταχωρήθηκε",
    "auth.text14": "Εισαγωγή διεύθυνσης ηλεκτρονικού ταχυδρομείου δεν βρέθηκε",
    "auth.text15": "δημιουργία χρήστη απέτυχε!",
    "auth.text16": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου χρησιμοποιείται ήδη από άλλο λογαριασμό",
    "auth.text17": "Εμφάνιση Εισαγωγή κωδικού πρόσβασης",
    "auth.text18": "Απόκρυψη κωδικού πρόσβασης",
    "auth.text19": "e-mail για ξεκούραση κωδικό εστάλη",
    "auth.text20": "Enterd email δεν βρέθηκε",
    "auth.text21": "επιτυχία επαναφοράς κωδικού πρόσβασης",
    "auth.text22": "επαναφοράς κωδικού πρόσβασης απέτυχε, παρακαλώ πηγαίνετε στο Σύνδεση της σελίδας και να στείλετε e-mail για την επαναφορά του κωδικού πρόσβασης και πάλι",
    "auth.text23": "σελίδα επαναφοράς κωδικού πρόσβασης",
    "auth.text24": "Νέος Κωδικός",
    "auth.text25": "Επαναφέρετε τον κωδικό πρόσβασης",
    "auth.text26": "Η σελίδα είναι άκυρο ή τον κωδικό πρόσβασης έχει ήδη αλλάξει",
    "auth.text27": "Αποστολή e-mail για την επαναφορά του κωδικού πρόσβασης απέτυχε, παρακαλώ διεύθυνση ηλεκτρονικού ταχυδρομείου έλεγχος",
    "auth.text28": "σελίδα επαλήθευσης ηλεκτρονικού ταχυδρομείου",
    "auth.text29": "Email επαλήθευσης επιτυχία, παρακαλούμε πηγαίνετε στη σελίδα σύνδεσης φαίνεται από τα παραπάνω",
    "auth.text30": "επαλήθευση ηλεκτρονικού ταχυδρομείου απέτυχε. Αυτή η σελίδα έχει λήξει, ή επαλήθευσης έχει ήδη ολοκληρωθεί.",
    "auth.text31": "Mail επαλήθευσης εστάλη. Pleše ελέγξτε το email σας και επιβεβαιώστε. (Επίσης chack το μήνυμα spam)",
    "auth.text32": "Email επαλήθευσης πρέπει να Σύνδεση. Παρακαλούμε να στείλετε e-mail για την επαλήθευση στη διεύθυνση ηλεκτρονικού ταχυδρομείου σας.",
    "auth.text33": "Στείλτε e-mail για την επαλήθευση",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Ταίζω",
    "nav.text2": "Σύνδεση",
    "nav.text3": "Εγγραφείτε",
    "nav.text4": "Πληροφορίες για το χρήστη",
    "nav.text5": "Αποσύνδεση",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Η σελίδα δεν βρέθηκε",
    "notFound.text2": "μεταβείτε στη σελίδα feed",
    "notFound.text3": "μεταβείτε στη σελίδα σύνδεσης",
    "notFound.text4": "Πού θα πάτε σήμερα;",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Ματαίωση",
    "comment.text2": "Διαγράφω",
    "comment.text3": "Αναρτήστε Σχόλιο",
    "comment.text4": "Εμφάνιση Απάντηση",
    "comment.text5": "σύνολο",
    "comment.text6": "Γράψτε Απάντηση",
    "comment.text7": "Απόκρυψη Απάντηση",
    "comment.text8": "Σχόλια",
    "comment.text9": "Είναι κανένα πρόβλημα να διαγράψετε αυτό το σχόλιο και τις απαντήσεις του αυτό το σχόλιο εντελώς;",
    "comment.text10": "Είναι κανένα πρόβλημα να διαγράψετε αυτό το σχόλιο εντελώς;",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Προσθήκη στα Αγαπημένα Δημοσίευση",
    "follow.text2": "Διαγραφή από τους αγαπημένους θέση",
    "follow.text3": "Προσθέστε στα αγαπημένα του χρήστη",
    "follow.text4": "Διαγραφή από τα αγαπημένα του χρήστη",
    "follow.text5": "Μηνύματα παράσταση",
    "follow.text6": "Αγαπημένο σας Χρήστες",
    "follow.text7": "Δημοσίευση Αγαπημένη χρήστες",
    "__11": "",
    "privacyPolicy.text1": "Πολιτική Προστασίας Προσωπικών Δεδομένων",
    "privacyPolicy.text2": "Αυτή η πολιτική μπορεί να ενημερωθεί ή να αναθεωρηθούν χωρίς προειδοποίηση. Είναι ευθύνη του χρήστη να είστε ενημερωμένοι σχετικά με τις αλλαγές της πολιτικής προστασίας της ιδιωτικής ζωής. Προσέξτε άλλα ζητήματα προστασίας της ιδιωτικής ζωής που μπορεί να σας επηρεάσουν:",
    "privacyPolicy.text3": "Η ιστοσελίδα αυτή θα μπορούσε να τεθεί σε κίνδυνο.",
    "privacyPolicy.text4": "Ο υπολογιστής σας μπορεί να τεθεί σε κίνδυνο.",
    "privacyPolicy.text5": "Αυτή η ιστοσελίδα φιλοξενείται σε υποδομές firebase και DigitalOcean. Μπορούν και έχουν τα δικά τους συστήματα παρακολούθησης στους κεντρικούς υπολογιστές τους. Οι υπηρεσίες αυτές έχουν τις δικές τους πολιτικές απορρήτου και δεν καλύπτονται από την παρούσα πολιτική απορρήτου.",
    "privacyPolicy.text6": "Αυτή η ιστοσελίδα καταστήματα ηλεκτρονικού ταχυδρομείου, κρυπτογραφημένη μορφή του κωδικού πρόσβασης, και άλλες εισροές των χρηστών σε φιλοξενείται υποδομές firebase και DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Οροι χρήσης",
    "termsOfUse.text2": "Μυστικότητα",
    "termsOfUse.text3": "Προστασία της ιδιωτικής ζωής σας είναι σημαντική για εμάς. Παρακαλούμε διαβάστε το",
    "termsOfUse.text4": "πολιτική απορρήτου",
    "termsOfUse.text5": "Για περισσότερες πληροφορίες.",
    "termsOfUse.text6": "Το δικό σας ευθύνες",
    "termsOfUse.text7": "Εσείς, ο χρήστης, είναι αποκλειστικά υπεύθυνος για την δική σας συμμόρφωση με τους νόμους και τους φόρους στη χώρα σας. Είστε αποκλειστικά υπεύθυνοι για τη δική σας ασφάλεια.",
    "termsOfUse.text8": "Αποποίηση εγγύησης",
    "termsOfUse.text9": "Η ιστοσελίδα αυτή παρέχεται σε «ΩΣ ΕΧΕΙ», ΧΩΡΙΣ ΕΓΓΥΗΣΕΙΣ Ή ΠΡΟΫΠΟΘΕΣΕΙΣ ΟΠΟΙΟΥΔΗΠΟΤΕ ΕΙΔΟΥΣ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "έναρξη Connect",
    "videoTalk.text2": "Διακοπή Σύνδεση",
    "videoTalk.text3": "Συνδεδεμένοι χρήστες",
    "videoTalk.text4": "Ξεκινήστε να μιλήσει",
    "videoTalk.text5": "Σας προσπαθούν να μιλήσει",
    "videoTalk.text6": "Δεν υπάρχει άλλη σε απευθείας σύνδεση των χρηστών",
    "videoTalk.text7": "Μιλάτε για την",
    "videoTalk.text8": "Θα αρχίσει να μιλήσετε",
    "videoTalk.text9": "Εμφάνιση Talk Κείμενο",
    "videoTalk.text10": "εισαγωγής κειμένου ....",
    "videoTalk.text11": "Μπορείτε ακόμα να μιλάμε. Θέλετε να σταματήσουμε να μιλάμε;",
    "videoTalk.text12": "Κλήση χρήστης αποσυνδέθηκε",
    "videoTalk.text13": "χρήστη σας καλεί. Δέχεστε αυτήν την κλήση;",
    "videoTalk.text14": "απόρριψη κλήσης σας",
    "videoTalk.text15": "αναζήτηση σε απευθείας σύνδεση των χρηστών ...",
    "videoTalk.text16": "αναζήτησης του χρήστη ...",
    "__14": "",
    "groupTalk.text1": "δημιουργός",
    "groupTalk.text2": "Περιγραφή",
    "groupTalk.text3": "ημερομηνία δημιουργίας",
    "groupTalk.text4": "Πληροφορίες Επεξεργασία ομάδας",
    "groupTalk.text5": "Είναι κανένα πρόβλημα να διαγράψετε από τα μέλη αυτής της ομάδας;",
    "groupTalk.text6": "Αφήστε από τα κράτη",
    "groupTalk.text7": "Γίνετε μέλος της ομάδας",
    "groupTalk.text8": "Εμφάνιση Ομάδα Μέλη",
    "groupTalk.text9": "Μήπως θέλετε να συμμετάσχετε σε αυτήν την ομάδα;",
    "groupTalk.text10": "Ονομα ομάδας",
    "groupTalk.text11": "Πίσω στη λίστα",
    "groupTalk.text12": "Επιβεβαιώστε την εισαγωγή σας",
    "groupTalk.text13": "Δημιουργησε ΟΜΑΔΑ",
    "groupTalk.text14": "Ομάδα Ενημέρωση",
    "groupTalk.text15": "Έξοδος από ομάδα",
    "groupTalk.text16": "Μεταβείτε στην αρχική σελίδα στο Είσοδος",
    "groupTalk.text17": "όνομα της ομάδας (5-100 χαρακτήρες)",
    "groupTalk.text18": "Περιγραφή ομάδα (λιγότερο από 500 χαρακτήρες)"
  },
  {
    "LANGUAGE": "English",
    "CODE": "en",
    "test.text1": "Hello",
    "test.text2": "Hi",
    "test.text3": "Thank you",
    "test.text4": "map",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Cancel",
    "general.text2": "Accept",
    "general.text3": "Delete",
    "general.text4": "Deleted",
    "general.text5": "Edit",
    "general.text6": "Update",
    "general.text7": "Upload Image",
    "general.text8": "Delete Image",
    "general.text9": "Image",
    "general.text10": "Feed",
    "general.text11": "Login",
    "general.text12": "Signup",
    "general.text13": "User Info",
    "general.text14": "Logout",
    "general.text15": "file should be",
    "general.text16": "and less than",
    "general.text17": "Password",
    "general.text18": "Favorite Posts",
    "general.text19": "Posts",
    "general.text20": "Images",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Show User Posts",
    "feed.text3": "Show Posts",
    "feed.text4": "view",
    "feed.text5": "edit",
    "feed.text6": "delete",
    "feed.text7": "Is is no problem to delete post completely?",
    "feed.text8": "Posted by",
    "feed.text9": "Created by",
    "feed.text10": "Title",
    "feed.text11": "Image",
    "feed.text12": "Content",
    "feed.text13": "public",
    "feed.text14": "private",
    "feed.text15": "Title for post",
    "feed.text16": "Content por post",
    "feed.text17": "delete",
    "feed.text18": "Is it no problem to delete image completely?",
    "feed.text19": "Deleted",
    "feed.text20": "Media File should be jpg, jpeg, png or mp4 file, and less than 3MB",
    "feed.text21": "public post",
    "feed.text22": "private post",
    "feed.text23": "Type for Search ....",
    "feed.text24": "Search User ...",
    "__2": "",
    "userInfo.text1": "User Information",
    "userInfo.text2": "name",
    "userInfo.text3": "image",
    "userInfo.text4": "creation date",
    "userInfo.text5": "Is it no problem to delete image completely?",
    "userInfo.text6": "New Name ...",
    "userInfo.text7": "Image file should be jpg, jpeg, or png file, and less than 1MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "About User",
    "userInfo.text10": "additional information about you, description bio etc... (less than 300 characters)",
    "userInfo.text11": "Language",
    "userInfo.text12": "Change",
    "userInfo.text13": "Notification",
    "__3": "",
    "auth.text1": "Your E-mail",
    "auth.text2": "Your Name",
    "auth.text3": "Password",
    "auth.text4": "Password should be 8 to 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&).",
    "auth.text5": "Signup",
    "auth.text6": "Login",
    "auth.text7": "Forget Password",
    "auth.text8": "Signup Page",
    "auth.text9": "E-mail address for password reset",
    "auth.text10": "send E-mail",
    "auth.text11": "Validation failed. Make sure the email address isn't used yet!",
    "auth.text12": "User login failed!",
    "auth.text13": "Invalid Password was Entered",
    "auth.text14": "Entered email address was not found",
    "auth.text15": "User creation failed!",
    "auth.text16": "The email address is already in use by another account",
    "auth.text17": "Show Entered Password",
    "auth.text18": "Hide Password",
    "auth.text19": "email for password rest was sent",
    "auth.text20": "Enterd email was not found",
    "auth.text21": "Password reset success",
    "auth.text22": "Password reset failed, Please go to Login page and send email for password reset again",
    "auth.text23": "Password reset page",
    "auth.text24": "New Password",
    "auth.text25": "Reset Password",
    "auth.text26": "Page is invalid or Password is already changed",
    "auth.text27": "Sending email for password reset failed, Please check email address",
    "auth.text28": "Email verification page",
    "auth.text29": "Email verification success, please go to login page shown above",
    "auth.text30": "Email verification failed. This page is expired, or verification is already finished.",
    "auth.text31": "Verification Mail was sent. Plese check your email and verify. (also chack in spam mail)",
    "auth.text32": "Email Verification is required to Login. Please send Email for verification to your Email address.",
    "auth.text33": "Send Email for verification",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Login",
    "nav.text3": "Signup",
    "nav.text4": "User Info",
    "nav.text5": "Logout",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Page Not Found",
    "notFound.text2": "go to feed page",
    "notFound.text3": "go to login page",
    "notFound.text4": "Where do you go today?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Cancel",
    "comment.text2": "Delete",
    "comment.text3": "Post Comment",
    "comment.text4": "Show Reply",
    "comment.text5": "total",
    "comment.text6": "Write Reply",
    "comment.text7": "Hide Reply",
    "comment.text8": "Comments",
    "comment.text9": "Is it no problem to delete this comment and replies of this comment completely?",
    "comment.text10": "Is it no problem to delete this comment completely?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Add to Favorite Post",
    "follow.text2": "Delete from favorite post",
    "follow.text3": "Add to favorite user",
    "follow.text4": "Delete from favorite user",
    "follow.text5": "show posts",
    "follow.text6": "Your Favorite Users",
    "follow.text7": "Post Favorite Users",
    "__11": "",
    "privacyPolicy.text1": "Privacy Policy",
    "privacyPolicy.text2": "This policy may be updated or revised without notice. It is the responsibility of the user to stay informed about privacy policy changes. Take note of other privacy issues that may affect you:",
    "privacyPolicy.text3": "This website might be compromised.",
    "privacyPolicy.text4": "Your computer might be compromised.",
    "privacyPolicy.text5": "This website is hosted on Firebase and DigitalOcean infrastructures. They may and do have their own tracking systems on their servers. Those services have their own privacy policies and they are not covered by this privacy policy.",
    "privacyPolicy.text6": "This website stores email, encrypted form of password, and other inputs of users at hosted Firebase and DigitalOcean infrastructures.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Terms of use",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Your privacy is important to us. Please read the",
    "termsOfUse.text4": "privacy policy",
    "termsOfUse.text5": "for more information.",
    "termsOfUse.text6": "Your own responsibilities",
    "termsOfUse.text7": "You, the user, are solely responsible for ensuring your own compliance with laws and taxes in your jurisdiction. You are solely responsible for your own security.",
    "termsOfUse.text8": "Disclaimer of warranty",
    "termsOfUse.text9": "This site is provided on an \"AS IS\" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online Users",
    "videoTalk.text4": "Start to talk",
    "videoTalk.text5": "Your are trying to talk",
    "videoTalk.text6": "No other online user exists",
    "videoTalk.text7": "You are talking to",
    "videoTalk.text8": "You started to talk",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "text input ....",
    "videoTalk.text11": "You are still talking. Do you want to stop talking?",
    "videoTalk.text12": "Calling user disconnected",
    "videoTalk.text13": "user is calling you. Do you accept this call?",
    "videoTalk.text14": "reject your call",
    "videoTalk.text15": "search online user...",
    "videoTalk.text16": "search user...",
    "__14": "",
    "groupTalk.text1": "creator",
    "groupTalk.text2": "Description",
    "groupTalk.text3": "creation date",
    "groupTalk.text4": "Edit group info",
    "groupTalk.text5": "Is it no problem to delete you from members of this group?",
    "groupTalk.text6": "Leave from Member",
    "groupTalk.text7": "Join Group",
    "groupTalk.text8": "Show Group Members",
    "groupTalk.text9": "Do You Want to Join This Group?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Back to List",
    "groupTalk.text12": "Confirm your input",
    "groupTalk.text13": "Create Group",
    "groupTalk.text14": "Update Group",
    "groupTalk.text15": "Exit from Group",
    "groupTalk.text16": "Go to Homepage to Login",
    "groupTalk.text17": "group name (5-100 characters)",
    "groupTalk.text18": "group description (less than 500 characters)"
  },
  {
    "LANGUAGE": "Esperanto",
    "CODE": "eo",
    "test.text1": "Saluton",
    "test.text2": "Saluton",
    "test.text3": "Dankon",
    "test.text4": "mapi",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Nuligi",
    "general.text2": "akceptu",
    "general.text3": "Forigi",
    "general.text4": "Forigita",
    "general.text5": "redakti",
    "general.text6": "Ĝisdatigi",
    "general.text7": "Alŝuto Bildo",
    "general.text8": "Forigi Bildo",
    "general.text9": "bildo",
    "general.text10": "paŝtaĵo",
    "general.text11": "Ensaluti",
    "general.text12": "Membriĝi",
    "general.text13": "Uzantinformoj",
    "general.text14": "Elsaluti",
    "general.text15": "dosiero devus esti",
    "general.text16": "kaj malpli ol",
    "general.text17": "Pasvorto",
    "general.text18": "favorita Afiŝoj",
    "general.text19": "Afiŝoj",
    "general.text20": "bildoj",
    "general.text21": "videoj",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "nova Poŝto",
    "feed.text2": "Montru Uzanto Afiŝoj",
    "feed.text3": "Montri afiŝojn",
    "feed.text4": "vido",
    "feed.text5": "redakti",
    "feed.text6": "forviŝi",
    "feed.text7": "Ĉu estas problemo al forigo post tute?",
    "feed.text8": "Poŝtita de",
    "feed.text9": "kreita de",
    "feed.text10": "Titolo",
    "feed.text11": "bildo",
    "feed.text12": "enhavo",
    "feed.text13": "publika",
    "feed.text14": "privata",
    "feed.text15": "Titolo por afiŝo",
    "feed.text16": "Enhavo bonvolu posteno",
    "feed.text17": "forviŝi",
    "feed.text18": "Ĉu neniu problemo por forigo bildon tute?",
    "feed.text19": "Forigita",
    "feed.text20": "Amaskomunikiloj Dosiero estu jpg, jpg, png aŭ mp4 dosiero, kaj malpli ol 3MB",
    "feed.text21": "publika posteno",
    "feed.text22": "privata posteno",
    "feed.text23": "Tajpu por Serĉu ....",
    "feed.text24": "Serĉu Uzanto ...",
    "__2": "",
    "userInfo.text1": "uzanto Informoj",
    "userInfo.text2": "nomo",
    "userInfo.text3": "bildo",
    "userInfo.text4": "kreo daton",
    "userInfo.text5": "Ĉu neniu problemo por forigo bildon tute?",
    "userInfo.text6": "Nova nomo ...",
    "userInfo.text7": "Bildo dosiero estu jpg, jpg, aŭ png dosiero, kaj malpli ol 1MB",
    "userInfo.text8": "malluma Mode",
    "userInfo.text9": "pri Uzanto",
    "userInfo.text10": "aldonaj informoj pri vi, priskribo bio ktp ... (malpli ol 300 karakteroj)",
    "userInfo.text11": "Lingvo",
    "userInfo.text12": "ŝanĝo",
    "userInfo.text13": "sciigo",
    "__3": "",
    "auth.text1": "Via Retpoŝto",
    "auth.text2": "Via nomo",
    "auth.text3": "Pasvorto",
    "auth.text4": "Pasvorto estu 8 ĝis 20 karakteroj, almenaŭ unu majuskla litero, unu minuskla litero, unu nombro kaj unu specialan karakteron (@ $!% *? &).",
    "auth.text5": "Membriĝi",
    "auth.text6": "Ensaluti",
    "auth.text7": "Forgesu Pasvorto",
    "auth.text8": "Membriĝi Paĝo",
    "auth.text9": "Retadreso por pasvorta ŝanĝo",
    "auth.text10": "sendu Retpoŝto",
    "auth.text11": "Validigo malsukcesis. Certiĝu la retadreso ne estas uzata ankoraŭ!",
    "auth.text12": "Uzanta ensaluto malsukcesis!",
    "auth.text13": "Nevalida Pasvorto ĝi Eniris",
    "auth.text14": "Eniris retadreso ne troviĝis",
    "auth.text15": "Uzanto kreo malsukcesis!",
    "auth.text16": "La retpoŝtadreso estas jam uzata de alia konto",
    "auth.text17": "Montru Enmetite Pasvorto",
    "auth.text18": "kaŝi Pasvorto",
    "auth.text19": "retpoŝto por pasvorton resto estis sendita",
    "auth.text20": "Enterd retpoŝto ne troviĝis",
    "auth.text21": "Pasvorto reset sukceso",
    "auth.text22": "Pasvorta ŝanĝo malsukcesis, Bonvolu iri al Salutnomo paĝo kaj sendu retpoŝton por pasvorta ŝanĝo denove",
    "auth.text23": "Pasvorta ŝanĝo paĝo",
    "auth.text24": "Nova pasvorto",
    "auth.text25": "rekomencigita pasvorton",
    "auth.text26": "Page estas nevalida aŭ Pasvorto jam ŝanĝiĝis",
    "auth.text27": "Sendante retpoŝton por pasvorta ŝanĝo malsukcesis, bonvolu kontroli retadreson",
    "auth.text28": "Retpoŝto konfirmo paĝon",
    "auth.text29": "Retpoŝto konfirmo sukceso, bonvolu iri al ensaluta paĝo montrita supre",
    "auth.text30": "Retpoŝto konfirmo malsukcesis. Ĉi tiu paĝo estas finiĝis, aŭ konfirmo jam finita.",
    "auth.text31": "Konfirma Poŝto sendita. Plese kontroli vian retpoŝton kaj konfirmi. (Ankaŭ chack en spamon poŝto)",
    "auth.text32": "Retpoŝto Verification estas bezonata por Salutnomo. Bonvolu sendi Retpoŝto por konfirmo al via Retpoŝto adreso.",
    "auth.text33": "Sendi retleteron por konfirmo",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "paŝtaĵo",
    "nav.text2": "Ensaluti",
    "nav.text3": "Membriĝi",
    "nav.text4": "Uzantinformoj",
    "nav.text5": "Elsaluti",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Paĝo ne trovita",
    "notFound.text2": "iru al-servo paĝon",
    "notFound.text3": "iru al ensaluta paĝo",
    "notFound.text4": "De kie vi iras hodiaŭ?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Nuligi",
    "comment.text2": "Forigi",
    "comment.text3": "post Komento",
    "comment.text4": "Montri Respondi",
    "comment.text5": "tuta",
    "comment.text6": "skribi Respondi",
    "comment.text7": "kaŝi Respondi",
    "comment.text8": "komentoj",
    "comment.text9": "Ĉu problemo forigi tiun komenton kaj respondoj de ĉi komento tute?",
    "comment.text10": "Ĉu problemo forigi tiun komenton tute?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Aldoni al Favorita Poŝto",
    "follow.text2": "Forigi el preferataj posteno",
    "follow.text3": "Aldoni al preferataj uzanto",
    "follow.text4": "Forigi el preferataj uzanto",
    "follow.text5": "spektaklo afiŝojn",
    "follow.text6": "Via Favorita Uzantoj",
    "follow.text7": "Post Favorita Uzantoj",
    "__11": "",
    "privacyPolicy.text1": "Privateca Politiko",
    "privacyPolicy.text2": "Tiu politiko povas esti ĝisdatigitaj aŭ reviziita sen avizo. Estas la respondeco de la uzanto restado informita pri privateca politiko ŝanĝoj. Observu aliaj privateco aferoj kiuj povas tuŝi vin;",
    "privacyPolicy.text3": "Tiu retejo eble estos kompromitita.",
    "privacyPolicy.text4": "Via komputilo povus esti kompromitita.",
    "privacyPolicy.text5": "Ĉi tiu retejo estas gastigita sur Firebase kaj DigitalOcean infrastrukturojn. Ili povas kaj ja havas proprajn sekvado sistemoj en liaj servantoj. Tiuj servoj havas proprajn privatecaj politikoj kaj ili ne estas kovritaj per tiu privateca politiko.",
    "privacyPolicy.text6": "Tiu retejo vendejoj retpoŝton, ĉifrita formo de pasvorto, kaj aliaj enigoj de uzantoj ĉe gastigita Firebase kaj DigitalOcean infrastrukturojn.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Kondiĉoj de Uzado",
    "termsOfUse.text2": "Privateco",
    "termsOfUse.text3": "Via privateco estas grava al ni. Bonvolu legi la",
    "termsOfUse.text4": "privateca politiko",
    "termsOfUse.text5": "por pli da informo.",
    "termsOfUse.text6": "Via propra respondecoj",
    "termsOfUse.text7": "Vi, la uzanto, estas sola respondeca certigante via propra plenumo leĝoj kaj impostoj en via jurisdikcio. Nur vi estas responsa pri via propra sekureco.",
    "termsOfUse.text8": "Malgarantion",
    "termsOfUse.text9": "Tiu ĉi retejo estas provizita sur \"KIEL ESTAS\" FUNDAMENTOJ, SEN GARANTIOJ OR KONDIĈOJ ia.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "komenco Konekti",
    "videoTalk.text2": "Stop Konekti",
    "videoTalk.text3": "rete Uzantoj",
    "videoTalk.text4": "Komenci paroli",
    "videoTalk.text5": "Via provas parolado",
    "videoTalk.text6": "Neniu alia rete uzanto ekzistas",
    "videoTalk.text7": "Vi parolas al",
    "videoTalk.text8": "Vi komencis paroli",
    "videoTalk.text9": "Montri Teksto Talk",
    "videoTalk.text10": "teksto enigo ....",
    "videoTalk.text11": "Vi ankoraŭ parolas. Ĉu vi volas halti parolanta?",
    "videoTalk.text12": "Nomante uzanto malkonektita",
    "videoTalk.text13": "uzanto alvokas vin. Ĉu vi akceptas tiun alvokon?",
    "videoTalk.text14": "malakcepti vian alvokon",
    "videoTalk.text15": "serĉu rete uzanto ...",
    "videoTalk.text16": "serĉu uzanto ...",
    "__14": "",
    "groupTalk.text1": "kreinto",
    "groupTalk.text2": "Priskribo",
    "groupTalk.text3": "kreo daton",
    "groupTalk.text4": "Redakti grupo info",
    "groupTalk.text5": "Ĉu problemo forigi vin de membroj de ĉi tiu grupo?",
    "groupTalk.text6": "Lasu de Membro",
    "groupTalk.text7": "aliĝi Grupo",
    "groupTalk.text8": "Montri Grupo Membroj",
    "groupTalk.text9": "Ĉu vi volas aliĝi al tiu grupo?",
    "groupTalk.text10": "grupo Nomo",
    "groupTalk.text11": "Reen al Listo",
    "groupTalk.text12": "Konfirmi via enigo",
    "groupTalk.text13": "krei Grupon",
    "groupTalk.text14": "Ĝisdatigi Grupo",
    "groupTalk.text15": "Eliri de Grupo",
    "groupTalk.text16": "Iru al Ĉefpaĝo al Salutnomo",
    "groupTalk.text17": "grupo nomo (5-100 karakteroj)",
    "groupTalk.text18": "grupo priskribo (malpli ol 500 karakteroj)"
  },
  {
    "LANGUAGE": "Spanish",
    "CODE": "es",
    "test.text1": "Hola",
    "test.text2": "Hola",
    "test.text3": "Gracias",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Cancelar",
    "general.text2": "Aceptar",
    "general.text3": "Eliminar",
    "general.text4": "Suprimido",
    "general.text5": "Editar",
    "general.text6": "Actualizar",
    "general.text7": "Cargar imagen",
    "general.text8": "Eliminar Imagen",
    "general.text9": "Imagen",
    "general.text10": "Alimentar",
    "general.text11": "Iniciar sesión",
    "general.text12": "Regístrate",
    "general.text13": "Información de usuario",
    "general.text14": "Cerrar sesión",
    "general.text15": "archivo debe estar",
    "general.text16": "y menos de",
    "general.text17": "Contraseña",
    "general.text18": "Mensajes favoritos",
    "general.text19": "Mensajes",
    "general.text20": "imágenes",
    "general.text21": "vídeos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nueva publicación",
    "feed.text2": "Mostrar mensajes de los usuarios",
    "feed.text3": "Mostrar Mensajes",
    "feed.text4": "ver",
    "feed.text5": "editar",
    "feed.text6": "Eliminar",
    "feed.text7": "Es no es un problema para después de eliminar por completo?",
    "feed.text8": "publicado por",
    "feed.text9": "Creado por",
    "feed.text10": "Título",
    "feed.text11": "Imagen",
    "feed.text12": "Contenido",
    "feed.text13": "público",
    "feed.text14": "privado",
    "feed.text15": "Título para el post",
    "feed.text16": "Contenido por puesto",
    "feed.text17": "Eliminar",
    "feed.text18": "Es que no hay problema con la imagen de borrar por completo?",
    "feed.text19": "Suprimido",
    "feed.text20": "Archivo multimedia debe ser jpg, jpeg, png o mp4, y menos de 3 MB",
    "feed.text21": "puesto público",
    "feed.text22": "correo privado",
    "feed.text23": "Tipo de búsqueda ....",
    "feed.text24": "La búsqueda de usuarios ...",
    "__2": "",
    "userInfo.text1": "informacion del usuario",
    "userInfo.text2": "nombre",
    "userInfo.text3": "imagen",
    "userInfo.text4": "fecha de creación",
    "userInfo.text5": "Es que no hay problema con la imagen de borrar por completo?",
    "userInfo.text6": "Nuevo nombre ...",
    "userInfo.text7": "archivo de imagen debe ser JPG, JPEG o PNG, y menos de 1 MB",
    "userInfo.text8": "Modo oscura",
    "userInfo.text9": "Acerca del usuario",
    "userInfo.text10": "Información adicional acerca de usted, descripción bio etc ... (a menos de 300 caracteres)",
    "userInfo.text11": "Idioma",
    "userInfo.text12": "Cambio",
    "userInfo.text13": "Notificación",
    "__3": "",
    "auth.text1": "Tu correo electrónico",
    "auth.text2": "Tu nombre",
    "auth.text3": "Contraseña",
    "auth.text4": "La contraseña debe tener entre 8 y 20 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial (@ $!% *? Y).",
    "auth.text5": "Regístrate",
    "auth.text6": "Iniciar sesión",
    "auth.text7": "Contraseña olvidada",
    "auth.text8": "Registrar Página",
    "auth.text9": "Dirección de correo electrónico de restablecimiento de contraseña",
    "auth.text10": "enviar correo electrónico",
    "auth.text11": "Validación fallida. Asegúrese de que la dirección de correo electrónico no se utiliza todavía!",
    "auth.text12": "Iniciar sesión de usuario no!",
    "auth.text13": "Contraseña no válida se ha introducido",
    "auth.text14": "No se encontró la dirección de correo electrónico introducida",
    "auth.text15": "creación de usuarios no!",
    "auth.text16": "La dirección de correo electrónico ya está en uso por otra cuenta",
    "auth.text17": "Mostrar introducido una contraseña",
    "auth.text18": "Contraseña oculta",
    "auth.text19": "correo electrónico para el descanso contraseña fue enviada",
    "auth.text20": "No se ha encontrado correo electrónico enterd",
    "auth.text21": "el éxito de restablecimiento de contraseña",
    "auth.text22": "contraseña falló, por favor ir a la página de Inicio de sesión de correo electrónico y envío para restablecer la contraseña de nuevo",
    "auth.text23": "la página de restablecimiento de contraseña",
    "auth.text24": "Nueva contraseña",
    "auth.text25": "Restablecer la contraseña",
    "auth.text26": "La página no es válido o contraseña ya se cambia",
    "auth.text27": "El envío de correo electrónico de restablecimiento de contraseña ha fallado, dirección de correo electrónico de verificación",
    "auth.text28": "página de verificación de correo electrónico",
    "auth.text29": "Correo electrónico de verificación éxito, por favor ir a la página de inicio de sesión se muestra arriba",
    "auth.text30": "verificación de correo electrónico falló. Esta página ha caducado, o verificación ya está terminado.",
    "auth.text31": "El correo de verificación se envió. Plese consultar su correo electrónico y verificar. (También chack en el correo no deseado)",
    "auth.text32": "Verificación de correo electrónico se requiere para iniciar sesión. Por favor enviar por correo electrónico para la verificación de su dirección de correo electrónico.",
    "auth.text33": "Enviar por correo electrónico para la verificación",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Alimentar",
    "nav.text2": "Iniciar sesión",
    "nav.text3": "Regístrate",
    "nav.text4": "Información de usuario",
    "nav.text5": "Cerrar sesión",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Página no encontrada",
    "notFound.text2": "ir a la página de alimentación",
    "notFound.text3": "ir a la página de inicio de sesión",
    "notFound.text4": "¿Dónde ir hoy?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Cancelar",
    "comment.text2": "Eliminar",
    "comment.text3": "publicar comentario",
    "comment.text4": "Mostrar las respuestas",
    "comment.text5": "total",
    "comment.text6": "Responder Escribir",
    "comment.text7": "Ocultar Responder",
    "comment.text8": "comentarios",
    "comment.text9": "Es que no hay problema para eliminar este comentario y las respuestas de este comentario completo?",
    "comment.text10": "Es que no hay problema para eliminar este comentario completo?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Añadir a Favoritos Publica",
    "follow.text2": "Eliminar desde el post favorito",
    "follow.text3": "Añadir a favoritos del usuario",
    "follow.text4": "Eliminar del usuario favorita",
    "follow.text5": "Mostrar mensajes",
    "follow.text6": "Sus Usuarios favoritos",
    "follow.text7": "Los usuarios de post favoritas",
    "__11": "",
    "privacyPolicy.text1": "Política de privacidad",
    "privacyPolicy.text2": "Esta política puede ser actualizada o modificado sin previo aviso. Es responsabilidad del usuario mantenerse informado acerca de los cambios de política de privacidad. Tomar nota de otras cuestiones de privacidad que pueden afectarle:",
    "privacyPolicy.text3": "Este sitio web puede estar en peligro.",
    "privacyPolicy.text4": "Su equipo puede estar en peligro.",
    "privacyPolicy.text5": "Este sitio web está alojado en infraestructuras de base de fuego y digitalocean. Ellos pueden tener y tienen sus propios sistemas de seguimiento en sus servidores. Estos servicios tienen sus propias políticas de privacidad y que no están cubiertos por esta política de privacidad.",
    "privacyPolicy.text6": "Este sitio web almacena correo electrónico, forma encriptada de la contraseña y otros insumos de los usuarios en las infraestructuras de base de fuego y digitalocean alojados.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Términos de Uso",
    "termsOfUse.text2": "Intimidad",
    "termsOfUse.text3": "Su privacidad es importante para nosotros. Por favor leer el",
    "termsOfUse.text4": "política de privacidad",
    "termsOfUse.text5": "para más información.",
    "termsOfUse.text6": "Sus propias responsabilidades",
    "termsOfUse.text7": "Usted, el usuario, es el único responsable de asegurar su propio cumplimiento de las leyes y los impuestos en su jurisdicción. Usted es el único responsable de su propia seguridad.",
    "termsOfUse.text8": "Renuncia de garantía",
    "termsOfUse.text9": "Este sitio se proporciona \"COMO ES\", SIN GARANTÍAS O CONDICIONES DE CUALQUIER TIPO.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "indicada",
    "videoTalk.text2": "Conectar parada",
    "videoTalk.text3": "Usuarios en línea",
    "videoTalk.text4": "Empezar a hablar",
    "videoTalk.text5": "Su están tratando de charla",
    "videoTalk.text6": "No existe ningún otro usuario conectado",
    "videoTalk.text7": "Que está hablando",
    "videoTalk.text8": "Usted comenzó a hablar",
    "videoTalk.text9": "Talk show texto",
    "videoTalk.text10": "entrada de texto ....",
    "videoTalk.text11": "Todavía se está hablando. ¿Quieres dejar de hablar?",
    "videoTalk.text12": "El usuario llamante desconecta",
    "videoTalk.text13": "usuario que está llamando. ¿Acepta esta llamada?",
    "videoTalk.text14": "rechazar su llamada",
    "videoTalk.text15": "la búsqueda de usuarios en línea ...",
    "videoTalk.text16": "la búsqueda de usuarios ...",
    "__14": "",
    "groupTalk.text1": "creador",
    "groupTalk.text2": "Descripción",
    "groupTalk.text3": "fecha de creación",
    "groupTalk.text4": "Editar la información del grupo",
    "groupTalk.text5": "Es que no hay problema para que eliminar de los miembros de este grupo?",
    "groupTalk.text6": "Deja de miembro",
    "groupTalk.text7": "Unirse al grupo",
    "groupTalk.text8": "Mostrar a los miembros del Grupo",
    "groupTalk.text9": "¿Es usted desee unirse a este grupo?",
    "groupTalk.text10": "Nombre del grupo",
    "groupTalk.text11": "Volver a la lista",
    "groupTalk.text12": "Confirme la entrada",
    "groupTalk.text13": "Crea un grupo",
    "groupTalk.text14": "Grupo actualización",
    "groupTalk.text15": "Salir del Grupo",
    "groupTalk.text16": "Ir a la página principal para iniciar sesión",
    "groupTalk.text17": "nombre del grupo (5-100 caracteres)",
    "groupTalk.text18": "Descripción grupo (menos de 500 caracteres)"
  },
  {
    "LANGUAGE": "Estonian",
    "CODE": "et",
    "test.text1": "Tere",
    "test.text2": "Tere",
    "test.text3": "Aitäh",
    "test.text4": "kaarti",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "tühistama",
    "general.text2": "aktsepteerima",
    "general.text3": "Kustuta",
    "general.text4": "kustutatud",
    "general.text5": "Edit",
    "general.text6": "ajakohastama",
    "general.text7": "Lae pilt üles",
    "general.text8": "Kustuta Pilt",
    "general.text9": "pilt",
    "general.text10": "sööt",
    "general.text11": "Logi sisse",
    "general.text12": "Registreeri",
    "general.text13": "kasutaja Info",
    "general.text14": "Logi välja",
    "general.text15": "Fail peaks olema",
    "general.text16": "ja vähem kui",
    "general.text17": "parool",
    "general.text18": "lemmik postitused",
    "general.text19": "postitused",
    "general.text20": "pilte",
    "general.text21": "Videod",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Uus postitus",
    "feed.text2": "Näita kasutaja postitused",
    "feed.text3": "Näita postitusi",
    "feed.text4": "vaade",
    "feed.text5": "muuta",
    "feed.text6": "kustutama",
    "feed.text7": "Kas on mingit probleemi Postituse kustutamine täiesti?",
    "feed.text8": "Postitaja",
    "feed.text9": "Loodud",
    "feed.text10": "pealkiri",
    "feed.text11": "pilt",
    "feed.text12": "Sisu",
    "feed.text13": "avalik",
    "feed.text14": "privaatne",
    "feed.text15": "Pealkiri post",
    "feed.text16": "Sisu por post",
    "feed.text17": "kustutama",
    "feed.text18": "Kas see ei ole probleem kustutada pilti täielikult?",
    "feed.text19": "kustutatud",
    "feed.text20": "Meedia fail peab olema jpg, jpeg, png või mp4 faili, ja vähem kui 3 MB",
    "feed.text21": "avaliku postituse",
    "feed.text22": "privaatset postitust",
    "feed.text23": "Tüüp Otsingumootori ....",
    "feed.text24": "Otsi Kasutaja ...",
    "__2": "",
    "userInfo.text1": "kasutaja info",
    "userInfo.text2": "nimi",
    "userInfo.text3": "pilt",
    "userInfo.text4": "loomise kuupäev",
    "userInfo.text5": "Kas see ei ole probleem kustutada pilti täielikult?",
    "userInfo.text6": "Uus nimi ...",
    "userInfo.text7": "Pildifail peab olema jpg, jpeg, png või faili, ja vähem kui 1 MB",
    "userInfo.text8": "Dark režiim",
    "userInfo.text9": "umbes Kasutaja",
    "userInfo.text10": "kohta lisateavet, kirjeldus bio jne ... (vähem kui 300 tähemärki)",
    "userInfo.text11": "keel",
    "userInfo.text12": "muutus",
    "userInfo.text13": "teade",
    "__3": "",
    "auth.text1": "Sinu email",
    "auth.text2": "Sinu nimi",
    "auth.text3": "parool",
    "auth.text4": "Parool peaks olema 8 kuni 20 tähemärki, vähemalt ühe suurtähe üks väiketäht, üks number ja üks erimärk (@ $!% *? &).",
    "auth.text5": "Registreeri",
    "auth.text6": "Logi sisse",
    "auth.text7": "unusta Parool",
    "auth.text8": "registreerumisleht",
    "auth.text9": "E-posti aadress parooli lähtestamise",
    "auth.text10": "saada email",
    "auth.text11": "Valideerimine nurjus. Veenduge, et e-posti aadressi ei kasutata veel!",
    "auth.text12": "Kasutaja sisselogimine ebaõnnestus!",
    "auth.text13": "Vale parool sisestatud",
    "auth.text14": "Sisestatud e-posti aadressi ei leitud",
    "auth.text15": "Kasutaja loomine ebaõnnestus!",
    "auth.text16": "E-posti aadress on juba kasutusel teise konto",
    "auth.text17": "Näita Sisestatud parool",
    "auth.text18": "Peida parool",
    "auth.text19": "e-posti salasõna ülejäänud saadeti",
    "auth.text20": "Enterd e ei leitud",
    "auth.text21": "Parooli lähtestamine edu",
    "auth.text22": "Parooli lähtestamine nurjus, palun mine sisselogimise lehele ja saata e-posti parooli lähtestamise uuesti",
    "auth.text23": "Parooli lähtestamine",
    "auth.text24": "uus salasõna",
    "auth.text25": "parooli lähtestamine",
    "auth.text26": "Page on kehtetu või parool on juba muutunud",
    "auth.text27": "Meili saatmine parooli reset ebaõnnestus, kontrollige e-posti aadress",
    "auth.text28": "Meilikinnitus lehekülg",
    "auth.text29": "Meilikinnitus edu, mine sisselogimise lehele eespool näidatud",
    "auth.text30": "Saatke nurjus. See leht on lõppenud või kontrollimine on juba lõppenud.",
    "auth.text31": "Kontrollimine Mail saadeti. Plese kontrollige oma e-posti ja kontrollida. (Ka Chack rämpsposti mail)",
    "auth.text32": "E-posti kinnitus on vajalik sisselogimine. Palun saatke Saatke kontrollimiseks oma e-posti aadress.",
    "auth.text33": "Saada kiri kontrollimiseks",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "sööt",
    "nav.text2": "Logi sisse",
    "nav.text3": "Registreeri",
    "nav.text4": "kasutaja Info",
    "nav.text5": "Logi välja",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Lehte ei leitud",
    "notFound.text2": "mine sööda lehekülg",
    "notFound.text3": "mine sisselogimise lehele",
    "notFound.text4": "Kui sa lähed täna?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "tühistama",
    "comment.text2": "Kustuta",
    "comment.text3": "Postita kommentaar",
    "comment.text4": "Näita Vasta",
    "comment.text5": "kogusumma",
    "comment.text6": "Kirjutage vastus",
    "comment.text7": "Peida Vasta",
    "comment.text8": "Kommentaarid",
    "comment.text9": "Kas see ei ole probleem selle kommentaari kustutada ja vastused selle kommentaari täielikult?",
    "comment.text10": "Kas see ei ole probleem selle kommentaari kustutada täielikult?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Lisa Lemmik Post",
    "follow.text2": "Kustuta lemmik post",
    "follow.text3": "Lisa lemmik kasutaja",
    "follow.text4": "Kustuta lemmik kasutaja",
    "follow.text5": "näita postitusi",
    "follow.text6": "Teie Lemmik Kasutajad",
    "follow.text7": "Post Lemmik Kasutajad",
    "__11": "",
    "privacyPolicy.text1": "Privaatsuspoliitika",
    "privacyPolicy.text2": "See poliitika võib ajakohastada või muudetud ilma ette teatamata. See on vastutus kasutajal kursis privaatsuspoliitika muudatused. Arvestada teiste privaatsuse küsimusi, mis võivad mõjutada teid:",
    "privacyPolicy.text3": "See veebisait võib olla ohus.",
    "privacyPolicy.text4": "Teie arvuti võib olla ohus.",
    "privacyPolicy.text5": "See veebileht on majutatud Firebase ja DigitalOcean infrastruktuure. Nad võivad ja ei ole oma jälgimissüsteemid oma servereid. Need teenused on oma privaatsuse poliitikat ja need ei kuulu käesoleva privaatsuspoliitika.",
    "privacyPolicy.text6": "See veebileht salvestab e-posti, krüpteeritud kujul parool ja muud sisendid kasutajate võõrustas Firebase ja DigitalOcean infrastruktuure.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Kasutustingimused",
    "termsOfUse.text2": "privaatsus",
    "termsOfUse.text3": "Teie privaatsus on meile oluline. Palun lugege",
    "termsOfUse.text4": "privaatsuspoliitika",
    "termsOfUse.text5": "rohkem informatsiooni.",
    "termsOfUse.text6": "Oma kohustusi",
    "termsOfUse.text7": "Sa, kasutaja ainuisikuliselt vastutav selle eest oma seadustest ja maksud oma jurisdiktsiooni. Te olete ainuisikuliselt vastutav oma turvalisust.",
    "termsOfUse.text8": "Hoiatus garantii",
    "termsOfUse.text9": "See sait on ette nähtud \"NAGU ON\" ILMA GARANTIITA või TINGIMUSED tahes.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stopp Connect",
    "videoTalk.text3": "Online Kasutajad",
    "videoTalk.text4": "Alustage rääkida",
    "videoTalk.text5": "Teie üritavad rääkida",
    "videoTalk.text6": "Ükski teine ​​kasutaja online olemas",
    "videoTalk.text7": "Sa räägid",
    "videoTalk.text8": "Sa hakkasid rääkima",
    "videoTalk.text9": "Näita Tekst Talk",
    "videoTalk.text10": "tekstisisestus ....",
    "videoTalk.text11": "Sa ikka räägid. Kas soovite vaikida?",
    "videoTalk.text12": "Üleskutse kasutaja lahti",
    "videoTalk.text13": "kasutaja helistab teile. Kas olete nõus selle kõne?",
    "videoTalk.text14": "lükata oma kõne",
    "videoTalk.text15": "veebiotsinguteks kasutaja ...",
    "videoTalk.text16": "Otsi kasutaja ...",
    "__14": "",
    "groupTalk.text1": "looja",
    "groupTalk.text2": "kirjeldus",
    "groupTalk.text3": "loomise kuupäev",
    "groupTalk.text4": "Muuda rühma info",
    "groupTalk.text5": "Kas see ei ole probleem kustutada te selle grupi liikmed?",
    "groupTalk.text6": "Väljuvad liige",
    "groupTalk.text7": "Liitu grupiga",
    "groupTalk.text8": "Näita Grupi liikmed",
    "groupTalk.text9": "Kas soovite selle grupiga liituma?",
    "groupTalk.text10": "Grupi nimi",
    "groupTalk.text11": "Tagasi loetelu",
    "groupTalk.text12": "Kinnita oma panuse",
    "groupTalk.text13": "Loo grupp",
    "groupTalk.text14": "värskendusgrupi",
    "groupTalk.text15": "Väljumine Group",
    "groupTalk.text16": "Mine koduleht sisselogimiseks",
    "groupTalk.text17": "rühma nimi (5-100 tähemärki)",
    "groupTalk.text18": "Grupi kirjeldus (vähem kui 500 tähemärki)"
  },
  {
    "LANGUAGE": "Basque",
    "CODE": "eu",
    "test.text1": "Kaixo",
    "test.text2": "Hi",
    "test.text3": "Eskerrik asko",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Utzi",
    "general.text2": "Onartu",
    "general.text3": "Ezabatu",
    "general.text4": "Ezabatutako",
    "general.text5": "Editatu",
    "general.text6": "eguneratzearen",
    "general.text7": "Upload Image",
    "general.text8": "Ezabatu irudia",
    "general.text9": "Image",
    "general.text10": "feed",
    "general.text11": "Saioa hasi",
    "general.text12": "Izena eman",
    "general.text13": "erabiltzailearen informazioa",
    "general.text14": "Saioa amaitu",
    "general.text15": "fitxategia izan behar da",
    "general.text16": "eta baino gutxiago",
    "general.text17": "Pasahitza",
    "general.text18": "gogokoenak Mezuak",
    "general.text19": "Mezuak",
    "general.text20": "Irudiak",
    "general.text21": "Bideoak",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Erakutsi erabiltzailearen Mezuak",
    "feed.text3": "Show Mezuak",
    "feed.text4": "ikuspegi",
    "feed.text5": "editatu",
    "feed.text6": "ezabatu",
    "feed.text7": "Da post ezabatu erabat arazoa ez da?",
    "feed.text8": "Nork",
    "feed.text9": "sortua",
    "feed.text10": "Izenburua",
    "feed.text11": "Image",
    "feed.text12": "Edukia",
    "feed.text13": "publiko",
    "feed.text14": "pribatuak",
    "feed.text15": "post izenburua",
    "feed.text16": "Eduki por post",
    "feed.text17": "ezabatu",
    "feed.text18": "Da ezabatu irudia erabat arazoa ez?",
    "feed.text19": "Ezabatutako",
    "feed.text20": "Media File jpg, jpeg, png edo mp4 fitxategia izan behar du, eta 3 MB baino gutxiago",
    "feed.text21": "post publiko",
    "feed.text22": "post pribatuak",
    "feed.text23": "for Search Idatzi ....",
    "feed.text24": "Search Erabiltzaile ...",
    "__2": "",
    "userInfo.text1": "Erabiltzaile informazioa",
    "userInfo.text2": "izen",
    "userInfo.text3": "irudia",
    "userInfo.text4": "sorkuntza data",
    "userInfo.text5": "Da ezabatu irudia erabat arazoa ez?",
    "userInfo.text6": "New izena ...",
    "userInfo.text7": "Irudiaren fitxategia jpg, jpeg, edo png fitxategia izan behar du, eta 1MB baino gutxiago",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "About Erabiltzaileak",
    "userInfo.text10": "Zuri buruzko informazio gehigarria, deskribapena bio abar ... (300 karaktere baino gutxiago)",
    "userInfo.text11": "Hizkuntza",
    "userInfo.text12": "Aldaketa",
    "userInfo.text13": "Jakinarazpen",
    "__3": "",
    "auth.text1": "Zure emaila",
    "auth.text2": "Zure izena",
    "auth.text3": "Pasahitza",
    "auth.text4": "Pasahitza 8 eta 20 karaktere, maiuskulaz gutxienez, gutun, minuskula bat letra, zenbaki bat eta karaktere berezi bat izan behar du (@ $%! *? &).",
    "auth.text5": "Izena eman",
    "auth.text6": "Saioa hasi",
    "auth.text7": "ahaztu pasahitza",
    "auth.text8": "erregistratzeko orria",
    "auth.text9": "E-posta pasahitza berrezartzeko",
    "auth.text10": "bidali E-mail",
    "auth.text11": "Validation huts egin du. Ziurtatu e-posta helbidea ez da oraindik erabili!",
    "auth.text12": "Saioaren hasiera huts egin du!",
    "auth.text13": "Pasahitza baliogabea sartu zen",
    "auth.text14": "Sartutako helbide elektronikoa ez da aurkitu",
    "auth.text15": "Erabiltzaile sortzea huts egin du!",
    "auth.text16": "e-posta helbidea dagoeneko erabileran beste kontu arabera",
    "auth.text17": "Erakutsi Sartutako pasahitza",
    "auth.text18": "ezkutatu pasahitza",
    "auth.text19": "pasahitza atseden-posta bidali zen",
    "auth.text20": "Enterd elektronikoa ez da aurkitu",
    "auth.text21": "Pasahitza berrezarri arrakasta",
    "auth.text22": "Pasahitza berrezarri huts egin du, mesedez Login orria eta bidali email pasahitza berrezarri berriro",
    "auth.text23": "Pasahitza berrezarri orria",
    "auth.text24": "pasahitz berria",
    "auth.text25": "Berrezarri pasahitza",
    "auth.text26": "Page baliogabea da edo pasahitza jada aldatu da",
    "auth.text27": "mezu elektroniko bat bidaltzea pasahitza berrezartzeko huts egin du, check-posta helbidea",
    "auth.text28": "E-posta egiaztatze orria",
    "auth.text29": "Emaila arrakasta egiaztatzea, zoaz erakutsia saio hasiera orrira",
    "auth.text30": "E-posta egiaztatze huts egin du. Orrialde hau amaitu egin da, edo egiaztapen dagoeneko bukatuta.",
    "auth.text31": "Egiaztapen Mail bidali da. Plese egiaztatu zure helbide elektronikoa eta egiaztatu. (Ere spam mail in egiaztatzea)",
    "auth.text32": "Posta elektronikoa egiaztatzeko Login to beharrezkoa da. Mesedez, bidali mezu elektroniko egiaztatzeko zure helbide elektronikora.",
    "auth.text33": "Bidali posta egiaztatzeko",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "feed",
    "nav.text2": "Saioa hasi",
    "nav.text3": "Izena eman",
    "nav.text4": "erabiltzailearen informazioa",
    "nav.text5": "Saioa amaitu",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Ez da orria aurkitu",
    "notFound.text2": "feed orrira",
    "notFound.text3": "login orrira",
    "notFound.text4": "Non egiten gaur joan?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Utzi",
    "comment.text2": "Ezabatu",
    "comment.text3": "Argitaratu iruzkina",
    "comment.text4": "Show erantzun",
    "comment.text5": "guztira",
    "comment.text6": "Idatzi erantzun",
    "comment.text7": "ezkutatu erantzun",
    "comment.text8": "Oharrak",
    "comment.text9": "Da iruzkin hau eta erantzunak iruzkin hau erabat ezabatu ez dago arazorik?",
    "comment.text10": "Da iruzkin hau erabat ezabatu ez dago arazorik?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Gogokoenak Post gehitu",
    "follow.text2": "post gogoko ezabatu",
    "follow.text3": "Erabiltzaile gogoko gehitu",
    "follow.text4": "Erabiltzaile gogoko ezabatu",
    "follow.text5": "ikuskizuna mezu",
    "follow.text6": "Zure gogoko erabiltzaileak",
    "follow.text7": "Post gogokoenak erabiltzaileak",
    "__11": "",
    "privacyPolicy.text1": "Privacy Policy",
    "privacyPolicy.text2": "Politika hori eguneratu ahal izango dira, edo aldez aurretik abisatu gabe berrikusi. Egonaldia Erabiltzaile pribatutasun politika aldaketak informatu ardura da. Hartu beste pribatutasun gaiak eragina izan dezaketen ohar:",
    "privacyPolicy.text3": "Webgune bortxatu dute.",
    "privacyPolicy.text4": "Zure ordenagailua arriskuan egon liteke.",
    "privacyPolicy.text5": "Webgune hau Firebase eta DigitalOcean azpiegitura ostatutako. Ahal izango dute eta ez dute beren jarraipena sistemak beren zerbitzarietan. zerbitzu horiek euren pribatutasun politikak dute eta ez dira pribatutasun politika honetan sartzen.",
    "privacyPolicy.text6": "Webgune dendetan elektroniko hau, pasahitza forma sinatuko, eta beste erabiltzaileen sarrera ostatutako Firebase eta DigitalOcean azpiegitura berean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Erabilera baldintzak",
    "termsOfUse.text2": "pribatutasuna",
    "termsOfUse.text3": "Zure pribatutasuna garrantzitsua da guretzat. Irakurri",
    "termsOfUse.text4": "pribatutasun politika",
    "termsOfUse.text5": "Informazio gehiago eskuratzeko.",
    "termsOfUse.text6": "Zure erantzukizunak propioak",
    "termsOfUse.text7": "You, erabiltzaileak, dira soilik zure betetze propioak lege eta zergak zure jurisdikzio batera bermatu. Soilik zure segurtasun propioa erantzule.",
    "termsOfUse.text8": "Bermea oharra",
    "termsOfUse.text9": "gune honek aukera ematen digu \"DA\" BASIS, berme edo INOLAKO baldintzarik gabe.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "start konektatu",
    "videoTalk.text2": "Stop konektatu",
    "videoTalk.text3": "Online erabiltzaileak",
    "videoTalk.text4": "Hasi hitz egin",
    "videoTalk.text5": "Zure eztabaida saiatzen ari",
    "videoTalk.text6": "Ez dago beste online erabiltzaile existitzen",
    "videoTalk.text7": "to You hitz egiten ari",
    "videoTalk.text8": "hitz egin hasi duzu",
    "videoTalk.text9": "Show Text Eztabaida",
    "videoTalk.text10": "testua idazteko ....",
    "videoTalk.text11": "oraindik hizketan ari zara. Ez hizketan gelditu nahi duzula?",
    "videoTalk.text12": "Deiak erabiltzaile deskonektatu",
    "videoTalk.text13": "Erabiltzaile deituz. Ez dei hau onartzen duzu?",
    "videoTalk.text14": "ukatzen deien",
    "videoTalk.text15": "bilatu online erabiltzaile ...",
    "videoTalk.text16": "bilatu Erabiltzaile ...",
    "__14": "",
    "groupTalk.text1": "sortzailea",
    "groupTalk.text2": "deskribapena",
    "groupTalk.text3": "sorkuntza data",
    "groupTalk.text4": "Aldatu taldearen informazioa",
    "groupTalk.text5": "Da ez dago arazorik dituzu ezabatu talde honen kideak from?",
    "groupTalk.text6": "Kideetako Utzi",
    "groupTalk.text7": "Parte Group",
    "groupTalk.text8": "Show Taldeko kideak",
    "groupTalk.text9": "Ez Group honetan parte hartu nahi duzu?",
    "groupTalk.text10": "Taldearen izena",
    "groupTalk.text11": "Back-zerrendan",
    "groupTalk.text12": "Berretsi zure sarrera",
    "groupTalk.text13": "Sortu taldea",
    "groupTalk.text14": "Eguneratu taldea",
    "groupTalk.text15": "Group irten",
    "groupTalk.text16": "Hasiera joan Login to",
    "groupTalk.text17": "taldearen izena (5-100 karaktere)",
    "groupTalk.text18": "Taldeko azalpena (500 karaktere baino gutxiago)"
  },
  {
    "LANGUAGE": "Farsi",
    "CODE": "fa",
    "test.text1": "سلام",
    "test.text2": "سلام",
    "test.text3": "متشکرم",
    "test.text4": "نقشه",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "لغو کردن",
    "general.text2": "تایید کنید",
    "general.text3": "حذف",
    "general.text4": "حذف شده",
    "general.text5": "ویرایش",
    "general.text6": "به روز رسانی",
    "general.text7": "آپلود تصویر",
    "general.text8": "حذف تصویر",
    "general.text9": "تصویر",
    "general.text10": "خوراک",
    "general.text11": "وارد شدن",
    "general.text12": "ثبت نام",
    "general.text13": "اطلاعات کاربر",
    "general.text14": "خروج",
    "general.text15": "فایل باید",
    "general.text16": "و کمتر از",
    "general.text17": "کلمه عبور",
    "general.text18": "پست مورد علاقه",
    "general.text19": "نوشته ها",
    "general.text20": "تصاویر",
    "general.text21": "فیلم های",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "مطلب جدید",
    "feed.text2": "نمایش ارسال های کاربر",
    "feed.text3": "نمایش پست ها",
    "feed.text4": "چشم انداز",
    "feed.text5": "ویرایش",
    "feed.text6": "حذف",
    "feed.text7": "است هیچ مشکلی برای حذف پست به طور کامل؟",
    "feed.text8": "ارسال شده توسط",
    "feed.text9": "خلق شده توسط",
    "feed.text10": "عنوان",
    "feed.text11": "تصویر",
    "feed.text12": "محتوا",
    "feed.text13": "عمومی",
    "feed.text14": "خصوصی",
    "feed.text15": "عنوان پست",
    "feed.text16": "پست پور محتوا",
    "feed.text17": "حذف",
    "feed.text18": "آیا هیچ مشکلی برای حذف تصویر به طور کامل؟",
    "feed.text19": "حذف شده",
    "feed.text20": "فایل های رسانه ای باید JPG، JPEG، PNG و یا فایل های MP4، و کمتر از 3MB",
    "feed.text21": "پست های عمومی",
    "feed.text22": "پست خصوصی",
    "feed.text23": "نوع برای جستجو ....",
    "feed.text24": "جستجو کاربر ...",
    "__2": "",
    "userInfo.text1": "اطلاعات کاربر",
    "userInfo.text2": "نام",
    "userInfo.text3": "تصویر",
    "userInfo.text4": "تاریخ ایجاد",
    "userInfo.text5": "آیا هیچ مشکلی برای حذف تصویر به طور کامل؟",
    "userInfo.text6": "نام جدید ...",
    "userInfo.text7": "فایل تصویری باید JPG، JPEG یا PNG باشد، و کمتر از 1MB",
    "userInfo.text8": "حالت تیره",
    "userInfo.text9": "کاربر درباره",
    "userInfo.text10": "اطلاعات بیشتر در مورد شما، زیستی توضیحات و غیره ... (کمتر از 300 حرف)",
    "userInfo.text11": "زبان",
    "userInfo.text12": "تغییر دادن",
    "userInfo.text13": "اطلاع",
    "__3": "",
    "auth.text1": "ایمیل شما",
    "auth.text2": "اسم شما",
    "auth.text3": "کلمه عبور",
    "auth.text4": "رمز عبور باید 8 تا 20 کاراکتر، حداقل یک حرف بزرگ، یک حرف کوچک، یک عدد و یک کاراکتر خاص (@ $!٪ *؟ و).",
    "auth.text5": "ثبت نام",
    "auth.text6": "وارد شدن",
    "auth.text7": "فراموشی رمز عبور",
    "auth.text8": "ثبت نام صفحه",
    "auth.text9": "آدرس پست الکترونیک برای تنظیم مجدد رمز عبور",
    "auth.text10": "ایمیل بفرست",
    "auth.text11": "اعتبارسنجی انجام نشد. اطمینان حاصل کنید که آدرس ایمیل استفاده نشده است!",
    "auth.text12": "ورود کاربر شکست خورده!",
    "auth.text13": "رمز عبور نامعتبر وارد شده",
    "auth.text14": "آدرس پست الکترونیک وارد شده یافت نشد",
    "auth.text15": "ایجاد کاربر شکست خورده!",
    "auth.text16": "آدرس ایمیل در حال حاضر در حال استفاده توسط حساب دیگر",
    "auth.text17": "نمایش رمز عبور وارد شده",
    "auth.text18": "مخفی کردن رمز عبور",
    "auth.text19": "ایمیل برای استراحت رمز عبور ارسال شد",
    "auth.text20": "ایمیل Enterd یافت نشد",
    "auth.text21": "موفقیت تنظیم مجدد رمز عبور",
    "auth.text22": "تنظیم مجدد رمز عبور شکست خورده، لطفا به صفحه ورود و ارسال ایمیل برای تنظیم مجدد رمز عبور دوباره",
    "auth.text23": "صفحه تنظیم مجدد رمز عبور",
    "auth.text24": "رمز عبور جدید",
    "auth.text25": "بازیابی رمز عبور",
    "auth.text26": "صفحه نامعتبر یا رمز عبور است در حال حاضر تغییر",
    "auth.text27": "ارسال ایمیل برای تنظیم مجدد رمز عبور ناموفق بود، لطفا آدرس ایمیل چک",
    "auth.text28": "صفحه تأیید ایمیل",
    "auth.text29": "ایمیل تأیید موفقیت، لطفا به صفحه ورود در بالا نشان داده رفتن",
    "auth.text30": "تأیید ایمیل شکست خورده است. این صفحه منقضی شده است یا تأیید قبلا به پایان رسید.",
    "auth.text31": "ایمیل تأیید فرستاده شد. plese با ایمیل خود را چک کنید و بررسی کنید. (همچنین در هرزنامه chack)",
    "auth.text32": "تأیید ایمیل به ورود مورد نیاز است. لطفا ایمیل را برای تأیید به آدرس ایمیل شما ارسال شود.",
    "auth.text33": "ارسال ایمیل برای تأیید صحت",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "خوراک",
    "nav.text2": "وارد شدن",
    "nav.text3": "ثبت نام",
    "nav.text4": "اطلاعات کاربر",
    "nav.text5": "خروج",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "صفحه یافت نشد",
    "notFound.text2": "رفتن به صفحه خوراک",
    "notFound.text3": "رفتن به صفحه ورود",
    "notFound.text4": "کجا امروز شما برود؟",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "لغو کردن",
    "comment.text2": "حذف",
    "comment.text3": "ارسال نظر",
    "comment.text4": "نمایش پاسخ",
    "comment.text5": "جمع",
    "comment.text6": "نوشتن پاسخ",
    "comment.text7": "مخفی کردن پاسخ",
    "comment.text8": "نظرات",
    "comment.text9": "است آن را بدون مشکل این نظر را حذف و پاسخ از این نظر به طور کامل؟",
    "comment.text10": "است آن را بدون مشکل این نظر را حذف به طور کامل؟",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "اضافه کردن به پست های مورد علاقه",
    "follow.text2": "حذف از پست های مورد علاقه",
    "follow.text3": "اضافه کردن به مورد علاقه کاربران",
    "follow.text4": "حذف از کاربر مورد علاقه",
    "follow.text5": "پست ها نمایش",
    "follow.text6": "کاربران مورد علاقه خود",
    "follow.text7": "کاربر پست مورد علاقه",
    "__11": "",
    "privacyPolicy.text1": "سیاست حفظ حریم خصوصی",
    "privacyPolicy.text2": "این سیاست ممکن است به روز و یا تجدید نظر بدون اطلاع قبلی. این مسئولیت از کاربر را به ماندن در مورد تغییرات سیاست حفظ حریم خصوصی است. توجه داشته باشید از مسائل خصوصی دیگر که ممکن است شما را تحت تاثیر قرار:",
    "privacyPolicy.text3": "این وب سایت ممکن است به خطر بیافتد.",
    "privacyPolicy.text4": "کامپیوتر شما ممکن است به خطر بیافتد.",
    "privacyPolicy.text5": "این وبسایت در زیرساخت فایربیس و DigitalOcean میزبانی. آنها ممکن است و لازم سیستم های ردیابی خود را بر روی سرور خود. این خدمات سیاست حفظ حریم خصوصی خود و آنها توسط این سیاست حفظ حریم خصوصی تحت پوشش نیست.",
    "privacyPolicy.text6": "این ایمیل های وب سایت، به صورت رمز شده از رمز عبور، و سایر ورودی های کاربران در میزبانی زیرساخت فایربیس و DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "شرایط استفاده",
    "termsOfUse.text2": "حریم خصوصی",
    "termsOfUse.text3": "حریم خصوصی شما برای ما مهم است. لطفا برای خواندن",
    "termsOfUse.text4": "سیاست حفظ حریم خصوصی",
    "termsOfUse.text5": "برای اطلاعات بیشتر.",
    "termsOfUse.text6": "مسئولیت خود را",
    "termsOfUse.text7": "شما، کاربر، به تنهایی مسئول برای حصول اطمینان از انطباق خود را با قوانین و مالیات در صلاحیت خود را. شما تنها مسئول امنیتی خود می باشد.",
    "termsOfUse.text8": "سلب مسئولیت گارانتی",
    "termsOfUse.text9": "این سایت بر روی \"AS IS\" و بدون ضمانت یا شرایط از هر نوع.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "شروع اتصال",
    "videoTalk.text2": "توقف اتصال",
    "videoTalk.text3": "کاربران آنلاین",
    "videoTalk.text4": "شروع به صحبت",
    "videoTalk.text5": "شما در حال تلاش برای بحث",
    "videoTalk.text6": "هیچ کاربری آنلاین دیگر وجود دارد",
    "videoTalk.text7": "شما در حال صحبت کردن به",
    "videoTalk.text8": "شما شروع به صحبت",
    "videoTalk.text9": "نشان می دهد بحث متن",
    "videoTalk.text10": "ورود متن ....",
    "videoTalk.text11": "شما هنوز به صحبت کردن. آیا شما می خواهید برای جلوگیری از صحبت؟",
    "videoTalk.text12": "تماس کاربر قطع",
    "videoTalk.text13": "کاربران تماس با شما. آیا این پاسخ به شما رجوع؟",
    "videoTalk.text14": "رد تماس خود را",
    "videoTalk.text15": "جستجو آنلاین برای کاربران ...",
    "videoTalk.text16": "جستجو کاربران ...",
    "__14": "",
    "groupTalk.text1": "ایجاد کننده",
    "groupTalk.text2": "شرح",
    "groupTalk.text3": "تاریخ ایجاد",
    "groupTalk.text4": "ویرایش اطلاعات گروه",
    "groupTalk.text5": "است آن را بدون مشکل به شما حذف از اعضای این گروه؟",
    "groupTalk.text6": "ترک از کاربران",
    "groupTalk.text7": "اضافه کردن گروه",
    "groupTalk.text8": "نمایش گروه کاربران",
    "groupTalk.text9": "آیا شما می خواهید برای پیوستن به این گروه؟",
    "groupTalk.text10": "اسم گروه",
    "groupTalk.text11": "بازگشت به لیست",
    "groupTalk.text12": "اعلام ورودی خود را",
    "groupTalk.text13": "ایجاد گروه",
    "groupTalk.text14": "به روز رسانی گروه",
    "groupTalk.text15": "خروج از گروه",
    "groupTalk.text16": "برو به صفحه خانگی به ورود",
    "groupTalk.text17": "نام گروه (5-100 کاراکتر)",
    "groupTalk.text18": "شرح گروه (کمتر از 500 حرف)"
  },
  {
    "LANGUAGE": "Finnish",
    "CODE": "fi",
    "test.text1": "Hei",
    "test.text2": "Moi",
    "test.text3": "Kiitos",
    "test.text4": "kartta",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Peruuttaa",
    "general.text2": "Hyväksyä",
    "general.text3": "Poistaa",
    "general.text4": "Poistettu",
    "general.text5": "Muokata",
    "general.text6": "Päivittää",
    "general.text7": "Lataa kuva",
    "general.text8": "kuvan poistaminen",
    "general.text9": "Kuva",
    "general.text10": "rehu",
    "general.text11": "Kirjaudu sisään",
    "general.text12": "Kirjaudu",
    "general.text13": "käyttäjätiedot",
    "general.text14": "Kirjautua ulos",
    "general.text15": "Tiedoston tulee olla",
    "general.text16": "ja alle",
    "general.text17": "Salasana",
    "general.text18": "suosikki Viestit",
    "general.text19": "Viestejä",
    "general.text20": "kuvat",
    "general.text21": "Videot",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "uusi viesti",
    "feed.text2": "Näytä käyttäjän viestit",
    "feed.text3": "Näytä kirjoitukset",
    "feed.text4": "näkymä",
    "feed.text5": "muokata",
    "feed.text6": "poistaa",
    "feed.text7": "Ei ole ongelmaa Poista viesti kokonaan?",
    "feed.text8": "lähettänyt",
    "feed.text9": "Luonut",
    "feed.text10": "otsikko",
    "feed.text11": "Kuva",
    "feed.text12": "Sisältö",
    "feed.text13": "julkinen",
    "feed.text14": "yksityinen",
    "feed.text15": "Otsikko post",
    "feed.text16": "Sisällön por post",
    "feed.text17": "poistaa",
    "feed.text18": "Onko mitään ongelmaa poistan kuvan kokonaan?",
    "feed.text19": "Poistettu",
    "feed.text20": "-Mediatiedosto pitäisi olla jpg, jpeg, png tai mp4, ja alle 3 Mt",
    "feed.text21": "julkinen viesti",
    "feed.text22": "yksityispostia",
    "feed.text23": "Kirjoita Search ....",
    "feed.text24": "Etsi käyttäjän ...",
    "__2": "",
    "userInfo.text1": "Käyttäjän tiedot",
    "userInfo.text2": "nimi",
    "userInfo.text3": "kuva",
    "userInfo.text4": "luomispäivä",
    "userInfo.text5": "Onko mitään ongelmaa poistan kuvan kokonaan?",
    "userInfo.text6": "Uusi nimi ...",
    "userInfo.text7": "Kuvatiedoston on oltava jpg, jpeg, ja PNG, ja alle 1 Mt",
    "userInfo.text8": "tumma tila",
    "userInfo.text9": "Tietoja Käyttäjä",
    "userInfo.text10": "lisätietoja sinusta, kuvaus bio jne ... (alle 300 merkkiä)",
    "userInfo.text11": "Kieli",
    "userInfo.text12": "Muuttaa",
    "userInfo.text13": "ilmoitus",
    "__3": "",
    "auth.text1": "Sähköpostisi",
    "auth.text2": "Sinun nimesi",
    "auth.text3": "Salasana",
    "auth.text4": "Salasanan tulisi olla 8-20 merkkiä, vähintään yksi iso kirjain, yksi pieni kirjain, yksi numero ja jokin erikoismerkki (@ $!% *? &).",
    "auth.text5": "Kirjaudu",
    "auth.text6": "Kirjaudu sisään",
    "auth.text7": "Unohtaa salasana",
    "auth.text8": "rekisteröitymissivulle",
    "auth.text9": "Sähköpostiosoite varten salasanan",
    "auth.text10": "lähettää sähköpostia",
    "auth.text11": "Vahvistus epäonnistui. Varmista, että sähköpostiosoitetta ei käytetä vielä!",
    "auth.text12": "Käyttäjänimi epäonnistui!",
    "auth.text13": "Virheellinen salasana merkittiin",
    "auth.text14": "Tuli sähköpostiosoitetta ei löydy",
    "auth.text15": "Käyttäjän luonti epäonnistui!",
    "auth.text16": "Sähköpostiosoite on jo toisen tilin käytössä",
    "auth.text17": "Näytä annettua salasanaa",
    "auth.text18": "Piilota salasana",
    "auth.text19": "sähköpostin salasanaa levätä lähetettiin",
    "auth.text20": "Enterd sähköposti ei löytynyt",
    "auth.text21": "Salasanan vaihto menestys",
    "auth.text22": "Salasanan vaihto epäonnistui, siirry kirjautumissivulle ja lähettää sähköpostia salasanan uudelleen",
    "auth.text23": "Salasanan vaihto sivulla",
    "auth.text24": "uusi salasana",
    "auth.text25": "Nollaa salasana",
    "auth.text26": "Sivu ei kelpaa tai salasana on jo muuttunut",
    "auth.text27": "Lähettämällä sähköpostitse salasanan epäonnistuu, tarkista sähköpostiosoite",
    "auth.text28": "Sähköpostiosoitteen vahvistussivulle",
    "auth.text29": "Sähköpostivahvistusta menestys, mene kirjautumissivulle yllä",
    "auth.text30": "Sähköposti epäonnistui. Tämä sivu on vanhentunut tai vahvistus on jo päättynyt.",
    "auth.text31": "Vahvistusviesti lähetettiin. Plese tarkistaa sähköpostisi ja tarkistaa. (Myös chack in roskapostin)",
    "auth.text32": "Sähköposti Tarkastus vaaditaan kirjautuminen. Lähetä sähköposti tarkastettavaksi sähköpostiisi.",
    "auth.text33": "Lähetä sähköposti vahvistusta",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "rehu",
    "nav.text2": "Kirjaudu sisään",
    "nav.text3": "Kirjaudu",
    "nav.text4": "käyttäjätiedot",
    "nav.text5": "Kirjautua ulos",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Sivua ei löydetty",
    "notFound.text2": "siirry syötteen sivulle",
    "notFound.text3": "siirry kirjautumissivulle",
    "notFound.text4": "Minne menet tänään?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Peruuttaa",
    "comment.text2": "Poistaa",
    "comment.text3": "Lähetä Kommentti",
    "comment.text4": "Näytä vastaus",
    "comment.text5": "kaikki yhteensä",
    "comment.text6": "Kirjoita vastaus",
    "comment.text7": "Piilota Vastaa",
    "comment.text8": "Kommentit",
    "comment.text9": "Onko mitään ongelmaa poistaa tämän kommentin ja vastaukset tämän kommentin kokonaan?",
    "comment.text10": "Onko mitään ongelmaa poistaa tämän kommentin kokonaan?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Suosikkilistalle Lähetä",
    "follow.text2": "Poista suosikki virkaan",
    "follow.text3": "Lisää suosikkeihin käyttäjälle",
    "follow.text4": "Poista suosikki käyttäjältä",
    "follow.text5": "Näytä kirjoitukset",
    "follow.text6": "Suosikkikäyttäjäsi",
    "follow.text7": "Post Suosikkikäyttäjät",
    "__11": "",
    "privacyPolicy.text1": "Tietosuojakäytäntö",
    "privacyPolicy.text2": "Tätä politiikkaa voidaan päivittää tai uudistetut ilman erillistä ilmoitusta. Se on käyttäjän vastuulla ja pysyä ajan tasalla tietosuojakäytäntöä muutoksista. Huomioitava muut tietosuojaongelmia, jotka saattavat vaikuttaa sinuun:",
    "privacyPolicy.text3": "Tämä sivusto saattaa vaarantua.",
    "privacyPolicy.text4": "Tietokone saattaa vaarantua.",
    "privacyPolicy.text5": "Tämä sivusto on isännöi Firebase ja DigitalOcean infrastruktuureja. Ne voivat ja eivät omat seurantajärjestelmät niiden palvelimet. Nämä palvelut on omat tietosuojakäytännöt ja ne eivät kuulu tämän yksityisyyden suoja.",
    "privacyPolicy.text6": "Tämä sivusto myymälöissä sähköpostin, salattu muoto salasanan ja muut panokset käyttäjien isännöi Firebase ja DigitalOcean infrastruktuureja.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Käyttöehdot",
    "termsOfUse.text2": "yksityisyys",
    "termsOfUse.text3": "Asiakkaiden tietosuoja on meille tärkeää. Lue",
    "termsOfUse.text4": "Tietosuojakäytäntö",
    "termsOfUse.text5": "Lisätietoja.",
    "termsOfUse.text6": "Omat vastuut",
    "termsOfUse.text7": "Te, käyttäjä, ovat yksin vastuussa siitä oman lakien ja verojen hallintoalueellasi. Olet yksin vastuussa omasta turvallisuudesta.",
    "termsOfUse.text8": "Takuuta",
    "termsOfUse.text9": "Tällä sivustolla tarjotaan \"SELLAISENAAN\", ILMAN TAI EHDOT MINKÄÄNLAISTA.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "käynnistys Kytke",
    "videoTalk.text2": "Lopeta Connect",
    "videoTalk.text3": "Online käyttäjät",
    "videoTalk.text4": "Aloita puhua",
    "videoTalk.text5": "Omat yrittävät puhua",
    "videoTalk.text6": "Mikään muu verkossa käyttäjä on olemassa",
    "videoTalk.text7": "Puhut",
    "videoTalk.text8": "Aloit puhua",
    "videoTalk.text9": "Näytäteksti Talk",
    "videoTalk.text10": "tekstinsyöttö ....",
    "videoTalk.text11": "Olet vielä puhua. Haluatko lopettaa puhumisen?",
    "videoTalk.text12": "Kutsuva käyttäjä irrotettu",
    "videoTalk.text13": "käyttäjä soittaa sinulle. Hyväksytkö tämän puhelun?",
    "videoTalk.text14": "hylkää puhelun",
    "videoTalk.text15": "hakea verkossa käyttäjä ...",
    "videoTalk.text16": "search käyttäjä ...",
    "__14": "",
    "groupTalk.text1": "luoja",
    "groupTalk.text2": "Kuvaus",
    "groupTalk.text3": "luomispäivä",
    "groupTalk.text4": "Muokkaa ryhmän tietoja",
    "groupTalk.text5": "Onko mitään ongelmaa poistaa teille tämän ryhmän jäseniä?",
    "groupTalk.text6": "Lähtevät jäsen",
    "groupTalk.text7": "Liittyä ryhmään",
    "groupTalk.text8": "Näytä Ryhmän jäsenet",
    "groupTalk.text9": "Haluatko liittyä ryhmään?",
    "groupTalk.text10": "Ryhmän nimi",
    "groupTalk.text11": "Takaisin listalle",
    "groupTalk.text12": "Vahvista syöte",
    "groupTalk.text13": "Luo ryhmä",
    "groupTalk.text14": "Päivitä ryhmä",
    "groupTalk.text15": "Irtautuminen Group",
    "groupTalk.text16": "Mene kotisivu kirjautumisruuttuun",
    "groupTalk.text17": "ryhmän nimi (5-100 merkkiä)",
    "groupTalk.text18": "ryhmä kuvaus (alle 500 merkkiä)"
  },
  {
    "LANGUAGE": "Faroese",
    "CODE": "fo",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "French",
    "CODE": "fr",
    "test.text1": "Bonjour",
    "test.text2": "salut",
    "test.text3": "Je vous remercie",
    "test.text4": "carte",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Annuler",
    "general.text2": "J'accepte",
    "general.text3": "Effacer",
    "general.text4": "supprimé",
    "general.text5": "Éditer",
    "general.text6": "Mise à jour",
    "general.text7": "Envoyer une image",
    "general.text8": "Supprimer l'image",
    "general.text9": "Image",
    "general.text10": "Alimentation",
    "general.text11": "S'identifier",
    "general.text12": "S'inscrire",
    "general.text13": "informations utilisateur",
    "general.text14": "Se déconnecter",
    "general.text15": "fichier doit être",
    "general.text16": "et moins",
    "general.text17": "Mot de passe",
    "general.text18": "messages favoris",
    "general.text19": "Des postes",
    "general.text20": "Images",
    "general.text21": "Vidéos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nouveau poste",
    "feed.text2": "Afficher les messages de l'utilisateur",
    "feed.text3": "Afficher les messages",
    "feed.text4": "vue",
    "feed.text5": "Éditer",
    "feed.text6": "effacer",
    "feed.text7": "Est-ce est pas de problème pour supprimer le message complètement?",
    "feed.text8": "posté par",
    "feed.text9": "Créé par",
    "feed.text10": "Titre",
    "feed.text11": "Image",
    "feed.text12": "Contenu",
    "feed.text13": "Publique",
    "feed.text14": "privé",
    "feed.text15": "Titre de poste",
    "feed.text16": "après le contenu de por",
    "feed.text17": "effacer",
    "feed.text18": "Est-il pas de problème de supprimer complètement l'image?",
    "feed.text19": "supprimé",
    "feed.text20": "Fichier des médias doit être jpg, jpeg, png ou fichier mp4, et moins de 3 Mo",
    "feed.text21": "postal public",
    "feed.text22": "après privé",
    "feed.text23": "Tapez Recherche ....",
    "feed.text24": "Recherche utilisateur ...",
    "__2": "",
    "userInfo.text1": "informations de l'utilisateur",
    "userInfo.text2": "Nom",
    "userInfo.text3": "image",
    "userInfo.text4": "date de création",
    "userInfo.text5": "Est-il pas de problème de supprimer complètement l'image?",
    "userInfo.text6": "Nouveau nom ...",
    "userInfo.text7": "fichier image doit être jpg, jpeg ou png, et moins de 1 Mo",
    "userInfo.text8": "dark mode",
    "userInfo.text9": "A propos de l'utilisateur",
    "userInfo.text10": "plus d'informations sur vous, la description bio etc ... (moins de 300 caractères)",
    "userInfo.text11": "Langue",
    "userInfo.text12": "Changement",
    "userInfo.text13": "Notification",
    "__3": "",
    "auth.text1": "Votre adresse e-mail",
    "auth.text2": "votre nom",
    "auth.text3": "Mot de passe",
    "auth.text4": "Mot de passe doit être de 8 à 20 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial (@ $!% * &).",
    "auth.text5": "S'inscrire",
    "auth.text6": "S'identifier",
    "auth.text7": "Mot de passe oublié",
    "auth.text8": "Inscription page",
    "auth.text9": "Adresse e-mail pour le mot de passe remis à zéro",
    "auth.text10": "envoyez un e-mail",
    "auth.text11": "Validation échouée. Assurez-vous que l'adresse e-mail est pas encore utilisé!",
    "auth.text12": "Connexion utilisateur a échoué!",
    "auth.text13": "Mot de passe incorrect a été saisi",
    "auth.text14": "Adresse e-mail indiquée n'a pas été trouvé",
    "auth.text15": "Création d'un utilisateur a échoué!",
    "auth.text16": "L'adresse e-mail est déjà utilisé par un autre compte",
    "auth.text17": "Afficher Entrée Mot de passe",
    "auth.text18": "Cacher passe",
    "auth.text19": "email pour le repos de mot de passe a été envoyé",
    "auth.text20": "email enterd n'a pas été trouvé",
    "auth.text21": "succès mot de passe de réinitialisation",
    "auth.text22": "Mot de passe Échec de la réinitialisation, S'il vous plaît aller à la page Connexion et envoyer des courriels pour la réinitialisation de mot de passe",
    "auth.text23": "Mot de passe page de réinitialisation",
    "auth.text24": "nouveau mot de passe",
    "auth.text25": "réinitialiser le mot de passe",
    "auth.text26": "La page est invalide ou mot de passe est déjà changé",
    "auth.text27": "Envoi de courrier électronique pour la réinitialisation de mot de passe échouée, S'il vous plaît vérifier l'adresse e-mail",
    "auth.text28": "page de vérification par courriel",
    "auth.text29": "Email vérification succès, s'il vous plaît aller à la page de connexion ci-dessus",
    "auth.text30": "vérification du courrier électronique a échoué. Cette page est expiré, ou la vérification est déjà terminée.",
    "auth.text31": "Le message de vérification a été envoyé. Plese vérifier votre e-mail et vérifier. (Également CHACK dans le courrier spam)",
    "auth.text32": "Vérification Email est requis pour se connecter. S'il vous plaît envoyer un courrier électronique pour vérification à votre adresse e-mail.",
    "auth.text33": "Envoyer un message pour la vérification",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Alimentation",
    "nav.text2": "S'identifier",
    "nav.text3": "S'inscrire",
    "nav.text4": "informations utilisateur",
    "nav.text5": "Se déconnecter",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Page non trouvée",
    "notFound.text2": "aller à la page d'alimentation",
    "notFound.text3": "aller à la page de connexion",
    "notFound.text4": "Où allez-vous aujourd'hui?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Annuler",
    "comment.text2": "Effacer",
    "comment.text3": "Poster un commentaire",
    "comment.text4": "Afficher Répondre",
    "comment.text5": "total",
    "comment.text6": "Répondre Ecrire",
    "comment.text7": "Cacher Répondre",
    "comment.text8": "commentaires",
    "comment.text9": "Est-il pas de problème pour supprimer ce commentaire et les réponses de ce commentaire tout à fait?",
    "comment.text10": "Est-il pas de problème de supprimer complètement ce commentaire?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Ajouter aux favoris Message",
    "follow.text2": "Supprimer du poste favori",
    "follow.text3": "Ajouter à l'utilisateur favori",
    "follow.text4": "Supprimer favori de l'utilisateur",
    "follow.text5": "messages de spectacle",
    "follow.text6": "Vos utilisateurs favoris",
    "follow.text7": "Poster Utilisateurs favoris",
    "__11": "",
    "privacyPolicy.text1": "Politique de confidentialité",
    "privacyPolicy.text2": "Cette politique peut être mise à jour ou révision sans préavis. Il est de la responsabilité de l'utilisateur de se tenir informés des changements de politique de confidentialité. Prendre note des autres questions de confidentialité qui peuvent vous affecter:",
    "privacyPolicy.text3": "Ce site pourrait être compromise.",
    "privacyPolicy.text4": "Votre ordinateur pourrait être compromise.",
    "privacyPolicy.text5": "Ce site est hébergé sur les infrastructures et Firebase Digital Ocean. Ils peuvent et ont leurs propres systèmes de suivi sur leurs serveurs. Ces services ont leurs propres politiques de confidentialité et ils ne sont pas couverts par cette politique de confidentialité.",
    "privacyPolicy.text6": "Ce site stocke email, sous forme cryptée de mot de passe et d'autres entrées des utilisateurs à des infrastructures hébergées et Firebase Digital Ocean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Conditions d'utilisation",
    "termsOfUse.text2": "Intimité",
    "termsOfUse.text3": "Votre vie privée est importante pour nous. S'il vous plaît lire la",
    "termsOfUse.text4": "politique de confidentialité",
    "termsOfUse.text5": "pour plus d'informations.",
    "termsOfUse.text6": "Vos propres responsabilités",
    "termsOfUse.text7": "Vous, l'utilisateur, seul responsable d'assurer votre propre respect des lois et des impôts dans votre juridiction. Vous êtes seul responsable de votre propre sécurité.",
    "termsOfUse.text8": "Exclusion de garantie",
    "termsOfUse.text9": "Ce site est fourni « TEL QUEL », SANS GARANTIE OU CONDITION DE QUELQUE NATURE QUE CE.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Démarrer Connect",
    "videoTalk.text2": "Arrêtez Connect",
    "videoTalk.text3": "Utilisateurs en ligne",
    "videoTalk.text4": "Commencer à parler",
    "videoTalk.text5": "Vous êtes essayer de parler",
    "videoTalk.text6": "Aucun autre utilisateur existe en ligne",
    "videoTalk.text7": "Vous parlez",
    "videoTalk.text8": "Vous avez commencé à parler",
    "videoTalk.text9": "Afficher Talk texte",
    "videoTalk.text10": "saisie de texte ....",
    "videoTalk.text11": "Vous parlez encore. Voulez-vous arrêter de parler?",
    "videoTalk.text12": "Appel utilisateur a déconnecté",
    "videoTalk.text13": "utilisateur vous appelle. Acceptez-vous cet appel?",
    "videoTalk.text14": "rejeter votre appel",
    "videoTalk.text15": "recherche en ligne ... utilisateur",
    "videoTalk.text16": "recherche utilisateur ...",
    "__14": "",
    "groupTalk.text1": "créateur",
    "groupTalk.text2": "Description",
    "groupTalk.text3": "date de création",
    "groupTalk.text4": "Modifier les informations de groupe",
    "groupTalk.text5": "Est-il pas de problème pour vous supprimer des membres de ce groupe?",
    "groupTalk.text6": "Départ de membres",
    "groupTalk.text7": "Rejoindre le groupe",
    "groupTalk.text8": "Afficher les membres du groupe",
    "groupTalk.text9": "Voulez-vous Rejoindre ce groupe?",
    "groupTalk.text10": "Nom de groupe",
    "groupTalk.text11": "Retour à la liste",
    "groupTalk.text12": "Confirmez votre entrée",
    "groupTalk.text13": "Créer un groupe",
    "groupTalk.text14": "Groupe de mise à jour",
    "groupTalk.text15": "Sortir du groupe",
    "groupTalk.text16": "Aller à la page d'accueil Connexion",
    "groupTalk.text17": "nom de groupe (5-100 caractères)",
    "groupTalk.text18": "Description du groupe (moins de 500 caractères)"
  },
  {
    "LANGUAGE": "Galician",
    "CODE": "gl",
    "test.text1": "Ola",
    "test.text2": "ola",
    "test.text3": "Grazas",
    "test.text4": "guión",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "cancelar",
    "general.text2": "aceptar",
    "general.text3": "eliminar",
    "general.text4": "deleted",
    "general.text5": "editar",
    "general.text6": "actualizar",
    "general.text7": "cargar imaxe",
    "general.text8": "imaxe de exclusión",
    "general.text9": "imaxe",
    "general.text10": "alimentación",
    "general.text11": "Iniciar sesión",
    "general.text12": "Rexistrarse",
    "general.text13": "Info de usuario",
    "general.text14": "saír",
    "general.text15": "arquivo debe ser",
    "general.text16": "e menos de",
    "general.text17": "contrasinal",
    "general.text18": "mensaxes favoritos",
    "general.text19": "mensaxes",
    "general.text20": "imaxes",
    "general.text21": "videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "novo post",
    "feed.text2": "Amosar mensaxes de usuarios",
    "feed.text3": "amosar Mensaxes",
    "feed.text4": "vista",
    "feed.text5": "editar",
    "feed.text6": "eliminar",
    "feed.text7": "E non é problema para post borrar completamente?",
    "feed.text8": "Publicado por",
    "feed.text9": "Creado por",
    "feed.text10": "título",
    "feed.text11": "imaxe",
    "feed.text12": "contido",
    "feed.text13": "público",
    "feed.text14": "privado",
    "feed.text15": "Título para post",
    "feed.text16": "pos por contido",
    "feed.text17": "eliminar",
    "feed.text18": "E ningún problema para a imaxe borrar completamente?",
    "feed.text19": "deleted",
    "feed.text20": "Ficheiro multimedia debe ser jpg, jpeg, png ou ficheiro mp4, e menos de 3MB",
    "feed.text21": "pos pública",
    "feed.text22": "pos privada",
    "feed.text23": "Escriba para a Investigación ....",
    "feed.text24": "Usuario buscar ...",
    "__2": "",
    "userInfo.text1": "Información do usuario",
    "userInfo.text2": "nome",
    "userInfo.text3": "imaxe",
    "userInfo.text4": "Data de creación",
    "userInfo.text5": "E ningún problema para a imaxe borrar completamente?",
    "userInfo.text6": "Nome ...",
    "userInfo.text7": "ficheiro de imaxe debe ser jpg, jpeg, png ou arquivo, e menos de 1 MB",
    "userInfo.text8": "modo escuro",
    "userInfo.text9": "Do usuario",
    "userInfo.text10": "obter información adicional sobre ti, descrición bio etc ... (menos de 300 caracteres)",
    "userInfo.text11": "Lingua",
    "userInfo.text12": "cambio",
    "userInfo.text13": "Notificación",
    "__3": "",
    "auth.text1": "O teu correo electrónico",
    "auth.text2": "O teu nome",
    "auth.text3": "contrasinal",
    "auth.text4": "O contrasinal debe ser de 8 a 20 caracteres, polo menos unha letra maiúscula, unha letra minúscula, un número e un carácter especial (@ $!% *? &).",
    "auth.text5": "Rexistrarse",
    "auth.text6": "Iniciar sesión",
    "auth.text7": "Esqueceches o contrasinal",
    "auth.text8": "Registre-se Páxina",
    "auth.text9": "Correo-e a redefinición de contrasinal",
    "auth.text10": "Enviar email",
    "auth.text11": "Validación fallou. Asegúrese de que o enderezo de correo-e non é utilizada aínda!",
    "auth.text12": "Iniciar sesión de usuario fallou!",
    "auth.text13": "Contrasinal incorrecto catálogo",
    "auth.text14": "enderezo de correo-e inserido non se atopou",
    "auth.text15": "a creación do usuario fallou!",
    "auth.text16": "O enderezo de correo-e xa está en uso por outra conta",
    "auth.text17": "Amosar contrasinal escritas",
    "auth.text18": "Agochar contrasinal",
    "auth.text19": "correo-e para o descanso contrasinal enviado",
    "auth.text20": "Enterd correo electrónico non se atopou",
    "auth.text21": "éxito de redefinición de contrasinal",
    "auth.text22": "redefinición de contrasinal fallou, por favor vaia á páxina de inicio de sesión e enviar correo-e a redefinición de contrasinal",
    "auth.text23": "páxina de redefinición de contrasinal",
    "auth.text24": "novo contrasinal",
    "auth.text25": "password Reset",
    "auth.text26": "Páxina non é válido ou contrasinal xa se cambiou",
    "auth.text27": "Enviar e-mail a redefinición de contrasinal fallou, por favor enderezo de correo-e de verificación",
    "auth.text28": "páxina de verificación de correo-e",
    "auth.text29": "Electrónico de verificación éxito, por favor, ir á páxina de inicio de sesión mostra arriba",
    "auth.text30": "verificación de correo-e fallou. Esta páxina é expirada ou comprobación xa está rematado.",
    "auth.text31": "A mensaxe de verificación se enviou. Plese comprobar o seu correo electrónico e comprobar. (Tamén chack no correo spam)",
    "auth.text32": "Correo electrónico de verificación se precisa para iniciar sesión. Por favor, envíe correo electrónico a confirmación ao seu enderezo de correo-e.",
    "auth.text33": "Enviar correo electrónico a verificación",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "alimentación",
    "nav.text2": "Iniciar sesión",
    "nav.text3": "Rexistrarse",
    "nav.text4": "Info de usuario",
    "nav.text5": "saír",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Páxina non atopada",
    "notFound.text2": "ir á páxina de alimentación",
    "notFound.text3": "ir á páxina de inicio de sesión",
    "notFound.text4": "Onde vai hoxe?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "cancelar",
    "comment.text2": "eliminar",
    "comment.text3": "post Comment",
    "comment.text4": "Mostrar a resposta",
    "comment.text5": "Total",
    "comment.text6": "escribir Responder",
    "comment.text7": "Agochar Responder",
    "comment.text8": "comentarios",
    "comment.text9": "E ningún problema para eliminar este comentario e respostas deste comentario completamente?",
    "comment.text10": "E ningún problema para eliminar este comentario completamente?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Agregar a favoritos Post",
    "follow.text2": "Eliminar do post favorito",
    "follow.text3": "Engadir ao usuario favorito",
    "follow.text4": "Eliminar do usuario favorito",
    "follow.text5": "amosar mensaxes",
    "follow.text6": "Os seus usuarios favoritos",
    "follow.text7": "Usuarios post favoritos",
    "__11": "",
    "privacyPolicy.text1": "Política de Privacidade",
    "privacyPolicy.text2": "Esta política pode ser actualizado ou revisado sen previo aviso. É de responsabilidade do usuario para manterse informado sobre cambios de política de privacidade. Tomé nota de outras cuestións de privacidade que poden afectan-lo:",
    "privacyPolicy.text3": "Este sitio web pode ser comprometida.",
    "privacyPolicy.text4": "O seu ordenador pode ser comprometida.",
    "privacyPolicy.text5": "Este sitio web aloxado en infraestruturas Firebase e DigitalOcean. Poden e teñen os seus propios sistemas de seguimento nos seus servidores. Estes servizos teñen as súas propias políticas de privacidade e non son cubertos por esta política de privacidade.",
    "privacyPolicy.text6": "Este correo electrónico web almacena, forma cifrada contrasinal e outras entradas de usuarios en aloxados infraestruturas Firebase e DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Condicións de uso",
    "termsOfUse.text2": "Privacidade",
    "termsOfUse.text3": "A súa privacidade é importante para nós. Por favor, lea o",
    "termsOfUse.text4": "política de Privacidade",
    "termsOfUse.text5": "para máis información.",
    "termsOfUse.text6": "Súas propias responsabilidades",
    "termsOfUse.text7": "Ti, o usuario é o único responsable de garantir a súa propia conforme leis e impostos na súa xurisdición. Vostede é o único responsable da súa propia seguridade.",
    "termsOfUse.text8": "Renuncia de garantía",
    "termsOfUse.text9": "Este sitio é subministrado \"TAL CAL\", SEN GARANTÍAS OU CONDICIÓNS DE CALQUERA NATUREZA.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "iniciar conectar",
    "videoTalk.text2": "Pare o Connect",
    "videoTalk.text3": "Usuarios en liña",
    "videoTalk.text4": "Comezar a falar",
    "videoTalk.text5": "A súa están tentando Talk",
    "videoTalk.text6": "existe ningún outro usuario en liña",
    "videoTalk.text7": "Está falando con",
    "videoTalk.text8": "Vostede comezou a falar",
    "videoTalk.text9": "Talk show Texto",
    "videoTalk.text10": "entrada de texto ....",
    "videoTalk.text11": "Aínda está falando. Quere deixar de falar?",
    "videoTalk.text12": "usuario chamando desconectado",
    "videoTalk.text13": "usuario está chamando. Vostede acepta esta chamada?",
    "videoTalk.text14": "rexeitar a súa chamada",
    "videoTalk.text15": "buscar usuario en liña ...",
    "videoTalk.text16": "buscar usuario ...",
    "__14": "",
    "groupTalk.text1": "creador",
    "groupTalk.text2": "descrición",
    "groupTalk.text3": "Data de creación",
    "groupTalk.text4": "Editar información grupo",
    "groupTalk.text5": "E ningún problema para eliminar ten de membros deste grupo?",
    "groupTalk.text6": "Deixe de Estados",
    "groupTalk.text7": "Entrar no Grupo",
    "groupTalk.text8": "Amosar membros do Grupo",
    "groupTalk.text9": "Quere unirse a este grupo?",
    "groupTalk.text10": "Nome do grupo",
    "groupTalk.text11": "Volver á lista",
    "groupTalk.text12": "Confirmar a súa entrada",
    "groupTalk.text13": "crear Grupo",
    "groupTalk.text14": "grupo actualización",
    "groupTalk.text15": "Saír do Grupo",
    "groupTalk.text16": "Ir Homepage login",
    "groupTalk.text17": "nome do grupo (5-100 caracteres)",
    "groupTalk.text18": "Descrición grupo (menos que 500 caracteres)"
  },
  {
    "LANGUAGE": "Gujarati",
    "CODE": "gu",
    "test.text1": "નમસ્તે",
    "test.text2": "હાય",
    "test.text3": "આભાર",
    "test.text4": "નકશો",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "રદ કરો",
    "general.text2": "સ્વીકારો",
    "general.text3": "કાઢી નાંખો",
    "general.text4": "કાઢી નાખ્યું",
    "general.text5": "સંપાદિત કરો",
    "general.text6": "અપડેટ",
    "general.text7": "છબી અપલોડ કરો",
    "general.text8": "કાઢી નાંખો છબી",
    "general.text9": "છબી",
    "general.text10": "ફીડ",
    "general.text11": "પ્રવેશ કરો",
    "general.text12": "સાઇનઅપ",
    "general.text13": "વપરાશકર્તા માહિતી",
    "general.text14": "લૉગ આઉટ",
    "general.text15": "ફાઈલ હોવી જોઈએ",
    "general.text16": "અને કરતા ઓછી",
    "general.text17": "પાસવર્ડ",
    "general.text18": "પ્રિય પોસ્ટ્સ",
    "general.text19": "પોસ્ટ્સ",
    "general.text20": "છબીઓ",
    "general.text21": "વિડિઓઝ",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "નવી પોસ્ટ",
    "feed.text2": "બતાવો વપરાશકર્તા પોસ્ટ્સ",
    "feed.text3": "પોસ્ટ્સ બતાવો",
    "feed.text4": "દૃશ્ય",
    "feed.text5": "ફેરફાર કરો",
    "feed.text6": "કાઢી નાખો",
    "feed.text7": "પોસ્ટ કાઢી નાખો સંપૂર્ણપણે કોઈ સમસ્યા છે?",
    "feed.text8": "મોકલનાર",
    "feed.text9": "દ્વારા બનાવવામાં",
    "feed.text10": "શીર્ષક",
    "feed.text11": "છબી",
    "feed.text12": "સામગ્રી",
    "feed.text13": "જાહેર",
    "feed.text14": "ખાનગી",
    "feed.text15": "પોસ્ટ માટે શીર્ષક",
    "feed.text16": "સામગ્રી પોર પોસ્ટ",
    "feed.text17": "કાઢી નાખો",
    "feed.text18": "તે સંપૂર્ણપણે કાઢી છબી કોઈ સમસ્યા છે?",
    "feed.text19": "કાઢી નાખ્યું",
    "feed.text20": "મીડિયા ફાઇલ JPG, JPEG, PNG અથવા એમપી 4 ફાઈલ હોવી જોઈએ, અને 3MB કરતાં ઓછી",
    "feed.text21": "સાર્વજનિક પોસ્ટ",
    "feed.text22": "ખાનગી પોસ્ટ",
    "feed.text23": "શોધ લખો ....",
    "feed.text24": "શોધ વપરાશકર્તા ...",
    "__2": "",
    "userInfo.text1": "વપરાશકર્તા માહિતી",
    "userInfo.text2": "નામ",
    "userInfo.text3": "છબી",
    "userInfo.text4": "બનાવટ તારીખ",
    "userInfo.text5": "તે સંપૂર્ણપણે કાઢી છબી કોઈ સમસ્યા છે?",
    "userInfo.text6": "નવું નામ ...",
    "userInfo.text7": "છબી ફાઇલ JPG, JPEG, અથવા PNG ફાઇલ હોવી જોઈએ, અને 1MB કરતાં ઓછી",
    "userInfo.text8": "ડાર્ક સ્થિતિ",
    "userInfo.text9": "વિશે વપરાશકર્તા",
    "userInfo.text10": "તમારા વિશે વધારાની જાણકારી, વર્ણન બાયો વગેરે ... (ઓછી કરતાં 300 અક્ષરો)",
    "userInfo.text11": "ભાષાની",
    "userInfo.text12": "બદલો",
    "userInfo.text13": "સૂચના",
    "__3": "",
    "auth.text1": "તમારા ઇમેઇલ",
    "auth.text2": "તમારું નામ",
    "auth.text3": "પાસવર્ડ",
    "auth.text4": "પાસવર્ડ 8 થી 20 અક્ષરો, ઓછામાં ઓછા એક અપરકેસ અક્ષર, એક લોઅરકેસ પત્ર, એક નંબર અને એક ખાસ અક્ષર હોવો જોઈએ (@ $!% *? &).",
    "auth.text5": "સાઇનઅપ",
    "auth.text6": "પ્રવેશ કરો",
    "auth.text7": "પાસવર્ડ ભૂલી ગયા",
    "auth.text8": "સાઇન અપ પૃષ્ઠ",
    "auth.text9": "ઇ-મેઇલ પાસવર્ડ રીસેટ સરનામું",
    "auth.text10": "ઈ - મેલ મોકલો",
    "auth.text11": "માન્યતા નિષ્ફળ થઈ. ખાતરી કરો ઇમેઇલ સરનામું હજી સુધી ઉપયોગ કર્યો નથી છે!",
    "auth.text12": "વપરાશકર્તા લોગિન નિષ્ફળ થયું!",
    "auth.text13": "અમાન્ય પાસવર્ડ દાખલ કરાયો હતો",
    "auth.text14": "દાખલ કરેલ ઇમેઇલ સરનામું મળ્યું ન હતું",
    "auth.text15": "વપરાશકર્તા બનાવટ નિષ્ફળ!",
    "auth.text16": "ઇમેઇલ સરનામું બીજા એકાઉન્ટ દ્વારા પહેલેથી ઉપયોગમાં છે",
    "auth.text17": "બતાવો દાખલ પાસવર્ડ",
    "auth.text18": "છુપાવો પાસવર્ડ",
    "auth.text19": "પાસવર્ડ બાકીના માટે ઇમેઇલ મોકલવામાં આવ્યો ન હતો",
    "auth.text20": "Enterd ઇમેઇલ મળી ન હતી",
    "auth.text21": "પાસવર્ડ રીસેટ સફળતા",
    "auth.text22": "પાસવર્ડ રીસેટ નિષ્ફળ, કૃપા કરીને ફરીથી પાસવર્ડ રીસેટ માટે લૉગિન પાનું અને મોકલો ઇમેઇલ પર જાઓ",
    "auth.text23": "પાસવર્ડ રીસેટ પાનું",
    "auth.text24": "નવો પાસવર્ડ",
    "auth.text25": "પાસવર્ડ રીસેટ કરો",
    "auth.text26": "પૃષ્ઠ અમાન્ય છે અથવા પાસવર્ડ પહેલાથી બદલાઇ જાય છે",
    "auth.text27": "પાસવર્ડ રીસેટ ઇમેઇલ મોકલવામાં નિષ્ફળ, કૃપા કરીને તપાસો ઇમેઇલ સરનામું",
    "auth.text28": "ઇમેઇલ ચકાસણી પાનું",
    "auth.text29": "સફળતા ચકાસણી ઇમેઇલ, કૃપા કરીને ઉપર બતાવ્યા પ્રમાણે લૉગિન પૃષ્ઠ પર જાઓ",
    "auth.text30": "ઇમેઇલ ચકાસણી નિષ્ફળ ગઇ હતી. આ પાનું સમયસીમા સમાપ્ત થઈ ગઈ છે, અથવા ચકાસણી પહેલેથી સમાપ્ત થાય છે.",
    "auth.text31": "ચકાસણી મેઇલ મોકલવામાં આવ્યો હતો. Plese તમારું ઇમેઇલ તપાસો અને ચકાસો. (પણ સ્પામ મેઇલ chack)",
    "auth.text32": "ઇમેઇલ ચકાસણી લૉગિન જરૂરી છે. કૃપા કરીને તમારું ઇમેઇલ સરનામું પર ચકાસણી ઇમેઇલ મોકલી શકો છો.",
    "auth.text33": "ચકાસણી માટે ઇમેઇલ મોકલો",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "ફીડ",
    "nav.text2": "પ્રવેશ કરો",
    "nav.text3": "સાઇનઅપ",
    "nav.text4": "વપરાશકર્તા માહિતી",
    "nav.text5": "લૉગ આઉટ",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "પેજ નથી મળ્યું",
    "notFound.text2": "ફીડ પૃષ્ઠ પર જાઓ",
    "notFound.text3": "લૉગિન પૃષ્ઠ પર જાઓ",
    "notFound.text4": "તમે આજે ક્યાં જાઓ છો?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "રદ કરો",
    "comment.text2": "કાઢી નાંખો",
    "comment.text3": "ટિપ્પણી પોસ્ટ કરો",
    "comment.text4": "બતાવો જવાબ",
    "comment.text5": "કુલ",
    "comment.text6": "જવાબ લખો",
    "comment.text7": "જવાબ છુપાવો",
    "comment.text8": "ટિપ્પણીઓ",
    "comment.text9": "તે આ ટિપ્પણી અને આ ટિપ્પણી સંપૂર્ણપણે ના જવાબો કાઢી નાખવા માટે કોઈ સમસ્યા છે?",
    "comment.text10": "તે સંપૂર્ણપણે આ ટિપ્પણીને કાઢી નાખવા કોઈ સમસ્યા છે?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "પ્રિય પોસ્ટમાં ઉમેરો",
    "follow.text2": "મનપસંદ પોસ્ટ માંથી કાઢી નાખો",
    "follow.text3": "મનપસંદ વપરાશકર્તા ઉમેરો",
    "follow.text4": "મનપસંદ વપરાશકર્તા માંથી કાઢી નાખો",
    "follow.text5": "પોસ્ટ્સ બતાવો",
    "follow.text6": "તમારી મનપસંદ વપરાશકર્તાઓ",
    "follow.text7": "પોસ્ટ પ્રિય વપરાશકર્તાઓ",
    "__11": "",
    "privacyPolicy.text1": "ગોપનીયતા નીતિ",
    "privacyPolicy.text2": "આ નીતિ અપડેટ કરવામાં આવે અથવા સૂચના વગર પુનરાવર્તિત થઈ શકે છે. તે ગોપનીયતા નીતિ ફેરફારો વિશે માહિતગાર રહેવા માટે વપરાશકર્તા જવાબદારી છે. અન્ય ગોપનીયતા મુદ્દાઓ કે જે તમે પર અસર કરી શકે છે તેની નોંધ લો:",
    "privacyPolicy.text3": "આ વેબસાઇટ સાથે ચેડા થઈ શકે છે.",
    "privacyPolicy.text4": "તમારું કમ્પ્યુટર સાથે ચેડા થઈ શકે છે.",
    "privacyPolicy.text5": "આ વેબસાઇટ Firebase અને DigitalOcean માળખાકીય સુવિધા પર હોસ્ટ કરવામાં આવે છે. તેઓ કરી શકે છે અને તેમના સર્વરો પર તેમના પોતાના ટ્રેકિંગ સિસ્ટમો નથી. તે સેવાઓ તેમના પોતાના ગોપનીયતા નીતિઓ હોય છે અને તેઓ આ ગોપનીયતા નીતિ દ્વારા આવરી લેવામાં આવતી નથી કરવામાં આવે છે.",
    "privacyPolicy.text6": "આ વેબસાઇટ સ્ટોર્સ ઇમેઇલ, પાસવર્ડ એનક્રિપ્ટ થયેલ ફોર્મ, અને હોસ્ટ Firebase અને DigitalOcean માળખાકીય સુવિધા વપરાશકર્તાઓ અન્ય ઇનપુટ્સ.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "વાપરવાના નિયમો",
    "termsOfUse.text2": "ગોપનીયતા",
    "termsOfUse.text3": "તમારી ગોપનીયતા અમારા માટે મહત્વપૂર્ણ છે. કૃપા કરીને વાંચી",
    "termsOfUse.text4": "ગોપનીયતા નીતિ",
    "termsOfUse.text5": "વધારે માહિતી માટે.",
    "termsOfUse.text6": "તમારા પોતાના જવાબદારી",
    "termsOfUse.text7": "તમે વપરાશકર્તા, તમારા અધિકારક્ષેત્રમાં કાયદાઓ અને કર સાથે તમારા પોતાના પાલન તેની ખાતરી કરવા માટે સંપૂર્ણપણે જવાબદાર છે. તમે તમારા પોતાના સુરક્ષા માટે સંપૂર્ણપણે જવાબદાર છે.",
    "termsOfUse.text8": "વોરંટીનો અસ્વીકાર",
    "termsOfUse.text9": "આ સાઇટ પર પૂરા પાડવામાં આવેલ છે બેસીસ \"જેમ છે તેમ\", વૉરંટી અથવા કોઈપણ પ્રકારની શરતો વગર.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "પ્રારંભ કનેક્ટ",
    "videoTalk.text2": "રોકો કનેક્ટ",
    "videoTalk.text3": "ઑનલાઇન વપરાશકર્તાઓ",
    "videoTalk.text4": "વાત શરૂ",
    "videoTalk.text5": "તમારું ચર્ચા કરવાનો પ્રયાસ કરી રહ્યા",
    "videoTalk.text6": "કોઈ અન્ય ઓનલાઈન વપરાશકર્તા અસ્તિત્વમાં",
    "videoTalk.text7": "તમે વાત કરી રહ્યા છો",
    "videoTalk.text8": "તમે વાત કરવા શરૂ",
    "videoTalk.text9": "ટેક્સ્ટ દર્શાવો ટોક",
    "videoTalk.text10": "ટેક્સ્ટ ઇનપુટ ....",
    "videoTalk.text11": "તમે હજુ પણ વાત કરવામાં આવે છે. તમે વાત કરવાનું બંધ કરવા માંગો છો?",
    "videoTalk.text12": "કોલિંગ વપરાશકર્તા ડિસ્કનેક્ટ",
    "videoTalk.text13": "વપરાશકર્તા તમને કૉલ કરે છે. તમે આ કૉલ સ્વીકારો છો?",
    "videoTalk.text14": "તમારા કોલ નકારવા",
    "videoTalk.text15": "ઓનલાઇન વપરાશકર્તા શોધ ...",
    "videoTalk.text16": "વપરાશકર્તા શોધ ...",
    "__14": "",
    "groupTalk.text1": "સર્જક",
    "groupTalk.text2": "વર્ણન",
    "groupTalk.text3": "બનાવટ તારીખ",
    "groupTalk.text4": "સંપાદિત જૂથ માહિતી",
    "groupTalk.text5": "તે આ જૂથના સભ્યોને તમે કાઢી નાખવા માટે કોઈ સમસ્યા છે?",
    "groupTalk.text6": "સભ્ય પાસેથી છોડો",
    "groupTalk.text7": "જૂથ સાથે જોડાવા",
    "groupTalk.text8": "બતાવો જૂથ સભ્યો",
    "groupTalk.text9": "જો તમે આ જૂથ જોડાવા માંગો છો?",
    "groupTalk.text10": "ગ્રુપ નામ",
    "groupTalk.text11": "સૂચિ પર પાછા",
    "groupTalk.text12": "તમારી ઇનપુટ પુષ્ટિ",
    "groupTalk.text13": "જૂથ બનાવો",
    "groupTalk.text14": "અપડેટ જૂથ",
    "groupTalk.text15": "ગ્રુપ બહાર નીકળો",
    "groupTalk.text16": "પ્રવેશમાં હોમપેજ પર જાઓ",
    "groupTalk.text17": "જૂથ નામ (5-100 અક્ષરો)",
    "groupTalk.text18": "જૂથ વર્ણન (500 અક્ષરો કરતાં ઓછું)"
  },
  {
    "LANGUAGE": "Hebrew",
    "CODE": "he",
    "test.text1": "שלום",
    "test.text2": "היי",
    "test.text3": "תודה",
    "test.text4": "מַפָּה",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "לְבַטֵל",
    "general.text2": "לְקַבֵּל",
    "general.text3": "לִמְחוֹק",
    "general.text4": "שנמחק",
    "general.text5": "לַעֲרוֹך",
    "general.text6": "עדכון",
    "general.text7": "העלאת תמונה",
    "general.text8": "תמונת מחק",
    "general.text9": "תמונה",
    "general.text10": "הזנה",
    "general.text11": "התחברות",
    "general.text12": "הירשם",
    "general.text13": "מידע משתמש",
    "general.text14": "להתנתק",
    "general.text15": "הקובץ צריך להיות",
    "general.text16": "ופחות מ",
    "general.text17": "סיסמה",
    "general.text18": "Favorite הודעות",
    "general.text19": "הודעות",
    "general.text20": "תמונות",
    "general.text21": "וידאו",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "פרסום חדש",
    "feed.text2": "הצג הודעות משתמש",
    "feed.text3": "הודעות צג",
    "feed.text4": "נוף",
    "feed.text5": "לַעֲרוֹך",
    "feed.text6": "לִמְחוֹק",
    "feed.text7": "האם הוא לא בעיה להודעה מחיקה לחלוטין?",
    "feed.text8": "פורסם על ידי",
    "feed.text9": "נוצר על ידי",
    "feed.text10": "כותרת",
    "feed.text11": "תמונה",
    "feed.text12": "תוֹכֶן",
    "feed.text13": "פּוּמְבֵּי",
    "feed.text14": "פְּרָטִי",
    "feed.text15": "כותרת לפוסט",
    "feed.text16": "פוסט por תוכן",
    "feed.text17": "לִמְחוֹק",
    "feed.text18": "האם זה שום בעיה לתמונה מחיקה לחלוטין?",
    "feed.text19": "שנמחק",
    "feed.text20": "קובץ מדיה אמור להיות jpg, jpeg, png או קובץ mp4, ופחות מ 3MB",
    "feed.text21": "פוסט ציבורי",
    "feed.text22": "פרסום פרטי",
    "feed.text23": "הקלד עבור חיפוש ....",
    "feed.text24": "חפש משתמש ...",
    "__2": "",
    "userInfo.text1": "מידע משתמש",
    "userInfo.text2": "שֵׁם",
    "userInfo.text3": "תמונה",
    "userInfo.text4": "תאריך היווצרות",
    "userInfo.text5": "האם זה שום בעיה לתמונה מחיקה לחלוטין?",
    "userInfo.text6": "שם חדש ...",
    "userInfo.text7": "קובץ תמונה צריך להיות jpg, jpeg, png או קובץ, ופחות מ 1MB",
    "userInfo.text8": "מצב Dark",
    "userInfo.text9": "משתמש על",
    "userInfo.text10": "מידע נוסף אודותיך, ביו תיאור וכו '... (פחות מ 300 תווים)",
    "userInfo.text11": "שפה",
    "userInfo.text12": "שינוי",
    "userInfo.text13": "הוֹדָעָה",
    "__3": "",
    "auth.text1": "האימייל שלך",
    "auth.text2": "השם שלך",
    "auth.text3": "סיסמה",
    "auth.text4": "סיסמה צריכה לכלול 8 עד 20 תווים, לפחות אות גדולה אחת, אות קטנה אחת, מספר אחד ואופי אחד מיוחד (@ $!% *? &).",
    "auth.text5": "הירשם",
    "auth.text6": "התחברות",
    "auth.text7": "שכחת את הסיסמה",
    "auth.text8": "עמוד להרשמה",
    "auth.text9": "כתובת דואר אלקטרוני עבור איפוס סיסמה",
    "auth.text10": "שלח אימייל",
    "auth.text11": "האימות נכשל. ודא את כתובת הדוא\"ל אינו משמש עדיין!",
    "auth.text12": "כניסת משתמש נכשלה!",
    "auth.text13": "סיסמה לא חוקית הוזן",
    "auth.text14": "כתובת הדוא\"ל שהזנת לא נמצאה",
    "auth.text15": "יצירת משתמש נכשלה!",
    "auth.text16": "כתובת הדוא\"ל כבר נמצאת בשימוש על ידי חשבון אחר",
    "auth.text17": "צג הזנת סיסמה",
    "auth.text18": "הסתר סיסמא",
    "auth.text19": "הדוא\"ל למנוחת סיסמא נשלח",
    "auth.text20": "הדוא\"ל Enterd לא נמצא",
    "auth.text21": "הצלחת איפוס סיסמא",
    "auth.text22": "איפוס הסיסמה נכשלה, אנא גש לעמוד ושליחת אימיילים כניסה עבור איפוס סיסמה שוב",
    "auth.text23": "דף איפוס סיסמה",
    "auth.text24": "סיסמה חדשה",
    "auth.text25": "לאפס את הסיסמה",
    "auth.text26": "עמוד ריקים או סיסמא כבר השתנתה",
    "auth.text27": "שליחת דוא\"ל עבור איתחול סיסמה נכשל, אנא כתובת הדוא\"ל הצ'ק",
    "auth.text28": "דף אימות דוא\"ל",
    "auth.text29": "דוא\"ל אימות הצלחה, בבקשה לעבור לדף הכניסה המוצג לעיל",
    "auth.text30": "אימות דוא\"ל נכשלה. דף זה פג, או אימות כבר סיימה.",
    "auth.text31": "אימות דואר שנשלח. Plese לבדוק את הדוא\"ל שלך ולאמת. (גם קרקור בדואר זבל)",
    "auth.text32": "דוא\"ל אימות נדרשת כניסה. נא לשלוח דוא\"ל לאימות אל כתובת הדוא\"ל שלך.",
    "auth.text33": "שלח דוא\"ל לאימות",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "הזנה",
    "nav.text2": "התחברות",
    "nav.text3": "הירשם",
    "nav.text4": "מידע משתמש",
    "nav.text5": "להתנתק",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "הדף לא נמצא",
    "notFound.text2": "לעבור לדף feed",
    "notFound.text3": "לעבור לדף הכניסה",
    "notFound.text4": "לאן אתה הולך היום?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "לְבַטֵל",
    "comment.text2": "לִמְחוֹק",
    "comment.text3": "פרסם תגובה",
    "comment.text4": "צג תשובה",
    "comment.text5": "סה\"כ",
    "comment.text6": "תגובה כתוב",
    "comment.text7": "סתר תגובה",
    "comment.text8": "תגובות",
    "comment.text9": "האם זה לא בעיה למחוק את התגובה הזו ותגובות של תגובה זו לחלוטין?",
    "comment.text10": "האם זה לא בעיה למחוק את התגובה הזו לחלוטין?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "מועדף פוסט",
    "follow.text2": "מחק פוסט אהוב",
    "follow.text3": "הוסף משתמש האהוב",
    "follow.text4": "מחק מהמשתמש אהוב",
    "follow.text5": "הצג הודעות",
    "follow.text6": "המשתמשים המועדפים שלך",
    "follow.text7": "משתמש פוסט המועדף",
    "__11": "",
    "privacyPolicy.text1": "מדיניות פרטיות",
    "privacyPolicy.text2": "מדיניות זו עשויה להתעדכן או מתוקנת ללא הודעה מוקדמת. זוהי האחריות של המשתמשים כדי להתעדכן לגבי שינויים במדיניות הפרטיות. שים לב לסוגיות פרטיות אחרות שעשויים להשפיע עליך:",
    "privacyPolicy.text3": "האתר הזה עלול להיות בסיכון.",
    "privacyPolicy.text4": "המחשב שלך עלול להיות בסיכון.",
    "privacyPolicy.text5": "אתר זה מתארח על תשתיות Firebase ו DigitalOcean. הם עשויים והאם יש מערכות מעקב משלהם בשרתים שלהם. יש מי שירותי למדיניות הפרטיות שלהם והם אינם מכוסים על ידי מדיניות פרטיות זו.",
    "privacyPolicy.text6": "הדוא\"ל חנויות באתר זה, בצורה של הסיסמה מוצפנת, ותשומות אחרות של משתמשים בתשתיות Firebase ו DigitalOcean אירחה.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "תנאי שימוש",
    "termsOfUse.text2": "פְּרָטִיוּת",
    "termsOfUse.text3": "הפרטיות שלך חשובה לנו. אנא קרא את",
    "termsOfUse.text4": "מדיניות פרטיות",
    "termsOfUse.text5": "למידע נוסף.",
    "termsOfUse.text6": "אחריות משלך",
    "termsOfUse.text7": "אתה, המשתמש, אחראים באופן בלעדי להבטיח תאימות משלך עם חוקים ומסים בתחום השיפוט שלך. אתה אחראי בלעדי על האבטחה שלך.",
    "termsOfUse.text8": "כתב ויתור",
    "termsOfUse.text9": "באתר זה מסופק על \"AS IS\" בסיס, ללא התחייבויות או תנאים מכל סוג שהוא.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "התחל Connect",
    "videoTalk.text2": "עצור Connect",
    "videoTalk.text3": "משתמשים ברשת",
    "videoTalk.text4": "התחל לדבר",
    "videoTalk.text5": "שלך מנסים לדבר",
    "videoTalk.text6": "אין משתמש מקוון אחר קיים",
    "videoTalk.text7": "אתה מדבר",
    "videoTalk.text8": "התחלת לדבר",
    "videoTalk.text9": "שיחת צג טקסט",
    "videoTalk.text10": "קלט טקסט ....",
    "videoTalk.text11": "אתה עדיין מדבר. האם אתה רוצה להפסיק לדבר?",
    "videoTalk.text12": "המשתמשים קוראים מנותקים",
    "videoTalk.text13": "משתמש קורא לך. האם אתה מקבל שיחה זו?",
    "videoTalk.text14": "לדחות את השיחה שלך",
    "videoTalk.text15": "לחפש המשתמשים באינטרנט ...",
    "videoTalk.text16": "לחפש משתמש ...",
    "__14": "",
    "groupTalk.text1": "בורא",
    "groupTalk.text2": "תיאור",
    "groupTalk.text3": "תאריך היווצרות",
    "groupTalk.text4": "מידע על הקבוצה ערוך",
    "groupTalk.text5": "האם זה לא בעיה למחוק ממך חברי קבוצה זו?",
    "groupTalk.text6": "השאר מן החבר",
    "groupTalk.text7": "להצטרף לקבוצה",
    "groupTalk.text8": "חברים תערוכה קבוצתית",
    "groupTalk.text9": "האם אתה מעוניין להצטרף לקבוצה זו?",
    "groupTalk.text10": "שם קבוצה",
    "groupTalk.text11": "בחזרה לרשימה",
    "groupTalk.text12": "אשר הקלט שלך",
    "groupTalk.text13": "צור קבוצה",
    "groupTalk.text14": "עדכן קבוצה",
    "groupTalk.text15": "יציאה מ הקבוצה",
    "groupTalk.text16": "עבור אל דף הבית כדי כניסה",
    "groupTalk.text17": "שם הקבוצה (5-100 תווים)",
    "groupTalk.text18": "תיאור הקבוצה (פחות מ 500 תווים)"
  },
  {
    "LANGUAGE": "Hindi",
    "CODE": "hi",
    "test.text1": "नमस्ते",
    "test.text2": "नमस्ते",
    "test.text3": "धन्यवाद",
    "test.text4": "नक्शा",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "रद्द करना",
    "general.text2": "स्वीकार करना",
    "general.text3": "हटाएं",
    "general.text4": "हटाए गए",
    "general.text5": "संपादित करें",
    "general.text6": "अपडेट करें",
    "general.text7": "तस्वीर डालिये",
    "general.text8": "हटाएं छवि",
    "general.text9": "छवि",
    "general.text10": "चारा",
    "general.text11": "लॉग इन करें",
    "general.text12": "साइन अप करें",
    "general.text13": "उपयोगकर्ता जानकारी",
    "general.text14": "लॉग आउट",
    "general.text15": "फ़ाइल होना चाहिए",
    "general.text16": "और कम से कम",
    "general.text17": "कुंजिका",
    "general.text18": "पसंदीदा के पोस्ट",
    "general.text19": "पोस्ट",
    "general.text20": "इमेजिस",
    "general.text21": "वीडियो",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "नई पोस्ट",
    "feed.text2": "दिखाएँ उपयोगकर्ता पोस्ट",
    "feed.text3": "शो के पोस्ट",
    "feed.text4": "राय",
    "feed.text5": "संपादित करें",
    "feed.text6": "हटाना",
    "feed.text7": "हटाने के बाद पूरी तरह से करने के लिए कोई समस्या नहीं है?",
    "feed.text8": "द्वारा प्रकाशित किया गया था",
    "feed.text9": "के द्वारा बनाई गई",
    "feed.text10": "शीर्षक",
    "feed.text11": "छवि",
    "feed.text12": "सामग्री",
    "feed.text13": "जनता",
    "feed.text14": "निजी",
    "feed.text15": "इस पद के लिए शीर्षक",
    "feed.text16": "सामग्री पोर पोस्ट",
    "feed.text17": "हटाना",
    "feed.text18": "यह पूरी तरह से हटाने की छवि के लिए कोई समस्या नहीं है?",
    "feed.text19": "हटाए गए",
    "feed.text20": "मीडिया फ़ाइल jpg, jpeg, png या mp4 फ़ाइल होना चाहिए, और 3 MB से कम",
    "feed.text21": "सार्वजनिक पद",
    "feed.text22": "निजी पोस्ट",
    "feed.text23": "खोज के लिए टाइप करें ....",
    "feed.text24": "खोजें उपयोगकर्ता ...",
    "__2": "",
    "userInfo.text1": "उपयोगकर्ता के लिए जानकारी",
    "userInfo.text2": "नाम",
    "userInfo.text3": "छवि",
    "userInfo.text4": "रचना तिथि",
    "userInfo.text5": "यह पूरी तरह से हटाने की छवि के लिए कोई समस्या नहीं है?",
    "userInfo.text6": "नया नाम ...",
    "userInfo.text7": "छवि फ़ाइल jpg, JPEG, या PNG फ़ाइल होना चाहिए, और 1MB से कम",
    "userInfo.text8": "डार्क मोड",
    "userInfo.text9": "के बारे में उपयोगकर्ता",
    "userInfo.text10": "आप के बारे में अतिरिक्त जानकारी, विवरण जैव आदि ... (कम से कम 300 अक्षर)",
    "userInfo.text11": "भाषा: हिन्दी",
    "userInfo.text12": "खुले पैसे",
    "userInfo.text13": "अधिसूचना",
    "__3": "",
    "auth.text1": "तुम्हारा ईमेल",
    "auth.text2": "आपका नाम",
    "auth.text3": "कुंजिका",
    "auth.text4": "पासवर्ड 8 से 20 अक्षर, कम से कम एक अपरकेस अक्षर, एक छोटा अक्षर, एक संख्या और एक विशेष वर्ण होना चाहिए (@ $!% *? और)।",
    "auth.text5": "साइन अप करें",
    "auth.text6": "लॉग इन करें",
    "auth.text7": "पासवर्ड भूल गए",
    "auth.text8": "साइनअप पृष्ठ",
    "auth.text9": "ई-मेल पासवर्ड रीसेट के लिए पता",
    "auth.text10": "ईमेल भेजें",
    "auth.text11": "प्रमाणीकरण विफल रहा। सुनिश्चित करें कि ई-मेल पता अभी तक उपयोग नहीं किया जाता है!",
    "auth.text12": "उपयोगकर्ता लॉगिन विफल रहा!",
    "auth.text13": "गलत पासवर्ड डाला गया था",
    "auth.text14": "जो ईमेल पता दर्ज नहीं मिला था",
    "auth.text15": "उपयोगकर्ता बनाने में विफल!",
    "auth.text16": "ई-मेल पता किसी अन्य खाते द्वारा पहले से उपयोग में है",
    "auth.text17": "दिखाएँ प्रवेश किया पासवर्ड",
    "auth.text18": "पासवर्ड छिपाएं",
    "auth.text19": "पासवर्ड आराम के लिए ईमेल भेजा गया",
    "auth.text20": "Enterd ईमेल नहीं मिला था",
    "auth.text21": "पासवर्ड रीसेट सफलता",
    "auth.text22": "प्रवेश शब्द परिवर्तन असफल, कृपया फिर से पासवर्ड रीसेट के लिए लॉग इन पेज और भेजें ईमेल करने के लिए जाना",
    "auth.text23": "पासवर्ड रीसेट पेज",
    "auth.text24": "नया पासवर्ड",
    "auth.text25": "पासवर्ड रीसेट",
    "auth.text26": "पृष्ठ अमान्य है या पासवर्ड पहले से ही बदल गया है",
    "auth.text27": "पासवर्ड रीसेट के लिए ईमेल भेजे जाने में विफल रहा है, कृपया जांच ईमेल पते",
    "auth.text28": "ईमेल सत्यापन पेज",
    "auth.text29": "सफलता सत्यापन ईमेल, कृपया ऊपर दिखाए गए प्रवेश पृष्ठ पर जाना",
    "auth.text30": "ईमेल सत्यापन में विफल रहा है। यह पृष्ठ अब मान्य है, या सत्यापन पहले समाप्त हो गया है।",
    "auth.text31": "सत्यापन मेल भेजा गया था। आपने जो अपने ईमेल की जाँच करें और सत्यापित करें। (यह भी स्पैम मेल में chack)",
    "auth.text32": "ईमेल सत्यापन लॉग इन करने के लिए आवश्यक है। कृपया अपना ईमेल पता करने के लिए सत्यापन के लिए ईमेल भेजें।",
    "auth.text33": "सत्यापन के लिए ईमेल भेजें",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "चारा",
    "nav.text2": "लॉग इन करें",
    "nav.text3": "साइन अप करें",
    "nav.text4": "उपयोगकर्ता जानकारी",
    "nav.text5": "लॉग आउट",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "पृष्ठ नहीं मिला",
    "notFound.text2": "फ़ीड पृष्ठ पर जाने के",
    "notFound.text3": "प्रवेश पृष्ठ पर जाना",
    "notFound.text4": "तुम आज कहाँ जाते हैं?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "रद्द करना",
    "comment.text2": "हटाएं",
    "comment.text3": "टिप्पणी पोस्ट करें",
    "comment.text4": "प्रत्युत्तर दिखाएं",
    "comment.text5": "कुल",
    "comment.text6": "उत्तर लिखें",
    "comment.text7": "उत्तर छिपाएं",
    "comment.text8": "टिप्पणियाँ",
    "comment.text9": "यह इस टिप्पणी और इस टिप्पणी को पूरी तरह से की उत्तरों को हटाने के लिए कोई समस्या नहीं है?",
    "comment.text10": "यह पूरी तरह से इस टिप्पणी को हटाना कोई समस्या नहीं है?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "पसंदीदा पोस्ट में जोड़े",
    "follow.text2": "पसंदीदा पद से हटाएं",
    "follow.text3": "पसंदीदा उपयोगकर्ता में जोड़े",
    "follow.text4": "पसंदीदा उपयोगकर्ता से हटाएँ",
    "follow.text5": "शो पदों",
    "follow.text6": "अपने पसंदीदा उपयोगकर्ता",
    "follow.text7": "पोस्ट पसंदीदा उपयोगकर्ता",
    "__11": "",
    "privacyPolicy.text1": "गोपनीयता नीति",
    "privacyPolicy.text2": "यह नीति अद्यतन या सूचना के बिना संशोधित किया जा सकता। यह गोपनीयता नीति बदलावों के बारे में सूचित रहने के लिए उपयोगकर्ता की जिम्मेदारी है। अन्य गोपनीयता के मुद्दों है कि आप को प्रभावित कर सकता को ध्यान में रखना:",
    "privacyPolicy.text3": "इस वेबसाइट का उल्लंघन हो सकता है।",
    "privacyPolicy.text4": "आपके कंप्यूटर से समझौता किया जा सकता है।",
    "privacyPolicy.text5": "इस वेबसाइट के Firebase और DigitalOcean आधारभूत संरचनाओं पर होस्ट की है। वे कर सकते हैं और अपने सर्वर पर अपने स्वयं के ट्रैकिंग सिस्टम की क्या ज़रूरत है। उन सेवाओं अपनी गोपनीयता नीतियों है और वे इस गोपनीयता नीति में शामिल नहीं हैं।",
    "privacyPolicy.text6": "इस वेबसाइट के भंडार ईमेल, पासवर्ड की एन्क्रिप्टेड रूप, और मेजबानी की Firebase और DigitalOcean आधारभूत संरचनाओं पर उपयोगकर्ताओं की अन्य इनपुट।",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "उपयोग की शर्तें",
    "termsOfUse.text2": "एकांत",
    "termsOfUse.text3": "आपकी निजता हमारे लिए महत्वपूर्ण है। कृपया पढ़ें",
    "termsOfUse.text4": "गोपनीयता नीति",
    "termsOfUse.text5": "अधिक जानकारी के लिए।",
    "termsOfUse.text6": "अपनी खुद की जिम्मेदारियों",
    "termsOfUse.text7": "तुम्हें पता है, उपयोगकर्ता, अपने अधिकार क्षेत्र में कानून और करों के साथ अपने स्वयं अनुपालन सुनिश्चित करने के लिए जिम्मेदार हैं। आप अपनी खुद की सुरक्षा के लिए जिम्मेदार हैं।",
    "termsOfUse.text8": "वारंटी का अस्वीकरण",
    "termsOfUse.text9": "इस साइट में एक उपलब्ध कराया जाता है \"जैसा है\", वारंटी या किसी भी प्रकार की शर्तों के बिना।",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "प्रारंभ कनेक्ट",
    "videoTalk.text2": "बंद करो कनेक्ट",
    "videoTalk.text3": "ऑनलाइन उपयोगकर्ता",
    "videoTalk.text4": "बात करने के लिए शुरू",
    "videoTalk.text5": "आपका बात करने के लिए कोशिश कर रहे हैं",
    "videoTalk.text6": "कोई अन्य ऑनलाइन उपयोगकर्ता मौजूद है",
    "videoTalk.text7": "आप करने के लिए बात कर रहे हैं",
    "videoTalk.text8": "आप बात करने के लिए शुरू कर दिया",
    "videoTalk.text9": "दिखाएँ पाठ टॉक",
    "videoTalk.text10": "पाठ इनपुट ....",
    "videoTalk.text11": "तुम अब भी बात कर रहे हैं। आप बात करना बंद करो करना चाहते हैं?",
    "videoTalk.text12": "कॉलिंग उपयोगकर्ता काट दिया",
    "videoTalk.text13": "उपयोगकर्ता बुला रहा है। आप इस कॉल स्वीकार करते हैं?",
    "videoTalk.text14": "अपने कॉल को अस्वीकार",
    "videoTalk.text15": "ऑनलाइन उपयोगकर्ता खोज ...",
    "videoTalk.text16": "उपयोगकर्ता खोज ...",
    "__14": "",
    "groupTalk.text1": "रचनाकार",
    "groupTalk.text2": "विवरण",
    "groupTalk.text3": "निर्माण तिथि",
    "groupTalk.text4": "संपादित करें समूह की जानकारी",
    "groupTalk.text5": "यह इस समूह के सदस्यों से आप हटाना कोई समस्या नहीं है?",
    "groupTalk.text6": "सदस्य से छोड़ दो",
    "groupTalk.text7": "समूह में शामिल हों",
    "groupTalk.text8": "दिखाएँ समूह के सदस्य",
    "groupTalk.text9": "यदि आप इस समूह में शामिल करना चाहते हैं?",
    "groupTalk.text10": "समूह का नाम",
    "groupTalk.text11": "दोबारा सूची को जाएं",
    "groupTalk.text12": "अपने इनपुट की पुष्टि करें",
    "groupTalk.text13": "समूह बनाएँ",
    "groupTalk.text14": "अपडेट समूह",
    "groupTalk.text15": "समूह से बाहर निकलें",
    "groupTalk.text16": "लॉग इन करने के लिए मुखपृष्ठ पर जाएं",
    "groupTalk.text17": "समूह का नाम (5-100 अक्षर)",
    "groupTalk.text18": "समूह का विवरण (500 वर्णों से कम)"
  },
  {
    "LANGUAGE": "Croatian",
    "CODE": "hr",
    "test.text1": "zdravo",
    "test.text2": "bok",
    "test.text3": "Hvala vam",
    "test.text4": "karta",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Otkazati",
    "general.text2": "Prihvatiti",
    "general.text3": "Izbrisati",
    "general.text4": "Izbrisani",
    "general.text5": "Uredi",
    "general.text6": "ažuriranje",
    "general.text7": "Učitaj sliku",
    "general.text8": "brisanje slike",
    "general.text9": "Slika",
    "general.text10": "stočna hrana",
    "general.text11": "Prijaviti se",
    "general.text12": "Prijavi se",
    "general.text13": "Podaci o korisniku",
    "general.text14": "Odjavite se",
    "general.text15": "datoteka trebala biti",
    "general.text16": "a manje od",
    "general.text17": "Zaporka",
    "general.text18": "Omiljeni Postovi",
    "general.text19": "Postovi",
    "general.text20": "slike",
    "general.text21": "Video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nova objava",
    "feed.text2": "Prikaži Korisničke postova",
    "feed.text3": "Prikaži poruke",
    "feed.text4": "pogled",
    "feed.text5": "Uredi",
    "feed.text6": "izbrisati",
    "feed.text7": "Je li se bez problema za brisanje posta u potpunosti?",
    "feed.text8": "Objavio",
    "feed.text9": "Napravio",
    "feed.text10": "Titula",
    "feed.text11": "Slika",
    "feed.text12": "Sadržaj",
    "feed.text13": "javnost",
    "feed.text14": "privatni",
    "feed.text15": "Naslov za poštu",
    "feed.text16": "Sadržaj poslije por",
    "feed.text17": "izbrisati",
    "feed.text18": "Je li to nije problem za brisanje slike u potpunosti?",
    "feed.text19": "Izbrisani",
    "feed.text20": "Mediji datoteke treba biti JPG, JPEG, PNG ili MP4 datoteka, a manje od 3 MB",
    "feed.text21": "javni post",
    "feed.text22": "privatni post",
    "feed.text23": "Upišite za pretraživanje ....",
    "feed.text24": "Ključna korisnika ...",
    "__2": "",
    "userInfo.text1": "informacije o korisniku",
    "userInfo.text2": "Ime",
    "userInfo.text3": "slika",
    "userInfo.text4": "Datum stvaranja",
    "userInfo.text5": "Je li to nije problem za brisanje slike u potpunosti?",
    "userInfo.text6": "Novo ime ...",
    "userInfo.text7": "Slikovne datoteke treba biti JPG, JPEG ili PNG datoteku, a manje od 1 MB",
    "userInfo.text8": "tamno način",
    "userInfo.text9": "O Korisnik",
    "userInfo.text10": "dodatne informacije o vama, opis bio itd ... (manje od 300 znakova)",
    "userInfo.text11": "Jezik",
    "userInfo.text12": "Promijeniti",
    "userInfo.text13": "Obavijest",
    "__3": "",
    "auth.text1": "Tvoj email",
    "auth.text2": "Tvoje ime",
    "auth.text3": "Zaporka",
    "auth.text4": "Lozinka treba biti 8 do 20 znakova, barem jedno veliko slovo, jedno malo slovo, jedan broj i jedan poseban karakter (@ $!% *? &).",
    "auth.text5": "Prijavi se",
    "auth.text6": "Prijaviti se",
    "auth.text7": "Zaboravili ste lozinku",
    "auth.text8": "Stranica prijave",
    "auth.text9": "E-mail adresa za reset zaporke",
    "auth.text10": "Pošalji e-mail",
    "auth.text11": "Provjera valjanosti nije uspjela. Provjerite je li adresa e-pošte još nije korišten!",
    "auth.text12": "Prijava korisnika nije uspio!",
    "auth.text13": "Pogrešna lozinka je unesena",
    "auth.text14": "Unesena e-mail adresa nije pronađena",
    "auth.text15": "Stvaranje Korisnik nije uspjela!",
    "auth.text16": "E-mail adresa već koristi drugi račun",
    "auth.text17": "Prikaži unesenu lozinku",
    "auth.text18": "Sakrij Lozinka",
    "auth.text19": "e za password ostatak je poslan",
    "auth.text20": "Enterd e nije pronađen",
    "auth.text21": "Uspjeh je poništavanje zaporke",
    "auth.text22": "poništavanje lozinke nije uspjelo, Molimo idite na stranicu za prijavu i slanje e-mail za reset lozinke opet",
    "auth.text23": "Stranica za poništavanje lozinke",
    "auth.text24": "Nova lozinka",
    "auth.text25": "Resetiranje lozinke",
    "auth.text26": "Stranica je nevažeći ili lozinka već je promijenjena",
    "auth.text27": "Slanje e-mail za reset zaporke nije uspjela, molimo pošaljite email adresa provjera",
    "auth.text28": "stranica za provjeru e-pošte",
    "auth.text29": "E-mail verifikacija uspjeha, molimo idite na stranicu za prijavu prikazan iznad",
    "auth.text30": "Potvrda e-pošte nije uspjelo. Ova stranica je istekla ili provjera je već gotov.",
    "auth.text31": "Provjera e-pošte je poslana. Pleše provjerite e-poštu i provjeriti. (Također chack u spam mail)",
    "auth.text32": "Provjera e-pošte je potrebno za prijavu. Molimo pošaljite e-poštu na provjeru u vaš e-mail adresu.",
    "auth.text33": "Pošaljite e-poštu za provjeru",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "stočna hrana",
    "nav.text2": "Prijaviti se",
    "nav.text3": "Prijavi se",
    "nav.text4": "Podaci o korisniku",
    "nav.text5": "Odjavite se",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Stranica nije pronađena",
    "notFound.text2": "idi na stranicu hrane",
    "notFound.text3": "ići na stranicu za prijavu",
    "notFound.text4": "Gdje ići danas?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Otkazati",
    "comment.text2": "Izbrisati",
    "comment.text3": "Objavi komentar",
    "comment.text4": "Prikaži Odgovori",
    "comment.text5": "ukupno",
    "comment.text6": "Pisanje Odgovori",
    "comment.text7": "Sakrij Odgovori",
    "comment.text8": "komentari",
    "comment.text9": "Je li to nije problem izbrisati ovaj komentar i odgovor na ovaj komentar u potpunosti?",
    "comment.text10": "Je li to nikakav problem da se u potpunosti izbrisati ovaj komentar?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Dodaj u Favorite post",
    "follow.text2": "Brisanje iz omiljenih poštom",
    "follow.text3": "Dodaj u omiljene korisnika",
    "follow.text4": "Brisanje iz omiljenih korisnika",
    "follow.text5": "Prikaži postove",
    "follow.text6": "Omiljene korisnike",
    "follow.text7": "Post omiljene korisnike",
    "__11": "",
    "privacyPolicy.text1": "Izjava o privatnosti",
    "privacyPolicy.text2": "Ovo pravilo može se ažurirati ili revidirati bez prethodne najave. To je odgovornost korisnika kako bi bili informirani o promjenama Pravila o privatnosti. Uzeti na znanje drugih pitanja privatnosti koje mogu utjecati na vas:",
    "privacyPolicy.text3": "Ova web stranica može biti ugrožena.",
    "privacyPolicy.text4": "Vaše računalo može biti ugrožena.",
    "privacyPolicy.text5": "Ova web stranica je domaćin na Firebase i DigitalOcean infrastruktura. Oni mogu i imaju svoje vlastite sustave za praćenje na svojim serverima. Te usluge su vlastita pravila o privatnosti i nisu obuhvaćeni ovim Pravilima o privatnosti.",
    "privacyPolicy.text6": "Ova web stranica pohranjuje e-mail, kodiran oblik lozinkom i drugih inputa korisnika na domaćin Firebase i DigitalOcean infrastruktura.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Uvjeti korištenja",
    "termsOfUse.text2": "Privatnost",
    "termsOfUse.text3": "Vaša privatnost nam je važna. Molimo pročitajte",
    "termsOfUse.text4": "Izjava o privatnosti",
    "termsOfUse.text5": "za više informacija.",
    "termsOfUse.text6": "Vlastite odgovornosti",
    "termsOfUse.text7": "Vi, korisnik, ste isključivo odgovorni za osiguravanje vlastitog zakona i porezima u svojoj nadležnosti. Vi ste isključivo odgovorni za vlastitu sigurnost.",
    "termsOfUse.text8": "Odricanje od jamstva",
    "termsOfUse.text9": "Ovaj site daje na „kakav jest”, BEZ JAMSTVA ILI UVJETA BILO KOJE VRSTE.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Početak Povezivanje",
    "videoTalk.text2": "zaustavljanje Povezivanje",
    "videoTalk.text3": "online Users",
    "videoTalk.text4": "Počnite pričati",
    "videoTalk.text5": "Vaš pokušavaju razgovor",
    "videoTalk.text6": "Niti jedan drugi online korisnika ne postoji",
    "videoTalk.text7": "Vi ste u razgovoru s",
    "videoTalk.text8": "Počeli ste pričati",
    "videoTalk.text9": "Prikaži Tekst Razgovor",
    "videoTalk.text10": "unos teksta ....",
    "videoTalk.text11": "Još uvijek se govori. Želite li prestati govoriti?",
    "videoTalk.text12": "Pozivanje korisnika isključen",
    "videoTalk.text13": "Korisnik vas zove. Da li prihvatiti ovaj poziv?",
    "videoTalk.text14": "odbiti vaš poziv",
    "videoTalk.text15": "Pretražite online korisniku ...",
    "videoTalk.text16": "Traženje korisnika ...",
    "__14": "",
    "groupTalk.text1": "tvorac",
    "groupTalk.text2": "Opis",
    "groupTalk.text3": "Datum stvaranja",
    "groupTalk.text4": "Informacije o Uredi grupu",
    "groupTalk.text5": "Je li to nije problem za vas izbrisati iz članova ove grupe?",
    "groupTalk.text6": "Ostavite iz člana",
    "groupTalk.text7": "Pridružite se grupi",
    "groupTalk.text8": "Prikaži Group Članovi",
    "groupTalk.text9": "Želite li da se pridruže ovoj grupi?",
    "groupTalk.text10": "Grupno ime",
    "groupTalk.text11": "Natrag na popis",
    "groupTalk.text12": "Potvrda unosa",
    "groupTalk.text13": "Napraviti grupu",
    "groupTalk.text14": "Ažuriranje grupe",
    "groupTalk.text15": "Izlaz iz grupe",
    "groupTalk.text16": "Idi na početnu stranicu za prijavu",
    "groupTalk.text17": "naziv grupe (5-100 znakova)",
    "groupTalk.text18": "Opis skupina (manje od 500 znakova)"
  },
  {
    "LANGUAGE": "Hungarian",
    "CODE": "hu",
    "test.text1": "Helló",
    "test.text2": "Szia",
    "test.text3": "Köszönöm",
    "test.text4": "térkép",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Megszünteti",
    "general.text2": "Elfogad",
    "general.text3": "Töröl",
    "general.text4": "törölve",
    "general.text5": "szerkesztése",
    "general.text6": "frissítés",
    "general.text7": "kép feltöltése",
    "general.text8": "Törlés parancsot",
    "general.text9": "Kép",
    "general.text10": "takarmány",
    "general.text11": "Belépés",
    "general.text12": "Regisztrálj",
    "general.text13": "Felhasználói adatok",
    "general.text14": "Kijelentkezés",
    "general.text15": "fájlnak kell lennie",
    "general.text16": "és kevesebb, mint",
    "general.text17": "Jelszó",
    "general.text18": "kedvenc hozzászólás",
    "general.text19": "Hozzászólások",
    "general.text20": "képek",
    "general.text21": "Videók",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "új hozzászólás",
    "feed.text2": "Itt található Felhasználó Hozzászólások",
    "feed.text3": "Üzenetek megjelenítése",
    "feed.text4": "Kilátás",
    "feed.text5": "szerkesztés",
    "feed.text6": "töröl",
    "feed.text7": "Van nem jelent problémát a bejegyzés törlése teljesen?",
    "feed.text8": "általa megosztva",
    "feed.text9": "Készítette",
    "feed.text10": "Cím",
    "feed.text11": "Kép",
    "feed.text12": "Tartalom",
    "feed.text13": "nyilvános",
    "feed.text14": "magán",
    "feed.text15": "Cím utáni",
    "feed.text16": "Tartalom por bejegyzést",
    "feed.text17": "töröl",
    "feed.text18": "Ez nem probléma, hogy delete image teljesen?",
    "feed.text19": "törölve",
    "feed.text20": "Media File legyen jpg, jpeg, png, vagy mp4 fájlt, és kevesebb, mint 3 MB",
    "feed.text21": "nyilvános bejegyzés",
    "feed.text22": "privát üzenet",
    "feed.text23": "Írja Keresési ....",
    "feed.text24": "Keresés Felhasználói ...",
    "__2": "",
    "userInfo.text1": "Felhasználói információ",
    "userInfo.text2": "név",
    "userInfo.text3": "kép",
    "userInfo.text4": "létrehozás dátuma",
    "userInfo.text5": "Ez nem probléma, hogy delete image teljesen?",
    "userInfo.text6": "Új név ...",
    "userInfo.text7": "Képfájl jpg, jpeg vagy png fájlt, és kevesebb, mint 1 MB",
    "userInfo.text8": "sötét Mode",
    "userInfo.text9": "A felhasználói",
    "userInfo.text10": "További információt akkor, leírás bio stb ... (kevesebb, mint 300 karakter)",
    "userInfo.text11": "Nyelv",
    "userInfo.text12": "változás",
    "userInfo.text13": "Értesítés",
    "__3": "",
    "auth.text1": "Az email címed",
    "auth.text2": "A neved",
    "auth.text3": "Jelszó",
    "auth.text4": "Jelszó legyen 8-20 karakter, legalább egy nagybetűt, egy kisbetűt, egy szám és egy speciális karakter (@ $!% *? &).",
    "auth.text5": "Regisztrálj",
    "auth.text6": "Belépés",
    "auth.text7": "Elfelejtett jelszo",
    "auth.text8": "Regisztráció oldal",
    "auth.text9": "E-mail cím: jelszó visszaállítás",
    "auth.text10": "küldjön e-mailt",
    "auth.text11": "Az ellenőrzés sikertelen. Győződjön meg róla, az e-mail cím nem használható még!",
    "auth.text12": "Felhasználói bejelentkezés sikertelen!",
    "auth.text13": "Érvénytelen jelszót adott",
    "auth.text14": "Megadott e-mail cím nem található",
    "auth.text15": "Felhasználó létrehozása nem sikerült!",
    "auth.text16": "Az e-mail cím már használatban van egy másik fiókkal",
    "auth.text17": "Itt található beírt jelszó",
    "auth.text18": "Jelszó elrejtése",
    "auth.text19": "e-mail jelszó többi küldték",
    "auth.text20": "Bejegyzésre kerülnek az e-mailt nem talált",
    "auth.text21": "A jelszó-visszaállítási siker",
    "auth.text22": "A jelszó-visszaállítási sikertelen, kérjük, menjen a bejelentkezés oldalon és e-mail küldést Jelszókiadási újra",
    "auth.text23": "A jelszó-visszaállítási oldal",
    "auth.text24": "új jelszó",
    "auth.text25": "Jelszó visszaállítása",
    "auth.text26": "Oldal érvénytelen vagy jelszó már megváltozott",
    "auth.text27": "E-mail küldése a jelszó visszaállítás sikertelen, kérjük ellenőrizze az e-mail címet",
    "auth.text28": "E-mail ellenőrző oldal",
    "auth.text29": "E-mail ellenőrzés siker, lépjen a bejelentkezési oldal fent látható",
    "auth.text30": "E-mail ellenőrzés sikertelen. Ez az oldal lejárt, vagy ellenőrzés már befejeződött.",
    "auth.text31": "Ellenőrző Mail küldték. Plese ellenőrizze az e-mail és ellenőrizni. (Szintén chack a spam levél)",
    "auth.text32": "E-mail ellenőrzés szükséges a belépéshez. Kérjük, küldje el e-mailben, hogy az ellenőrzést az e-mail címét.",
    "auth.text33": "E-mail küldése az ellenőrzés",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "takarmány",
    "nav.text2": "Belépés",
    "nav.text3": "Regisztrálj",
    "nav.text4": "Felhasználói adatok",
    "nav.text5": "Kijelentkezés",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Az oldal nem található",
    "notFound.text2": "megy takarmány oldal",
    "notFound.text3": "megy bejelentkezési oldal",
    "notFound.text4": "Hová megy ma?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Megszünteti",
    "comment.text2": "Töröl",
    "comment.text3": "Üzenet elküldése",
    "comment.text4": "Válasz megjelenítése",
    "comment.text5": "teljes",
    "comment.text6": "Írja Válasz",
    "comment.text7": "elrejtése Válasz",
    "comment.text8": "Hozzászólások",
    "comment.text9": "Ez nem probléma, hogy törölje ezt a megjegyzést, és válaszok A megjegyzés teljesen?",
    "comment.text10": "Ez nem probléma, hogy törölje ezt a megjegyzést teljesen?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Hozzá Kedvenc Hozzászólás",
    "follow.text2": "Törlés a kedvenc bejegyzést",
    "follow.text3": "Hozzá kedvenc felhasználói",
    "follow.text4": "Törlés a kedvenc felhasználói",
    "follow.text5": "Mutasd üzenete",
    "follow.text6": "Kedvenc felhasználó",
    "follow.text7": "Hozzászólás kedvenc felhasználók",
    "__11": "",
    "privacyPolicy.text1": "Adatvédelmi irányelvek",
    "privacyPolicy.text2": "Ez a politika lehet frissíteni vagy felülvizsgált értesítés nélkül. Ez a felelősség a felhasználót, hogy tájékozott legyen az adatvédelmi politikai változások. Vegye tudomásul más adatvédelmi problémák, amelyek hatással lehetnek Önnek:",
    "privacyPolicy.text3": "Ez a honlap veszélybe kerülhet.",
    "privacyPolicy.text4": "A számítógép veszélybe kerülhet.",
    "privacyPolicy.text5": "Ez a honlap a házigazdája Firebase és DigitalOcean infrastruktúrát. Ők lehet, és nem rendelkeznek saját nyomkövető rendszerek a szerverén. Ezek a szolgáltatások saját adatvédelmi politika, és nem terjed ki az adatvédelmi politika.",
    "privacyPolicy.text6": "Ez a honlap tárolja az e-mail, titkosított formában, jelszó és egyéb bemenetek felhasználók házigazdája Firebase és DigitalOcean infrastruktúrát.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Használati feltételek",
    "termsOfUse.text2": "Magánélet",
    "termsOfUse.text3": "Az Ön adatainak védelme fontos számunkra. Kérjük, olvassa el a",
    "termsOfUse.text4": "Adatvédelmi irányelvek",
    "termsOfUse.text5": "további információért.",
    "termsOfUse.text6": "Saját felelősség",
    "termsOfUse.text7": "Te, a felhasználó egyedül felelős annak biztosításáért, saját törvények betartását és az adók a joghatóság. Kizárólag Ön felelős a saját biztonságát.",
    "termsOfUse.text8": "Fontos garanciális",
    "termsOfUse.text9": "Ezt az oldalt eleme „AS IS” ALAPON GARANCIA NÉLKÜL VAGY BÁRMELY.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Csatlakozás",
    "videoTalk.text2": "A stop Csatlakozás",
    "videoTalk.text3": "Online felhasználók",
    "videoTalk.text4": "Kezdjen beszélni",
    "videoTalk.text5": "Ön próbál beszélni",
    "videoTalk.text6": "Nincs más online felhasználó létezik",
    "videoTalk.text7": "Beszél, hogy",
    "videoTalk.text8": "Akkor kezdett beszélni",
    "videoTalk.text9": "Szöveg megjelenítése Talk",
    "videoTalk.text10": "szövegbevitel ....",
    "videoTalk.text11": "Még mindig beszélünk. Szeretné, hogy hagyja abba beszél?",
    "videoTalk.text12": "Calling felhasználó megszakad",
    "videoTalk.text13": "felhasználó hív téged. Nem fogadja el a hívást?",
    "videoTalk.text14": "elutasítja a hívást",
    "videoTalk.text15": "keresés az online felhasználói ...",
    "videoTalk.text16": "keresés felhasználói ...",
    "__14": "",
    "groupTalk.text1": "Teremtő",
    "groupTalk.text2": "Leírás",
    "groupTalk.text3": "létrehozás dátuma",
    "groupTalk.text4": "Csoport szerkesztése info",
    "groupTalk.text5": "Ez nem probléma, hogy töröljük a csoport tagjai?",
    "groupTalk.text6": "Hagyja a tagállamoktól",
    "groupTalk.text7": "Csapathoz csatlakozni",
    "groupTalk.text8": "Megjelenítése Group tagok",
    "groupTalk.text9": "Akarsz csatlakozni a csoporthoz?",
    "groupTalk.text10": "Csoport név",
    "groupTalk.text11": "Vissza a listához",
    "groupTalk.text12": "Erősítsd meg a bemeneti",
    "groupTalk.text13": "Csoport létrehozása",
    "groupTalk.text14": "Csoport frissítése",
    "groupTalk.text15": "Kilépés a Csoport",
    "groupTalk.text16": "Tovább a honlapunkon Bejelentkezés",
    "groupTalk.text17": "csoport neve (5-100 karakter)",
    "groupTalk.text18": "Csoport leírás (kevesebb, mint 500 karakter)"
  },
  {
    "LANGUAGE": "Armenian",
    "CODE": "hy",
    "test.text1": "Բարեւ",
    "test.text2": "Ողջու՜յն",
    "test.text3": "Շնորհակալություն",
    "test.text4": "map",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "վերացնել",
    "general.text2": "Ընդունել",
    "general.text3": "Ջնջել",
    "general.text4": "ջնջված",
    "general.text5": "խմբագրել",
    "general.text6": "Թարմացնել",
    "general.text7": "վերբեռնում Image",
    "general.text8": "Ջնջել պատկերը",
    "general.text9": "պատկեր",
    "general.text10": "կեր",
    "general.text11": "լոգին",
    "general.text12": "Գրանցվել",
    "general.text13": "Օգտվող Info",
    "general.text14": "Ելք",
    "general.text15": "ֆայլը պետք է լինի",
    "general.text16": "եւ ավելի քիչ, քան",
    "general.text17": "գաղտնաբառ",
    "general.text18": "Սիրված Գրառումներ",
    "general.text19": "Գրառումներ",
    "general.text20": "images",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "նոր Post",
    "feed.text2": "Ցույց են տալիս, User գրառումներ",
    "feed.text3": "Գրառումներ",
    "feed.text4": "տեսարան",
    "feed.text5": "խմբագրել",
    "feed.text6": "ջնջել",
    "feed.text7": "Սա է, ոչ մի խնդիր ջնջելու կվիճեք.",
    "feed.text8": "Տեղադրվել է",
    "feed.text9": "ստեղծված է",
    "feed.text10": "Կոչում",
    "feed.text11": "պատկեր",
    "feed.text12": "պարունակություն",
    "feed.text13": "հասարակական",
    "feed.text14": "մասնավոր",
    "feed.text15": "Վերնագիր պաշտոնում",
    "feed.text16": "Բովանդակությունը por գրառումը",
    "feed.text17": "ջնջել",
    "feed.text18": "Արդյոք դա ոչ մի խնդիր է ջնջել պատկերի ամբողջությամբ.",
    "feed.text19": "ջնջված",
    "feed.text20": "ԶԼՄ-ները Պատկեր պետք է լինի JPG, JPEG, PNG կամ mp4 ֆայլը, եւ ավելի քիչ, քան 3MB",
    "feed.text21": "հասարակական գրություն",
    "feed.text22": "Ավելացնել գրառումը մեջբերման պահոցում",
    "feed.text23": "Մուտքագրեք որոնման համար ....",
    "feed.text24": "Որոնել Օգտվող ...",
    "__2": "",
    "userInfo.text1": "Օգտվող Տեղեկություն",
    "userInfo.text2": "Անուն",
    "userInfo.text3": "պատկեր",
    "userInfo.text4": "ստեղծման ամսաթիվը",
    "userInfo.text5": "Արդյոք դա ոչ մի խնդիր է ջնջել պատկերի ամբողջությամբ.",
    "userInfo.text6": "Նոր անուն ...",
    "userInfo.text7": "Պատկերի ֆայլը պետք է լինի JPG, JPEG, կամ PNG ֆայլեր, եւ ավելի քիչ, քան 1 ՄԲ",
    "userInfo.text8": "մուգ ռեժիմ",
    "userInfo.text9": "Օգտվողի մասին",
    "userInfo.text10": "լրացուցիչ տեղեկություն Ձեր մասին, նկարագրությունը բիո եւ այլն ... (ոչ պակաս, քան 300 նիշ)",
    "userInfo.text11": "Լեզու",
    "userInfo.text12": "փոփոխություն",
    "userInfo.text13": "ծանուցում",
    "__3": "",
    "auth.text1": "Քո էլէկտրոնային փոստը",
    "auth.text2": "Քո անունը",
    "auth.text3": "գաղտնաբառ",
    "auth.text4": "Գաղտնաբառ պետք է լինի 8-ից 20 նիշ, առնվազն մեկ մեծատառ, մեկ փոքրատառ, մեկ համարը եւ մեկ հատուկ բնավորությամբ (@ $!% *. &):",
    "auth.text5": "Գրանցվել",
    "auth.text6": "լոգին",
    "auth.text7": "Մոռացել եք ծածկագիրը",
    "auth.text8": "Գրանցվել Էջ",
    "auth.text9": "E-mail հասցե գաղտնաբառը զրոյացնել",
    "auth.text10": "ուղարկել նամակ",
    "auth.text11": "Վավերացումը ձախողվեց: Համոզվեք, որ էլփոստի հասցեն չի օգտագործվում դեռ!",
    "auth.text12": "Լոգին ձախողվեց.",
    "auth.text13": "Գաղտնաբառը սխալ է եղել Ընդունվել",
    "auth.text14": "Ընդունվել էլ-փոստի հասցեն չի գտնվել",
    "auth.text15": "Օգտվող ստեղծումը ձախողվեց:",
    "auth.text16": "Էլփոստի հասցեն արդեն օգտագործվում է մեկ այլ հաշիվ",
    "auth.text17": "Ցույց են տալիս, Մուտքագրված գաղտնաբառը",
    "auth.text18": "թաքցնել Գաղտնաբառ",
    "auth.text19": "Փոստի գաղտնաբառը հանգստի է ուղարկվել",
    "auth.text20": "Enterd Փոստի չի գտնվել",
    "auth.text21": "Գաղտնաբառ հետադարձելու հաջողությունը",
    "auth.text22": "Գաղտնաբառ հետադարձելու ձախողվեց, խնդրում ենք գնալ Մուտք էջում եւ ուղարկել էլեկտրոնային փոստով համար գաղտնաբառը զրոյացնել կրկին",
    "auth.text23": "Գաղտնաբառ հետադարձելու էջ",
    "auth.text24": "նոր ծածկագիր",
    "auth.text25": "Վերագործարկեք գաղտնաբառը",
    "auth.text26": "Էջ է անվավեր կամ Գաղտնաբառ արդեն փոխվել է",
    "auth.text27": "Ուղարկելով նամակ գաղտնաբառը զրոյացնել չհաջողվեց, Խնդրում ենք ստուգել էլեկտրոնային հասցեն",
    "auth.text28": "Փոստի ստուգման էջ",
    "auth.text29": "Փոստի ստուգման հաջողության, խնդրում ենք գնալ դեպի մուտքի էջում ցուցադրված է վերեւում",
    "auth.text30": "Փոստի ստուգումը ձախողվել է. Այս էջի ժամկետանց է, կամ ստուգման արդեն ավարտվել է:",
    "auth.text31": "Հաստատման Փոստ էր ուղարկվել: Plese ստուգել Ձեր էլ հաստատել: (Նաեւ chack է սպամ փոստով)",
    "auth.text32": "Փոստի Հաստատում է պահանջվում է գրանցվել. Խնդրում եմ ուղարկել Email ստուգման համար ձեր email հասցեի.",
    "auth.text33": "Ուղարկել Email ստուգման համար",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "կեր",
    "nav.text2": "լոգին",
    "nav.text3": "Գրանցվել",
    "nav.text4": "Օգտվող Info",
    "nav.text5": "Ելք",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Էջը չի գտնվել",
    "notFound.text2": "գնալ feed էջում",
    "notFound.text3": "գնալ մուտքի էջում",
    "notFound.text4": "Որտեղ եք գնալ այսօր.",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "վերացնել",
    "comment.text2": "Ջնջել",
    "comment.text3": "գրություն Մեկնաբանություն",
    "comment.text4": "Ցույց տալ Պատասխանել",
    "comment.text5": "ընդհանուր",
    "comment.text6": "գրել Պատասխանել",
    "comment.text7": "թաքցնել Պատասխանել",
    "comment.text8": "մեկնաբանություններ",
    "comment.text9": "Արդյոք դա ոչ մի խնդիր ջնջել այս մեկնաբանությունը եւ Պատասխան !!! այս մեկնաբանության ամբողջությամբ.",
    "comment.text10": "Արդյոք դա ոչ մի խնդիր ջնջել այս մեկնաբանությունը ամբողջությամբ.",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Ավելացնել սիրելի Post",
    "follow.text2": "Ջնջել սիրած պաշտոնից",
    "follow.text3": "Ավելացնել սիրելի մասին",
    "follow.text4": "Ջնջել սիրած մասին",
    "follow.text5": "Գրառումներ",
    "follow.text6": "Ձեր Սիրված Users",
    "follow.text7": "Հաղորդագրություն Ավելացված է Սիրված Users",
    "__11": "",
    "privacyPolicy.text1": "Գաղտնիության քաղաքականություն",
    "privacyPolicy.text2": "Այս քաղաքականությունը կարող է թարմացվել կամ վերանայված առանց ծանուցման. Դա պատասխանատվությունն օգտագործողի մնալ: Այս մասին գաղտնիության քաղաքականության փոփոխությունների: Վերցրեք գիտություն է այլ գաղտնիության հարցերի, որոնք կարող են ազդել ձեզ:",
    "privacyPolicy.text3": "Այս կայքը կարող է վտանգել:",
    "privacyPolicy.text4": "Ձեր համակարգիչը կարող է վտանգել:",
    "privacyPolicy.text5": "Այս կայքը հյուրընկալվել է Firebase եւ DigitalOcean ենթակառուցվածքների. Նրանք կարող են եւ չեն ունեն իրենց սեփական հետեւող համակարգեր իրենց սերվերների. Այդ ծառայությունները ունեն իրենց սեփական գաղտնիության քաղաքականությունը, եւ իրենք չեն լուսաբանվում այս գաղտնիության քաղաքականության.",
    "privacyPolicy.text6": "Այս կայքը խանութներ Փոստի, կոդավորված ձեւը գաղտնաբառի եւ այլ միջոցները օգտվողների ին հյուրընկալված Firebase եւ DigitalOcean ենթակառուցվածքների.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Օգտվելու կանոններ",
    "termsOfUse.text2": "Գաղտնիության",
    "termsOfUse.text3": "Ձեր գաղտնիությունը կարեւոր է մեզ համար: Խնդրում ենք կարդալ",
    "termsOfUse.text4": "Գաղտնիության քաղաքականություն",
    "termsOfUse.text5": "Լրացուցիչ տեղեկությունների համար.",
    "termsOfUse.text6": "Քո սեփական պարտականությունները",
    "termsOfUse.text7": "Դուք, որ օգտագործողը, բացառապես պատասխանատու ապահովելով ձեր սեփական օրենքների եւ հարկերի Ձեր իրավասության. Դուք բացառապես պատասխանատու ձեր սեփական անվտանգության համար:",
    "termsOfUse.text8": "Հրաժարում երաշխիք",
    "termsOfUse.text9": "Այս կայքը նախատեսված է «ինչպես կա» սկզբունքով, առանց ԵՐԱՇԽԻՔՆԵՐԻՑ կամ պայմանների ցանկացած տեսակի.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "սկիզբը Connect",
    "videoTalk.text2": "կանգառը Connect",
    "videoTalk.text3": "առցանց օգտագործողներ",
    "videoTalk.text4": "Սկսել է խոսել",
    "videoTalk.text5": "Ձեր փորձում են զրույցի",
    "videoTalk.text6": "Ոչ մի այլ առցանց օգտվողին գոյություն ունի",
    "videoTalk.text7": "Դուք խոսում են",
    "videoTalk.text8": "Դուք սկսել է խոսել",
    "videoTalk.text9": "Ցույց տալ Տեքստը Քննարկում",
    "videoTalk.text10": "Տեքստերի մուտքագրում ....",
    "videoTalk.text11": "Դուք դեռ խոսում. Ինչ եք ուզում լռել.",
    "videoTalk.text12": "Calling օգտվողին անջատված է",
    "videoTalk.text13": "օգտագործողը կանչում է ձեզ: Դուք ընդունում եք այս կոչը:",
    "videoTalk.text14": "մերժում են Ձեր կոչը",
    "videoTalk.text15": "որոնել օնլայն օգտվողին ...",
    "videoTalk.text16": "որոնել օգտվողին ...",
    "__14": "",
    "groupTalk.text1": "ստեղծող",
    "groupTalk.text2": "նկարագրություն",
    "groupTalk.text3": "ստեղծման ամսաթիվը",
    "groupTalk.text4": "Խմբագրել այս խմբի մասին",
    "groupTalk.text5": "Արդյոք դա ոչ մի խնդիր ջնջել քեզ անդամների այս խմբի.",
    "groupTalk.text6": "Թողնել ից անդամ",
    "groupTalk.text7": "Միանալ խմբին",
    "groupTalk.text8": "Ցույց տալ Խմբի անդամները",
    "groupTalk.text9": "Ցանկանում եք Միանալ այս խմբին:",
    "groupTalk.text10": "խումբ Անունը",
    "groupTalk.text11": "Վերադառնալ ցուցակ",
    "groupTalk.text12": "Հաստատել Ձեր կարծիքը",
    "groupTalk.text13": "Ստեղծել խումբ",
    "groupTalk.text14": "Թարմացնել Group",
    "groupTalk.text15": "Ելքը խմբի",
    "groupTalk.text16": "Գնալ դեպի Գլխավոր էջ է Մուտք",
    "groupTalk.text17": "խումբ անունը (5-100 նիշ)",
    "groupTalk.text18": "խումբ նկարագրությունը (ոչ պակաս, քան 500 նիշ)"
  },
  {
    "LANGUAGE": "Indonesian",
    "CODE": "id",
    "test.text1": "Halo",
    "test.text2": "Hai",
    "test.text3": "Terima kasih",
    "test.text4": "peta",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Membatalkan",
    "general.text2": "Menerima",
    "general.text3": "Menghapus",
    "general.text4": "Dihapus",
    "general.text5": "mengedit",
    "general.text6": "Memperbarui",
    "general.text7": "Upload Gambar",
    "general.text8": "Hapus Gambar",
    "general.text9": "Gambar",
    "general.text10": "Makan",
    "general.text11": "Gabung",
    "general.text12": "Daftar",
    "general.text13": "Info Pengguna",
    "general.text14": "Keluar",
    "general.text15": "file harus",
    "general.text16": "dan kurang dari",
    "general.text17": "Kata sandi",
    "general.text18": "Tulisan favorit",
    "general.text19": "tulisan",
    "general.text20": "Gambar-gambar",
    "general.text21": "video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Berita Baru",
    "feed.text2": "Tampilkan pengguna Posting",
    "feed.text3": "Tampilkan Tulisan",
    "feed.text4": "melihat",
    "feed.text5": "mengedit",
    "feed.text6": "menghapus",
    "feed.text7": "Apakah tidak ada masalah untuk posting menghapus sepenuhnya?",
    "feed.text8": "Diposting oleh",
    "feed.text9": "Dibuat oleh",
    "feed.text10": "Judul",
    "feed.text11": "Gambar",
    "feed.text12": "Kandungan",
    "feed.text13": "publik",
    "feed.text14": "pribadi",
    "feed.text15": "Judul untuk posting",
    "feed.text16": "pasca por konten",
    "feed.text17": "menghapus",
    "feed.text18": "Apakah ada masalah untuk gambar menghapus sepenuhnya?",
    "feed.text19": "Dihapus",
    "feed.text20": "Media Berkas harus jpg, jpeg, png atau file mp4, dan kurang dari 3MB",
    "feed.text21": "pos publik",
    "feed.text22": "pos pribadi",
    "feed.text23": "Ketik untuk Cari ....",
    "feed.text24": "Cari Pengguna ...",
    "__2": "",
    "userInfo.text1": "informasi pengguna",
    "userInfo.text2": "nama",
    "userInfo.text3": "gambar",
    "userInfo.text4": "tanggal pembuatan",
    "userInfo.text5": "Apakah ada masalah untuk gambar menghapus sepenuhnya?",
    "userInfo.text6": "Nama baru ...",
    "userInfo.text7": "file gambar harus jpg, jpeg, png atau berkas, dan kurang dari 1MB",
    "userInfo.text8": "gelap Modus",
    "userInfo.text9": "Tentang Pengguna",
    "userInfo.text10": "informasi tambahan tentang Anda, deskripsi bio dll ... (kurang dari 300 karakter)",
    "userInfo.text11": "Bahasa",
    "userInfo.text12": "Perubahan",
    "userInfo.text13": "Pemberitahuan",
    "__3": "",
    "auth.text1": "Email mu",
    "auth.text2": "Namamu",
    "auth.text3": "Kata sandi",
    "auth.text4": "Password harus 8 sampai 20 karakter, setidaknya satu huruf besar, satu huruf kecil, satu nomor dan satu karakter khusus (@ $!% *? &).",
    "auth.text5": "Daftar",
    "auth.text6": "Gabung",
    "auth.text7": "Lupa Password",
    "auth.text8": "Laman Pendaftaran",
    "auth.text9": "Alamat e-mail untuk reset password",
    "auth.text10": "mengirim email",
    "auth.text11": "Validasi gagal. Pastikan alamat email tidak digunakan belum!",
    "auth.text12": "User login gagal!",
    "auth.text13": "Sandi tidak valid Pemasukan",
    "auth.text14": "alamat email yang dimasukkan tidak ditemukan",
    "auth.text15": "penciptaan pengguna gagal!",
    "auth.text16": "Alamat email ini sudah digunakan oleh akun lain",
    "auth.text17": "Tampilkan Sandi Memasuki",
    "auth.text18": "Sembunyikan Sandi",
    "auth.text19": "email untuk istirahat sandi dikirim",
    "auth.text20": "email enterd tidak ditemukan",
    "auth.text21": "Keberhasilan reset password",
    "auth.text22": "Reset password gagal, silahkan pergi ke halaman Login dan mengirim email untuk reset password lagi",
    "auth.text23": "Halaman ulang kata sandi",
    "auth.text24": "kata sandi baru",
    "auth.text25": "Reset password",
    "auth.text26": "Halaman tidak valid atau Sandi sudah berubah",
    "auth.text27": "Mengirim email untuk reset password gagal, silahkan cek alamat email",
    "auth.text28": "Email halaman verifikasi",
    "auth.text29": "Email verifikasi sukses, silahkan pergi ke halaman login yang ditunjukkan di atas",
    "auth.text30": "verifikasi email gagal. Halaman ini berakhir, atau verifikasi sudah selesai.",
    "auth.text31": "Verifikasi Mail dikirim. Plese memeriksa email Anda dan memverifikasi. (Juga chack dalam spam mail)",
    "auth.text32": "Email Verifikasi diperlukan untuk Login. Silahkan kirim Email untuk verifikasi ke alamat Email Anda.",
    "auth.text33": "Kirim Email untuk verifikasi",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Makan",
    "nav.text2": "Gabung",
    "nav.text3": "Daftar",
    "nav.text4": "Info Pengguna",
    "nav.text5": "Keluar",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Halaman tidak ditemukan",
    "notFound.text2": "pergi ke halaman pakan",
    "notFound.text3": "pergi ke halaman login",
    "notFound.text4": "Di mana Anda pergi hari ini?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Membatalkan",
    "comment.text2": "Menghapus",
    "comment.text3": "Kirim Komentar",
    "comment.text4": "Tampilkan Balas",
    "comment.text5": "total",
    "comment.text6": "tulis Balas",
    "comment.text7": "Sembunyikan Balas",
    "comment.text8": "komentar",
    "comment.text9": "Apakah ada masalah untuk menghapus komentar ini dan balasan dari komentar ini benar-benar?",
    "comment.text10": "Apakah ada masalah untuk menghapus komentar ini benar-benar?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Tambahkan ke Favorit Posting",
    "follow.text2": "Hapus dari posting favorit",
    "follow.text3": "Tambah ke pengguna favorit",
    "follow.text4": "Hapus dari pengguna favorit",
    "follow.text5": "acara posting",
    "follow.text6": "Pengguna Favorit Anda",
    "follow.text7": "Pengguna posting Favorit",
    "__11": "",
    "privacyPolicy.text1": "Rahasia pribadi",
    "privacyPolicy.text2": "Kebijakan ini dapat diperbarui atau direvisi tanpa pemberitahuan. Ini adalah tanggung jawab pengguna untuk tinggal diberitahu tentang perubahan kebijakan privasi. Mencatat masalah privasi lainnya yang dapat mempengaruhi Anda:",
    "privacyPolicy.text3": "Website ini mungkin dikompromikan.",
    "privacyPolicy.text4": "komputer Anda mungkin dikompromikan.",
    "privacyPolicy.text5": "Situs web ini di-host di Firebase dan DigitalOcean infrastruktur. Mereka mungkin dan jangan memiliki sistem pelacakan mereka sendiri di server mereka. Mereka jasa memiliki kebijakan privasi mereka sendiri dan mereka tidak tercakup oleh kebijakan privasi ini.",
    "privacyPolicy.text6": "Ini toko situs email, bentuk terenkripsi dari password, dan masukan lainnya dari pengguna di host Firebase dan DigitalOcean infrastruktur.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Syarat Penggunaan",
    "termsOfUse.text2": "Pribadi",
    "termsOfUse.text3": "Privasi Anda penting bagi kami. Silahkan baca",
    "termsOfUse.text4": "rahasia pribadi",
    "termsOfUse.text5": "untuk informasi lebih lanjut.",
    "termsOfUse.text6": "tanggung jawab Anda sendiri",
    "termsOfUse.text7": "Anda, pengguna, yang bertanggung jawab untuk memastikan kepatuhan Anda sendiri dengan hukum dan pajak di wilayah hukum Anda. Anda bertanggung jawab untuk keamanan Anda sendiri.",
    "termsOfUse.text8": "Disclaimer garansi",
    "termsOfUse.text9": "Situs ini disediakan pada \"APA ADANYA\", TANPA JAMINAN ATAU KETENTUAN APAPUN.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Mulai Connect",
    "videoTalk.text2": "berhenti Connect",
    "videoTalk.text3": "Pengguna online",
    "videoTalk.text4": "Mulai berbicara",
    "videoTalk.text5": "Anda mencoba untuk bicara",
    "videoTalk.text6": "Tidak ada pengguna online lainnya ada",
    "videoTalk.text7": "Anda berbicara dengan",
    "videoTalk.text8": "Anda mulai berbicara",
    "videoTalk.text9": "Tampilkan Bicara Text",
    "videoTalk.text10": "input teks ....",
    "videoTalk.text11": "Anda masih berbicara. Apakah Anda ingin berhenti berbicara?",
    "videoTalk.text12": "Memanggil pengguna terputus",
    "videoTalk.text13": "pengguna yang menelepon Anda. Apakah Anda menerima panggilan ini?",
    "videoTalk.text14": "menolak panggilan Anda",
    "videoTalk.text15": "mencari user online ...",
    "videoTalk.text16": "mencari pengguna ...",
    "__14": "",
    "groupTalk.text1": "pencipta",
    "groupTalk.text2": "Deskripsi",
    "groupTalk.text3": "tanggal pembuatan",
    "groupTalk.text4": "Info Edit grup",
    "groupTalk.text5": "Apakah ada masalah untuk menghapus Anda dari anggota kelompok ini?",
    "groupTalk.text6": "Berangkat dari Anggota",
    "groupTalk.text7": "Bergabung dengan grup",
    "groupTalk.text8": "Tampilkan Anggota Grup",
    "groupTalk.text9": "Apakah Anda Ingin Gabung Grup ini?",
    "groupTalk.text10": "Nama grup",
    "groupTalk.text11": "Kembali ke daftar",
    "groupTalk.text12": "Konfirmasi masukan Anda",
    "groupTalk.text13": "Membuat grup",
    "groupTalk.text14": "Grup pembaruan",
    "groupTalk.text15": "Keluar dari Grup",
    "groupTalk.text16": "Pergi ke Homepage untuk Login",
    "groupTalk.text17": "Nama kelompok (5-100 karakter)",
    "groupTalk.text18": "deskripsi grup (kurang dari 500 karakter)"
  },
  {
    "LANGUAGE": "Icelandic",
    "CODE": "is",
    "test.text1": "Halló",
    "test.text2": "Hi",
    "test.text3": "Þakka þér fyrir",
    "test.text4": "Kort",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Hætta við",
    "general.text2": "Samþykkja",
    "general.text3": "Eyða",
    "general.text4": "eytt",
    "general.text5": "Breyta",
    "general.text6": "Uppfæra",
    "general.text7": "Hlaða inn mynd",
    "general.text8": "Eyða mynd",
    "general.text9": "Mynd",
    "general.text10": "Feed",
    "general.text11": "Skrá inn",
    "general.text12": "Skráðu þig",
    "general.text13": "User Info",
    "general.text14": "Að skrá þig út",
    "general.text15": "Skráin skal vera",
    "general.text16": "og minna en",
    "general.text17": "Lykilorð",
    "general.text18": "uppáhalds Posts",
    "general.text19": "færslur",
    "general.text20": "myndir",
    "general.text21": "Myndbönd",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "nýtt Post",
    "feed.text2": "Sýna notanda færslur",
    "feed.text3": "Sýna Innlegg",
    "feed.text4": "útsýni",
    "feed.text5": "breyta",
    "feed.text6": "eyða",
    "feed.text7": "Er er ekkert mál að eyða færslunni alveg?",
    "feed.text8": "Sent inn af",
    "feed.text9": "búið til af",
    "feed.text10": "Title",
    "feed.text11": "Mynd",
    "feed.text12": "efni",
    "feed.text13": "opinber",
    "feed.text14": "einka",
    "feed.text15": "Title fyrir færslu",
    "feed.text16": "Efni Por færslu",
    "feed.text17": "eyða",
    "feed.text18": "Er það ekki vandamál til að eyða mynd alveg?",
    "feed.text19": "eytt",
    "feed.text20": "Media File ætti að vera jpg, jpeg, png eða mp4 skrá, og minna en 3MB",
    "feed.text21": "opinber staða",
    "feed.text22": "einka færslu",
    "feed.text23": "Slá fyrir leit ....",
    "feed.text24": "Leita notandi ...",
    "__2": "",
    "userInfo.text1": "Upplýsingar um notanda",
    "userInfo.text2": "nafn",
    "userInfo.text3": "mynd",
    "userInfo.text4": "Stofndagur",
    "userInfo.text5": "Er það ekki vandamál til að eyða mynd alveg?",
    "userInfo.text6": "Nýtt nafn ...",
    "userInfo.text7": "Myndskrá ætti að vera jpg, jpeg, eða PNG skrá, og minna en 1MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "um User",
    "userInfo.text10": "viðbótarupplýsingar um þig, lýsing lífrænt etc ... (minna en 300 stafir)",
    "userInfo.text11": "Tungumál",
    "userInfo.text12": "Breyta",
    "userInfo.text13": "Tilkynning",
    "__3": "",
    "auth.text1": "Netfangið þitt",
    "auth.text2": "Nafn þitt",
    "auth.text3": "Lykilorð",
    "auth.text4": "Lykilorðið ætti að vera 8 til 20 stafi, að minnsta kosti einn hástaf, einn lágstaf, eitt númer og eitt sérstakt eðli (@ $!% *? &).",
    "auth.text5": "Skráðu þig",
    "auth.text6": "Skrá inn",
    "auth.text7": "Gleymdu lykilorð",
    "auth.text8": "Skráning Page",
    "auth.text9": "E-mail fyrir að endurstilla lykilorðið",
    "auth.text10": "senda tölvupóst",
    "auth.text11": "Staðfesting mistókst. Gakktu úr skugga um að netfangið sé ekki notað ennþá?",
    "auth.text12": "Notandanafn mistókst!",
    "auth.text13": "Ógilt aðgangsorð var inn",
    "auth.text14": "Tóku Netfangið fannst ekki",
    "auth.text15": "Notandinn tókst að búa!",
    "auth.text16": "Netfangið er þegar í notkun af öðrum reikningi",
    "auth.text17": "Sýna slegið lykilorð",
    "auth.text18": "fela lykilorð",
    "auth.text19": "Tölvupóstur aðgangsorð hvíld var sendur",
    "auth.text20": "Enterd tölvupósti fannst ekki",
    "auth.text21": "Lykilorð endurstillt velgengni",
    "auth.text22": "Lykilorð endurstillt ekki, vinsamlegast fara á innskráningar síðuna og senda tölvupóst til að endurstilla lykilorðið á ný",
    "auth.text23": "Lykilorð endurstillt síðu",
    "auth.text24": "nýtt lykilorð",
    "auth.text25": "Endur stilla lykilorð",
    "auth.text26": "Page er ógilt eða lykilorð er þegar breytt",
    "auth.text27": "Senda tölvupóst til að endurstilla lykilorðið ekki, vinsamlegast athuga netfang",
    "auth.text28": "Tölvupóstur sannprófun síðu",
    "auth.text29": "Email sannprófun árangri skaltu fara á innskráningar síðuna og sýnt er hér að ofan",
    "auth.text30": "Tölvupóstur mistókst. Þessi síða er útrunnið, eða sannprófun er þegar lokið.",
    "auth.text31": "Sannprófun Mail var send. Plese athuga tölvupóstinn þinn og staðfesta. (Einnig chack í spam pósti)",
    "auth.text32": "Staðfesting netfangs er nauðsynlegt að skrá þig inn. Vinsamlegast sendu tölvupóst til staðfestingar á netfangið þitt.",
    "auth.text33": "Senda tölvupóst til staðfestingar",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Skrá inn",
    "nav.text3": "Skráðu þig",
    "nav.text4": "User Info",
    "nav.text5": "Að skrá þig út",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Síða ekki fundin",
    "notFound.text2": "fara til fóðurs síðu",
    "notFound.text3": "fara á innskráningar síðuna",
    "notFound.text4": "Hvert ertu að fara í dag?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Hætta við",
    "comment.text2": "Eyða",
    "comment.text3": "Post Comment",
    "comment.text4": "Sýna Svara",
    "comment.text5": "alls",
    "comment.text6": "skrifa Svara",
    "comment.text7": "fela Svara",
    "comment.text8": "athugasemdir",
    "comment.text9": "Er það ekki vandamál að eyða þessum ummælum og svörum af athugasemdinni alveg?",
    "comment.text10": "Er það ekki vandamál að eyða þessum ummælum alveg?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Bæta við Uppáhalds Post",
    "follow.text2": "Eyða úr uppáhalds færslu",
    "follow.text3": "Bæta við uppáhalds notanda",
    "follow.text4": "Eyða úr uppáhalds notanda",
    "follow.text5": "Sýna færslur",
    "follow.text6": "Uppáhalds notendur þínir",
    "follow.text7": "Post Uppáhalds notendur",
    "__11": "",
    "privacyPolicy.text1": "Friðhelgisstefna",
    "privacyPolicy.text2": "Þessi stefna má uppfæra eða endurskoða án fyrirvara. Það er á ábyrgð notandans að vera upplýst um breytingar á stefnunni. Taka mið af öðrum málefnum næði sem geta haft áhrif þig:",
    "privacyPolicy.text3": "Þessi vefsíða gætu versnað.",
    "privacyPolicy.text4": "Tölvan þín gæti verið í hættu.",
    "privacyPolicy.text5": "Þessi vefsíða er hýst á Firebase og DigitalOcean innra skipulagi. Þeir geta og hafa eigin mælingar kerfi þeirra á netþjónum sínum. Sú þjónusta að hafa eigin persónu þeirra stefnu og þeir eru ekki undir þessa persónuverndarstefnu.",
    "privacyPolicy.text6": "Þetta vefsvæði verslanir email, dulkóðuð form lykilorð og öðrum aðföngum notenda á farfuglaheimili Firebase og DigitalOcean innra skipulagi.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Notenda Skilmálar",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Persónuvernd er okkur mikilvæg. Vinsamlegast lesið",
    "termsOfUse.text4": "friðhelgisstefna",
    "termsOfUse.text5": "fyrir meiri upplýsingar.",
    "termsOfUse.text6": "eigin ábyrgð þína",
    "termsOfUse.text7": "Þú, sem notandi, berð ábyrgð á því eigin farið með lögum og skatta þínu umdæmi. Þú berð ábyrgð á eigin öryggi þitt.",
    "termsOfUse.text8": "Fyrirvari um ábyrgð",
    "termsOfUse.text9": "Þessi síða er veitt á \"eins og er\" GRUNNI, ÁN ÁBYRGÐAR EÐA SKILYRÐI af einhverju tagi.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "stöðva Connect",
    "videoTalk.text3": "Online notendur",
    "videoTalk.text4": "Byrja að tala",
    "videoTalk.text5": "Eru að reyna að tala",
    "videoTalk.text6": "Engin önnur netinu notandi er til",
    "videoTalk.text7": "Þú ert að tala við",
    "videoTalk.text8": "Þú byrjaðir að tala",
    "videoTalk.text9": "Sýna Texti Spjall",
    "videoTalk.text10": "ritun ....",
    "videoTalk.text11": "Þú ert enn að tala. Viltu hætta að tala?",
    "videoTalk.text12": "Hringja notandi aftengdur",
    "videoTalk.text13": "notandi hringir í þig. Þiggur þú þetta símtal?",
    "videoTalk.text14": "hafna símtali þínu",
    "videoTalk.text15": "leita á netinu notanda ...",
    "videoTalk.text16": "leita notanda ...",
    "__14": "",
    "groupTalk.text1": "Höfundur",
    "groupTalk.text2": "Lýsing",
    "groupTalk.text3": "Stofndagur",
    "groupTalk.text4": "Breyta upplýsingum um hóp",
    "groupTalk.text5": "Er það ekki vandamál að eyða þér af meðlimum þessa hóps?",
    "groupTalk.text6": "Fara frá aðildarríki",
    "groupTalk.text7": "Taka þátt í hóp",
    "groupTalk.text8": "Sýna Hópur: Members",
    "groupTalk.text9": "Gera Þú Vilja til að ganga í hópinn?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Til baka í lista",
    "groupTalk.text12": "Staðfesta inntakið",
    "groupTalk.text13": "Búa til hóp",
    "groupTalk.text14": "Uppfæra Group",
    "groupTalk.text15": "Hætta frá Group",
    "groupTalk.text16": "Fara til Homepage að Innskráning",
    "groupTalk.text17": "heiti hópsins (5-100 stafir)",
    "groupTalk.text18": "Hópurinn lýsing (minna en 500 stafir)"
  },
  {
    "LANGUAGE": "Italian",
    "CODE": "it",
    "test.text1": "Ciao",
    "test.text2": "Ciao",
    "test.text3": "Grazie",
    "test.text4": "carta geografica",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Annulla",
    "general.text2": "Accettare",
    "general.text3": "Elimina",
    "general.text4": "Deleted",
    "general.text5": "modificare",
    "general.text6": "Aggiornare",
    "general.text7": "Carica immagine",
    "general.text8": "Elimina immagine",
    "general.text9": "Immagine",
    "general.text10": "Alimentazione",
    "general.text11": "Accesso",
    "general.text12": "Iscriviti",
    "general.text13": "Info Utenti",
    "general.text14": "Disconnettersi",
    "general.text15": "file dovrebbe essere",
    "general.text16": "e meno di",
    "general.text17": "Parola d'ordine",
    "general.text18": "Messaggi preferiti",
    "general.text19": "Messaggi",
    "general.text20": "immagini",
    "general.text21": "video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nuovo messaggio",
    "feed.text2": "Mostra dell'utente Messaggi",
    "feed.text3": "Visualizza post",
    "feed.text4": "Visualizza",
    "feed.text5": "modificare",
    "feed.text6": "Elimina",
    "feed.text7": "È non è un problema per posta cancellare completamente?",
    "feed.text8": "Pubblicato da",
    "feed.text9": "Creato da",
    "feed.text10": "Titolo",
    "feed.text11": "Immagine",
    "feed.text12": "Soddisfare",
    "feed.text13": "pubblico",
    "feed.text14": "privato",
    "feed.text15": "Titolo per il post",
    "feed.text16": "posta por Content",
    "feed.text17": "Elimina",
    "feed.text18": "E 'un problema di immagine cancellare completamente?",
    "feed.text19": "Deleted",
    "feed.text20": "File multimediale dovrebbe essere jpg, jpeg, png o un file mp4, e meno di 3 MB",
    "feed.text21": "post pubblico",
    "feed.text22": "messaggio privato",
    "feed.text23": "Digitare per la ricerca ....",
    "feed.text24": "Cerca utente ...",
    "__2": "",
    "userInfo.text1": "informazioni utente",
    "userInfo.text2": "nome",
    "userInfo.text3": "Immagine",
    "userInfo.text4": "data di creazione",
    "userInfo.text5": "E 'un problema di immagine cancellare completamente?",
    "userInfo.text6": "Nuovo nome ...",
    "userInfo.text7": "file di immagine deve essere jpg, jpeg, png o un file, e meno di 1 MB",
    "userInfo.text8": "Modalità scura",
    "userInfo.text9": "utente Informazioni su",
    "userInfo.text10": "ulteriori informazioni su di te, descrizione bio ecc ... (meno di 300 caratteri)",
    "userInfo.text11": "linguaggio",
    "userInfo.text12": "Modificare",
    "userInfo.text13": "Notifica",
    "__3": "",
    "auth.text1": "La tua email",
    "auth.text2": "Il tuo nome",
    "auth.text3": "Parola d'ordine",
    "auth.text4": "La password deve essere da 8 a 20 caratteri, almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale (@ $!% *? &).",
    "auth.text5": "Iscriviti",
    "auth.text6": "Accesso",
    "auth.text7": "Dimenticare la password",
    "auth.text8": "Iscriviti Pagina",
    "auth.text9": "Indirizzo e-mail per la reimpostazione della password",
    "auth.text10": "invia una email",
    "auth.text11": "Validazione fallita. Verificare che l'indirizzo di posta elettronica non è ancora utilizzato!",
    "auth.text12": "Login utente fallito!",
    "auth.text13": "Password non valida è stato inserito",
    "auth.text14": "indirizzo e-mail inserito non è stato trovato",
    "auth.text15": "Creazione utente non riuscita!",
    "auth.text16": "L'indirizzo e-mail è già in uso da un altro account",
    "auth.text17": "Mostra password inserita",
    "auth.text18": "Nascondi password",
    "auth.text19": "e-mail per la password resto è stato inviato",
    "auth.text20": "e-mail enterd non è stato trovato",
    "auth.text21": "successo reimpostazione della password",
    "auth.text22": "reimpostazione della password non è riuscito, per favore vai alla pagina di accesso e invia un'email per la reimpostazione della password di nuovo",
    "auth.text23": "Pagina reimpostazione della password",
    "auth.text24": "nuova password",
    "auth.text25": "Resetta la password",
    "auth.text26": "Pagina non è valido o password è già cambiata",
    "auth.text27": "L'invio di e-mail per la reimpostazione della password non è riuscita, si prega di controllare indirizzo e-mail",
    "auth.text28": "pagina di verifica e-mail",
    "auth.text29": "E-mail di verifica di successo, vai alla pagina di login mostrata sopra",
    "auth.text30": "Verifica email non è riuscita. Questa pagina è scaduto, o la verifica è già finito.",
    "auth.text31": "Mail di verifica è stato inviato. Plese controllare la posta elettronica e verificare. (Chack anche nella posta spam)",
    "auth.text32": "E-mail di verifica è necessario effettuare il login. Si prega di inviare e-mail per la verifica al tuo indirizzo email.",
    "auth.text33": "Invia E-mail per la verifica",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Alimentazione",
    "nav.text2": "Accesso",
    "nav.text3": "Iscriviti",
    "nav.text4": "Info Utenti",
    "nav.text5": "Disconnettersi",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Pagina non trovata",
    "notFound.text2": "vai alla pagina di alimentazione",
    "notFound.text3": "vai alla pagina di login",
    "notFound.text4": "Dove andate oggi?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Annulla",
    "comment.text2": "Elimina",
    "comment.text3": "Posta un commento",
    "comment.text4": "Visualizza la risposta",
    "comment.text5": "totale",
    "comment.text6": "scrivi una risposta",
    "comment.text7": "Nascondi Rispondi",
    "comment.text8": "Commenti",
    "comment.text9": "E 'un problema di eliminare questo commento e le risposte di questo commento del tutto?",
    "comment.text10": "E 'un problema di eliminare completamente questo commento?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Aggiungi a preferiti Invia",
    "follow.text2": "Eliminare dal post preferito",
    "follow.text3": "Aggiungi a utente preferito",
    "follow.text4": "Elimina dal utente preferito",
    "follow.text5": "Visualizza i messaggi",
    "follow.text6": "I suoi Utenti preferiti",
    "follow.text7": "Gli utenti post preferiti",
    "__11": "",
    "privacyPolicy.text1": "politica sulla riservatezza",
    "privacyPolicy.text2": "Questa politica può essere aggiornato o modificato senza alcun preavviso. È la responsabilità dell'utente per rimanere informati sui cambiamenti privacy policy. Prendete nota di altre questioni di privacy che vi riguardano:",
    "privacyPolicy.text3": "Questo sito potrebbe essere compromesso.",
    "privacyPolicy.text4": "Il computer potrebbe essere compromessa.",
    "privacyPolicy.text5": "Questo sito è ospitato su infrastrutture Firebase e DigitalOcean. Essi possono e hanno i loro propri sistemi di monitoraggio sui loro server. Tali servizi hanno le proprie politiche sulla privacy e non sono coperti da questa politica sulla privacy.",
    "privacyPolicy.text6": "Questo sito Web memorizza e-mail, forma crittografata di password e altri ingressi di utenti in infrastrutture Firebase e DigitalOcean ospitati.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Condizioni d'uso",
    "termsOfUse.text2": "vita privata",
    "termsOfUse.text3": "La tua privacy è importante per noi. Si prega di leggere il",
    "termsOfUse.text4": "politica sulla riservatezza",
    "termsOfUse.text5": "per maggiori informazioni.",
    "termsOfUse.text6": "Le proprie responsabilità",
    "termsOfUse.text7": "Si, l'utente, l'unico responsabile per garantire il proprio rispetto delle leggi e delle imposte nella vostra giurisdizione. Sei il solo responsabile per la propria sicurezza.",
    "termsOfUse.text8": "Esclusione di garanzia",
    "termsOfUse.text9": "Questo sito è fornito \"COSÌ COM'È\", SENZA GARANZIE O CONDIZIONI DI ALCUN TIPO.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "avvio Collegare",
    "videoTalk.text2": "Arrestate Connect",
    "videoTalk.text3": "Utenti online",
    "videoTalk.text4": "Iniziare a parlare",
    "videoTalk.text5": "Il vostro stanno cercando di parlare",
    "videoTalk.text6": "Non esiste altro utente on-line",
    "videoTalk.text7": "Si sta parlando",
    "videoTalk.text8": "Hai iniziato a parlare",
    "videoTalk.text9": "Mostra testo di conversazione",
    "videoTalk.text10": "l'immissione di testo ....",
    "videoTalk.text11": "Si sta ancora parlando. Vuoi smettere di parlare?",
    "videoTalk.text12": "Chiamata utente disconnesso",
    "videoTalk.text13": "utente sta chiamando. Accettate questa chiamata?",
    "videoTalk.text14": "rifiutare la chiamata",
    "videoTalk.text15": "cercare utenti on-line ...",
    "videoTalk.text16": "cercare utente ...",
    "__14": "",
    "groupTalk.text1": "Creatore",
    "groupTalk.text2": "Descrizione",
    "groupTalk.text3": "data di creazione",
    "groupTalk.text4": "Informazioni Modifica gruppo",
    "groupTalk.text5": "E 'un problema di cancellarti dal membri di questo gruppo?",
    "groupTalk.text6": "Lasciare da parte degli Stati",
    "groupTalk.text7": "Unirsi al gruppo",
    "groupTalk.text8": "Mostra Membri del Gruppo",
    "groupTalk.text9": "Sei vuoi unirti a questo gruppo?",
    "groupTalk.text10": "Nome del gruppo",
    "groupTalk.text11": "Torna alla lista",
    "groupTalk.text12": "Confermare l'introduzione",
    "groupTalk.text13": "Creare un gruppo",
    "groupTalk.text14": "Gruppo Aggiornamento",
    "groupTalk.text15": "Uscita dal Gruppo",
    "groupTalk.text16": "Vai alla Homepage di Accesso",
    "groupTalk.text17": "il nome del gruppo (5-100 caratteri)",
    "groupTalk.text18": "descrizione del gruppo (meno di 500 caratteri)"
  },
  {
    "LANGUAGE": "Japanese",
    "CODE": "ja",
    "test.text1": "こんにちは",
    "test.text2": "こんにちは",
    "test.text3": "ありがとうございました",
    "test.text4": "地図",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "キャンセル",
    "general.text2": "受け入れます",
    "general.text3": "[削除]",
    "general.text4": "削除されました",
    "general.text5": "編集します",
    "general.text6": "更新",
    "general.text7": "画像をアップロード",
    "general.text8": "削除画像",
    "general.text9": "画像",
    "general.text10": "フィード",
    "general.text11": "ログインする",
    "general.text12": "サインアップ",
    "general.text13": "ユーザー情報",
    "general.text14": "ログアウト",
    "general.text15": "ファイルがなければなりません",
    "general.text16": "未満",
    "general.text17": "パスワード",
    "general.text18": "お気に入り投稿",
    "general.text19": "投稿",
    "general.text20": "画像",
    "general.text21": "ビデオ",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "新しいポスト",
    "feed.text2": "ユーザーの投稿を表示",
    "feed.text3": "投稿を表示",
    "feed.text4": "見る",
    "feed.text5": "編集します",
    "feed.text6": "削除",
    "feed.text7": "ですが、完全に削除ポストに問題はありませんか？",
    "feed.text8": "投稿者",
    "feed.text9": "によって作成された",
    "feed.text10": "題名",
    "feed.text11": "画像",
    "feed.text12": "コンテンツ",
    "feed.text13": "公衆",
    "feed.text14": "民間",
    "feed.text15": "ポストのためのタイトル",
    "feed.text16": "コンテンツポル・ポスト",
    "feed.text17": "削除",
    "feed.text18": "それは完全に削除画像に問題はありませんか？",
    "feed.text19": "削除されました",
    "feed.text20": "メディアファイルはJPG、JPEG、PNGまたはmp4ファイルも、少ない3メガバイト以上必要があります",
    "feed.text21": "公共のポスト",
    "feed.text22": "プライベートポスト",
    "feed.text23": "検索のために入力し....",
    "feed.text24": "ユーザーを検索...",
    "__2": "",
    "userInfo.text1": "ユーザー情報",
    "userInfo.text2": "名前",
    "userInfo.text3": "画像",
    "userInfo.text4": "作成日",
    "userInfo.text5": "それは完全に削除画像に問題はありませんか？",
    "userInfo.text6": "新しい名前 ...",
    "userInfo.text7": "画像ファイルはJPG、JPEG、またはPNGファイル、および1MB未満でなければなりません",
    "userInfo.text8": "ダークモード",
    "userInfo.text9": "ユーザーについて",
    "userInfo.text10": "あなたの詳細については、説明バイオ等...（未満300文字）",
    "userInfo.text11": "言語",
    "userInfo.text12": "変化する",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "あなたのEメール",
    "auth.text2": "あなたの名前",
    "auth.text3": "パスワード",
    "auth.text4": "パスワードは8〜20文字、少なくとも一つの大文字、小文字1つの文字、1つのナンバーと一つの特殊文字でなければなりません（@ $！％*？＆）。",
    "auth.text5": "サインアップ",
    "auth.text6": "ログインする",
    "auth.text7": "パスワードを忘れました",
    "auth.text8": "申し込みページ",
    "auth.text9": "パスワードリセットのためのE-mailアドレス",
    "auth.text10": "メールを送る",
    "auth.text11": "検証に失敗しました。ていることを確認したメールアドレスがまだ使用されていません！",
    "auth.text12": "ユーザーログインに失敗しました！",
    "auth.text13": "無効なパスワードが入力されました",
    "auth.text14": "入力されたメールアドレスが見つかりませんでした",
    "auth.text15": "ユーザーの作成に失敗しました！",
    "auth.text16": "メールアドレスは別のアカウントで既に使用されています",
    "auth.text17": "入力されたパスワードを表示します",
    "auth.text18": "隠すパスワード",
    "auth.text19": "パスワードの残りのための電子メールが送信されました",
    "auth.text20": "Enterd電子メールが見つかりませんでした",
    "auth.text21": "パスワードのリセット成功",
    "auth.text22": "パスワードのリセットは、ログインページに移動し、もう一度パスワードリセットのための電子メールを送ってください、失敗しました",
    "auth.text23": "パスワードのリセットページ",
    "auth.text24": "新しいパスワード",
    "auth.text25": "パスワードを再設定する",
    "auth.text26": "ページは無効であるか、またはパスワードがすでに変更されました",
    "auth.text27": "失敗したパスワードリセットのための電子メールの送信、メールアドレスをご確認ください",
    "auth.text28": "電子メールの確認ページ",
    "auth.text29": "成功の確認メールは、上に示したログインページをご覧ください",
    "auth.text30": "メールの確認に失敗しました。このページは有効期限が切れている、または検証がすでに終了しています。",
    "auth.text31": "検証メールが送信されました。 pleseはあなたの電子メールをチェックして確認してください。 （スパムメールにCHACK）",
    "auth.text32": "メールの検証はログインが必要です。あなたのメールアドレスに確認用のメールを送ってください。",
    "auth.text33": "検証のために電子メールを送信",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "フィード",
    "nav.text2": "ログインする",
    "nav.text3": "サインアップ",
    "nav.text4": "ユーザー情報",
    "nav.text5": "ログアウト",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "ページが見つかりません",
    "notFound.text2": "フィードページに移動します",
    "notFound.text3": "ログインページにアクセスしてください",
    "notFound.text4": "今日はどこに行くのですか？",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "キャンセル",
    "comment.text2": "[削除]",
    "comment.text3": "コメントを投稿",
    "comment.text4": "表示返信",
    "comment.text5": "トータル",
    "comment.text6": "書き込みに返信",
    "comment.text7": "非表示返信",
    "comment.text8": "注釈",
    "comment.text9": "完全にこのコメントのこのコメントや返信を削除するには問題ありませんか？",
    "comment.text10": "完全にこのコメントを削除するには問題ありませんか？",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "お気に入りのポストに追加",
    "follow.text2": "お気に入りのポストから削除します。",
    "follow.text3": "お気に入りのユーザーに追加",
    "follow.text4": "お気に入りのユーザーからの削除",
    "follow.text5": "ショーのポスト",
    "follow.text6": "あなたのお気に入りユーザー",
    "follow.text7": "ポストお気に入りのユーザー",
    "__11": "",
    "privacyPolicy.text1": "個人情報保護方針",
    "privacyPolicy.text2": "このポリシーは、予告なしに更新または改訂することができます。これは、プライバシーポリシーの変更について通知滞在する利用者の責任です。あなたに影響を与える可能性があり、他のプライバシーの問題に注意してください：",
    "privacyPolicy.text3": "このウェブサイトは危険にさらされる可能性があります。",
    "privacyPolicy.text4": "お使いのコンピュータが危険にさらされる可能性があります。",
    "privacyPolicy.text5": "このウェブサイトはFirebaseとDigitalOceanインフラ上でホストされています。彼らはかもしれないし、そのサーバー上で、独自のトラッキングシステムを持っています。これらのサービスは、独自のプライバシーポリシーを持っており、彼らはこのプライバシーポリシーによってカバーされていません。",
    "privacyPolicy.text6": "このウェブサイトを格納した電子メール、パスワードの暗号化された形式、およびホストさFirebaseとDigitalOceanインフラでのユーザーの他の入力。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "利用規約",
    "termsOfUse.text2": "プライバシー",
    "termsOfUse.text3": "あなたのプライバシーは私達にとって重要です。お読みください",
    "termsOfUse.text4": "個人情報保護方針",
    "termsOfUse.text5": "詳細については。",
    "termsOfUse.text6": "ご自身の責任",
    "termsOfUse.text7": "あなたは、ユーザーは、自分の管轄の法律、税金を使用して独自の遵守を確保する責任があります。あなたは、あなた自身の安全保障のために責任を負うものとします。",
    "termsOfUse.text8": "保証の免責事項",
    "termsOfUse.text9": "このサイトは、いかなる種類の保証または条件なし、BASIS「AS ISは、」上に設けられています。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "スタート接続",
    "videoTalk.text2": "ストップ接続",
    "videoTalk.text3": "オンラインユーザー",
    "videoTalk.text4": "話を開始",
    "videoTalk.text5": "あなたは話にしようとしています",
    "videoTalk.text6": "他のオンラインユーザーは存在しません",
    "videoTalk.text7": "あなたは話しています",
    "videoTalk.text8": "あなたは話を始めました",
    "videoTalk.text9": "表示するテキストの話",
    "videoTalk.text10": "テキスト入力....",
    "videoTalk.text11": "あなたはまだ話しています。あなたは話を停止しますか？",
    "videoTalk.text12": "ユーザーの呼び出し切断",
    "videoTalk.text13": "ユーザーがあなたを呼んでいます。あなたはこのコールを受け入れますか？",
    "videoTalk.text14": "あなたの呼び出しを拒否",
    "videoTalk.text15": "オンラインユーザーを検索...",
    "videoTalk.text16": "検索ユーザー...",
    "__14": "",
    "groupTalk.text1": "クリエーター",
    "groupTalk.text2": "説明",
    "groupTalk.text3": "作成日",
    "groupTalk.text4": "編集グループ情報",
    "groupTalk.text5": "それは、このグループのメンバーからあなたを削除しても問題はありませんか？",
    "groupTalk.text6": "メンバーから脱退",
    "groupTalk.text7": "グループに参加",
    "groupTalk.text8": "ショーグループメンバー",
    "groupTalk.text9": "あなたはこのグループに参加したいですか？",
    "groupTalk.text10": "グループ名",
    "groupTalk.text11": "一覧へ戻ります",
    "groupTalk.text12": "あなたの入力を確認",
    "groupTalk.text13": "グループを作る",
    "groupTalk.text14": "更新グループ",
    "groupTalk.text15": "グループを終了します",
    "groupTalk.text16": "ログインしてホームページへ",
    "groupTalk.text17": "グループ名（5-100文字）",
    "groupTalk.text18": "グループの説明（500未満文字）"
  },
  {
    "LANGUAGE": "Georgian",
    "CODE": "ka",
    "test.text1": "გაუმარჯოს",
    "test.text2": "Hi",
    "test.text3": "Გმადლობთ",
    "test.text4": "რუკაზე",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "გაუქმება",
    "general.text2": "მიღება",
    "general.text3": "Delete",
    "general.text4": "ამოღებულია",
    "general.text5": "რედაქტირება",
    "general.text6": "განახლების",
    "general.text7": "Სურათის ატვირთვა",
    "general.text8": "სურათის წაშლა",
    "general.text9": "Image",
    "general.text10": "Feed",
    "general.text11": "Შესვლა",
    "general.text12": "რეგისტრაცია",
    "general.text13": "მომხმარებელი ინფორმაცია",
    "general.text14": "გამოსვლა",
    "general.text15": "ფაილი უნდა იყოს",
    "general.text16": "და ნაკლები",
    "general.text17": "პაროლი",
    "general.text18": "საყვარელი პოსტები",
    "general.text19": "პოსტები",
    "general.text20": "სურათები",
    "general.text21": "ვიდეოები",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Ახალი პოსტი",
    "feed.text2": "შოუ მომხმარებლის პოსტები",
    "feed.text3": "პოსტების ჩვენება",
    "feed.text4": "კალენდარი",
    "feed.text5": "რედაქტირება",
    "feed.text6": "წაშლა",
    "feed.text7": "არის პრობლემა არ წაშალოთ პოსტი მთლიანად?",
    "feed.text8": "გამოგზავნილია",
    "feed.text9": "Შექმნილია მიერ",
    "feed.text10": "სტატუსი:",
    "feed.text11": "Image",
    "feed.text12": "Content",
    "feed.text13": "საჯარო",
    "feed.text14": "კერძო",
    "feed.text15": "სათაური პოსტი",
    "feed.text16": "Content por პოსტი",
    "feed.text17": "წაშლა",
    "feed.text18": "არის ეს არ არის პრობლემა, რომ სურათის წაშლა მთლიანად?",
    "feed.text19": "ამოღებულია",
    "feed.text20": "მედია ფაილი უნდა იყოს jpg, jpeg, png ან mp4 ფაილი, და ნაკლები 3MB",
    "feed.text21": "საჯარო ჩანაწერი",
    "feed.text22": "დახურული ჩანაწერი",
    "feed.text23": "ტიპი ძებნა ....",
    "feed.text24": "ძებნა მომხმარებელი ...",
    "__2": "",
    "userInfo.text1": "შესახებ ინფორმაცია",
    "userInfo.text2": "სახელი",
    "userInfo.text3": "სურათი",
    "userInfo.text4": "შექმნის თარიღი",
    "userInfo.text5": "არის ეს არ არის პრობლემა, რომ სურათის წაშლა მთლიანად?",
    "userInfo.text6": "New სახელი ...",
    "userInfo.text7": "გამოსახულება ფაილი უნდა იყოს jpg, jpeg, ან png ფაილი, და ნაკლები 1MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "მომხმარებლის შესახებ",
    "userInfo.text10": "დამატებითი ინფორმაცია თქვენს შესახებ, ბიო და ა.შ ... (არანაკლებ 300 სიმბოლო)",
    "userInfo.text11": "Ენა",
    "userInfo.text12": "შეცვლა",
    "userInfo.text13": "შეტყობინება",
    "__3": "",
    "auth.text1": "Თქვენი ელ. ფოსტა",
    "auth.text2": "Თქვენი სახელი",
    "auth.text3": "პაროლი",
    "auth.text4": "პაროლი უნდა იყოს 8-დან 20 პერსონაჟი, მინიმუმ ერთი ზედა წერილი, ერთი ამას წერილი, ერთი ნომერი და ერთი სპეციალური ხასიათი (@ $!% *? &).",
    "auth.text5": "რეგისტრაცია",
    "auth.text6": "Შესვლა",
    "auth.text7": "Პაროლის დავიწყება",
    "auth.text8": "რეგისტრაციის გვერდზე",
    "auth.text9": "E-mail მისამართი პაროლის აღდგენა",
    "auth.text10": "გაუგზავნე E-mail",
    "auth.text11": "დადასტურება ვერ მოხერხდა. დარწმუნდით, რომ ელექტრონული ფოსტის მისამართი არ გამოიყენება ჯერ!",
    "auth.text12": "მომხმარებლის შესვლა ვერ მოხერხდა!",
    "auth.text13": "არასწორი პაროლი შევიდა",
    "auth.text14": "შეყვანილი ელფოსტის მისამართი არ იქნა ნაპოვნი",
    "auth.text15": "მომხმარებლის შექმნა ვერ მოხერხდა!",
    "auth.text16": "ელ-ფოსტის მისამართი უკვე გამოიყენება სხვა ანგარიშზე",
    "auth.text17": "ჩვენება შევიდა პაროლი",
    "auth.text18": "Hide პაროლი",
    "auth.text19": "ელ დაგავიწყდათ დანარჩენი გაიგზავნა",
    "auth.text20": "Enterd ელ არ იქნა ნაპოვნი",
    "auth.text21": "პაროლის აღდგენა წარმატება",
    "auth.text22": "პაროლის აღდგენა ვერ მოხერხდა, გთხოვთ გადადით გვერდზე შესვლა და პირადი ელ პაროლის აღდგენა ერთხელ",
    "auth.text23": "პაროლის აღდგენა გვერდი",
    "auth.text24": "ახალი პაროლი",
    "auth.text25": "პაროლის",
    "auth.text26": "გვერდზე არასწორია ან პაროლი უკვე შეიცვალა",
    "auth.text27": "გაგზავნის ელ პაროლის აღდგენა ვერ მოხერხდა, გთხოვთ გამშვები ფოსტის მისამართი",
    "auth.text28": "ელ გადამოწმების გვერდი",
    "auth.text29": "ელ გადამოწმების წარმატება, გთხოვთ წასვლა შესვლის გვერდზე ნაჩვენებია ზემოთ",
    "auth.text30": "ელ გადამოწმების ვერ მოხერხდა. ეს გვერდი ვადა გაუვიდა, ან შემოწმების უკვე დასრულდა.",
    "auth.text31": "შემოწმება ფოსტა გაიგზავნა. Plese გადაამოწმოთ ელფოსტა და გადაამოწმონ. (ასევე chack in spam mail)",
    "auth.text32": "ელ-ფოსტის შემოწმება არის საჭირო შესვლა. გამოაგზავნეთ ელ-ფოსტის შემოწმების თქვენი ელ-ფოსტის მისამართი.",
    "auth.text33": "წერილის გაგზავნა გადამოწმების",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Შესვლა",
    "nav.text3": "რეგისტრაცია",
    "nav.text4": "მომხმარებელი ინფორმაცია",
    "nav.text5": "გამოსვლა",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Გვერდი არ მოიძებნა",
    "notFound.text2": "წასვლა feed გვერდი",
    "notFound.text3": "წასვლა შესვლის გვერდზე",
    "notFound.text4": "სად წავიდეთ დღეს?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "გაუქმება",
    "comment.text2": "Delete",
    "comment.text3": "კომენტარის დატოვება",
    "comment.text4": "Show პასუხის",
    "comment.text5": "სულ",
    "comment.text6": "დაწერეთ პასუხი",
    "comment.text7": "Hide პასუხის",
    "comment.text8": "კომენტარები",
    "comment.text9": "არის ეს არ არის პრობლემა ამ კომენტარის წაშლა და პასუხები ამ კომენტარის მთლიანად?",
    "comment.text10": "არის ეს არ არის პრობლემა ამ კომენტარის წაშლა მთლიანად?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "რჩეულებში Post",
    "follow.text2": "წაშლა საყვარელი პოსტი",
    "follow.text3": "დაამატე საყვარელ შესახებ",
    "follow.text4": "წაშლა საყვარელი შესახებ",
    "follow.text5": "პოსტების ჩვენება",
    "follow.text6": "შენი საყვარელი მომხმარებელი",
    "follow.text7": "Post საყვარელი მომხმარებელი",
    "__11": "",
    "privacyPolicy.text1": "Კონფიდენციალურობის პოლიტიკა",
    "privacyPolicy.text2": "ეს პოლიტიკა შეიძლება მფლობელის მხრიდან ან შესწორებული გაფრთხილების გარეშე. ეს არის პასუხისმგებლობის შესახებ დარჩენა ინფორმირებული კონფიდენციალურობის პოლიტიკა ცვლილებები. მიიღოს ნოტა სხვა კონფიდენციალურობის საკითხები, რომელიც შეიძლება გავლენა მოახდინოს თქვენ:",
    "privacyPolicy.text3": "ეს საიტი შეიძლება დათმობაზე წავიდა.",
    "privacyPolicy.text4": "თქვენი კომპიუტერი შეიძლება დათმობაზე წავიდა.",
    "privacyPolicy.text5": "ეს საიტი უმასპინძლა Firebase და DigitalOcean ინფრასტრუქტურა. მათ შეუძლიათ და არ აქვს საკუთარი ნავიგაციის სისტემები მათი სერვერები. იმ მომსახურების აქვს საკუთარი კონფიდენციალურობის პოლიტიკას და ისინი არ არის გათვალისწინებული ამ კონფიდენციალურობის პოლიტიკა.",
    "privacyPolicy.text6": "ეს საიტი მაღაზიები ელ, დაშიფრული სახით პაროლი, და სხვა საშუალებებით წევრებს უმასპინძლა Firebase და DigitalOcean ინფრასტრუქტურა.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Მოხმარების პირობები",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "შენი კონფიდენციალურობის არის ჩვენთვის მნიშვნელოვანია. გთხოვთ წაიკითხოთ",
    "termsOfUse.text4": "კონფიდენციალურობის პოლიტიკა",
    "termsOfUse.text5": "დამატებითი ინფორმაცია.",
    "termsOfUse.text6": "საკუთარი პასუხისმგებლობა",
    "termsOfUse.text7": "თქვენ, შესახებ, რომლებიც მხოლოდ პასუხისმგებელია საკუთარი კანონების და გადასახადების თქვენს იურისდიქციაში. მხოლოდ თქვენ ხართ პასუხისმგებელი საკუთარი უსაფრთხოების.",
    "termsOfUse.text8": "გარანტიის უარყოფა",
    "termsOfUse.text9": "ეს საიტი არის გათვალისწინებული \"როგორც არის\" საფუძველზე, გარანტიების და პირობების გარეშე ნებისმიერი სახის.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "დაწყება Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Online მომხმარებელი",
    "videoTalk.text4": "დაწყება გაიგო",
    "videoTalk.text5": "თქვენი ცდილობთ განხილვა",
    "videoTalk.text6": "არც ერთ სხვა მომხმარებლების არსებობს",
    "videoTalk.text7": "თქვენ საუბრობთ",
    "videoTalk.text8": "თქვენ დაიწყო გაიგო",
    "videoTalk.text9": "Show ტექსტი Talk",
    "videoTalk.text10": "ტექსტის შეყვანა ....",
    "videoTalk.text11": "თქვენ ჯერ კიდევ საუბარი. გსურთ შეწყვიტოს საუბარი?",
    "videoTalk.text12": "დარეკვის შესახებ გათიშულია",
    "videoTalk.text13": "შესახებ გირეკავთ. მიგაჩნიათ თუ არა მიიღოს ამ ზარის?",
    "videoTalk.text14": "უარი თქვას თქვენი სატელეფონო ზარი",
    "videoTalk.text15": "ძიება ამჟამად შესახებ ...",
    "videoTalk.text16": "ძიება შესახებ ...",
    "__14": "",
    "groupTalk.text1": "შემოქმედი",
    "groupTalk.text2": "აღწერა",
    "groupTalk.text3": "შექმნის თარიღი",
    "groupTalk.text4": "ჯგუფის რედაქტირება ინფორმაცია",
    "groupTalk.text5": "არის ეს არ არის პრობლემა წაშლა თქვენ ამ ჯგუფის წევრებს?",
    "groupTalk.text6": "მიადევნე წევრი",
    "groupTalk.text7": "Შეუერთდი ჯგუფს",
    "groupTalk.text8": "Show ჯგუფი: წევრი",
    "groupTalk.text9": "გინდა, რომ ამ ჯგუფში?",
    "groupTalk.text10": "Ჯგუფის სახელი",
    "groupTalk.text11": "უკან დაბრუნება",
    "groupTalk.text12": "დაადასტურეთ თქვენი input",
    "groupTalk.text13": "ჯგუფის შექმნა",
    "groupTalk.text14": "განახლების Group",
    "groupTalk.text15": "გასვლის Group",
    "groupTalk.text16": "გადასვლა მთავარ შესვლა",
    "groupTalk.text17": "ჯგუფის სახელი (5-100 სიმბოლო)",
    "groupTalk.text18": "ჯგუფი აღწერა (არანაკლებ 500 სიმბოლო)"
  },
  {
    "LANGUAGE": "Kazakh",
    "CODE": "kk",
    "test.text1": "Сәлеметсіз бе",
    "test.text2": "Hi",
    "test.text3": "Рақмет сізге",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "күшін жою",
    "general.text2": "қабылдау",
    "general.text3": "Жою",
    "general.text4": "жойылған",
    "general.text5": "редакциялау",
    "general.text6": "жаңарту",
    "general.text7": "жүктеу Image",
    "general.text8": "жою Image",
    "general.text9": "бейне",
    "general.text10": "жем",
    "general.text11": "Кіру",
    "general.text12": "Тіркелу",
    "general.text13": "пайдаланушы ақпарат",
    "general.text14": "Шығу",
    "general.text15": "файл болуы тиіс",
    "general.text16": "және кем",
    "general.text17": "пароль",
    "general.text18": "сүйікті Хабарламалар",
    "general.text19": "Хабарламалар",
    "general.text20": "Суреттер",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "жаңа Post",
    "feed.text2": "Пайдаланушы хабарламалар көрсету",
    "feed.text3": "көрсету Хабарлар",
    "feed.text4": "көрініс",
    "feed.text5": "редакциялау",
    "feed.text6": "жою",
    "feed.text7": "Is толығымен жою лауазымына ешқандай проблема болып табылады?",
    "feed.text8": "Жарияланды",
    "feed.text9": "Жасалған",
    "feed.text10": "тақырып",
    "feed.text11": "бейне",
    "feed.text12": "Мазмұны",
    "feed.text13": "қоғамдық",
    "feed.text14": "жеке",
    "feed.text15": "лауазымына атауы",
    "feed.text16": "Мазмұн POR кейінгі",
    "feed.text17": "жою",
    "feed.text18": "ол толығымен жою кескінге ешқандай проблема болып табылады?",
    "feed.text19": "жойылған",
    "feed.text20": "БАҚ Файл JPG, JPEG, PNG немесе mp4 файл болуы, және аз 3МБ қарағанда тиіс",
    "feed.text21": "қоғамдық кейінгі",
    "feed.text22": "жеке пошта",
    "feed.text23": "Іздеу үшін Түр ....",
    "feed.text24": "Пайдаланушы Іздеу ...",
    "__2": "",
    "userInfo.text1": "пайдаланушы ақпарат",
    "userInfo.text2": "ат",
    "userInfo.text3": "бейне",
    "userInfo.text4": "Жасалған күні",
    "userInfo.text5": "ол толығымен жою кескінге ешқандай проблема болып табылады?",
    "userInfo.text6": "Жаңа атауы ...",
    "userInfo.text7": "Image файл JPG, JPEG, немесе PNG файл, және 1Мбайттан аз болуы тиіс",
    "userInfo.text8": "Dark режимі",
    "userInfo.text9": "қолданушы туралы",
    "userInfo.text10": "сіз туралы қосымша ақпаратты, сипаттамасы био және т.б. ... (кем дегенде 300 таңба)",
    "userInfo.text11": "тіл",
    "userInfo.text12": "өзгеріс",
    "userInfo.text13": "хабарландыру",
    "__3": "",
    "auth.text1": "Сіздің E-пошта",
    "auth.text2": "Сіздің атыңыз",
    "auth.text3": "пароль",
    "auth.text4": "Құпия сөз (@ $!% *? &) Бір кіші әріптер, бір санын және бір арнайы сипаттағы, кем дегенде, бір бас әріп, 8-ден 20 таңбадан тұруы тиіс.",
    "auth.text5": "Тіркелу",
    "auth.text6": "Кіру",
    "auth.text7": "Парольді ұмыттыңыз",
    "auth.text8": "Тіркелу бет",
    "auth.text9": "Құпиясөзді қалпына келтіру үшін Электрондық пошта мекен-жайы",
    "auth.text10": "Электрондық пошта хабарламасын жіберу",
    "auth.text11": "Тексеру сәтсіз аяқталды. көз электрондық пошта мекен-жайы әлі пайдаланылмаса, жасау!",
    "auth.text12": "Жүйеге кіру Қолданушының аты сәтсіз!",
    "auth.text13": "Жарамсыз құпия сөз енгізілген болатын",
    "auth.text14": "Енгізілген электрондық пошта мекен-жайы табылған жоқ",
    "auth.text15": "Пайдаланушы құру сәтсіз аяқталды!",
    "auth.text16": "электрондық пошта мекенжайы басқа шотқа пайдалануға бұрыннан бар",
    "auth.text17": "Енгізген жаңа пароль көрсету",
    "auth.text18": "жасыру Пароль",
    "auth.text19": "құпия сөз демалыс үшін электрондық пошта жіберілді",
    "auth.text20": "Enterd электрондық пошта табылған жоқ",
    "auth.text21": "Құпия сөзді қалпына келтіру табысы",
    "auth.text22": "Құпия сөзді қалпына келтіру Логин бетке өтіңіз және қайтадан Құпиясөзді қалпына келтіру үшін электрондық поштаны жіберуге сұраймыз, сәтсіз",
    "auth.text23": "Құпия сөзді қалпына келтіру бет",
    "auth.text24": "Жаңа Құпия Сөз",
    "auth.text25": "Құпиясөзді қалпына келтіру",
    "auth.text26": "Бет жарамсыз немесе Password өзінде өзгертілген болып табылады",
    "auth.text27": "сәтсіз Құпиясөзді қалпына келтіру үшін электрондық поштаны жіберу, электрондық пошта мекенжайын тексеріңіз сұраймыз",
    "auth.text28": "Email тексеру бет",
    "auth.text29": "Email тексеру табысы, жоғарыда көрсетілген кіру бетке өтіңіз",
    "auth.text30": "Email тексеру сәтсіз аяқталды. Бұл бет мерзімі біткен, немесе тексеру өзінде аяқталды.",
    "auth.text31": "Тексеру Mail жіберілді. Пожалуйста сіздің электрондық поштаны тексеру және тексеру. (Сондай-ақ, спам жылы chack)",
    "auth.text32": "Email Тексеру Логин қажет. Сіздің электрондық пошта мекенжайына тексеру үшін электрондық пошта жіберіңіз.",
    "auth.text33": "тексеру үшін Email жіберу",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "жем",
    "nav.text2": "Кіру",
    "nav.text3": "Тіркелу",
    "nav.text4": "пайдаланушы ақпарат",
    "nav.text5": "Шығу",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Бет табылмады",
    "notFound.text2": "жемшөп бетке өтіңіз",
    "notFound.text3": "кіру бетке өтіңіз",
    "notFound.text4": "Сіз бүгін қайда барамыз?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "күшін жою",
    "comment.text2": "Жою",
    "comment.text3": "Post Пікір",
    "comment.text4": "көрсету Жауап",
    "comment.text5": "жалпы",
    "comment.text6": "жазу Жауап",
    "comment.text7": "жасыру Жауап",
    "comment.text8": "Пікірлер",
    "comment.text9": "толық Бұл мәндемеге осы түсініктеме және жауап жою үшін ешқандай проблема ол?",
    "comment.text10": "толық Осы Пікір жою үшін ешқандай проблема ол?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Сүйікті Post қосу",
    "follow.text2": "сүйікті қызметінен жою",
    "follow.text3": "сүйікті пайдаланушыға қосу",
    "follow.text4": "сүйікті пайдаланушының жою",
    "follow.text5": "шоу хабарламалар",
    "follow.text6": "Сіздің сүйікті Пайдаланушылар",
    "follow.text7": "Post Сүйікті Пайдаланушылар",
    "__11": "",
    "privacyPolicy.text1": "Құпиялылық саясаты",
    "privacyPolicy.text2": "Бұл саясат ескертусіз жаңартылады немесе қайта қаралуы мүмкін. Бұл құпиялылық саясаты өзгерістер туралы хабардар болу үшін пайдаланушының жауапкершілігі болып табылады. Сізді әсер етуі мүмкін басқа да құпиялылық мәселелері назарға:",
    "privacyPolicy.text3": "Бұл веб-сайт тәуекелге ұшырауы мүмкін.",
    "privacyPolicy.text4": "Компьютеріңіз тәуекелге ұшырауы мүмкін.",
    "privacyPolicy.text5": "Бұл веб-сайт Firebase және DigitalOcean инфрақұрылымға орналасқан. Олар мүмкін және олардың серверлерде өз бақылау жүйелерін бар. Бұл қызметтер өздерінің құпиялылық саясатын бар және олар осы құпиялылық саясатына қамтылған жоқ.",
    "privacyPolicy.text6": "Бұл веб-сайт сақтайды электрондық пошта, құпия сөзді шифрланған нысаны, және өтті Firebase және DigitalOcean инфрақұрылымдарды кезінде пайдаланушылардың басқа да кіріс ұяшықтары.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Қолдану ережелері",
    "termsOfUse.text2": "Құпиялық",
    "termsOfUse.text3": "Сіздің құпиялылығыңыз біз үшін маңызды болып табылады. оқып шығыңыз",
    "termsOfUse.text4": "Құпиялылық саясаты",
    "termsOfUse.text5": "Қосымша ақпарат алу үшін.",
    "termsOfUse.text6": "Сіздің өз міндеттері",
    "termsOfUse.text7": "Сіз, пайдаланушы, сіздің юрисдикция заңдар мен салықтар өз сақталуын қамтамасыз ету үшін ғана жауапты болып табылады. Сіз өз қауіпсіздігі үшін ғана жауапты болып табылады.",
    "termsOfUse.text8": "кепілдік Жауапкершіліктен бас тарту",
    "termsOfUse.text9": "Бұл сайт бар ҚАНДАЙ КЕПІЛДІКТЕР МЕН ШАРТТАРДЫ жоқ, НЕГІЗІ «СОЛ ҚАЛПЫНДА» беріледі.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Бастау Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Онлайн Пайдаланушылар",
    "videoTalk.text4": "Әңгіме Бастау",
    "videoTalk.text5": "Баяндамаңның тырысады",
    "videoTalk.text6": "басқа да онлайн пайдаланушы бар жоқ",
    "videoTalk.text7": "Сіз сөйлесіп жатқан",
    "videoTalk.text8": "Сіз талқылауы бастады",
    "videoTalk.text9": "Көрсету Мәтін Талқылауы",
    "videoTalk.text10": "Мәтін енгізу ....",
    "videoTalk.text11": "Сіз әлі де айтып отырмыз. Сіз тоқтату сөйлегенін келе ме?",
    "videoTalk.text12": "пайдаланушыны Calling ажыратылды",
    "videoTalk.text13": "пайдаланушы сені шақырып жатыр. Сіз бұл қоңырауды қабылдауға ма?",
    "videoTalk.text14": "Егер қоңырауды қабылдамау",
    "videoTalk.text15": "Онлайн Пайдаланушы Іздеу ...",
    "videoTalk.text16": "Іздеу пайдаланушы ...",
    "__14": "",
    "groupTalk.text1": "Creator",
    "groupTalk.text2": "сипаттамасы",
    "groupTalk.text3": "Жасалған күні",
    "groupTalk.text4": "Өңдеу тобы ақпарат",
    "groupTalk.text5": "осы топтың мүшелері сізді жою ешқандай проблема ол?",
    "groupTalk.text6": "Мүшесі босатпай",
    "groupTalk.text7": "Топқа қосылыңыз",
    "groupTalk.text8": "Көрсету тобы мүшелері",
    "groupTalk.text9": "Сіз осы топқа қосылу келеді?",
    "groupTalk.text10": "Топ атауы",
    "groupTalk.text11": "Тізімге оралу",
    "groupTalk.text12": "Сіздің енгізуін құптау",
    "groupTalk.text13": "Топты жасау",
    "groupTalk.text14": "жаңарту тобы",
    "groupTalk.text15": "Group шығу",
    "groupTalk.text16": "Логин Басты бет өтіңіз",
    "groupTalk.text17": "топ атауы (5-100 таңба)",
    "groupTalk.text18": "топтық сипаттамасы (500-ден кем таңба)"
  },
  {
    "LANGUAGE": "Kannada",
    "CODE": "kn",
    "test.text1": "ಹಲೋ",
    "test.text2": "ನಮಸ್ತೆ",
    "test.text3": "ಧನ್ಯವಾದಗಳು",
    "test.text4": "ನಕ್ಷೆ",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "ರದ್ದು",
    "general.text2": "ಒಪ್ಪಿಕೊಳ್ಳಿ",
    "general.text3": "ಅಳಿಸು",
    "general.text4": "ಅಳಿಸಲಾಗಿದೆ",
    "general.text5": "ತಿದ್ದು",
    "general.text6": "ನವೀಕರಣ",
    "general.text7": "ಇಮೇಜ್ ಅಪ್ಲೋಡ್",
    "general.text8": "ಅಳಿಸು ಚಿತ್ರ",
    "general.text9": "ಚಿತ್ರ",
    "general.text10": "ಫೀಡ್",
    "general.text11": "ಲಾಗಿನ್",
    "general.text12": "ಸೈನ್ ಅಪ್",
    "general.text13": "ಬಳಕೆದಾರ ಮಾಹಿತಿ",
    "general.text14": "ಲಾಗ್ ಔಟ್",
    "general.text15": "ಫೈಲ್ ಆಗಿರಬೇಕು",
    "general.text16": "ಹೆಚ್ಚು ಮತ್ತು ಕಡಿಮೆ",
    "general.text17": "ಗುಪ್ತಪದ",
    "general.text18": "ಮೆಚ್ಚಿನ ಪೋಸ್ಟ್ಗಳು",
    "general.text19": "ಪೋಸ್ಟ್ಗಳು",
    "general.text20": "ಚಿತ್ರಗಳು",
    "general.text21": "ವೀಡಿಯೊಗಳು",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "ಹೊಸ ಪೋಸ್ಟ್",
    "feed.text2": "ಬಳಕೆದಾರ ಪೋಸ್ಟ್ಗಳು ತೋರಿಸಿ",
    "feed.text3": "ತೋರಿಸಿ ಚಿನ್ನದ",
    "feed.text4": "ನೋಟ",
    "feed.text5": "ತಿದ್ದು",
    "feed.text6": "ಅಳಿಸಿ",
    "feed.text7": "ಸಂಪೂರ್ಣವಾಗಿ ಅಳಿಸಿ ಪೋಸ್ಟ್ಗೆ ಯಾವುದೇ ಸಮಸ್ಯೆಯಾಗುತ್ತದೆ?",
    "feed.text8": "ಪೋಸ್ಟ್ ಮಾಡಿದವರು",
    "feed.text9": "ರಚಿಸಿದವರು",
    "feed.text10": "ಶೀರ್ಷಿಕೆ",
    "feed.text11": "ಚಿತ್ರ",
    "feed.text12": "ವಿಷಯ",
    "feed.text13": "ಸಾರ್ವಜನಿಕ",
    "feed.text14": "ಖಾಸಗಿ",
    "feed.text15": "ಪೋಸ್ಟ್ಗೆ ಶೀರ್ಷಿಕೆ",
    "feed.text16": "ವಿಷಯ ಪೊರ್ ಪೋಸ್ಟ್",
    "feed.text17": "ಅಳಿಸಿ",
    "feed.text18": "ಸಂಪೂರ್ಣವಾಗಿ ಅಳಿಸಿ ಚಿತ್ರಕ್ಕೆ ಯಾವುದೇ ಸಮಸ್ಯೆ?",
    "feed.text19": "ಅಳಿಸಲಾಗಿದೆ",
    "feed.text20": "ಮಾಧ್ಯಮ ಫೈಲ್ JPG, JPEG, PNG ಅಥವಾ MP4 ಫೈಲ್, ಮತ್ತು ಕಡಿಮೆ 3MB ಹೆಚ್ಚು ಮಾಡಬೇಕು",
    "feed.text21": "ಸಾರ್ವಜನಿಕ ಪೋಸ್ಟ್",
    "feed.text22": "ಖಾಸಗಿ ಪೋಸ್ಟ್",
    "feed.text23": "ಹುಡುಕಿ ಟೈಪ್ ....",
    "feed.text24": "ಬಳಕೆದಾರ ಹುಡುಕಿ ...",
    "__2": "",
    "userInfo.text1": "ಬಳಕೆದಾರ ಮಾಹಿತಿ",
    "userInfo.text2": "ಹೆಸರು",
    "userInfo.text3": "ಚಿತ್ರ",
    "userInfo.text4": "ದಿನಾಂಕ ಸೃಷ್ಟಿಸುವುದು",
    "userInfo.text5": "ಸಂಪೂರ್ಣವಾಗಿ ಅಳಿಸಿ ಚಿತ್ರಕ್ಕೆ ಯಾವುದೇ ಸಮಸ್ಯೆ?",
    "userInfo.text6": "ಹೊಸ ಹೆಸರು ...",
    "userInfo.text7": "ಇಮೇಜ್ ಫೈಲ್ JPG, JPEG, ಅಥವಾ PNG ಫೈಲ್, ಮತ್ತು ಕಡಿಮೆ 1MB ಗಿಂತ ಮಾಡಬೇಕು",
    "userInfo.text8": "ಡಾರ್ಕ್ ಮೋಡ್",
    "userInfo.text9": "ಬಗ್ಗೆ ಬಳಕೆದಾರ",
    "userInfo.text10": "ಹೆಚ್ಚುವರಿ ಮಾಹಿತಿಯನ್ನು, ಇತ್ಯಾದಿ ವಿವರಣೆ ಜೈವಿಕ ... (300 ಅಕ್ಷರಗಳ ಒಳಗೆ)",
    "userInfo.text11": "ಭಾಷಾ",
    "userInfo.text12": "ಚೇಂಜ್",
    "userInfo.text13": "ಅಧಿಸೂಚನೆ",
    "__3": "",
    "auth.text1": "ನಿಮ್ಮ ಮೇಲ್",
    "auth.text2": "ನಿಮ್ಮ ಹೆಸರು",
    "auth.text3": "ಗುಪ್ತಪದ",
    "auth.text4": "ಪಾಸ್ವರ್ಡ್ 8 ರಿಂದ 20 ಪಾತ್ರಗಳು, ಕನಿಷ್ಠ ಒಂದು ದೊಡ್ಡ ಅಕ್ಷರ, ಒಂದು ಸಣ್ಣಕ್ಷರವು, ಒಂದು ಸಂಖ್ಯೆ ಮತ್ತು ಒಂದು ವಿಶೇಷ ಪಾತ್ರದಲ್ಲಿ (@ $!% *? &) ಬೇಕು.",
    "auth.text5": "ಸೈನ್ ಅಪ್",
    "auth.text6": "ಲಾಗಿನ್",
    "auth.text7": "ಪಾಸ್ವರ್ಡ್ ಫರ್ಗೆಟ್",
    "auth.text8": "ಸೈನ್ ಅಪ್ ಪುಟ",
    "auth.text9": "ಪಾಸ್ವರ್ಡ್ ರೀಸೆಟ್ ಇ-ಮೇಲ್",
    "auth.text10": "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
    "auth.text11": "ಪ್ರಮಾಣೀಕರಣ ವಿಫಲವಾಗಿದೆ. ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ಇನ್ನೂ ಬಳಸಲಾಗುವುದಿಲ್ಲ!",
    "auth.text12": "ಬಳಕೆದಾರ ಲಾಗಿನ್ ವಿಫಲವಾಗಿದೆ!",
    "auth.text13": "ಅಮಾನ್ಯ ಪಾಸ್ವರ್ಡ್ ಪ್ರವೇಶಿಸಿತು ಮಾಡಲಾಯಿತು",
    "auth.text14": "ನಮೂದಿಸಿದ ಇಮೇಲ್ ವಿಳಾಸ ಕಂಡುಬಂದಿಲ್ಲ",
    "auth.text15": "ಬಳಕೆದಾರ ರಚನೆ ವಿಫಲವಾಗಿದೆ!",
    "auth.text16": "ಇಮೇಲ್ ವಿಳಾಸ ಮತ್ತೊಂದು ಖಾತೆಯಿಂದ ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ",
    "auth.text17": "ಪ್ರವೇಶಿಸಿತು ಪಾಸ್ವರ್ಡ್ ತೋರಿಸಿ",
    "auth.text18": "ಪಾಸ್ವರ್ಡ್ ಮರೆಮಾಡಿ",
    "auth.text19": "ಪಾಸ್ವರ್ಡ್ ಉಳಿದ ಇಮೇಲ್ ಕಳುಹಿಸಲಾಗಿದೆ",
    "auth.text20": "Enterd ಇಮೇಲ್ ಕಂಡುಬಂದಿಲ್ಲ",
    "auth.text21": "ಪಾಸ್ವರ್ಡ್ ರೀಸೆಟ್ ಯಶಸ್ಸನ್ನು",
    "auth.text22": "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಲು ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪಾಸ್ವರ್ಡ್ ರೀಸೆಟ್ ಲಾಗಿನ್ ಪುಟ ಮತ್ತು ಇಮೇಲ್ ಕಳುಹಿಸಿ ಹೋಗಿ",
    "auth.text23": "ಪಾಸ್ವರ್ಡ್ ರೀಸೆಟ್ ಪುಟ",
    "auth.text24": "ಹೊಸ ಪಾಸ್ವರ್ಡ್",
    "auth.text25": "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
    "auth.text26": "ಪುಟ ಅಮಾನ್ಯ ಅಥವಾ ಗುಪ್ತಪದ ಈಗಾಗಲೇ ಬದಲಾಗಿದ್ದು",
    "auth.text27": "ಪಾಸ್ವರ್ಡ್ ರೀಸೆಟ್ ಇಮೇಲ್ ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಪರಿಶೀಲಿಸಿ ಇಮೇಲ್ ವಿಳಾಸ",
    "auth.text28": "ಇಮೇಲ್ ಪರಿಶೀಲನೆ ಪುಟ",
    "auth.text29": "ಇಮೇಲ್ ಯಶಸ್ಸಿನ ಪರಿಶೀಲನೆ, ದಯವಿಟ್ಟು ಲಾಗಿನ್ ಪುಟ ಮೇಲೆ ತೋರಿಸಿದ ಹೋಗಿ",
    "auth.text30": "ಇಮೇಲ್ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ. ಈ ಪುಟದ ಅವಧಿಯು, ಅಥವಾ ಪರಿಶೀಲನೆಯನ್ನು ಈಗಾಗಲೇ ಮುಗಿದ.",
    "auth.text31": "ಪರಿಶೀಲನೆ ಮೇಲ್ ಕಳುಹಿಸಲಾಗಿದೆ. Plese ನಿಮ್ಮ ಇಮೇಲ್ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಪರಿಶೀಲಿಸಲು. (ಸ್ಪ್ಯಾಮ್ ಮೇಲ್ chack)",
    "auth.text32": "ಇಮೇಲ್ ಪರಿಶೀಲನೆ ಲಾಗಿನ್ ಅಗತ್ಯವಿದೆ. ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸಕ್ಕೆ ಪರಿಶೀಲನೆ ಇಮೇಲ್ ಕಳುಹಿಸಿ.",
    "auth.text33": "ಪರಿಶೀಲನೆಗಾಗಿ ಇಮೇಲ್ ಕಳುಹಿಸಿ",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "ಫೀಡ್",
    "nav.text2": "ಲಾಗಿನ್",
    "nav.text3": "ಸೈನ್ ಅಪ್",
    "nav.text4": "ಬಳಕೆದಾರ ಮಾಹಿತಿ",
    "nav.text5": "ಲಾಗ್ ಔಟ್",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "ಪುಟ ಕಂಡುಬಂದಿಲ್ಲ",
    "notFound.text2": "ಫೀಡ್ ಪುಟಕ್ಕೆ ಹೋಗಿ",
    "notFound.text3": "ಲಾಗಿನ್ ಪುಟಕ್ಕೆ ಹೋಗಿ",
    "notFound.text4": "ಎಲ್ಲಿ ನೀವು ಇಂದು ಹೋಗಲಿ?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "ರದ್ದು",
    "comment.text2": "ಅಳಿಸು",
    "comment.text3": "ಕಾಮೆಂಟ್ಪೋಸ್ಟ್",
    "comment.text4": "ಉತ್ತರಿಸಿ ಶೋ",
    "comment.text5": "ಒಟ್ಟು",
    "comment.text6": "ಬರೆ ಉತ್ತರಿಸಿ",
    "comment.text7": "ಉತ್ತರಿಸಿ ಮರೆಮಾಡಿ",
    "comment.text8": "ಪ್ರತಿಕ್ರಿಯೆಗಳು",
    "comment.text9": "ಇದು ಈ ಕಾಮೆಂಟ್ ಮತ್ತು ಪ್ರತ್ಯುತ್ತರಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಈ ಕಾಮೆಂಟ್ ಅಳಿಸಲು ಯಾವುದೇ ಸಮಸ್ಯೆ?",
    "comment.text10": "ಸಂಪೂರ್ಣವಾಗಿ ಈ ಕಾಮೆಂಟ್ ಅಳಿಸಲು ಯಾವುದೇ ಸಮಸ್ಯೆ?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "ಮೆಚ್ಚಿನ ಪೋಸ್ಟ್ ಸೇರಿಸಿ",
    "follow.text2": "ನೆಚ್ಚಿನ ಹುದ್ದೆಗೆ ಅಳಿಸಿ",
    "follow.text3": "ನೆಚ್ಚಿನ ಬಳಕೆದಾರರಿಗೆ ಸೇರಿಸಿ",
    "follow.text4": "ನೆಚ್ಚಿನ ಬಳಕೆದಾರರಿಂದ ಅಳಿಸಿ",
    "follow.text5": "ಪ್ರದರ್ಶನ ಪೋಸ್ಟ್ಗಳು",
    "follow.text6": "ನಿಮ್ಮ ಮೆಚ್ಚಿನ ಬಳಕೆದಾರರು",
    "follow.text7": "ಪೋಸ್ಟ್ ಮೆಚ್ಚಿನ ಬಳಕೆದಾರರು",
    "__11": "",
    "privacyPolicy.text1": "ಗೌಪ್ಯತಾ ನೀತಿ",
    "privacyPolicy.text2": "ಈ ನೀತಿಯು ಪರಿಷ್ಕರಿಸಿಕೊಳ್ಳುವ ಸೂಚನೆ ಇಲ್ಲದೆ ಪರಿಷ್ಕೃತ ಮಾಡಬಹುದು. ಇದು ಗೌಪ್ಯತೆ ನೀತಿ ಬದಲಾವಣೆಗಳ ಕುರಿತು ಬಳಕೆದಾರ ಜವಾಬ್ದಾರಿ. ನೀವು ಪರಿಣಾಮಕಾರಿಯಾಗಲು ಇತರ ಖಾಸಗಿ ವಿಷಯಕ್ಕೆ ಗಮನಹರಿಸಲು:",
    "privacyPolicy.text3": "ಈ ವೆಬ್ಸೈಟ್ ರಾಜಿ ಇರಬಹುದು.",
    "privacyPolicy.text4": "ನಿಮ್ಮ ಕಂಪ್ಯೂಟರ್ ರಾಜಿ ಇರಬಹುದು.",
    "privacyPolicy.text5": "ಈ ವೆಬ್ಸೈಟ್ Firebase, ಮತ್ತು DigitalOcean ಮೂಲಸೌಲಭ್ಯಗಳ ಹೋಸ್ಟ್ ಮಾಡಲಾಗಿದೆ. ಅವರು ಮೇ ಮತ್ತು ಸರ್ವರ್ ಮೇಲಿನ ತಮ್ಮ ಟ್ರ್ಯಾಕ್ ಹೊಂದಿಲ್ಲ. ಆ ಸೇವೆಗಳು ಅವರ ಗೌಪ್ಯತಾ ನಿಯಮಗಳಿಗೆ ಮತ್ತು ಅವರು ಈ ಗೌಪ್ಯತಾ ನೀತಿ ಆವರಿಸಿದೆ ಇಲ್ಲ.",
    "privacyPolicy.text6": "ಈ ವೆಬ್ಸೈಟ್ ಅಂಗಡಿಗಳಲ್ಲಿ ಇಮೇಲ್, ಪಾಸ್ವರ್ಡ್ ಎನ್ಕ್ರಿಪ್ಟ್ ರೂಪ, ಮತ್ತು ಬಳಕೆದಾರರು ಇತರ ಒಳಹರಿವು ಆತಿಥ್ಯ Firebase, ಮತ್ತು DigitalOcean ಮೂಲಸೌಲಭ್ಯಗಳ ನಲ್ಲಿ.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "ಬಳಕೆಯ ನಿಯಮಗಳು",
    "termsOfUse.text2": "ಗೌಪ್ಯತೆ",
    "termsOfUse.text3": "ನಿಮ್ಮ ಗೌಪ್ಯತೆ ನಮಗೆ ಮುಖ್ಯ. ಓದಿ",
    "termsOfUse.text4": "ಗೌಪ್ಯತಾ ನೀತಿ",
    "termsOfUse.text5": "ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ.",
    "termsOfUse.text6": "ನಿಮ್ಮ ಸ್ವಂತ ಜವಾಬ್ದಾರಿಗಳು",
    "termsOfUse.text7": "ನೀವು, ಬಳಕೆದಾರ, ನಿಮ್ಮ ನ್ಯಾಯಾಲಯ ವ್ಯಾಪ್ತಿಯಲ್ಲಿ ಕಾನೂನು ಮತ್ತು ತೆರಿಗೆ ನಿಮ್ಮ ಸ್ವಂತ ಅನುಸರಣೆ ಖಾತರಿ ಮಾತ್ರ ಜವಾಬ್ದಾರರು. ನೀವು ನಿಮ್ಮ ಭದ್ರತೆಗಾಗಿ ಮಾತ್ರ ಜವಾಬ್ದಾರರು.",
    "termsOfUse.text8": "ಖಾತರಿ ನಿರಾಕರಣೆ",
    "termsOfUse.text9": "ಈ ಸೈಟ್ ಮೇಲೆ ಒದಗಿಸಲಾಗಿದೆ, ಆಧಾರದ \"ಇದ್ದದ್ದು ಇದ್ದಂತೆ\" ಯಾವುದೇ ವಾರೆಂಟಿಗಳು ಅಥವಾ ಯಾವುದೇ ರೀತಿಯ ಷರತ್ತುಗಳಿಲ್ಲದೆ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "ಪ್ರಾರಂಭಿಸಿ ಸಂಪರ್ಕಿಸಿ",
    "videoTalk.text2": "ನಿಲ್ಲಿಸಿ ಸಂಪರ್ಕ",
    "videoTalk.text3": "ಆನ್ಲೈನ್ ಬಳಕೆದಾರರು",
    "videoTalk.text4": "ಮಾತನಾಡಲು ಪ್ರಾರಂಭಿಸಿ",
    "videoTalk.text5": "ನಿಮ್ಮ ಚರ್ಚೆ ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ",
    "videoTalk.text6": "ಯಾವುದೇ ಇತರ ಆನ್ಲೈನ್ ಬಳಕೆದಾರರು ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
    "videoTalk.text7": "ನೀವು ಮಾತನಾಡುತ್ತಿರುವ",
    "videoTalk.text8": "ನೀವು ಮಾತನಾಡಲು ಪ್ರಾರಂಭಿಸಿದರು",
    "videoTalk.text9": "ಪಠ್ಯ ಟಾಕ್ ಶೋ",
    "videoTalk.text10": "ಪಠ್ಯ ಇನ್ಪುಟ್ ....",
    "videoTalk.text11": "ನೀವು ಇನ್ನೂ ಮಾತನಾಡುತ್ತಿದ್ದೇವೆ. ನೀವು ಮಾತನಾಡುವ ನಿಲ್ಲಿಸಲು ಬಯಸುವಿರಾ?",
    "videoTalk.text12": "ಕರೆ ಬಳಕೆದಾರರ ಸಂಪರ್ಕ",
    "videoTalk.text13": "ಬಳಕೆದಾರ ನಿಮಗೆ ಆಹ್ವಾನವಿದೆ. ನೀವು ಈ ಕರೆಯನ್ನು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಾ?",
    "videoTalk.text14": "ನಿಮ್ಮ ಕರೆ ತಿರಸ್ಕರಿಸಲು",
    "videoTalk.text15": "ಆನ್ಲೈನ್ ಬಳಕೆದಾರರ ಹುಡುಕಲು ...",
    "videoTalk.text16": "ಹುಡುಕಲು ಬಳಕೆದಾರ ...",
    "__14": "",
    "groupTalk.text1": "ಸೃಷ್ಟಿಕರ್ತ",
    "groupTalk.text2": "ವಿವರಣೆ",
    "groupTalk.text3": "ದಿನಾಂಕ ಸೃಷ್ಟಿಸುವುದು",
    "groupTalk.text4": "ಗುಂಪು ಸಂಪಾದಿಸಿ ಮಾಹಿತಿಯನ್ನು",
    "groupTalk.text5": "ಈ ಗುಂಪಿನ ಸದಸ್ಯರು ನಿಮ್ಮನ್ನು ಅಳಿಸಲು ಯಾವುದೇ ಸಮಸ್ಯೆ?",
    "groupTalk.text6": "ಸದಸ್ಯ ರಿಂದ ನಿರ್ಗಮಿಸಿ",
    "groupTalk.text7": "ಗುಂಪು ಸೇರಿ",
    "groupTalk.text8": "ಶೋ ಗ್ರೂಪ್ ಮೆಂಬರ್ಸ್",
    "groupTalk.text9": "ನೀವು ಈ ಗುಂಪನ್ನು ಸೇರಲು ಬಯಸುವಿರಾ?",
    "groupTalk.text10": "ತಂಡದ ಹೆಸರು",
    "groupTalk.text11": "ಪಟ್ಟಿ ಬ್ಯಾಕ್",
    "groupTalk.text12": "ನಿಮ್ಮ ಇನ್ಪುಟ್ ದೃಢೀಕರಿಸಿ",
    "groupTalk.text13": "ಗುಂಪು ರಚಿಸಿ",
    "groupTalk.text14": "ನವೀಕರಣ ಗುಂಪಿನ",
    "groupTalk.text15": "ಗ್ರೂಪ್ನಿಂದ ನಿರ್ಗಮಿಸಿ",
    "groupTalk.text16": "ಮುಖಪುಟ ಲಾಗಿನ್ ಆಗಿ ಹೋಗಿ",
    "groupTalk.text17": "ಗುಂಪು ಹೆಸರು (5-100 ಅಕ್ಷರಗಳು)",
    "groupTalk.text18": "ಗುಂಪು ವಿವರಣೆ (500 ಅಕ್ಷರಗಳ ಒಳಗೆ)"
  },
  {
    "LANGUAGE": "Korean",
    "CODE": "ko",
    "test.text1": "여보세요",
    "test.text2": "안녕하세요",
    "test.text3": "감사합니다",
    "test.text4": "지도",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "취소",
    "general.text2": "동의하기",
    "general.text3": "지우다",
    "general.text4": "삭제",
    "general.text5": "편집하다",
    "general.text6": "최신 정보",
    "general.text7": "이미지 업로드",
    "general.text8": "삭제 이미지",
    "general.text9": "영상",
    "general.text10": "먹이다",
    "general.text11": "로그인",
    "general.text12": "가입하기",
    "general.text13": "사용자 정보",
    "general.text14": "로그 아웃",
    "general.text15": "파일이 있어야한다",
    "general.text16": "보다 적은",
    "general.text17": "암호",
    "general.text18": "즐겨 찾기 게시물",
    "general.text19": "게시물",
    "general.text20": "이미지",
    "general.text21": "비디오",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "새로운 게시물",
    "feed.text2": "사용자 게시물보기",
    "feed.text3": "보기 게시물",
    "feed.text4": "전망",
    "feed.text5": "편집하다",
    "feed.text6": "지우다",
    "feed.text7": "완전히 삭제 게시물에 대한 문제입니다입니까?",
    "feed.text8": "에 의해 게시 됨",
    "feed.text9": "만든 사람",
    "feed.text10": "표제",
    "feed.text11": "영상",
    "feed.text12": "함유량",
    "feed.text13": "공공의",
    "feed.text14": "은밀한",
    "feed.text15": "게시물 제목",
    "feed.text16": "내용 포 포스트",
    "feed.text17": "지우다",
    "feed.text18": "완전히 삭제 이미지에 아무 문제가 없다?",
    "feed.text19": "삭제",
    "feed.text20": "미디어 파일은 JPG, JPEG, PNG 또는 MP4 파일, 그리고 적은 3메가바이트에 비해한다",
    "feed.text21": "공공 포스트",
    "feed.text22": "개인 포스트",
    "feed.text23": "검색에 사용되는 형태입니다 ....",
    "feed.text24": "사용자 검색 ...",
    "__2": "",
    "userInfo.text1": "사용자 정보",
    "userInfo.text2": "이름",
    "userInfo.text3": "영상",
    "userInfo.text4": "생산 일자",
    "userInfo.text5": "완전히 삭제 이미지에 아무 문제가 없다?",
    "userInfo.text6": "새 이름 ...",
    "userInfo.text7": "이미지 파일은 JPG, JPEG 또는 PNG 파일, 그리고 적은 1메가바이트에 비해한다",
    "userInfo.text8": "다크 모드",
    "userInfo.text9": "소개 사용자",
    "userInfo.text10": "등 당신에 대한 자세한 내용은 설명 바이오 (이하 300 자)",
    "userInfo.text11": "언어",
    "userInfo.text12": "변화",
    "userInfo.text13": "공고",
    "__3": "",
    "auth.text1": "귀하의 이메일",
    "auth.text2": "당신의 이름",
    "auth.text3": "암호",
    "auth.text4": "비밀번호는 8 ~ 20 자, 적어도 하나의 대문자, 소문자가 한 문자, 하나 개의 숫자와 하나 개의 특수 문자가 있어야합니다 (@ $! % *? &).",
    "auth.text5": "가입하기",
    "auth.text6": "로그인",
    "auth.text7": "비밀번호 분실",
    "auth.text8": "회원 가입 페이지",
    "auth.text9": "암호 재설정을위한 전자 메일 주소",
    "auth.text10": "이메일을 보내",
    "auth.text11": "검증에 실패했습니다. 확인 이메일 주소는 아직 사용하지 않습니다!",
    "auth.text12": "사용자 로그인이 실패했습니다!",
    "auth.text13": "잘못된 암호를 입력했습니다",
    "auth.text14": "입력 한 이메일 주소를 찾을 수 없습니다",
    "auth.text15": "사용자 작성에 실패했습니다!",
    "auth.text16": "이메일 주소는 다른 계정에서 이미 사용",
    "auth.text17": "입력 한 암호를보기",
    "auth.text18": "숨기기 비밀번호",
    "auth.text19": "암호 나머지 이메일을 보냈습니다",
    "auth.text20": "Enterd 이메일을 찾을 수 없습니다",
    "auth.text21": "암호 재설정 성공",
    "auth.text22": "비밀번호 재설정 실패는 다시 암호 재설정을위한 로그인 페이지 및 전자 메일 보내기를 방문하시기 바랍니다",
    "auth.text23": "암호 재설정 페이지",
    "auth.text24": "새 비밀번호",
    "auth.text25": "암호를 재설정",
    "auth.text26": "페이지가 잘못되었거나 암호가 이미 변경",
    "auth.text27": "암호 재설정 이메일을 실패 보내기 바랍니다 체크 이메일 주소",
    "auth.text28": "이메일 확인 페이지",
    "auth.text29": "성공 확인 이메일, 위의 로그인 페이지로 이동하십시오",
    "auth.text30": "이메일 확인에 실패했습니다. 이 페이지는 만료, 또는 검증은 이미 완료됩니다.",
    "auth.text31": "확인 메일이 전송되었습니다. Plese 이메일을 확인하고 확인합니다. (스팸 메일에 chack)",
    "auth.text32": "이메일 확인은 로그인이 필요합니다. 당신의 이메일 주소 확인을 위해 이메일을 보내 주시기 바랍니다.",
    "auth.text33": "확인을 위해 이메일 보내기",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "먹이다",
    "nav.text2": "로그인",
    "nav.text3": "가입하기",
    "nav.text4": "사용자 정보",
    "nav.text5": "로그 아웃",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "페이지를 찾을 수 없음",
    "notFound.text2": "피드 페이지로 이동",
    "notFound.text3": "로그인 페이지로 이동",
    "notFound.text4": "어디 오늘 가야합니까?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "취소",
    "comment.text2": "지우다",
    "comment.text3": "댓글 달기",
    "comment.text4": "쇼 답글",
    "comment.text5": "합계",
    "comment.text6": "쓰기 답글",
    "comment.text7": "숨기기 답글",
    "comment.text8": "코멘트",
    "comment.text9": "완전히이 댓글의이 댓글과 답글을 삭제하는 문제입니까?",
    "comment.text10": "완전히이 댓글을 삭제하는 문제입니까?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "즐겨 찾기 포스트에 추가",
    "follow.text2": "마음에 드는 게시물에서 삭제",
    "follow.text3": "좋아하는 사용자에게 추가",
    "follow.text4": "좋아하는 사용자에서 삭제",
    "follow.text5": "쇼 게시물",
    "follow.text6": "좋아하는 사용자",
    "follow.text7": "포스트 좋아하는 사용자",
    "__11": "",
    "privacyPolicy.text1": "개인 정보 정책",
    "privacyPolicy.text2": "이 정책은 업데이트 예고없이 변경 될 수 있습니다. 이 개인 정보 보호 정책의 변경 사항에 대한 최신 정보를 사용자의 책임입니다. 당신에 영향을 미칠 수있는 다른 개인 정보 보호 문제에주의하십시오 :",
    "privacyPolicy.text3": "이 웹 사이트는 손상 될 수 있습니다.",
    "privacyPolicy.text4": "컴퓨터가 손상 될 수 있습니다.",
    "privacyPolicy.text5": "이 웹 사이트는 중포 기지 및 디지털 오션 인프라를 호스팅하고 있습니다. 그들은 수 있으며, 자신의 서버에 자신의 추적 시스템을 가지고 않습니다. 이러한 서비스는 자신의 개인 정보 보호 정책을 가지고 있고이 개인 정보 보호 정책이 적용되지 않습니다.",
    "privacyPolicy.text6": "이 웹 사이트를 저장 이메일, 비밀번호의 암호화 된 형태로, 그리고 호스팅 중포 기지 및 디지털 오션 인프라에서 사용자의 다른 입력.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "이용 약관",
    "termsOfUse.text2": "은둔",
    "termsOfUse.text3": "귀하의 개인 정보는 우리에게 중요하다. 내용을 읽어주십시오",
    "termsOfUse.text4": "개인 정보 정책",
    "termsOfUse.text5": "자세한 내용은.",
    "termsOfUse.text6": "귀하 자신의 책임",
    "termsOfUse.text7": "당신, 사용자는 해당 관할 지역의 법률 및 세금 자신을 준수 할 책임은 전적으로 귀하에게 있습니다. 당신은 당신의 자신의 안전에 대한 책임은 전적으로 귀하에게 있습니다.",
    "termsOfUse.text8": "보증의 면책 조항",
    "termsOfUse.text9": "이 사이트가 설치되어 보증이나 어떠한 제한없이 BASIS \"AS 것은\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "시작 연결",
    "videoTalk.text2": "정지 연결",
    "videoTalk.text3": "온라인 사용자",
    "videoTalk.text4": "이야기 시작",
    "videoTalk.text5": "귀하는 이야기하려고하는",
    "videoTalk.text6": "다른 온라인 사용자가 존재하지 않는다",
    "videoTalk.text7": "당신은 이야기하고 있습니다",
    "videoTalk.text8": "당신은 이야기하기 시작",
    "videoTalk.text9": "표시 텍스트 토크",
    "videoTalk.text10": "텍스트 입력 ....",
    "videoTalk.text11": "당신은 여전히 ​​말하고있다. 당신은 그만 얘기 하시겠습니까?",
    "videoTalk.text12": "호출 사용자의 연결이 끊어",
    "videoTalk.text13": "사용자는 전화입니다. 당신이 전화를 받습니까?",
    "videoTalk.text14": "당신의 통화를 거부",
    "videoTalk.text15": "온라인 사용자를 검색 ...",
    "videoTalk.text16": "사용자가 검색 ...",
    "__14": "",
    "groupTalk.text1": "창조자",
    "groupTalk.text2": "기술",
    "groupTalk.text3": "생산 일",
    "groupTalk.text4": "편집 그룹 정보",
    "groupTalk.text5": "그것은이 그룹의 구성원에서 당신을 삭제하는 문제는 없습니까?",
    "groupTalk.text6": "회원에서 출발",
    "groupTalk.text7": "그룹 가입",
    "groupTalk.text8": "쇼 그룹 회원",
    "groupTalk.text9": "당신은이 그룹에 가입 하시겠습니까?",
    "groupTalk.text10": "그룹 이름",
    "groupTalk.text11": "다시 목록으로",
    "groupTalk.text12": "입력 내용을 확인",
    "groupTalk.text13": "그룹 만들기",
    "groupTalk.text14": "그룹 업데이트",
    "groupTalk.text15": "그룹에서 종료",
    "groupTalk.text16": "로그인에 홈페이지로 이동",
    "groupTalk.text17": "그룹 이름 (5-100 자)",
    "groupTalk.text18": "그룹 설명 (이하 500 자)"
  },
  {
    "LANGUAGE": "Konkani",
    "CODE": "kok",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Kyrgyz",
    "CODE": "ky",
    "test.text1": "салам",
    "test.text2": "салам",
    "test.text3": "Рахмат сага",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "жокко чыгаруу",
    "general.text2": "кабыл алуу",
    "general.text3": "Delete",
    "general.text4": "жок кылынды",
    "general.text5": "редакциялоо",
    "general.text6": "өзгөртүү",
    "general.text7": "Сүрөт жүктөө",
    "general.text8": "Delete Image",
    "general.text9": "Image",
    "general.text10": "тамактануу",
    "general.text11": "Кирүү",
    "general.text12": "Кирүү",
    "general.text13": "Колдонуучу жөнүндө маалымат",
    "general.text14": "Чыгуу",
    "general.text15": "билэ болушу керек",
    "general.text16": "жана аз",
    "general.text17": "Купуя сөз",
    "general.text18": "Favorite Билдирүүлөр",
    "general.text19": "Билдирүүлөр",
    "general.text20": "Сүрөттөр",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Болумушту Post",
    "feed.text2": "Колдонуучу Билдирүүлөр көрсөтүү",
    "feed.text3": "Show Билдирүүлөр",
    "feed.text4": "көрүнүш",
    "feed.text5": "редакциялоо",
    "feed.text6": "жок кылуу",
    "feed.text7": "толугу менен жок кылууну билдирүүгө эч кандай көйгөй болуп жатабы?",
    "feed.text8": "Жарыялаган",
    "feed.text9": "тарабынан түзүлгөн",
    "feed.text10": "наам",
    "feed.text11": "Image",
    "feed.text12": "ыраазы",
    "feed.text13": "мамлекеттик",
    "feed.text14": "жеке",
    "feed.text15": "Жарыкка аталышы",
    "feed.text16": "Мазмун түзүүчү ээрчүү",
    "feed.text17": "жок кылуу",
    "feed.text18": "ал толугу менен жок кылууну буркандын эч кандай маселе барбы?",
    "feed.text19": "жок кылынды",
    "feed.text20": "Медиа File өлчөмү же mp4 билэ болушу керек, жана 3mb кем",
    "feed.text21": "мамлекеттик кызмат",
    "feed.text22": "жеке билдирүү",
    "feed.text23": "Издөө үчүн терип ....",
    "feed.text24": "Колдонуучу издөө ...",
    "__2": "",
    "userInfo.text1": "Колдонуучу маалыматы",
    "userInfo.text2": "ысым",
    "userInfo.text3": "сүрөт",
    "userInfo.text4": "Тъзългён датасы",
    "userInfo.text5": "ал толугу менен жок кылууну буркандын эч кандай маселе барбы?",
    "userInfo.text6": "Жаңы ат ...",
    "userInfo.text7": "Image File өлчөмү, же PNG билэ болушу керек, жана 1МБтан азыраак",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "жөнүндө маалымат",
    "userInfo.text10": "ж.б сага, сүрөттөлүшү био тууралуу кошумча маалымат ... (кеминде 300 белгиден)",
    "userInfo.text11": "тил",
    "userInfo.text12": "өзгөртүү",
    "userInfo.text13": "билдирүү",
    "__3": "",
    "auth.text1": "Сиздин E-почта",
    "auth.text2": "Your Name",
    "auth.text3": "Купуя сөз",
    "auth.text4": "Сырсөз кеминде бир тамга 8 20 белгиге чейин болушу керек, бир сап тамга, бир номери жана кайсы бир өзгөчө белги (@ $!% *? &).",
    "auth.text5": "Кирүү",
    "auth.text6": "Кирүү",
    "auth.text7": "унутуп калуу",
    "auth.text8": "Signup Page",
    "auth.text9": "E-сөздү абалга дареги",
    "auth.text10": "E-кат жөнөтүү",
    "auth.text11": "Жарактуулугун текшерүү ишке ашкан жок. Текшерип, электрондук почта дареги, бирок пайдаланылбай жатат!",
    "auth.text12": "Колдонуучу кирүү ишке ашкан жок!",
    "auth.text13": "Жараксыз сырсөз киргизилди болду",
    "auth.text14": "Киргизилген электрондук почта дареги табылган жок",
    "auth.text15": "Колдонуучу ишке ашкан жок!",
    "auth.text16": "электрондук почта дареги башка эсепке тарабынан колдонулуп келе жатат",
    "auth.text17": "Сырсөздү көрсөтүү",
    "auth.text18": "Hide Password",
    "auth.text19": "Сырсөз эс алуу үчүн электрондук кат жөнөтүлдү",
    "auth.text20": "Enterd электрондук табылган жок",
    "auth.text21": "Сырсөздү өзгөртүү ийгилик",
    "auth.text22": "Сырсөздү өзгөртүү ишке ашкан жок, кайра Сырсөздү өзгөртүү үчүн Сураныч Кирүү бетине жана жөнөтүү үчүн",
    "auth.text23": "Сырсөздү өзгөртүү бет",
    "auth.text24": "жаңы Сыр сөз",
    "auth.text25": "Сырсөздү",
    "auth.text26": "Page жараксыз же Сырсөз мурунтан өзгөртүлдү",
    "auth.text27": "кыйрады Сырсөздү өзгөртүү үчүн электрондук почта аркылуу кат жөнөтүп, Сураныч, текшерүү электрондук почта дареги",
    "auth.text28": "Email текшерүү бет",
    "auth.text29": "ийгилик текшерүү Email, сураныч, жогоруда көрсөтүлгөн каттоо үчүн",
    "auth.text30": "Email текшерүү ишке ашкан жок. Бул барак мөөнөтү, же текшерүү мурунтан аяктады.",
    "auth.text31": "Текшерүү Mail жөнөтүлгөн. Plese почта текшерүү жана ырастоо. (Ошондой эле спам почта аркылуу одной)",
    "auth.text32": "Email текшерүү кирүү үчүн талап кылынат. Сиздин E-mail дарегине текшерүү үчүн электрондук кат жөнөтөбүз.",
    "auth.text33": "текшерүү үчүн кат жөнөтүү",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "тамактануу",
    "nav.text2": "Кирүү",
    "nav.text3": "Кирүү",
    "nav.text4": "Колдонуучу жөнүндө маалымат",
    "nav.text5": "Чыгуу",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Барак табылган жок",
    "notFound.text2": "канал бетине барып,",
    "notFound.text3": "Логин бетине барып,",
    "notFound.text4": "Сен бүгүн барып жатат?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "жокко чыгаруу",
    "comment.text2": "Delete",
    "comment.text3": "Жорум",
    "comment.text4": "Show Reply",
    "comment.text5": "жалпы",
    "comment.text6": "Которуу Жооп берүү",
    "comment.text7": "Hide Reply",
    "comment.text8": "Comments",
    "comment.text9": "Бул жорумга толугу менен бул пикир менен жооп жок кылуу үчүн эч кандай маселе барбы?",
    "comment.text10": "ал толугу менен Бул жорумду жок кылуу үчүн эч кандай маселе барбы?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Favorite Билдирүүнү кошуу",
    "follow.text2": "Сүйүктүү ээлеген жок кылуу",
    "follow.text3": "Сүйүктүү колдонуучуга кошуу",
    "follow.text4": "Сүйүктүү колдонуучудан жок кылуу",
    "follow.text5": "-Петербург",
    "follow.text6": "Сиздин Favorite Users",
    "follow.text7": "Post Favorite Users",
    "__11": "",
    "privacyPolicy.text1": "Privacy Policy",
    "privacyPolicy.text2": "Бул саясат программаланган же эскертүүсүз кайра каралышы мүмкүн. Бул Купуялык саясатын өзгөртүү жөнүндө кабардар болуу үчүн колдонуучунун милдети болуп саналат. сага таасир этиши мүмкүн болгон башка жекелик маселелери эске алып:",
    "privacyPolicy.text3": "Бул сайт бузулган болот.",
    "privacyPolicy.text4": "Сиздин компьютер бузулган болот.",
    "privacyPolicy.text5": "Бул сайт Firebase жана DigitalOcean объектилерди боюнча өткөрүлөт. Алар мүмкүн жана кызматтар боюнча өз көз салуу системасын бар. Бул кызматтар өз купуялык саясаттар бар, алар ушул купуялык саясаты менен камсыз болушкан эмес.",
    "privacyPolicy.text6": "Бул сайт дүкөндөр электрондук почта, сырсөздү ИП түрү жана уюштурулган Firebase жана DigitalOcean объектилерди пайдалануучулар башка салымдар.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Пайдалануу шарттары",
    "termsOfUse.text2": "Жашыруун",
    "termsOfUse.text3": "Сиздин купуялык биз үчүн абдан маанилүү. Сураныч, окуу",
    "termsOfUse.text4": "купуялуулук саясаты",
    "termsOfUse.text5": "Көбүрөөк маалымат алуу үчүн.",
    "termsOfUse.text6": "Өз милдеттерин",
    "termsOfUse.text7": "Сиз колдонуучу сиздин өлкөдөгү мыйзамдардын жана салыктарды эсепке алуу менен өз сакталышын камсыз кылуу үчүн гана жооп берет. Сиз өз коопсуздугу үчүн гана жооп берет.",
    "termsOfUse.text8": "КЕПИЛДИКТЕН БАШ ТАРТУУ",
    "termsOfUse.text9": "Бул сайт КЕПИЛДИКТЕРИБИЗ ЖЕ ЭЧ КАНДАЙ шарты жок, бир НЕГИЗИ \"БОЛГОН АБАЛЫНДА\" берилет.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Онлайн колдонуучулар",
    "videoTalk.text4": "сүйлөшүү баштоо",
    "videoTalk.text5": "Сиздин талкуулоо үчүн аракет кылып жатышат",
    "videoTalk.text6": "Жок, башка колдонуучулар бар",
    "videoTalk.text7": "Сиз менен сүйлөшүп жатат",
    "videoTalk.text8": "Сиз менен сүйлөшүү баштады",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "текст киргизүү ....",
    "videoTalk.text11": "Сиз дагы эле айтып жатат. Сиз сүйлөшүп алууну токтотууну каалайсызбы?",
    "videoTalk.text12": "Чалып колдонуучу ажыратылды",
    "videoTalk.text13": "колдонуучу сизди чакырып жатат. Бул чакырууну кабыл алабыз?",
    "videoTalk.text14": "Сиздин чакырууну четке кагып,",
    "videoTalk.text15": "онлайн колдонуучуну издөө ...",
    "videoTalk.text16": "Колдонуучуну издөө ...",
    "__14": "",
    "groupTalk.text1": "посттору",
    "groupTalk.text2": "баяндоо",
    "groupTalk.text3": "Тъзългён датасы",
    "groupTalk.text4": "Edit топ маалымат",
    "groupTalk.text5": "Бул топтун мүчөлөрү силерди жок кылуу үчүн эч кандай маселе барбы?",
    "groupTalk.text6": "Мүчө-кетүү",
    "groupTalk.text7": "Каттоо Group",
    "groupTalk.text8": "Show: Колдонуучулар",
    "groupTalk.text9": "Бул топту Каттоо келеби?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Артка List",
    "groupTalk.text12": "Сиздин киргизүүнү ыраста",
    "groupTalk.text13": "топ түзүү",
    "groupTalk.text14": "өзгөртүү Group",
    "groupTalk.text15": "Курамынан чыгуу",
    "groupTalk.text16": "Кирүү үчүн Азаттык Мурунку",
    "groupTalk.text17": "топтун аталышы (5-100 белги)",
    "groupTalk.text18": "топ сүрөттөлүшү (500 белгиден)"
  },
  {
    "LANGUAGE": "Lithuanian",
    "CODE": "lt",
    "test.text1": "Sveiki",
    "test.text2": "Labas",
    "test.text3": "Ačiū",
    "test.text4": "žemėlapį",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "atšaukti",
    "general.text2": "Priimti",
    "general.text3": "Ištrinti",
    "general.text4": "Ištrinta",
    "general.text5": "Redaguoti",
    "general.text6": "atnaujinimas",
    "general.text7": "Įkelti paveikslėlį",
    "general.text8": "Ištrinti Vaizdo",
    "general.text9": "vaizdas",
    "general.text10": "Maitinti",
    "general.text11": "Prisijungti",
    "general.text12": "Registruotis",
    "general.text13": "vartotojo Info",
    "general.text14": "Atsijungti",
    "general.text15": "Failas turi būti",
    "general.text16": "ir mažiau nei",
    "general.text17": "Slaptažodis",
    "general.text18": "Mėgstamiausios Pranešimai",
    "general.text19": "Pranešimai",
    "general.text20": "Vaizdai",
    "general.text21": "Vaizdo įrašai",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Naujas įrašas",
    "feed.text2": "Rodyti User Žinutės",
    "feed.text3": "Rodyti pranešimus",
    "feed.text4": "vaizdas",
    "feed.text5": "Redaguoti",
    "feed.text6": "Ištrinti",
    "feed.text7": "Ar yra ne problema ištrinti postą visiškai?",
    "feed.text8": "paskelbtas",
    "feed.text9": "Sukurta",
    "feed.text10": "pavadinimas",
    "feed.text11": "vaizdas",
    "feed.text12": "Turinys",
    "feed.text13": "visuomenės",
    "feed.text14": "privatus",
    "feed.text15": "Pavadinimas už postą",
    "feed.text16": "Turinio por pranešimą",
    "feed.text17": "Ištrinti",
    "feed.text18": "Ar tai ne problema ištrinti atvaizdas visiškai?",
    "feed.text19": "Ištrinta",
    "feed.text20": "Medijos failas turi būti JPG, JPEG, PNG arba MP4 failą, ir mažiau nei 3MB",
    "feed.text21": "viešas įrašas",
    "feed.text22": "įrašas",
    "feed.text23": "Įveskite paieškai ....",
    "feed.text24": "Paieška vartotoją ...",
    "__2": "",
    "userInfo.text1": "vartotojo informacija",
    "userInfo.text2": "vardas",
    "userInfo.text3": "vaizdas",
    "userInfo.text4": "sukūrimo data",
    "userInfo.text5": "Ar tai ne problema ištrinti atvaizdas visiškai?",
    "userInfo.text6": "Naujas vardas ...",
    "userInfo.text7": "Vaizdo failas turi būti JPG, JPEG arba PNG, ir mažiau nei 1 MB",
    "userInfo.text8": "Tamsiai režimas",
    "userInfo.text9": "Viskas apie",
    "userInfo.text10": "Papildoma informacija apie jus, aprašymas bio ir tt ... (mažiau nei 300 simbolių)",
    "userInfo.text11": "kalba",
    "userInfo.text12": "pokytis",
    "userInfo.text13": "pranešimas",
    "__3": "",
    "auth.text1": "Tavo elektroninis paštas",
    "auth.text2": "Tavo vardas",
    "auth.text3": "Slaptažodis",
    "auth.text4": "Slaptažodis turi būti nuo 8 iki 20 simbolių, bent viena didžioji raidė, vienas mažoji raidė, vienas skaičius ir vienas specialus simbolis (@ $!% *? &).",
    "auth.text5": "Registruotis",
    "auth.text6": "Prisijungti",
    "auth.text7": "Pamiršti slaptažodį",
    "auth.text8": "registracija tinklalapis",
    "auth.text9": "E-paštas adresas naujo slaptažodžio",
    "auth.text10": "Siųsti laišką",
    "auth.text11": "Patvirtinimo nepavyko. Įsitikinkite, kad elektroninio pašto adresas nebus naudojamas dar!",
    "auth.text12": "Vartotojo prisijungimas nepavyko!",
    "auth.text13": "Neteisingas slaptažodis buvo įrašytas",
    "auth.text14": "Įvedė pašto adresas nerastas",
    "auth.text15": "Vartotojas sukurti nepavyko!",
    "auth.text16": "Pašto adresas jau naudojamas kito sąskaita",
    "auth.text17": "Rodyti įvestas slaptažodis",
    "auth.text18": "Slėpti Slaptažodžių",
    "auth.text19": "siųsti slaptažodžio poilsio buvo išsiųstas",
    "auth.text20": "Enterd siųsti nerastas",
    "auth.text21": "Slaptažodžio keitimas sėkmė",
    "auth.text22": "Slaptažodžio keitimas nepavyko, prašome eiti į prisijungimo puslapį ir išsiųsti paštu už naujo slaptažodžio vėl",
    "auth.text23": "Slaptažodžio keitimas puslapis",
    "auth.text24": "Naujas Slaptažodis",
    "auth.text25": "Atstatyti slaptažodį",
    "auth.text26": "Puslapis yra neteisingas arba slaptažodis jau pasikeitė",
    "auth.text27": "Siuntimas paštu už naujo slaptažodžio nepavyko, prašome patikrinti elektroninio pašto adresas",
    "auth.text28": "Pašto tikrinimo puslapis",
    "auth.text29": "Pašto tikrinimo sėkmės, eikite į prisijungimo puslapį nurodyta pirmiau",
    "auth.text30": "Pašto tikrinimo nepavyko. Šis puslapis yra pasibaigęs, ar patikrinimas jau baigtas.",
    "auth.text31": "Patikrinimo laiškas buvo išsiųstas. Plese pasitikrinti savo elektroninį paštą ir patikrinti. (Taip pat Chack į šlamštas)",
    "auth.text32": "Paštas patikra yra reikalaujama prisijungti. Prašome siųsti paštu patikrinti savo elektroninio pašto adresą.",
    "auth.text33": "Siųsti paštu patikrinimui",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Maitinti",
    "nav.text2": "Prisijungti",
    "nav.text3": "Registruotis",
    "nav.text4": "vartotojo Info",
    "nav.text5": "Atsijungti",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Puslapis nerastas",
    "notFound.text2": "pereiti prie pašarų puslapyje",
    "notFound.text3": "eiti į prisijungimo puslapį",
    "notFound.text4": "Kur tu šiandien?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "atšaukti",
    "comment.text2": "Ištrinti",
    "comment.text3": "Pranešimo Komentuoti",
    "comment.text4": "Rodyti Atsakyti",
    "comment.text5": "visas",
    "comment.text6": "Parašyti Atsakyti",
    "comment.text7": "Slėpti Atsakyti",
    "comment.text8": "komentarai",
    "comment.text9": "Ar tai ne problema ištrinti šį komentarą ir atsakymai šį komentarą visiškai?",
    "comment.text10": "Ar tai ne problema visiškai ištrinti šį komentarą?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Pridėti į Favoritus Post",
    "follow.text2": "Ištrinti iš mėgstamų paštu",
    "follow.text3": "Pridėti į Favoritus vartotojas",
    "follow.text4": "Ištrinti iš mėgstamų vartotojas",
    "follow.text5": "Rodyti žinutes",
    "follow.text6": "Jūsų Mėgstamiausios Vartotojai",
    "follow.text7": "Pranešimo Mėgstamiausios Vartotojai",
    "__11": "",
    "privacyPolicy.text1": "Privatumo politika",
    "privacyPolicy.text2": "Ši politika gali būti atnaujinami arba peržiūrėti be įspėjimo. Tai yra naudotojo Viešnagės informuotas apie privatumo politikos pakeitimus atsakomybė. Atsižvelgti į kitų privatumo klausimais, kurie gali turėti įtakos jums:",
    "privacyPolicy.text3": "Ši svetainė gali būti pažeista.",
    "privacyPolicy.text4": "Jūsų kompiuteris gali būti pažeistas.",
    "privacyPolicy.text5": "Ši svetainė yra talpinami Firebase ir DigitalOcean infrastruktūrą. Jie gali, o turi savo sekimo sistemas savo serveriuose. Šios paslaugos turi savo privatumo politiką ir jie neapima šią privatumo politiką.",
    "privacyPolicy.text6": "Ši Interneto svetainė parduotuvės siųsti, užkoduota forma slaptažodį, ir kiti įėjimai vartotojų at vyko Firebase ir DigitalOcean infrastruktūrą.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Naudojimo sąlygos",
    "termsOfUse.text2": "slaptumas",
    "termsOfUse.text3": "Jūsų privatumas yra svarbus mums. Prašome perskaityti",
    "termsOfUse.text4": "Privatumo politika",
    "termsOfUse.text5": "Daugiau informacijos.",
    "termsOfUse.text6": "Jūsų pačių atsakomybė",
    "termsOfUse.text7": "Jūs, vartotojas, yra tik atsakingas už savo laikomasi įstatymų ir mokesčių savo jurisdikcijos. Jūs esate atsakingi už savo saugumą.",
    "termsOfUse.text8": "Atsakomybės apribojimas garantija",
    "termsOfUse.text9": "Ši svetainė yra pateikiama \"kaip yra\", BE GARANTIJŲ ARBA SĄLYGŲ bet kokios rūšies.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Pradėti Prisijungimas",
    "videoTalk.text2": "Sustabdyti Prisijungimas",
    "videoTalk.text3": "Vartotojai online",
    "videoTalk.text4": "Pradėti kalbėtis",
    "videoTalk.text5": "Jūsų bando kalbėti",
    "videoTalk.text6": "Jokia kita interneto vartotojas egzistuoja",
    "videoTalk.text7": "Tu kalbi",
    "videoTalk.text8": "Jūs pradėjote kalbėti",
    "videoTalk.text9": "Rodyti Tekstas Aptarimas",
    "videoTalk.text10": "teksto įvestis ....",
    "videoTalk.text11": "Jūs vis dar kalbame. Ar norite nustoti kalbėti?",
    "videoTalk.text12": "Skambinęs vartotojas atjungtas",
    "videoTalk.text13": "vartotojas skambina jums. Ar sutinkate su šiuo skambinti?",
    "videoTalk.text14": "atmesti skambutį",
    "videoTalk.text15": "ieškoti internete vartotoją ...",
    "videoTalk.text16": "ieškoti vartotojo ...",
    "__14": "",
    "groupTalk.text1": "kūrėjas",
    "groupTalk.text2": "apibūdinimas",
    "groupTalk.text3": "sukūrimo data",
    "groupTalk.text4": "Redaguoti grupės informacijos",
    "groupTalk.text5": "Ar tai ne problema ištrinti tave iš šios grupės narių?",
    "groupTalk.text6": "Išvykti iš narys",
    "groupTalk.text7": "Prisijungti prie grupės",
    "groupTalk.text8": "Rodyti Grupė Nariai",
    "groupTalk.text9": "Norite prisijungti prie šios grupės?",
    "groupTalk.text10": "Grupės pavadinimas",
    "groupTalk.text11": "Grįžti į sąrašą",
    "groupTalk.text12": "Patvirtinkite savo indėlį",
    "groupTalk.text13": "Sukurti grupę",
    "groupTalk.text14": "Atnaujinti grupė",
    "groupTalk.text15": "Išeiti iš grupės",
    "groupTalk.text16": "Eiti į pagrindinį puslapį prisijungti",
    "groupTalk.text17": "grupės pavadinimas (viršija 5-100 simbolių)",
    "groupTalk.text18": "Grupės apibūdinimas (mažiau nei 500 simbolių)"
  },
  {
    "LANGUAGE": "Latvian",
    "CODE": "lv",
    "test.text1": "Sveiki",
    "test.text2": "Sveiki",
    "test.text3": "Paldies",
    "test.text4": "karte",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "atcelt",
    "general.text2": "akceptēt",
    "general.text3": "Dzēst",
    "general.text4": "Svītrots",
    "general.text5": "rediģēt",
    "general.text6": "Atjaunināt",
    "general.text7": "Augšupielādēt attēlu",
    "general.text8": "Dzēst attēlu",
    "general.text9": "attēls",
    "general.text10": "feed",
    "general.text11": "Pieslēgties",
    "general.text12": "Pierakstīties",
    "general.text13": "Lietotāja informācija",
    "general.text14": "Izlogoties",
    "general.text15": "fails būtu",
    "general.text16": "un mazāk nekā",
    "general.text17": "parole",
    "general.text18": "Iecienītākie Posts",
    "general.text19": "Posts",
    "general.text20": "Attēli",
    "general.text21": "Video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Jauna ziņa",
    "feed.text2": "Parādīt lietotāja ziņojumus",
    "feed.text3": "Rādīt ierakstus",
    "feed.text4": "skats",
    "feed.text5": "rediģēt",
    "feed.text6": "izdzēst",
    "feed.text7": "Vai nav problēma dzēst ziņu pilnīgi?",
    "feed.text8": "rakstīja",
    "feed.text9": "Radīts",
    "feed.text10": "virsraksts",
    "feed.text11": "attēls",
    "feed.text12": "saturs",
    "feed.text13": "valsts",
    "feed.text14": "Privāts",
    "feed.text15": "Virsraksts pastu",
    "feed.text16": "Satura por post",
    "feed.text17": "izdzēst",
    "feed.text18": "Vai tas nav problēma, lai izdzēstu attēlu pilnīgi?",
    "feed.text19": "Svītrots",
    "feed.text20": "Media File jābūt jpg, jpeg, png vai mp4 failu, un mazāk par 3 MB",
    "feed.text21": "publisku ziņu",
    "feed.text22": "privāta ziņa",
    "feed.text23": "Ierakstiet meklēšanā ....",
    "feed.text24": "Meklēt lietotāju ...",
    "__2": "",
    "userInfo.text1": "Lietotāja informācija",
    "userInfo.text2": "nosaukums",
    "userInfo.text3": "attēls",
    "userInfo.text4": "izveidošanas datums",
    "userInfo.text5": "Vai tas nav problēma, lai izdzēstu attēlu pilnīgi?",
    "userInfo.text6": "Jauns vārds ...",
    "userInfo.text7": "Attēla failam jābūt jpg, jpeg, vai png failu, un mazāk nekā 1 MB",
    "userInfo.text8": "Tumši Mode",
    "userInfo.text9": "Par lietotāju",
    "userInfo.text10": "Papildu informācija par jums, apraksts bio utt ... (mazāk nekā 300 rakstzīmes)",
    "userInfo.text11": "valoda",
    "userInfo.text12": "maiņa",
    "userInfo.text13": "paziņojums",
    "__3": "",
    "auth.text1": "Tavs e-pasts",
    "auth.text2": "Tavs vārds",
    "auth.text3": "parole",
    "auth.text4": "Parolē jābūt 8 līdz 20 rakstzīmēm, vismaz viens lielais burts, viena mazo burtu, viens numurs un viens īpašs raksturs (@ $!% *? &).",
    "auth.text5": "Pierakstīties",
    "auth.text6": "Pieslēgties",
    "auth.text7": "Aizmirstiet paroli",
    "auth.text8": "Reģistrēšanās lapa",
    "auth.text9": "E-pasta adrese paroles atiestatīšanas",
    "auth.text10": "sūtīt e-pastu",
    "auth.text11": "Validācija neizdevās. Pārliecinieties, vai e-pasta adrese nav izmantots vēl!",
    "auth.text12": "Lietotāja pieteikšanās neizdevās!",
    "auth.text13": "Nederīga ievadīta parole",
    "auth.text14": "Ievadītais e-pasta adrese netika atrasts",
    "auth.text15": "Lietotāja izveide neizdevās!",
    "auth.text16": "E-pasta adrese jau tiek izmantota ar citu kontu",
    "auth.text17": "Rādīt ievadījis paroli",
    "auth.text18": "Slēpt paroli",
    "auth.text19": "e-pasts parole atpūtai tika nosūtīts",
    "auth.text20": "Enterd e-pasts netika atrasts",
    "auth.text21": "Paroles atiestatīšanas veiksme",
    "auth.text22": "Paroles atiestatīšana neizdevās, lūdzu, dodieties uz pieteikšanās lapu un sūtīt e-pastu paroles atiestatīšanas atkal",
    "auth.text23": "Paroles atiestatīšanas lapā",
    "auth.text24": "jauna parole",
    "auth.text25": "Atiestatīt paroli",
    "auth.text26": "Lapa ir nederīga vai parole jau ir mainīts",
    "auth.text27": "Sūta e-pastu paroles atiestatīšanas neizdevās, lūdzu, pārbaudiet e-pasta adresi",
    "auth.text28": "E-pasta verifikācijas lapa",
    "auth.text29": "E-pasta pārbaude panākumiem, lūdzu, dodieties uz pieteikšanās lapu norādīts iepriekš",
    "auth.text30": "E-pasta pārbaude neizdevās. Šī lapa ir beidzies, vai pārbaude ir jau pabeigta.",
    "auth.text31": "Pārbaudes pasts tika nosūtīts. Plese pārbaudiet savu e-pastu un pārbaudīt. (Arī chack mēstuļu pastu)",
    "auth.text32": "E-pasta verifikācija ir nepieciešams, lai pieteiktos. Lūdzu sūtīt e-pasta pārbaudei uz jūsu e-pasta adresi.",
    "auth.text33": "Sūtīt e-pastu, lai pārbaudītu",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "feed",
    "nav.text2": "Pieslēgties",
    "nav.text3": "Pierakstīties",
    "nav.text4": "Lietotāja informācija",
    "nav.text5": "Izlogoties",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Lapa nav atrasta",
    "notFound.text2": "dodieties uz lopbarības lapu",
    "notFound.text3": "dodieties uz pieteikšanās lapu",
    "notFound.text4": "Kur jums iet šodien?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "atcelt",
    "comment.text2": "Dzēst",
    "comment.text3": "publicēt komentāru",
    "comment.text4": "Rādīt Atbildēt",
    "comment.text5": "Kopā",
    "comment.text6": "Rakstīt Atbildēt",
    "comment.text7": "Slēpt Atbildēt",
    "comment.text8": "Komentāri",
    "comment.text9": "Vai tas nav problēma dzēst šo komentāru un atbilžu šī komentāra pilnīgi?",
    "comment.text10": "Vai tas nav problēma dzēst šo komentāru pavisam?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Pievienot Favorite Post",
    "follow.text2": "Dzēst no mīļākie pastu",
    "follow.text3": "Pievienot iecienītāko lietotājam",
    "follow.text4": "Dzēst no mīļākie lietotājs",
    "follow.text5": "Rādīt ziņas",
    "follow.text6": "Jūsu mīļākie lietotāji",
    "follow.text7": "Post Iecienītākie lietotāji",
    "__11": "",
    "privacyPolicy.text1": "Privātuma politika",
    "privacyPolicy.text2": "Šī politika var atjaunināt vai pārskatīt bez brīdinājuma. Tas ir atbildīgs lietotājs palikt informēti par konfidencialitātes politikas izmaiņām. Ņem vērā citu privātuma jautājumiem, kas var ietekmēt jums:",
    "privacyPolicy.text3": "Šī vietne varētu būt apdraudēta.",
    "privacyPolicy.text4": "Jūsu dators var būt apdraudēta.",
    "privacyPolicy.text5": "Šī vietne ir izvietots uz Firebase un DigitalOcean infrastruktūru. Tās var un tiem ir savas uzskaites sistēmas uz viņu serveriem. Šiem pakalpojumiem ir savi privātuma politiku, un tie neattiecas šo konfidencialitātes politiku.",
    "privacyPolicy.text6": "Šī tīmekļa vietne saglabā e-pasts, šifrēta forma paroles, un citas izejvielas lietotāju pie hosted Firebase un DigitalOcean infrastruktūru.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Lietošanas noteikumi",
    "termsOfUse.text2": "Privātums",
    "termsOfUse.text3": "Jūsu privātums ir svarīgi mums. Lūdzu, izlasiet",
    "termsOfUse.text4": "Privātuma politika",
    "termsOfUse.text5": "lai iegūtu vairāk informācijas.",
    "termsOfUse.text6": "Jūsu pašu atbildība",
    "termsOfUse.text7": "Tu, lietotājs ir atbildīgs tikai par to savu atbilstību likumiem un nodokļiem savā jurisdikcijā. Jūs esat atbildīgs par savu drošību.",
    "termsOfUse.text8": "Atteikšanās no garantijas",
    "termsOfUse.text9": "Šī vietne ir sniegta uz \"KĀ IR\", bez garantijām vai nekādu.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Sākt Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Lietotāji tiešsaistē",
    "videoTalk.text4": "Sākt runāt",
    "videoTalk.text5": "Jūsu mēģina runāt",
    "videoTalk.text6": "Neviena cita interneta lietotāja eksistē",
    "videoTalk.text7": "Jūs runājat par",
    "videoTalk.text8": "Jūs sākāt runāt",
    "videoTalk.text9": "Rādīt Teksta Talk",
    "videoTalk.text10": "teksta ievades ....",
    "videoTalk.text11": "Tu joprojām runā. Vai vēlaties pārtraukt runāt?",
    "videoTalk.text12": "Calling lietotāju atvienots",
    "videoTalk.text13": "lietotājs jums zvana. Vai jūs pieņemat šo zvanu?",
    "videoTalk.text14": "noraidīt zvanu",
    "videoTalk.text15": "meklēt tiešsaistes lietotāju ...",
    "videoTalk.text16": "meklēt lietotāju ...",
    "__14": "",
    "groupTalk.text1": "radītājs",
    "groupTalk.text2": "apraksts",
    "groupTalk.text3": "izveidošanas datums",
    "groupTalk.text4": "Labot grupas info",
    "groupTalk.text5": "Vai tas nav problēma, lai izdzēstu jūs no šīs grupas dalībnieku?",
    "groupTalk.text6": "Atstājiet no locekļa",
    "groupTalk.text7": "Pievienoties grupai",
    "groupTalk.text8": "Rādīt grupas dalībnieki",
    "groupTalk.text9": "Vai vēlaties pievienoties šai grupai?",
    "groupTalk.text10": "Grupas nosaukums",
    "groupTalk.text11": "Atpakaļ uz sarakstu",
    "groupTalk.text12": "Apstipriniet savu ieguldījumu",
    "groupTalk.text13": "Izveidot grupu",
    "groupTalk.text14": "Atjaunināt Group",
    "groupTalk.text15": "Iziet no grupas",
    "groupTalk.text16": "Iet uz mājas lapu, lai pieteiktos",
    "groupTalk.text17": "grupa (5-100 rakstzīmes)",
    "groupTalk.text18": "grupa apraksts (mazāk nekā 500 rakstzīmes)"
  },
  {
    "LANGUAGE": "Maori",
    "CODE": "mi",
    "test.text1": "Hello",
    "test.text2": "hi",
    "test.text3": "Mauruuru koe",
    "test.text4": "mahere",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Whakakore",
    "general.text2": "Manakohia",
    "general.text3": "Mukua",
    "general.text4": "Mukua",
    "general.text5": "Whakatika",
    "general.text6": "Whakahōu",
    "general.text7": "Tukuatu Atahanga",
    "general.text8": "Mukua Atahanga",
    "general.text9": "image",
    "general.text10": "whāngai",
    "general.text11": "takiuru",
    "general.text12": "tohuake",
    "general.text13": "Info kaiwhakamahi",
    "general.text14": "Takiputa",
    "general.text15": "kia kōnae",
    "general.text16": "me iti iho i te",
    "general.text17": "kupuhipa",
    "general.text18": "makau Whakairinga",
    "general.text19": "Whakairinga",
    "general.text20": "Images",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Post New",
    "feed.text2": "Whakaaturia Whakairinga Kaiwhakamahi",
    "feed.text3": "Whakaatu Whakairinga",
    "feed.text4": "Tiro",
    "feed.text5": "whakatika",
    "feed.text6": "muku",
    "feed.text7": "He ko kahore raruraru ki te pou muku rawa?",
    "feed.text8": "Posted e",
    "feed.text9": "i waihangatia e",
    "feed.text10": "taitara",
    "feed.text11": "image",
    "feed.text12": "ihirangi",
    "feed.text13": "tūmatanui",
    "feed.text14": "tūmataiti",
    "feed.text15": "Taitara mō te pou",
    "feed.text16": "pou por Ihirangi",
    "feed.text17": "muku",
    "feed.text18": "He kahore raruraru ki te whakapakoko muku rawa?",
    "feed.text19": "Mukua",
    "feed.text20": "kia Kōnae Media jpg, JPEG, teitei ranei kōnae mp4, a iti iho i te 3Mb",
    "feed.text21": "pou tūmatanui",
    "feed.text22": "pou tūmataiti",
    "feed.text23": "Patoa hoki Rapu ....",
    "feed.text24": "Rapu Kaiwhakamahi ...",
    "__2": "",
    "userInfo.text1": "Mōhiohio kaiwhakamahi",
    "userInfo.text2": "ingoa",
    "userInfo.text3": "image",
    "userInfo.text4": "rā hanga",
    "userInfo.text5": "He kahore raruraru ki te whakapakoko muku rawa?",
    "userInfo.text6": "Ingoa Hōu ...",
    "userInfo.text7": "kia kōnae Image jpg, JPEG, kōnae teitei ranei, a iti iho i te 1MB",
    "userInfo.text8": "Aratau Dark",
    "userInfo.text9": "Kaiwhakamahi Mō",
    "userInfo.text10": "atu mōhiohio e pā ana ki a koutou, whakaahuatanga bio aha ... (iti iho i te 300 pūāhua)",
    "userInfo.text11": "reo",
    "userInfo.text12": "Huri",
    "userInfo.text13": "whakamōhiotanga",
    "__3": "",
    "auth.text1": "Tō E-mēra",
    "auth.text2": "tō ingoa",
    "auth.text3": "kupuhipa",
    "auth.text4": "kia kupuhipa 8 ki te 20 pūāhua, i te iti rawa kia kotahi te reta pūmatua, kotahi reta pūriki, kotahi tau me te kotahi pūāhua motuhake (@ $!% *? &).",
    "auth.text5": "tohuake",
    "auth.text6": "takiuru",
    "auth.text7": "wareware Kupuhipa",
    "auth.text8": "Whārangi tohuake",
    "auth.text9": "wāhitau E-mēra mō te tautuhi kupuhipa",
    "auth.text10": "tonoa E-mēra",
    "auth.text11": "I rahua te Whakamana. tino Hanga kore te te wāhitau īmēra whakamahia ano!",
    "auth.text12": "rahua takiuru Kaiwhakamahi!",
    "auth.text13": "i tāuruhia Kupuhipa muhu",
    "auth.text14": "kihai i kitea te wāhitau īmēra tomo",
    "auth.text15": "I rahua te hanga Kaiwhakamahi!",
    "auth.text16": "Ko kē te wāhitau īmēra i roto i te whakamahi i tētahi atu pūkete",
    "auth.text17": "Whakaaturia Kupuhipa tāuruhia",
    "auth.text18": "Huna Kupuhipa",
    "auth.text19": "īmēra mō te kupuhipa okiokinga i tonoa",
    "auth.text20": "kihai i kitea Enterd īmēra",
    "auth.text21": "angitu Kupuhipa tautuhi",
    "auth.text22": "tautuhi Kupuhipa rahua, koa haere ano ki te whārangi Takiuru me tuku īmēra mō te tautuhi kupuhipa",
    "auth.text23": "whārangi tautuhi kupuhipa anō",
    "auth.text24": "Kupuhipa Hōu",
    "auth.text25": "Kupuhipa tautuhi",
    "auth.text26": "He muhu Whārangi ranei kua puta ke kē Kupuhipa",
    "auth.text27": "Kei te tuku īmēra mō te tautuhi kupuhipa rahua, Tena wāhitau īmēra Taki",
    "auth.text28": "whārangi whakaū Īmēra",
    "auth.text29": "Īmēra whakaū angitu, tēnā haere ki te whārangi takiuru whakaaturia i runga ake",
    "auth.text30": "I rahua te Īmēra whakaū. Kei te pau te ana tēnei whārangi, kua oti whakaū ranei.",
    "auth.text31": "i tonoa manatokonga Mail. Plese tirohia tō īmēra, me te manatoko. (Chack hoki i roto i te pāme mēra)",
    "auth.text32": "hiahiatia Īmēra manatokonga te ki Takiuru. unga koa Email mō te whakaū ki tō wāhitau Īmēra.",
    "auth.text33": "Tukua Īmēra mō te whakaū",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "whāngai",
    "nav.text2": "takiuru",
    "nav.text3": "tohuake",
    "nav.text4": "Info kaiwhakamahi",
    "nav.text5": "Takiputa",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Kāore Whārangi kitea",
    "notFound.text2": "haere ki te whārangi whāngai",
    "notFound.text3": "haere ki te whārangi takiuru",
    "notFound.text4": "e haere koe i tenei ra ki hea?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Whakakore",
    "comment.text2": "Mukua",
    "comment.text3": "Post Tākupu",
    "comment.text4": "Whakaatu Reply",
    "comment.text5": "tapeke",
    "comment.text6": "Tuhia Reply",
    "comment.text7": "Huna Reply",
    "comment.text8": "Comments",
    "comment.text9": "He kahore raruraru ki te muku i tēnei kōrero me te whakautu o tenei kōrero rawa?",
    "comment.text10": "He kahore raruraru ki te muku i tēnei kōrero rawa?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Tāpiri ki Makau Post",
    "follow.text2": "Mukua i te pou tino",
    "follow.text3": "Tāpiri ki te kaiwhakamahi tino",
    "follow.text4": "Mukua i te kaiwhakamahi tino",
    "follow.text5": "pou whakaatu",
    "follow.text6": "Koutou Kaiwhakamahi Makau",
    "follow.text7": "Kaiwhakamahi Post Makau",
    "__11": "",
    "privacyPolicy.text1": "Kaupapahere tūmataiti",
    "privacyPolicy.text2": "kia whakahoutia tēnei kaupapa kia hou kahore pānui ranei. Ko reira te hopoi'a o te kaiwhakamahi ki te noho mōhio e pā ana ki ngā huringa Kaupapahere tūmataiti. Tangohia tuhipoka o ētahi atu take tūmataiti e kia pā koutou:",
    "privacyPolicy.text3": "kia faaoti ai tēnei paetukutuku.",
    "privacyPolicy.text4": "kia faaoti ai tō rorohiko.",
    "privacyPolicy.text5": "whakahaerehia ana tēnei paetukutuku kei te runga i Firebase me DigitalOcean hanganga. kia ratou, me te e whai ratou ake pūnaha aroturuki i runga i o ratou tūmau. Ko te hunga ratonga i to ratou ake kaupapa here tūmataiti, me te kore e hipokina e ratou i tenei kaupapa here tūmataiti.",
    "privacyPolicy.text6": "Tenei īmēra toa paetukutuku, puka whakamuna o kupuhipa, me ētahi atu kōkuhunga o ngā kaiwhakamahi i whakahaeretia hanganga Firebase me DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Ngā ritenga whakamahi",
    "termsOfUse.text2": "Tūmataitinga",
    "termsOfUse.text3": "He nui ki a tatou i to koutou tūmataiti. Koa te pānui i te",
    "termsOfUse.text4": "Kaupapahere tūmataiti",
    "termsOfUse.text5": "hoki ētahi atu mōhiohio.",
    "termsOfUse.text6": "Koutou kawenga ake",
    "termsOfUse.text7": "Koe, te kaiwhakamahi, he anake te kawenga mō te whakarite koutou ake tautukunga ki ture, me takoha i roto i to koutou mana. Ko koe anake te kawenga mō koutou ake haumarutanga.",
    "termsOfUse.text8": "Whakakape o pūtāhui",
    "termsOfUse.text9": "whakaratohia ana tēnei pae te i runga i te \"AS IS\" Tuhinga, KĀORE pūtāhui OR Tuhinga o TĒTAHI ahua.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Tīmata Connect",
    "videoTalk.text2": "Kāti Connect",
    "videoTalk.text3": "Kaiwhakamahi Tuihono",
    "videoTalk.text4": "Tīmata ki te kōrero",
    "videoTalk.text5": "Kei te ngana tō ki te korero",
    "videoTalk.text6": "vai No atu kaiwhakamahi ipurangi",
    "videoTalk.text7": "E korero koe ki",
    "videoTalk.text8": "tīmata koe ki te kōrero",
    "videoTalk.text9": "Whakaatu Talk Kuputuhi",
    "videoTalk.text10": "tāuru kuputuhi ....",
    "videoTalk.text11": "E korero tonu koe. E hiahia ana koe ki te mutu korero?",
    "videoTalk.text12": "momotu karangatia kaiwhakamahi",
    "videoTalk.text13": "Kei te karanga koutou kaiwhakamahi. E manako koe i tenei karanga?",
    "videoTalk.text14": "paopao koutou karanga",
    "videoTalk.text15": "rapu kaiwhakamahi online ...",
    "videoTalk.text16": "rapu kaiwhakamahi ...",
    "__14": "",
    "groupTalk.text1": "kaihanga",
    "groupTalk.text2": "Whakaahuatanga",
    "groupTalk.text3": "rā hanga",
    "groupTalk.text4": "info Whakatika rōpū",
    "groupTalk.text5": "He kahore raruraru ki te muku koe i ngā mema o tenei rōpū?",
    "groupTalk.text6": "Waiho i Mema",
    "groupTalk.text7": "Hono Rōpū",
    "groupTalk.text8": "Whakaatu Mema Rōpū",
    "groupTalk.text9": "E hiahia ana koe ki te Hono tenei Rōpū?",
    "groupTalk.text10": "ingoa rōpū",
    "groupTalk.text11": "Hoki ki Rārangi",
    "groupTalk.text12": "Whakaūngia tō tāuru",
    "groupTalk.text13": "Waihanga Rōpū",
    "groupTalk.text14": "Whakahōu Group",
    "groupTalk.text15": "Puta i Rōpū",
    "groupTalk.text16": "Haere ki Kāinga ki Takiuru",
    "groupTalk.text17": "ingoa rōpū (5-100 pūāhua)",
    "groupTalk.text18": "whakaahuatanga rōpū (iti iho i te 500 pūāhua)"
  },
  {
    "LANGUAGE": "FYRO Macedonian",
    "CODE": "mk",
    "test.text1": "Здраво",
    "test.text2": "Здраво",
    "test.text3": "Ви благодарам",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Откажи",
    "general.text2": "прифати",
    "general.text3": "Избриши",
    "general.text4": "избришани",
    "general.text5": "Уредување",
    "general.text6": "ажурирање",
    "general.text7": "Постави слика",
    "general.text8": "Избриши слика",
    "general.text9": "слика",
    "general.text10": "feed",
    "general.text11": "Логирај Се",
    "general.text12": "Регистрирај се",
    "general.text13": "Информации за корисникот",
    "general.text14": "Одјава",
    "general.text15": "датотеката треба да биде",
    "general.text16": "и помалку од",
    "general.text17": "Лозинка",
    "general.text18": "омилени Мислења",
    "general.text19": "мислења",
    "general.text20": "слики",
    "general.text21": "видеа",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "нови пост",
    "feed.text2": "Прикажи пристап Мислења",
    "feed.text3": "Пораки",
    "feed.text4": "поглед",
    "feed.text5": "Уредување",
    "feed.text6": "избриши",
    "feed.text7": "Е не е проблем да ги избришете пост целосно?",
    "feed.text8": "Напишано од",
    "feed.text9": "Направено од",
    "feed.text10": "Наслов",
    "feed.text11": "слика",
    "feed.text12": "содржина",
    "feed.text13": "јавниот",
    "feed.text14": "приватна",
    "feed.text15": "Наслов за пост",
    "feed.text16": "Содржина на соба пост",
    "feed.text17": "избриши",
    "feed.text18": "Е тоа не е проблем да ги избришете сликата целосно?",
    "feed.text19": "избришани",
    "feed.text20": "Медиа датотеката треба да биде JPG, JPEG, PNG или MP4 датотеки, а помалку од 3MB",
    "feed.text21": "јавна функција",
    "feed.text22": "приватни пост",
    "feed.text23": "Тип за пребарување ....",
    "feed.text24": "Барај корисник ...",
    "__2": "",
    "userInfo.text1": "Информации за корисникот",
    "userInfo.text2": "име",
    "userInfo.text3": "слика",
    "userInfo.text4": "датум на производство",
    "userInfo.text5": "Е тоа не е проблем да ги избришете сликата целосно?",
    "userInfo.text6": "Ново име ...",
    "userInfo.text7": "сликата треба да биде JPG, JPEG или PNG, а помалку од 1MB",
    "userInfo.text8": "темно режим",
    "userInfo.text9": "за пристап",
    "userInfo.text10": "дополнителни информации за вас, опис био итн ... (помалку од 300 карактери)",
    "userInfo.text11": "Јазик",
    "userInfo.text12": "промена",
    "userInfo.text13": "известување",
    "__3": "",
    "auth.text1": "Твојата електронска пошта",
    "auth.text2": "Вашето име",
    "auth.text3": "Лозинка",
    "auth.text4": "Лозинката треба да биде 8 до 20 карактери, барем една голема буква, една мала буква, еден број и еден посебен знак (@ $!% *? &).",
    "auth.text5": "Регистрирај се",
    "auth.text6": "Логирај Се",
    "auth.text7": "Заборавете Лозинка",
    "auth.text8": "регистрација страница",
    "auth.text9": "E-mail адреса за ресетирање на лозинката",
    "auth.text10": "Испрати е-маил",
    "auth.text11": "Потврдата не е успешна. Бидете сигурни дека е-мејл адреса не се користи уште!",
    "auth.text12": "Најава за корисници не успеа!",
    "auth.text13": "Погрешна лозинка е внесена",
    "auth.text14": "Внесен e-mail адреса не е пронајден",
    "auth.text15": "создавање корисникот не успеа!",
    "auth.text16": "е-маил адреса е веќе во употреба од некоја друга сметка",
    "auth.text17": "Прикажи ја внесовте лозинката",
    "auth.text18": "Сокриј Лозинка",
    "auth.text19": "e-mail за остатокот лозинка е испратена",
    "auth.text20": "Enterd е-мејл не е пронајден",
    "auth.text21": "успех Ресетирање на Лозинка",
    "auth.text22": "Ресетирање на Лозинка не успеа, ве молиме одете на страната за логирање и испрати е-маил за ресетирање на лозинка",
    "auth.text23": "страница лозинка ресетирање",
    "auth.text24": "нова лозинка",
    "auth.text25": "Ресетирање на Лозинка",
    "auth.text26": "Страница е валиден или лозинка е веќе е изменета",
    "auth.text27": "Испраќање на e-mail за ресетирање на лозинката не успеа, ве молиме проверете ја е-мејл адреса",
    "auth.text28": "страница верификација-пошта",
    "auth.text29": "E-mail проверка успех, ве молиме одете на страната за логирање е прикажано погоре",
    "auth.text30": "E-mail проверка пропадна. Оваа страница е истечен, или проверка е завршена.",
    "auth.text31": "Верификација пошта беше испратена. Плесе проверете ја вашата е-мејл и да се провери. (Исто така chack во спам пошта)",
    "auth.text32": "Е-пошта верификација е потребно за да се најавите. Ве молиме испратете е-пошта за проверка на вашата e-mail адреса.",
    "auth.text33": "Прати e-mail за проверка",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "feed",
    "nav.text2": "Логирај Се",
    "nav.text3": "Регистрирај се",
    "nav.text4": "Информации за корисникот",
    "nav.text5": "Одјава",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Страната не е пронајдена",
    "notFound.text2": "Одете на страница feed",
    "notFound.text3": "оди на страната за логирање",
    "notFound.text4": "Каде да одите денес?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Откажи",
    "comment.text2": "Избриши",
    "comment.text3": "остави коментар",
    "comment.text4": "Прикажи Одговори",
    "comment.text5": "Вкупниот",
    "comment.text6": "Напиши коментар",
    "comment.text7": "Сокриј Одговори",
    "comment.text8": "коментари",
    "comment.text9": "Е тоа не е проблем да го избришете овој коментар и одговорите на овој коментар целосно?",
    "comment.text10": "Е тоа не е проблем да го избришете овој коментар целосно?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Додај-во Омилени пост",
    "follow.text2": "Избриши од омилените пост",
    "follow.text3": "Додади во омилени корисникот",
    "follow.text4": "Избриши од омилените корисникот",
    "follow.text5": "пораки",
    "follow.text6": "Твојот Омилен корисници",
    "follow.text7": "Пост Омилени корисници",
    "__11": "",
    "privacyPolicy.text1": "Политика за приватност",
    "privacyPolicy.text2": "Оваа политика може да се ажурира или ревидирани без претходна најава. Тоа е одговорност на корисникот за да бидете информирани за промените политиката за приватност. Земете предвид на други прашања за приватност со кои може да влијаат на:",
    "privacyPolicy.text3": "Овој веб-сајт може да биде компромитиран.",
    "privacyPolicy.text4": "Вашиот компјутер може да биде компромитиран.",
    "privacyPolicy.text5": "Овој веб-сајт е хостиран на Firebase и DigitalOcean инфраструктури. Тие можат и немаат свои системи за следење на нивните сервери. Овие услуги имаат сопствени политики за приватност и не се опфатени со оваа Политика на приватност.",
    "privacyPolicy.text6": "Овој веб-сајт е-мејл продавници, шифрирана форма на лозинка и други влезови на корисници на домаќин Firebase и DigitalOcean инфраструктури.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Услови за користење",
    "termsOfUse.text2": "Заштита на податоци",
    "termsOfUse.text3": "Вашата приватност е важна за нас. Ве молиме прочитајте ги",
    "termsOfUse.text4": "политика за приватност",
    "termsOfUse.text5": "за повеќе информации.",
    "termsOfUse.text6": "Свој одговорности",
    "termsOfUse.text7": "Можете, на корисникот, се единствено одговорни за обезбедување на свој согласност со законите и даноците во вашата јурисдикција. Вие сте единствено одговорни за сопствената безбедност.",
    "termsOfUse.text8": "Порекнување на гаранција",
    "termsOfUse.text9": "Овој сајт е предвиден за \"КАКО ШТО Е\", БЕЗ ГАРАНЦИИ ИЛИ УСЛОВИ ОД БИЛО КАКОВ ВИД.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "почеток Поврзи",
    "videoTalk.text2": "Стоп Поврзи",
    "videoTalk.text3": "онлајн корисници",
    "videoTalk.text4": "Почнат да зборуваат",
    "videoTalk.text5": "Ви се обидува да се зборува",
    "videoTalk.text6": "Нема други онлајн корисникот постои",
    "videoTalk.text7": "Ќе се зборува за",
    "videoTalk.text8": "Ти почна да се зборува",
    "videoTalk.text9": "Прикажи текст Разговор",
    "videoTalk.text10": "внесување на текст ....",
    "videoTalk.text11": "Ако се уште се зборува. Дали сакате да престане да зборува?",
    "videoTalk.text12": "Повикувајќи корисникот исклучен",
    "videoTalk.text13": "корисникот е те вика. Дали го прифаќате овој повик?",
    "videoTalk.text14": "го одбие вашето повик",
    "videoTalk.text15": "пребарување на интернет, на корисникот ...",
    "videoTalk.text16": "пребарување корисник ...",
    "__14": "",
    "groupTalk.text1": "творец",
    "groupTalk.text2": "Опис",
    "groupTalk.text3": "датум на производство",
    "groupTalk.text4": "информации Уреди група",
    "groupTalk.text5": "Е тоа не е проблем да го избришете од членовите на оваа група?",
    "groupTalk.text6": "Остави од земјите-",
    "groupTalk.text7": "Приклучи се на Групен",
    "groupTalk.text8": "Прикажи Членови на групата",
    "groupTalk.text9": "Дали сакате да се придружите на оваа група?",
    "groupTalk.text10": "Име на групата",
    "groupTalk.text11": "Назад",
    "groupTalk.text12": "Потврдете ја вашата влез",
    "groupTalk.text13": "Креирај група",
    "groupTalk.text14": "ажурирање група",
    "groupTalk.text15": "Излез од група",
    "groupTalk.text16": "Главна страница да се најавите",
    "groupTalk.text17": "Име на група (5-100 карактери)",
    "groupTalk.text18": "опис на групата (помалку од 500 карактери)"
  },
  {
    "LANGUAGE": "Mongolian",
    "CODE": "mn",
    "test.text1": "Сайн уу",
    "test.text2": "Сайн байна уу",
    "test.text3": "Баярлалаа",
    "test.text4": "газрын зураг",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Болих",
    "general.text2": "хүлээн авах",
    "general.text3": "Delete",
    "general.text4": "Хассан",
    "general.text5": "Засварлах",
    "general.text6": "шинэчлэх",
    "general.text7": "Оруулж Image",
    "general.text8": "Delete Image",
    "general.text9": "Image",
    "general.text10": "Feed",
    "general.text11": "Нэвтрэх",
    "general.text12": "Бүртгүүлэх",
    "general.text13": "хэрэглэгчийн мэдээлэл",
    "general.text14": "Гарах",
    "general.text15": "файл нь байх ёстой",
    "general.text16": "ба-ээс бага",
    "general.text17": "Нууц үг",
    "general.text18": "Дуртай Posts",
    "general.text19": "Бичлэгийн",
    "general.text20": "Images",
    "general.text21": "видео",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "шинэ Бичлэгийн",
    "feed.text2": "Хэрэглэгчийн Бичлэгүүд харуулах",
    "feed.text3": "харуулах Posts",
    "feed.text4": "харах",
    "feed.text5": "засварлах",
    "feed.text6": "устгах",
    "feed.text7": "бүрэн Delete бичлэгт ямар ч асуудал байна гэж үү?",
    "feed.text8": "Нийтэлсэн",
    "feed.text9": "Бүтээсэн байгуулсан зохион бүтээсэн",
    "feed.text10": "гарчиг",
    "feed.text11": "Image",
    "feed.text12": "Агуулга",
    "feed.text13": "олон нийтийн",
    "feed.text14": "хувийн",
    "feed.text15": "албан тушаалд нэр",
    "feed.text16": "Агуулга POR дараах",
    "feed.text17": "устгах",
    "feed.text18": "Энэ нь бүрэн устгах зургийг ямар ч асуудал байна уу?",
    "feed.text19": "Хассан",
    "feed.text20": "Хэвлэл мэдээллийн Файл JPG, JPEG, PNG, эсвэл mp4 файлд байх ёстой бөгөөд бага 3MB илүү",
    "feed.text21": "олон нийтийн дараах",
    "feed.text22": "хувийн дараах",
    "feed.text23": "Хайх нь бичнэ ....",
    "feed.text24": "User хайх ...",
    "__2": "",
    "userInfo.text1": "Хэрэглэгчийн мэдээлэл",
    "userInfo.text2": "нэр",
    "userInfo.text3": "зураг",
    "userInfo.text4": "бүтээл огноо",
    "userInfo.text5": "Энэ нь бүрэн устгах зургийг ямар ч асуудал байна уу?",
    "userInfo.text6": "Шинэ нэр ...",
    "userInfo.text7": "Зургийн файл нь JPG, JPEG эсвэл PNG файл байх ёстой бөгөөд бага 1MB-аас",
    "userInfo.text8": "Dark горим",
    "userInfo.text9": "тухай User",
    "userInfo.text10": "чиний тухай нэмэлт мэдээлэл, тайлбар био гэх мэт ... (300-аас цөөн тэмдэгт)",
    "userInfo.text11": "хэл",
    "userInfo.text12": "өөрчлөлт",
    "userInfo.text13": "мэдэгдэл",
    "__3": "",
    "auth.text1": "Таны имэйл",
    "auth.text2": "таны нэр",
    "auth.text3": "Нууц үг",
    "auth.text4": "Нууц үг 8-20 тэмдэгт, наад зах нь нэг гэдэг үгний эхний том үсэг, нэг жижиг үсгээр үсэг, нэг тоо, нэг тусгай тэмдэгт байх ёстой (@ $!% *? &).",
    "auth.text5": "Бүртгүүлэх",
    "auth.text6": "Нэвтрэх",
    "auth.text7": "Нууц үгээ мартсан",
    "auth.text8": "бүртгүүлэх Page",
    "auth.text9": "И-мэйл Нууц үг дахин тохируулах хаяг",
    "auth.text10": "И-мэйл илгээх",
    "auth.text11": "Баталгаажуулалт амжилтгүй болсон. шалгаарай и-мэйл хаяг хараахан хэрэглэж байгаа бол!",
    "auth.text12": "Хэрэглэгч нэвтрэх чадсангүй!",
    "auth.text13": "Буруу нууц үг оруулсан байна",
    "auth.text14": "Орж и-мэйл хаяг олж байсан юм",
    "auth.text15": "Хэрэглэгчийн бүтээл чадсангүй!",
    "auth.text16": "и-мэйл хаягийг өөр данс ашиглах аль хэдийн байна",
    "auth.text17": "Оруулсан нууц үг үзүүл",
    "auth.text18": "нуух Нууц үг",
    "auth.text19": "Нууц үг үлдсэн и-мэйл явуулсан байна",
    "auth.text20": "Enterd и-мэйл олж байсан юм",
    "auth.text21": "Нууц үг дахин амжилт",
    "auth.text22": "Нууц үг дахин амжилтгүй болсон, дахин нууц үгийг өөрчлөх Холбогдох хуудсанд илгээх и-мэйл явах уу",
    "auth.text23": "Нууц үг шинэчлэх хуудас",
    "auth.text24": "Шинэ нууц үг",
    "auth.text25": "Нууц үгээ анхны байдалд нь оруулж",
    "auth.text26": "Page хүчингүй эсвэл Нууц үг аль хэдийн өөрчлөгдсөн байна",
    "auth.text27": "Нууц үг дахин тохируулах имэйл илгээх амжилтгүй, уу шалгах и-мэйл хаяг",
    "auth.text28": "И-мэйл шалгах хуудас",
    "auth.text29": "И-мэйл амжилттай шалгалт, Дээр үзүүлсэн нэвтрэх хуудсанд очих уу",
    "auth.text30": "И-мэйл шалгах болсон. Энэ хуудас нь дууссан, эсвэл шалгалтын аль хэдийн дууссан байна.",
    "auth.text31": "Баталгаажуулах мэйл явуулсан байна. Plese таны и-мэйл шалгаж, баталгаажуулах. (Мөн спам шуудангаар chack)",
    "auth.text32": "И-мэйл шалгах нэвтрэх шаардлагатай. Таны И-мэйл хаяг руу баталгаажуулалтын и-мэйл илгээнэ үү.",
    "auth.text33": "Шалгахын тулд И-мэйл илгээх",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Нэвтрэх",
    "nav.text3": "Бүртгүүлэх",
    "nav.text4": "хэрэглэгчийн мэдээлэл",
    "nav.text5": "Гарах",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Хуудас олдсонгүй",
    "notFound.text2": "тэжээлийн хуудас руу очих",
    "notFound.text3": "Нэвтрэх 'хуудсан дахь явах",
    "notFound.text4": "Өнөөдөр та хаашаа явах вэ?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Болих",
    "comment.text2": "Delete",
    "comment.text3": "Post Comment",
    "comment.text4": "харуулах хариулах",
    "comment.text5": "нийт",
    "comment.text6": "бичих хариулах",
    "comment.text7": "нуух хариулах",
    "comment.text8": "Сэтгэгдэл",
    "comment.text9": "Энэ нь бүрэн Энэ сэтгэгдлийн энэ Сэтгэгдлийг, хариу устгах ямар ч асуудал байна уу?",
    "comment.text10": "Энэ нь бүрэн энэ Сэтгэгдлийг устгахын тулд ямар ч асуудал байна уу?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Дуртай Post нэмэх",
    "follow.text2": "дуртай албан тушаалаас нь устгах",
    "follow.text3": "дуртай хэрэглэгчид нэмэх",
    "follow.text4": "дуртай хэрэглэгчийн устгах",
    "follow.text5": "шоу бичлэгүүд",
    "follow.text6": "Таны хамгийн дуртай гишүүд",
    "follow.text7": "Post Дуртай гишүүд",
    "__11": "",
    "privacyPolicy.text1": "Хувийн мэдээллийн талаарх Баримтлал",
    "privacyPolicy.text2": "Энэ бодлого нь шинэчлэгдсэн, эсвэл мэдэгдэлгүйгээр өөрчилж болно. Энэ нь нууцлалын бодлогын өөрчлөлтийн талаар мэдээлэлтэй байх нь хэрэглэгчийн үүрэг юм. танд нөлөөлж болох бусад нууцлалын асуудлыг анхааралдаа авах:",
    "privacyPolicy.text3": "Энэ вэб сайт нь эвдэгдсэн байж болох юм.",
    "privacyPolicy.text4": "Таны компьютер эвдэгдсэн байж болох юм.",
    "privacyPolicy.text5": "Энэ вэб сайт нь Firebase болон DigitalOcean дэд бүтцийн дээр зохион байгуулсан байна. Тэд байж болох, тэдний сервер дээр өөрийн хянах тогтолцоог байна вэ. Эдгээр үйлчилгээ нь өөрийн нууцлалын бодлого байна, тэд энэ нууцлалын бодлогын хамрагдсан байна.",
    "privacyPolicy.text6": "Энэ вэб сайт нь хадгалдаг и-мэйл, нууц үгийг шифрлэгдсэн хэлбэр, зохион байгуулсан Firebase болон DigitalOcean дэд бүтцийн үед хэрэглэгчдийн бусад оролт.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "ашиглах нөхцөл",
    "termsOfUse.text2": "Нууцлалын",
    "termsOfUse.text3": "Таны хувийн нууцыг бидэнд чухал ач холбогдолтой юм. уншина уу",
    "termsOfUse.text4": "Хувийн мэдээллийн талаарх Баримтлал",
    "termsOfUse.text5": "Дэлгэрэнгүй мэдээллийг.",
    "termsOfUse.text6": "Таны өөрийн үүрэг хариуцлага",
    "termsOfUse.text7": "Та, хэрэглэгч, таны улс оронд хууль тогтоомж, татварын өөрийн хэрэгжилтийг хангах дангаараа хариуцдаг юм. Та өөрийн аюулгүй байдлыг хангах нь зөвхөн үүрэгтэй.",
    "termsOfUse.text8": "баталгаат хугацаа Disclaimer",
    "termsOfUse.text9": "Энэ сайт нь дээр заасан байгаа үндэс, баталгаа эсвэл ямар нэгэн нөхцөл Хязгааргүй \"гэж байна.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Онлайн гишүүд",
    "videoTalk.text4": "ярих эхлэх",
    "videoTalk.text5": "Таны яриа гэж оролдож байна",
    "videoTalk.text6": "Ямар ч бусад онлайн хэрэглэгч байхгүй",
    "videoTalk.text7": "Та ярьж байна",
    "videoTalk.text8": "Та ярьж эхэлсэн",
    "videoTalk.text9": "Харуулах Текст Talk",
    "videoTalk.text10": "текст оруулах ....",
    "videoTalk.text11": "Та одоо ч гэсэн ярьж байна. Та ярьж зогсоохыг хүсэж байна уу?",
    "videoTalk.text12": "Дуудсан хэрэглэгчийн холбогдоогүй",
    "videoTalk.text13": "Хэрэглэгчийн чамайг дуудаж байна. Та энэ дуудлагыг хүлээн авч байна вэ?",
    "videoTalk.text14": "таны дуудлагыг татгалзах",
    "videoTalk.text15": "Онлайн хэрэглэгчийг хайх ...",
    "videoTalk.text16": "Хэрэглэгч хайх ...",
    "__14": "",
    "groupTalk.text1": "бүтээгч",
    "groupTalk.text2": "Тодорхойлолт",
    "groupTalk.text3": "бүтээл огноо",
    "groupTalk.text4": "Засах бүлэг мэдээлэл",
    "groupTalk.text5": "Энэ нь энэ бүлгийн гишүүн та нарыг устгах ямар ч асуудал байна уу?",
    "groupTalk.text6": "Гишүүн нь үлдээх",
    "groupTalk.text7": "Group нэгдээрэй",
    "groupTalk.text8": "Харуулах групп гишүүд",
    "groupTalk.text9": "Та энэ бүлгэмд нэгдэх гэж байна уу?",
    "groupTalk.text10": "бүлгийн нэр",
    "groupTalk.text11": "Жагсаалт Буцах",
    "groupTalk.text12": "таны оролтыг баталгаажуулна",
    "groupTalk.text13": "групп үүсгэх",
    "groupTalk.text14": "Update Group",
    "groupTalk.text15": "Group-аас гарах",
    "groupTalk.text16": "Нэвтрэх Homepage рүү очих",
    "groupTalk.text17": "бүлгийн нэр (5-100 тэмдэгт)",
    "groupTalk.text18": "бүлгийн тодорхойлолт (500-аас доошгүй тэмдэгт)"
  },
  {
    "LANGUAGE": "Marathi",
    "CODE": "mr",
    "test.text1": "नमस्कार",
    "test.text2": "हाय",
    "test.text3": "धन्यवाद",
    "test.text4": "नकाशा",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "रद्द करा",
    "general.text2": "स्वीकारा",
    "general.text3": "हटवा",
    "general.text4": "हटविले",
    "general.text5": "सुधारणे",
    "general.text6": "अद्यतनित करा",
    "general.text7": "प्रतिमा अपलोड करा",
    "general.text8": "हटवा प्रतिमा",
    "general.text9": "प्रतिमा",
    "general.text10": "अन्न देणे",
    "general.text11": "लॉग-इन",
    "general.text12": "साइन अप",
    "general.text13": "वापरकर्ता माहिती",
    "general.text14": "बाहेर पडणे",
    "general.text15": "फाईल असणे आवश्यक आहे",
    "general.text16": "आणि पेक्षा कमी",
    "general.text17": "पासवर्ड",
    "general.text18": "आवडते पोस्ट",
    "general.text19": "टिप्पणी पोस्ट करा",
    "general.text20": "प्रतिमा",
    "general.text21": "व्हिडिओ",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "नवीन नोंद",
    "feed.text2": "वापरकर्ता पोस्ट दर्शवा",
    "feed.text3": "पोस्ट दर्शवा",
    "feed.text4": "दृश्य",
    "feed.text5": "सुधारणे",
    "feed.text6": "हटवा",
    "feed.text7": "पूर्णपणे हटवा पोस्ट हरकत नाही आहे?",
    "feed.text8": "द्वारा पोस्ट केलेले",
    "feed.text9": "ने निर्मित",
    "feed.text10": "शीर्षक",
    "feed.text11": "प्रतिमा",
    "feed.text12": "सामग्री",
    "feed.text13": "सार्वजनिक",
    "feed.text14": "खाजगी",
    "feed.text15": "पोस्ट शीर्षक",
    "feed.text16": "सामग्री द्वारे पोस्ट",
    "feed.text17": "हटवा",
    "feed.text18": "तो पूर्णपणे हटवा प्रतिमा नाही समस्या आहे का?",
    "feed.text19": "हटविले",
    "feed.text20": "मीडिया फाईल jpg, JPEG, PNG किंवा MP4 फाइल असेल, आणि कमी 3mb पेक्षा पाहिजे",
    "feed.text21": "सार्वजनिक पोस्ट",
    "feed.text22": "खाजगी पोस्ट",
    "feed.text23": "शोध टाइप करा ....",
    "feed.text24": "शोधा वापरकर्ता ...",
    "__2": "",
    "userInfo.text1": "वापरकर्ता माहिती",
    "userInfo.text2": "नाव",
    "userInfo.text3": "प्रतिमा",
    "userInfo.text4": "निर्मिती तारीख",
    "userInfo.text5": "तो पूर्णपणे हटवा प्रतिमा नाही समस्या आहे का?",
    "userInfo.text6": "नवीन नाव ...",
    "userInfo.text7": "प्रतिमा फाइल JPG, JPEG, किंवा PNG फाईल, आणि कमी 1MB पेक्षा पाहिजे",
    "userInfo.text8": "गडद मोड",
    "userInfo.text9": "बद्दल वापरकर्ता",
    "userInfo.text10": "आपण अतिरिक्त माहिती, वर्णन जैव इ ... (300 पेक्षा कमी वर्ण)",
    "userInfo.text11": "इंग्रजी",
    "userInfo.text12": "बदला",
    "userInfo.text13": "सूचना",
    "__3": "",
    "auth.text1": "आपला ई - मेल",
    "auth.text2": "आपले नाव",
    "auth.text3": "पासवर्ड",
    "auth.text4": "पासवर्ड 8 ते 20 वर्ण, किमान एक मोठे पत्र, एक लोअरकेस अक्षर, एक नंबर आणि एक विशेष वर्ण असावे (@ $% *? &).",
    "auth.text5": "साइन अप",
    "auth.text6": "लॉग-इन",
    "auth.text7": "पासवर्ड विसरा",
    "auth.text8": "साइन अप पृष्ठ",
    "auth.text9": "पासवर्ड रीसेट ई-मेल पत्ता",
    "auth.text10": "ई - मेल पाठवा",
    "auth.text11": "प्रमाणीकरण अयशस्वी झाले. याची खात्री करा ई-मेल पत्ता अद्याप वापरली जात नाही!",
    "auth.text12": "वापरकर्ता लॉग इन अयशस्वी झाले!",
    "auth.text13": "अवैध संकेतशब्द प्रविष्ट केला होता",
    "auth.text14": "प्रविष्ट केलेला ईमेल पत्ता सापडला नाही",
    "auth.text15": "वापरकर्ता तयार करणे अयशस्वी!",
    "auth.text16": "ई-मेल पत्ता दुसर्या खात्याद्वारे आधीच वापरात आहे",
    "auth.text17": "प्रविष्ट संकेतशब्द दर्शवा",
    "auth.text18": "संकेतशब्द लपवा",
    "auth.text19": "पासवर्ड उर्वरित ईमेल पाठविले गेले",
    "auth.text20": "Enterd ईमेल आढळले नाही",
    "auth.text21": "संकेतशब्द रीसेट यश",
    "auth.text22": "पासवर्ड रीसेट करणे अयशस्वी झाले, कृपया पुन्हा संकेतशब्द रीसेट करण्यासाठी लॉग-इन पृष्ठ आणि पाठवा ईमेल जा",
    "auth.text23": "संकेतशब्द रीसेट करा पृष्ठ",
    "auth.text24": "नवीन संकेतशब्द",
    "auth.text25": "पासवर्ड रीसेट करा",
    "auth.text26": "पृष्ठ अवैध आहे किंवा संकेतशब्द आधीच बदलले आहे",
    "auth.text27": "संकेतशब्द रीसेट करण्यासाठी ईमेल पाठवत आहे अयशस्वी, कृपया ई-मेल पत्ता",
    "auth.text28": "ई-मेल सत्यापन पृष्ठ",
    "auth.text29": "यश सत्यापन ई-मेल, कृपया लॉग इन पृष्ठ वर दर्शविलेल्या जा",
    "auth.text30": "ईमेल सत्यापन अयशस्वी झाले. हे पृष्ठ कालबाह्य झाले आहे, किंवा सत्यापन आधीच पूर्ण झाले आहे.",
    "auth.text31": "पडताळणी मेल पाठवले होते. Plese आपले ईमेल तपासा आणि सत्यापित करा. (देखील स्पॅम मेल chack)",
    "auth.text32": "ई-मेल सत्यापन लॉग-इन करणे आवश्यक आहे. कृपया आपला ईमेल पत्ता पडताळणी ईमेल पाठवा.",
    "auth.text33": "सत्यापन ईमेल पाठवा",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "अन्न देणे",
    "nav.text2": "लॉग-इन",
    "nav.text3": "साइन अप",
    "nav.text4": "वापरकर्ता माहिती",
    "nav.text5": "बाहेर पडणे",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "पृष्ठ सापडले नाही",
    "notFound.text2": "फीड पृष्ठावर जा",
    "notFound.text3": "करण्यासाठी प्रवेश पृष्ठावर जा",
    "notFound.text4": "आज आपण कुठे जाऊ?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "रद्द करा",
    "comment.text2": "हटवा",
    "comment.text3": "टिप्पणी पोस्ट करा",
    "comment.text4": "उत्तर द्या दर्शवा",
    "comment.text5": "एकूण",
    "comment.text6": "उत्तर द्या लिहा",
    "comment.text7": "उत्तर द्या लपवा",
    "comment.text8": "टिप्पण्या",
    "comment.text9": "तो पूर्णपणे या टिप्पणीच्या ही टिप्पणी आणि प्रत्युत्तरे हटविण्यासाठी कोणतेही समस्या आहे का?",
    "comment.text10": "तो पूर्णपणे ही टिप्पणी हटवू हरकत नाही आहे का?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "आवडते पोस्ट जोडा",
    "follow.text2": "आवडत्या पोस्ट हटवा",
    "follow.text3": "आवडत्या वापरकर्ता जोडा",
    "follow.text4": "आवडत्या वापरकर्ता हटवा",
    "follow.text5": "पोस्ट शो",
    "follow.text6": "आपले आवडते वापरकर्ते",
    "follow.text7": "आवडते पोस्ट वापरकर्ते",
    "__11": "",
    "privacyPolicy.text1": "गोपनीयता धोरण",
    "privacyPolicy.text2": "हे धोरण सुधारित किंवा सूचना न देता सुधारित केली जाऊ शकतात. तो गोपनीयता धोरण बदल माहिती ठेवण्यास वापरकर्ता जबाबदारी आहे. आपण प्रभाव पडू शकतो की इतर गोपनीयता समस्या लक्षात ठेवा:",
    "privacyPolicy.text3": "ही वेबसाइट तडजोड केली जाऊ शकते.",
    "privacyPolicy.text4": "आपला संगणक तडजोड केली जाऊ शकते.",
    "privacyPolicy.text5": "ही वेबसाइट Firebase आणि DigitalOcean पायाभूत सुविधा होस्ट केले आहे. कदाचित त्यांच्या सर्व्हरवर त्यांच्या स्वत: च्या ट्रॅकिंग प्रणाली आहेत. त्या सेवा त्यांच्या स्वत: च्या गोपनीयता धोरण आहे आणि ते या गोपनीयता धोरण समाविष्ट न केलेले.",
    "privacyPolicy.text6": "हा ई-मेल वेबसाइट स्टोअर्स, पासवर्ड एनक्रिप्टेड स्वरूपात, वापरकर्ते इतर साधनांचा होस्ट Firebase आणि DigitalOcean पायाभूत सुविधा येथे.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "वापरण्याच्या अटी",
    "termsOfUse.text2": "गोपनीयता",
    "termsOfUse.text3": "आपली गोपनीयता आमच्यासाठी महत्त्वाची आहे. कृपया",
    "termsOfUse.text4": "गोपनीयता धोरण",
    "termsOfUse.text5": "अधिक माहितीसाठी.",
    "termsOfUse.text6": "आपले स्वत: चे जबाबदारी",
    "termsOfUse.text7": "आपण वापरकर्ता, आपल्या अधिकार कायदे आणि कर आपल्या स्वत: च्या पालन सुनिश्चित करण्यासाठी पूर्णपणे जबाबदार आहात. आपण आपल्या स्वत: च्या सुरक्षिततेसाठी पूर्णपणे जबाबदार आहात.",
    "termsOfUse.text8": "हमी अस्वीकरण",
    "termsOfUse.text9": "ही साइट पुरवण्यात आली आहे कोणत्याही प्रकारच्या हमी किंवा नियम न तत्वावर \"तशी\" आहे.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "प्रारंभ कनेक्ट",
    "videoTalk.text2": "कनेक्ट थांबवा",
    "videoTalk.text3": "ऑनलाइन वापरकर्ते",
    "videoTalk.text4": "बोलणे प्रारंभ",
    "videoTalk.text5": "आपला चर्चा करण्याचा प्रयत्न करत आहात",
    "videoTalk.text6": "कोणत्याही इतर ऑनलाइन वापरकर्ता विद्यमान",
    "videoTalk.text7": "आपण बोलत आहात",
    "videoTalk.text8": "आपण बोलणे सुरु",
    "videoTalk.text9": "मजकूर टॉक शो",
    "videoTalk.text10": "मजकूर इनपुट ....",
    "videoTalk.text11": "आपण अजूनही बोलत आहेत. आपण बोलत थांबवू इच्छिता का?",
    "videoTalk.text12": "वापरकर्ता कॉल करत आहे डिस्कनेक्ट",
    "videoTalk.text13": "वापरकर्ता कॉल करत आहे. आपण हा कॉल मान्य आहे का?",
    "videoTalk.text14": "आपल्या कॉल नाकारू",
    "videoTalk.text15": "ऑनलाइन वापरकर्ता शोध ...",
    "videoTalk.text16": "शोध वापरकर्ता ...",
    "__14": "",
    "groupTalk.text1": "निर्माता",
    "groupTalk.text2": "वर्णन",
    "groupTalk.text3": "निर्मिती तारीख",
    "groupTalk.text4": "गट माहिती मिळवा",
    "groupTalk.text5": "या गटाच्या सदस्य आपण हटवू काही हरकत नाही आहे का?",
    "groupTalk.text6": "सदस्य पासून द्या",
    "groupTalk.text7": "गटात सामील व्हा",
    "groupTalk.text8": "गट सदस्य दर्शवा",
    "groupTalk.text9": "आपण या समूहात सामील व्हा करू इच्छिता का?",
    "groupTalk.text10": "गटाचे नाव",
    "groupTalk.text11": "सूचीकडे परत",
    "groupTalk.text12": "आपले इनपुट पुष्टी करा",
    "groupTalk.text13": "गट तयार करा",
    "groupTalk.text14": "अद्यतन गट",
    "groupTalk.text15": "गट वरून बाहेर",
    "groupTalk.text16": "लॉग-इन करण्यासाठी मुख्यपृष्ठ जा",
    "groupTalk.text17": "गट नाव (5-100 वर्ण)",
    "groupTalk.text18": "गट वर्णन (500 पेक्षा कमी वर्ण)"
  },
  {
    "LANGUAGE": "Malay",
    "CODE": "ms",
    "test.text1": "Hello",
    "test.text2": "Hi",
    "test.text3": "Terima kasih",
    "test.text4": "peta",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Batal",
    "general.text2": "terima",
    "general.text3": "Padam",
    "general.text4": "Deleted",
    "general.text5": "Edit",
    "general.text6": "Kemas kini",
    "general.text7": "Memuat naik imej",
    "general.text8": "Padam Image",
    "general.text9": "Imej",
    "general.text10": "Feed",
    "general.text11": "Log masuk",
    "general.text12": "Daftar",
    "general.text13": "Maklumat Pengguna",
    "general.text14": "Log keluar",
    "general.text15": "fail perlu",
    "general.text16": "dan kurang daripada",
    "general.text17": "kata laluan",
    "general.text18": "Posts kegemaran",
    "general.text19": "jawatan",
    "general.text20": "Images",
    "general.text21": "Video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Jawatan baru",
    "feed.text2": "Tunjuk User Pos",
    "feed.text3": "Paparkan Kiriman",
    "feed.text4": "pandangan",
    "feed.text5": "edit",
    "feed.text6": "memadam",
    "feed.text7": "Apakah tidak ada masalah pada catatan padam sepenuhnya?",
    "feed.text8": "Dicatat oleh",
    "feed.text9": "Dicipta oleh",
    "feed.text10": "tajuk",
    "feed.text11": "Imej",
    "feed.text12": "Kandungan",
    "feed.text13": "awam",
    "feed.text14": "swasta",
    "feed.text15": "Tajuk untuk post",
    "feed.text16": "jawatan por kandungan",
    "feed.text17": "memadam",
    "feed.text18": "Adakah ia tidak ada masalah untuk imej padam sepenuhnya?",
    "feed.text19": "Deleted",
    "feed.text20": "Media File harus jpg, jpeg, png atau fail mp4, dan kurang daripada 3MB",
    "feed.text21": "jawatan awam",
    "feed.text22": "siaran peribadi",
    "feed.text23": "Menaip untuk Cari ....",
    "feed.text24": "Mencari pengguna ...",
    "__2": "",
    "userInfo.text1": "Maklumat pengguna",
    "userInfo.text2": "nama",
    "userInfo.text3": "imej",
    "userInfo.text4": "tarikh penciptaan",
    "userInfo.text5": "Adakah ia tidak ada masalah untuk imej padam sepenuhnya?",
    "userInfo.text6": "Nama baru ...",
    "userInfo.text7": "fail imej perlu jpg, jpeg, png atau fail, dan kurang daripada 1MB",
    "userInfo.text8": "Mod gelap",
    "userInfo.text9": "pengguna tentang",
    "userInfo.text10": "maklumat tambahan tentang anda, penerangan bio lain-lain ... (kurang daripada 300 aksara)",
    "userInfo.text11": "bahasa",
    "userInfo.text12": "Ubah",
    "userInfo.text13": "pemberitahuan",
    "__3": "",
    "auth.text1": "Emel anda",
    "auth.text2": "Nama awak",
    "auth.text3": "kata laluan",
    "auth.text4": "Kata laluan hendaklah 8-20 watak-watak, sekurang-kurangnya satu huruf besar, satu huruf kecil, satu nombor dan satu aksara khas (@ $!% *? &).",
    "auth.text5": "Daftar",
    "auth.text6": "Log masuk",
    "auth.text7": "Lupa kata laluan",
    "auth.text8": "Daftar Page",
    "auth.text9": "Alamat e-mel untuk menetapkan semula kata laluan",
    "auth.text10": "menghantar e-mel",
    "auth.text11": "Pengesahan gagal. Pastikan alamat e-mel yang tidak digunakan lagi!",
    "auth.text12": "Masuk pengguna gagal!",
    "auth.text13": "Tidak sah Kata laluan telah Dimasukkan",
    "auth.text14": "alamat e-mel yang dimasukkan tidak dijumpai",
    "auth.text15": "penciptaan pengguna gagal!",
    "auth.text16": "Alamat e-mel sudah digunakan oleh akaun lain",
    "auth.text17": "Tunjuk Dimasukkan Kata laluan",
    "auth.text18": "Menyembunyikan Kata laluan",
    "auth.text19": "e-mel untuk berehat kata laluan telah dihantar",
    "auth.text20": "e-mel Enterd tidak dijumpai",
    "auth.text21": "kejayaan penetapan semula kata laluan",
    "auth.text22": "penetapan semula kata laluan gagal, Sila pergi ke halaman Daftar dan e-mel menghantar untuk set semula kata laluan sekali lagi",
    "auth.text23": "halaman tetapan semula kata laluan",
    "auth.text24": "Kata laluan baharu",
    "auth.text25": "Menetapkan semula kata laluan",
    "auth.text26": "Halaman tidak sah atau Kata Laluan sudah berubah",
    "auth.text27": "Hantar e-mel untuk menetapkan semula kata laluan gagal, Sila menyemak alamat e-mel",
    "auth.text28": "E-mel halaman pengesahan",
    "auth.text29": "E-mel pengesahan berjaya, sila pergi ke halaman login yang ditunjukkan di atas",
    "auth.text30": "pengesahan e-mel gagal. Laman ini telah tamat tempoh, atau pengesahan sudah selesai.",
    "auth.text31": "Mail pengesahan telah dihantar. Plese semak e-mel anda dan mengesahkan. (Juga Chack dalam mel spam)",
    "auth.text32": "E-mel pengesahan diperlukan untuk Login. Sila hantar e-mel untuk pengesahan ke alamat E-mel anda.",
    "auth.text33": "Menghantar E-mel untuk pengesahan",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "Log masuk",
    "nav.text3": "Daftar",
    "nav.text4": "Maklumat Pengguna",
    "nav.text5": "Log keluar",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Laman tidak dijumpai",
    "notFound.text2": "pergi ke halaman suapan",
    "notFound.text3": "pergi ke halaman login",
    "notFound.text4": "Di manakah anda pergi hari ini?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Batal",
    "comment.text2": "Padam",
    "comment.text3": "pos komen",
    "comment.text4": "persembahan Balas",
    "comment.text5": "jumlah",
    "comment.text6": "Tulis Balas",
    "comment.text7": "Menyembunyikan Balas",
    "comment.text8": "Comments",
    "comment.text9": "Adakah ia tidak ada masalah untuk memadam komen ini dan dihantar komen ini sepenuhnya?",
    "comment.text10": "Adakah ia tidak ada masalah untuk memadam komen ini sepenuhnya?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Tambahkan ke kegemaran Post",
    "follow.text2": "Memotong daripada post kegemaran",
    "follow.text3": "Menambah kepada pengguna kegemaran",
    "follow.text4": "Memotong daripada pengguna kegemaran",
    "follow.text5": "jawatan persembahan",
    "follow.text6": "Pengguna Kegemaran Anda",
    "follow.text7": "Pengguna Post kegemaran",
    "__11": "",
    "privacyPolicy.text1": "Dasar privasi",
    "privacyPolicy.text2": "Polisi ini boleh dikemaskini atau disemak tanpa notis. Adalah menjadi tanggungjawab pengguna untuk penginapan dimaklumkan mengenai perubahan dasar privasi. Mengambil perhatian terhadap isu-isu privasi lain yang boleh memberi kesan kepada anda:",
    "privacyPolicy.text3": "laman web ini akan tergadai.",
    "privacyPolicy.text4": "Komputer anda akan tergadai.",
    "privacyPolicy.text5": "Laman web ini dihoskan pada Firebase dan DigitalOcean infrastruktur. Mereka boleh dan jangan mempunyai sistem pengesanan mereka sendiri pada pelayan mereka. Perkhidmatan tersebut mempunyai dasar privasi mereka sendiri dan mereka tidak dilindungi oleh dasar privasi ini.",
    "privacyPolicy.text6": "Ini e-mel kedai laman web, bentuk disulitkan kata laluan, dan lain-lain input dari pengguna di dihoskan Firebase dan DigitalOcean infrastruktur.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Syarat-syarat penggunaan",
    "termsOfUse.text2": "Privasi",
    "termsOfUse.text3": "Privasi anda adalah penting bagi kami. Sila baca",
    "termsOfUse.text4": "dasar privasi",
    "termsOfUse.text5": "untuk maklumat lanjut.",
    "termsOfUse.text6": "Tanggungjawab anda sendiri",
    "termsOfUse.text7": "Anda, pengguna, adalah semata-mata bertanggungjawab untuk memastikan pematuhan anda sendiri dengan undang-undang dan cukai dalam bidang kuasa anda. Anda bertanggungjawab sepenuhnya untuk keselamatan anda sendiri.",
    "termsOfUse.text8": "Penafian waranti",
    "termsOfUse.text9": "Laman web ini disediakan dalam keadaan \"AS IS\", TANPA WARANTI ATAU SYARAT APA-APA JENIS.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Mula Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Pengguna dalam talian",
    "videoTalk.text4": "Mula bercakap",
    "videoTalk.text5": "Anda cuba untuk bercakap",
    "videoTalk.text6": "Tiada pengguna dalam talian lain wujud",
    "videoTalk.text7": "Anda bercakap dengan",
    "videoTalk.text8": "Anda mula bercakap",
    "videoTalk.text9": "Persembahan Text Talk",
    "videoTalk.text10": "input teks ....",
    "videoTalk.text11": "Anda masih bercakap. Adakah anda mahu berhenti bercakap?",
    "videoTalk.text12": "pengguna Panggilan terputus",
    "videoTalk.text13": "pengguna sedang memanggil anda. Adakah anda menerima panggilan ini?",
    "videoTalk.text14": "menolak panggilan anda",
    "videoTalk.text15": "mencari dalam talian pengguna ...",
    "videoTalk.text16": "mencari pengguna ...",
    "__14": "",
    "groupTalk.text1": "pencipta",
    "groupTalk.text2": "Penerangan",
    "groupTalk.text3": "tarikh penciptaan",
    "groupTalk.text4": "Edit maklumat kumpulan",
    "groupTalk.text5": "Adakah ia tidak ada masalah untuk memadam anda daripada ahli-ahli kumpulan ini?",
    "groupTalk.text6": "Bertolak dari Ahli",
    "groupTalk.text7": "Sertai kumpulan",
    "groupTalk.text8": "Persembahan Kumpulan Ahli",
    "groupTalk.text9": "Adakah Anda Ingin Sertai Kumpulan Ini?",
    "groupTalk.text10": "Nama kumpulan",
    "groupTalk.text11": "Kembali kepada senarai",
    "groupTalk.text12": "Mengesahkan input anda",
    "groupTalk.text13": "Buat Kumpulan",
    "groupTalk.text14": "Update Kumpulan",
    "groupTalk.text15": "Keluar daripada Kumpulan",
    "groupTalk.text16": "Pergi ke Laman untuk Login",
    "groupTalk.text17": "nama kumpulan (5-100 aksara)",
    "groupTalk.text18": "penerangan kumpulan (kurang daripada 500 aksara)"
  },
  {
    "LANGUAGE": "Maltese",
    "CODE": "mt",
    "test.text1": "Bongu",
    "test.text2": "Hi",
    "test.text3": "Grazzi",
    "test.text4": "mappa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Ikkanċella",
    "general.text2": "Aċċetta",
    "general.text3": "Ħassar",
    "general.text4": "imħassar",
    "general.text5": "jeditjaw",
    "general.text6": "aġġornament",
    "general.text7": "Tella Image",
    "general.text8": "Image Ħassar",
    "general.text9": "Image",
    "general.text10": "għalf",
    "general.text11": "Idħol",
    "general.text12": "Irregistra",
    "general.text13": "utent Info",
    "general.text14": "Oħroġ",
    "general.text15": "fajl għandu jkun",
    "general.text16": "u inqas minn",
    "general.text17": "password",
    "general.text18": "Favorite Karigi",
    "general.text19": "karigi",
    "general.text20": "stampi",
    "general.text21": "videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "ġdid Post",
    "feed.text2": "Uri Utent Karigi",
    "feed.text3": "Uri Karigi",
    "feed.text4": "fehma",
    "feed.text5": "jeditjaw",
    "feed.text6": "tħassar",
    "feed.text7": "Huwa l-ebda problema biex kariga ħassar kompletament?",
    "feed.text8": "stazzjonati mill",
    "feed.text9": "maħluqa mill",
    "feed.text10": "Titolu",
    "feed.text11": "Image",
    "feed.text12": "kontenut",
    "feed.text13": "pubbliku",
    "feed.text14": "privata",
    "feed.text15": "Titolu għall-posta",
    "feed.text16": "posta por Kontenut",
    "feed.text17": "tħassar",
    "feed.text18": "Huwa l-ebda problema mad-dehra ħassar kompletament?",
    "feed.text19": "imħassar",
    "feed.text20": "Midja Fajl għandu jkun jpg, jpeg, png jew mp4 fajl, u inqas minn 3MB",
    "feed.text21": "posta pubbliku",
    "feed.text22": "posta privata",
    "feed.text23": "Tip ta Fittex ....",
    "feed.text24": "Fittex Utent ...",
    "__2": "",
    "userInfo.text1": "Informazzjoni lill-utent",
    "userInfo.text2": "isem",
    "userInfo.text3": "immaġni",
    "userInfo.text4": "data ħolqien",
    "userInfo.text5": "Huwa l-ebda problema mad-dehra ħassar kompletament?",
    "userInfo.text6": "Isem ġdid ...",
    "userInfo.text7": "Image fajl għandu jkun jpg, jpeg, jew file png, u inqas minn 1MB",
    "userInfo.text8": "dlam Modalità",
    "userInfo.text9": "Utent dwar",
    "userInfo.text10": "informazzjoni addizzjonali dwarek, id-deskrizzjoni bijo eċċ ... (inqas minn 300 karattru)",
    "userInfo.text11": "lingwa",
    "userInfo.text12": "bidla",
    "userInfo.text13": "notifika",
    "__3": "",
    "auth.text1": "L-email tieghek",
    "auth.text2": "Ismek",
    "auth.text3": "password",
    "auth.text4": "Password għandha tkun 8 sa 20 karattru, ittra mill-inqas wieħed uppercase, ittra waħda zghar, numru wieħed u karattru speċjali wieħed (@ $!% *? &).",
    "auth.text5": "Irregistra",
    "auth.text6": "Idħol",
    "auth.text7": "tinsa Password",
    "auth.text8": "Signup Page",
    "auth.text9": "E-mail għal reset password",
    "auth.text10": "tibgħat E-mail",
    "auth.text11": "Validazzjoni fallew. Kun żgur li l-indirizz email ma tintużax għadha!",
    "auth.text12": "utent login naqset!",
    "auth.text13": "Password Invalid kien Daħal",
    "auth.text14": "Daħal l-indirizz email ma nstabx",
    "auth.text15": "ħolqien utent naqset!",
    "auth.text16": "L-indirizz email huwa diġà fl-użu minn kont ieħor",
    "auth.text17": "Uri Password Daħal",
    "auth.text18": "hide Password",
    "auth.text19": "email għall-bqija password ntbagħtet",
    "auth.text20": "email enterd ma nstabx",
    "auth.text21": "suċċess Password reset",
    "auth.text22": "reset password naqset, jekk jogħġbok mur għall-paġna Login u l-email jibgħat għall reset password darb'oħra",
    "auth.text23": "paġna reset password",
    "auth.text24": "Password gdida",
    "auth.text25": "Password Irrisettja",
    "auth.text26": "Page huwa invalidu jew Password diġà nbidlu",
    "auth.text27": "Mittenti email għall-reset password naqset, jekk jogħġbok verifika l-indirizz email",
    "auth.text28": "paġna verifika Email",
    "auth.text29": "Email verifika suċċess, jekk jogħġbok mur għall-paġna login murija hawn fuq",
    "auth.text30": "verifika Email fallew. Din il-paġna hija skaduta, jew verifika huwa diġà lest.",
    "auth.text31": "Verifika Mail ntbagħtet. Plese jiċċekkjaw email tiegħek u tivverifika. (Chack wkoll posta spam)",
    "auth.text32": "Email verifika hija meħtieġa biex Login. Jekk jogħġbok ibgħat email għall-verifika lill-indirizz email tiegħek.",
    "auth.text33": "Send Email għall-verifika",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "għalf",
    "nav.text2": "Idħol",
    "nav.text3": "Irregistra",
    "nav.text4": "utent Info",
    "nav.text5": "Oħroġ",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Page Mhux tinsab",
    "notFound.text2": "mur għall-paġna għalf",
    "notFound.text3": "mur għall-paġna login",
    "notFound.text4": "Fejn do you go illum?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Ikkanċella",
    "comment.text2": "Ħassar",
    "comment.text3": "Post Kumment",
    "comment.text4": "Uri Tweġiba",
    "comment.text5": "totali",
    "comment.text6": "Ikteb Tweġiba",
    "comment.text7": "Tweġiba jinħbew",
    "comment.text8": "kummenti",
    "comment.text9": "Huwa l-ebda problema li jitħassar dan il-kumment u tweġibiet ta 'dan il-kumment kompletament?",
    "comment.text10": "Huwa l-ebda problema li jitħassar dan il-kumment kompletament?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Żid mal-Favoriti Post",
    "follow.text2": "Ħassar mill-kariga favoriti",
    "follow.text3": "Żid mal utent favoriti",
    "follow.text4": "Ħassar minn utent favoriti",
    "follow.text5": "postijiet juru",
    "follow.text6": "Utenti favoriti tiegħek",
    "follow.text7": "Utenti Post favoriti",
    "__11": "",
    "privacyPolicy.text1": "Regoli tal-privatezza",
    "privacyPolicy.text2": "Din il-politika tista 'tiġi aġġornata jew riveduti mingħajr avviż. Hija r-responsabbiltà tal-utent biex waqfa informati dwar bidliet fil-politika tal-privatezza. Ħu nota ta 'kwistjonijiet oħra ta' privatezza li jistgħu jaffettwaw inti:",
    "privacyPolicy.text3": "Dan il-websajt tista 'tiġi kompromessa.",
    "privacyPolicy.text4": "Kompjuter tiegħek jista 'jkunu kompromessi.",
    "privacyPolicy.text5": "Dan il-websajt huwa ospitat fuq infrastrutturi Firebase u DigitalOcean. Huma jistgħu u ma jkollhom sistemi ta 'insegwiment tagħhom stess fuq servers tagħhom. Dawk is-servizzi għandhom politiki tagħhom ta 'privatezza stess u mhumiex koperti minn din il-politika privatezza.",
    "privacyPolicy.text6": "Din l-email ħwienet websajt, f'forma kriptata ta password, u inputs oħra ta 'utenti fid Firebase u DigitalOcean infrastrutturi ospitat.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Termini ta 'Użu",
    "termsOfUse.text2": "privatezza",
    "termsOfUse.text3": "Privatezza tiegħek hija importanti għalina. Jekk jogħġbok aqra l-",
    "termsOfUse.text4": "regoli tal-privatezza",
    "termsOfUse.text5": "għal aktar informazzjoni.",
    "termsOfUse.text6": "responsabbiltajiet tiegħek",
    "termsOfUse.text7": "Inti, l-utent, huma unikament responsabbli biex jiżguraw konformità tiegħek stess mal-liġijiet u t-taxxi fil-ġurisdizzjoni tiegħek. Inti huma unikament responsabbli għas-sigurtà tiegħek.",
    "termsOfUse.text8": "Ċaħda ta 'garanzija",
    "termsOfUse.text9": "Dan is-sit hija pprovduta fuq bażi \"AS IS\" BAŻI, MINGĦAJR GARANZIJI JEW KUNDIZZJONIJIET TA 'KWALUNKWE TIP.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "bidu Connect",
    "videoTalk.text2": "stop Connect",
    "videoTalk.text3": "Utenti online",
    "videoTalk.text4": "Ibda biex jitkellmu",
    "videoTalk.text5": "Tiegħek qed jippruvaw jitkellmu",
    "videoTalk.text6": "teżisti l-ebda utent oħra online",
    "videoTalk.text7": "Tkun qed titkellem",
    "videoTalk.text8": "Bdejt biex jitkellmu",
    "videoTalk.text9": "Uri Talk Test",
    "videoTalk.text10": "input test ....",
    "videoTalk.text11": "Inti għadek titkellem. Do inti tixtieq li tieqaf titkellem?",
    "videoTalk.text12": "Sejħat utent skonnettjata",
    "videoTalk.text13": "utent qed titlob inti. Do you taċċetta din is-sejħa?",
    "videoTalk.text14": "tirrifjuta sejħa tiegħek",
    "videoTalk.text15": "tiftix utent onlajn ...",
    "videoTalk.text16": "utent tfittxija ...",
    "__14": "",
    "groupTalk.text1": "kreatur",
    "groupTalk.text2": "deskrizzjoni",
    "groupTalk.text3": "data ħolqien",
    "groupTalk.text4": "info grupp jeditjaw",
    "groupTalk.text5": "Huwa l-ebda problema li tħassar inti minn membri ta 'dan il-grupp?",
    "groupTalk.text6": "Ħalli mill-Istati",
    "groupTalk.text7": "Ingħaqad Grupp",
    "groupTalk.text8": "Uri membri Grupp",
    "groupTalk.text9": "Tixtieq li Ingħaqad Dan il-Grupp?",
    "groupTalk.text10": "grupp Isem",
    "groupTalk.text11": "Lura għall-Lista",
    "groupTalk.text12": "Ikkonferma l-input tiegħek",
    "groupTalk.text13": "Oħloq Grupp",
    "groupTalk.text14": "aġġornament Grupp",
    "groupTalk.text15": "Ħruġ mill-Grupp",
    "groupTalk.text16": "Mur fil Homepage lill Login",
    "groupTalk.text17": "isem tal-grupp (5-100 karattri)",
    "groupTalk.text18": "Deskrizzjoni grupp (inqas minn 500 karattru)"
  },
  {
    "LANGUAGE": "Norwegian (Bokm?l)",
    "CODE": "nb",
    "test.text1": "Hallo",
    "test.text2": "Hei",
    "test.text3": "Takk skal du ha",
    "test.text4": "kart",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Avbryt",
    "general.text2": "Aksepterer",
    "general.text3": "Slett",
    "general.text4": "Slettet",
    "general.text5": "Redigere",
    "general.text6": "Oppdater",
    "general.text7": "Last opp bilde",
    "general.text8": "Slett bilde",
    "general.text9": "Bilde",
    "general.text10": "Mate",
    "general.text11": "Logg Inn",
    "general.text12": "Melde deg på",
    "general.text13": "brukerinformasjon",
    "general.text14": "Logg ut",
    "general.text15": "filen skal være",
    "general.text16": "og mindre enn",
    "general.text17": "Passord",
    "general.text18": "favoritt innlegg",
    "general.text19": "innlegg",
    "general.text20": "Bilder",
    "general.text21": "videoer",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nytt innlegg",
    "feed.text2": "Vis Bruker innlegg",
    "feed.text3": "Vis innlegg",
    "feed.text4": "utsikt",
    "feed.text5": "redigere",
    "feed.text6": "slette",
    "feed.text7": "Er det ikke noe problem å slette innlegget helt?",
    "feed.text8": "Postet av",
    "feed.text9": "Laget av",
    "feed.text10": "Tittel",
    "feed.text11": "Bilde",
    "feed.text12": "Innhold",
    "feed.text13": "offentlig",
    "feed.text14": "privat",
    "feed.text15": "Tittel for post",
    "feed.text16": "Innhold por post",
    "feed.text17": "slette",
    "feed.text18": "Er det ikke noe problem å slette bildet helt?",
    "feed.text19": "Slettet",
    "feed.text20": "Media File bør være jpg, jpeg, png eller mp4-fil, og mindre enn 3 MB",
    "feed.text21": "offentlig post",
    "feed.text22": "privat innlegg",
    "feed.text23": "Skriv inn søkestatistikk ....",
    "feed.text24": "Søk Bruker ...",
    "__2": "",
    "userInfo.text1": "brukerinformasjon",
    "userInfo.text2": "Navn",
    "userInfo.text3": "bilde",
    "userInfo.text4": "opprettelsesdato",
    "userInfo.text5": "Er det ikke noe problem å slette bildet helt?",
    "userInfo.text6": "Nytt navn ...",
    "userInfo.text7": "Bildefilen må være jpg, jpeg eller png fil, og mindre enn 1 MB",
    "userInfo.text8": "mørk Mode",
    "userInfo.text9": "om bruker",
    "userInfo.text10": "ytterligere informasjon om deg, beskrivelse bio etc ... (mindre enn 300 tegn)",
    "userInfo.text11": "Språk",
    "userInfo.text12": "Endring",
    "userInfo.text13": "Melding",
    "__3": "",
    "auth.text1": "Din epost",
    "auth.text2": "Navnet ditt",
    "auth.text3": "Passord",
    "auth.text4": "Passord bør være 8 til 20 tegn, minst en stor bokstav, en liten bokstav, ett tall og ett spesialtegn (@ $!% *? &).",
    "auth.text5": "Melde deg på",
    "auth.text6": "Logg Inn",
    "auth.text7": "Glem passord",
    "auth.text8": "Påmelding Side",
    "auth.text9": "E-postadressen for tilbakestilling av passord",
    "auth.text10": "Send e-post",
    "auth.text11": "Godkjenning mislyktes. Pass på at e-postadressen ikke er i bruk ennå!",
    "auth.text12": "Brukerpålogging mislyktes!",
    "auth.text13": "Ugyldig passord ble angitt",
    "auth.text14": "Angitt e-postadresse ble ikke funnet",
    "auth.text15": "Bruker skapelsen mislyktes!",
    "auth.text16": "E-postadressen er allerede i bruk av en annen konto",
    "auth.text17": "Vis angitte passordet",
    "auth.text18": "Skjul passord",
    "auth.text19": "e-post for passord resten ble sendt",
    "auth.text20": "Enterd e-post ble ikke funnet",
    "auth.text21": "reset passord suksess",
    "auth.text22": "Tilbakestilling av passord mislyktes, vennligst gå til innloggingssiden og sende e-post for å tilbakestille passord på nytt",
    "auth.text23": "Tilbakestilling av passord side",
    "auth.text24": "Nytt passord",
    "auth.text25": "Tilbakestille passord",
    "auth.text26": "Page er ugyldig eller passord er allerede endret",
    "auth.text27": "Sende e-post for å tilbakestille passord mislyktes, vennligst sjekk e-postadresse",
    "auth.text28": "E-postbekreftelse side",
    "auth.text29": "E-postbekreftelse suksess, kan du gå til påloggingssiden vist ovenfor",
    "auth.text30": "E-postbekreftelse mislyktes. Denne siden er utløpt, eller verifisering er allerede ferdig.",
    "auth.text31": "Verification Mail ble sendt. Plese sjekke e-posten og bekrefte. (Også chack i spam mail)",
    "auth.text32": "E-postbekreftelse er nødvendig for å Login. Vennligst send e-post for bekreftelse til din e-post adresse.",
    "auth.text33": "Send e-post for verifisering",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Mate",
    "nav.text2": "Logg Inn",
    "nav.text3": "Melde deg på",
    "nav.text4": "brukerinformasjon",
    "nav.text5": "Logg ut",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Side ikke funnet",
    "notFound.text2": "gå til fôr siden",
    "notFound.text3": "gå til påloggingssiden",
    "notFound.text4": "Hvor du går i dag du?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Avbryt",
    "comment.text2": "Slett",
    "comment.text3": "post Kommentar",
    "comment.text4": "Vis Svar",
    "comment.text5": "Total",
    "comment.text6": "Skriv svar",
    "comment.text7": "Skjul Svar",
    "comment.text8": "kommentarer",
    "comment.text9": "Er det ikke noe problem å slette denne kommentaren og svar på denne kommentaren helt?",
    "comment.text10": "Er det ikke noe problem å slette denne kommentaren helt?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Legg til Favoritt Post",
    "follow.text2": "Slett fra favoritt post",
    "follow.text3": "Legg til favorittbruker",
    "follow.text4": "Slett fra favorittbruker",
    "follow.text5": "Vis innlegg",
    "follow.text6": "Dine favoritt Brukere",
    "follow.text7": "Innlegg Favoritt Brukere",
    "__11": "",
    "privacyPolicy.text1": "personvern",
    "privacyPolicy.text2": "Denne politikken kan bli oppdatert eller revidert uten varsel. Det er brukerens ansvar å holde deg oppdatert om personvern politiske endringer. Ta note av andre personlige problemer som kan påvirke deg:",
    "privacyPolicy.text3": "Dette nettstedet kan være kompromittert.",
    "privacyPolicy.text4": "Datamaskinen kan være kompromittert.",
    "privacyPolicy.text5": "Dette nettstedet er vert på Fire og DigitalOcean infrastrukturer. De kan og har sine egne sporingssystemer på sine servere. Disse tjenestene har sine egne retningslinjer, og de er ikke omfattet av disse retningslinjene for personvern.",
    "privacyPolicy.text6": "Dette nettsted lagrer e-post, kryptert form av passord, og andre innganger til brukerne i vert Fire og DigitalOcean infrastrukturer.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Vilkår for bruk",
    "termsOfUse.text2": "Personvern",
    "termsOfUse.text3": "Ditt personvern er viktig for oss. Vennligst les",
    "termsOfUse.text4": "retningslinjer for personvern",
    "termsOfUse.text5": "for mer informasjon.",
    "termsOfUse.text6": "Ditt eget ansvar",
    "termsOfUse.text7": "Du, brukeren, er selv ansvarlig for å sikre din egen samsvar med lover og skatter i din jurisdiksjon. Du er selv ansvarlig for din egen sikkerhet.",
    "termsOfUse.text8": "Ansvarsfraskrivelse",
    "termsOfUse.text9": "Dette nettstedet er gitt på en \"SOM DEN ER\", UTEN GARANTIER AV NOE SLAG.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "start Connect",
    "videoTalk.text2": "stopp Connect",
    "videoTalk.text3": "online brukere",
    "videoTalk.text4": "Begynner å snakke",
    "videoTalk.text5": "Din prøver å snakke",
    "videoTalk.text6": "Ingen andre online brukeren finnes",
    "videoTalk.text7": "Du snakker med",
    "videoTalk.text8": "Du begynte å snakke",
    "videoTalk.text9": "Vis tekst Diskusjon",
    "videoTalk.text10": "skriving ....",
    "videoTalk.text11": "Du er fremdeles snakker. Ønsker du å slutte å snakke?",
    "videoTalk.text12": "Ringe bruker frakoblet",
    "videoTalk.text13": "brukeren ringer deg. Godtar du dette kallet?",
    "videoTalk.text14": "avvise samtalen",
    "videoTalk.text15": "søk på nettet bruker ...",
    "videoTalk.text16": "søke brukeren ...",
    "__14": "",
    "groupTalk.text1": "skaperen",
    "groupTalk.text2": "Beskrivelse",
    "groupTalk.text3": "opprettelsesdato",
    "groupTalk.text4": "Rediger gruppe info",
    "groupTalk.text5": "Er det ikke noe problem å slette deg fra medlemmer av denne gruppen?",
    "groupTalk.text6": "Permisjon fra medlem",
    "groupTalk.text7": "Bli med i gruppe",
    "groupTalk.text8": "Vis gruppemedlemmer",
    "groupTalk.text9": "Ønsker du å bli med i denne gruppen?",
    "groupTalk.text10": "Gruppenavn",
    "groupTalk.text11": "Tilbake til listen",
    "groupTalk.text12": "Bekreft inntastingen",
    "groupTalk.text13": "Lag gruppe",
    "groupTalk.text14": "Oppdater gruppe",
    "groupTalk.text15": "Exit fra gruppe",
    "groupTalk.text16": "Gå til hjemmeside for å logge inn",
    "groupTalk.text17": "Gruppe (5-100 tegn)",
    "groupTalk.text18": "gruppe beskrivelse (mindre enn 500 tegn)"
  },
  {
    "LANGUAGE": "Dutch",
    "CODE": "nl",
    "test.text1": "Hallo",
    "test.text2": "Hoi",
    "test.text3": "Dank je",
    "test.text4": "kaart",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "annuleren",
    "general.text2": "Aanvaarden",
    "general.text3": "Delete",
    "general.text4": "Verwijderde",
    "general.text5": "Bewerk",
    "general.text6": "Bijwerken",
    "general.text7": "Afbeelding uploaden",
    "general.text8": "Verwijder afbeelding",
    "general.text9": "Beeld",
    "general.text10": "Voeden",
    "general.text11": "Log in",
    "general.text12": "Aanmelden",
    "general.text13": "gebruikers informatie",
    "general.text14": "Uitloggen",
    "general.text15": "bestand zou moeten zijn",
    "general.text16": "en minder dan",
    "general.text17": "Wachtwoord",
    "general.text18": "favoriete berichten",
    "general.text19": "berichten",
    "general.text20": "Afbeeldingen",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nieuw bericht",
    "feed.text2": "Toon Gebruiker Berichten",
    "feed.text3": "Toon bijdragen",
    "feed.text4": "visie",
    "feed.text5": "Bewerk",
    "feed.text6": "verwijderen",
    "feed.text7": "Is het geen probleem om delete bericht helemaal?",
    "feed.text8": "Gepost door",
    "feed.text9": "Gemaakt door",
    "feed.text10": "Titel",
    "feed.text11": "Beeld",
    "feed.text12": "Inhoud",
    "feed.text13": "openbaar",
    "feed.text14": "privaat",
    "feed.text15": "Titel voor post",
    "feed.text16": "Content por bericht",
    "feed.text17": "verwijderen",
    "feed.text18": "Is het geen probleem delete image volledig aan?",
    "feed.text19": "Verwijderde",
    "feed.text20": "Media File moet jpg, jpeg, png of mp4-bestand zijn, en minder dan 3MB",
    "feed.text21": "openbaar bericht",
    "feed.text22": "prive bericht",
    "feed.text23": "Typ voor Search ....",
    "feed.text24": "Zoek gebruiker ...",
    "__2": "",
    "userInfo.text1": "gebruikers informatie",
    "userInfo.text2": "naam",
    "userInfo.text3": "beeld",
    "userInfo.text4": "Aanmaakdatum",
    "userInfo.text5": "Is het geen probleem delete image volledig aan?",
    "userInfo.text6": "Nieuwe naam ...",
    "userInfo.text7": "Afbeelding bestand moet jpg, jpeg, of png-bestand zijn, en minder dan 1 MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "over Gebruiker",
    "userInfo.text10": "Aanvullende informatie over u, beschrijving bio etc ... (minder dan 300 tekens)",
    "userInfo.text11": "Taal",
    "userInfo.text12": "Verandering",
    "userInfo.text13": "Kennisgeving",
    "__3": "",
    "auth.text1": "Jouw email",
    "auth.text2": "Uw naam",
    "auth.text3": "Wachtwoord",
    "auth.text4": "Wachtwoord moet tussen de 8 en 20 tekens, ten minste één hoofdletter, één kleine letter, één cijfer en één speciaal teken (@ $!% *? &).",
    "auth.text5": "Aanmelden",
    "auth.text6": "Log in",
    "auth.text7": "Wachtwoord vergeten",
    "auth.text8": "Inschrijven Pagina",
    "auth.text9": "E-mailadres voor password reset",
    "auth.text10": "stuur dan een e-mail",
    "auth.text11": "Validatie mislukt. Zorg ervoor dat het e-mailadres is nog niet gebruikt!",
    "auth.text12": "Gebruiker login mislukt!",
    "auth.text13": "Ongeldig wachtwoord ingevoerd",
    "auth.text14": "Ingevoerde e-mailadres is niet gevonden",
    "auth.text15": "Gebruiker is mislukt!",
    "auth.text16": "Het e-mailadres wordt al gebruikt door een ander account",
    "auth.text17": "Toon ingevoerde wachtwoord",
    "auth.text18": "Verberg wachtwoord",
    "auth.text19": "e-mail voor wachtwoord rust werd verzonden",
    "auth.text20": "Enterd e-mail is niet gevonden",
    "auth.text21": "Password reset succes",
    "auth.text22": "Password reset mislukt, Ga naar Inloggen pagina en stuur e-mail voor password reset weer",
    "auth.text23": "Password reset pagina",
    "auth.text24": "nieuw paswoord",
    "auth.text25": "Reset Password",
    "auth.text26": "Pagina is ongeldig of wachtwoord is al veranderd",
    "auth.text27": "Het verzenden van e-mail voor wachtwoord te resetten is mislukt, controleert e-mailadres",
    "auth.text28": "E-mail verificatiepagina",
    "auth.text29": "E-mail verificatie succes, ga dan naar login pagina hierboven getoonde",
    "auth.text30": "E-mail is mislukt. Deze pagina is verlopen, of verificatie is al klaar.",
    "auth.text31": "Verificatie mail is verzonden. Plese controleer uw e-mail en te controleren. (Ook chack in spam-mail)",
    "auth.text32": "E-mail Verificatie is vereist om in te loggen. Stuur e-mail ter verificatie naar uw e-mailadres.",
    "auth.text33": "E-mail verzenden voor verificatie",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Voeden",
    "nav.text2": "Log in",
    "nav.text3": "Aanmelden",
    "nav.text4": "gebruikers informatie",
    "nav.text5": "Uitloggen",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Pagina niet gevonden",
    "notFound.text2": "ga naar feedpagina",
    "notFound.text3": "ga naar login pagina",
    "notFound.text4": "Waar ga je vandaag heen?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "annuleren",
    "comment.text2": "Delete",
    "comment.text3": "Plaats een reactie",
    "comment.text4": "Show Reply",
    "comment.text5": "totaal",
    "comment.text6": "Schrijf een reactie",
    "comment.text7": "verbergen Beantwoorden",
    "comment.text8": "Comments",
    "comment.text9": "Is het geen probleem om deze reactie en de antwoorden van deze opmerking volledig verwijderen?",
    "comment.text10": "Is het geen probleem om de reactie volledig te verwijderen?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Voeg toe aan favoriete bericht",
    "follow.text2": "Verwijderen uit favoriete bericht",
    "follow.text3": "Voeg toe aan favoriete gebruiker",
    "follow.text4": "Verwijderen uit favoriete gebruiker",
    "follow.text5": "Toon posten",
    "follow.text6": "Your Favorite Gebruikers",
    "follow.text7": "Favourite Gebruikers",
    "__11": "",
    "privacyPolicy.text1": "Privacybeleid",
    "privacyPolicy.text2": "Dit beleid kan worden geactualiseerd of herzien zonder voorafgaande kennisgeving. Het is de verantwoordelijkheid van de gebruiker om de hoogte blijven van veranderingen privacybeleid. Kennis te nemen van andere privacy problemen die kunnen beïnvloeden:",
    "privacyPolicy.text3": "Deze website kan worden aangetast.",
    "privacyPolicy.text4": "Uw computer kan worden aangetast.",
    "privacyPolicy.text5": "Deze website wordt gehost op Firebase en DigitalOcean infrastructuren. Zij kunnen en hebben hun eigen tracking systemen op hun servers. Deze diensten hebben hun eigen privacybeleid en ze vallen niet onder dit privacybeleid.",
    "privacyPolicy.text6": "Deze website slaat e-mail, gecodeerde vorm van het wachtwoord, en andere ingangen van de gebruikers in hosted Firebase en DigitalOcean infrastructuren.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Gebruiksvoorwaarden",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Uw privacy is belangrijk voor ons. Lees de",
    "termsOfUse.text4": "privacybeleid",
    "termsOfUse.text5": "voor meer informatie.",
    "termsOfUse.text6": "Uw eigen verantwoordelijkheid",
    "termsOfUse.text7": "U, de gebruiker, bent zelf verantwoordelijk voor je eigen naleving van wetten en belastingen in uw rechtsgebied. U bent zelf verantwoordelijk voor je eigen veiligheid.",
    "termsOfUse.text8": "Garantiedisclaimer",
    "termsOfUse.text9": "Deze site wordt geleverd op een \"AS IS\", ZONDER GARANTIES OF VOORWAARDEN VAN WELKE AARD.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start verbinding",
    "videoTalk.text2": "stop Connect",
    "videoTalk.text3": "Online gebruikers",
    "videoTalk.text4": "Start om te praten",
    "videoTalk.text5": "Je probeert te praten",
    "videoTalk.text6": "Geen enkele andere online gebruiker bestaat",
    "videoTalk.text7": "Je praat",
    "videoTalk.text8": "Je begon te praten",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "tekst invoer ....",
    "videoTalk.text11": "Je bent nog steeds in gesprek. Wilt u stoppen met praten?",
    "videoTalk.text12": "Oproepende gebruiker losgekoppeld",
    "videoTalk.text13": "gebruiker u belt. Heeft u deze oproep te accepteren?",
    "videoTalk.text14": "uw oproep te weigeren",
    "videoTalk.text15": "online zoeken gebruiker ...",
    "videoTalk.text16": "Zoek gebruiker ...",
    "__14": "",
    "groupTalk.text1": "Schepper",
    "groupTalk.text2": "Omschrijving",
    "groupTalk.text3": "Aanmaakdatum",
    "groupTalk.text4": "Groep bewerken info",
    "groupTalk.text5": "Is het geen probleem om u verwijdert uit leden van deze groep?",
    "groupTalk.text6": "Vertrekken vanaf Lid",
    "groupTalk.text7": "Deelnemen aan groep",
    "groupTalk.text8": "Toon Groepsleden",
    "groupTalk.text9": "Wilt u Deelnemen aan deze groep?",
    "groupTalk.text10": "Groepsnaam",
    "groupTalk.text11": "Terug naar de lijst",
    "groupTalk.text12": "Bevestig de invoer",
    "groupTalk.text13": "Groep maken",
    "groupTalk.text14": "Groep bijwerken",
    "groupTalk.text15": "Exit uit groep",
    "groupTalk.text16": "Ga naar de homepage om in te loggen",
    "groupTalk.text17": "groepsnaam (5-100 tekens)",
    "groupTalk.text18": "groepsbeschrijving (minder dan 500 tekens)"
  },
  {
    "LANGUAGE": "Northern Sotho",
    "CODE": "ns",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Punjabi",
    "CODE": "pa",
    "test.text1": "ਸਤ ਸ੍ਰੀ ਅਕਾਲ",
    "test.text2": "ਅਧਿਕਤਮ",
    "test.text3": "ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    "test.text4": "ਨਕਸ਼ਾ",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "ਰੱਦ ਕਰੋ",
    "general.text2": "ਸਵੀਕਾਰ ਕਰੋ",
    "general.text3": "ਹਟਾਓ",
    "general.text4": "ਹਟਾਇਆ",
    "general.text5": "ਸੋਧ",
    "general.text6": "ਅੱਪਡੇਟ",
    "general.text7": "ਅੱਪਲੋਡ ਚਿੱਤਰ ਹੈ",
    "general.text8": "ਹਟਾਓ ਚਿੱਤਰ",
    "general.text9": "ਚਿੱਤਰ",
    "general.text10": "ਫੀਡ",
    "general.text11": "ਲਾਗਿਨ",
    "general.text12": "ਸਾਇਨ ਅਪ",
    "general.text13": "ਯੂਜ਼ਰ ਜਾਣਕਾਰੀ",
    "general.text14": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
    "general.text15": "ਫਾਇਲ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    "general.text16": "ਅਤੇ ਵੱਧ ਘੱਟ",
    "general.text17": "ਪਾਸਵਰਡ",
    "general.text18": "ਪਸੰਦੀਦਾ ਪੋਸਟ",
    "general.text19": "ਪੋਸਟ",
    "general.text20": "ਚਿੱਤਰ",
    "general.text21": "ਵੀਡੀਓ",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "ਨਿਊ ਪੋਸਟ",
    "feed.text2": "ਦਿਖਾਓ ਯੂਜ਼ਰ ਪੋਸਟ",
    "feed.text3": "ਵੇਖੋ ਪੋਸਟ",
    "feed.text4": "ਝਲਕ",
    "feed.text5": "ਸੰਪਾਦਨ",
    "feed.text6": "ਨੂੰ ਹਟਾਉਣ",
    "feed.text7": "ਹਟਾਓ ਪੋਸਟ ਪੂਰੀ ਕਰਨ ਲਈ ਕੋਈ ਸਮੱਸਿਆ ਹੈ ਹੈ?",
    "feed.text8": "ਦੁਆਰਾ ਪੋਸਟ ਕੀਤਾ",
    "feed.text9": "ਕੇ ਬਣਾਇਆ",
    "feed.text10": "ਟਾਈਟਲ",
    "feed.text11": "ਚਿੱਤਰ",
    "feed.text12": "ਸਮੱਗਰੀ",
    "feed.text13": "ਜਨਤਕ",
    "feed.text14": "ਪ੍ਰਾਈਵੇਟ",
    "feed.text15": "ਅਹੁਦੇ ਲਈ ਟਾਈਟਲ",
    "feed.text16": "ਸਮੱਗਰੀ por ਪੋਸਟ",
    "feed.text17": "ਨੂੰ ਹਟਾਉਣ",
    "feed.text18": "ਇਸ ਨੂੰ ਪੂਰੀ ਹਟਾਓ ਚਿੱਤਰ ਨੂੰ ਕਰਨ ਲਈ ਕੋਈ ਵੀ ਸਮੱਸਿਆ ਹੈ?",
    "feed.text19": "ਹਟਾਇਆ",
    "feed.text20": "ਮੀਡੀਆ ਫਾਇਲ jpg, JPEG, PNG ਜ MP4 ਫਾਇਲ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ, ਅਤੇ 3MB ਵੱਧ ਘੱਟ",
    "feed.text21": "ਜਨਤਕ ਪੋਸਟ",
    "feed.text22": "ਨਿੱਜੀ ਪੋਸਟ",
    "feed.text23": "ਲਈ ਖੋਜ ਟਾਈਪ ਕਰੋ ....",
    "feed.text24": "ਖੋਜ ਦੇ ...",
    "__2": "",
    "userInfo.text1": "ਯੂਜ਼ਰ ਜਾਣਕਾਰੀ",
    "userInfo.text2": "ਨਾਮ",
    "userInfo.text3": "ਚਿੱਤਰ ਨੂੰ",
    "userInfo.text4": "ਬਣਾਉਣ ਮਿਤੀ",
    "userInfo.text5": "ਇਸ ਨੂੰ ਪੂਰੀ ਹਟਾਓ ਚਿੱਤਰ ਨੂੰ ਕਰਨ ਲਈ ਕੋਈ ਵੀ ਸਮੱਸਿਆ ਹੈ?",
    "userInfo.text6": "ਨਵ ਨਾਮ ...",
    "userInfo.text7": "ਚਿੱਤਰ ਫਾਇਲ jpg, jpeg, ਜ ਫਾਇਲ ਨੂੰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ, ਅਤੇ 1MB ਘੱਟ",
    "userInfo.text8": "ਹਨੇਰੇ ਮੋਡ",
    "userInfo.text9": "ਬਾਰੇ ਯੂਜ਼ਰ",
    "userInfo.text10": "ਤੁਹਾਡੇ ਬਾਰੇ ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ, ਵੇਰਵਾ ਦੇ ਬਾਇਓ ਆਦਿ ... (ਘੱਟ ਵੱਧ 300 ਅੱਖਰ)",
    "userInfo.text11": "ਭਾਸ਼ਾ",
    "userInfo.text12": "ਬਦਲੋ",
    "userInfo.text13": "ਸੂਚਨਾ",
    "__3": "",
    "auth.text1": "ਤੁਹਾਡਾ ਈਮੇਲ",
    "auth.text2": "ਤੁਹਾਡਾ ਨਾਮ",
    "auth.text3": "ਪਾਸਵਰਡ",
    "auth.text4": "ਪਾਸਵਰਡ 8 20 ਅੱਖਰ, ਘੱਟੋ-ਘੱਟ ਇੱਕ ਵੱਡੇ ਪੱਤਰ, ਇੱਕ ਛੋਟੇ ਪੱਤਰ, ਇਕ ਨੰਬਰ ਅਤੇ ਇੱਕ ਖਾਸ ਅੱਖਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ (@ $!% *? &).",
    "auth.text5": "ਸਾਇਨ ਅਪ",
    "auth.text6": "ਲਾਗਿਨ",
    "auth.text7": "ਪਾਸਵਰਡ ਭੁੱਲ",
    "auth.text8": "ਸਾਇਨਅਪ ਸਫ਼ਾ",
    "auth.text9": "ਈ-ਮੇਲ ਪਾਸਵਰਡ ਰੀਸੈਟ ਲਈ ਦਾ ਪਤਾ",
    "auth.text10": "ਭੇਜਣ ਈ-ਮੇਲ",
    "auth.text11": "ਪ੍ਰਮਾਣਿਕਤਾ ਅਸਫਲ. ਇਹ ਯਕੀਨੀ ਬਣਾਓ ਕਿ ਈ-ਮੇਲ ਪਤਾ ਅਜੇ ਤੱਕ ਵਰਤਿਆ ਨਹੀ ਹੈ!",
    "auth.text12": "ਯੂਜ਼ਰ ਲਾਗਇਨ ਫੇਲ੍ਹ!",
    "auth.text13": "ਗਲਤ ਪਾਸਵਰਡ ਦਿੱਤਾ ਗਿਆ ਹੈ",
    "auth.text14": "ਦਰਜ ਈਮੇਲ ਪਤਾ ਨਾ ਪਾਇਆ ਗਿਆ ਸੀ",
    "auth.text15": "ਯੂਜ਼ਰ ਬਣਾਉਣ ਵਿੱਚ ਅਸਫਲ!",
    "auth.text16": "ਈ-ਮੇਲ ਪਤਾ ਦੂਸਰੇ ਖਾਤੇ ਦੇ ਵਰਤਣ ਵਿੱਚ ਹੀ ਹੈ,",
    "auth.text17": "ਦਿਖਾਓ ਦਿੱਤੇ ਪਾਸਵਰਡ",
    "auth.text18": "ਓਹਲੇ ਪਾਸਵਰਡ",
    "auth.text19": "ਪਾਸਵਰਡ ਬਾਕੀ ਦੇ ਲਈ ਈ-ਮੇਲ ਭੇਜਿਆ ਗਿਆ ਸੀ",
    "auth.text20": "Enterd ਈ-ਮੇਲ ਮਿਲਿਆ ਹੈ, ਨਾ ਗਿਆ ਸੀ,",
    "auth.text21": "ਪਾਸਵਰਡ ਰੀਸੈਟ ਸਫਲਤਾ",
    "auth.text22": "ਪਾਸਵਰਡ ਰੀਸੈਟ ਅਸਫਲ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਪਾਸਵਰਡ ਰੀਸੈਟ ਲਈ ਲਾਗਇਨ ਸਫ਼ੇ ਅਤੇ ਭੇਜੋ ਈ-ਮੇਲ ਕਰਨ ਲਈ ਜਾਣ",
    "auth.text23": "ਪਾਸਵਰਡ ਰੀਸੈਟ ਸਫ਼ਾ",
    "auth.text24": "ਨਵਾਂ ਪਾਸਵਰਡ",
    "auth.text25": "ਮੁੜ ਪਾਸਵਰਡ",
    "auth.text26": "ਪੰਨਾ ਗਲਤ ਹੈ ਜ ਪਾਸਵਰਡ ਹੀ ਬਦਲ ਗਿਆ ਹੈ",
    "auth.text27": "ਪਾਸਵਰਡ ਰੀਸੈਟ ਲਈ ਈਮੇਲ ਭੇਜਣ ਵਿੱਚ ਅਸਫਲ,, ਕਿਰਪਾ ਕਰਕੇ ਚੈਕ ਈਮੇਲ ਪਤਾ",
    "auth.text28": "ਮਿੱਤਰ ਨੂੰ ਈ ਮੇਲ ਤਸਦੀਕ ਸਫ਼ਾ",
    "auth.text29": "ਸਫਲਤਾ ਤਸਦੀਕ ਈਮੇਲ, ਕਿਰਪਾ ਕਰਕੇ ਉੱਪਰ ਦਿੱਤੀ ਲਾਗਇਨ ਸਫ਼ੇ ਲਈ ਜਾ",
    "auth.text30": "ਮਿੱਤਰ ਨੂੰ ਈ ਮੇਲ ਤਸਦੀਕ ਅਸਫਲ ਹੈ. ਇਹ ਸਫ਼ਾ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ, ਜ ਤਸਦੀਕ ਹੀ ਪੂਰਾ ਹੋ ਗਿਆ ਹੈ.",
    "auth.text31": "ਤਸਦੀਕ ਮੇਲ ਭੇਜਿਆ ਗਿਆ ਸੀ. Plese ਆਪਣੇ ਈਮੇਲ ਚੈੱਕ ਕਰੋ ਅਤੇ ਦੀ ਪੁਸ਼ਟੀ. (ਇਹ ਵੀ ਸਪੈਮ ਮੇਲ ਵਿੱਚ ਚੱਕ)",
    "auth.text32": "ਈਮੇਲ ਪੁਸ਼ਟੀਕਰਣ ਲਾਗਇਨ ਕਰਨ ਦੀ ਲੋੜ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਈ-ਮੇਲ ਪਤੇ ਨੂੰ ਤਸਦੀਕ ਕਰਨ ਲਈ ਈ-ਮੇਲ ਭੇਜੋ.",
    "auth.text33": "ਤਸਦੀਕ ਲਈ ਈਮੇਲ ਭੇਜੋ",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "ਫੀਡ",
    "nav.text2": "ਲਾਗਿਨ",
    "nav.text3": "ਸਾਇਨ ਅਪ",
    "nav.text4": "ਯੂਜ਼ਰ ਜਾਣਕਾਰੀ",
    "nav.text5": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "ਪੰਨਾ ਮਿਲਿਆ ਨਹੀਂ",
    "notFound.text2": "ਫੀਡ ਸਫ਼ੇ ਲਈ ਜਾ",
    "notFound.text3": "ਲਾਗਇਨ ਸਫ਼ੇ ਲਈ ਜਾ",
    "notFound.text4": "ਅੱਜ ਤੁਹਾਨੂੰ ਕਿੱਥੇ ਜਾਣ ਹੈ?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "ਰੱਦ ਕਰੋ",
    "comment.text2": "ਹਟਾਓ",
    "comment.text3": "ਪੋਸਟ ਟਿੱਪਣੀ",
    "comment.text4": "ਵੇਖੋ ਜਵਾਬ",
    "comment.text5": "ਕੁੱਲ",
    "comment.text6": "ਜਵਾਬ ਲਿਖੋ",
    "comment.text7": "ਜਵਾਬ ਲੁਕਾਓ",
    "comment.text8": "ਟਿੱਪਣੀ",
    "comment.text9": "ਇਸ ਨੂੰ ਇਸ ਟਿੱਪਣੀ ਹੈ ਅਤੇ ਇਸ ਟਿੱਪਣੀ ਨੂੰ ਪੂਰੀ ਦੀ ਜਵਾਬ ਨੂੰ ਹਟਾਉਣ ਲਈ ਕੋਈ ਵੀ ਸਮੱਸਿਆ ਹੈ?",
    "comment.text10": "ਇਸ ਨੂੰ ਪੂਰੀ ਇਸ ਟਿੱਪਣੀ ਨੂੰ ਹਟਾਉਣ ਲਈ ਕੋਈ ਵੀ ਸਮੱਸਿਆ ਹੈ?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "ਪਸੰਦੀਦਾ ਪੋਸਟ ਵਿੱਚ ਜੋੜੋ",
    "follow.text2": "ਪਸੰਦੀਦਾ ਪੋਸਟ ਤੱਕ ਹਟਾਓ",
    "follow.text3": "ਮਨਪਸੰਦ ਉਪਭੋਗੀ ਨੂੰ ਜੋੜੋ",
    "follow.text4": "ਪਸੰਦੀਦਾ ਯੂਜ਼ਰ ਹਟਾਓ",
    "follow.text5": "ਪ੍ਰਦਰਸ਼ਨ ਪੋਸਟ",
    "follow.text6": "ਤੁਹਾਡਾ ਪਸੰਦੀਦਾ ਉਪਭੋਗੀ",
    "follow.text7": "ਪੋਸਟ ਪਸੰਦੀਦਾ ਉਪਭੋਗੀ",
    "__11": "",
    "privacyPolicy.text1": "ਪਰਾਈਵੇਟ ਨੀਤੀ",
    "privacyPolicy.text2": "ਇਹ ਨੀਤੀ ਨੂੰ ਅੱਪਡੇਟ ਜ ਬਿਨਾ ਨੋਟਿਸ ਸੋਧੇ ਜਾ ਸਕਦਾ ਹੈ. ਇਹ ਗੁਪਤ ਨੀਤੀ ਵਿੱਚ ਤਬਦੀਲੀ ਬਾਰੇ ਸੂਚਿਤ ਰਹਿਣ ਲਈ ਉਪਭੋਗੀ ਦਾ ਜ਼ਿੰਮੇਵਾਰੀ ਹੈ. ਹੋਰ ਗੋਪਨੀਯ ਮੁੱਦੇ, ਜੋ ਕਿ ਤੁਹਾਨੂੰ ਅਸਰ ਪੈ ਸਕਦਾ ਹੈ ਦੇ ਨੋਟ ਲਵੋ:",
    "privacyPolicy.text3": "ਇਹ ਵੈਬਸਾਈਟ ਸਮਝੌਤਾ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ.",
    "privacyPolicy.text4": "ਤੁਹਾਡਾ ਕੰਪਿਊਟਰ ਸਮਝੌਤਾ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ.",
    "privacyPolicy.text5": "ਇਹ ਵੈਬਸਾਈਟ Firebase ਅਤੇ DigitalOcean ਆਧਾਰਭੂਤ 'ਤੇ ਮੇਜ਼ਬਾਨੀ ਕਰ ਰਿਹਾ ਹੈ. ਉਹ ਹੋ ਸਕਦਾ ਹੈ ਅਤੇ ਆਪਣੇ ਸਰਵਰ 'ਤੇ ਆਪਣੇ ਹੀ ਟਰੈਕਿੰਗ ਸਿਸਟਮ ਹੈ. ਉਹ ਸੇਵਾ ਆਪਣੇ ਗੋਪਨੀਯ ਪਾਲਸੀ ਹੈ ਅਤੇ ਉਹ ਇਸ ਨੂੰ ਗੁਪਤ ਨੀਤੀ ਦੁਆਰਾ ਕਵਰ ਨਾ ਰਹੇ ਹਨ.",
    "privacyPolicy.text6": "ਇਹ ਵੈਬਸਾਈਟ ਸਟੋਰ ਈਮੇਲ, ਪਾਸਵਰਡ ਦੀ ਇਨਕਰਿਪਟਡ ਫਾਰਮ, ਅਤੇ ਮੇਜ਼ਬਾਨੀ Firebase ਅਤੇ DigitalOcean ਆਧਾਰਭੂਤ 'ਤੇ ਉਪਭੋਗੀ ਨੂੰ ਦੇ ਹੋਰ ਖੇਤੀ.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ",
    "termsOfUse.text2": "ਪ੍ਰਾਈਵੇਸੀ",
    "termsOfUse.text3": "ਤੁਹਾਡੀ ਗੁਪਤਤਾ ਸਾਡੇ ਲਈ ਮਹੱਤਵਪੂਰਨ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਪੜ੍ਹਨ ਦੀ",
    "termsOfUse.text4": "ਪਰਾਈਵੇਟ ਨੀਤੀ",
    "termsOfUse.text5": "ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ.",
    "termsOfUse.text6": "ਤੁਹਾਡੀ ਆਪਣੀ ਜ਼ਿੰਮੇਵਾਰੀ",
    "termsOfUse.text7": "ਤੁਹਾਨੂੰ ਯੂਜ਼ਰ ਨੂੰ, ਆਪਣੇ ਅਧਿਕਾਰ ਖੇਤਰ ਵਿਚ ਕਾਨੂੰਨ ਅਤੇ ਟੈਕਸ ਦੇ ਨਾਲ ਆਪਣੇ ਹੀ ਦੀ ਪਾਲਣਾ ਨੂੰ ਯਕੀਨੀ ਬਣਾਉਣ ਲਈ ਇਕੱਲੇ ਹੀ ਜ਼ਿੰਮੇਵਾਰ ਹੁੰਦੇ ਹਨ. ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ ਆਪਣੇ ਸੁਰੱਖਿਆ ਲਈ ਇਕੱਲੇ ਹੀ ਜ਼ਿੰਮੇਵਾਰ ਹੁੰਦੇ ਹਨ.",
    "termsOfUse.text8": "ਵਾਰੰਟੀ ਦੇ ਬੇਦਾਅਵਾ",
    "termsOfUse.text9": "ਇਹ ਸਾਈਟ ਇੱਕ 'ਤੇ ਦਿੱਤਾ ਗਿਆ ਹੈ, ਦੇ ਅਧਾਰ \"ਦੇ ਤੌਰ ਤੇ ਹੈ\", ਵਾਰੰਟੀ ਜ ਕਿਸੇ ਕਿਸਮ ਦੀ ਸ਼ਰਤ ਦੇ ਬਿਨਾ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "ਸ਼ੁਰੂ ਕੁਨੈਕਟ",
    "videoTalk.text2": "ਰੋਕੋ ਕੁਨੈਕਟ",
    "videoTalk.text3": "ਆਨਲਾਈਨ ਉਪਭੋਗੀ",
    "videoTalk.text4": "ਗੱਲ ਕਰਨ ਦੀ ਸ਼ੁਰੂ",
    "videoTalk.text5": "ਤੁਹਾਡਾ ਬਾਤ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰ ਰਹੇ ਹਨ,",
    "videoTalk.text6": "ਕੋਈ ਹੋਰ ਆਨਲਾਈਨ ਯੂਜ਼ਰ ਨੂੰ ਹੀ ਮੌਜੂਦ ਹੈ",
    "videoTalk.text7": "ਤੁਹਾਡੇ ਨਾਲ ਗੱਲ ਕਰ ਰਹੇ ਹਨ",
    "videoTalk.text8": "ਤੁਹਾਨੂੰ ਗੱਲ ਕਰਨ ਲਈ ਸ਼ੁਰੂ ਕੀਤਾ",
    "videoTalk.text9": "ਵੇਖੋ ਪਾਠ ਗੱਲਬਾਤ",
    "videoTalk.text10": "ਪਾਠ ਇੰਪੁੱਟ ....",
    "videoTalk.text11": "ਤੁਹਾਨੂੰ ਹਾਲੇ ਵੀ ਗੱਲ ਕਰ ਰਹੇ ਹਨ. ਤੁਹਾਨੂੰ ਗੱਲ ਕਰਨੀ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    "videoTalk.text12": "ਕਾਲ ਉਪਭੋਗੀ ਨਾਲ ਕੁਨੈਕਸ਼ਨ",
    "videoTalk.text13": "ਉਪਭੋਗੀ ਨੂੰ ਤੈਨੂੰ ਬੁਲਾਇਆ ਹੈ. ਤੁਹਾਨੂੰ ਇਸ ਕਾਲ ਨੂੰ ਸਵੀਕਾਰ ਕਰਦੇ ਹੋ?",
    "videoTalk.text14": "ਤੁਹਾਡੀ ਕਾਲ ਨੂੰ ਰੱਦ",
    "videoTalk.text15": "ਆਨਲਾਈਨ ਯੂਜ਼ਰ ਖੋਜ ...",
    "videoTalk.text16": "ਯੂਜ਼ਰ ਖੋਜ ...",
    "__14": "",
    "groupTalk.text1": "ਸਿਰਜਣਹਾਰ",
    "groupTalk.text2": "ਵੇਰਵਾ",
    "groupTalk.text3": "ਬਣਾਉਣ ਮਿਤੀ",
    "groupTalk.text4": "ਸੋਧ ਦੇ ਗਰੁੱਪ ਜਾਣਕਾਰੀ",
    "groupTalk.text5": "ਇਸ ਨੂੰ ਇਸ ਗਰੁੱਪ ਦੇ ਤੁਹਾਨੂੰ ਹਟਾਉਣ ਲਈ ਕੋਈ ਸਮੱਸਿਆ ਹੈ?",
    "groupTalk.text6": "ਸਦੱਸ ਤੱਕ ਛੱਡੋ",
    "groupTalk.text7": "ਗਰੁੱਪ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋ ਜਾਓ",
    "groupTalk.text8": "ਵੇਖੋ ਗਰੁੱਪ ਸਦੱਸ",
    "groupTalk.text9": "ਤੁਹਾਨੂੰ ਇਸ ਗਰੁੱਪ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋ ਜਾਓ ਚਾਹੁੰਦੇ ਹੋ?",
    "groupTalk.text10": "ਗਰੁੱਪ ਦਾ ਨਾਮ",
    "groupTalk.text11": "ਵਾਪਸ ਸੂਚੀ",
    "groupTalk.text12": "ਆਪਣੇ ਇੰਪੁੱਟ ਦੀ ਪੁਸ਼ਟੀ",
    "groupTalk.text13": "ਗਰੁੱਪ ਬਣਾਓ",
    "groupTalk.text14": "ਅੱਪਡੇਟ ਗਰੁੱਪ",
    "groupTalk.text15": "ਗਰੁੱਪ ਬੰਦ ਕਰੋ",
    "groupTalk.text16": "ਲਾਗਇਨ ਕਰਨ ਲਈ ਹੋਮਪੇਜ ਤੇ ਜਾਓ",
    "groupTalk.text17": "ਸਮੂਹ ਦਾ ਨਾਮ (5-100 ਅੱਖਰ)",
    "groupTalk.text18": "ਗਰੁੱਪ ਵੇਰਵਾ (ਘੱਟ ਵੱਧ 500 ਅੱਖਰ)"
  },
  {
    "LANGUAGE": "Polish",
    "CODE": "pl",
    "test.text1": "cześć",
    "test.text2": "cześć",
    "test.text3": "Dziękuję Ci",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "anulować",
    "general.text2": "Zaakceptować",
    "general.text3": "Kasować",
    "general.text4": "Usunięte",
    "general.text5": "Edytować",
    "general.text6": "Aktualizacja",
    "general.text7": "Załaduj obrazek",
    "general.text8": "Usuń obraz",
    "general.text9": "Wizerunek",
    "general.text10": "Karmić",
    "general.text11": "Zaloguj sie",
    "general.text12": "Zapisz się",
    "general.text13": "Informacje użytkownika",
    "general.text14": "Wyloguj",
    "general.text15": "Plik powinien być",
    "general.text16": "i mniej niż",
    "general.text17": "Hasło",
    "general.text18": "Ulubione Wiadomości",
    "general.text19": "Wiadomości",
    "general.text20": "Obrazy",
    "general.text21": "filmy",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nowy post",
    "feed.text2": "Pokaż Posty",
    "feed.text3": "Pokaż wiadomości",
    "feed.text4": "widok",
    "feed.text5": "edytować",
    "feed.text6": "kasować",
    "feed.text7": "To jest żaden problem, aby usunąć postu całkowicie?",
    "feed.text8": "Wysłane przez",
    "feed.text9": "Stworzone przez",
    "feed.text10": "Tytuł",
    "feed.text11": "Wizerunek",
    "feed.text12": "Zadowolony",
    "feed.text13": "publiczny",
    "feed.text14": "prywatny",
    "feed.text15": "Tytuł postu",
    "feed.text16": "Zawartość autor postu",
    "feed.text17": "kasować",
    "feed.text18": "Czy to nie ma problemu, aby usunąć obraz jest całkowicie?",
    "feed.text19": "Usunięte",
    "feed.text20": "Media File powinien być jpg, jpeg, png lub plik mp4, a mniej niż 3MB",
    "feed.text21": "publicznej po",
    "feed.text22": "Prywatna poczta",
    "feed.text23": "Wpisz w wyszukiwarce ....",
    "feed.text24": "Szukaj użytkownika ...",
    "__2": "",
    "userInfo.text1": "Informacje o użytkowniku",
    "userInfo.text2": "Nazwa",
    "userInfo.text3": "wizerunek",
    "userInfo.text4": "Data utworzenia",
    "userInfo.text5": "Czy to nie ma problemu, aby usunąć obraz jest całkowicie?",
    "userInfo.text6": "Nowe imie ...",
    "userInfo.text7": "Plik graficzny powinien być jpg, jpeg, png lub plik, a mniej niż 1 MB",
    "userInfo.text8": "Tryb ciemny",
    "userInfo.text9": "o użytkowniku",
    "userInfo.text10": "dodatkowe informacje o sobie, opis bio itd ... (mniej niż 300 znaków)",
    "userInfo.text11": "Język",
    "userInfo.text12": "Zmiana",
    "userInfo.text13": "Powiadomienie",
    "__3": "",
    "auth.text1": "Twój email",
    "auth.text2": "Twoje imię",
    "auth.text3": "Hasło",
    "auth.text4": "Hasło powinno mieć od 8 do 20 znaków, co najmniej jedna litera wielka, jedna nas małe, jeden numer i jeden znak specjalny (@ $!% *? &).",
    "auth.text5": "Zapisz się",
    "auth.text6": "Zaloguj sie",
    "auth.text7": "Zapomnij hasło",
    "auth.text8": "Strona rejestracji",
    "auth.text9": "Adres e-mail do resetowania hasła",
    "auth.text10": "wysłać email",
    "auth.text11": "Weryfikacja nie powiodła się. Upewnij się, że adres e-mail nie jest jeszcze używany!",
    "auth.text12": "Logowanie nie powiodło się!",
    "auth.text13": "Nieprawidłowe hasło zostało wprowadzone",
    "auth.text14": "Wprowadzony adres e-mail nie został znaleziony",
    "auth.text15": "Utworzenie użytkownika nie powiodło się!",
    "auth.text16": "Adres e-mail jest już używany przez innego konta",
    "auth.text17": "Pokaż wprowadzone hasło",
    "auth.text18": "Ukryj hasło",
    "auth.text19": "e-mail dla reszty hasło zostało wysłane",
    "auth.text20": "email enterd nie został znaleziony",
    "auth.text21": "Sukces resetowania hasła",
    "auth.text22": "resetowania hasła nie powiodła się, przejdź do strony logowania i wysłać e-mail do resetowania hasła ponownie",
    "auth.text23": "Strona resetowania hasła",
    "auth.text24": "nowe hasło",
    "auth.text25": "Zresetuj hasło",
    "auth.text26": "Strona jest nieprawidłowy lub hasło jest już zmieniona",
    "auth.text27": "Wysyłanie wiadomości e-mail do resetowania hasła nie, należy sprawdzić adres e-mail",
    "auth.text28": "E-mail Strona weryfikacja",
    "auth.text29": "E-mail weryfikacji sukcesu, przejdź do strony logowania przedstawionego powyżej",
    "auth.text30": "Weryfikacja e-mail nie powiodło się. Ta strona jest wygasł lub weryfikacja jest już zakończona.",
    "auth.text31": "Weryfikacja mail został wysłany. Plese sprawdzić pocztę i sprawdzić. (Także sprawdzaj w spamu)",
    "auth.text32": "E-mail weryfikacyjny jest wymagane do logowania. Proszę wysłać e-mail do weryfikacji na adres e-mail.",
    "auth.text33": "Wyślij e-mail do weryfikacji",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Karmić",
    "nav.text2": "Zaloguj sie",
    "nav.text3": "Zapisz się",
    "nav.text4": "Informacje użytkownika",
    "nav.text5": "Wyloguj",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Strona nie znaleziona",
    "notFound.text2": "przejdź do strony paszy",
    "notFound.text3": "przejdź na stronę logowania",
    "notFound.text4": "Gdzie idziesz dzisiaj?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "anulować",
    "comment.text2": "Kasować",
    "comment.text3": "Wyślij komentarz",
    "comment.text4": "Pokaż odpowiedź",
    "comment.text5": "całkowity",
    "comment.text6": "Napisz odpowiedź",
    "comment.text7": "Ukryj Odpowiedz",
    "comment.text8": "Komentarze",
    "comment.text9": "Czy to nie ma problemu, aby usunąć ten komentarz i odpowiedzi komentarza całkowicie?",
    "comment.text10": "Czy to nie ma problemu, aby całkowicie usunąć ten komentarz?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Dodaj do ulubionych Napisz",
    "follow.text2": "Usuń z ulubionych postu",
    "follow.text3": "Dodaj do ulubionych użytkownik",
    "follow.text4": "Usuń z ulubionych użytkownika",
    "follow.text5": "Pokaż wiadomości",
    "follow.text6": "Ulubionych użytkowników",
    "follow.text7": "Poczta ulubionych użytkowników",
    "__11": "",
    "privacyPolicy.text1": "Polityka prywatności",
    "privacyPolicy.text2": "Polityka ta może zostać zaktualizowane lub zmienione bez uprzedzenia. Jest to odpowiedzialność użytkownika aby być na bieżąco o zmianach polityki prywatności. Należy wziąć pod uwagę inne kwestie prywatności, które mogą wpływać na ciebie:",
    "privacyPolicy.text3": "Ta strona może być zagrożona.",
    "privacyPolicy.text4": "Komputer może być zagrożony.",
    "privacyPolicy.text5": "Ta strona jest hostowana na Firebase i DigitalOcean infrastruktury. Mogą i mają swoje własne systemy śledzenia na swoich serwerach. Usługi te mają własne polityki prywatności i nie są objęte niniejszą polityką prywatności.",
    "privacyPolicy.text6": "Ten adres e-sklepy www, forma zaszyfrowane hasła i inne dane wejściowe użytkowników w hostowane infrastruktury Firebase i DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Warunki korzystania",
    "termsOfUse.text2": "Prywatność",
    "termsOfUse.text3": "Twoja prywatność jest dla nas ważna. Proszę przeczytać",
    "termsOfUse.text4": "Polityka prywatności",
    "termsOfUse.text5": "po więcej informacji.",
    "termsOfUse.text6": "Własne obowiązki",
    "termsOfUse.text7": "Ci, użytkownik, są wyłączną odpowiedzialność za zapewnienie własnego zgodności z przepisami prawa i podatków w swojej jurysdykcji. Użytkownik ponosi wyłączną odpowiedzialność za własne bezpieczeństwo.",
    "termsOfUse.text8": "Warunki gwarancji",
    "termsOfUse.text9": "Ta strona jest na „TAK JAK JEST”, BEZ GWARANCJI LUB JAKICHKOLWIEK WARUNKÓW.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Połącz początek",
    "videoTalk.text2": "Połącz przystanek",
    "videoTalk.text3": "Użytkownicy online",
    "videoTalk.text4": "Zacznij rozmawiać",
    "videoTalk.text5": "Twój próbują rozmawiać",
    "videoTalk.text6": "Nie istnieje inny użytkownik",
    "videoTalk.text7": "Mówisz do",
    "videoTalk.text8": "Zacząłeś mówić",
    "videoTalk.text9": "Tekst Talk Show",
    "videoTalk.text10": "Wprowadzanie tekstu ....",
    "videoTalk.text11": "Nadal rozmawiać. Chcesz przestać mówić?",
    "videoTalk.text12": "Wywołanie użytkownik rozłączony",
    "videoTalk.text13": "użytkownik dzwoni. Czy akceptujecie to wezwanie?",
    "videoTalk.text14": "odrzucić połączenie",
    "videoTalk.text15": "Szukaj użytkownika w Internecie ...",
    "videoTalk.text16": "Szukaj użytkownika ...",
    "__14": "",
    "groupTalk.text1": "twórca",
    "groupTalk.text2": "Opis",
    "groupTalk.text3": "Data utworzenia",
    "groupTalk.text4": "Edytuj informacje o grupie",
    "groupTalk.text5": "Czy to nie ma problemu, aby usunąć Cię z członków tej grupy?",
    "groupTalk.text6": "Wyjazd spod Członek",
    "groupTalk.text7": "Przyłączyć się do grupy",
    "groupTalk.text8": "Pokaż Członkowie grupy",
    "groupTalk.text9": "Chcesz dołączyć do tej grupy?",
    "groupTalk.text10": "Nazwa grupy",
    "groupTalk.text11": "Powrót do listy",
    "groupTalk.text12": "Potwierdź swój wkład",
    "groupTalk.text13": "Stworzyć grupę",
    "groupTalk.text14": "Grupa aktualizacja",
    "groupTalk.text15": "Wyjście z grupy",
    "groupTalk.text16": "Przejdź do strony głównej, aby zalogować",
    "groupTalk.text17": "Nazwa grupy (5-100 znaków)",
    "groupTalk.text18": "Opis grupy (mniej niż 500 znaków)"
  },
  {
    "LANGUAGE": "Pashto",
    "CODE": "ps",
    "test.text1": "سلام",
    "test.text2": "سلام",
    "test.text3": "مننه",
    "test.text4": "نقشه",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "لغوه",
    "general.text2": "ومني",
    "general.text3": "حذف",
    "general.text4": "ړنګ",
    "general.text5": "سمول",
    "general.text6": "تازه",
    "general.text7": "پورته انځور",
    "general.text8": "حذف انځور",
    "general.text9": "د انځور",
    "general.text10": "Feed",
    "general.text11": "د ننه کیدل",
    "general.text12": "ګډون کول",
    "general.text13": "کارن پيژندنه",
    "general.text14": "وتل",
    "general.text15": "د دوتنې باید وي",
    "general.text16": "او په پرتله لږ",
    "general.text17": "رمز",
    "general.text18": "د خوښې ليکنې",
    "general.text19": "ليکنې",
    "general.text20": "انځورونه",
    "general.text21": "ويډيوګانې",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "نوي ليکنه د",
    "feed.text2": "وښایاست کارن ليکنې",
    "feed.text3": "خپرونه ليکنې",
    "feed.text4": "محتویات",
    "feed.text5": "سمول",
    "feed.text6": "ړنګول",
    "feed.text7": "آیا د ړنګولو وروسته په بشپړ ډول ستونزه نه ده؟",
    "feed.text8": "Posted by",
    "feed.text9": "له خوا رامنځته",
    "feed.text10": "عنوان",
    "feed.text11": "د انځور",
    "feed.text12": "منځپانګه",
    "feed.text13": "د عامه",
    "feed.text14": "د خصوصي",
    "feed.text15": "د وروسته عنوان",
    "feed.text16": "منځپانګه por وروسته",
    "feed.text17": "ړنګول",
    "feed.text18": "آیا دا د ړنګولو لپاره د انځور په بشپړه ستونزه نه؟",
    "feed.text19": "ړنګ",
    "feed.text20": "د رسنیو د دوتنې باید 3MB څخه لږ JPG، jpeg، png یا mp4 دوتنې وي، او",
    "feed.text21": "د عامه وروسته",
    "feed.text22": "شخصي وروسته",
    "feed.text23": "لپاره د لټون ډول ....",
    "feed.text24": "پلټنه کارن ...",
    "__2": "",
    "userInfo.text1": "د کارن مالومات",
    "userInfo.text2": "نوم",
    "userInfo.text3": "انځور",
    "userInfo.text4": "جوړېدنې نېټه",
    "userInfo.text5": "آیا دا د ړنګولو لپاره د انځور په بشپړه ستونزه نه؟",
    "userInfo.text6": "د نوي نوم ...",
    "userInfo.text7": "د انځور د دوتنې باید 1MB څخه لږ JPG، jpeg، یا png دوتنه وي، او",
    "userInfo.text8": "Dark اکر",
    "userInfo.text9": "په اړه کارن",
    "userInfo.text10": "تاسو په اړه اضافي معلومات، Description Bio داسې نور ... (د 300 څخه لږ خویونه)",
    "userInfo.text11": "ژبه",
    "userInfo.text12": "د بدلون",
    "userInfo.text13": "خبرتیا",
    "__3": "",
    "auth.text1": "ستا برېښنالیک",
    "auth.text2": "ستاسو نوم",
    "auth.text3": "رمز",
    "auth.text4": "پاسورډ باید 8 تر 20 تورو، لږترلږه یو لوېتوري لیک، یو lowercase لیک، یو شمیر او یو خاص کرکټر (@ $!٪ *؟ &).",
    "auth.text5": "ګډون کول",
    "auth.text6": "د ننه کیدل",
    "auth.text7": "پټنوم هېر شوی",
    "auth.text8": "لپارهخپل پاڼه",
    "auth.text9": "د پټنوم د بیرته پست برېښلیک پته",
    "auth.text10": "برېښنا لیک ولېږه",
    "auth.text11": "اعتبار پاتې راغلي دي. د کمکیانو لپاره د ډاډ د ایمیل ادرس نه دی کارول تر اوسه!",
    "auth.text12": "کارن login ناکام!",
    "auth.text13": "ناسم پټنوم شو ننوتل",
    "auth.text14": "ننوتل ایمیل ادرس نه وموندل شو",
    "auth.text15": "د کارن د رښتو ناکام!",
    "auth.text16": "هغه برېښليک پته چې د پخوا څخه بل حساب له خوا ده",
    "auth.text17": "ننوتل پاسورډ وښایاست",
    "auth.text18": "پټ رمز",
    "auth.text19": "پټنوم پاتې لپاره ایمیل ته واستول شوه",
    "auth.text20": "Enterd ایمیل نه وموندل شو",
    "auth.text21": "شفر بیاجوړول برياليتوب",
    "auth.text22": "شفر بیاجوړول ناکام، Login مخ او Send ایمیل ته لطفا د پټنوم د بیرته بیا لاړ",
    "auth.text23": "شفر بیاجوړول مخ",
    "auth.text24": "نوئ پټ نوم",
    "auth.text25": "د بیرته پاسورډ",
    "auth.text26": "Page ناسم دی او يا پاسورډ لا ​​بدل",
    "auth.text27": "د پټنوم د بيا جوړلو بريښناليک لېږل ناکام لطفا پوستې ایمیل ادرس",
    "auth.text28": "برېښنالیک تصدیق مخ",
    "auth.text29": "تصدیق برياليتوب برېښناليک، لطفا login مخ پورته ښودل ته ولاړ",
    "auth.text30": "برېښناليک تاييد ونشو. دا مخ تېر شوی دی، او یا د تصدیق لا پای ته.",
    "auth.text31": "تصديق لیک ته واستول شوه. Plese خپل ایمیل وګورئ او تایید کړي. (هم په سپم پست chack)",
    "auth.text32": "برېښناليک تصديق ته اړتيا ده تر څو ننوتل. لطفا د تصدیق ستاسو دبرېښنا ليک پته ته ليک واستوي.",
    "auth.text33": "د تصدیق ليک وليږئ",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "د ننه کیدل",
    "nav.text3": "ګډون کول",
    "nav.text4": "کارن پيژندنه",
    "nav.text5": "وتل",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "مخ و نه موندل",
    "notFound.text2": "د خوراکي مخ ته ولاړ",
    "notFound.text3": "د ننوت مخ ته ولاړ",
    "notFound.text4": "تاسو د کوم ځای نن ته لاړ؟",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "لغوه",
    "comment.text2": "حذف",
    "comment.text3": "ليکنه د پيغام",
    "comment.text4": "نمایش ته ځواب ورکړئ",
    "comment.text5": "ټول",
    "comment.text6": "نوشتن ته ځواب ورکړئ",
    "comment.text7": "پټول ته ځواب ورکړئ",
    "comment.text8": "تبصره",
    "comment.text9": "آیا دا ستونزه نشته چې د ړنګولو په دې نظر او د دې نظر په بشپړه توګه ځواب؟",
    "comment.text10": "آیا دا ستونزه نشته چې په بشپړه توګه د ړنګولو په دې نظر؟",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "ته خواپورې ليکنه د Add",
    "follow.text2": "له خوښې وروسته ړنګول",
    "follow.text3": "د خوښې د کارونکي ورزیات کړئ",
    "follow.text4": "له خوښې کارونکي ړنګول",
    "follow.text5": "ليکنې خپرونه",
    "follow.text6": "ستا د خوښې کارنان",
    "follow.text7": "ليکنه د خواپورې کارنان",
    "__11": "",
    "privacyPolicy.text1": "د پټتیا تګلاره",
    "privacyPolicy.text2": "دا پالیسي ښايي تازه شي او يا پرته د اعالم کتل. دا د کارونکي ته د استوګنې د پټنتيا تگلاره د بدلونونو په اړه خبر په غاړه ده. د نورو د پټنتيا مسایل چې کېدای شي تاسو اغيزه نوټ واخلئ:",
    "privacyPolicy.text3": "دغه ویب پاڼه کېدای شي استعمالېږي.",
    "privacyPolicy.text4": "ستاسو د کمپيوټر کېدای شي استعمالېږي.",
    "privacyPolicy.text5": "دغه ویب پاڼه د Firebase او DigitalOcean بنسټونو کوربه و. دوی د مې او مه د خپلو سرور خپل تعقیب سیسټمونه لري. د دغو خدمتونو د خپلو د محرمیت د تګلاری لري او دوی دې د پټنتيا تگلاره له خوا تر پوښښ لاندې نه دي.",
    "privacyPolicy.text6": "دغه ویب پاڼه دوکانونو ایمیل، د پټنوم د کوډ شوې فورمې، او د کوربه Firebase او DigitalOcean بنسټونو د کاروونکو څره.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "د کارولو شرایط",
    "termsOfUse.text2": "د پټنتيا",
    "termsOfUse.text3": "ستاسو د پټنتيا موږ ته مهمه ده. لطفا د پاتې برخه",
    "termsOfUse.text4": "د پټتیا تګلاره",
    "termsOfUse.text5": "د زياتو معلوماتو لپاره.",
    "termsOfUse.text6": "ستاسو د خپل مسؤلیتونه",
    "termsOfUse.text7": "تاسو، د کارونکي دي، یوازې د خپل سره په خپل واک د قوانینو او د مالياتو د خپل اطاعت تامين په غاړه لري. تاسو یوازې د ستاسو د خپل امنیت مسولیت لري.",
    "termsOfUse.text8": "د تضمین دادعا",
    "termsOfUse.text9": "دا پاڼه په يو برابر \"ده\" پر بنسټ، پرته مکلفیتونه یا کوم ډول conditions.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "د پیل سره نښلوي",
    "videoTalk.text2": "Stop سره نښلوي",
    "videoTalk.text3": "په آنلاین توګه د کاروونکو",
    "videoTalk.text4": "سره خبرې بیا",
    "videoTalk.text5": "ستاسو ته خبرې اترې هڅه دي",
    "videoTalk.text6": "نه د نورو آنلاین کارونکي شتون",
    "videoTalk.text7": "تاسو ته خبرې کوي",
    "videoTalk.text8": "تاسو سره خبرې پیل",
    "videoTalk.text9": "خپرونه متن خبرې اترې",
    "videoTalk.text10": "متن آخذه ....",
    "videoTalk.text11": "تاسو اوس هم خبرې کړي دي. تاسو غواړئ چې د خبرو مخه ونيسي؟",
    "videoTalk.text12": "د کارونکي وبلله پيوست",
    "videoTalk.text13": "د کارونکي ده غوښتنه تاسو. آيا تاسو د دې غوښتنې ومني؟",
    "videoTalk.text14": "رد د خپل غږ",
    "videoTalk.text15": "پلټنه آنلاین کارونکي ...",
    "videoTalk.text16": "پلټنه د کارونکي ...",
    "__14": "",
    "groupTalk.text1": "خالق",
    "groupTalk.text2": "Description",
    "groupTalk.text3": "جوړېدنې نېټه",
    "groupTalk.text4": "د سمونونو شمېر ډلې پيژندنه",
    "groupTalk.text5": "آیا دا ستونزه نشته چې له دې ډلې د غړو تاسو د ړنګولو؟",
    "groupTalk.text6": "له غړو څخه ووځي",
    "groupTalk.text7": "ګروپ سره یو ځای شول",
    "groupTalk.text8": "خپرونه ګروپ غړي",
    "groupTalk.text9": "ایا ته غواړې چې دا ګروپ ته سره یو ځای شول؟",
    "groupTalk.text10": "ډلې نوم",
    "groupTalk.text11": "بېرته بشپړفهرست",
    "groupTalk.text12": "ستاسو د آخذه تاييد",
    "groupTalk.text13": "ګروپ جوړول",
    "groupTalk.text14": "تازه ګروپ",
    "groupTalk.text15": "له ګروپ وزي",
    "groupTalk.text16": "ته څېره کورپاڼه ته لاړ شئ",
    "groupTalk.text17": "ډلې نوم (5-100 خویونه)",
    "groupTalk.text18": "ډله Description (د 500 څخه کمې خویونه)"
  },
  {
    "LANGUAGE": "Portuguese",
    "CODE": "pt",
    "test.text1": "Olá",
    "test.text2": "Oi",
    "test.text3": "Obrigado",
    "test.text4": "mapa",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Cancelar",
    "general.text2": "Aceitar",
    "general.text3": "Excluir",
    "general.text4": "Deleted",
    "general.text5": "Editar",
    "general.text6": "Atualizar",
    "general.text7": "Enviar Imagem",
    "general.text8": "imagem de exclusão",
    "general.text9": "Imagem",
    "general.text10": "Alimentação",
    "general.text11": "Conecte-se",
    "general.text12": "Inscrever-se",
    "general.text13": "informação de usuário",
    "general.text14": "Sair",
    "general.text15": "arquivo deve ser",
    "general.text16": "e menos de",
    "general.text17": "Senha",
    "general.text18": "Mensagens favoritos",
    "general.text19": "Postagens",
    "general.text20": "imagens",
    "general.text21": "vídeos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nova postagem",
    "feed.text2": "Mostrar mensagens de usuários",
    "feed.text3": "Mostrar Mensagens",
    "feed.text4": "Visão",
    "feed.text5": "editar",
    "feed.text6": "excluir",
    "feed.text7": "É não é problema para pós apagar completamente?",
    "feed.text8": "postado por",
    "feed.text9": "Criado por",
    "feed.text10": "Título",
    "feed.text11": "Imagem",
    "feed.text12": "Conteúdo",
    "feed.text13": "público",
    "feed.text14": "privado",
    "feed.text15": "Título para pós",
    "feed.text16": "pós POR conteúdo",
    "feed.text17": "excluir",
    "feed.text18": "É nenhum problema para a imagem apagar completamente?",
    "feed.text19": "Deleted",
    "feed.text20": "Arquivo de mídia deve ser jpg, jpeg, png ou arquivo mp4, e menos de 3MB",
    "feed.text21": "pós pública",
    "feed.text22": "pós privada",
    "feed.text23": "Digite para Pesquisa ....",
    "feed.text24": "Usuário pesquisar ...",
    "__2": "",
    "userInfo.text1": "Informação do usuário",
    "userInfo.text2": "nome",
    "userInfo.text3": "imagem",
    "userInfo.text4": "data de criação",
    "userInfo.text5": "É nenhum problema para a imagem apagar completamente?",
    "userInfo.text6": "Novo nome ...",
    "userInfo.text7": "ficheiro de imagem deve ser jpg, jpeg, png ou arquivo, e menos de 1 MB",
    "userInfo.text8": "Modo escuro",
    "userInfo.text9": "Sobre o Utilizador",
    "userInfo.text10": "obter informações adicionais sobre você, descrição bio etc ... (menos de 300 caracteres)",
    "userInfo.text11": "Língua",
    "userInfo.text12": "Mudar",
    "userInfo.text13": "Notificação",
    "__3": "",
    "auth.text1": "Seu email",
    "auth.text2": "Seu nome",
    "auth.text3": "Senha",
    "auth.text4": "A senha deve ser de 8 a 20 caracteres, pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial (@ $!% *? &).",
    "auth.text5": "Inscrever-se",
    "auth.text6": "Conecte-se",
    "auth.text7": "Esqueceu a senha",
    "auth.text8": "Registre-se Página",
    "auth.text9": "E-mail para redefinição de senha",
    "auth.text10": "enviar email",
    "auth.text11": "Falha na validação. Verifique se o endereço de e-mail não é utilizada ainda!",
    "auth.text12": "Login do usuário falhou!",
    "auth.text13": "Senha inválida foi Introduzido",
    "auth.text14": "endereço de e-mail inserido não foi encontrado",
    "auth.text15": "a criação do usuário falhou!",
    "auth.text16": "O endereço de e-mail já está em uso por outra conta",
    "auth.text17": "Mostrar senha digitada",
    "auth.text18": "Esconder a senha",
    "auth.text19": "e-mail para o descanso senha foi enviada",
    "auth.text20": "Enterd e-mail não foi encontrado",
    "auth.text21": "sucesso de redefinição de senha",
    "auth.text22": "redefinição de senha falhou, por favor vá à página de login e enviar e-mail para redefinição de senha novamente",
    "auth.text23": "página de redefinição de senha",
    "auth.text24": "Nova senha",
    "auth.text25": "Password Reset",
    "auth.text26": "Página é inválido ou senha já é alterado",
    "auth.text27": "Enviando e-mail para redefinição de senha falhou, por favor endereço de e-mail de verificação",
    "auth.text28": "página de verificação de e-mail",
    "auth.text29": "-Mail de verificação sucesso, por favor, ir para a página de login mostrado acima",
    "auth.text30": "verificação de e-mail falhou. Esta página é expirada, ou verificação já está terminado.",
    "auth.text31": "A mensagem de verificação foi enviado. Plese verificar seu e-mail e verificar. (Também chack no correio spam)",
    "auth.text32": "E-mail de verificação é necessária para fazer login. Por favor, envie e-mail para confirmação para o seu endereço de e-mail.",
    "auth.text33": "Enviar e-mail para verificação",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Alimentação",
    "nav.text2": "Conecte-se",
    "nav.text3": "Inscrever-se",
    "nav.text4": "informação de usuário",
    "nav.text5": "Sair",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Página não encontrada",
    "notFound.text2": "ir para a página de alimentação",
    "notFound.text3": "ir para a página de login",
    "notFound.text4": "Onde você vai hoje?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Cancelar",
    "comment.text2": "Excluir",
    "comment.text3": "Post Comment",
    "comment.text4": "Mostrar a resposta",
    "comment.text5": "total",
    "comment.text6": "Escrever Responder",
    "comment.text7": "Esconder Responder",
    "comment.text8": "Comentários",
    "comment.text9": "É nenhum problema para apagar este comentário e respostas deste comentário completamente?",
    "comment.text10": "É nenhum problema para excluir este comentário completamente?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Adicionar a favoritos Pós",
    "follow.text2": "Excluir do pós favorito",
    "follow.text3": "Adicionar ao utilizador favorito",
    "follow.text4": "Excluir do usuário favorito",
    "follow.text5": "Mostrar mensagens",
    "follow.text6": "Seus usuários favoritos",
    "follow.text7": "Usuários pós favoritos",
    "__11": "",
    "privacyPolicy.text1": "Política de Privacidade",
    "privacyPolicy.text2": "Esta política pode ser atualizado ou revisto sem aviso prévio. É de responsabilidade do usuário para se manter informado sobre mudanças de política de privacidade. Tome nota de outras questões de privacidade que podem afetá-lo:",
    "privacyPolicy.text3": "Este site pode ser comprometida.",
    "privacyPolicy.text4": "Seu computador pode ser comprometida.",
    "privacyPolicy.text5": "Este website está hospedado em infra-estruturas Firebase e DigitalOcean. Eles podem e têm seus próprios sistemas de rastreamento em seus servidores. Esses serviços têm suas próprias políticas de privacidade e não são abrangidos por esta política de privacidade.",
    "privacyPolicy.text6": "Este e-mail site armazena, forma criptografada da senha e outras entradas de usuários no hospedados infra-estruturas Firebase e DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Termos de uso",
    "termsOfUse.text2": "Privacidade",
    "termsOfUse.text3": "Sua privacidade é importante para nós. Por favor, leia o",
    "termsOfUse.text4": "política de Privacidade",
    "termsOfUse.text5": "Para maiores informações.",
    "termsOfUse.text6": "Suas próprias responsabilidades",
    "termsOfUse.text7": "Você, o usuário, é o único responsável por garantir sua própria conformidade com leis e impostos em sua jurisdição. Você é o único responsável por sua própria segurança.",
    "termsOfUse.text8": "Isenção de garantia",
    "termsOfUse.text9": "Este site é fornecido \"COMO ESTÁ\", SEM GARANTIAS OU CONDIÇÕES DE QUALQUER NATUREZA.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Iniciar Ligar",
    "videoTalk.text2": "Pare o Connect",
    "videoTalk.text3": "Usuários online",
    "videoTalk.text4": "Comece a falar",
    "videoTalk.text5": "Sua estão tentando talk",
    "videoTalk.text6": "existe nenhum outro usuário online",
    "videoTalk.text7": "Você está falando com",
    "videoTalk.text8": "Você começou a falar",
    "videoTalk.text9": "Talk show Texto",
    "videoTalk.text10": "entrada de texto ....",
    "videoTalk.text11": "Você ainda está falando. Você quer parar de falar?",
    "videoTalk.text12": "usuário chamando desconectado",
    "videoTalk.text13": "usuário está chamando você. Você aceita esta chamada?",
    "videoTalk.text14": "rejeitar a sua chamada",
    "videoTalk.text15": "procurar utilizador online ...",
    "videoTalk.text16": "procurar utilizador ...",
    "__14": "",
    "groupTalk.text1": "O Criador",
    "groupTalk.text2": "Descrição",
    "groupTalk.text3": "data de criação",
    "groupTalk.text4": "Editar informação grupo",
    "groupTalk.text5": "É nenhum problema para excluir você de membros deste grupo?",
    "groupTalk.text6": "Deixe dos Estados",
    "groupTalk.text7": "Juntar-se ao grupo",
    "groupTalk.text8": "Mostrar membros do Grupo",
    "groupTalk.text9": "Você quer aderir a este grupo?",
    "groupTalk.text10": "Nome do grupo",
    "groupTalk.text11": "De volta à lista",
    "groupTalk.text12": "Confirme a sua entrada",
    "groupTalk.text13": "Criar grupo",
    "groupTalk.text14": "Grupo atualização",
    "groupTalk.text15": "Sair do Grupo",
    "groupTalk.text16": "Ir para Homepage de login",
    "groupTalk.text17": "nome do grupo (5-100 caracteres)",
    "groupTalk.text18": "Descrição grupo (menos do que 500 caracteres)"
  },
  {
    "LANGUAGE": "Quechua",
    "CODE": "qu",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Romanian",
    "CODE": "ro",
    "test.text1": "Salut",
    "test.text2": "Bună",
    "test.text3": "Mulțumesc",
    "test.text4": "Hartă",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Anulare",
    "general.text2": "Accept",
    "general.text3": "Șterge",
    "general.text4": "şters",
    "general.text5": "Editați | ×",
    "general.text6": "Actualizați",
    "general.text7": "Incarca imaginea",
    "general.text8": "Ștergeți imaginea",
    "general.text9": "Imagine",
    "general.text10": "A hrani",
    "general.text11": "Autentificare",
    "general.text12": "Inscrie-te",
    "general.text13": "Informații utilizator",
    "general.text14": "Deconectare",
    "general.text15": "fișier ar trebui să fie",
    "general.text16": "și mai puțin",
    "general.text17": "Parola",
    "general.text18": "Mesaje preferate",
    "general.text19": "Mesaje",
    "general.text20": "Imagini",
    "general.text21": "Videoclipuri",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Postare noua",
    "feed.text2": "Afiseaza mesajele utilizatorului",
    "feed.text3": "Afișare Mesaje",
    "feed.text4": "vedere",
    "feed.text5": "Editați | ×",
    "feed.text6": "șterge",
    "feed.text7": "Este este nici o problemă pentru a posta șterge complet?",
    "feed.text8": "Postat de",
    "feed.text9": "Creat de",
    "feed.text10": "Titlu",
    "feed.text11": "Imagine",
    "feed.text12": "Conţinut",
    "feed.text13": "public",
    "feed.text14": "privat",
    "feed.text15": "Titlu pentru post-",
    "feed.text16": "postare por Conținut",
    "feed.text17": "șterge",
    "feed.text18": "Este nici o problemă de imagine pentru a șterge complet?",
    "feed.text19": "şters",
    "feed.text20": "Media File ar trebui să fie jpg, jpeg, png sau un fișier MP4, și mai puțin de 3MB",
    "feed.text21": "postare publică",
    "feed.text22": "post-privat",
    "feed.text23": "Tip de căutare ....",
    "feed.text24": "Căutare utilizator ...",
    "__2": "",
    "userInfo.text1": "Informații despre utilizator",
    "userInfo.text2": "Nume",
    "userInfo.text3": "imagine",
    "userInfo.text4": "data crearii",
    "userInfo.text5": "Este nici o problemă de imagine pentru a șterge complet?",
    "userInfo.text6": "Nume nou ...",
    "userInfo.text7": "Fișierul imagine trebuie să fie jpg, jpeg, sau un fișier png, și mai puțin de 1 MO",
    "userInfo.text8": "Modul închis",
    "userInfo.text9": "utilizator Despre",
    "userInfo.text10": "informații suplimentare despre tine, descriere bio, etc ... (mai puțin de 300 de caractere)",
    "userInfo.text11": "Limba",
    "userInfo.text12": "Schimbare",
    "userInfo.text13": "Notificare",
    "__3": "",
    "auth.text1": "Email-ul tau",
    "auth.text2": "Numele dumneavoastră",
    "auth.text3": "Parola",
    "auth.text4": "Parola ar trebui să fie de 8 până la 20 de caractere, cel puțin o literă mare, o literă mică, un număr și un caracter special (@ $!% *? &).",
    "auth.text5": "Inscrie-te",
    "auth.text6": "Autentificare",
    "auth.text7": "Parola uitata",
    "auth.text8": "pagină de înscriere",
    "auth.text9": "Adresa de e-mail pentru resetarea parolei",
    "auth.text10": "Trimite email",
    "auth.text11": "Validarea a esuat. Asigurați-vă că adresa de e-mail nu este încă folosit!",
    "auth.text12": "Conectare utilizator nu a reușit!",
    "auth.text13": "Parola nevalidă a fost Introdus",
    "auth.text14": "Introdus adresă e-mail nu a fost găsit",
    "auth.text15": "crearea de utilizatori nu a reușit!",
    "auth.text16": "Adresa de e-mail este deja utilizat de către un alt cont",
    "auth.text17": "Afișați parola Introdus",
    "auth.text18": "Ascundeți parola",
    "auth.text19": "e-mail pentru odihna parola a fost trimisă",
    "auth.text20": "e-mail Enterd nu a fost găsit",
    "auth.text21": "Succesul de resetare a parolei",
    "auth.text22": "Resetarea parolei nu a reușit, mergeți la pagina de autentificare și de e-mail de trimitere pentru resetarea parolei din nou",
    "auth.text23": "Pagina de resetare a parolei",
    "auth.text24": "Parolă Nouă",
    "auth.text25": "Reseteaza parola",
    "auth.text26": "Pagină este invalid sau parolă este deja schimbată",
    "auth.text27": "Trimiterea de e-mail pentru resetarea parolei a eșuat, Vă rugăm să verificați adresa de e-mail",
    "auth.text28": "pagina de verificare a e-mail",
    "auth.text29": "E-mail de verificare de succes, vă rugăm să mergeți la pagina de autentificare prezentată mai sus",
    "auth.text30": "Verificarea e-mail nu a reușit. Această pagină a expirat, sau de verificare este deja terminat.",
    "auth.text31": "Verificarea mail a fost trimis. Plese verifica e-mail-ul și să verifice. (Chack, de asemenea, în e-mail de spam)",
    "auth.text32": "E-mail de verificare este necesară pentru autentificare. Vă rugăm să trimiteți e-mail pentru verificare adresei de e-mail.",
    "auth.text33": "Trimite e-mail pentru verificare",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "A hrani",
    "nav.text2": "Autentificare",
    "nav.text3": "Inscrie-te",
    "nav.text4": "Informații utilizator",
    "nav.text5": "Deconectare",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Pagina nu a fost gasita",
    "notFound.text2": "du-te la pagina de alimentare",
    "notFound.text3": "du-te la pagina de conectare",
    "notFound.text4": "Unde te duci azi?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Anulare",
    "comment.text2": "Șterge",
    "comment.text3": "posteaza comentariu",
    "comment.text4": "Arată Răspuns",
    "comment.text5": "total",
    "comment.text6": "Scrie Răspuns",
    "comment.text7": "Ascunde Răspuns",
    "comment.text8": "Comentarii",
    "comment.text9": "Este nici o problemă pentru a șterge acest comentariu și răspunsurile din acest comentariu complet?",
    "comment.text10": "Este nici o problemă pentru a șterge acest comentariu complet?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Adauga la favorite Publica",
    "follow.text2": "Ștergeți din postul favorit",
    "follow.text3": "Adăugați la utilizator favorit",
    "follow.text4": "Ștergeți de utilizator favorit",
    "follow.text5": "Arată posturi",
    "follow.text6": "Utilizatorii dvs. preferate",
    "follow.text7": "Utilizatorii postează favorite",
    "__11": "",
    "privacyPolicy.text1": "Politica de Confidențialitate",
    "privacyPolicy.text2": "Această politică poate fi actualizată sau revizuită fără notificare. Este responsabilitatea utilizatorului de ședere informat cu privire la schimbările de politică de confidențialitate. Ia act de alte probleme de confidențialitate pe care le pot afecta:",
    "privacyPolicy.text3": "Acest site ar putea fi compromisă.",
    "privacyPolicy.text4": "Calculatorul poate fi compromisă.",
    "privacyPolicy.text5": "Acest site este găzduit pe infrastructura Firebase și DigitalOcean. Ele pot și au propriile lor sisteme de urmărire pe serverele lor. Aceste servicii au propriile politici de confidențialitate și nu sunt acoperite de această politică de confidențialitate.",
    "privacyPolicy.text6": "Acest e-mail site-ul stochează, sub formă criptată a parolei, precum și a altor factori de producție ale utilizatorilor de la găzduit infrastructuri Firebase și DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Termeni de utilizare",
    "termsOfUse.text2": "intimitate",
    "termsOfUse.text3": "Confidențialitatea dvs. este importantă pentru noi. Vă rugăm să citiți",
    "termsOfUse.text4": "Politica de Confidențialitate",
    "termsOfUse.text5": "pentru mai multe informatii.",
    "termsOfUse.text6": "propriile tale responsabilități",
    "termsOfUse.text7": "Tu, utilizatorul, sunteți singurul responsabil pentru a asigura propria conformitate cu legile și taxele în jurisdicția dumneavoastră. Sunteți singurul responsabil pentru propria securitate.",
    "termsOfUse.text8": "Declinarea garanției",
    "termsOfUse.text9": "Acest site este oferit „CA ATARE“, FĂRĂ GARANȚII SAU CONDIȚII DE ORICE FEL.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Conectare",
    "videoTalk.text2": "Oprire Conectare",
    "videoTalk.text3": "Utilizatori online",
    "videoTalk.text4": "Începe să vorbească",
    "videoTalk.text5": "Dvs. încearcă să vorbim",
    "videoTalk.text6": "Nici un alt utilizator on-line există",
    "videoTalk.text7": "Tu vorbesti cu",
    "videoTalk.text8": "Ai început să vorbești",
    "videoTalk.text9": "Afișare text Discuție",
    "videoTalk.text10": "de introducere a textului ....",
    "videoTalk.text11": "Tu încă mai vorbesc. Vrei să nu mai vorbești?",
    "videoTalk.text12": "utilizatorului Tonuri deconectat",
    "videoTalk.text13": "utilizator te cheamă. Acceptați acest apel?",
    "videoTalk.text14": "respinge apelul",
    "videoTalk.text15": "Căutare utilizator on-line ...",
    "videoTalk.text16": "Căutare utilizator ...",
    "__14": "",
    "groupTalk.text1": "creator",
    "groupTalk.text2": "Descriere",
    "groupTalk.text3": "data crearii",
    "groupTalk.text4": "Editați informațiile de grup",
    "groupTalk.text5": "Este nici o problemă să vă ștergeți de la membrii acestui grup?",
    "groupTalk.text6": "Lăsați de membru",
    "groupTalk.text7": "Alătură-te grupului",
    "groupTalk.text8": "Afișare Membrii grupului",
    "groupTalk.text9": "Doriți să vă alăturați acestui grup?",
    "groupTalk.text10": "Numele Grupului",
    "groupTalk.text11": "Înapoi la listă",
    "groupTalk.text12": "Confirmați datele introduse",
    "groupTalk.text13": "Creare grup",
    "groupTalk.text14": "Actualizare grup",
    "groupTalk.text15": "Ieșirea din grup",
    "groupTalk.text16": "Du-te la pagina de pornire pentru a te inregistra",
    "groupTalk.text17": "nume de grup (5-100 caractere)",
    "groupTalk.text18": "Descriere grup (mai puțin de 500 de caractere)"
  },
  {
    "LANGUAGE": "Russian",
    "CODE": "ru",
    "test.text1": "Здравствуйте",
    "test.text2": "Здравствуй",
    "test.text3": "Спасибо",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Отмена",
    "general.text2": "принимать",
    "general.text3": "удалять",
    "general.text4": "Исключен",
    "general.text5": "редактировать",
    "general.text6": "Обновить",
    "general.text7": "Загрузить изображение",
    "general.text8": "Удалить изображение",
    "general.text9": "Образ",
    "general.text10": "Корм",
    "general.text11": "Авторизоваться",
    "general.text12": "Зарегистрироваться",
    "general.text13": "Информация о пользователе",
    "general.text14": "Выйти",
    "general.text15": "Файл должен быть",
    "general.text16": "и меньше",
    "general.text17": "пароль",
    "general.text18": "Избранные сообщения",
    "general.text19": "Сообщений",
    "general.text20": "Изображений",
    "general.text21": "Видео",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Новый пост",
    "feed.text2": "Показать сообщения пользователя",
    "feed.text3": "Показать сообщения",
    "feed.text4": "Посмотреть",
    "feed.text5": "редактировать",
    "feed.text6": "удалять",
    "feed.text7": "Разве это не проблема, чтобы удалить сообщение полностью?",
    "feed.text8": "Сообщение от",
    "feed.text9": "Сделано",
    "feed.text10": "заглавие",
    "feed.text11": "Образ",
    "feed.text12": "содержание",
    "feed.text13": "общественности",
    "feed.text14": "частный",
    "feed.text15": "Заголовок поста",
    "feed.text16": "Содержание Por сообщение",
    "feed.text17": "удалять",
    "feed.text18": "Разве это не проблема удаления изображения полностью?",
    "feed.text19": "Исключен",
    "feed.text20": "Media File должен быть JPG, JPEG, PNG или файл mp4, и меньше, чем 3MB",
    "feed.text21": "общественное сообщение",
    "feed.text22": "частное сообщение",
    "feed.text23": "Введите для поиска ....",
    "feed.text24": "Поиск пользователя ...",
    "__2": "",
    "userInfo.text1": "информация о пользователе",
    "userInfo.text2": "имя",
    "userInfo.text3": "образ",
    "userInfo.text4": "Дата создания",
    "userInfo.text5": "Разве это не проблема удаления изображения полностью?",
    "userInfo.text6": "Новое имя ...",
    "userInfo.text7": "Файл изображения должен быть JPG, JPEG или PNG-файл, и менее 1 МБ",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "О пользователе",
    "userInfo.text10": "Дополнительная информация о Вас, описание био и т.д ... (менее 300 символов)",
    "userInfo.text11": "Язык",
    "userInfo.text12": "Изменять",
    "userInfo.text13": "Уведомление",
    "__3": "",
    "auth.text1": "Ваш адрес электронной почты",
    "auth.text2": "Ваше имя",
    "auth.text3": "пароль",
    "auth.text4": "Пароль должен быть от 8 до 20 символов, по меньшей мере, один верхний регистр букв, одна строчная буква, один номер и один специальный символ (@ $!% *? &).",
    "auth.text5": "Зарегистрироваться",
    "auth.text6": "Авторизоваться",
    "auth.text7": "Забыть пароль",
    "auth.text8": "Регистрация Page",
    "auth.text9": "Адрес электронной почты для сброса пароля",
    "auth.text10": "отправить электронную почту",
    "auth.text11": "Проверка не удалась. Убедитесь, что адрес электронной почты еще не используется!",
    "auth.text12": "Логин пользователя не удалось!",
    "auth.text13": "Неверный пароль был введен",
    "auth.text14": "Введенный адрес электронной почты не найден",
    "auth.text15": "Создание пользователя не удалось!",
    "auth.text16": "Адрес электронной почты уже используется другой учетной записи",
    "auth.text17": "Показать введенный пароль",
    "auth.text18": "Скрыть Пароль",
    "auth.text19": "электронная почта для пароля отдыха была отправлена",
    "auth.text20": "Enterd электронной почты не найден",
    "auth.text21": "Успех Восстановление пароля",
    "auth.text22": "Сброс пароля неудачен, пожалуйста, перейдите на страницу входа в систему и отправить по электронной почте для сброса пароля еще раз",
    "auth.text23": "страница Восстановление пароля",
    "auth.text24": "Новый пароль",
    "auth.text25": "Сброс пароля",
    "auth.text26": "Страница недействительна или пароль уже изменен",
    "auth.text27": "Отправка электронной почты для сброса пароля не прошло, проверьте адрес электронной почты",
    "auth.text28": "страница проверки электронной почты",
    "auth.text29": "Email подтверждения успеха, пожалуйста, перейдите на страницу входа, показанную выше",
    "auth.text30": "Email проверки не удалось. Эта страница истек, или проверка уже закончена.",
    "auth.text31": "Почта Проверка была отправлена. Plese проверить свою электронную почту и проверить. (Также Chack в спам)",
    "auth.text32": "Подтверждение адреса электронной почты необходимо войти в систему. Пожалуйста, присылайте на e-mail для проверки на ваш адрес электронной почты.",
    "auth.text33": "Отправить Email для проверки",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Корм",
    "nav.text2": "Авторизоваться",
    "nav.text3": "Зарегистрироваться",
    "nav.text4": "Информация о пользователе",
    "nav.text5": "Выйти",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Страница не найдена",
    "notFound.text2": "перейти на страницу подачи",
    "notFound.text3": "перейти на страницу входа",
    "notFound.text4": "Где вы сегодня?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Отмена",
    "comment.text2": "удалять",
    "comment.text3": "Оставить комментарий",
    "comment.text4": "Показать ответ",
    "comment.text5": "Всего",
    "comment.text6": "Написать ответ",
    "comment.text7": "Скрыть Ответить",
    "comment.text8": "Комментарии",
    "comment.text9": "Разве это не проблема, чтобы удалить этот комментарий и ответы на этот комментарий полностью?",
    "comment.text10": "Разве это не проблема, чтобы полностью удалить этот комментарий?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Добавить в избранное сообщение",
    "follow.text2": "Удалить из любимого поста",
    "follow.text3": "Добавить в избранное пользователь",
    "follow.text4": "Удалить из любимого пользователя",
    "follow.text5": "показать сообщения",
    "follow.text6": "Ваши любимые Пользователи",
    "follow.text7": "Сообщение Любимые Пользователи",
    "__11": "",
    "privacyPolicy.text1": "политика конфиденциальности",
    "privacyPolicy.text2": "Эта политика может быть обновлена ​​или изменена без предварительного уведомления. Это ответственность пользователя Остаться информированным об изменениях политики конфиденциальности. Обратите внимание на другие вопросы конфиденциальности, которые могут повлиять на вас:",
    "privacyPolicy.text3": "Этот веб-сайт может быть поставлена ​​под угрозу.",
    "privacyPolicy.text4": "Ваш компьютер может быть поставлена ​​под угрозу.",
    "privacyPolicy.text5": "Этот веб-сайт размещен на Firebase и DigitalOcean инфраструктуры. Они могут и не иметь свои собственные системы слежения на своих серверах. Эти услуги имеют свою собственную политику конфиденциальности, и они не охвачены этой политикой конфиденциальности.",
    "privacyPolicy.text6": "Этот сайт хранит электронную почту, зашифрованном виде пароля, а также другие входы пользователей на размещенные Firebase и DigitalOcean инфраструктуры.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Условия эксплуатации",
    "termsOfUse.text2": "Конфиденциальность",
    "termsOfUse.text3": "Ваша конфиденциальность очень важна для нас. Пожалуйста, прочтите",
    "termsOfUse.text4": "политика конфиденциальности",
    "termsOfUse.text5": "Чтобы получить больше информации.",
    "termsOfUse.text6": "Ваши собственные обязанности",
    "termsOfUse.text7": "Вы, пользователь, несете полную ответственность за обеспечение собственного соблюдения законов и налогами в вашей юрисдикции. Вы несете полную ответственность за свою собственную безопасность.",
    "termsOfUse.text8": "Отказ от гарантии",
    "termsOfUse.text9": "Этот сайт предоставляется по принципу «как есть», БЕЗ ГАРАНТИЙ ИЛИ УСЛОВИЙ КАКИХ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Стоп Connect",
    "videoTalk.text3": "Пользователи на сайте",
    "videoTalk.text4": "Начинают говорить",
    "videoTalk.text5": "Вы пытаетесь говорить",
    "videoTalk.text6": "Ни один другой онлайн пользователь не существует",
    "videoTalk.text7": "Вы разговариваете",
    "videoTalk.text8": "Вы начали говорить",
    "videoTalk.text9": "Показать текст Обсуждение",
    "videoTalk.text10": "ввод текста ....",
    "videoTalk.text11": "Вы все еще говорите. Вы хотите, чтобы прекратить говорить?",
    "videoTalk.text12": "Вызов пользователя отключен",
    "videoTalk.text13": "пользователь звонит вам. Принимаете ли вы этот вызов?",
    "videoTalk.text14": "отклонить вызов",
    "videoTalk.text15": "Поиск онлайн-пользователей ...",
    "videoTalk.text16": "Поиск пользователей ...",
    "__14": "",
    "groupTalk.text1": "создатель",
    "groupTalk.text2": "Описание",
    "groupTalk.text3": "Дата создания",
    "groupTalk.text4": "Редактирование информации о группе",
    "groupTalk.text5": "Разве это не проблема, чтобы удалить вас из членов этой группы?",
    "groupTalk.text6": "Выходим из члена",
    "groupTalk.text7": "Вступить в группу",
    "groupTalk.text8": "Показать Члены группы",
    "groupTalk.text9": "Хотите ли вы присоединиться к этой группе?",
    "groupTalk.text10": "Название группы",
    "groupTalk.text11": "Обратно к списку",
    "groupTalk.text12": "Подтвердите ввод",
    "groupTalk.text13": "Создать группу",
    "groupTalk.text14": "Группа Update",
    "groupTalk.text15": "Выход из группы",
    "groupTalk.text16": "Перейти на главную страницу к Логин",
    "groupTalk.text17": "Имя группы (5-100 символов)",
    "groupTalk.text18": "Описание группы (менее 500 символов)"
  },
  {
    "LANGUAGE": "Sanskrit",
    "CODE": "sa",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Sami (Northern)",
    "CODE": "se",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Slovak",
    "CODE": "sk",
    "test.text1": "Ahoj",
    "test.text2": "Ahoj",
    "test.text3": "Ďakujem",
    "test.text4": "pre",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Zrušiť",
    "general.text2": "súhlasiť",
    "general.text3": "delete",
    "general.text4": "vypúšťa",
    "general.text5": "editovať",
    "general.text6": "aktualizovať",
    "general.text7": "Nahrať obrázok",
    "general.text8": "delete Image",
    "general.text9": "obraz",
    "general.text10": "krmivo",
    "general.text11": "Prihlásiť sa",
    "general.text12": "Prihlásiť Se",
    "general.text13": "Informácie o užívateľovi",
    "general.text14": "Odhlásiť sa",
    "general.text15": "Súbor by mal byť",
    "general.text16": "a menej ako",
    "general.text17": "heslo",
    "general.text18": "Obľúbené príspevky",
    "general.text19": "príspevky",
    "general.text20": "snímky",
    "general.text21": "videá",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nový príspevok",
    "feed.text2": "Ukázať Užívateľské príspevky",
    "feed.text3": "Zobraziť príspevky",
    "feed.text4": "vyhliadka",
    "feed.text5": "editovať",
    "feed.text6": "vymazať",
    "feed.text7": "Je nie je žiadny problém so zmazať príspevok úplne?",
    "feed.text8": "pridané",
    "feed.text9": "Vytvoril",
    "feed.text10": "nadpis",
    "feed.text11": "obraz",
    "feed.text12": "obsah",
    "feed.text13": "verejnosť",
    "feed.text14": "súkromné",
    "feed.text15": "Hlava pre príspevok",
    "feed.text16": "Obsah por príspevok",
    "feed.text17": "vymazať",
    "feed.text18": "Je to nie je problém mazanie snímky úplne?",
    "feed.text19": "vypúšťa",
    "feed.text20": "Media Súbor by mal byť jpg, jpeg, png alebo MP4 súborov, a menej ako 3 MB",
    "feed.text21": "verejný príspevok",
    "feed.text22": "postprimárna",
    "feed.text23": "Zadajte pre vyhľadávanie ....",
    "feed.text24": "Hľadať užívateľa ...",
    "__2": "",
    "userInfo.text1": "Informácie o užívateľovi",
    "userInfo.text2": "názov",
    "userInfo.text3": "obraz",
    "userInfo.text4": "dátum vytvorenia",
    "userInfo.text5": "Je to nie je problém mazanie snímky úplne?",
    "userInfo.text6": "Nový názov ...",
    "userInfo.text7": "Obrázok by mal byť jpg, jpeg, alebo png súborov, a menej ako 1 MB",
    "userInfo.text8": "tmavé Mode",
    "userInfo.text9": "o User",
    "userInfo.text10": "ďalšie informácie o vás, popis bio atď ... (menej ako 300 znakov)",
    "userInfo.text11": "Jazyk",
    "userInfo.text12": "zmena",
    "userInfo.text13": "oznámenia",
    "__3": "",
    "auth.text1": "Tvoj email",
    "auth.text2": "Tvoje meno",
    "auth.text3": "heslo",
    "auth.text4": "Heslo by malo mať 8 až 20 znakov, aspoň jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak (@ $!% *? A).",
    "auth.text5": "Prihlásiť Se",
    "auth.text6": "Prihlásiť sa",
    "auth.text7": "Zabudnuté heslo",
    "auth.text8": "Registrácia Page",
    "auth.text9": "E-mailová adresa pre resetovanie hesla",
    "auth.text10": "poslať email",
    "auth.text11": "Overenie zlyhalo. Uistite sa, že e-mailová adresa nie je používaný ešte!",
    "auth.text12": "Prihlásenie užívateľa zlyhalo!",
    "auth.text13": "Neplatné heslo bolo zadané",
    "auth.text14": "Zadaná e-mailová adresa nebola nájdená",
    "auth.text15": "Vytvorenie používateľa zlyhalo!",
    "auth.text16": "E-mailová adresa je už používaný iným účtom",
    "auth.text17": "Ukázať zadané heslo",
    "auth.text18": "skryť Heslo",
    "auth.text19": "e-mail pre hesla zvyšok bol odoslaný",
    "auth.text20": "Enterd email nebol nájdený",
    "auth.text21": "Úspech resetovanie hesla",
    "auth.text22": "resetovanie hesla zlyhal, prosím, prejdite na prihlasovaciu stránku a poslať e-mail pre resetovanie hesla znovu",
    "auth.text23": "Stránka resetovanie hesla",
    "auth.text24": "Nové heslo",
    "auth.text25": "Obnoviť heslo",
    "auth.text26": "Stránka je neplatný alebo heslo je už zmenený",
    "auth.text27": "Odosielanie e-mailu pre resetovanie hesla zlyhalo, skontrolujte e-mailovej adresy",
    "auth.text28": "Email overenie stránky",
    "auth.text29": "Email overenie úspešnosti, prejdite na prihlasovaciu stránku je uvedené vyššie",
    "auth.text30": "Overenie e-mailu zlyhalo. Táto stránka je vypršal, alebo overenie je už dokončená.",
    "auth.text31": "Overovací e-mail bol odoslaný. Plese skontrolovať svoj e-mail a overiť. (Tiež chack vo spam)",
    "auth.text32": "Overenie e-mailu je nutné prihlásenie. Prosím, pošlite e-mail na overenie vašej e-mailovej adresy.",
    "auth.text33": "Odoslať e-mail pre overenie",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "krmivo",
    "nav.text2": "Prihlásiť sa",
    "nav.text3": "Prihlásiť Se",
    "nav.text4": "Informácie o užívateľovi",
    "nav.text5": "Odhlásiť sa",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Stránka nenájdená",
    "notFound.text2": "prejsť na stránku krmív",
    "notFound.text3": "prejsť na prihlasovaciu stránku",
    "notFound.text4": "Kam ísť dnes?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Zrušiť",
    "comment.text2": "delete",
    "comment.text3": "Pridať komentár",
    "comment.text4": "show Reply",
    "comment.text5": "Celkom",
    "comment.text6": "písať odpoveď",
    "comment.text7": "Skryť odpoveď",
    "comment.text8": "Komentáre",
    "comment.text9": "Je to nie je problém odstrániť tento komentár a odpovede na tento komentár úplne?",
    "comment.text10": "Je to nie je problém tento komentár zmazať úplne?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Pridať do obľúbených položiek Post",
    "follow.text2": "Odstrániť z obľúbeného príspevku",
    "follow.text3": "Pridať k obľúbeným užívateľa",
    "follow.text4": "Odstrániť z obľúbeného užívateľa",
    "follow.text5": "zobraziť príspevky",
    "follow.text6": "Tvoj obľúbený Užívatelia",
    "follow.text7": "Post Obľúbené Užívatelia",
    "__11": "",
    "privacyPolicy.text1": "Zásady ochrany osobných údajov",
    "privacyPolicy.text2": "Tieto pravidlá môžu byť aktualizované alebo revidované bez predchádzajúceho upozornenia. Je zodpovednosťou užívateľa, aby ostali informovaní o zmenách zásad ochrany súkromia. Vezmite na vedomie ďalším otázkam ochrany osobných údajov, ktoré môžu mať vplyv na:",
    "privacyPolicy.text3": "Táto webová stránka by mohla byť ohrozená.",
    "privacyPolicy.text4": "Počítač môže byť ohrozený.",
    "privacyPolicy.text5": "Tento web je hosťovaný v Firebase a DigitalOcean infraštruktúry. Môžu a majú svoje vlastné sledovacie systémy na svojich serveroch. Tieto služby majú svoje vlastné zásady ochrany osobných údajov a nie sú pokryté týmito zásadami ochrany súkromia.",
    "privacyPolicy.text6": "Táto webová stránka ukladá e-maily, šifrovaná podoba heslá a ďalšie vstupy užívateľov na hostovaných Firebase a DigitalOcean infraštruktúry.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Podmienky používania",
    "termsOfUse.text2": "súkromia",
    "termsOfUse.text3": "Vaše súkromie je pre nás dôležité. Prečítajte si prosím",
    "termsOfUse.text4": "zásady ochrany osobných údajov",
    "termsOfUse.text5": "Pre viac informácií.",
    "termsOfUse.text6": "Vaše vlastné zodpovednosti",
    "termsOfUse.text7": "Vy, užívateľ, výhradne zodpovedný za svoj vlastný súlade so zákonmi a daniach vo vašej jurisdikcii. Nesiete plnú zodpovednosť za svoje vlastnej bezpečnosti.",
    "termsOfUse.text8": "vylúčenie záruky",
    "termsOfUse.text9": "Tieto stránky sú poskytované \"AKO JE\", BEZ ZÁRUKY ALEBO PODMIENKY AKÉHOKOĽVEK DRUHU.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Štart Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "online používatelia",
    "videoTalk.text4": "začať hovoriť",
    "videoTalk.text5": "Vaša sa snaží hovoriť",
    "videoTalk.text6": "Žiadny iný on-line užívateľ existuje",
    "videoTalk.text7": "hovoríte",
    "videoTalk.text8": "začal hovoriť",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "vkladanie textu ....",
    "videoTalk.text11": "Ste stále hovorí. Chcete prestať hovoriť?",
    "videoTalk.text12": "Volajúci používateľ odpojený",
    "videoTalk.text13": "Používateľ vám volá. Myslíte si, prijať túto výzvu?",
    "videoTalk.text14": "odmietnutie hovoru",
    "videoTalk.text15": "vyhľadávať užívateľa online ...",
    "videoTalk.text16": "vyhľadávať užívateľa ...",
    "__14": "",
    "groupTalk.text1": "tvorca",
    "groupTalk.text2": "popis",
    "groupTalk.text3": "dátum vytvorenia",
    "groupTalk.text4": "Upraviť informácie o skupine",
    "groupTalk.text5": "Je to nie je problém, aby vám odstrániť z členov tejto skupiny?",
    "groupTalk.text6": "Odchádza z členských",
    "groupTalk.text7": "Pridať sa do skupiny",
    "groupTalk.text8": "Ukázať Členovia skupiny",
    "groupTalk.text9": "Chcete sa pridať k tejto skupine?",
    "groupTalk.text10": "Názov skupiny",
    "groupTalk.text11": "Späť na zoznam",
    "groupTalk.text12": "potvrďte zadania",
    "groupTalk.text13": "Vytvoriť skupinu",
    "groupTalk.text14": "skupina aktualizácia",
    "groupTalk.text15": "Exit zo skupiny",
    "groupTalk.text16": "Prejsť na domovskú stránku Prihlásenie",
    "groupTalk.text17": "Názov skupiny (5-100 znakov)",
    "groupTalk.text18": "popis skupiny (menej ako 500 znakov)"
  },
  {
    "LANGUAGE": "Slovenian",
    "CODE": "sl",
    "test.text1": "zdravo",
    "test.text2": "Hi",
    "test.text3": "Hvala vam",
    "test.text4": "zemljevid",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Preklic",
    "general.text2": "Sprejmi",
    "general.text3": "Izbriši",
    "general.text4": "Izbrisano",
    "general.text5": "Uredi",
    "general.text6": "Nadgradnja",
    "general.text7": "Naloži sliko",
    "general.text8": "Izbriši sliko",
    "general.text9": "Slika",
    "general.text10": "feed",
    "general.text11": "Vpiši se",
    "general.text12": "Vpis",
    "general.text13": "Informacije o uporabniku",
    "general.text14": "Odjava",
    "general.text15": "Datoteka mora biti",
    "general.text16": "in manj kot",
    "general.text17": "geslo",
    "general.text18": "Priljubljene Objave",
    "general.text19": "Prispevkov",
    "general.text20": "slike",
    "general.text21": "Video posnetki",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nova objava",
    "feed.text2": "Prikaži uporabnikov objav",
    "feed.text3": "prikaži Prispevkov",
    "feed.text4": "pogled",
    "feed.text5": "Uredi",
    "feed.text6": "brisanje",
    "feed.text7": "Je ni problem za brisanje mesto povsem?",
    "feed.text8": "Avtor",
    "feed.text9": "Ustvaril",
    "feed.text10": "Naslov",
    "feed.text11": "Slika",
    "feed.text12": "vsebina",
    "feed.text13": "javno",
    "feed.text14": "zasebno",
    "feed.text15": "Naslov za pošto",
    "feed.text16": "Vsebina por po",
    "feed.text17": "brisanje",
    "feed.text18": "Je ni problem za brisanje slike popolnoma?",
    "feed.text19": "Izbrisano",
    "feed.text20": "Mediji datoteke mora biti jpg, jpeg, png ali mp4 datoteke, in manj kot 3MB",
    "feed.text21": "javne objave",
    "feed.text22": "zasebna objava",
    "feed.text23": "Tip za iskanje ....",
    "feed.text24": "Iskanje uporabnika ...",
    "__2": "",
    "userInfo.text1": "Informacije o uporabniku",
    "userInfo.text2": "ime",
    "userInfo.text3": "slika",
    "userInfo.text4": "Datum nastanka",
    "userInfo.text5": "Je ni problem za brisanje slike popolnoma?",
    "userInfo.text6": "Novo ime ...",
    "userInfo.text7": "slikovna datoteka mora biti jpg, jpeg, ali PNG, in manj kot 1 MB",
    "userInfo.text8": "Dark način",
    "userInfo.text9": "O Uporabnik",
    "userInfo.text10": "dodatne informacije o vas, opis bio itd ... (manj kot 300 znakov)",
    "userInfo.text11": "Jezik",
    "userInfo.text12": "Spremeni",
    "userInfo.text13": "obvestilo",
    "__3": "",
    "auth.text1": "Vaš e-poštni naslov",
    "auth.text2": "Tvoje ime",
    "auth.text3": "geslo",
    "auth.text4": "Geslo mora biti 8 do 20 znakov, vsaj eno veliko črko, eno malo črko, eno številko in eno posebno znakov (@ $!% *? &).",
    "auth.text5": "Vpis",
    "auth.text6": "Vpiši se",
    "auth.text7": "pozabite geslo",
    "auth.text8": "Prijava Stran",
    "auth.text9": "E-poštni naslov za ponastavitev gesla",
    "auth.text10": "Pošlji sporočilo",
    "auth.text11": "Preverjanje ni uspelo. Prepričajte se, da je e-poštni naslov še ni uporabljen!",
    "auth.text12": "Uporabnik Prijava ni uspela!",
    "auth.text13": "Neveljavna Geslo je bilo Vpis",
    "auth.text14": "Vnesli e-poštni naslov ni bilo mogoče najti",
    "auth.text15": "Ustvarjanje Uporabnik ni uspelo!",
    "auth.text16": "E-poštni naslov je že v uporabi drug račun",
    "auth.text17": "Prikaži uveljavite geslo",
    "auth.text18": "Skrij geslo",
    "auth.text19": "e-poštni naslov za geslo počitek je bil poslan",
    "auth.text20": "Vpiše e-pošte ni bilo mogoče najti",
    "auth.text21": "ponastavitev gesla uspeh",
    "auth.text22": "ponastavitev gesla ni uspelo, Prosimo, pojdite na stran za prijavo in pošiljanje e-pošte za ponastavitev gesla znova",
    "auth.text23": "Ponastavitev gesla stran",
    "auth.text24": "novo geslo",
    "auth.text25": "Ponastavitev gesla",
    "auth.text26": "Stran je veljavna ali geslo je že spremenila",
    "auth.text27": "Pošiljanje e-pošte za ponastavitev gesla ni uspelo, preverite e-poštni naslov",
    "auth.text28": "stran za preverjanje e-poštni naslov",
    "auth.text29": "E-pošta preverjanje uspešnosti, pojdite na stran za prijavo prikazano zgoraj",
    "auth.text30": "Preverjanje e-poštni ni uspelo. Ta stran je potekel, ali preverjanje je že končal.",
    "auth.text31": "Preverjanje pošte je bilo poslano. Plese preverite e-pošto in preveriti. (Tudi chack v spam pošto)",
    "auth.text32": "Preverjanje e je potrebno za vpis. Prosim, pošljite po e-pošti za potrditev na vaš e-poštni naslov.",
    "auth.text33": "Pošlji e-pošto za potrditev",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "feed",
    "nav.text2": "Vpiši se",
    "nav.text3": "Vpis",
    "nav.text4": "Informacije o uporabniku",
    "nav.text5": "Odjava",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Stran ni najdena",
    "notFound.text2": "pojdi na stran krme",
    "notFound.text3": "pojdite na stran za prijavo",
    "notFound.text4": "Kam greš danes?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Preklic",
    "comment.text2": "Izbriši",
    "comment.text3": "Objavi komentar",
    "comment.text4": "Prikaži Odgovori",
    "comment.text5": "skupaj",
    "comment.text6": "Napiši odgovor",
    "comment.text7": "Skrij Odgovori",
    "comment.text8": "Komentarji",
    "comment.text9": "Je ni problem izbrisati ta komentar in odgovore tega komentarja v celoti?",
    "comment.text10": "Je ni problem, da popolnoma izbrisati ta komentar?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Dodaj Najljubši Post",
    "follow.text2": "Izbriši iz najljubše mesto",
    "follow.text3": "Dodaj med najljubše uporabnika",
    "follow.text4": "Izbriši iz priljubljenega uporabnika",
    "follow.text5": "Prikaži objave",
    "follow.text6": "Vaš najljubši uporabniki",
    "follow.text7": "Objavi Priljubljeni uporabniki",
    "__11": "",
    "privacyPolicy.text1": "Pravilnik o zasebnosti",
    "privacyPolicy.text2": "Ta politika se lahko posodobi ali popravi brez predhodnega obvestila. To je odgovornost uporabnika za bivanje obveščen o spremembah pravilnika o zasebnosti. Bodite pozorni na druga vprašanja zasebnosti, ki vas lahko vplivajo na:",
    "privacyPolicy.text3": "Ta spletna stran je morda ogrožen.",
    "privacyPolicy.text4": "Računalnik je morda ogrožen.",
    "privacyPolicy.text5": "Ta spletna stran gostuje na Firebase in DigitalOcean infrastrukture. Ti se lahko in se imajo svoje sledilne sisteme na njihovih strežnikih. Te storitve imajo svoje pravilnike o zasebnosti in niso zajete v tem pravilniku o zasebnosti.",
    "privacyPolicy.text6": "Ta spletna stran shranjuje elektronska pošta, šifrirani obliki geslom, in drugi vložki uporabnikov na gostuje Firebase in DigitalOcean infrastrukture.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Pogoji uporabe",
    "termsOfUse.text2": "Zasebnost",
    "termsOfUse.text3": "Vaša zasebnost je za nas pomembno. Preberite",
    "termsOfUse.text4": "pravilnik o zasebnosti",
    "termsOfUse.text5": "za več informacij.",
    "termsOfUse.text6": "Lastne odgovornosti",
    "termsOfUse.text7": "Vi, uporabnik, so izključno odgovorni za zagotavljanje lastne skladnosti z zakoni in davki v vaši pristojnosti. Sami ste odgovorni za svojo varnost.",
    "termsOfUse.text8": "Pogoji garancije",
    "termsOfUse.text9": "Ta stran je na voljo na \"KOT JE\", BREZ JAMSTEV ALI KAKRŠNIH KOLI POGOJEV.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Začetek Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Prisotni uporabniki",
    "videoTalk.text4": "Začni govoriti",
    "videoTalk.text5": "Vaš poskušamo govoriti",
    "videoTalk.text6": "Noben drug spletni uporabnik obstaja",
    "videoTalk.text7": "Vi ste v pogovoru z",
    "videoTalk.text8": "Začeli ste govoriti",
    "videoTalk.text9": "Prikaži Besedilo Pogovor",
    "videoTalk.text10": "vnos besedila ....",
    "videoTalk.text11": "Ti so še vedno govorimo. Ali želite, da neha govoriti?",
    "videoTalk.text12": "Klicanje uporabnik prekinjena",
    "videoTalk.text13": "Uporabnik vas kliče. Ali sprejemate ta klic?",
    "videoTalk.text14": "zavrnete klic",
    "videoTalk.text15": "iskanje spletnega uporabnika ...",
    "videoTalk.text16": "iskanje uporabnika ...",
    "__14": "",
    "groupTalk.text1": "ustvarjalec",
    "groupTalk.text2": "Opis",
    "groupTalk.text3": "Datum nastanka",
    "groupTalk.text4": "Urejanje podatkov o skupini",
    "groupTalk.text5": "Je ni problem, da te izbrisati iz članov te skupine?",
    "groupTalk.text6": "Pustite iz držav",
    "groupTalk.text7": "Pridruži se skupini",
    "groupTalk.text8": "Prikaži Člani skupine",
    "groupTalk.text9": "Ali se želite pridružiti tej skupini?",
    "groupTalk.text10": "Ime skupine",
    "groupTalk.text11": "Nazaj na seznam",
    "groupTalk.text12": "Potrdite vaš vnos",
    "groupTalk.text13": "Ustvari skupino",
    "groupTalk.text14": "Skupina Update",
    "groupTalk.text15": "Izhod iz skupine",
    "groupTalk.text16": "Pojdite na domačo stran, da Prijava",
    "groupTalk.text17": "filter (5-100 znakov)",
    "groupTalk.text18": "Opis stroja (manj kot 500 znakov)"
  },
  {
    "LANGUAGE": "Albanian",
    "CODE": "sq",
    "test.text1": "Përshëndetje",
    "test.text2": "Hi",
    "test.text3": "Faleminderit",
    "test.text4": "hartë",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "anuloj",
    "general.text2": "pranoj",
    "general.text3": "fshini",
    "general.text4": "Deleted",
    "general.text5": "redaktoj",
    "general.text6": "Update",
    "general.text7": "Ngarkoni imazhin",
    "general.text8": "fshini Image",
    "general.text9": "imazh",
    "general.text10": "ushqim",
    "general.text11": "hyrje",
    "general.text12": "Regjistrohu",
    "general.text13": "Të dhëna Përdoruesi",
    "general.text14": "Shkyç",
    "general.text15": "Dosja duhet të jetë",
    "general.text16": "dhe më pak se",
    "general.text17": "fjalëkalim",
    "general.text18": "Posts e preferuar",
    "general.text19": "Posts",
    "general.text20": "images",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Trego Postime",
    "feed.text3": "Trego Postimet",
    "feed.text4": "pamje",
    "feed.text5": "redaktoj",
    "feed.text6": "fshij",
    "feed.text7": "A nuk është problem për postin fshini plotësisht?",
    "feed.text8": "postuar nga",
    "feed.text9": "krijuar nga",
    "feed.text10": "titull",
    "feed.text11": "imazh",
    "feed.text12": "përmbajtje",
    "feed.text13": "publik",
    "feed.text14": "privat",
    "feed.text15": "Titulli për postin",
    "feed.text16": "Përmbajtja Post por",
    "feed.text17": "fshij",
    "feed.text18": "A është e nuk ka problem me imazhin fshini plotësisht?",
    "feed.text19": "Deleted",
    "feed.text20": "Media dokumentit duhet të jetë jpg, jpeg, png ose mp4 file, dhe më pak se 3MB",
    "feed.text21": "Post publik",
    "feed.text22": "Post private",
    "feed.text23": "Lloji per Kërko ....",
    "feed.text24": "Kërko përdoruesin ...",
    "__2": "",
    "userInfo.text1": "Informacione përdorues",
    "userInfo.text2": "emër",
    "userInfo.text3": "imazh",
    "userInfo.text4": "Data e krijimit",
    "userInfo.text5": "A është e nuk ka problem me imazhin fshini plotësisht?",
    "userInfo.text6": "Emri i ri ...",
    "userInfo.text7": "Image file duhet të jetë jpg, jpeg, png ose fotografi, dhe më pak se 1MB",
    "userInfo.text8": "Mode errët",
    "userInfo.text9": "Rreth User",
    "userInfo.text10": "informacion shtesë në lidhje me ju, përshkrimi bio, etj ... (më pak se 300 karaktere)",
    "userInfo.text11": "Gjuhe",
    "userInfo.text12": "ndryshim",
    "userInfo.text13": "Njoftim",
    "__3": "",
    "auth.text1": "Juaj E-mail",
    "auth.text2": "Emri juaj",
    "auth.text3": "fjalëkalim",
    "auth.text4": "Fjalëkalimi duhet të jetë 8 deri në 20 karaktere, të paktën një letër uppercase, një letër të vogla, një numër dhe një karakter të veçantë (@ $!% *? &).",
    "auth.text5": "Regjistrohu",
    "auth.text6": "hyrje",
    "auth.text7": "Harroj Fjalekalimin",
    "auth.text8": "Regjistrohu Page",
    "auth.text9": "E-mail adresa për Password Reset",
    "auth.text10": "Dërgoni një email",
    "auth.text11": "Validation dështuar. Sigurohuni që adresa email nuk është përdorur ende!",
    "auth.text12": "User login dështuar!",
    "auth.text13": "Fjalëkalimi pavlefshme u Regjistruar",
    "auth.text14": "Hyri në adresën e emailit nuk u gjet",
    "auth.text15": "Krijimi User dështuar!",
    "auth.text16": "Adresa e postës elektronike është tashmë në përdorim nga një tjetër llogari",
    "auth.text17": "Trego Fjalëkalimi juaj",
    "auth.text18": "Hide Password",
    "auth.text19": "email për fjalëkalim tjetër u dërgua",
    "auth.text20": "Enterd email nuk u gjet",
    "auth.text21": "Suksesi Password Reset",
    "auth.text22": "Password Reset dështuar, ju lutem shkoni tek faqja e identifikimit dhe email dërgoj Password Reset përsëri",
    "auth.text23": "Fjalëkalimi faqe rishkruhet",
    "auth.text24": "Fjalëkalim i ri",
    "auth.text25": "Rivendosni fjalëkalimin",
    "auth.text26": "Page është e pavlefshme ose Password është ndryshuar tashmë",
    "auth.text27": "Dërguar një email për Password Reset dështuar, ju lutem kontrolloni adresën email",
    "auth.text28": "Email faqe verifikimi",
    "auth.text29": "Email verifikimit sukses, ju lutem shkoni tek faqja e identifikimit treguar më lart",
    "auth.text30": "Verifikimi Email dështuar. Kjo faqe ka skaduar, ose verifikimi tashmë është përfunduar.",
    "auth.text31": "Verifikimi Mail u dërgua. Plese kontrolloni email tuaj dhe të verifikojë. (Gjithashtu kontrolloni përshtatshmërinë në mail spam)",
    "auth.text32": "Email Verifikimi është e nevojshme të identifikoheni. Ju lutem dërgoni me email për verifikim në adresën tuaj të postës elektronike.",
    "auth.text33": "Dërgo Email për verifikim",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "ushqim",
    "nav.text2": "hyrje",
    "nav.text3": "Regjistrohu",
    "nav.text4": "Të dhëna Përdoruesi",
    "nav.text5": "Shkyç",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Faqja nuk u gjet",
    "notFound.text2": "shkoni në faqen e ushqimit",
    "notFound.text3": "shkoni tek faqja e identifikimit",
    "notFound.text4": "Ku do të shkoni sot?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "anuloj",
    "comment.text2": "fshini",
    "comment.text3": "Post Comment",
    "comment.text4": "Shfaqe përgjigjen",
    "comment.text5": "total",
    "comment.text6": "Shkruaj Përgjigju",
    "comment.text7": "Hide Përgjigju",
    "comment.text8": "Comments",
    "comment.text9": "A është e nuk ka problem ta fshini këtë koment dhe përgjigje të këtij komenti plotësisht?",
    "comment.text10": "A është e nuk ka problem ta fshini këtë koment plotësisht?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Shtoni në të preferuar Post",
    "follow.text2": "Fshij nga posti i preferuar",
    "follow.text3": "Shto tek përdoruesit preferuar",
    "follow.text4": "Fshij nga përdorues të preferuar",
    "follow.text5": "Trego postimet",
    "follow.text6": "Përdoruesit tuaj të preferuar",
    "follow.text7": "Përdoruesit Post preferuar",
    "__11": "",
    "privacyPolicy.text1": "Politika e privatësisë",
    "privacyPolicy.text2": "Kjo politikë mund të përditësuar ose ndryshuar pa paralajmërim. Është përgjegjësi e përdoruesit të qëndrojnë të informuar në lidhje me ndryshimet Politika e privatësisë. Marrë parasysh të çështjeve të tjera të privatësisë që mund të ndikojnë ju:",
    "privacyPolicy.text3": "Kjo faqe e internetit mund të rrezikohet.",
    "privacyPolicy.text4": "Kompjuteri juaj mund të rrezikohet.",
    "privacyPolicy.text5": "Kjo faqe e internetit është pritur në Firebase dhe DigitalOcean infrastrukturave. Ata mund dhe kanë sistemet e tyre të përcjelljes në serverat e tyre. Këto shërbime kanë politikat e tyre të privatësisë dhe ata nuk janë të mbuluara nga kjo privacy policy.",
    "privacyPolicy.text6": "Ky email dyqanet e internetit, formë të koduar e fjalëkalimin, dhe inputeve të tjera të përdoruesve në priti Firebase dhe DigitalOcean infrastrukturave.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Kushtet e përdorimit",
    "termsOfUse.text2": "intimitet",
    "termsOfUse.text3": "Privacy juaj është i rëndësishëm për ne. Ju lutemi lexoni",
    "termsOfUse.text4": "Politika e privatësisë",
    "termsOfUse.text5": "për më shumë informacion.",
    "termsOfUse.text6": "Përgjegjësitë e tu",
    "termsOfUse.text7": "Ju, përdoruesi, janë vetëm përgjegjës për të siguruar pajtueshmërinë tuaj me ligjet dhe taksat në juridiksionin tuaj. Ju jeni përgjegjës vetëm për sigurinë tuaj.",
    "termsOfUse.text8": "Mohim i garancisë",
    "termsOfUse.text9": "Kjo faqe është dhënë në një \"siç është\", PA GARANCI ose kushteve të çdo lloji.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "fillimi Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Users Online",
    "videoTalk.text4": "Filloni për të folur",
    "videoTalk.text5": "Tuaj janë duke u përpjekur për të folur",
    "videoTalk.text6": "Asnjë përdorues të tjerë në internet ekziston",
    "videoTalk.text7": "Ju jeni duke folur për",
    "videoTalk.text8": "Ju keni filluar për të folur",
    "videoTalk.text9": "Trego Talk Text",
    "videoTalk.text10": "input text ....",
    "videoTalk.text11": "Ju jeni ende duke folur. A doni të ndalet duke folur?",
    "videoTalk.text12": "Thirrja përdorues shkëputur",
    "videoTalk.text13": "përdorues është duke bërë thirrje ju. A e pranoni këtë thirrje?",
    "videoTalk.text14": "refuzojë thirrjen tuaj",
    "videoTalk.text15": "kërko në internet përdorues ...",
    "videoTalk.text16": "kërko përdorues ...",
    "__14": "",
    "groupTalk.text1": "krijues",
    "groupTalk.text2": "Përshkrim",
    "groupTalk.text3": "Data e krijimit",
    "groupTalk.text4": "info Edit grup",
    "groupTalk.text5": "A është asnjë problem të fshini ju nga anëtarët e këtij grupi?",
    "groupTalk.text6": "Lini nga anëtar",
    "groupTalk.text7": "Bashkohu ne grup",
    "groupTalk.text8": "Shfaq Anëtarët Grupi",
    "groupTalk.text9": "A doni të bashkohet me këtë grup?",
    "groupTalk.text10": "Emri i grupit",
    "groupTalk.text11": "Kthehu në listë",
    "groupTalk.text12": "Konfirmo kontributin tuaj",
    "groupTalk.text13": "Krijo grupin",
    "groupTalk.text14": "Update Group",
    "groupTalk.text15": "Dil nga Grupi",
    "groupTalk.text16": "Shko në Faqe Hyrëse të identifikoheni",
    "groupTalk.text17": "Emri i grupit (5-100 karaktere)",
    "groupTalk.text18": "Përshkrimi grup (më pak se 500 karaktere)"
  },
  {
    "LANGUAGE": "Swedish",
    "CODE": "sv",
    "test.text1": "Hallå",
    "test.text2": "Hej",
    "test.text3": "Tack",
    "test.text4": "Karta",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Avbryt",
    "general.text2": "Acceptera",
    "general.text3": "Radera",
    "general.text4": "raderade",
    "general.text5": "Redigera",
    "general.text6": "Uppdatering",
    "general.text7": "Ladda upp bild",
    "general.text8": "Radera bild",
    "general.text9": "Bild",
    "general.text10": "Utfodra",
    "general.text11": "Logga in",
    "general.text12": "Bli Medlem",
    "general.text13": "användarinformation",
    "general.text14": "Logga ut",
    "general.text15": "filen ska vara",
    "general.text16": "och mindre än",
    "general.text17": "Lösenord",
    "general.text18": "Favorit inlägg",
    "general.text19": "inlägg",
    "general.text20": "Bilder",
    "general.text21": "videoklipp",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Nytt brev",
    "feed.text2": "Visa användar Inlägg",
    "feed.text3": "Visa inlägg",
    "feed.text4": "se",
    "feed.text5": "redigera",
    "feed.text6": "radera",
    "feed.text7": "Är inget problem att radera inlägg helt?",
    "feed.text8": "Postat av",
    "feed.text9": "Skapad av",
    "feed.text10": "Titel",
    "feed.text11": "Bild",
    "feed.text12": "Innehåll",
    "feed.text13": "offentlig",
    "feed.text14": "privat",
    "feed.text15": "Titel för post",
    "feed.text16": "Innehåll por post",
    "feed.text17": "radera",
    "feed.text18": "Är det inget problem att radera bilden helt och hållet?",
    "feed.text19": "raderade",
    "feed.text20": "Mediefil bör vara jpg, jpeg, png eller mp4, och mindre än 3MB",
    "feed.text21": "offentliga inlägg",
    "feed.text22": "privat post",
    "feed.text23": "Skriv för Search ....",
    "feed.text24": "Sök användare ...",
    "__2": "",
    "userInfo.text1": "användar information",
    "userInfo.text2": "namn",
    "userInfo.text3": "bild",
    "userInfo.text4": "Skapelsedagen",
    "userInfo.text5": "Är det inget problem att radera bilden helt och hållet?",
    "userInfo.text6": "Nytt namn ...",
    "userInfo.text7": "Bildfil ska vara jpg, jpeg eller png-fil, och mindre än 1 MB",
    "userInfo.text8": "mörk Läge",
    "userInfo.text9": "om användare",
    "userInfo.text10": "Ytterligare information om dig, beskrivning bio osv ... (mindre än 300 tecken)",
    "userInfo.text11": "Språk",
    "userInfo.text12": "Förändra",
    "userInfo.text13": "Underrättelse",
    "__3": "",
    "auth.text1": "Din email",
    "auth.text2": "Ditt namn",
    "auth.text3": "Lösenord",
    "auth.text4": "Lösenordet ska vara 8 till 20 tecken, minst en stor bokstav, en liten bokstav, en siffra och ett specialtecken (@ $!% *? &).",
    "auth.text5": "Bli Medlem",
    "auth.text6": "Logga in",
    "auth.text7": "Glöm lösenord",
    "auth.text8": "Registrera Page",
    "auth.text9": "E-postadress för återställning av lösenord",
    "auth.text10": "skicka epost",
    "auth.text11": "Validering misslyckades. Se till att e-postadressen inte används ännu!",
    "auth.text12": "Användarinloggning misslyckades!",
    "auth.text13": "Ogiltigt lösenord angavs",
    "auth.text14": "In e-postadressen kunde inte hittas",
    "auth.text15": "Skapande av användare misslyckades!",
    "auth.text16": "E-postadressen används redan av ett annat konto",
    "auth.text17": "Visa inmatade lösenordet",
    "auth.text18": "Göm Lösenord",
    "auth.text19": "e-post för lösenord resten sändes",
    "auth.text20": "Enterd e hittades inte",
    "auth.text21": "Lösenordsåterställning framgång",
    "auth.text22": "Återställning av lösenord misslyckades, Gå till inloggningssidan och skicka e-post för återställning av lösenord igen",
    "auth.text23": "Återställning av lösenord sida",
    "auth.text24": "nytt lösenord",
    "auth.text25": "Återställ lösenord",
    "auth.text26": "Sidan är ogiltig eller lösenord är redan ändrat",
    "auth.text27": "Att skicka e-post för återställning av lösenord misslyckades, kontrollera e-postadress",
    "auth.text28": "E-postverifiering sida",
    "auth.text29": "E-postverifiering framgång, gå till inloggningssidan som visas ovan",
    "auth.text30": "E-postverifiering misslyckades. Denna sida har upphört att gälla, eller verifiering är redan klar.",
    "auth.text31": "Verifiering Mail har skickats. Plese kolla din e-post och kontrollera. (Även chack i skräppost)",
    "auth.text32": "E-postverifiering krävs för inloggning. Vänligen skicka e-post för verifiering till din e-postadress.",
    "auth.text33": "Skicka e-post för verifiering",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Utfodra",
    "nav.text2": "Logga in",
    "nav.text3": "Bli Medlem",
    "nav.text4": "användarinformation",
    "nav.text5": "Logga ut",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Sidan hittas inte",
    "notFound.text2": "gå till feed sida",
    "notFound.text3": "gå till inloggningssidan",
    "notFound.text4": "Vart går du i dag?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Avbryt",
    "comment.text2": "Radera",
    "comment.text3": "Publicera kommentar",
    "comment.text4": "Visa Svar",
    "comment.text5": "total",
    "comment.text6": "Skriv svar",
    "comment.text7": "Göm Svara",
    "comment.text8": "kommentarer",
    "comment.text9": "Är det inga problem att ta bort den här kommentaren och svar på denna kommentar helt?",
    "comment.text10": "Är det inga problem att ta bort den här kommentaren helt?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Lägg till Favoriter Post",
    "follow.text2": "Ta bort från favorit post",
    "follow.text3": "Lägg till favorit användare",
    "follow.text4": "Ta bort från favorit användare",
    "follow.text5": "Visa inlägg",
    "follow.text6": "Dina favoritanvändare",
    "follow.text7": "Post favoritanvändare",
    "__11": "",
    "privacyPolicy.text1": "Integritetspolicy",
    "privacyPolicy.text2": "Denna policy kan uppdateras eller revideras utan förvarning. Det är användarens ansvar att hålla sig informerad om sekretess politiska förändringar. Ta del av andra integritetsfrågor som kan påverka dig:",
    "privacyPolicy.text3": "Denna webbplats kan äventyras.",
    "privacyPolicy.text4": "Datorn kan äventyras.",
    "privacyPolicy.text5": "Denna webbplats är värd på Firebase och DigitalOcean infrastruktur. De kan och har sina egna tracking system på sina servrar. Dessa tjänster har sina egna sekretesspolicy och de omfattas inte av denna integritetspolicy.",
    "privacyPolicy.text6": "Denna webbplats lagrar e-post, krypterad form av lösenord och andra ingångar på användare vid värd Firebase och DigitalOcean infrastruktur.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Villkor",
    "termsOfUse.text2": "Integritet",
    "termsOfUse.text3": "Din integritet är viktig för oss. Läs",
    "termsOfUse.text4": "integritetspolicy",
    "termsOfUse.text5": "för mer information.",
    "termsOfUse.text6": "Dina egna ansvarsområden",
    "termsOfUse.text7": "Du som användare är själv ansvarig för att se till din egen efterlevnad av lagar och skatter i din jurisdiktion. Du är ensam ansvarig för din egen säkerhet.",
    "termsOfUse.text8": "Garantifriskrivning",
    "termsOfUse.text9": "Denna webbplats tillhandahålls \"SOM ÄR\", UTAN GARANTIER ELLER VILLKOR AV NÅGOT SLAG.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "stopp Connect",
    "videoTalk.text3": "Online-användare",
    "videoTalk.text4": "Börja med att prata",
    "videoTalk.text5": "Din försöker prata",
    "videoTalk.text6": "Ingen annan online-användare finns",
    "videoTalk.text7": "Du pratar med",
    "videoTalk.text8": "Du började prata",
    "videoTalk.text9": "Visa Text Diskussion",
    "videoTalk.text10": "Textinmatning ....",
    "videoTalk.text11": "Du talar fortfarande. Vill du sluta prata?",
    "videoTalk.text12": "Ringa användare frånkopplad",
    "videoTalk.text13": "Användaren ringer dig. Accepterar du det här samtalet?",
    "videoTalk.text14": "avvisa samtalet",
    "videoTalk.text15": "söka på nätet användare ...",
    "videoTalk.text16": "Sök användare ...",
    "__14": "",
    "groupTalk.text1": "skapare",
    "groupTalk.text2": "Beskrivning",
    "groupTalk.text3": "Skapelsedagen",
    "groupTalk.text4": "Redigera gruppinformation",
    "groupTalk.text5": "Är det inga problem att ta bort dig från medlemmarna i denna grupp?",
    "groupTalk.text6": "Lämna från medlemsstat",
    "groupTalk.text7": "Gå med i grupp",
    "groupTalk.text8": "Visa Gruppmedlemmar",
    "groupTalk.text9": "Vill du att gå med i gruppen?",
    "groupTalk.text10": "Grupp namn",
    "groupTalk.text11": "Tillbaka till listan",
    "groupTalk.text12": "Bekräfta inmatning",
    "groupTalk.text13": "Skapa grupp",
    "groupTalk.text14": "Uppdatera Group",
    "groupTalk.text15": "Avsluta Group",
    "groupTalk.text16": "Gå till hemsidan för att logga in",
    "groupTalk.text17": "gruppnamn (5-100 tecken)",
    "groupTalk.text18": "gruppbeskrivning (mindre än 500 tecken)"
  },
  {
    "LANGUAGE": "Swahili",
    "CODE": "sw",
    "test.text1": "Habari",
    "test.text2": "Hi",
    "test.text3": "Asante",
    "test.text4": "ramani",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "kufuta",
    "general.text2": "Kubali",
    "general.text3": "Futa",
    "general.text4": "Imefutwa",
    "general.text5": "Edit",
    "general.text6": "Mwisho",
    "general.text7": "upload Image",
    "general.text8": "Futa Image",
    "general.text9": "Image",
    "general.text10": "feed",
    "general.text11": "Ingia",
    "general.text12": "Jiunge",
    "general.text13": "user Info",
    "general.text14": "Toka",
    "general.text15": "file lazima",
    "general.text16": "na chini ya",
    "general.text17": "password",
    "general.text18": "Favourite Posts",
    "general.text19": "posts",
    "general.text20": "Picha",
    "general.text21": "Video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Onyesha User Posts",
    "feed.text3": "Show Posts",
    "feed.text4": "mtazamo",
    "feed.text5": "hariri",
    "feed.text6": "kufuta",
    "feed.text7": "Je ni hakuna tatizo kwa kufuta post kabisa?",
    "feed.text8": "Imetumwa na",
    "feed.text9": "Imetengenezwa na",
    "feed.text10": "Title",
    "feed.text11": "Image",
    "feed.text12": "maudhui",
    "feed.text13": "umma",
    "feed.text14": "Privat",
    "feed.text15": "Kichwa cha chapisho",
    "feed.text16": "baada maudhui por",
    "feed.text17": "kufuta",
    "feed.text18": "Je, ni hakuna tatizo kwa picha kufuta kabisa?",
    "feed.text19": "Imefutwa",
    "feed.text20": "Vyombo vya habari File lazima jpg, jpeg, png au mp4 faili, na chini ya 3MB",
    "feed.text21": "baada ya umma",
    "feed.text22": "baada ya kibinafsi",
    "feed.text23": "Aina kwa Search ....",
    "feed.text24": "Tafuta User ...",
    "__2": "",
    "userInfo.text1": "Taarifa user",
    "userInfo.text2": "jina",
    "userInfo.text3": "picha",
    "userInfo.text4": "tarehe ya uumbaji",
    "userInfo.text5": "Je, ni hakuna tatizo kwa picha kufuta kabisa?",
    "userInfo.text6": "Jina Jipya ...",
    "userInfo.text7": "Faili ya picha lazima jpg, jpeg, au png faili na chini ya 1MB",
    "userInfo.text8": "Mode Dark",
    "userInfo.text9": "kuhusu User",
    "userInfo.text10": "maelezo ya ziada kuhusu wewe, maelezo bio nk ... (chini ya herufi 300)",
    "userInfo.text11": "lugha",
    "userInfo.text12": "Badilisha",
    "userInfo.text13": "Taarifa",
    "__3": "",
    "auth.text1": "Barua pepe yako",
    "auth.text2": "Jina lako",
    "auth.text3": "password",
    "auth.text4": "Password lazima 8 kwa 20 wahusika, angalau moja uppercase barua, mmoja barua lowercase, namba moja na moja herufi maalum (@ $!% *? &).",
    "auth.text5": "Jiunge",
    "auth.text6": "Ingia",
    "auth.text7": "Kusahau Password",
    "auth.text8": "Jiunge Ukurasa",
    "auth.text9": "E-mail anwani kwa password reset",
    "auth.text10": "kutuma barua pepe",
    "auth.text11": "Validation alishindwa. Hakikisha kuwa anwani ya barua pepe haitumiki bado!",
    "auth.text12": "User login imeshindwa!",
    "auth.text13": "Password batili iliingizwa",
    "auth.text14": "Iliyoingizwa mahali hakuonekana",
    "auth.text15": "uundaji wa mtumiaji imeshindwa!",
    "auth.text16": "anwani ya barua pepe tayari linatumika na akaunti nyingine",
    "auth.text17": "Onyesha Umeingia Password",
    "auth.text18": "Ficha Password",
    "auth.text19": "barua pepe ya mapumziko password alitumwa",
    "auth.text20": "Enterd email hakuonekana",
    "auth.text21": "mafanikio Password reset",
    "auth.text22": "Password reset wameshindwa, Tafadhali kwenda Login ukurasa na kutuma barua pepe kwa ajili password reset tena",
    "auth.text23": "Password ukurasa upya",
    "auth.text24": "New Password",
    "auth.text25": "Rudisha siri",
    "auth.text26": "Ukurasa ni batili au Password tayari iliyopita",
    "auth.text27": "Kutuma barua pepe kwa password reset kumeshindwa, Tafadhali kuangalia barua pepe",
    "auth.text28": "Email ukurasa uhakiki",
    "auth.text29": "Barua pepe ya uthibitisho mafanikio, tafadhali kwenda ukurasa wa kuingia iliyoonyeshwa hapo juu",
    "auth.text30": "Barua pepe ya uthibitisho alishindwa. Ukurasa huu muda wake, au ukaguzi tayari kumaliza.",
    "auth.text31": "Uthibitishaji wa barua pepe iliyotumwa. Plese kuangalia barua pepe yako na kuthibitisha. (Pia chack katika spam pepe)",
    "auth.text32": "Barua pepe ya uthibitisho inahitajika Login. Tafadhali tuma barua pepe kwa ajili ya ukaguzi wa Anuani ya barua pepe yako.",
    "auth.text33": "Tuma barua pepe kwa ajili ya ukaguzi",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "feed",
    "nav.text2": "Ingia",
    "nav.text3": "Jiunge",
    "nav.text4": "user Info",
    "nav.text5": "Toka",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Ukurasa Haukupatikana",
    "notFound.text2": "kwenda kwenye ukurasa kulisha",
    "notFound.text3": "kwenda kwenye ukurasa wa kuingia",
    "notFound.text4": "Wapi wewe kwenda leo?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "kufuta",
    "comment.text2": "Futa",
    "comment.text3": "Chapisha Maoni",
    "comment.text4": "Onyesha Jibu",
    "comment.text5": "jumla",
    "comment.text6": "Write Jibu",
    "comment.text7": "Ficha Jibu",
    "comment.text8": "maoni",
    "comment.text9": "Je, ni hakuna tatizo kufuta maoni haya na majibu ya maoni haya kabisa?",
    "comment.text10": "Je, ni hakuna tatizo kufuta maoni haya kabisa?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Ongeza kwenye orodha ya Posta",
    "follow.text2": "Futa kutoka post favorite",
    "follow.text3": "Kuongeza user favorite",
    "follow.text4": "Futa kutoka user favorite",
    "follow.text5": "show posts",
    "follow.text6": "Watumiaji wako vipenzi",
    "follow.text7": "Post Kipendwa Watumiaji",
    "__11": "",
    "privacyPolicy.text1": "Sera ya faragha",
    "privacyPolicy.text2": "Sera hii inaweza kubadilishwa au marekebisho bila taarifa. Ni wajibu wa mtumiaji kukaa kuhusu mabadiliko sera ya faragha. Dokezo ya masuala mengine ya faragha ambayo inaweza kuathiri wewe:",
    "privacyPolicy.text3": "Tovuti hii inaweza kuathirika.",
    "privacyPolicy.text4": "Kompyuta yako inaweza kuathirika.",
    "privacyPolicy.text5": "Tovuti hii ni mwenyeji kwenye Firebase na DigitalOcean miundombinu. Wao wanaweza na wala kuwa na mifumo yao ya kufuatilia kwenye seva zao. huduma hizo zina sera zao za faragha na wao si kufunikwa na sera hii faragha.",
    "privacyPolicy.text6": "Hii maduka tovuti ya barua pepe, fiche mfumo wa password, na pembejeo nyingine za watumiaji wakati mwenyeji Firebase na DigitalOcean miundombinu.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Masharti ya matumizi",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Faragha yako ni muhimu sana kwetu. tafadhali soma",
    "termsOfUse.text4": "sera ya faragha",
    "termsOfUse.text5": "kwa taarifa zaidi.",
    "termsOfUse.text6": "majukumu yako mwenyewe",
    "termsOfUse.text7": "Wewe, mtumiaji, unawajibika kuhakikisha utekelezaji yako mwenyewe na sheria na kodi katika mamlaka yako. Wewe ni kuwajibika tu kwa ajili ya usalama yako mwenyewe.",
    "termsOfUse.text8": "Kanusho la udhamini",
    "termsOfUse.text9": "Tovuti hii ni zinazotolewa juu ya \"kama ni\", BILA DHAMANA AU MASHARTI YA AINA YOYOTE.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "online Watumiaji",
    "videoTalk.text4": "Kuanza kuzungumza",
    "videoTalk.text5": "Wako ni kujaribu majadiliano",
    "videoTalk.text6": "Hakuna mwingine online user ipo",
    "videoTalk.text7": "Unayeongea",
    "videoTalk.text8": "You kuanza kuzungumza",
    "videoTalk.text9": "Show Nakala Majadiliano",
    "videoTalk.text10": "maandishi ....",
    "videoTalk.text11": "Bado kuzungumza. Je, unataka kuacha kuzungumza?",
    "videoTalk.text12": "Kupiga user disconnected",
    "videoTalk.text13": "user anakuita. Unakubali simu hii?",
    "videoTalk.text14": "kukataa simu yako",
    "videoTalk.text15": "kutafuta online mtumiaji ...",
    "videoTalk.text16": "kutafuta mtumiaji ...",
    "__14": "",
    "groupTalk.text1": "muumba",
    "groupTalk.text2": "Maelezo",
    "groupTalk.text3": "tarehe ya uumbaji",
    "groupTalk.text4": "Maelezo Badilisha kikundi",
    "groupTalk.text5": "Je, ni hakuna tatizo kufuta wewe kutoka kwa wanachama wa kundi hili?",
    "groupTalk.text6": "Acha kutoka kwa Mwanachama",
    "groupTalk.text7": "Kujiunga na Kikundi",
    "groupTalk.text8": "Show Wanachama Group",
    "groupTalk.text9": "Unataka kujiunga na kikundi cha Hii?",
    "groupTalk.text10": "Jina Group",
    "groupTalk.text11": "Rudi kwenye Orodha ya",
    "groupTalk.text12": "Thibitisha mchango wako",
    "groupTalk.text13": "kujenga Group",
    "groupTalk.text14": "Mwisho Group",
    "groupTalk.text15": "Toka kutoka Group",
    "groupTalk.text16": "Kwenda Homepage kwa Login",
    "groupTalk.text17": "Kundi jina (5-100 herufi)",
    "groupTalk.text18": "Kundi maelezo (chini ya herufi 500)"
  },
  {
    "LANGUAGE": "Syriac",
    "CODE": "syr",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Tamil",
    "CODE": "ta",
    "test.text1": "வணக்கம்",
    "test.text2": "வணக்கம்",
    "test.text3": "நன்றி",
    "test.text4": "கண்டறிவதில்",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "ரத்து",
    "general.text2": "ஏற்கவும்",
    "general.text3": "அழி",
    "general.text4": "நீக்கப்பட்ட",
    "general.text5": "தொகு",
    "general.text6": "புதுப்பிக்கப்பட்டது",
    "general.text7": "படத்தை பதிவேற்றம் செய்யவும்",
    "general.text8": "நீக்கு பட",
    "general.text9": "பட",
    "general.text10": "ஊட்டம்",
    "general.text11": "உள்நுழைய",
    "general.text12": "பதிவுசெய்தல்",
    "general.text13": "பயனர் தகவல்",
    "general.text14": "வெளியேறு",
    "general.text15": "கோப்பாக இருக்க வேண்டும்",
    "general.text16": "காட்டிலும் மிகவும் குறைவாகவே",
    "general.text17": "கடவுச்சொல்",
    "general.text18": "பிடித்த படம்",
    "general.text19": "இடுகைகள்",
    "general.text20": "படங்களை",
    "general.text21": "வீடியோக்கள்",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "புதிய பதவி",
    "feed.text2": "பயனர் இடுகைகள் காட்டு",
    "feed.text3": "காட்டு இடுகைகள்",
    "feed.text4": "பார்வை",
    "feed.text5": "தொகு",
    "feed.text6": "அழி",
    "feed.text7": "முற்றிலும் நீக்கு பதவியை எந்த சிக்கலாக உள்ளது?",
    "feed.text8": "பதிவிட்டவர்",
    "feed.text9": "மூலம் உருவாக்கப்பட்டது",
    "feed.text10": "தலைப்பு",
    "feed.text11": "பட",
    "feed.text12": "உள்ளடக்கம்",
    "feed.text13": "பொது",
    "feed.text14": "தனியார்",
    "feed.text15": "பதவிக்கு தலைப்பு",
    "feed.text16": "உள்ளடக்க தயவு பதவியை",
    "feed.text17": "அழி",
    "feed.text18": "அது முற்றிலும் நீக்கு படத்தை எந்த பிரச்சனை இருக்கிறதா?",
    "feed.text19": "நீக்கப்பட்ட",
    "feed.text20": "மீடியா பைல் JPG, JPEG, PNG அல்லது mp4 கோப்பாக இருக்க வேண்டும், மற்றும் குறைந்த 3MB விட",
    "feed.text21": "பொது பதவியை",
    "feed.text22": "தனிப்பட்ட இடுகையில்",
    "feed.text23": "தேடுதல் தட்டச்சு செய்யவும் ....",
    "feed.text24": "பயனர் தேடு ...",
    "__2": "",
    "userInfo.text1": "பயனர் தகவல்",
    "userInfo.text2": "பெயர்",
    "userInfo.text3": "படத்தை",
    "userInfo.text4": "உருவாக்கிய தேதி",
    "userInfo.text5": "அது முற்றிலும் நீக்கு படத்தை எந்த பிரச்சனை இருக்கிறதா?",
    "userInfo.text6": "புதிய பெயர் ...",
    "userInfo.text7": "படக் கோப்பு JPG, JPEG, அல்லது PNG கோப்பாக இருக்க வேண்டும், மற்றும் குறைந்த 1MB விட",
    "userInfo.text8": "டார்க் முறை",
    "userInfo.text9": "பற்றி பயனர்",
    "userInfo.text10": "போன்றவை உங்களைப் பற்றிய கூடுதல் தகவலை, விளக்கம் உயிர் ... (300 க்குக் குறைவான எழுத்துகளில்)",
    "userInfo.text11": "மொழி",
    "userInfo.text12": "மாற்றம்",
    "userInfo.text13": "அறிவித்தல்",
    "__3": "",
    "auth.text1": "உங்கள் மின்னஞ்சல்",
    "auth.text2": "உங்கள் பெயர்",
    "auth.text3": "கடவுச்சொல்",
    "auth.text4": "கடவுச்சொல் குறைந்தபட்சம் 8 முதல் 20 எழுத்துக்கள், குறைந்தது ஒரு பெரிய எழுத்தில் கடிதம், ஒரு ஸ்மால் கடிதம், ஒரு எண் மற்றும் ஒரு சிறப்பு எழுத்தாக இருக்க வேண்டும் (@ $!% *? &).",
    "auth.text5": "பதிவுசெய்தல்",
    "auth.text6": "உள்நுழைய",
    "auth.text7": "கடவுச்சொல்லை மறந்து",
    "auth.text8": "இணைந்ததற்கு பக்கம்",
    "auth.text9": "பாஸ்வேர்ட் ரீசெட் மின்னஞ்சல் முகவரியை",
    "auth.text10": "மின்னஞ்சல் அனுப்பு",
    "auth.text11": "சரிபார்த்தல் தோல்வியுற்றது. உறுதி மின்னஞ்சல் முகவரி இன்னும் பயன்படுத்தப்படவில்லை!",
    "auth.text12": "பயனர் உள்நுழைவு தோல்வியடைந்தது!",
    "auth.text13": "தவறான கடவுச்சொல் உள்ளிடப்பட்டிருந்தாலும்",
    "auth.text14": "உள்ளிட்ட மின்னஞ்சல் முகவரி காணப்படவில்லை",
    "auth.text15": "பயனரின் உருவாக்கம் தோல்வியடைந்தது!",
    "auth.text16": "மின்னஞ்சல் முகவரியை வேறொரு கணக்கால் ஏற்கனவே பயன்பாட்டில் உள்ளது",
    "auth.text17": "உள்ளிட்ட கடவுச்சொல் காட்டு",
    "auth.text18": "கடவுச்சொல் மறை",
    "auth.text19": "கடவுச்சொல்லை முழுவதும் மின்னஞ்சல் அனுப்பப்பட்டது",
    "auth.text20": "Enterd மின்னஞ்சல் காணப்படவில்லை",
    "auth.text21": "கடவுச்சொல் மீட்டமை வெற்றி",
    "auth.text22": "கடவுச்சொல் மீட்டமை தோல்வியுற்றது, மீண்டும் கடவுச்சொல் மீட்டமைப்பைக் தேதி பக்கம், அனுப்பு மின்னஞ்சல் செல்க",
    "auth.text23": "கடவுச்சொல் மீட்டமை பக்கம்",
    "auth.text24": "புதிய கடவுச்சொல்",
    "auth.text25": "கடவுச்சொல்லை மீட்டமைக்க",
    "auth.text26": "பக்கம் தவறானது அல்லது கடவுச்சொல் ஏற்கனவே மாற்றப்பட்டால்",
    "auth.text27": "பாஸ்வேர்ட் ரீசெட் மின்னஞ்சல் அனுப்புதல் தோல்வி, தயவு செய்து காசோலை மின்னஞ்சல் முகவரியை",
    "auth.text28": "மின்னஞ்சல் சரிபார்ப்பு பக்கம்",
    "auth.text29": "வெற்றி சரிபார்ப்பு மின்னஞ்சல், உள்நுழைவு பக்கம் மேலே காட்டப்பட்டுள்ள செல்க",
    "auth.text30": "மின்னஞ்சல் சரிபார்ப்பு தோல்வியடைந்தது. இந்தப் பக்கம் காலாவதியாகிவிட்டது, அல்லது சரிபார்ப்பு ஏற்கனவே முடிந்துவிட்டது.",
    "auth.text31": "சரிபார்ப்பு மின்னஞ்சல் அனுப்பப்பட்டது. கடிதத்தின் உங்கள் மின்னஞ்சல் சரிபார்த்து சரிபார்க்கவும். (மேலும் ஸ்பேம் அஞ்சலில் chack)",
    "auth.text32": "மின்னஞ்சல் சரிபார்ப்பு உள்நுழைய வேண்டும். உங்கள் மின்னஞ்சல் முகவரிக்கு சரிபார்ப்பதற்காக மின்னஞ்சல் அனுப்பவும்.",
    "auth.text33": "சரிபார்ப்பதற்காக மின்னஞ்சல் அனுப்பவும்",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "ஊட்டம்",
    "nav.text2": "உள்நுழைய",
    "nav.text3": "பதிவுசெய்தல்",
    "nav.text4": "பயனர் தகவல்",
    "nav.text5": "வெளியேறு",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "பக்கம் கிடைக்கவில்லை",
    "notFound.text2": "ஊட்டம் பக்கத்துக்கு சென்று",
    "notFound.text3": "உள்நுழைவு பக்கத்துக்கு சென்று",
    "notFound.text4": "எங்கே நீங்கள் இன்று படித்தீர்கள்?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "ரத்து",
    "comment.text2": "அழி",
    "comment.text3": "கருத்து தெரிவி",
    "comment.text4": "பதில் காட்டு",
    "comment.text5": "மொத்தம்",
    "comment.text6": "எழுது பதில்",
    "comment.text7": "பதில் மறை",
    "comment.text8": "கருத்துக்கள்",
    "comment.text9": "அது முற்றிலும் இந்தக் கருத்தின் இந்தக் கருத்தையும் பதில்களையும் நீக்க எந்த பிரச்சனை இருக்கிறதா?",
    "comment.text10": "அது முற்றிலும் இந்தக் கருத்தை நீக்க எந்த பிரச்சனை இருக்கிறதா?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "பிடித்தமான போஸ்ட் சேர்க்க",
    "follow.text2": "பிடித்த பதவியில் இருந்து நீக்கு",
    "follow.text3": "பிடித்த பயனர் சேர்",
    "follow.text4": "பிடித்த பயனர் நீக்கு",
    "follow.text5": "நிகழ்ச்சி பதிவுகள்",
    "follow.text6": "உங்கள் பிடித்தமான பயனர்கள்",
    "follow.text7": "போஸ்ட் பிடித்தமான பயனர்கள்",
    "__11": "",
    "privacyPolicy.text1": "தனியுரிமை கொள்கை",
    "privacyPolicy.text2": "இந்த கொள்கை புதுப்பிக்கப்பட்டுள்ளது அல்லது அறிவிப்பு இல்லாமல் திருத்தப்பட்ட இருக்கலாம். அது தனியுரிமை கொள்கை மாற்றங்கள் பற்றிய செய்திகளை உடனுக்குடன் அறிந்துகொள்ள பயனர் பொறுப்பாகும். நீங்கள் பாதிக்கலாம் என்று தனியுரிமையுடன் பிரச்சினைகள் குறிப்பு எடுத்து:",
    "privacyPolicy.text3": "இந்த வலைத்தளம் சமரசம் இருக்கலாம்.",
    "privacyPolicy.text4": "உங்கள் கணினியில் சமரசம் இருக்கலாம்.",
    "privacyPolicy.text5": "இந்த வலைத்தளம் Firebase மற்றும் DigitalOcean கட்டமைப்புகள் ஹோஸ்ட் செய்யப்பட்டுள்ளது. அவர்கள் மே தங்கள் சர்வர்களில் தங்கள் சொந்த கண்காணிப்பு அமைப்புகள் இல்லை. அந்த சேவைகளை தங்கள் சொந்த தனியுரிமைக் கொள்கைகளைக் கொண்டிருக்கும் மற்றும் அவர்கள் இந்த தனியுரிமை கொள்கையால் பாதுகாப்பு அளிக்கப்பட மாட்டாது.",
    "privacyPolicy.text6": "இந்த மின்னஞ்சல் வலைத்தளத்தில் கடைகள், கடவுச்சொல்லை குறியாக்கம் வடிவம், மற்றும் வழங்கினார் Firebase மற்றும் DigitalOcean கட்டமைப்புகள் பயனர்களுக்கு மற்ற உள்ளீடுகள்.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "பயன்பாட்டு விதிமுறைகளை",
    "termsOfUse.text2": "தனியுரிமை",
    "termsOfUse.text3": "உங்கள் தனியுரிமை எங்களுக்கு முக்கியம். படிக்கவும்",
    "termsOfUse.text4": "தனியுரிமை கொள்கை",
    "termsOfUse.text5": "மேலும் தகவலுக்கு.",
    "termsOfUse.text6": "உங்கள் சொந்த பொறுப்புகளை",
    "termsOfUse.text7": "நீங்கள், பயனர், உங்கள் அதிகார சட்டங்கள் மற்றும் வரி உங்கள் சொந்த படுத்துவதை உறுதி செய்ய பொறுப்பேற்கிறீர்கள். நீங்கள் உங்கள் பாதுகாப்பிற்கு பொறுப்பேற்கிறீர்கள்.",
    "termsOfUse.text8": "உத்தரவாதத்தை உரிமைவிலக்கம்",
    "termsOfUse.text9": "என்பதன் உத்தரவாதம் அல்லது நிபந்தனைகளை இல்லாமல் இத்தளம் ஒரு அன்று வழங்கப்படுகிறது \"\" வழங்கப்பட்டுள்ளது.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "தொடக்க இணைக்கவும்",
    "videoTalk.text2": "நிறுத்து இணைப்பு",
    "videoTalk.text3": "ஆன்லைன் பயனர்கள்",
    "videoTalk.text4": "பேச தொடங்க",
    "videoTalk.text5": "உங்கள் பேச்சு முயற்சி",
    "videoTalk.text6": "வேறு எந்த ஆன்லைன் பயனர் இல்லை",
    "videoTalk.text7": "நீங்கள் பேசிக் கொண்டிருக்கும்",
    "videoTalk.text8": "நீங்கள் பேச தொடங்கியது",
    "videoTalk.text9": "உரை விவாத நிகழ்ச்சி",
    "videoTalk.text10": "உரை உள்ளீடு ....",
    "videoTalk.text11": "நீங்கள் இன்னும் பேசுகிறீர்கள். நீங்கள் பேசுவதை நிறுத்த விரும்புகிறீர்களா?",
    "videoTalk.text12": "அழைப்புக் பயனர் துண்டிக்கப்பட்ட",
    "videoTalk.text13": "பயனர் உங்களை அழைக்கிறார். இந்த அழைப்பை ஏற்று?",
    "videoTalk.text14": "உங்கள் அழைப்பை நிராகரிக்க",
    "videoTalk.text15": "ஆன்லைன் பயனர் தேட ...",
    "videoTalk.text16": "பயனர் தேட ...",
    "__14": "",
    "groupTalk.text1": "உருவாக்கியவர்",
    "groupTalk.text2": "விளக்கம்",
    "groupTalk.text3": "உருவாக்கிய தேதி",
    "groupTalk.text4": "திருத்துக தகவல்",
    "groupTalk.text5": "அது இந்தக் குழுவின் உறுப்பினர்களைப் இருந்து நீங்கள் நீக்க எந்த பிரச்சனை இருக்கிறதா?",
    "groupTalk.text6": "உறுப்பினர் பதவியிலிருந்து விட்டு",
    "groupTalk.text7": "குழுவில் இணை",
    "groupTalk.text8": "குழு உறுப்பினர்கள் காட்டு",
    "groupTalk.text9": "நீங்கள் இந்த குழுவில் சேவதற்கான ஆசையா?",
    "groupTalk.text10": "குழு பெயர்",
    "groupTalk.text11": "மீண்டும் பட்டியலில்",
    "groupTalk.text12": "உங்கள் உள்ளீடு உறுதிப்படுத்தவும்",
    "groupTalk.text13": "குழுவை உருவாக்கு",
    "groupTalk.text14": "update குழுக்",
    "groupTalk.text15": "குழுமத்திடம் இருந்து வெளியேறவும்",
    "groupTalk.text16": "முகப்புப் உள்நுழைய சென்று",
    "groupTalk.text17": "குழு பெயர் (5-100 எழுத்துக்கள்)",
    "groupTalk.text18": "குழு விளக்கம் (500 எழுத்துக்களுக்கு குறைவாக)"
  },
  {
    "LANGUAGE": "Telugu",
    "CODE": "te",
    "test.text1": "హలో",
    "test.text2": "హాయ్",
    "test.text3": "ధన్యవాదాలు",
    "test.text4": "మ్యాప్",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "రద్దు చేయండి",
    "general.text2": "అంగీకరించు",
    "general.text3": "తొలగించు",
    "general.text4": "తొలగించిన",
    "general.text5": "మార్చు",
    "general.text6": "నవీకరణ",
    "general.text7": "అప్లోడ్ చిత్రం",
    "general.text8": "తొలగించు చిత్రం",
    "general.text9": "చిత్రం",
    "general.text10": "Feed",
    "general.text11": "ప్రవేశించండి",
    "general.text12": "చేరడం",
    "general.text13": "వాడుకరి సమాచారం",
    "general.text14": "లాగౌట్",
    "general.text15": "ఫైలు ఉండాలి",
    "general.text16": "కంటే ఎక్కువ మరియు తక్కువ",
    "general.text17": "పాస్వర్డ్",
    "general.text18": "ఇష్టమైన పోస్ట్లు",
    "general.text19": "పోస్ట్లు",
    "general.text20": "చిత్రాలు",
    "general.text21": "వీడియోలు",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "క్రొత్త పోస్ట్",
    "feed.text2": "వాడుకరి పోస్ట్లు చూపించు",
    "feed.text3": "షో పోస్ట్లు",
    "feed.text4": "వీక్షణ",
    "feed.text5": "మార్చు",
    "feed.text6": "తొలగించండి",
    "feed.text7": "పూర్తిగా డిలీట్ పోస్ట్కు ఏ సమస్య ఉంది?",
    "feed.text8": "వీరిచే పోస్ట్",
    "feed.text9": "సృష్టికర్త",
    "feed.text10": "శీర్షిక",
    "feed.text11": "చిత్రం",
    "feed.text12": "విషయము",
    "feed.text13": "ప్రజా",
    "feed.text14": "ప్రైవేట్",
    "feed.text15": "పోస్ట్ కోసం శీర్షిక",
    "feed.text16": "కంటెంట్ por పోస్ట్",
    "feed.text17": "తొలగించండి",
    "feed.text18": "ఇది పూర్తిగా డిలీట్ చిత్రం ఎటువంటి సమస్య?",
    "feed.text19": "తొలగించిన",
    "feed.text20": "మీడియా ఫైల్ jpg, jpeg, png లేదా mp4 ఫైలు, మరియు 3MB కంటే తక్కువ ఉండాలి",
    "feed.text21": "పబ్లిక్ పోస్ట్",
    "feed.text22": "ప్రైవేట్ పోస్ట్",
    "feed.text23": "శోధన కోసం టైప్ ....",
    "feed.text24": "శోధన వాడుకరి ...",
    "__2": "",
    "userInfo.text1": "వాడుకరి సమాచారం",
    "userInfo.text2": "పేరు",
    "userInfo.text3": "చిత్రం",
    "userInfo.text4": "సృష్టి తేదీ",
    "userInfo.text5": "ఇది పూర్తిగా డిలీట్ చిత్రం ఎటువంటి సమస్య?",
    "userInfo.text6": "న్యూ పేరు ...",
    "userInfo.text7": "ఇమేజ్ ఫైల్ jpg, jpeg, లేదా png ఫైలు, మరియు 1MB కంటే తక్కువ ఉండాలి",
    "userInfo.text8": "డార్క్ మోడ్",
    "userInfo.text9": "గురించి వాడుకరి",
    "userInfo.text10": "etc మీ గురించి అదనపు సమాచారాన్ని, వివరణ బయో ... (కంటే తక్కువ 300 అక్షరాలు)",
    "userInfo.text11": "భాషా",
    "userInfo.text12": "మార్చు",
    "userInfo.text13": "నోటిఫికేషన్",
    "__3": "",
    "auth.text1": "మీ ఇమెయిల్",
    "auth.text2": "నీ పేరు",
    "auth.text3": "పాస్వర్డ్",
    "auth.text4": "పాస్వర్డ్ 8 నుంచి 20 అక్షరాలు, కనీసం ఒక పెద్ద అక్షరం, ఒక చిన్న లేఖ, ఒక సంఖ్య మరియు ఒక ప్రత్యేక పాత్ర ఉండాలి (@ $!% *? &).",
    "auth.text5": "చేరడం",
    "auth.text6": "ప్రవేశించండి",
    "auth.text7": "పాస్వర్డ్ మర్చిపో",
    "auth.text8": "సైన్అప్ పేజ్",
    "auth.text9": "పాస్వర్డ్ రీసెట్ ఇ-మెయిల్ చిరునామా",
    "auth.text10": "ఈ మెయిల్ పంపించండి",
    "auth.text11": "ప్రామాణీకరణ విఫలమైంది. నిర్ధారించుకోండి ఇమెయిల్ చిరునామాను ఇంకా ఉపయోగించని!",
    "auth.text12": "వాడుకరి లాగిన్ విఫలమైంది!",
    "auth.text13": "చెల్లని పాస్వర్డ్ను నమోదు",
    "auth.text14": "ప్రవేశించింది ఇమెయిల్ చిరునామా కనుగొనబడలేదు",
    "auth.text15": "వాడుకరి పేరుమార్పుల విఫలమైంది!",
    "auth.text16": "ఇమెయిల్ చిరునామా మరో ఖాతా ద్వారా ఇప్పటికే ఉపయోగంలో ఉంది",
    "auth.text17": "ప్రవేశించింది పాస్వర్డ్ను చూపించు",
    "auth.text18": "పాస్వర్డ్ దాచు",
    "auth.text19": "పాస్వర్డ్ మిగిలిన ఇమెయిల్ పంపబడింది",
    "auth.text20": "Enterd ఇమెయిల్ దొరకలేదు",
    "auth.text21": "పాస్వర్డ్ రీసెట్ విజయం",
    "auth.text22": "పాస్వర్డ్ రీసెట్ విఫలమైంది, దయచేసి మళ్ళీ పాస్వర్డ్ను రీసెట్ లాగిన్ పేజీ మరియు ఇమెయిల్ పంపండి వెళ్ళండి",
    "auth.text23": "పాస్వర్డ్ రీసెట్ పేజీ",
    "auth.text24": "కొత్త పాస్వర్డ్",
    "auth.text25": "రహస్యపదాన్ని మార్చుకోండి",
    "auth.text26": "పేజీ చెల్లదు లేదా పాస్వర్డ్ ఇప్పటికే మార్చబడింది",
    "auth.text27": "పాస్వర్డ్ రీసెట్ ఇమెయిల్ పంపడం విఫలమైంది, దయచేసి తనిఖీ ఇమెయిల్ చిరునామా",
    "auth.text28": "ఇమెయిల్ ధృవీకరణ పేజీ",
    "auth.text29": "విజయం ధృవీకరణ ఇమెయిల్, దయచేసి పైన చూపిన లాగిన్ పేజీకి వెళ్ళండి",
    "auth.text30": "ఇమెయిల్ ధృవీకరణ విఫలమైంది. ఈ పేజీ గడువు, లేదా ధృవీకరణ ముగిసిన.",
    "auth.text31": "ధృవీకరణ మెయిల్ పంపబడింది. దయచేసి దానిని మీ ఇమెయిల్ తనిఖీ మరియు ధ్రువీకరించడం. (స్పామ్ మెయిల్ లో chack)",
    "auth.text32": "ఇమెయిల్ ధృవీకరణ లాగిన్ అవసరం. మీ ఇమెయిల్ చిరునామా ధృవీకరణ ఇమెయిల్ పంపండి.",
    "auth.text33": "ధృవీకరణ ఇమెయిల్ పంపండి",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Feed",
    "nav.text2": "ప్రవేశించండి",
    "nav.text3": "చేరడం",
    "nav.text4": "వాడుకరి సమాచారం",
    "nav.text5": "లాగౌట్",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "పేజీ కనుగొనబడలేదు",
    "notFound.text2": "ఫీడ్ పేజీకి వెళ్ళండి",
    "notFound.text3": "లాగిన్ పేజీకి వెళ్ళండి",
    "notFound.text4": "ఎక్కడ మీరు నేడు గో?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "రద్దు చేయండి",
    "comment.text2": "తొలగించు",
    "comment.text3": "పోస్ట్ వ్యాఖ్య",
    "comment.text4": "Reply షో",
    "comment.text5": "మొత్తం",
    "comment.text6": "వ్రాయండి Reply",
    "comment.text7": "Reply దాచు",
    "comment.text8": "వ్యాఖ్యలు",
    "comment.text9": "ఇది పూర్తిగా ఈ వ్యాఖ్య చేసిన ఈ వ్యాఖ్యను మరియు ప్రత్యుత్తరాలు తొలగించబడతాయి ఎటువంటి సమస్య?",
    "comment.text10": "ఇది పూర్తిగా ఈ వ్యాఖ్యను తొలగించు ఎటువంటి సమస్య?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "ఇష్టమైన పోస్ట్ జోడించండి",
    "follow.text2": "ఇష్టమైన పదవికి తొలగించు",
    "follow.text3": "ఇష్టమైన వినియోగదారుకు జోడించండి",
    "follow.text4": "ఇష్టమైన వినియోగదారు నుండి తొలగించు",
    "follow.text5": "షో పోస్ట్లు",
    "follow.text6": "మీ ఇష్టమైన వినియోగదారులు",
    "follow.text7": "పోస్ట్ ఇష్టమైనవి వినియోగదారులు",
    "__11": "",
    "privacyPolicy.text1": "గోప్యతా విధానం",
    "privacyPolicy.text2": "ఈ విధానం నవీకరించబడింది లేదా నోటీసు లేకుండా సవరించిన ఉండవచ్చు. ఇది స్టే యూజర్ గోప్యతా విధాన మార్పులను గురించి సమాచారం యొక్క బాధ్యత. మీరు ప్రభావితం చేసే ఇతర గోప్యత సమస్యల గమనించాల్సి:",
    "privacyPolicy.text3": "ఈ వెబ్సైట్ రాజీ ఉండవచ్చు.",
    "privacyPolicy.text4": "మీ కంప్యూటర్ రాజీ ఉండవచ్చు.",
    "privacyPolicy.text5": "ఈ వెబ్సైట్ Firebase మరియు DigitalOcean నిర్మాణాల్లో పొందుపరచబడింది. వారు మరియు వారి సర్వర్లలో తమ సొంత ట్రాకింగ్ వ్యవస్థలు ఉన్నాయి. ఆ సేవలను వారి స్వంత గోప్యతా విధానాలను కలిగి మరియు వారు ఈ గోప్యతా విధానం ద్వారా కవర్ కాదు.",
    "privacyPolicy.text6": "ఈ వెబ్సైట్ దుకాణాలు ఇమెయిల్, పాస్వర్డ్ ఎన్క్రిప్టెడ్ రూపంలో, మరియు హోస్ట్ Firebase మరియు DigitalOcean నిర్మాణాల్లో వినియోగదారులు ఇతర ఇన్పుట్లను.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "ఉపయోగ నిబంధనలు",
    "termsOfUse.text2": "గోప్యతా",
    "termsOfUse.text3": "మీ గోప్యత మాకు ముఖ్యం. చదవండి",
    "termsOfUse.text4": "గోప్యతా విధానం",
    "termsOfUse.text5": "మరిన్ని వివరములకు.",
    "termsOfUse.text6": "మీ సొంత బాధ్యతలు",
    "termsOfUse.text7": "మీరు, వినియోగదారు, మీ అధికార చట్టాలు మరియు పన్నులు తో మీ స్వంత అమలు పరిచే మాత్రమే బాధ్యత వహిస్తాయి. మీరు మీ స్వంత భద్రత కోసం మాత్రమే బాధ్యత వహిస్తాయి.",
    "termsOfUse.text8": "వారంటీ నిభంధనలు",
    "termsOfUse.text9": "ఈ సైట్ అందించబడుతుంది హామీలతో లేదా ఏ రకమైన షరతులు లేకుండా, యధాతథంగా \"యథాతథంగా\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "ప్రారంభం కనెక్ట్",
    "videoTalk.text2": "కనెక్ట్ ఆపు",
    "videoTalk.text3": "ఆన్లైన్ వినియోగదారులు",
    "videoTalk.text4": "మాట్లాడటానికి ప్రారంభం",
    "videoTalk.text5": "మీ చర్చ ప్రయత్నిస్తున్న",
    "videoTalk.text6": "ఏ ఇతర వినియోగదారుడిని ఉంది",
    "videoTalk.text7": "మీరు మాట్లాడుతున్నారా",
    "videoTalk.text8": "మీరు మాట్లాడటానికి మొదలు",
    "videoTalk.text9": "టెక్స్ట్ టాక్ షో",
    "videoTalk.text10": "టెక్స్ట్ ఇన్పుట్ ....",
    "videoTalk.text11": "మీరు ఇప్పటికీ మాట్లాడుతున్నారు. మీరు మాట్లాడటం ఆపడానికి అనుకుంటున్నారా?",
    "videoTalk.text12": "కాలింగ్ యూజర్ డిస్కనెక్ట్",
    "videoTalk.text13": "యూజర్ కాల్ చేస్తున్నారు. మీరు ఈ కాల్ అంగీకరిస్తారా?",
    "videoTalk.text14": "మీ కాల్ తిరస్కరించడానికి",
    "videoTalk.text15": "ఆన్లైన్ వినియోగదారు యొక్క శోధన ...",
    "videoTalk.text16": "వినియోగదారు యొక్క శోధన ...",
    "__14": "",
    "groupTalk.text1": "సృష్టికర్త",
    "groupTalk.text2": "వివరణ",
    "groupTalk.text3": "సృష్టి తేదీ",
    "groupTalk.text4": "సమూహం ఎడిట్ సమాచారం",
    "groupTalk.text5": "ఈ గుంపు సభ్యులు నుండి మీరు తొలగించాలనుకుంటున్నారా ఏ సమస్య?",
    "groupTalk.text6": "సభ్యుని నుండి వదిలి",
    "groupTalk.text7": "బృందంలో చేరు",
    "groupTalk.text8": "గ్రూప్ సభ్యులు చూపించు",
    "groupTalk.text9": "మీరు ఈ గుంపులో చేరండి అనుకుంటున్నారా?",
    "groupTalk.text10": "కూటమి పేరు",
    "groupTalk.text11": "జాబితా తిరిగి",
    "groupTalk.text12": "మీ ఇన్పుట్ నిర్ధారించండి",
    "groupTalk.text13": "గ్రూప్ సృష్టించు",
    "groupTalk.text14": "నవీకరణ గ్రూప్",
    "groupTalk.text15": "గ్రూప్ నుండి నిష్క్రమించు",
    "groupTalk.text16": "వరకు హోమ్పేజీ వెళ్ళండి లాగిన్",
    "groupTalk.text17": "గుంపు పేరు (5-100 అక్షరాలు)",
    "groupTalk.text18": "సమూహం వివరణ (కంటే తక్కువ 500 అక్షరాలు)"
  },
  {
    "LANGUAGE": "Thai",
    "CODE": "th",
    "test.text1": "สวัสดี",
    "test.text2": "สวัสดี",
    "test.text3": "ขอขอบคุณ",
    "test.text4": "แผนที่",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "ยกเลิก",
    "general.text2": "ยอมรับ",
    "general.text3": "ลบ",
    "general.text4": "ลบ",
    "general.text5": "แก้ไข",
    "general.text6": "ปรับปรุง",
    "general.text7": "อัพโหลดรูปภาพ",
    "general.text8": "ลบภาพ",
    "general.text9": "ภาพ",
    "general.text10": "อาหาร",
    "general.text11": "เข้าสู่ระบบ",
    "general.text12": "ลงชื่อ",
    "general.text13": "รายละเอียดผู้ใช้",
    "general.text14": "ออกจากระบบ",
    "general.text15": "ไฟล์ที่ควรจะเป็น",
    "general.text16": "และน้อยกว่า",
    "general.text17": "รหัสผ่าน",
    "general.text18": "ที่ชื่นชอบโพสต์",
    "general.text19": "โพสต์",
    "general.text20": "ภาพ",
    "general.text21": "วิดีโอ",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "โพสต์ใหม่",
    "feed.text2": "แสดงผู้ใช้โพสต์",
    "feed.text3": "แสดงกระทู้",
    "feed.text4": "ดู",
    "feed.text5": "แก้ไข",
    "feed.text6": "ลบ",
    "feed.text7": "คือเป็นปัญหาในการโพสต์ลบอย่างสมบูรณ์หรือไม่?",
    "feed.text8": "โพสโดย",
    "feed.text9": "สร้างโดย",
    "feed.text10": "หัวข้อ",
    "feed.text11": "ภาพ",
    "feed.text12": "เนื้อหา",
    "feed.text13": "สาธารณะ",
    "feed.text14": "เอกชน",
    "feed.text15": "ชื่อสำหรับโพสต์",
    "feed.text16": "โพสต์เนื้อหา por",
    "feed.text17": "ลบ",
    "feed.text18": "มันเป็นปัญหากับภาพลบอย่างสมบูรณ์หรือไม่?",
    "feed.text19": "ลบ",
    "feed.text20": "สื่อไฟล์ควรเป็น JPG, JPEG, PNG หรือไฟล์ MP4, และน้อยกว่า 3MB",
    "feed.text21": "โพสต์สาธารณะ",
    "feed.text22": "โพสต์ส่วนตัว",
    "feed.text23": "พิมพ์สำหรับการค้นหา ....",
    "feed.text24": "ค้นหาผู้ใช้ ...",
    "__2": "",
    "userInfo.text1": "ข้อมูลผู้ใช้",
    "userInfo.text2": "ชื่อ",
    "userInfo.text3": "ภาพ",
    "userInfo.text4": "วันที่สร้าง",
    "userInfo.text5": "มันเป็นปัญหากับภาพลบอย่างสมบูรณ์หรือไม่?",
    "userInfo.text6": "ชื่อใหม่ ...",
    "userInfo.text7": "ไฟล์ภาพควรจะ jpg, jpeg, หรือ PNG และน้อยกว่า 1MB",
    "userInfo.text8": "โหมดเข้ม",
    "userInfo.text9": "เกี่ยวกับผู้ใช้",
    "userInfo.text10": "ข้อมูลเพิ่มเติมเกี่ยวกับคุณคำอธิบายชีวภาพ ฯลฯ ... (น้อยกว่า 300 ตัวอักษร)",
    "userInfo.text11": "ภาษา",
    "userInfo.text12": "เปลี่ยนแปลง",
    "userInfo.text13": "แจ้ง",
    "__3": "",
    "auth.text1": "อีเมลของคุณ",
    "auth.text2": "ชื่อของคุณ",
    "auth.text3": "รหัสผ่าน",
    "auth.text4": "รหัสผ่านควรจะ 8-20 ตัวอักษรตัวอักษรอย่างน้อยหนึ่งตัวพิมพ์ใหญ่ตัวอักษรตัวพิมพ์เล็กหนึ่งหมายเลขหนึ่งและตัวอักษรพิเศษ (@ $!% *? &)",
    "auth.text5": "ลงชื่อ",
    "auth.text6": "เข้าสู่ระบบ",
    "auth.text7": "ลืมรหัสผ่าน",
    "auth.text8": "ลงทะเบียนหน้า",
    "auth.text9": "E-mail address สำหรับรีเซ็ตรหัสผ่าน",
    "auth.text10": "ส่งอีเมล",
    "auth.text11": "การยืนยันล้มเหลว. ตรวจสอบให้แน่ใจที่อยู่อีเมลที่ไม่ได้ใช้เลย!",
    "auth.text12": "ผู้ใช้เข้าสู่ระบบล้มเหลว!",
    "auth.text13": "รหัสผ่านไม่ถูกต้องป้อน",
    "auth.text14": "ป้อนที่อยู่อีเมลไม่พบ",
    "auth.text15": "การสร้างผู้ใช้ล้มเหลว!",
    "auth.text16": "ที่อยู่อีเมลที่มีอยู่แล้วในการใช้งานโดยบัญชีอื่น",
    "auth.text17": "แสดงป้อนรหัสผ่าน",
    "auth.text18": "ซ่อนรหัสผ่าน",
    "auth.text19": "อีเมลรหัสผ่านสำหรับส่วนที่เหลือถูกส่ง",
    "auth.text20": "อีเมล enterd ไม่พบ",
    "auth.text21": "ประสบความสำเร็จในการรีเซ็ตรหัสผ่าน",
    "auth.text22": "การรีเซ็ตรหัสผ่านล้มเหลว, โปรดไปที่หน้าเข้าสู่ระบบและอีเมล์ส่งสำหรับรีเซ็ตรหัสผ่านอีกครั้ง",
    "auth.text23": "หน้าการรีเซ็ตรหัสผ่าน",
    "auth.text24": "รหัสผ่านใหม่",
    "auth.text25": "รีเซ็ตรหัสผ่าน",
    "auth.text26": "หน้าไม่ถูกต้องหรือการเปลี่ยนรหัสผ่านแล้ว",
    "auth.text27": "ส่งอีเมลสำหรับรีเซ็ตรหัสผ่านล้มเหลวโปรดตรวจสอบที่อยู่อีเมล",
    "auth.text28": "หน้ายืนยันอีเมล",
    "auth.text29": "อีเมลยืนยันความสำเร็จของโปรดไปที่หน้าเข้าสู่ระบบที่แสดงข้างต้น",
    "auth.text30": "ตรวจสอบอีเมล์ที่ล้มเหลว หน้านี้หมดอายุหรือการตรวจสอบเสร็จสิ้นแล้ว",
    "auth.text31": "ตรวจสอบจดหมายที่ถูกส่ง Plese ตรวจสอบอีเมลของคุณและตรวจสอบ (ยัง Chack ในอีเมลขยะ)",
    "auth.text32": "ยืนยันอีเมลจะต้องเข้าสู่ระบบ กรุณาส่งอีเมล์สำหรับการตรวจสอบไปยังที่อยู่อีเมลของคุณ",
    "auth.text33": "ส่งอีเมลสำหรับการตรวจสอบ",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "อาหาร",
    "nav.text2": "เข้าสู่ระบบ",
    "nav.text3": "ลงชื่อ",
    "nav.text4": "รายละเอียดผู้ใช้",
    "nav.text5": "ออกจากระบบ",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "ไม่พบหน้านี้",
    "notFound.text2": "ไปที่หน้าฟีด",
    "notFound.text3": "ไปที่หน้าเข้าสู่ระบบ",
    "notFound.text4": "คุณจะไปไหนวันนี้?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "ยกเลิก",
    "comment.text2": "ลบ",
    "comment.text3": "เขียนความคิดเห็น",
    "comment.text4": "แสดงตอบกลับ",
    "comment.text5": "รวม",
    "comment.text6": "เขียนตอบ",
    "comment.text7": "ซ่อนตอบ",
    "comment.text8": "ความคิดเห็น",
    "comment.text9": "มันเป็นปัญหาในการลบความคิดเห็นนี้และการตอบกลับความคิดเห็นนี้สมบูรณ์หรือไม่?",
    "comment.text10": "มันเป็นปัญหาในการลบความคิดเห็นนี้อย่างสมบูรณ์หรือไม่?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Add to Favorite โพสต์",
    "follow.text2": "ลบจากการโพสต์ที่ชื่นชอบ",
    "follow.text3": "เพิ่มให้กับผู้ที่ชื่นชอบ",
    "follow.text4": "ลบจากผู้ใช้ที่ชื่นชอบ",
    "follow.text5": "โพสต์โชว์",
    "follow.text6": "ผู้ใช้ที่คุณชื่นชอบ",
    "follow.text7": "ผู้โพสต์ที่ชื่นชอบ",
    "__11": "",
    "privacyPolicy.text1": "นโยบายความเป็นส่วนตัว",
    "privacyPolicy.text2": "นโยบายนี้อาจมีการปรับปรุงหรือแก้ไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า มันเป็นความรับผิดชอบของผู้ใช้เพื่อรับทราบข้อมูลเกี่ยวกับการเปลี่ยนแปลงนโยบายความเป็นส่วนตัว จดประเด็นความเป็นส่วนตัวอื่น ๆ ที่อาจส่งผลกระทบต่อคุณ:",
    "privacyPolicy.text3": "เว็บไซต์นี้อาจจะมีการบุกรุก",
    "privacyPolicy.text4": "คอมพิวเตอร์ของคุณอาจถูกบุกรุก",
    "privacyPolicy.text5": "เว็บไซต์นี้จะเป็นเจ้าภาพใน Firebase และโครงสร้างพื้นฐาน DigitalOcean และพวกเขาอาจจะมีระบบการติดตามของตัวเองบนเซิร์ฟเวอร์ของตน บริการเหล่านั้นมีนโยบายความเป็นส่วนตัวของตัวเองและพวกเขาจะไม่ได้รับการคุ้มครองโดยนโยบายความเป็นส่วนตัว",
    "privacyPolicy.text6": "อีเมลนี้ร้านค้าเว็บไซต์รูปแบบการเข้ารหัสของรหัสผ่านและปัจจัยการผลิตอื่น ๆ ของผู้ใช้งานที่เป็นเจ้าภาพ Firebase และ DigitalOcean โครงสร้างพื้นฐาน",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "ข้อตกลงในการใช้งาน",
    "termsOfUse.text2": "ความเป็นส่วนตัว",
    "termsOfUse.text3": "ส่วนตัวของคุณเป็นสิ่งสำคัญสำหรับเรา โปรดอ่าน",
    "termsOfUse.text4": "นโยบายความเป็นส่วนตัว",
    "termsOfUse.text5": "สำหรับข้อมูลเพิ่มเติม.",
    "termsOfUse.text6": "ความรับผิดชอบของคุณเอง",
    "termsOfUse.text7": "คุณผู้ใช้จะต้องรับผิดชอบในการตรวจสอบการปฏิบัติตามของคุณเองด้วยกฎหมายและภาษีในเขตอำนาจศาลของคุณ คุณจะต้องเป็นผู้รับผิดชอบในการรักษาความปลอดภัยของคุณเอง",
    "termsOfUse.text8": "ขอสงวนสิทธิ์ในการรับประกัน",
    "termsOfUse.text9": "เว็บไซต์นี้จะให้ในลักษณะ \"ตามสภาพ\" โดยไม่มีการรับประกันหรือเงื่อนไขใด ๆ",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "เริ่มต้นการเชื่อมต่อ",
    "videoTalk.text2": "หยุดการเชื่อมต่อ",
    "videoTalk.text3": "ผู้ใช้ที่ออนไลน์",
    "videoTalk.text4": "เปิดปาก",
    "videoTalk.text5": "คุณกำลังพยายามที่จะพูดคุย",
    "videoTalk.text6": "ไม่มีผู้ใช้ออนไลน์อื่น ๆ ที่มีอยู่",
    "videoTalk.text7": "คุณกำลังพูดคุยกับ",
    "videoTalk.text8": "คุณเริ่มต้นที่จะพูดคุย",
    "videoTalk.text9": "แสดงข้อความพูดคุย",
    "videoTalk.text10": "ป้อนข้อความ ....",
    "videoTalk.text11": "คุณยังคงพูดคุย คุณต้องการที่จะหยุดพูด?",
    "videoTalk.text12": "ผู้ใช้โทรตัดการเชื่อมต่อ",
    "videoTalk.text13": "ผู้ใช้จะโทรหาคุณ คุณรับสายนี้หรือไม่?",
    "videoTalk.text14": "ปฏิเสธการรับสายของคุณ",
    "videoTalk.text15": "ค้นหาผู้ใช้ออนไลน์ ...",
    "videoTalk.text16": "ผู้ใช้ค้นหา ...",
    "__14": "",
    "groupTalk.text1": "ผู้สร้าง",
    "groupTalk.text2": "ลักษณะ",
    "groupTalk.text3": "วันที่สร้าง",
    "groupTalk.text4": "แก้ไขข้อมูลกลุ่ม",
    "groupTalk.text5": "มันเป็นปัญหาที่จะลบคุณจากสมาชิกของกลุ่มนี้หรือไม่?",
    "groupTalk.text6": "ฝากจากสมาชิก",
    "groupTalk.text7": "เข้าร่วมกลุ่ม",
    "groupTalk.text8": "แสดงสมาชิกกลุ่ม",
    "groupTalk.text9": "คุณต้องการที่จะเข้าร่วมกับกลุ่มนี้?",
    "groupTalk.text10": "ชื่อกลุ่ม",
    "groupTalk.text11": "กลับไปยังรายการ",
    "groupTalk.text12": "ยืนยันการป้อนข้อมูลของคุณ",
    "groupTalk.text13": "สร้างกลุ่ม",
    "groupTalk.text14": "กลุ่มปรับปรุง",
    "groupTalk.text15": "ออกจากกลุ่ม",
    "groupTalk.text16": "ไปที่หน้าแรกเพื่อเข้าสู่ระบบ",
    "groupTalk.text17": "ชื่อกลุ่ม (5-100 ตัวอักษร)",
    "groupTalk.text18": "คำอธิบายกลุ่ม (น้อยกว่า 500 ตัวอักษร)"
  },
  {
    "LANGUAGE": "Tagalog",
    "CODE": "tl",
    "test.text1": "Kamusta",
    "test.text2": "Hi",
    "test.text3": "Salamat",
    "test.text4": "map",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "kanselahin",
    "general.text2": "tanggapin",
    "general.text3": "Delete",
    "general.text4": "Deleted",
    "general.text5": "I-edit",
    "general.text6": "I-update",
    "general.text7": "Mag-upload ng Imahe",
    "general.text8": "Delete Imahe",
    "general.text9": "larawan",
    "general.text10": "Magpakain",
    "general.text11": "Mag log in",
    "general.text12": "Pag-signup",
    "general.text13": "iNFORMATION NG GUMAGAMIT",
    "general.text14": "Mag-logout",
    "general.text15": "file ay dapat na",
    "general.text16": "at mas mababa sa",
    "general.text17": "password",
    "general.text18": "Paboritong Mga Post",
    "general.text19": "Mga Post",
    "general.text20": "Mga imahe",
    "general.text21": "Mga video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "bagong Post",
    "feed.text2": "Ipakita ang User Posts",
    "feed.text3": "Ipakita ang mga Post",
    "feed.text4": "tingnan",
    "feed.text5": "edit",
    "feed.text6": "alisin",
    "feed.text7": "Ay ay walang problema upang delete post ganap?",
    "feed.text8": "Ipinaskil ni",
    "feed.text9": "Ginawa ni",
    "feed.text10": "pamagat",
    "feed.text11": "larawan",
    "feed.text12": "nilalaman",
    "feed.text13": "publiko",
    "feed.text14": "pribado",
    "feed.text15": "Pamagat para sa post na ito",
    "feed.text16": "Nilalaman por post",
    "feed.text17": "alisin",
    "feed.text18": "Ito ba ay walang problema upang delete image ganap?",
    "feed.text19": "Deleted",
    "feed.text20": "Media File ay dapat na jpg, jpeg, png o mp4 file, at mas mababa sa 3MB",
    "feed.text21": "pampublikong post",
    "feed.text22": "pribadong post",
    "feed.text23": "I-type para sa Search ....",
    "feed.text24": "Hanapan ang User ...",
    "__2": "",
    "userInfo.text1": "Impormasyon ng User",
    "userInfo.text2": "pangalan",
    "userInfo.text3": "larawan",
    "userInfo.text4": "petsa ng paglikha",
    "userInfo.text5": "Ito ba ay walang problema upang delete image ganap?",
    "userInfo.text6": "Bagong pangalan ...",
    "userInfo.text7": "Image file ay dapat na jpg, jpeg, o png file, at mas mababa sa 1MB",
    "userInfo.text8": "madilim Mode",
    "userInfo.text9": "Tungkol User",
    "userInfo.text10": "karagdagang impormasyon tungkol sa iyo, paglalarawan bio atbp ... (mas mababa sa 300 mga character)",
    "userInfo.text11": "wika",
    "userInfo.text12": "pagbabago",
    "userInfo.text13": "abiso",
    "__3": "",
    "auth.text1": "Ang email mo",
    "auth.text2": "Ang pangalan mo",
    "auth.text3": "password",
    "auth.text4": "Password ay dapat na 8 sa 20 character, hindi bababa sa isang uppercase sulat, isa lowercase na titik, o isang numero at ang isa espesyal na character (@ $!% *? &).",
    "auth.text5": "Pag-signup",
    "auth.text6": "Mag log in",
    "auth.text7": "kalimutan ang Password",
    "auth.text8": "Signup Pahina",
    "auth.text9": "E-mail address para sa reset ng password",
    "auth.text10": "magpadala ng E-mail",
    "auth.text11": "Nabigo ang pagpapatunay. Siguraduhin na ang email address ay hindi na ginagamit pa!",
    "auth.text12": "Nabigo ang User login!",
    "auth.text13": "Di-wastong Password ay Ipinasok",
    "auth.text14": "Ipinasok email address ay hindi natagpuan",
    "auth.text15": "Nabigo ang paglikha ng user!",
    "auth.text16": "Ang email address ay ginagamit na ng ibang account",
    "auth.text17": "Ipakita Ipinasok Password",
    "auth.text18": "Itago ang Password",
    "auth.text19": "email para sa password natitira ay ipinadala",
    "auth.text20": "Enterd email ay hindi natagpuan",
    "auth.text21": "Reset ng password sa tagumpay",
    "auth.text22": "Reset ng password nabigo, Mangyaring pumunta sa login page at magpadala ng email para sa reset ng password muli",
    "auth.text23": "Password pahinang reset",
    "auth.text24": "bagong Password",
    "auth.text25": "I-reset ang Password",
    "auth.text26": "Page ay hindi wasto o Password ay naka binago",
    "auth.text27": "Nagpapadala ng email para sa password reset Nabigo ang, Mangyaring check email address",
    "auth.text28": "Email page na pag-verify",
    "auth.text29": "Email verification tagumpay, mangyaring pumunta sa pag-login page na ipinakita sa itaas",
    "auth.text30": "Nabigo ang pagpapatotoo ng email. Ang pahinang ito ay nag-expire, o pag-verify ay naka-tapos na.",
    "auth.text31": "Pag-verify ng Mail ay ipinadala. Plese ang iyong email at i-verify. (Din chack sa spam mail)",
    "auth.text32": "Pagpapatunay ng Email ay kinakailangan upang pag-login. Mangyaring magpadala ng Email para sa pag-verify sa iyong email address.",
    "auth.text33": "Magpadala ng Email para sa pag-verify",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Magpakain",
    "nav.text2": "Mag log in",
    "nav.text3": "Pag-signup",
    "nav.text4": "iNFORMATION NG GUMAGAMIT",
    "nav.text5": "Mag-logout",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Hindi Natagpuan ang Pahina",
    "notFound.text2": "pumunta sa feed page",
    "notFound.text3": "pumunta sa pahina ng pag-login",
    "notFound.text4": "Saan ka pupunta ngayon?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "kanselahin",
    "comment.text2": "Delete",
    "comment.text3": "Mag-post ng Komento",
    "comment.text4": "Ipakita ang Sagot",
    "comment.text5": "total",
    "comment.text6": "Sumulat Sagot",
    "comment.text7": "Itago Sagot",
    "comment.text8": "Comments",
    "comment.text9": "Ito ba ay walang problema upang burahin ang komentong at mga tugon ng mga komentong ito ganap?",
    "comment.text10": "Ito ba ay walang problema upang tanggalin ang komentong ito ganap?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Idagdag sa Paborito Post",
    "follow.text2": "Tanggalin mula sa mga paboritong post",
    "follow.text3": "Idagdag sa mga paboritong user",
    "follow.text4": "Tanggalin mula sa mga paboritong user",
    "follow.text5": "show ng mga post",
    "follow.text6": "Iyong Paboritong Users",
    "follow.text7": "Post Paboritong Users",
    "__11": "",
    "privacyPolicy.text1": "Patakaran sa Privacy",
    "privacyPolicy.text2": "Ang patakarang ito ay maaaring ma-update o binagong nang walang abiso. Ito ay ang responsibilidad ng gumagamit upang manatili kaalaman tungkol sa mga pagbabago sa patakaran sa privacy. Itala ng iba pang mga isyu sa privacy na maaaring makaapekto sa iyo:",
    "privacyPolicy.text3": "Ang website na ito ay maaaring naka-kompromiso.",
    "privacyPolicy.text4": "Ang iyong computer ay maaaring naka-kompromiso.",
    "privacyPolicy.text5": "Ang website na ito ay naka-host sa Firebase at DigitalOcean infrastructures. Sila ay maaaring at huwag magkaroon ng kanilang sariling sistema ng pagsubaybay sa kanilang mga server. Yaong mga serbisyo ay may kanilang sariling mga patakaran sa privacy at sila ay hindi sakop ng patakaran sa privacy na ito.",
    "privacyPolicy.text6": "Ito mga tindahan ng website ng email, naka-encrypt na form ng password, at iba pang mga input ng user sa naka-host Firebase at DigitalOcean infrastructures.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Mga Tuntunin ng Paggamit",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Ang inyong privacy ay mahalaga sa amin. Pakibasa ang",
    "termsOfUse.text4": "patakaran sa privacy",
    "termsOfUse.text5": "para sa karagdagang impormasyon.",
    "termsOfUse.text6": "Ang iyong sariling mga responsibilidad",
    "termsOfUse.text7": "Ikaw, user, ikaw ay tanging responsable para sa pagtiyak ng iyong sariling pagsunod sa mga batas at mga buwis sa iyong hurisdiksyon. Ikaw ang mananagot para sa iyong sariling seguridad sa iyo.",
    "termsOfUse.text8": "Disclaimer ng warranty",
    "termsOfUse.text9": "Ang site na ito ay ibinigay sa isang \"AS IS\" BATAYAN ANG, NANG WALANG WARRANTY O KUNDISYON NG ANUMANG URI.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "stop Connect",
    "videoTalk.text3": "online na Tagagamit",
    "videoTalk.text4": "Simulan na makipag-usap",
    "videoTalk.text5": "Ang iyong sinusubukan na makipag-usap",
    "videoTalk.text6": "Walang iba pang mga online user ay umiiral",
    "videoTalk.text7": "Ikaw ay pakikipag-usap sa",
    "videoTalk.text8": "Sinimulan mo na bang makipag-usap",
    "videoTalk.text9": "Ipakita ang Teksto Talk",
    "videoTalk.text10": "text input ....",
    "videoTalk.text11": "ikaw pa rin ang pinag-uusapan. Huwag gusto mong ihinto ang pakikipag-usap?",
    "videoTalk.text12": "Calling user-disconnect",
    "videoTalk.text13": "user ay tumatawag sa iyo. Tinatanggap mo ba ang tawag na ito?",
    "videoTalk.text14": "tanggihan ang iyong tawag",
    "videoTalk.text15": "maghanap sa online user ...",
    "videoTalk.text16": "hanapin ang user ...",
    "__14": "",
    "groupTalk.text1": "lumikha",
    "groupTalk.text2": "paglalarawan",
    "groupTalk.text3": "petsa ng paglikha",
    "groupTalk.text4": "edit ang impormasyon ng grupo",
    "groupTalk.text5": "Ito ba ay walang problema upang tanggalin mo mula sa mga kasapi ng grupong ito?",
    "groupTalk.text6": "Mag-iwan mula sa Miyembro",
    "groupTalk.text7": "Sumali sa grupo",
    "groupTalk.text8": "Ipakita Miyembro ng Grupo",
    "groupTalk.text9": "Huwag Nais Mong Sumali Ang Group?",
    "groupTalk.text10": "Pangalan ng grupo",
    "groupTalk.text11": "Bumalik sa Listahan ng",
    "groupTalk.text12": "Kumpirmahin ang iyong input",
    "groupTalk.text13": "Gumawa ng grupo",
    "groupTalk.text14": "I-update ang Group",
    "groupTalk.text15": "Lumabas mula Group",
    "groupTalk.text16": "Pumunta sa Homepage sa Pag-login",
    "groupTalk.text17": "pangalan ng grupo (5-100 letra)",
    "groupTalk.text18": "grupo paglalarawan (mas mababa sa 500 mga character)"
  },
  {
    "LANGUAGE": "Tswana",
    "CODE": "tn",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Turkish",
    "CODE": "tr",
    "test.text1": "Merhaba",
    "test.text2": "Selam",
    "test.text3": "teşekkür ederim",
    "test.text4": "harita",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "İptal etmek",
    "general.text2": "Kabul etmek",
    "general.text3": "Sil",
    "general.text4": "silindi",
    "general.text5": "Düzenle",
    "general.text6": "Güncelleme",
    "general.text7": "Fotoğraf yükleniyor",
    "general.text8": "Resmi Sil",
    "general.text9": "görüntü",
    "general.text10": "besleme",
    "general.text11": "Oturum aç",
    "general.text12": "Kaydol",
    "general.text13": "Kullanıcı bilgisi",
    "general.text14": "Çıkış Yap",
    "general.text15": "dosyası olmalıdır",
    "general.text16": "daha az",
    "general.text17": "Parola",
    "general.text18": "Sevdiği Mesajlar",
    "general.text19": "Gönderiler",
    "general.text20": "Görüntüler",
    "general.text21": "Videolar",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Yeni posta",
    "feed.text2": "Kullanıcı Mesajını göster",
    "feed.text3": "göster Mesajlar",
    "feed.text4": "görünüm",
    "feed.text5": "Düzenle",
    "feed.text6": "silmek",
    "feed.text7": "Tamamen silme yayına hiçbir sorun olduğu var mı?",
    "feed.text8": "tarafından gönderildi",
    "feed.text9": "Tarafından yaratıldı",
    "feed.text10": "Başlık",
    "feed.text11": "görüntü",
    "feed.text12": "içerik",
    "feed.text13": "halka açık",
    "feed.text14": "özel",
    "feed.text15": "yazı için Başlık",
    "feed.text16": "İçerik por sonrası",
    "feed.text17": "silmek",
    "feed.text18": "tamamen silme resimle hiçbir sorun var mı?",
    "feed.text19": "silindi",
    "feed.text20": "Medya Dosyası jpg, jpeg, png veya mp4 dosya olabilir ve daha az fazla 3 MB olmalıdır",
    "feed.text21": "kamu sonrası",
    "feed.text22": "özel mesaj",
    "feed.text23": "Arama için yazın ....",
    "feed.text24": "Kullanıcıyı ara ...",
    "__2": "",
    "userInfo.text1": "Kullanıcı bilgisi",
    "userInfo.text2": "isim",
    "userInfo.text3": "görüntü",
    "userInfo.text4": "oluşturulma tarihi",
    "userInfo.text5": "tamamen silme resimle hiçbir sorun var mı?",
    "userInfo.text6": "Yeni isim ...",
    "userInfo.text7": "Görüntü dosyası jpg, jpeg veya png dosyası olabilir, 1MB gerekir",
    "userInfo.text8": "Koyu Modu",
    "userInfo.text9": "Hakkında Kullanıcı",
    "userInfo.text10": "vb hakkınızda ek bilgiler, açıklama biyo ... (az 300 karakter)",
    "userInfo.text11": "Dil",
    "userInfo.text12": "Değişiklik",
    "userInfo.text13": "Bildirim",
    "__3": "",
    "auth.text1": "E-posta adresiniz",
    "auth.text2": "Adınız",
    "auth.text3": "Parola",
    "auth.text4": "Şifre 8 ila 20 karakter, en az bir büyük harf harf, bir küçük harf, bir rakam ve bir özel karakter olmalıdır (@ $!% *? &).",
    "auth.text5": "Kaydol",
    "auth.text6": "Oturum aç",
    "auth.text7": "Şifreyi unut",
    "auth.text8": "Kayıt Sayfası",
    "auth.text9": "parola sıfırlama e-posta adresi",
    "auth.text10": "eposta gönder",
    "auth.text11": "Doğrulama başarısız. Emin olun e-posta adresi henüz kullanılmamaktadır!",
    "auth.text12": "Kullanıcı girişi başarısız!",
    "auth.text13": "Geçersiz Şifre girildi edildi",
    "auth.text14": "Girilen e-posta adresi bulunamadı",
    "auth.text15": "Kullanıcı oluşturulamadı!",
    "auth.text16": "E-posta adresi başka bir hesap tarafından zaten kullanılıyor",
    "auth.text17": "Girilen Şifre göster",
    "auth.text18": "Şifreyi gizle",
    "auth.text19": "şifre kalanı için e-postanın gönderildiği",
    "auth.text20": "Enterd eposta bulunamadı",
    "auth.text21": "Şifre sıfırlama başarı",
    "auth.text22": "Şifre yenileme başarısız, tekrar şifre sıfırlama için Giriş sayfası ve e-posta göndermek gidiniz",
    "auth.text23": "Şifre sıfırlama sayfası",
    "auth.text24": "Yeni Şifre",
    "auth.text25": "Şifreyi yenile",
    "auth.text26": "Sayfa geçersiz veya Şifre zaten değiştirilir",
    "auth.text27": "parola sıfırlama e-posta başarısız gönderme Lütfen onay e-posta adresi",
    "auth.text28": "E-posta doğrulama sayfası",
    "auth.text29": "başarı doğrulama E-posta, yukarıda gösterilen giriş sayfasına gidin lütfen",
    "auth.text30": "E-posta doğrulaması başarısız oldu. Bu sayfa süresi dolmuş veya doğrulama zaten bitti.",
    "auth.text31": "Doğrulama Postası gönderildi. Plese e-postanızı kontrol ve doğrulamak. (Ayrıca istenmeyen postada chack)",
    "auth.text32": "E-posta Doğrulama Giriş için gereklidir. E-posta adresine doğrulama için posta gönderin.",
    "auth.text33": "doğrulama için posta Gönder",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "besleme",
    "nav.text2": "Oturum aç",
    "nav.text3": "Kaydol",
    "nav.text4": "Kullanıcı bilgisi",
    "nav.text5": "Çıkış Yap",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Sayfa bulunamadı",
    "notFound.text2": "besleme sayfasına gitmek",
    "notFound.text3": "Giriş sayfasını açıp",
    "notFound.text4": "Bugün nereye gider?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "İptal etmek",
    "comment.text2": "Sil",
    "comment.text3": "Yorum Gönder",
    "comment.text4": "Yanıtları göster",
    "comment.text5": "Toplam",
    "comment.text6": "Yaz Cevap",
    "comment.text7": "gizle Yanıtla",
    "comment.text8": "Yorumlar",
    "comment.text9": "Tamamen Bu yorumun Bu yorumu ve yanıtları silmek için sorun var mı?",
    "comment.text10": "Tamamen bu yorumu silmek için sorun var mı?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Favori Post'a ekle",
    "follow.text2": "favori görevinden Sil",
    "follow.text3": "favori kullanıcıya ekle",
    "follow.text4": "favori kullanıcıdan Sil",
    "follow.text5": "gösteri mesajlar",
    "follow.text6": "Sevdiğiniz Kullanıcılar",
    "follow.text7": "Mesaj Favori Kullanıcılar",
    "__11": "",
    "privacyPolicy.text1": "Gizlilik Politikası",
    "privacyPolicy.text2": "Bu politika güncellenmiş veya haber verilmeksizin değiştirilebilir. Bu gizlilik politikası değişiklikler hakkında bilgi fiyatının kullanıcının sorumluluğundadır. sizi etkileyebilecek diğer gizlilik sorunları dikkate alın:",
    "privacyPolicy.text3": "Bu web sitesi tehlikeye olabilir.",
    "privacyPolicy.text4": "Bilgisayarınız tehlikeye olabilir.",
    "privacyPolicy.text5": "Bu web sitesi Firebase ve DigitalOcean altyapıları barındırılmaktadır. Olabilir ve sunucularında kendi izleme sistemlerini var. Bu hizmetler kendi gizlilik politikaları vardır ve bu gizlilik politikası kapsamında değildir.",
    "privacyPolicy.text6": "Bu web sitesi saklar e-posta, şifre şifreli formu ve barındırılan Firebase ve DigitalOcean altyapıların kullanıcılara diğer girdiler.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Kullanım Şartları",
    "termsOfUse.text2": "Gizlilik",
    "termsOfUse.text3": "Gizliliğiniz bizim için önemlidir. Lütfen okuyun",
    "termsOfUse.text4": "Gizlilik Politikası",
    "termsOfUse.text5": "daha fazla bilgi için.",
    "termsOfUse.text6": "Kendi sorumlulukları",
    "termsOfUse.text7": "Sen, kullanıcı, sizin yargı yasa ve vergi ile kendi uyumu sağlamaktan yalnızca siz sorumlusunuz. Kendi güvenliği için tamamen size aittir.",
    "termsOfUse.text8": "Garanti Reddi",
    "termsOfUse.text9": "Bu site sağlanır GARANTİ VEYA HERHANGİ BİR KOŞUL OLMAKSIZIN ESASLAR \"AS IS\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Başlangıç ​​Bağlan",
    "videoTalk.text2": "Dur Bağlan",
    "videoTalk.text3": "çevrimiçi Kullanıcılar",
    "videoTalk.text4": "konuşmak başla",
    "videoTalk.text5": "Sizin konuşma çalışıyoruz",
    "videoTalk.text6": "Başka hiçbir çevrimiçi kullanıcı var",
    "videoTalk.text7": "Sen konuşuyorsun",
    "videoTalk.text8": "Sen konuşmaya başladı",
    "videoTalk.text9": "Metni Göster Konuşma",
    "videoTalk.text10": "metin girişi ....",
    "videoTalk.text11": "Hala konuşuyorsun. Konuştuğunuz durdurmak istiyor musunuz?",
    "videoTalk.text12": "Arayan Kullanıcının bağlantısı",
    "videoTalk.text13": "Kullanıcı seni aramıyor. bu aramayı kabul ediyor musunuz?",
    "videoTalk.text14": "Çağrınızı reddetmek",
    "videoTalk.text15": "Online kullanıcıyı ara ...",
    "videoTalk.text16": "kullanıcıyı ara ...",
    "__14": "",
    "groupTalk.text1": "yaratıcı",
    "groupTalk.text2": "Açıklama",
    "groupTalk.text3": "oluşturulma tarihi",
    "groupTalk.text4": "Düzenleme grup bilgisi",
    "groupTalk.text5": "Bu grubun üyeleri sizi silmek için sorun var mı?",
    "groupTalk.text6": "Üye konumundan çık",
    "groupTalk.text7": "Gruba katılmak",
    "groupTalk.text8": "Göster Grubu Üyeleri",
    "groupTalk.text9": "Bu Gruba Katılmak İstiyoruz?",
    "groupTalk.text10": "Grup ismi",
    "groupTalk.text11": "Listeye geri dön",
    "groupTalk.text12": "onaylayın",
    "groupTalk.text13": "Grup oluştur",
    "groupTalk.text14": "Güncelleme Grup",
    "groupTalk.text15": "Gruptan Çık",
    "groupTalk.text16": "Login Homepage",
    "groupTalk.text17": "Grup adı (5-100 karakter)",
    "groupTalk.text18": "grubu tanımı (en az 500 karakter)"
  },
  {
    "LANGUAGE": "Tatar",
    "CODE": "tt",
    "test.text1": "Сәлам",
    "test.text2": "hi",
    "test.text3": "Рәхмәт",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "туктау",
    "general.text2": "кабул итү",
    "general.text3": "Бетерү",
    "general.text4": "Бетерелгән",
    "general.text5": "редакиялңү",
    "general.text6": "Яңарту",
    "general.text7": "йөкләү Рәсмнең",
    "general.text8": "Бетерү Рәсмнең",
    "general.text9": "Рәсемнең",
    "general.text10": "бирү",
    "general.text11": "Керергә",
    "general.text12": "Теркәлергә",
    "general.text13": "Кулланучы Тыңларга",
    "general.text14": "Чыгарга",
    "general.text15": "файл булырга тиеш",
    "general.text16": "караганда азрак",
    "general.text17": "пароль",
    "general.text18": "Сайлап Хәбәрләр",
    "general.text19": "Хәбәрләр",
    "general.text20": "images",
    "general.text21": "Видеолар",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "яңа почта",
    "feed.text2": "Кулланучы хәбәрләрен күрсәтү",
    "feed.text3": "күрсәтергә Хәбәрләр",
    "feed.text4": "караш",
    "feed.text5": "редакиялңү",
    "feed.text6": "delete",
    "feed.text7": "тулысынча бетерү вазыйфасына юк проблема булып тора?",
    "feed.text8": "буенча урнаштырылды",
    "feed.text9": "Ясалган",
    "feed.text10": "титул",
    "feed.text11": "Рәсемнең",
    "feed.text12": "эчтәлек",
    "feed.text13": "ачык",
    "feed.text14": "хосусый",
    "feed.text15": "вазыйфасына Дәрәҗәсе",
    "feed.text16": "Эчтәлек por почта",
    "feed.text17": "delete",
    "feed.text18": "Бу тулысынча бетерә сынга юк проблемдир?",
    "feed.text19": "Бетерелгән",
    "feed.text20": "Медиа Файл jpg, jpeg, png яки mp4 файл булырга, һәм азрак 3Mb артык тиеш",
    "feed.text21": "җәмәгать почта",
    "feed.text22": "шәхси почта",
    "feed.text23": "Эзләү өчен кертегез ....",
    "feed.text24": "Кулланучы Эзләү ...",
    "__2": "",
    "userInfo.text1": "Кулланучы мәгълүмат",
    "userInfo.text2": "исем",
    "userInfo.text3": "сурәт",
    "userInfo.text4": "халык дата",
    "userInfo.text5": "Бу тулысынча бетерә сынга юк проблемдир?",
    "userInfo.text6": "Яңа исеме ...",
    "userInfo.text7": "Рәсемнең файл jpg, jpeg, яки png файл булырга, һәм азрак 1MB артык тиеш",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "турында Кулланучы",
    "userInfo.text10": "с сез турында өстәмә мәгълүмат, тасвирлау bio ... (азрак 300 characters)",
    "userInfo.text11": "тел",
    "userInfo.text12": "үзгәртү",
    "userInfo.text13": "белдерү",
    "__3": "",
    "auth.text1": "Сезнең E-mail:",
    "auth.text2": "Исемегез",
    "auth.text3": "пароль",
    "auth.text4": "Серсүз 8 20 characters, кимендә бер хәреф, бер Keçkenä хат, бер саны һәм бер махсус характер булырга тиеш (@ $!% *? Карау).",
    "auth.text5": "Теркәлергә",
    "auth.text6": "Керергә",
    "auth.text7": "Серсүз онытма",
    "auth.text8": "Катнашу Бит",
    "auth.text9": "әрҗәгә өчен E-mail адресы",
    "auth.text10": "E-mail:",
    "auth.text11": "Тикшерү алмады. Make ышанам почта адресыгыз әле кулланыла түгел!",
    "auth.text12": "Кулланучы кереше алмады!",
    "auth.text13": "Invalid Серсүз кердеңме иде",
    "auth.text14": "Кердеңме электрон почта адресы табып булмый иде",
    "auth.text15": "Кулланучы халык алмады!",
    "auth.text16": "Электрон почта адресы тагын вакыйгадан инде файдаланыла,",
    "auth.text17": "Кердеңме Серсүз күрсәтергә",
    "auth.text18": "Яшерергә Серсүз",
    "auth.text19": "серсүз ял электрон почта җибәрелгән",
    "auth.text20": "Enterd электрон почта булмаган",
    "auth.text21": "Серсүз кабызу уңышлар",
    "auth.text22": "Серсүз кабызу алмады, кабат әрҗәгә өчен Керү бит һәм җибәрер электрон почта барырга итегез",
    "auth.text23": "Серсүз кабызу бит",
    "auth.text24": "Яңа серсүз",
    "auth.text25": "әрҗәгә Серсүз",
    "auth.text26": "Бит маташтылар, яки Серсүз инде алмашына",
    "auth.text27": "әрҗәгә өчен электрон почта кимемәде, көт итегез тикшерү электрон почта адресы",
    "auth.text28": "Email тикшерү бит",
    "auth.text29": "уңышлар тикшерү Email, өстә күрсәтелгән логин бит барырга итегез",
    "auth.text30": "Email тикшерү алмады. Бу битне идәнгә, яки тикшерү инде тәмамланды.",
    "auth.text31": "Йохланылмасы Mail җибәрелгән иде. Plese почта тикшерү һәм исбатлау. (Шулай ук ​​спам почта чәк)",
    "auth.text32": "Email йохланылмасы Керү таләп ителә. сезнең электрон почта адресына тикшерү өчен Email җибәрегез.",
    "auth.text33": "тикшерү өчен Email Җибәрү",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "бирү",
    "nav.text2": "Керергә",
    "nav.text3": "Теркәлергә",
    "nav.text4": "Кулланучы Тыңларга",
    "nav.text5": "Чыгарга",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Бит табылмады",
    "notFound.text2": "тасмасы бит барырга",
    "notFound.text3": "логин бит барырга",
    "notFound.text4": "Син бүген кайда гидйәрлер?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "туктау",
    "comment.text2": "Бетерү",
    "comment.text3": "post Comment",
    "comment.text4": "күрсәтергә җавап",
    "comment.text5": "барлык",
    "comment.text6": "яз җавап",
    "comment.text7": "Яшерергә җавап",
    "comment.text8": "комментарийлар",
    "comment.text9": "Бу тулысынча бу комментарий бу комментарий һәм җаваплар бетерергә юк проблемдир?",
    "comment.text10": "Бу тулысынча бу комментарий бетерергә юк проблемдир?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Сайлап Post өстәргә",
    "follow.text2": "яраткан вазифасыннан Бетерү",
    "follow.text3": "яраткан кулланучы өстәргә",
    "follow.text4": "яраткан кулланучы Бетерү",
    "follow.text5": "шоу хәбәрләрен",
    "follow.text6": "Сезнең үткән Кулланучылар",
    "follow.text7": "Post үткән Кулланучылар",
    "__11": "",
    "privacyPolicy.text1": "Шәхсилек сәясәте",
    "privacyPolicy.text2": "Бу сәясәте яңартылу яки әйтмичә чыгылган мөмкин. Бу хосусыйлык политикасы үзгәрешләр турында хәбәр итте калырга кулланучының җаваплы. Сез ничек тәэсир итәргә мөмкин, дип башка хосусыйлык мәсьәләләре игътибар итеп:",
    "privacyPolicy.text3": "Бу сайтта калмагач мөмкин.",
    "privacyPolicy.text4": "Сезнең компьютер калмагач мөмкин.",
    "privacyPolicy.text5": "Бу сайтта Firebase һәм DigitalOcean инфраструктурасы буенча алып бара. Алар өчен һәм аларның серверларында үз кит системалары бар. Хезмәтләр үзләренең шәхси хосусыйлык сийасят бар һәм алар бу хосусыйлык политикасы белән капланмый.",
    "privacyPolicy.text6": "Бу сайтта кибет, электрон почта, серсүзне шифрланган форма, һәм узды Firebase һәм DigitalOcean инфраструктурасы да кулланучылар башка китерелә.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Куллану шартлары",
    "termsOfUse.text2": "Яшеренлек",
    "termsOfUse.text3": "Сезнең хосусыйлык безнең өчен мөһим. укыганга итегез",
    "termsOfUse.text4": "шәхсилек сәясәте",
    "termsOfUse.text5": "күбрәк мәгълүмат.",
    "termsOfUse.text6": "Сезнең үз бурычларын",
    "termsOfUse.text7": "Син, кулланучының сезнең юрисдикция законнар һәм салым белән үз нигезендә тәэмин итү өчен генә җаваплы. Сез үз куркынычсызлыгы өчен генә җаваплы.",
    "termsOfUse.text8": "Гарантия буенча Warranties",
    "termsOfUse.text9": "Бу сайт нче каралган гарантияләр ЯКИ теләсә нинди шартлар куймыйча, ТАЯНЫП \"кебек\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "башлау Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Онлайн Кулланучылар",
    "videoTalk.text4": "сөйләшергә башлагыз",
    "videoTalk.text5": "Сезнең бәхәсе тырыша",
    "videoTalk.text6": "Юк башка онлайн кулланучы барлыгына",
    "videoTalk.text7": "Син бара",
    "videoTalk.text8": "Сез сөйләшә башлады",
    "videoTalk.text9": "Күрсәтергә Текст бәхәсе",
    "videoTalk.text10": "текстны тапшыру ....",
    "videoTalk.text11": "Син һаман да бара. Сез сөйлисе килә, беләсезме?",
    "videoTalk.text12": "Creed кулланучы өзелде",
    "videoTalk.text13": "кулланучының сине чакыра. Сез бу чакыруны кабул беләсезме?",
    "videoTalk.text14": "сорыйсыз инкяр",
    "videoTalk.text15": "онлайн кулланучы эзләү ...",
    "videoTalk.text16": "кулланучының эзләү ...",
    "__14": "",
    "groupTalk.text1": "Алаһудан",
    "groupTalk.text2": "тасвир",
    "groupTalk.text3": "халык дата",
    "groupTalk.text4": "Үзгәртү төркем белешмә",
    "groupTalk.text5": "Бу төркем әгъзалары сез бетерергә юк проблемдир?",
    "groupTalk.text6": "Әгъзасы китә",
    "groupTalk.text7": "төркеме кушылыгыз",
    "groupTalk.text8": "Шоу-группасы әгъзалары",
    "groupTalk.text9": "Сез бу төркеме кушылыгыз киләме?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Исемлегенә артка",
    "groupTalk.text12": "сезнең тапшыру расла",
    "groupTalk.text13": "Create Group",
    "groupTalk.text14": "Яңарту төркеме",
    "groupTalk.text15": "Төркеме Чыгу",
    "groupTalk.text16": "Керү өчен албит барыгыз",
    "groupTalk.text17": "төркем name (5-100 characters)",
    "groupTalk.text18": "төркем тасвирлау (азрак 500 characters)"
  },
  {
    "LANGUAGE": "Tsonga",
    "CODE": "ts",
    "test.text1": "#VALUE!",
    "test.text2": "#VALUE!",
    "test.text3": "#VALUE!",
    "test.text4": "#VALUE!",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "#VALUE!",
    "feed.text2": "#VALUE!",
    "feed.text3": "#VALUE!",
    "feed.text4": "#VALUE!",
    "feed.text5": "#VALUE!",
    "feed.text6": "#VALUE!",
    "feed.text7": "#VALUE!",
    "feed.text8": "#VALUE!",
    "feed.text9": "#VALUE!",
    "feed.text10": "#VALUE!",
    "feed.text11": "#VALUE!",
    "feed.text12": "#VALUE!",
    "feed.text13": "#VALUE!",
    "feed.text14": "#VALUE!",
    "feed.text15": "#VALUE!",
    "feed.text16": "#VALUE!",
    "feed.text17": "#VALUE!",
    "feed.text18": "#VALUE!",
    "feed.text19": "#VALUE!",
    "feed.text20": "#VALUE!",
    "feed.text21": "#VALUE!",
    "feed.text22": "#VALUE!",
    "feed.text23": "#VALUE!",
    "feed.text24": "#VALUE!",
    "__2": "",
    "userInfo.text1": "#VALUE!",
    "userInfo.text2": "#VALUE!",
    "userInfo.text3": "#VALUE!",
    "userInfo.text4": "#VALUE!",
    "userInfo.text5": "#VALUE!",
    "userInfo.text6": "#VALUE!",
    "userInfo.text7": "#VALUE!",
    "userInfo.text8": "#VALUE!",
    "userInfo.text9": "#VALUE!",
    "userInfo.text10": "#VALUE!",
    "userInfo.text11": "#VALUE!",
    "userInfo.text12": "#VALUE!",
    "userInfo.text13": "#VALUE!",
    "__3": "",
    "auth.text1": "#VALUE!",
    "auth.text2": "#VALUE!",
    "auth.text3": "#VALUE!",
    "auth.text4": "#VALUE!",
    "auth.text5": "#VALUE!",
    "auth.text6": "#VALUE!",
    "auth.text7": "#VALUE!",
    "auth.text8": "#VALUE!",
    "auth.text9": "#VALUE!",
    "auth.text10": "#VALUE!",
    "auth.text11": "#VALUE!",
    "auth.text12": "#VALUE!",
    "auth.text13": "#VALUE!",
    "auth.text14": "#VALUE!",
    "auth.text15": "#VALUE!",
    "auth.text16": "#VALUE!",
    "auth.text17": "#VALUE!",
    "auth.text18": "#VALUE!",
    "auth.text19": "#VALUE!",
    "auth.text20": "#VALUE!",
    "auth.text21": "#VALUE!",
    "auth.text22": "#VALUE!",
    "auth.text23": "#VALUE!",
    "auth.text24": "#VALUE!",
    "auth.text25": "#VALUE!",
    "auth.text26": "#VALUE!",
    "auth.text27": "#VALUE!",
    "auth.text28": "#VALUE!",
    "auth.text29": "#VALUE!",
    "auth.text30": "#VALUE!",
    "auth.text31": "#VALUE!",
    "auth.text32": "#VALUE!",
    "auth.text33": "#VALUE!",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "#VALUE!",
    "nav.text2": "#VALUE!",
    "nav.text3": "#VALUE!",
    "nav.text4": "#VALUE!",
    "nav.text5": "#VALUE!",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "#VALUE!",
    "notFound.text2": "#VALUE!",
    "notFound.text3": "#VALUE!",
    "notFound.text4": "#VALUE!",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "#VALUE!",
    "comment.text2": "#VALUE!",
    "comment.text3": "#VALUE!",
    "comment.text4": "#VALUE!",
    "comment.text5": "#VALUE!",
    "comment.text6": "#VALUE!",
    "comment.text7": "#VALUE!",
    "comment.text8": "#VALUE!",
    "comment.text9": "#VALUE!",
    "comment.text10": "#VALUE!",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "#VALUE!",
    "follow.text2": "#VALUE!",
    "follow.text3": "#VALUE!",
    "follow.text4": "#VALUE!",
    "follow.text5": "#VALUE!",
    "follow.text6": "#VALUE!",
    "follow.text7": "#VALUE!",
    "__11": "",
    "privacyPolicy.text1": "#VALUE!",
    "privacyPolicy.text2": "#VALUE!",
    "privacyPolicy.text3": "#VALUE!",
    "privacyPolicy.text4": "#VALUE!",
    "privacyPolicy.text5": "#VALUE!",
    "privacyPolicy.text6": "#VALUE!",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "#VALUE!",
    "termsOfUse.text2": "#VALUE!",
    "termsOfUse.text3": "#VALUE!",
    "termsOfUse.text4": "#VALUE!",
    "termsOfUse.text5": "#VALUE!",
    "termsOfUse.text6": "#VALUE!",
    "termsOfUse.text7": "#VALUE!",
    "termsOfUse.text8": "#VALUE!",
    "termsOfUse.text9": "#VALUE!",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "#VALUE!",
    "videoTalk.text2": "#VALUE!",
    "videoTalk.text3": "#VALUE!",
    "videoTalk.text4": "#VALUE!",
    "videoTalk.text5": "#VALUE!",
    "videoTalk.text6": "#VALUE!",
    "videoTalk.text7": "#VALUE!",
    "videoTalk.text8": "#VALUE!",
    "videoTalk.text9": "#VALUE!",
    "videoTalk.text10": "#VALUE!",
    "videoTalk.text11": "#VALUE!",
    "videoTalk.text12": "#VALUE!",
    "videoTalk.text13": "#VALUE!",
    "videoTalk.text14": "#VALUE!",
    "videoTalk.text15": "#VALUE!",
    "videoTalk.text16": "#VALUE!",
    "__14": "",
    "groupTalk.text1": "#VALUE!",
    "groupTalk.text2": "#VALUE!",
    "groupTalk.text3": "#VALUE!",
    "groupTalk.text4": "#VALUE!",
    "groupTalk.text5": "#VALUE!",
    "groupTalk.text6": "#VALUE!",
    "groupTalk.text7": "#VALUE!",
    "groupTalk.text8": "#VALUE!",
    "groupTalk.text9": "#VALUE!",
    "groupTalk.text10": "#VALUE!",
    "groupTalk.text11": "#VALUE!",
    "groupTalk.text12": "#VALUE!",
    "groupTalk.text13": "#VALUE!",
    "groupTalk.text14": "#VALUE!",
    "groupTalk.text15": "#VALUE!",
    "groupTalk.text16": "#VALUE!",
    "groupTalk.text17": "#VALUE!",
    "groupTalk.text18": "#VALUE!"
  },
  {
    "LANGUAGE": "Ukrainian",
    "CODE": "uk",
    "test.text1": "Здрастуйте",
    "test.text2": "Привіт",
    "test.text3": "Дякую",
    "test.text4": "карта",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "скасувати",
    "general.text2": "приймати",
    "general.text3": "видалити",
    "general.text4": "виключений",
    "general.text5": "редагувати",
    "general.text6": "оновлення",
    "general.text7": "Завантажити зображення",
    "general.text8": "видалити зображення",
    "general.text9": "зображення",
    "general.text10": "подача",
    "general.text11": "Ввійти",
    "general.text12": "Реєстрація",
    "general.text13": "Інформація про користувача",
    "general.text14": "Вийти",
    "general.text15": "Файл повинен бути",
    "general.text16": "і менше",
    "general.text17": "пароль",
    "general.text18": "Вибрані повідомлення",
    "general.text19": "повідомлень",
    "general.text20": "зображень",
    "general.text21": "Відео",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "нове повідомлення",
    "feed.text2": "Показати повідомлення, написані",
    "feed.text3": "Показати повідомлення",
    "feed.text4": "вид",
    "feed.text5": "редагувати",
    "feed.text6": "видаляти",
    "feed.text7": "Хіба це не проблема, щоб видалити повідомлення повністю?",
    "feed.text8": "Автор",
    "feed.text9": "Створено",
    "feed.text10": "титульний",
    "feed.text11": "зображення",
    "feed.text12": "зміст",
    "feed.text13": "громадськості",
    "feed.text14": "приватний",
    "feed.text15": "Тема поста",
    "feed.text16": "Зміст Por повідомлення",
    "feed.text17": "видаляти",
    "feed.text18": "Хіба це не проблема видалення зображення повністю?",
    "feed.text19": "виключений",
    "feed.text20": "Media File повинен бути JPG, JPEG, PNG або файл mp4, і менше, ніж 3MB",
    "feed.text21": "громадську повідомлення",
    "feed.text22": "приватне повідомлення",
    "feed.text23": "Виконайте пошук за словом ....",
    "feed.text24": "Пошук користувача ...",
    "__2": "",
    "userInfo.text1": "інформація про користувача",
    "userInfo.text2": "ім'я",
    "userInfo.text3": "зображення",
    "userInfo.text4": "дата створення",
    "userInfo.text5": "Хіба це не проблема видалення зображення повністю?",
    "userInfo.text6": "Нове ім'я ...",
    "userInfo.text7": "Файл зображення повинен бути JPG, JPEG або PNG-файл, і менше 1 МБ",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "Доступна інформація про",
    "userInfo.text10": "Додаткова інформація про Вас, опис біо і т.д ... (менш 300 символів)",
    "userInfo.text11": "Мову",
    "userInfo.text12": "зміна",
    "userInfo.text13": "повідомлення",
    "__3": "",
    "auth.text1": "Твоя електронна пошта",
    "auth.text2": "Твоє ім'я",
    "auth.text3": "пароль",
    "auth.text4": "Пароль повинен бути від 8 до 20 символів, щонайменше, один верхній регістр букв, одна мала літера, один номер і один спеціальний символ (@ $!% *? &).",
    "auth.text5": "Реєстрація",
    "auth.text6": "Ввійти",
    "auth.text7": "забути пароль",
    "auth.text8": "Реєстрація Page",
    "auth.text9": "Адреса електронної пошти для скидання пароля",
    "auth.text10": "відправити лист",
    "auth.text11": "Перевірка не вдалася. Переконайтеся, що адреса електронної пошти не використовується!",
    "auth.text12": "Логін користувача не вдалося!",
    "auth.text13": "Неправильний пароль був введений",
    "auth.text14": "Зазначену адресу електронної пошти не знайдено",
    "auth.text15": "Створення користувача не вдалося!",
    "auth.text16": "Адреса електронної пошти вже використовується іншим користувачем записи",
    "auth.text17": "Показати введений пароль",
    "auth.text18": "приховати Пароль",
    "auth.text19": "електронна пошта для пароля відпочинку була відправлена",
    "auth.text20": "Enterd електронної пошти не знайдено",
    "auth.text21": "Успіх відновлення пароля",
    "auth.text22": "Скидання пароля невдалий, будь ласка, перейдіть на сторінку входу в систему і відправити по електронній пошті для скидання пароля ще раз",
    "auth.text23": "сторінка Відновлення пароля",
    "auth.text24": "новий пароль",
    "auth.text25": "Скинути пароль",
    "auth.text26": "Сторінка недійсна або пароль вже змінений",
    "auth.text27": "Відправлення електронної пошти для скидання пароля не минуло, перевірте адреса електронної пошти",
    "auth.text28": "сторінка перевірки електронної пошти",
    "auth.text29": "Email підтвердження успіху, будь ласка, перейдіть на сторінку входу, показану вище",
    "auth.text30": "Email перевірки не вдалося. Ця сторінка закінчився, або перевірка вже закінчена.",
    "auth.text31": "Пошта Перевірка була відправлена. Plese перевірити свою електронну пошту, щоб переглянути. (Також Chack в спам)",
    "auth.text32": "Підтвердження адреси електронної пошти необхідно увійти в систему. Будь ласка, надсилайте на e-mail для перевірки на Вашу електронну адресу електронної пошти.",
    "auth.text33": "Надіслати Email для перевірки",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "подача",
    "nav.text2": "Ввійти",
    "nav.text3": "Реєстрація",
    "nav.text4": "Інформація про користувача",
    "nav.text5": "Вийти",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Сторінку не знайдено",
    "notFound.text2": "перейти на сторінку подачі",
    "notFound.text3": "перейти на сторінку входу",
    "notFound.text4": "Де ви сьогодні?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "скасувати",
    "comment.text2": "видалити",
    "comment.text3": "повідомлення Коментар",
    "comment.text4": "Показати відповідь",
    "comment.text5": "загальний",
    "comment.text6": "написати відповідь",
    "comment.text7": "приховати Відповісти",
    "comment.text8": "Коментарі",
    "comment.text9": "Хіба це не проблема, щоб видалити цей коментар і відповіді на цей коментар повністю?",
    "comment.text10": "Хіба це не проблема, щоб повністю видалити цей коментар?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Додати в обране повідомлення",
    "follow.text2": "Видалити з улюбленого поста",
    "follow.text3": "Додати в обране користувач",
    "follow.text4": "Видалити з улюбленого користувача",
    "follow.text5": "показати повідомлення",
    "follow.text6": "Ваші улюблені Користувачі",
    "follow.text7": "Повідомлення Улюблені Користувачі",
    "__11": "",
    "privacyPolicy.text1": "Політика конфіденційності",
    "privacyPolicy.text2": "Ця політика може бути оновлена ​​або змінена без попереднього повідомлення. Це відповідальність користувача Залишитися поінформованим про зміни політики конфіденційності. Зверніть увагу на інші питання конфіденційності, які можуть вплинути на вас:",
    "privacyPolicy.text3": "Цей веб-сайт може бути поставлена ​​під загрозу.",
    "privacyPolicy.text4": "Ваш комп'ютер може бути поставлена ​​під загрозу.",
    "privacyPolicy.text5": "Цей веб-сайт розміщений на Firebase і DigitalOcean інфраструктури. Вони можуть і не мати свої власні системи стеження на своїх серверах. Ці послуги мають свою власну політику конфіденційності, і вони не охоплені цією політикою конфіденційності.",
    "privacyPolicy.text6": "Цей сайт зберігає електронну пошту, зашифрованому вигляді пароля, а також інші входи користувачів на розміщення Firebase і DigitalOcean інфраструктури.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Умови користування",
    "termsOfUse.text2": "секретність",
    "termsOfUse.text3": "Ваша конфіденційність дуже важлива для нас. Будь ласка, прочитайте",
    "termsOfUse.text4": "політика конфіденційності",
    "termsOfUse.text5": "для отримання додаткової інформації.",
    "termsOfUse.text6": "Ваші власні обов'язки",
    "termsOfUse.text7": "Ви, користувач, несете повну відповідальність за забезпечення власного дотримання законів і податками в вашій юрисдикції. Ви несете повну відповідальність за свою власну безпеку.",
    "termsOfUse.text8": "Відмова від гарантії",
    "termsOfUse.text9": "Цей сайт надається за принципом «як є», БЕЗ ГАРАНТІЙ АБО УМОВ БУДЬ.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "стоп Connect",
    "videoTalk.text3": "Користувачі на сайті",
    "videoTalk.text4": "починають говорити",
    "videoTalk.text5": "Ви намагаєтеся говорити",
    "videoTalk.text6": "Жоден інший онлайн користувач не існує",
    "videoTalk.text7": "ви розмовляєте",
    "videoTalk.text8": "Ви почали говорити",
    "videoTalk.text9": "Показати текст Обговорення",
    "videoTalk.text10": "введення тексту ....",
    "videoTalk.text11": "Ви все ще говорите. Ви хочете, щоб припинити говорити?",
    "videoTalk.text12": "Виклик користувача відключений",
    "videoTalk.text13": "користувач дзвонить вам. Чи приймаєте ви цей виклик?",
    "videoTalk.text14": "відхилити виклик",
    "videoTalk.text15": "Пошук онлайн-користувачів ...",
    "videoTalk.text16": "Пошук користувачів ...",
    "__14": "",
    "groupTalk.text1": "творець",
    "groupTalk.text2": "опис",
    "groupTalk.text3": "дата створення",
    "groupTalk.text4": "Редагування інформації про групу",
    "groupTalk.text5": "Хіба це не проблема, щоб видалити вас з членів цієї групи?",
    "groupTalk.text6": "Виходимо з члена",
    "groupTalk.text7": "Приєднатись до групи",
    "groupTalk.text8": "Показати Члени групи",
    "groupTalk.text9": "Чи хочете ви приєднатися до цієї групи?",
    "groupTalk.text10": "Назва групи",
    "groupTalk.text11": "Назад до списку",
    "groupTalk.text12": "Підтвердіть введення",
    "groupTalk.text13": "створити групу",
    "groupTalk.text14": "Група Update",
    "groupTalk.text15": "Вихід з групи",
    "groupTalk.text16": "Перейти на головну сторінку до Логін",
    "groupTalk.text17": "Ім'я групи (5-100 символів)",
    "groupTalk.text18": "Опис групи (менше 500 символів)"
  },
  {
    "LANGUAGE": "Urdu",
    "CODE": "ur",
    "test.text1": "ہیلو",
    "test.text2": "ہیلو",
    "test.text3": "آپ کا شکریہ",
    "test.text4": "نقشہ",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "منسوخ کریں",
    "general.text2": "قبول کریں",
    "general.text3": "حذف کریں",
    "general.text4": "خارج کر دیا گیا",
    "general.text5": "ترمیم",
    "general.text6": "اپ ڈیٹ",
    "general.text7": "تصویر انٹرنیٹ پر ڈالنا",
    "general.text8": "حذف کریں تصویری",
    "general.text9": "تصویر",
    "general.text10": "کھانا کھلانا",
    "general.text11": "لاگ ان کریں",
    "general.text12": "سائن اپ",
    "general.text13": "صارف کی معلومات",
    "general.text14": "لاگ آوٹ",
    "general.text15": "فائل ہونا چاہئے",
    "general.text16": "اور سے کم",
    "general.text17": "پاس ورڈ",
    "general.text18": "پسندیدہ مراسلات",
    "general.text19": "پوسٹس",
    "general.text20": "امیجز",
    "general.text21": "ویڈیوز",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "نئی پوسٹ",
    "feed.text2": "صارف مراسلات دکھائیں",
    "feed.text3": "تحاریر دکھائیں",
    "feed.text4": "قول",
    "feed.text5": "ترمیم",
    "feed.text6": "حذف کردیں",
    "feed.text7": "مکمل طور پر حذف پوسٹ پر کوئی مسئلہ ہے؟",
    "feed.text8": "کی طرف سے پوسٹ کیا گیا",
    "feed.text9": "بنائی گئی",
    "feed.text10": "سرخی",
    "feed.text11": "تصویر",
    "feed.text12": "مواد",
    "feed.text13": "عوام",
    "feed.text14": "نجی",
    "feed.text15": "عہدے کے لئے کی سرخی",
    "feed.text16": "مواد براہ مراسلہ",
    "feed.text17": "حذف کردیں",
    "feed.text18": "یہ مکمل طور پر حذف تصویر کو کوئی مسئلہ ہے؟",
    "feed.text19": "خارج کر دیا گیا",
    "feed.text20": "میڈیا فائل JPG، JPEG، PNG یا اتارنا Mp4 فائل ہونا چاہئے، اور 3MB سے کم",
    "feed.text21": "عوامی اشاعت",
    "feed.text22": "نجی اشاعت",
    "feed.text23": "کے لئے تلاش ٹائپ ....",
    "feed.text24": "صارف کی تلاش ...",
    "__2": "",
    "userInfo.text1": "صارف کی معلومات",
    "userInfo.text2": "نام",
    "userInfo.text3": "تصویر",
    "userInfo.text4": "بنانے کی تاریخ",
    "userInfo.text5": "یہ مکمل طور پر حذف تصویر کو کوئی مسئلہ ہے؟",
    "userInfo.text6": "نیا نام دیں ...",
    "userInfo.text7": "تصویری فائل JPG، JPEG، یا PNG فائل ہونا چاہئے، اور 1MB سے کم",
    "userInfo.text8": "گہرا موڈ",
    "userInfo.text9": "کے بارے میں صارف",
    "userInfo.text10": "آپ کے بارے میں عام معلومات، تفصیل تعارف وغیرہ ... (300 سے کم حروف)",
    "userInfo.text11": "زبان",
    "userInfo.text12": "تبدیل کریں",
    "userInfo.text13": "بارے میں اہم اطلاع",
    "__3": "",
    "auth.text1": "آپ کا ای میل",
    "auth.text2": "تمھارا نام",
    "auth.text3": "پاس ورڈ",
    "auth.text4": "پاس ورڈ 8 سے 20 حروف، کم از کم ایک بڑے خط، ایک چھوٹے خط، ایک نمبر اور ایک خاص کردار ہونا چاہئے (@ $! *؟ &).",
    "auth.text5": "سائن اپ",
    "auth.text6": "لاگ ان کریں",
    "auth.text7": "پاس ورڈ بھول جانا",
    "auth.text8": "سائن اپ صفحہ",
    "auth.text9": "ای میل کے پاس ورڈ دوبارہ ترتیب دینے کیلئے پتہ",
    "auth.text10": "ای میل بھیجیں",
    "auth.text11": "جائز قرار دینے میں ناکام رہے. یقینی بنائیں کہ ای میل پتہ تک نہیں استعمال کیا جاتا ہے!",
    "auth.text12": "یوزر لاگ ان ناکام!",
    "auth.text13": "غلط پاس ورڈ درج کیا گیا تھا",
    "auth.text14": "درج کردہ ای میل ایڈریس نہیں پایا گیا",
    "auth.text15": "صارف کی تخلیق ناکام!",
    "auth.text16": "ای میل پتہ دوسرے اکاؤنٹ کی طرف سے پہلے سے استعمال میں ہے",
    "auth.text17": "داخل کر دیا گیا پاس ورڈ دکھائیں",
    "auth.text18": "پاس ورڈ چھپائیں",
    "auth.text19": "پاس ورڈ آرام کے لئے ای میل بھیجا گیا تھا",
    "auth.text20": "Enterd ای میل نہیں پایا گیا",
    "auth.text21": "پاس ورڈ دوبارہ ترتیب دینے کی کامیابی",
    "auth.text22": "پاس ورڈ دوبارہ ترتیب دینے میں ناکام، براہ مہربانی دوبارہ پاس ورڈ دوبارہ ترتیب دینے کے لیے لاگ ان پیج اور بھیجیں ای میل پر جانے",
    "auth.text23": "پاس ورڈ دوبارہ ترتیب دینے صفحہ",
    "auth.text24": "نیا پاس ورڈ",
    "auth.text25": "پاس ورڈ ری سیٹ",
    "auth.text26": "صفحہ غلط ہے یا پاس ورڈ کے پہلے ہی تبدیل کر دیا جاتا ہے",
    "auth.text27": "پاس ورڈ دوبارہ ترتیب دینے کے لئے ای میل بھیج رہا ہے ناکام، دوبارہ چیک کی ای میل پتہ",
    "auth.text28": "ای میل کی توثیق کے صفحے",
    "auth.text29": "کامیابی تصدیقی ای میل، براہ مہربانی اوپر دکھائے لاگ ان صفحے پر جانے",
    "auth.text30": "ای میل کی توثیق ناکام رہے. اس صفحہ کی میعاد ختم ہو جاتا ہے، یا تصدیق کے پہلے ہی ختم ہو گیا ہے.",
    "auth.text31": "توثیق میل بھیجا گیا تھا. تو plese آپ کے ای میل کی جانچ پڑتال اور تصدیق کریں. (بھی سپیم ای میل میں chack)",
    "auth.text32": "ای میل کی توثیق کریں لاگ ان کرنا ضروری ہے. اپنا ای میل ایڈریس کو توثیق کے لئے ای میل کریں.",
    "auth.text33": "تصدیق کے لیے ای میل ارسال کریں",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "کھانا کھلانا",
    "nav.text2": "لاگ ان کریں",
    "nav.text3": "سائن اپ",
    "nav.text4": "صارف کی معلومات",
    "nav.text5": "لاگ آوٹ",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "صفحہ نہیں ملا",
    "notFound.text2": "فیڈ کے صفحے پر جانے",
    "notFound.text3": "لاگ ان صفحے پر جانے",
    "notFound.text4": "آپ آج کہاں جاتے ہو؟",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "منسوخ کریں",
    "comment.text2": "حذف کریں",
    "comment.text3": "تبصرہ کریں",
    "comment.text4": "جواب دکھائیں",
    "comment.text5": "کل",
    "comment.text6": "لکھیں جواب",
    "comment.text7": "جواب چھپائیں",
    "comment.text8": "تبصرے",
    "comment.text9": "یہ اس تبصرہ اور مکمل طور پر اس تبصرہ کے جوابات کو حذف کرنے کا مسئلہ ہے؟",
    "comment.text10": "یہ مکمل طور پر یہ تبصرہ حذف کرنے کا مسئلہ ہے؟",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "پسندیدہ پوسٹ میں شامل کریں",
    "follow.text2": "پسندیدہ پوسٹ سے حذف",
    "follow.text3": "پسندیدہ صارف میں شامل کریں",
    "follow.text4": "پسندیدہ صارف سے حذف",
    "follow.text5": "تحاریر دکھائیں",
    "follow.text6": "آپ کا پسندیدہ صارفین",
    "follow.text7": "پوسٹ کردہ صارفین",
    "__11": "",
    "privacyPolicy.text1": "پرائیویسی پالیسی",
    "privacyPolicy.text2": "اس پالیسی کو اپ ڈیٹ یا بغیر نوٹس کے نظر ثانی کی جا سکتی ہے. یہ رازداری کی پالیسی تبدیلیوں کے بارے میں باخبر رہنے کے لئے صارف کی ذمہ داری ہے. آپ کو متاثر کر سکتا ہے کہ دیگر رازداری کے مسائل کو نوٹ کرلیں:",
    "privacyPolicy.text3": "یہ ویب سائٹ سے سمجھوتہ کیا جا سکتا ہے.",
    "privacyPolicy.text4": "آپ کے کمپیوٹر میں سمجھوتہ کیا جا سکتا ہے.",
    "privacyPolicy.text5": "یہ ویب سائٹ Firebase کی اور DigitalOcean بنیادی ڈھانچے پر ہوسٹ کی گئی ہے. انہوں لائیں اور ان کے سرورز پر ان کے اپنے سے باخبر رہنے کے نظام کی کیا ضرورت ہے. ان خدمات میں ان کے اپنے رازداری کی پالیسیوں ہے اور وہ اس کی رازداری کی پالیسی کی طرف سے احاطہ نہیں کر رہے ہیں.",
    "privacyPolicy.text6": "یہ ویب سائٹ اسٹورز ای میل، پاس ورڈ کے مرموز فارم، اور میزبانی Firebase کی اور DigitalOcean کے بنیادی ڈھانچے میں صارفین کی دیگر آدانوں.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "استعمال کرنے کی شرائط",
    "termsOfUse.text2": "پرائیویسی",
    "termsOfUse.text3": "آپ کی پرائیویسی ہمارے لئے اہم ہے. براہ مہربانی پڑھیں",
    "termsOfUse.text4": "رازداری کی پالیسی",
    "termsOfUse.text5": "مزید معلومات کے لیے.",
    "termsOfUse.text6": "آپ کی اپنی ذمہ داریاں",
    "termsOfUse.text7": "آپ، صارف، آپ کے دائرہ اختیار میں قوانین اور ٹیکس کے ساتھ آپ کے اپنے عمل کو یقینی بنانے کے لئے خود ذمہ دار ہیں. آپ اپنی ہی سیکورٹی کے لئے خود ذمہ دار ہیں.",
    "termsOfUse.text8": "وارنٹی کا اعلانات",
    "termsOfUse.text9": "یہ سائٹ ایک پر فراہم کی جاتی بنیاد \"جیسی ہے جہاں\"، وارنٹیوں یا کسی بھی قسم کی شرائط کے بغیر.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "شروع کریں کنیکٹ",
    "videoTalk.text2": "سٹاپ کنیکٹ",
    "videoTalk.text3": "Online کے صارفین",
    "videoTalk.text4": "بات کرنا شروع کریں",
    "videoTalk.text5": "آپ کی بات کرنے کی کوشش کر رہے ہیں",
    "videoTalk.text6": "کوئی دیگر آن لائن صارف موجود ہے",
    "videoTalk.text7": "تم سے بات کر رہے ہیں",
    "videoTalk.text8": "تم بات کرنا شروع کر دیا",
    "videoTalk.text9": "ہائے ٹاک شو",
    "videoTalk.text10": "متن ان پٹ ....",
    "videoTalk.text11": "آپ اب بھی بات کر رہے ہیں. آپ بات کرنا بند کرنا چاہتے ہیں؟",
    "videoTalk.text12": "کالنگ صارف منقطع",
    "videoTalk.text13": "صارف آپ کو بلا رہا ہے. آپ اس کال کو قبول کرتے ہیں؟",
    "videoTalk.text14": "آپ کی کال کو مسترد کرتے ہیں",
    "videoTalk.text15": "تلاش صارف ...",
    "videoTalk.text16": "صارف کی تلاش ...",
    "__14": "",
    "groupTalk.text1": "خالق",
    "groupTalk.text2": "تفصیل",
    "groupTalk.text3": "بنانے کی تاریخ",
    "groupTalk.text4": "گروپ میں ترمیم کی معلومات",
    "groupTalk.text5": "یہ اس گروپ کے ارکان کی طرف سے تم کو حذف کرنے کا مسئلہ ہے؟",
    "groupTalk.text6": "اراکین کی طرف سے چھوڑ دو",
    "groupTalk.text7": "گروپ میں شامل ہوں",
    "groupTalk.text8": "دکھائیں گروپ کے ممبران",
    "groupTalk.text9": "آپ اس گروپ میں شامل کرنا چاہتے ہیں؟",
    "groupTalk.text10": "گروہ کا نام",
    "groupTalk.text11": "فہرست پر واپس جائیں",
    "groupTalk.text12": "آپ ان پٹ کی توثیق کریں",
    "groupTalk.text13": "گروپ بنائیں",
    "groupTalk.text14": "اپ ڈیٹ گروپ",
    "groupTalk.text15": "گروپ سے باہر نکلیں",
    "groupTalk.text16": "لاگ ان کرنے کے لئے مرکزی صفحہ پر جائیں",
    "groupTalk.text17": "گروپ کا نام (5-100 حروف)",
    "groupTalk.text18": "گروپ کی وضاحت (500 حروف سے کم)"
  },
  {
    "LANGUAGE": "Uzbek (Latin)",
    "CODE": "uz",
    "test.text1": "Salom",
    "test.text2": "Hi",
    "test.text3": "rahmat",
    "test.text4": "xaritaga",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Bekor qilish",
    "general.text2": "qabul",
    "general.text3": "Delete",
    "general.text4": "o'chirilgan",
    "general.text5": "Edit",
    "general.text6": "yangilash",
    "general.text7": "Yuklab olish Image",
    "general.text8": "Delete Image",
    "general.text9": "surat",
    "general.text10": "yem",
    "general.text11": "Kirish",
    "general.text12": "Ro'yxatdan o'tish",
    "general.text13": "Foydalanuvchi Info",
    "general.text14": "Chiqish",
    "general.text15": "Fayl bo'lishi kerak",
    "general.text16": "dan va kam",
    "general.text17": "parol",
    "general.text18": "Sevimli Xabarlar",
    "general.text19": "Xabarlar",
    "general.text20": "tasvirlar",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Yangi xabar",
    "feed.text2": "Foydalanuvchining xabarlarini ko'rish",
    "feed.text3": "Show Posts",
    "feed.text4": "ko'rinish",
    "feed.text5": "tahrir",
    "feed.text6": "o'chirish",
    "feed.text7": "butunlay o lavozimiga hech qanday muammo bo'ladi?",
    "feed.text8": "Posted by",
    "feed.text9": "Tomonidan yaratilgan",
    "feed.text10": "sarlavha",
    "feed.text11": "surat",
    "feed.text12": "tarkib",
    "feed.text13": "ijtimoiy",
    "feed.text14": "shaxsiy",
    "feed.text15": "lavozimiga nomi",
    "feed.text16": "Tarkib por pochta",
    "feed.text17": "o'chirish",
    "feed.text18": "to'liq tasvir yo'q qilish uchun hech qanday muammo bormi?",
    "feed.text19": "o'chirilgan",
    "feed.text20": "Media fayl jpg, jpeg, gif yoki mp4 fayl bo'lishi, va kam 3 mb.dan ortiq kerak",
    "feed.text21": "davlat post",
    "feed.text22": "xususiy post",
    "feed.text23": "Izlash kiriting ....",
    "feed.text24": "Foydalanuvchilarni qidirish ...",
    "__2": "",
    "userInfo.text1": "Foydalanuvchi haqida ma'lumot",
    "userInfo.text2": "ism",
    "userInfo.text3": "surat",
    "userInfo.text4": "yaratish sana",
    "userInfo.text5": "to'liq tasvir yo'q qilish uchun hech qanday muammo bormi?",
    "userInfo.text6": "Yangi ismi ...",
    "userInfo.text7": "Tasvir fayl jpg, jpeg, yoki PNG fayl bo'lishi va 1MB kerak",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "haqida Foydalanuvchi",
    "userInfo.text10": "etc siz haqingizda qo'shimcha ma'lumot, Tavsif bio ... (kamida 300 belgi)",
    "userInfo.text11": "til",
    "userInfo.text12": "o'zgarish",
    "userInfo.text13": "bildirish",
    "__3": "",
    "auth.text1": "Sizning elektron manzilingiz",
    "auth.text2": "Ismingiz",
    "auth.text3": "parol",
    "auth.text4": "Parol 8 20 belgi, kamida bir bosh harf, bir kichik harf, bir qator va bir maxsus belgi bo'lishi kerak (@ $!% *? &).",
    "auth.text5": "Ro'yxatdan o'tish",
    "auth.text6": "Kirish",
    "auth.text7": "Parolni unutdingizmi",
    "auth.text8": "Ro'yxatdan o'tish sahifa",
    "auth.text9": "Parolni tiklash uchun E-mail manzili",
    "auth.text10": "E-mail jo'natish",
    "auth.text11": "Tasdiqlash muvaffaqiyatsiz. Ishonch hosil qiling elektron pochta manzili hali ishlatiladigan emas!",
    "auth.text12": "Foydalanuvchi Kirish muvaffaqiyatsiz tugadi!",
    "auth.text13": "Noto'g'ri parol kiritdingiz edi",
    "auth.text14": "Kiritilgan e-pochta manzili topilmadi",
    "auth.text15": "Foydalanuvchi yaratish muvaffaqiyatsiz tugadi!",
    "auth.text16": "elektron pochta manzili boshqa hisob tomonidan foydalanish allaqachon",
    "auth.text17": "Kiritilgan Parolni ko'rsatish",
    "auth.text18": "yashir Parol",
    "auth.text19": "Parolni dam olish uchun elektron pochta yuborilgan",
    "auth.text20": "Enterd email topilmadi",
    "auth.text21": "Parolni qayta tiklash muvaffaqiyat",
    "auth.text22": "Parolni qayta tiklash muvaffaqiyatsiz, yana parol tiklash uchun Kirish sahifasi va yuborish pochta o'ting",
    "auth.text23": "Parolni qayta tiklash sahifa",
    "auth.text24": "Yangi Parol",
    "auth.text25": "Reset Parol",
    "auth.text26": "Page yaroqsiz yoki Parolni allaqachon o'zgaradi",
    "auth.text27": "Parolni tiklash uchun elektron pochta jo'natish muvaffaqiyatsiz tugadi, Iltimos, tekshiring elektron pochta manzili",
    "auth.text28": "Email tekshirish sahifa",
    "auth.text29": "muvaffaqiyat tekshirish Email, yuqorida ko'rsatilgan kirish sahifasiga borib iltimos",
    "auth.text30": "Email tekshirib bo'lmadi. Bu sahifa muddati tugadi, yoki tekshirish allaqachon tugagan bo'ladi.",
    "auth.text31": "Tekshirish Mail yuborilgan edi. Iltimos e-pochtangizni tekshiring va tekshirish. (Shuningdek, spam pochtada chack)",
    "auth.text32": "Email tekshirish Kirish uchun talab qilinadi. Sizning email manzilingiz uchun tekshirish Email yuboring.",
    "auth.text33": "tekshirish uchun Email yuborish",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "yem",
    "nav.text2": "Kirish",
    "nav.text3": "Ro'yxatdan o'tish",
    "nav.text4": "Foydalanuvchi Info",
    "nav.text5": "Chiqish",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Sahifa topilmadi",
    "notFound.text2": "feed-sahifasiga o'ting",
    "notFound.text3": "kirish sahifasiga borib",
    "notFound.text4": "Bugun qaerga ketmoqchi qilasiz?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Bekor qilish",
    "comment.text2": "Delete",
    "comment.text3": "Post Comment",
    "comment.text4": "Show Javob",
    "comment.text5": "jami",
    "comment.text6": "yozish Javob",
    "comment.text7": "yashir Javob",
    "comment.text8": "Izohlar",
    "comment.text9": "to'liq Bu sharhga bu sharh va javoblar yo'q qilish uchun hech qanday muammo bormi?",
    "comment.text10": "to'liq, bu sharh yo'q qilish uchun, hech qanday muammo bormi?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Sevimli Post qo'shish",
    "follow.text2": "sevimli lavozimidan o'chirish",
    "follow.text3": "sevimli foydalanuvchiga qo'shish",
    "follow.text4": "sevimli foydalanuvchidan o'chirish",
    "follow.text5": "ko'rsatish xabarlar",
    "follow.text6": "Sizning Sevimli foydalanuvchilar",
    "follow.text7": "Post Sevimli foydalanuvchilar",
    "__11": "",
    "privacyPolicy.text1": "Maxfiylik siyosati",
    "privacyPolicy.text2": "Bu siyosat yangilangan yoki ogohlantirmagan holda qayta ko'rib mumkin. Bu Maxfiylik siyosati o'zgarishlar haqida xabardor qilish foydalanuvchining u javobgar. sizga ta'sir qilishi mumkin, boshqa shaxsiy masalalar eslatma oling:",
    "privacyPolicy.text3": "Ushbu veb-sayti xavf bo'lishi mumkin.",
    "privacyPolicy.text4": "Sizning kompyuteringiz xavf bo'lishi mumkin.",
    "privacyPolicy.text5": "Ushbu veb-sayti Firebase va DigitalOcean infratuzilmalari joylashtirilgan. Ular may va ularning serverlar o'z kuzatuv tizimlarini bor. Ana xizmatlar o'z maxfiylik siyosatiga ega va ular bu maxfiylik siyosati qoplanmaydi.",
    "privacyPolicy.text6": "Ushbu veb-sayti saqlaydi email, parol shifrlangan shakli va o'tdi Firebase va DigitalOcean infratuzilmalarni o'zida foydalanuvchilar boshqa kirishlar.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Foydalanish shartlari",
    "termsOfUse.text2": "Maxfiylik",
    "termsOfUse.text3": "Sizning maxfiylik biz uchun muhim. o'qib chiqing",
    "termsOfUse.text4": "Maxfiylik siyosati",
    "termsOfUse.text5": "Qo'shimcha ma'lumot olish uchun.",
    "termsOfUse.text6": "O'z mas'uliyat",
    "termsOfUse.text7": "Siz foydalanuvchi, sizning davlatingizdagi qonunlar va soliqlarni bilan o'z mosligini ta'minlash uchun javobgar bo'ladi. Siz o'z xavfsizligi uchun javobgar bo'ladi.",
    "termsOfUse.text8": "Kafolat Voz kechish",
    "termsOfUse.text9": "Ushbu sayt bir kuni taqdim etiladi KAFOLATLAR YOKI HAR QANDAY shart holda, ASOSI \"AS IS\".",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Start Connect",
    "videoTalk.text2": "stop Connect",
    "videoTalk.text3": "Online foydalanuvchilar",
    "videoTalk.text4": "gapirishni boshlang",
    "videoTalk.text5": "Nutqni harakat qilmoqda",
    "videoTalk.text6": "Boshqa hech qanday onlayn foydalanuvchi bor",
    "videoTalk.text7": "Siz bilan gaplashadi",
    "videoTalk.text8": "Siz gapirishni boshladi",
    "videoTalk.text9": "Show Matn Talk",
    "videoTalk.text10": "matn kiritish ....",
    "videoTalk.text11": "Siz hali ham gapiryapsiz. Agar gaplashib to'xtatish istaysizmi?",
    "videoTalk.text12": "Chaqirib Foydalanuvchi uzilgan",
    "videoTalk.text13": "Foydalanuvchi sizni chaqiradi. Agar bu da'vatni qabul qilasizmi?",
    "videoTalk.text14": "Sizning da'vatni rad",
    "videoTalk.text15": "onlayn foydalanuvchi qidirish ...",
    "videoTalk.text16": "foydalanuvchi qidirish ...",
    "__14": "",
    "groupTalk.text1": "muallif",
    "groupTalk.text2": "Tavsif",
    "groupTalk.text3": "yaratish sana",
    "groupTalk.text4": "Edit guruhi info",
    "groupTalk.text5": "Bu guruh a'zolari sizni yo'q qilish uchun hech qanday muammo bormi?",
    "groupTalk.text6": "A'zosi dan qoldiring",
    "groupTalk.text7": "guruhga ishtirok eting",
    "groupTalk.text8": "Show Guruh a'zolari",
    "groupTalk.text9": "Siz bu guruh ishtirok etish istaysizmi?",
    "groupTalk.text10": "Group nomi",
    "groupTalk.text11": "Bo'limga qaytish",
    "groupTalk.text12": "Sizning usulidan tasdiqlang",
    "groupTalk.text13": "guruh yaratish",
    "groupTalk.text14": "yangilash Group",
    "groupTalk.text15": "Guruh Exit",
    "groupTalk.text16": "Kirish uchun Bosh sahifaga o'tish",
    "groupTalk.text17": "guruh nomi (5-100 belgi)",
    "groupTalk.text18": "guruh tavsifi (kam 500 belgi)"
  },
  {
    "LANGUAGE": "Vietnamese",
    "CODE": "vi",
    "test.text1": "xin chào",
    "test.text2": "Chào",
    "test.text3": "Cảm ơn bạn",
    "test.text4": "bản đồ",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "hủy bỏ",
    "general.text2": "Chấp nhận",
    "general.text3": "Xóa bỏ",
    "general.text4": "Đã bị xóa",
    "general.text5": "Biên tập",
    "general.text6": "cập nhật",
    "general.text7": "Tải lên ảnh",
    "general.text8": "xóa ảnh",
    "general.text9": "hình ảnh",
    "general.text10": "Cho ăn",
    "general.text11": "Đăng nhập",
    "general.text12": "Đăng ký",
    "general.text13": "thông tin người dùng",
    "general.text14": "Đăng xuất",
    "general.text15": "tập tin nên được",
    "general.text16": "và ít hơn",
    "general.text17": "Mật khẩu",
    "general.text18": "bài viết yêu thích",
    "general.text19": "bài viết",
    "general.text20": "hình ảnh",
    "general.text21": "video",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Bài viết mới",
    "feed.text2": "Hiện tài khoản Bài viết",
    "feed.text3": "Hiện bài viết",
    "feed.text4": "lượt xem",
    "feed.text5": "biên tập",
    "feed.text6": "xóa bỏ",
    "feed.text7": "Là không có vấn đề bài xóa hoàn toàn?",
    "feed.text8": "Gửi bởi",
    "feed.text9": "Được tạo bởi",
    "feed.text10": "Tiêu đề",
    "feed.text11": "hình ảnh",
    "feed.text12": "Nội dung",
    "feed.text13": "công cộng",
    "feed.text14": "riêng tư",
    "feed.text15": "Tiêu đề cho bài",
    "feed.text16": "bài por nội dung",
    "feed.text17": "xóa bỏ",
    "feed.text18": "Là nó không có vấn đề để xóa hình ảnh hoàn toàn?",
    "feed.text19": "Đã bị xóa",
    "feed.text20": "Media File nên jpg, jpeg, png hoặc file mp4, và ít hơn 3MB",
    "feed.text21": "bưu chính công ích",
    "feed.text22": "bài riêng",
    "feed.text23": "Gõ Tìm kiếm ....",
    "feed.text24": "Tìm kiếm tài ...",
    "__2": "",
    "userInfo.text1": "Thông tin người dùng",
    "userInfo.text2": "Tên",
    "userInfo.text3": "hình ảnh",
    "userInfo.text4": "Ngày thành lập",
    "userInfo.text5": "Là nó không có vấn đề để xóa hình ảnh hoàn toàn?",
    "userInfo.text6": "Tên mới ...",
    "userInfo.text7": "Tệp ảnh phải jpg, jpeg, png hoặc tập tin, và ít hơn 1MB",
    "userInfo.text8": "chế độ Dark Mode",
    "userInfo.text9": "về tài khoản",
    "userInfo.text10": "thêm thông tin về bạn, mô tả sinh học vv ... (ít hơn 300 ký tự)",
    "userInfo.text11": "ngôn ngữ",
    "userInfo.text12": "Thay đổi",
    "userInfo.text13": "thông báo",
    "__3": "",
    "auth.text1": "Email của bạn",
    "auth.text2": "Tên của bạn",
    "auth.text3": "Mật khẩu",
    "auth.text4": "Mật khẩu nên có 8 đến 20 ký tự, ít nhất một chữ cái viết hoa, một chữ cái thường, một số và một ký tự đặc biệt (@ $!% *? &).",
    "auth.text5": "Đăng ký",
    "auth.text6": "Đăng nhập",
    "auth.text7": "Quên mật khẩu",
    "auth.text8": "trang đăng ký",
    "auth.text9": "địa chỉ E-mail cho thiết lập lại mật khẩu",
    "auth.text10": "gửi email",
    "auth.text11": "Xác thực không thành công. Hãy chắc chắn rằng địa chỉ email không được sử dụng chưa!",
    "auth.text12": "Thành viên đăng nhập không thành công!",
    "auth.text13": "Mật khẩu không hợp lệ được nhập",
    "auth.text14": "địa chỉ email vào không được tìm thấy",
    "auth.text15": "Tạo người dùng không thành công!",
    "auth.text16": "Các địa chỉ email đã được sử dụng bởi một tài khoản khác",
    "auth.text17": "Hiện Được nhập Mật khẩu",
    "auth.text18": "Ẩn Mật khẩu",
    "auth.text19": "email cho phần còn lại mật khẩu đã được gửi",
    "auth.text20": "email Enterd không được tìm thấy",
    "auth.text21": "thành công đặt lại mật khẩu",
    "auth.text22": "Đặt lại mật khẩu thất bại, Xin vui lòng đến đăng nhập trang và gửi email cho thiết lập lại mật khẩu một lần nữa",
    "auth.text23": "trang thiết lập lại mật khẩu",
    "auth.text24": "mật khẩu mới",
    "auth.text25": "Đặt lại mật khẩu",
    "auth.text26": "Trang không hợp lệ hoặc Mật khẩu đã được thay đổi",
    "auth.text27": "Gửi email cho thiết lập lại mật khẩu thất bại, Xin vui lòng kiểm tra địa chỉ email",
    "auth.text28": "trang xác minh email",
    "auth.text29": "Email xác minh thành công, hãy truy cập vào trang đăng nhập hiển thị ở trên",
    "auth.text30": "Xác minh qua email thất bại. Trang này đã hết hạn, hoặc xác minh đã được hoàn thành.",
    "auth.text31": "Xác minh qua thư đã được gửi. Plese kiểm tra email của bạn và xác minh. (Còn chack trong thư rác)",
    "auth.text32": "Email xác minh được yêu cầu phải đăng nhập. Xin vui lòng gửi Email để xác minh địa chỉ email của bạn.",
    "auth.text33": "Gửi Email để xác minh",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Cho ăn",
    "nav.text2": "Đăng nhập",
    "nav.text3": "Đăng ký",
    "nav.text4": "thông tin người dùng",
    "nav.text5": "Đăng xuất",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Không tìm thấy trang",
    "notFound.text2": "đi đến trang thức ăn chăn nuôi",
    "notFound.text3": "đi đến trang đăng nhập",
    "notFound.text4": "Hôm nay, bạn đi đâu?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "hủy bỏ",
    "comment.text2": "Xóa bỏ",
    "comment.text3": "đăng bình luận",
    "comment.text4": "Hiển thị trả lời",
    "comment.text5": "toàn bộ",
    "comment.text6": "Viết trả lời",
    "comment.text7": "Ẩn Trả lời",
    "comment.text8": "Bình luận",
    "comment.text9": "Là nó không có vấn đề để xóa bình luận này và trả lời của nhận xét này hoàn toàn?",
    "comment.text10": "Là nó không có vấn đề để xóa bình luận này hoàn toàn?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Thêm vào bài viết yêu thích",
    "follow.text2": "Xóa từ bài yêu thích",
    "follow.text3": "Thêm vào yêu thích sử dụng",
    "follow.text4": "Xóa từ người dùng yêu thích",
    "follow.text5": "chương trình viết",
    "follow.text6": "Người dùng yêu thích của bạn",
    "follow.text7": "Người dùng có bài viết yêu thích",
    "__11": "",
    "privacyPolicy.text1": "Chính sách bảo mật",
    "privacyPolicy.text2": "Chính sách này có thể được cập nhật hoặc sửa đổi mà không cần thông báo trước. Đây là trách nhiệm của người sử dụng để nghỉ thông báo về những thay đổi chính sách bảo mật. Hãy lưu ý các vấn đề riêng tư khác có thể ảnh hưởng đến bạn:",
    "privacyPolicy.text3": "Trang web này có thể bị tổn hại.",
    "privacyPolicy.text4": "Máy tính của bạn có thể bị tổn hại.",
    "privacyPolicy.text5": "Trang web này được lưu trữ trên căn cứ hỏa lực và cơ sở hạ tầng DigitalOcean. Họ có thể và không có hệ thống theo dõi của mình trên các máy chủ của họ. Những dịch vụ có chính sách bảo mật riêng của họ và họ không được bao phủ bởi chính sách bảo mật này.",
    "privacyPolicy.text6": "Này lưu trữ trang web email, hình thức mã hóa mật khẩu, và các đầu vào khác của người sử dụng tại tổ chức căn cứ hỏa lực và cơ sở hạ tầng DigitalOcean.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Điều khoản sử dụng",
    "termsOfUse.text2": "Riêng tư",
    "termsOfUse.text3": "Quyền riêng tư của bạn rất quan trọng với chúng tôi. Hãy đọc",
    "termsOfUse.text4": "Chính sách bảo mật",
    "termsOfUse.text5": "để biết thêm thông tin.",
    "termsOfUse.text6": "trách nhiệm của riêng bạn",
    "termsOfUse.text7": "Bạn, người sử dụng, hoàn toàn chịu trách nhiệm đảm bảo việc tuân thủ của riêng bạn với pháp luật và thuế trong thẩm quyền của mình. Bạn hoàn toàn chịu trách nhiệm về an ninh của riêng bạn.",
    "termsOfUse.text8": "Từ chối bảo hành",
    "termsOfUse.text9": "Trang web này được cung cấp trên cơ sở \"NHƯ VẬY\", KHÔNG CÓ BẢO ĐẢM HOẶC ĐIỀU KIỆN NÀO.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Bắt đầu Connect",
    "videoTalk.text2": "dừng Connect",
    "videoTalk.text3": "Người dùng trực tuyến",
    "videoTalk.text4": "Bắt đầu nói chuyện",
    "videoTalk.text5": "Bạn đang cố gắng để nói chuyện",
    "videoTalk.text6": "Không sử dụng trực tuyến khác tồn tại",
    "videoTalk.text7": "Bạn đang nói chuyện với",
    "videoTalk.text8": "Bạn bắt đầu nói",
    "videoTalk.text9": "Show Text Talk",
    "videoTalk.text10": "nhập văn bản ....",
    "videoTalk.text11": "Bạn vẫn đang nói. Bạn có muốn dừng lại nói chuyện?",
    "videoTalk.text12": "Kêu gọi người sử dụng bị ngắt kết nối",
    "videoTalk.text13": "người dùng đang gọi bạn. Bạn có chấp nhận cuộc gọi này?",
    "videoTalk.text14": "từ chối cuộc gọi của bạn",
    "videoTalk.text15": "tìm kiếm người đang truy cập ...",
    "videoTalk.text16": "tìm kiếm người dùng ...",
    "__14": "",
    "groupTalk.text1": "người sáng tạo",
    "groupTalk.text2": "Sự miêu tả",
    "groupTalk.text3": "Ngày thành lập",
    "groupTalk.text4": "Chỉnh sửa thông tin nhóm",
    "groupTalk.text5": "Là nó không có vấn đề để xóa bạn từ các thành viên của nhóm này?",
    "groupTalk.text6": "Để lại từ thành viên",
    "groupTalk.text7": "Tham gia nhóm",
    "groupTalk.text8": "Hiện Nhóm thành viên",
    "groupTalk.text9": "Bạn có muốn nhập nhóm này?",
    "groupTalk.text10": "Tên nhóm",
    "groupTalk.text11": "Trở lại danh sách",
    "groupTalk.text12": "Xác nhận đầu vào của bạn",
    "groupTalk.text13": "Tạo nhóm",
    "groupTalk.text14": "cập nhật Nhóm",
    "groupTalk.text15": "Thoát khỏi Nhóm",
    "groupTalk.text16": "Tới trang chủ để đăng nhập",
    "groupTalk.text17": "tên nhóm (5-100 ký tự)",
    "groupTalk.text18": "Mô tả nhóm (ít hơn 500 ký tự)"
  },
  {
    "LANGUAGE": "Xhosa",
    "CODE": "xh",
    "test.text1": "Mholweni",
    "test.text2": "Mholweni",
    "test.text3": "Enkosi",
    "test.text4": "imephu",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Rhoxisa",
    "general.text2": "Yamkela",
    "general.text3": "Cima",
    "general.text4": "isusiwe",
    "general.text5": "Hlela",
    "general.text6": "Update",
    "general.text7": "Layisha Image",
    "general.text8": "Image Delete",
    "general.text9": "image",
    "general.text10": "Yondla",
    "general.text11": "Ngema",
    "general.text12": "Bhalisa",
    "general.text13": "User Info",
    "general.text14": "Phuma",
    "general.text15": "fayili",
    "general.text16": "kanye ngaphantsi kwe",
    "general.text17": "Inombolo yokuvula",
    "general.text18": "ezithandwa Posts",
    "general.text19": "posts",
    "general.text20": "images",
    "general.text21": "Videos",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "New Post",
    "feed.text2": "Bonisa Posts Umsebenzisi",
    "feed.text3": "Bonisa Posts",
    "feed.text4": "umbono",
    "feed.text5": "hlela",
    "feed.text6": "ususe",
    "feed.text7": "Ngaba ayikho ingxaki sithuba Cima ngokupheleleyo?",
    "feed.text8": "ithunyelwe ngu",
    "feed.text9": "Yenziwe ngu",
    "feed.text10": "isihloko",
    "feed.text11": "image",
    "feed.text12": "Content",
    "feed.text13": "umphakathi",
    "feed.text14": "labucala",
    "feed.text15": "Isihloko for ngeposi",
    "feed.text16": "post por Content",
    "feed.text17": "ususe",
    "feed.text18": "Ngaba akukho ngxaki kumfanekiso Cima ngokupheleleyo?",
    "feed.text19": "isusiwe",
    "feed.text20": "Ifayile Media kufuneka jpg, jpeg, png okanye ifayile mp4, yaye ngaphantsi kwe 3MB",
    "feed.text21": "ethunyelwe",
    "feed.text22": "post yabucala",
    "feed.text23": "Chwetheza for Search ....",
    "feed.text24": "Ukufuna Umsebenzisi ...",
    "__2": "",
    "userInfo.text1": "Ulwazi lomsebenzisi",
    "userInfo.text2": "igama",
    "userInfo.text3": "umfanekiso",
    "userInfo.text4": "usuku indalo",
    "userInfo.text5": "Ngaba akukho ngxaki kumfanekiso Cima ngokupheleleyo?",
    "userInfo.text6": "New Igama ...",
    "userInfo.text7": "wefayile kufuneka jpg, jpeg, okanye ifayile png, yaye ngaphantsi kwe 1MB",
    "userInfo.text8": "Mode Dark",
    "userInfo.text9": "Umsebenzisi About",
    "userInfo.text10": "iinkcukacha ezongeziweyo zakho, inkcazo bio etc ... (abalinganiswa ngaphantsi kwama-300)",
    "userInfo.text11": "Language",
    "userInfo.text12": "Guqula",
    "userInfo.text13": "isaziso",
    "__3": "",
    "auth.text1": "Imeyile yakho",
    "auth.text2": "Ingama lakho",
    "auth.text3": "Inombolo yokuvula",
    "auth.text4": "Password kufuneka ibe abalinganiswa-8 ukuya ku-20, ubuncinane omnye unobumba, unobumba omnye negama, inombolo enye kunye nonobumba ezizodwa (@ $!% *? &).",
    "auth.text5": "Bhalisa",
    "auth.text6": "Ngema",
    "auth.text7": "Libala Password",
    "auth.text8": "Signup Page",
    "auth.text9": "Idilesi ye-imeyile password ukuhlela",
    "auth.text10": "ukuthumela E-mail",
    "auth.text11": "Ukuqinisekiswa akuphumelelanga. Qinisekisa ukuba idilesi ye email ayisetyenziselwanga okwangoku!",
    "auth.text12": "login umsebenzisi akuphumelelanga!",
    "auth.text13": "Igama elingasebenziyo esangena",
    "auth.text14": "ayifumanekanga idilesi ye email wangena",
    "auth.text15": "indalo Umsebenzisi akuphumelelanga!",
    "auth.text16": "Idilesi ye email seyisetyenziswa ngomnye akhawunti",
    "auth.text17": "Bonisa Password Wangena",
    "auth.text18": "Fihla Password",
    "auth.text19": "imeyili igama abanye ithunyelwe",
    "auth.text20": "ayifumanekanga Enterd email",
    "auth.text21": "Password ukuhlela impumelelo",
    "auth.text22": "ukuhlela Password akuphumelelanga, Nceda yiya kwiphepha Login Thumela i-imeyile ze usete iphasiwedi kwakhona",
    "auth.text23": "Password page ukuhlela",
    "auth.text24": "Iphasiwedi entsha",
    "auth.text25": "Hlela igama",
    "auth.text26": "Page ayisebenzi okanye Password sele watshintsha",
    "auth.text27": "Thumela imeyili usete iphasiwedi aliphumelelanga, Nceda idilesi ye email check",
    "auth.text28": "page yokuqinisekisa Email",
    "auth.text29": "Imeyili yokuqinisekisa impumelelo, nceda uye kwiphepha lokungena eboniswe ngasentla",
    "auth.text30": "imeyili akuphumelelanga. Eli phepha iphelelwe, okanye isiqinisekiso sele ugqibile.",
    "auth.text31": "Lokuqinisekisa Mail wathunyelwa. Plese imeyili yakho uze uqinisekise. (Kwakhona chack kwiposi spam)",
    "auth.text32": "Imeyili ukuqinisekisa ukuba Login. Nceda uthumele Imeyili yokuqinisekisa kwidilesi yakho Email.",
    "auth.text33": "Thumela Imeyili yokuqinisekisa",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Yondla",
    "nav.text2": "Ngema",
    "nav.text3": "Bhalisa",
    "nav.text4": "User Info",
    "nav.text5": "Phuma",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Iphela alifumaneki",
    "notFound.text2": "ukuya kwiphepha feed",
    "notFound.text3": "ukuya kwiphepha lokungena",
    "notFound.text4": "ngaba uye phi na namhla?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Rhoxisa",
    "comment.text2": "Cima",
    "comment.text3": "Post Comment",
    "comment.text4": "Bonisa Phendula",
    "comment.text5": "iyonke",
    "comment.text6": "Bhala impendulo",
    "comment.text7": "Fihla Phendula",
    "comment.text8": "izimvo",
    "comment.text9": "Ngaba akukho ngxaki ukucima le izimvo kunye nezimpendulo yale mazwana ngokupheleleyo?",
    "comment.text10": "Ngaba akukho ngxaki ukucima le mazwana ngokupheleleyo?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Faka ezithandwa Post",
    "follow.text2": "Sula post ozithandayo",
    "follow.text3": "Yongeza Umsebenzisi ozithandayo",
    "follow.text4": "Cima evela kumsebenzisi ozithandayo",
    "follow.text5": "bonisa izithuba",
    "follow.text6": "Abasebenzisi zakho ozithandayo",
    "follow.text7": "Post ezithandwa Abasebenzisi",
    "__11": "",
    "privacyPolicy.text1": "umthetho wabucala",
    "privacyPolicy.text2": "Lo mgaqo-nkqubo kufuneka ihlaziywe okanye ehlaziyiweyo ngaphandle kwesaziso. Luxanduva lomsebenzisi ukuhlala unolwazi malunga notshintsho umgaqo-nkqubo. Phawula eminye imiba wedwa ukuze kukuchaphazela:",
    "privacyPolicy.text3": "Le website ukuze sengozini.",
    "privacyPolicy.text4": "kwikhompyutha yakho ukuze sengozini.",
    "privacyPolicy.text5": "Le website isingathwa kwi Firebase kunye DigitalOcean kwezakhelo. Bangase kwaye kufuneka iinkqubo zabo tracking kulo abancedisi babo. Abo iinkonzo zineepolisi yazo kodwa abayi kuhlawulwa ngulo mgaqo-nkqubo wabucala.",
    "privacyPolicy.text6": "Le email website iivenkile, uhlobo oluchaziweyo password, kunye nezinye namagalelo abasebenzisi kwi esingethwe Firebase kunye DigitalOcean kwezakhelo.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Imimmiselo yokusebenzisa",
    "termsOfUse.text2": "Privacy",
    "termsOfUse.text3": "Ezabucala kubalulekile kuthi. Nceda ufunde",
    "termsOfUse.text4": "umthetho wabucala",
    "termsOfUse.text5": "ukufumana ulwazi oluthe vetshe.",
    "termsOfUse.text6": "imithwalo yakho",
    "termsOfUse.text7": "Wena, umsebenzisi, luxanduva lwazo zodwa qwaba ukuqinisekisa ukuthobela yakho nemithetho kunye irhafu kolawulo lwakho. Wena ngokupheleleyo uxanduva yokhuseleko yakho.",
    "termsOfUse.text8": "Lwemibandela iwaranti",
    "termsOfUse.text9": "Le sayithi inikwa i \"NJENGOKO ZINJALO\" NGENDLELA, NGAPHANDLE IIWARANTI OKANYE IIMEKO NA UHLOBO.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Qala Connect",
    "videoTalk.text2": "Stop Connect",
    "videoTalk.text3": "Abasebenzisi intanethi",
    "videoTalk.text4": "Qala ukuxoxa",
    "videoTalk.text5": "Bakho bezama kwintetho",
    "videoTalk.text6": "Ayikho enye online umsebenzisi ikhona",
    "videoTalk.text7": "Uthetha ukuba",
    "videoTalk.text8": "Wena waqalisa ukuthetha",
    "videoTalk.text9": "Bonisa Text Talk",
    "videoTalk.text10": "input text ....",
    "videoTalk.text11": "Uya kuthi usathetha. Ingaba ufuna ukuyeka ukuthetha?",
    "videoTalk.text12": "umsebenzisi Calling inqanyulwe",
    "videoTalk.text13": "umsebenzisi onibizela. Ingaba le umnxeba?",
    "videoTalk.text14": "Ukuyeka ucingo lwakho",
    "videoTalk.text15": "ukukhangela umsebenzisi intanethi ...",
    "videoTalk.text16": "ukucinga umsebenzisi ...",
    "__14": "",
    "groupTalk.text1": "kumdlali",
    "groupTalk.text2": "inkcazelo",
    "groupTalk.text3": "usuku indalo",
    "groupTalk.text4": "Hlela group ulwazi",
    "groupTalk.text5": "Ngaba akukho ngxaki ukucima kuni evela kumalungu eli qela?",
    "groupTalk.text6": "Shiya evela kwilungu",
    "groupTalk.text7": "Joyina Group",
    "groupTalk.text8": "Bonisa Amalungu Group",
    "groupTalk.text9": "Ngaba ufuna ukujoyina Le Group?",
    "groupTalk.text10": "Group Name",
    "groupTalk.text11": "Buyela ku Luhlu",
    "groupTalk.text12": "Qinisekisa igalelo lakho",
    "groupTalk.text13": "Yenza Group",
    "groupTalk.text14": "Group Update",
    "groupTalk.text15": "Phuma kwi Group",
    "groupTalk.text16": "Yiya Imidlalo ku Login",
    "groupTalk.text17": "Igama leqela (abalinganiswa 5-100)",
    "groupTalk.text18": "inkcazo Iqela (abalinganiswa ngaphantsi kwe 500)"
  },
  {
    "LANGUAGE": "Chinese",
    "CODE": "zh",
    "test.text1": "你好",
    "test.text2": "你好",
    "test.text3": "谢谢",
    "test.text4": "地图",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "取消",
    "general.text2": "接受",
    "general.text3": "删除",
    "general.text4": "已删除",
    "general.text5": "编辑",
    "general.text6": "更新",
    "general.text7": "上传图片",
    "general.text8": "删除图片",
    "general.text9": "图片",
    "general.text10": "饲料",
    "general.text11": "登录",
    "general.text12": "注册",
    "general.text13": "用户信息",
    "general.text14": "登出",
    "general.text15": "文件应该是",
    "general.text16": "和小于",
    "general.text17": "密码",
    "general.text18": "喜欢的帖子",
    "general.text19": "帖子",
    "general.text20": "图片",
    "general.text21": "影片",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "最新帖子",
    "feed.text2": "显示用户的帖子",
    "feed.text3": "显示帖子",
    "feed.text4": "视图",
    "feed.text5": "编辑",
    "feed.text6": "删除",
    "feed.text7": "是否是删除后完全没问题？",
    "feed.text8": "发布者",
    "feed.text9": "由...制作",
    "feed.text10": "标题",
    "feed.text11": "图片",
    "feed.text12": "内容",
    "feed.text13": "上市",
    "feed.text14": "私人的",
    "feed.text15": "标题后",
    "feed.text16": "内容POR后",
    "feed.text17": "删除",
    "feed.text18": "它是彻底删除图像没有问题？",
    "feed.text19": "已删除",
    "feed.text20": "媒体文件应该是JPG，JPEG，PNG或MP4文件，并小于3MB",
    "feed.text21": "公开信息",
    "feed.text22": "私立专",
    "feed.text23": "键入搜索....",
    "feed.text24": "搜索用户...",
    "__2": "",
    "userInfo.text1": "用户信息",
    "userInfo.text2": "名称",
    "userInfo.text3": "图片",
    "userInfo.text4": "创立日期",
    "userInfo.text5": "它是彻底删除图像没有问题？",
    "userInfo.text6": "新名字 ...",
    "userInfo.text7": "图像文件应该是JPG，JPEG，或PNG文件，并小于1MB",
    "userInfo.text8": "黑暗模式",
    "userInfo.text9": "关于用户",
    "userInfo.text10": "关于你更多的信息，说明生物等...（少于300个字）",
    "userInfo.text11": "语言",
    "userInfo.text12": "改变",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "你的邮件",
    "auth.text2": "你的名字",
    "auth.text3": "密码",
    "auth.text4": "密码应为8到20个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符（@ $！％*？＆）。",
    "auth.text5": "注册",
    "auth.text6": "登录",
    "auth.text7": "忘记密码",
    "auth.text8": "注册页面",
    "auth.text9": "E-mail地址的密码重置",
    "auth.text10": "发送电子邮件",
    "auth.text11": "验证失败。确保电子邮件地址尚未使用！",
    "auth.text12": "用户登录失败！",
    "auth.text13": "无效密码被输入",
    "auth.text14": "输入的电子邮件地址未找到",
    "auth.text15": "用户创建失败！",
    "auth.text16": "该电子邮件地址已被其他帐户",
    "auth.text17": "显示输入的密码",
    "auth.text18": "隐藏密码",
    "auth.text19": "电子邮件密码其余被送往",
    "auth.text20": "照耀处电子邮件没有被发现",
    "auth.text21": "密码重置成功",
    "auth.text22": "密码重置失败，请重新进入登录页面和发送电子邮件的密码重置",
    "auth.text23": "密码重置页面",
    "auth.text24": "新密码",
    "auth.text25": "重设密码",
    "auth.text26": "页无效或密码已被更改",
    "auth.text27": "发送电子邮件的密码重置失败，请检查电子邮件地址",
    "auth.text28": "电子邮件验证页面",
    "auth.text29": "电子邮件验证成功，请进入上图所示的登录页面",
    "auth.text30": "电子邮件验证失败。此页面已过期，或者验证已经完成。",
    "auth.text31": "验证邮件已发送。普莱舍查收电子邮件和验证。 （在垃圾邮件也焯芬）",
    "auth.text32": "电子邮件验证才能登录。请验证电子邮件发送到您的邮箱地址。",
    "auth.text33": "发送电邮验证",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "饲料",
    "nav.text2": "登录",
    "nav.text3": "注册",
    "nav.text4": "用户信息",
    "nav.text5": "登出",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "找不到网页",
    "notFound.text2": "去饲料页",
    "notFound.text3": "进入登录页面",
    "notFound.text4": "当你今天去做？",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "取消",
    "comment.text2": "删除",
    "comment.text3": "发表评论",
    "comment.text4": "显示回复",
    "comment.text5": "总",
    "comment.text6": "写回复",
    "comment.text7": "隐藏回复",
    "comment.text8": "评论",
    "comment.text9": "是否删除此评论完全的这一评论和答复没有问题？",
    "comment.text10": "它是完全删除这条评论有没有问题？",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "加入收藏帖子",
    "follow.text2": "从最喜欢的后删除",
    "follow.text3": "加入收藏用户",
    "follow.text4": "从最喜欢的用户删除",
    "follow.text5": "显示帖子",
    "follow.text6": "您收藏的用户",
    "follow.text7": "帖子收藏的用户",
    "__11": "",
    "privacyPolicy.text1": "隐私政策",
    "privacyPolicy.text2": "这一政策可能会更新或恕不另行通知修订。这是用户随时了解隐私政策变化的责任。请注意那些可能会影响你的其他隐私问题：",
    "privacyPolicy.text3": "这个网站可能会受到损害。",
    "privacyPolicy.text4": "您的计算机可能会受到影响。",
    "privacyPolicy.text5": "该网站托管在火力地堡和DigitalOcean基础设施。他们可能与你有自己的服务器上自己的跟踪系统。这些服务有自己的隐私政策，它们不属于本隐私政策。",
    "privacyPolicy.text6": "本网站存储电子邮件，密码的加密形式，在托管火力地堡和DigitalOcean基础设施用户的其他投入。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "使用条款",
    "termsOfUse.text2": "隐私",
    "termsOfUse.text3": "您的隐私对我们很重要。请阅读",
    "termsOfUse.text4": "隐私政策",
    "termsOfUse.text5": "了解更多信息。",
    "termsOfUse.text6": "你自己的责任",
    "termsOfUse.text7": "你的用户，是确保自己符合您所在司法辖区的法律和税收承担全部责任。你是你自己的安全负责。",
    "termsOfUse.text8": "保修免责声明",
    "termsOfUse.text9": "本网站提供的“原样”的基础，没有担保或任何形式的条件。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "开始连接",
    "videoTalk.text2": "停止连接",
    "videoTalk.text3": "在线用户",
    "videoTalk.text4": "开口",
    "videoTalk.text5": "您正在试图说服",
    "videoTalk.text6": "没有其他在线用户存在",
    "videoTalk.text7": "你是说，以",
    "videoTalk.text8": "你开始谈论",
    "videoTalk.text9": "显示文本对话",
    "videoTalk.text10": "文本输入....",
    "videoTalk.text11": "你还在说话。你想停止说话？",
    "videoTalk.text12": "主叫用户断开连接",
    "videoTalk.text13": "用户呼叫你。你接受这个电话？",
    "videoTalk.text14": "拒绝您的来电",
    "videoTalk.text15": "在网上搜索用户...",
    "videoTalk.text16": "搜索用户...",
    "__14": "",
    "groupTalk.text1": "创造者",
    "groupTalk.text2": "描述",
    "groupTalk.text3": "创建日期",
    "groupTalk.text4": "编辑组信息",
    "groupTalk.text5": "它是没有问题的，从这个组的成员中删除吗？",
    "groupTalk.text6": "从会员离开",
    "groupTalk.text7": "加入组",
    "groupTalk.text8": "展集团成员",
    "groupTalk.text9": "你想加入这个集团？",
    "groupTalk.text10": "组名",
    "groupTalk.text11": "返回目录",
    "groupTalk.text12": "确认您输入",
    "groupTalk.text13": "创建组",
    "groupTalk.text14": "更新组",
    "groupTalk.text15": "从集团退出",
    "groupTalk.text16": "转到主页到登录",
    "groupTalk.text17": "组名称（5-100个字符）",
    "groupTalk.text18": "组描述（少于500个字符）"
  },
  {
    "LANGUAGE": "Chinese (Simplified)",
    "CODE": "zh-CN",
    "test.text1": "你好",
    "test.text2": "你好",
    "test.text3": "谢谢",
    "test.text4": "地图",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "取消",
    "general.text2": "接受",
    "general.text3": "删除",
    "general.text4": "已删除",
    "general.text5": "编辑",
    "general.text6": "更新",
    "general.text7": "上传图片",
    "general.text8": "删除图片",
    "general.text9": "图片",
    "general.text10": "饲料",
    "general.text11": "登录",
    "general.text12": "注册",
    "general.text13": "用户信息",
    "general.text14": "登出",
    "general.text15": "文件应该是",
    "general.text16": "和小于",
    "general.text17": "密码",
    "general.text18": "喜欢的帖子",
    "general.text19": "帖子",
    "general.text20": "图片",
    "general.text21": "影片",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "最新帖子",
    "feed.text2": "显示用户的帖子",
    "feed.text3": "显示帖子",
    "feed.text4": "视图",
    "feed.text5": "编辑",
    "feed.text6": "删除",
    "feed.text7": "是否是删除后完全没问题？",
    "feed.text8": "发布者",
    "feed.text9": "由...制作",
    "feed.text10": "标题",
    "feed.text11": "图片",
    "feed.text12": "内容",
    "feed.text13": "上市",
    "feed.text14": "私人的",
    "feed.text15": "标题后",
    "feed.text16": "内容POR后",
    "feed.text17": "删除",
    "feed.text18": "它是彻底删除图像没有问题？",
    "feed.text19": "已删除",
    "feed.text20": "媒体文件应该是JPG，JPEG，PNG或MP4文件，并小于3MB",
    "feed.text21": "公开信息",
    "feed.text22": "私立专",
    "feed.text23": "键入搜索....",
    "feed.text24": "搜索用户...",
    "__2": "",
    "userInfo.text1": "用户信息",
    "userInfo.text2": "名称",
    "userInfo.text3": "图片",
    "userInfo.text4": "创立日期",
    "userInfo.text5": "它是彻底删除图像没有问题？",
    "userInfo.text6": "新名字 ...",
    "userInfo.text7": "图像文件应该是JPG，JPEG，或PNG文件，并小于1MB",
    "userInfo.text8": "黑暗模式",
    "userInfo.text9": "关于用户",
    "userInfo.text10": "关于你更多的信息，说明生物等...（少于300个字）",
    "userInfo.text11": "语言",
    "userInfo.text12": "改变",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "你的邮件",
    "auth.text2": "你的名字",
    "auth.text3": "密码",
    "auth.text4": "密码应为8到20个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符（@ $！％*？＆）。",
    "auth.text5": "注册",
    "auth.text6": "登录",
    "auth.text7": "忘记密码",
    "auth.text8": "注册页面",
    "auth.text9": "E-mail地址的密码重置",
    "auth.text10": "发送电子邮件",
    "auth.text11": "验证失败。确保电子邮件地址尚未使用！",
    "auth.text12": "用户登录失败！",
    "auth.text13": "无效密码被输入",
    "auth.text14": "输入的电子邮件地址未找到",
    "auth.text15": "用户创建失败！",
    "auth.text16": "该电子邮件地址已被其他帐户",
    "auth.text17": "显示输入的密码",
    "auth.text18": "隐藏密码",
    "auth.text19": "电子邮件密码其余被送往",
    "auth.text20": "照耀处电子邮件没有被发现",
    "auth.text21": "密码重置成功",
    "auth.text22": "密码重置失败，请重新进入登录页面和发送电子邮件的密码重置",
    "auth.text23": "密码重置页面",
    "auth.text24": "新密码",
    "auth.text25": "重设密码",
    "auth.text26": "页无效或密码已被更改",
    "auth.text27": "发送电子邮件的密码重置失败，请检查电子邮件地址",
    "auth.text28": "电子邮件验证页面",
    "auth.text29": "电子邮件验证成功，请进入上图所示的登录页面",
    "auth.text30": "电子邮件验证失败。此页面已过期，或者验证已经完成。",
    "auth.text31": "验证邮件已发送。普莱舍查收电子邮件和验证。 （在垃圾邮件也焯芬）",
    "auth.text32": "电子邮件验证才能登录。请验证电子邮件发送到您的邮箱地址。",
    "auth.text33": "发送电邮验证",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "饲料",
    "nav.text2": "登录",
    "nav.text3": "注册",
    "nav.text4": "用户信息",
    "nav.text5": "登出",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "找不到网页",
    "notFound.text2": "去饲料页",
    "notFound.text3": "进入登录页面",
    "notFound.text4": "当你今天去做？",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "取消",
    "comment.text2": "删除",
    "comment.text3": "发表评论",
    "comment.text4": "显示回复",
    "comment.text5": "总",
    "comment.text6": "写回复",
    "comment.text7": "隐藏回复",
    "comment.text8": "评论",
    "comment.text9": "是否删除此评论完全的这一评论和答复没有问题？",
    "comment.text10": "它是完全删除这条评论有没有问题？",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "加入收藏帖子",
    "follow.text2": "从最喜欢的后删除",
    "follow.text3": "加入收藏用户",
    "follow.text4": "从最喜欢的用户删除",
    "follow.text5": "显示帖子",
    "follow.text6": "您收藏的用户",
    "follow.text7": "帖子收藏的用户",
    "__11": "",
    "privacyPolicy.text1": "隐私政策",
    "privacyPolicy.text2": "这一政策可能会更新或恕不另行通知修订。这是用户随时了解隐私政策变化的责任。请注意那些可能会影响你的其他隐私问题：",
    "privacyPolicy.text3": "这个网站可能会受到损害。",
    "privacyPolicy.text4": "您的计算机可能会受到影响。",
    "privacyPolicy.text5": "该网站托管在火力地堡和DigitalOcean基础设施。他们可能与你有自己的服务器上自己的跟踪系统。这些服务有自己的隐私政策，它们不属于本隐私政策。",
    "privacyPolicy.text6": "本网站存储电子邮件，密码的加密形式，在托管火力地堡和DigitalOcean基础设施用户的其他投入。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "使用条款",
    "termsOfUse.text2": "隐私",
    "termsOfUse.text3": "您的隐私对我们很重要。请阅读",
    "termsOfUse.text4": "隐私政策",
    "termsOfUse.text5": "了解更多信息。",
    "termsOfUse.text6": "你自己的责任",
    "termsOfUse.text7": "你的用户，是确保自己符合您所在司法辖区的法律和税收承担全部责任。你是你自己的安全负责。",
    "termsOfUse.text8": "保修免责声明",
    "termsOfUse.text9": "本网站提供的“原样”的基础，没有担保或任何形式的条件。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "开始连接",
    "videoTalk.text2": "停止连接",
    "videoTalk.text3": "在线用户",
    "videoTalk.text4": "开口",
    "videoTalk.text5": "您正在试图说服",
    "videoTalk.text6": "没有其他在线用户存在",
    "videoTalk.text7": "你是说，以",
    "videoTalk.text8": "你开始谈论",
    "videoTalk.text9": "显示文本对话",
    "videoTalk.text10": "文本输入....",
    "videoTalk.text11": "你还在说话。你想停止说话？",
    "videoTalk.text12": "主叫用户断开连接",
    "videoTalk.text13": "用户呼叫你。你接受这个电话？",
    "videoTalk.text14": "拒绝您的来电",
    "videoTalk.text15": "在网上搜索用户...",
    "videoTalk.text16": "搜索用户...",
    "__14": "",
    "groupTalk.text1": "创造者",
    "groupTalk.text2": "描述",
    "groupTalk.text3": "创建日期",
    "groupTalk.text4": "编辑组信息",
    "groupTalk.text5": "它是没有问题的，从这个组的成员中删除吗？",
    "groupTalk.text6": "从会员离开",
    "groupTalk.text7": "加入组",
    "groupTalk.text8": "展集团成员",
    "groupTalk.text9": "你想加入这个集团？",
    "groupTalk.text10": "组名",
    "groupTalk.text11": "返回目录",
    "groupTalk.text12": "确认您输入",
    "groupTalk.text13": "创建组",
    "groupTalk.text14": "更新组",
    "groupTalk.text15": "从集团退出",
    "groupTalk.text16": "转到主页到登录",
    "groupTalk.text17": "组名称（5-100个字符）",
    "groupTalk.text18": "组描述（少于500个字符）"
  },
  {
    "LANGUAGE": "Chinese (Hong Kong)",
    "CODE": "zh-HK",
    "test.text1": "你好",
    "test.text2": "你好",
    "test.text3": "謝謝",
    "test.text4": "地圖",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "取消",
    "general.text2": "接受",
    "general.text3": "刪除",
    "general.text4": "已刪除",
    "general.text5": "編輯",
    "general.text6": "更新",
    "general.text7": "上傳圖片",
    "general.text8": "刪除圖片",
    "general.text9": "圖片",
    "general.text10": "飼料",
    "general.text11": "登錄",
    "general.text12": "註冊",
    "general.text13": "用戶信息",
    "general.text14": "登出",
    "general.text15": "文件應該是",
    "general.text16": "和小於",
    "general.text17": "密碼",
    "general.text18": "喜歡的帖子",
    "general.text19": "帖子",
    "general.text20": "圖片",
    "general.text21": "影片",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "最新帖子",
    "feed.text2": "顯示用戶的帖子",
    "feed.text3": "顯示帖子",
    "feed.text4": "視圖",
    "feed.text5": "編輯",
    "feed.text6": "刪除",
    "feed.text7": "是否是刪除後完全沒問題？",
    "feed.text8": "發布者",
    "feed.text9": "由...製作",
    "feed.text10": "標題",
    "feed.text11": "圖片",
    "feed.text12": "內容",
    "feed.text13": "上市",
    "feed.text14": "私人的",
    "feed.text15": "標題後",
    "feed.text16": "內容POR後",
    "feed.text17": "刪除",
    "feed.text18": "它是徹底刪除圖像沒有問題？",
    "feed.text19": "已刪除",
    "feed.text20": "媒體文件應該是JPG，JPEG，PNG或MP4文件，並小於3MB",
    "feed.text21": "公開信息",
    "feed.text22": "私立專",
    "feed.text23": "鍵入搜索....",
    "feed.text24": "搜索用戶...",
    "__2": "",
    "userInfo.text1": "用戶信息",
    "userInfo.text2": "名稱",
    "userInfo.text3": "圖片",
    "userInfo.text4": "創立日期",
    "userInfo.text5": "它是徹底刪除圖像沒有問題？",
    "userInfo.text6": "新名字 ...",
    "userInfo.text7": "圖像文件應該是JPG，JPEG，或PNG文件，並小於1MB",
    "userInfo.text8": "黑暗模式",
    "userInfo.text9": "關於用戶",
    "userInfo.text10": "關於你更多的信息，說明生物等...（少於300字）",
    "userInfo.text11": "語言",
    "userInfo.text12": "改變",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "你的郵件",
    "auth.text2": "你的名字",
    "auth.text3": "密碼",
    "auth.text4": "密碼應為8到20個字符，至少一個大寫字母，一個小寫字母，一個數字和一個特殊字符（@ $！％*？＆）。",
    "auth.text5": "註冊",
    "auth.text6": "登錄",
    "auth.text7": "忘記密碼",
    "auth.text8": "註冊頁面",
    "auth.text9": "E-mail地址的密碼重置",
    "auth.text10": "發送電子郵件",
    "auth.text11": "驗證失敗。確保電子郵件地址尚未使用！",
    "auth.text12": "用戶登錄失敗！",
    "auth.text13": "無效密碼被輸入",
    "auth.text14": "輸入的電子郵件地址未找到",
    "auth.text15": "用戶創建失敗！",
    "auth.text16": "該電子郵件地址已被其他帳戶",
    "auth.text17": "顯示輸入的密碼",
    "auth.text18": "隱藏密碼",
    "auth.text19": "電子郵件密碼其餘被送往",
    "auth.text20": "照耀處電子郵件沒有被發現",
    "auth.text21": "密碼重置成功",
    "auth.text22": "密碼重置失敗，請重新進入登錄頁面和發送電子郵件的密碼重置",
    "auth.text23": "密碼重置頁面",
    "auth.text24": "新密碼",
    "auth.text25": "重設密碼",
    "auth.text26": "頁無效或密碼已被更改",
    "auth.text27": "發送電子郵件的密碼重置失敗，請檢查電子郵件地址",
    "auth.text28": "電子郵件驗證頁面",
    "auth.text29": "電子郵件驗證成功，請進入上圖所示的登錄頁面",
    "auth.text30": "電子郵件驗證失敗。此頁面已過期，或者驗證已經完成。",
    "auth.text31": "驗證郵件已發送。普萊舍查收電子郵件和驗證。 （在垃圾郵件也焯芬）",
    "auth.text32": "電子郵件驗證才能登錄。請驗證電子郵件發送到您的郵箱地址。",
    "auth.text33": "發送電郵驗證",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "飼料",
    "nav.text2": "登錄",
    "nav.text3": "註冊",
    "nav.text4": "用戶信息",
    "nav.text5": "登出",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "找不到網頁",
    "notFound.text2": "去飼料頁",
    "notFound.text3": "進入登錄頁面",
    "notFound.text4": "當你今天去做？",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "取消",
    "comment.text2": "刪除",
    "comment.text3": "發表評論",
    "comment.text4": "顯示回复",
    "comment.text5": "總",
    "comment.text6": "寫回复",
    "comment.text7": "隱藏回复",
    "comment.text8": "評論",
    "comment.text9": "是否刪除此評論完全的這一評論和答复沒有問題？",
    "comment.text10": "它是完全刪除這條評論有沒有問題？",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "加入收藏帖子",
    "follow.text2": "從最喜歡的後刪除",
    "follow.text3": "加入收藏用戶",
    "follow.text4": "從最喜歡的用戶刪除",
    "follow.text5": "顯示帖子",
    "follow.text6": "您收藏的用戶",
    "follow.text7": "帖子收藏的用戶",
    "__11": "",
    "privacyPolicy.text1": "隱私政策",
    "privacyPolicy.text2": "這一政策可能會更新或恕不另行通知修訂。這是用戶隨時了解隱私政策變化的責任。請注意那些可能會影響你的其他隱私問題：",
    "privacyPolicy.text3": "這個網站可能會受到損害。",
    "privacyPolicy.text4": "您的計算機可能會受到影響。",
    "privacyPolicy.text5": "該網站託管在火力地堡和DigitalOcean基礎設施。他們可能與你有自己的服務器上自己的跟踪系統。這些服務有自己的隱私政策，它們不屬於本隱私政策。",
    "privacyPolicy.text6": "本網站存儲電子郵件，密碼的加密形式，在託管火力地堡和DigitalOcean基礎設施用戶的其他投入。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "使用條款",
    "termsOfUse.text2": "隱私",
    "termsOfUse.text3": "您的隱私對我們很重要。請閱讀",
    "termsOfUse.text4": "隱私政策",
    "termsOfUse.text5": "了解更多信息。",
    "termsOfUse.text6": "你自己的責任",
    "termsOfUse.text7": "你的用戶，是確保自己符合您所在司法轄區的法律和稅收承擔全部責任。你是你自己的安全負責。",
    "termsOfUse.text8": "保修免責聲明",
    "termsOfUse.text9": "本網站提供的“原樣”的基礎，沒有擔保或任何形式的條件。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "開始連接",
    "videoTalk.text2": "停止連接",
    "videoTalk.text3": "在線用戶",
    "videoTalk.text4": "開口",
    "videoTalk.text5": "您正在試圖說服",
    "videoTalk.text6": "沒有其他在線用戶存在",
    "videoTalk.text7": "你是說，以",
    "videoTalk.text8": "你開始談論",
    "videoTalk.text9": "顯示文本對話",
    "videoTalk.text10": "文本輸入....",
    "videoTalk.text11": "你還在說話。你想停止說話？",
    "videoTalk.text12": "主叫用戶斷開連接",
    "videoTalk.text13": "用戶呼叫你。你接受這個電話？",
    "videoTalk.text14": "拒絕您的來電",
    "videoTalk.text15": "在網上搜索用戶...",
    "videoTalk.text16": "搜索用戶...",
    "__14": "",
    "groupTalk.text1": "創造者",
    "groupTalk.text2": "描述",
    "groupTalk.text3": "創建日期",
    "groupTalk.text4": "編輯組信息",
    "groupTalk.text5": "它是沒有問題的，從這個組的成員中刪除嗎？",
    "groupTalk.text6": "從會員離開",
    "groupTalk.text7": "加入組",
    "groupTalk.text8": "展集團成員",
    "groupTalk.text9": "你想加入這個集團？",
    "groupTalk.text10": "組名",
    "groupTalk.text11": "返回目錄",
    "groupTalk.text12": "確認您輸入",
    "groupTalk.text13": "創建組",
    "groupTalk.text14": "更新組",
    "groupTalk.text15": "從集團退出",
    "groupTalk.text16": "轉到主頁到登錄",
    "groupTalk.text17": "組名稱（5-100個字符）",
    "groupTalk.text18": "組描述（少於500個字符）"
  },
  {
    "LANGUAGE": "Chinese (Traditional)",
    "CODE": "zh-TW",
    "test.text1": "你好",
    "test.text2": "你好",
    "test.text3": "謝謝",
    "test.text4": "地圖",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "取消",
    "general.text2": "接受",
    "general.text3": "刪除",
    "general.text4": "已刪除",
    "general.text5": "編輯",
    "general.text6": "更新",
    "general.text7": "上傳圖片",
    "general.text8": "刪除圖片",
    "general.text9": "圖片",
    "general.text10": "飼料",
    "general.text11": "登錄",
    "general.text12": "註冊",
    "general.text13": "用戶信息",
    "general.text14": "登出",
    "general.text15": "文件應該是",
    "general.text16": "和小於",
    "general.text17": "密碼",
    "general.text18": "喜歡的帖子",
    "general.text19": "帖子",
    "general.text20": "圖片",
    "general.text21": "影片",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "最新帖子",
    "feed.text2": "顯示用戶的帖子",
    "feed.text3": "顯示帖子",
    "feed.text4": "視圖",
    "feed.text5": "編輯",
    "feed.text6": "刪除",
    "feed.text7": "是否是刪除後完全沒問題？",
    "feed.text8": "發布者",
    "feed.text9": "由...製作",
    "feed.text10": "標題",
    "feed.text11": "圖片",
    "feed.text12": "內容",
    "feed.text13": "上市",
    "feed.text14": "私人的",
    "feed.text15": "標題後",
    "feed.text16": "內容POR後",
    "feed.text17": "刪除",
    "feed.text18": "它是徹底刪除圖像沒有問題？",
    "feed.text19": "已刪除",
    "feed.text20": "媒體文件應該是JPG，JPEG，PNG或MP4文件，並小於3MB",
    "feed.text21": "公開信息",
    "feed.text22": "私立專",
    "feed.text23": "鍵入搜索....",
    "feed.text24": "搜索用戶...",
    "__2": "",
    "userInfo.text1": "用戶信息",
    "userInfo.text2": "名稱",
    "userInfo.text3": "圖片",
    "userInfo.text4": "創立日期",
    "userInfo.text5": "它是徹底刪除圖像沒有問題？",
    "userInfo.text6": "新名字 ...",
    "userInfo.text7": "圖像文件應該是JPG，JPEG，或PNG文件，並小於1MB",
    "userInfo.text8": "黑暗模式",
    "userInfo.text9": "關於用戶",
    "userInfo.text10": "關於你更多的信息，說明生物等...（少於300字）",
    "userInfo.text11": "語言",
    "userInfo.text12": "改變",
    "userInfo.text13": "通知",
    "__3": "",
    "auth.text1": "你的郵件",
    "auth.text2": "你的名字",
    "auth.text3": "密碼",
    "auth.text4": "密碼應為8到20個字符，至少一個大寫字母，一個小寫字母，一個數字和一個特殊字符（@ $！％*？＆）。",
    "auth.text5": "註冊",
    "auth.text6": "登錄",
    "auth.text7": "忘記密碼",
    "auth.text8": "註冊頁面",
    "auth.text9": "E-mail地址的密碼重置",
    "auth.text10": "發送電子郵件",
    "auth.text11": "驗證失敗。確保電子郵件地址尚未使用！",
    "auth.text12": "用戶登錄失敗！",
    "auth.text13": "無效密碼被輸入",
    "auth.text14": "輸入的電子郵件地址未找到",
    "auth.text15": "用戶創建失敗！",
    "auth.text16": "該電子郵件地址已被其他帳戶",
    "auth.text17": "顯示輸入的密碼",
    "auth.text18": "隱藏密碼",
    "auth.text19": "電子郵件密碼其餘被送往",
    "auth.text20": "照耀處電子郵件沒有被發現",
    "auth.text21": "密碼重置成功",
    "auth.text22": "密碼重置失敗，請重新進入登錄頁面和發送電子郵件的密碼重置",
    "auth.text23": "密碼重置頁面",
    "auth.text24": "新密碼",
    "auth.text25": "重設密碼",
    "auth.text26": "頁無效或密碼已被更改",
    "auth.text27": "發送電子郵件的密碼重置失敗，請檢查電子郵件地址",
    "auth.text28": "電子郵件驗證頁面",
    "auth.text29": "電子郵件驗證成功，請進入上圖所示的登錄頁面",
    "auth.text30": "電子郵件驗證失敗。此頁面已過期，或者驗證已經完成。",
    "auth.text31": "驗證郵件已發送。普萊舍查收電子郵件和驗證。 （在垃圾郵件也焯芬）",
    "auth.text32": "電子郵件驗證才能登錄。請驗證電子郵件發送到您的郵箱地址。",
    "auth.text33": "發送電郵驗證",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "飼料",
    "nav.text2": "登錄",
    "nav.text3": "註冊",
    "nav.text4": "用戶信息",
    "nav.text5": "登出",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "找不到網頁",
    "notFound.text2": "去飼料頁",
    "notFound.text3": "進入登錄頁面",
    "notFound.text4": "當你今天去做？",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "取消",
    "comment.text2": "刪除",
    "comment.text3": "發表評論",
    "comment.text4": "顯示回复",
    "comment.text5": "總",
    "comment.text6": "寫回复",
    "comment.text7": "隱藏回复",
    "comment.text8": "評論",
    "comment.text9": "是否刪除此評論完全的這一評論和答复沒有問題？",
    "comment.text10": "它是完全刪除這條評論有沒有問題？",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "加入收藏帖子",
    "follow.text2": "從最喜歡的後刪除",
    "follow.text3": "加入收藏用戶",
    "follow.text4": "從最喜歡的用戶刪除",
    "follow.text5": "顯示帖子",
    "follow.text6": "您收藏的用戶",
    "follow.text7": "帖子收藏的用戶",
    "__11": "",
    "privacyPolicy.text1": "隱私政策",
    "privacyPolicy.text2": "這一政策可能會更新或恕不另行通知修訂。這是用戶隨時了解隱私政策變化的責任。請注意那些可能會影響你的其他隱私問題：",
    "privacyPolicy.text3": "這個網站可能會受到損害。",
    "privacyPolicy.text4": "您的計算機可能會受到影響。",
    "privacyPolicy.text5": "該網站託管在火力地堡和DigitalOcean基礎設施。他們可能與你有自己的服務器上自己的跟踪系統。這些服務有自己的隱私政策，它們不屬於本隱私政策。",
    "privacyPolicy.text6": "本網站存儲電子郵件，密碼的加密形式，在託管火力地堡和DigitalOcean基礎設施用戶的其他投入。",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "使用條款",
    "termsOfUse.text2": "隱私",
    "termsOfUse.text3": "您的隱私對我們很重要。請閱讀",
    "termsOfUse.text4": "隱私政策",
    "termsOfUse.text5": "了解更多信息。",
    "termsOfUse.text6": "你自己的責任",
    "termsOfUse.text7": "你的用戶，是確保自己符合您所在司法轄區的法律和稅收承擔全部責任。你是你自己的安全負責。",
    "termsOfUse.text8": "保修免責聲明",
    "termsOfUse.text9": "本網站提供的“原樣”的基礎，沒有擔保或任何形式的條件。",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "開始連接",
    "videoTalk.text2": "停止連接",
    "videoTalk.text3": "在線用戶",
    "videoTalk.text4": "開口",
    "videoTalk.text5": "您正在試圖說服",
    "videoTalk.text6": "沒有其他在線用戶存在",
    "videoTalk.text7": "你是說，以",
    "videoTalk.text8": "你開始談論",
    "videoTalk.text9": "顯示文本對話",
    "videoTalk.text10": "文本輸入....",
    "videoTalk.text11": "你還在說話。你想停止說話？",
    "videoTalk.text12": "主叫用戶斷開連接",
    "videoTalk.text13": "用戶呼叫你。你接受這個電話？",
    "videoTalk.text14": "拒絕您的來電",
    "videoTalk.text15": "在網上搜索用戶...",
    "videoTalk.text16": "搜索用戶...",
    "__14": "",
    "groupTalk.text1": "創造者",
    "groupTalk.text2": "描述",
    "groupTalk.text3": "創建日期",
    "groupTalk.text4": "編輯組信息",
    "groupTalk.text5": "它是沒有問題的，從這個組的成員中刪除嗎？",
    "groupTalk.text6": "從會員離開",
    "groupTalk.text7": "加入組",
    "groupTalk.text8": "展集團成員",
    "groupTalk.text9": "你想加入這個集團？",
    "groupTalk.text10": "組名",
    "groupTalk.text11": "返回目錄",
    "groupTalk.text12": "確認您輸入",
    "groupTalk.text13": "創建組",
    "groupTalk.text14": "更新組",
    "groupTalk.text15": "從集團退出",
    "groupTalk.text16": "轉到主頁到登錄",
    "groupTalk.text17": "組名稱（5-100個字符）",
    "groupTalk.text18": "組描述（少於500個字符）"
  },
  {
    "LANGUAGE": "Zulu",
    "CODE": "zu",
    "test.text1": "Sawubona",
    "test.text2": "Sawubona",
    "test.text3": "Ngiyabonga",
    "test.text4": "kumephu",
    "test.text5": "#VALUE!",
    "": "#VALUE!",
    "general.text1": "Khansela",
    "general.text2": "Yamukela",
    "general.text3": "Susa",
    "general.text4": "Isuse",
    "general.text5": "Hlela",
    "general.text6": "Buyekeza",
    "general.text7": "Layisha Image",
    "general.text8": "Susa Image",
    "general.text9": "image",
    "general.text10": "Okuphakelayo",
    "general.text11": "Ngena ngemvume",
    "general.text12": "Bhalisela",
    "general.text13": "Info Umsebenzisi",
    "general.text14": "Phuma",
    "general.text15": "ifayela kufanele kube",
    "general.text16": "futhi esingaphansi",
    "general.text17": "Iphasiwedi",
    "general.text18": "Izintandokazi Okuthunyelwe",
    "general.text19": "okuthunyelwe",
    "general.text20": "Izithombe",
    "general.text21": "Imiqophi",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "__1": "",
    "feed.text1": "Iposi elisha",
    "feed.text2": "Bonisa Umsebenzisi Okuthunyelwe",
    "feed.text3": "Bonisa Okuthunyelwe",
    "feed.text4": "umbono",
    "feed.text5": "hlela",
    "feed.text6": "ukususa",
    "feed.text7": "Ingabe is akunankinga kuposi wokusula ngokuphelele?",
    "feed.text8": "Kuthunyelwe ngu-",
    "feed.text9": "Yakhiwe ngu-",
    "feed.text10": "Isihloko",
    "feed.text11": "image",
    "feed.text12": "Okuqukethwe",
    "feed.text13": "yomphakathi",
    "feed.text14": "yangasese",
    "feed.text15": "Isihloko okuthunyelwe",
    "feed.text16": "iposi por sokuqukethwe",
    "feed.text17": "ukususa",
    "feed.text18": "Ingabe akukho nkinga isithombe wokusula ngokuphelele?",
    "feed.text19": "Isuse",
    "feed.text20": "Abezindaba Ifayela kufanele kube jpg, JPEG, PNG noma ifayela mp4, futhi ngaphansi kuka 3MB",
    "feed.text21": "amaposi omphakathi",
    "feed.text22": "iposi yangasese",
    "feed.text23": "Thayipha i Ukucinga ....",
    "feed.text24": "Ukucinga Umsebenzisi ...",
    "__2": "",
    "userInfo.text1": "Imininingwane Umsebenzisi",
    "userInfo.text2": "igama",
    "userInfo.text3": "isithombe",
    "userInfo.text4": "usuku lokwakha",
    "userInfo.text5": "Ingabe akukho nkinga isithombe wokusula ngokuphelele?",
    "userInfo.text6": "Igama elisha ...",
    "userInfo.text7": "Ifayela yesithombe kufanele kube jpg, i-jpeg, noma ifayela png, futhi ngaphansi kuka-1MB",
    "userInfo.text8": "Dark Mode",
    "userInfo.text9": "Umsebenzisi mayelana",
    "userInfo.text10": "imininingwane ngawe, incazelo bio etc ... (nezinhlamvu ezingaphansi kuka-300)",
    "userInfo.text11": "Ulimi",
    "userInfo.text12": "Guqula",
    "userInfo.text13": "Isaziso",
    "__3": "",
    "auth.text1": "Imeyili yakho",
    "auth.text2": "Igama lakho",
    "auth.text3": "Iphasiwedi",
    "auth.text4": "Iphasiwedi kumele ibe nezinhlamvu ezingu-8 kuya ku-20, incwadi okungenani oyedwa usonhlamvukazi, incwadi eyodwa ezincane, inombolo eyodwa kanye ngohlamvu olulodwa ekhethekile (@ $!% *? &).",
    "auth.text5": "Bhalisela",
    "auth.text6": "Ngena ngemvume",
    "auth.text7": "Khohlwa Iphasiwedi",
    "auth.text8": "lokubhalisa Page",
    "auth.text9": "Ikheli le-imeyili ukuze ukusetha kabusha iphasiwedi",
    "auth.text10": "ukuthumela E-mail",
    "auth.text11": "Ukuqinisekisa kwehlulekile. Qinisekisa ukuthi ikheli le-imeyili alisetshenziswa okwamanje!",
    "auth.text12": "ngemvume komsebenzisi kuhlulekile!",
    "auth.text13": "Iphasiwedi engavumelekile Langena",
    "auth.text14": "Ufake ikheli le-imeyili alutholakalanga",
    "auth.text15": "ukudalwa komsebenzisi kuhlulekile!",
    "auth.text16": "Ikheli le-imeyili selivele liyasetshenziswa enye i-akhawunti",
    "auth.text17": "Bonisa iphasiwedi Langena",
    "auth.text18": "Fihla Iphasiwedi",
    "auth.text19": "imeyili i-password ukuphumula wathunyelwa",
    "auth.text20": "Enterd imeyili alutholakalanga",
    "auth.text21": "Ukusetha kabusha iphasiwedi impumelelo",
    "auth.text22": "Ukusetha kabusha iphasiwedi kuhlulekile, Sicela uye Ngemvume ikhasi bese thumela i-imeyili ukuze ukusetha kabusha iphasiwedi futhi",
    "auth.text23": "ikhasi kabusha iphasiwedi",
    "auth.text24": "Iphasiwedi Entsha",
    "auth.text25": "Setha kabusha iphasiwedi",
    "auth.text26": "Sekhasi asivumelekile noma iphasiwedi isivele washintsha",
    "auth.text27": "Ukuthumela i-imeyili ukuze ukusetha kabusha iphasiwedi kuhlulekile, Sicela isheke ikheli le-imeyili",
    "auth.text28": "ikhasi yokuqinisekisa i-imeyili",
    "auth.text29": "Imeyili yokuqinisekisa impumelelo, sicela uye ekhasini lokungena eboniswe ngenhla",
    "auth.text30": "Ukuqinisekisa i-imeyili kuhlulekile. Leli khasi liphelelwe yisikhathi, noma ukuqinisekiswa kakade kuqedile.",
    "auth.text31": "Ngemeyili ukuqinisekiswa sithunyelwe. Plese uhlole i-imeyili yakho futhi uqinisekise. (Futhi chack e mail ugaxekile)",
    "auth.text32": "Ukuqinisekiswa kwe-imeyili liyadingeka Login. Sicela uthumele i-imeyili ukuze uthole yokuqinisekisa ikheli lakho le-imeyili.",
    "auth.text33": "Thumela i-imeyili ukuqinisekisa",
    "__4": "",
    "__5": "",
    "__6": "",
    "__7": "",
    "nav.text1": "Okuphakelayo",
    "nav.text2": "Ngena ngemvume",
    "nav.text3": "Bhalisela",
    "nav.text4": "Info Umsebenzisi",
    "nav.text5": "Phuma",
    "nav.text6": "#VALUE!",
    "__8": "#VALUE!",
    "notFound.text1": "Ikhasi alitholakali",
    "notFound.text2": "iya ekhasini okuphakelayo",
    "notFound.text3": "iya ekhasini ngemvume",
    "notFound.text4": "Ngabe ufunda kusiphi namuhla?",
    "notFound.text5": "#VALUE!",
    "__9": "#VALUE!",
    "comment.text1": "Khansela",
    "comment.text2": "Susa",
    "comment.text3": "Thumela amazwana",
    "comment.text4": "Bonisa impendulo",
    "comment.text5": "Imininingwane",
    "comment.text6": "Bhala impendulo",
    "comment.text7": "Fihla impendulo",
    "comment.text8": "Amazwana",
    "comment.text9": "Ingabe ayikho inkinga ukususa le amazwana kanye nezimpendulo mazwana ngokuphelele?",
    "comment.text10": "Ingabe ayikho inkinga ukususa ngokuphelele mazwana?",
    "comment.text11": "#VALUE!",
    "__10": "",
    "follow.text1": "Engeza Izintandokazi Thumela",
    "follow.text2": "Susa kusukela okuthunyelwe ozithandayo",
    "follow.text3": "Engeza umsebenzisi ozithandayo",
    "follow.text4": "Susa kusukela umsebenzisi ozithandayo",
    "follow.text5": "umbukiso okuthunyelwe",
    "follow.text6": "Abasebenzisi akho ayizintandokazi",
    "follow.text7": "Thumela Izintandokazi Abasebenzisi",
    "__11": "",
    "privacyPolicy.text1": "Inqubomgomo yobumfihlo",
    "privacyPolicy.text2": "Le nqubomgomo ingase olusha noma ebuyekeziwe ngaphandle kwesaziso. Kungumsebenzi we umsebenzisi ukuhlala unolwazi mayelana izinguquko zomgomo wobumfihlo. Phawula ezinye izinkinga zobumfihlo ezingase zithinte wena:",
    "privacyPolicy.text3": "Le webhusayithi ngahle ibe sengozini.",
    "privacyPolicy.text4": "Ikhompyutha yakho ingase ibe sengozini.",
    "privacyPolicy.text5": "Le webhusayithi liphethwe kuma Firebase futhi DigitalOcean sizinda. Zingase futhi babe kokulandela abo izinhlelo kumaseva zabo. Labo amasevisi anezinqubomgomo zawo zobumfihlo futhi kungukuthi imbozwe ngale nqubomgomo yemfihlo.",
    "privacyPolicy.text6": "Le webhusayithi ezitolo imeyili, ifomu abethelwe we iphasiwedi, kanye nezinye izidingo zolimo abasebenzisi ngesikhathi isingathwe Firebase futhi DigitalOcean sizinda.",
    "privacyPolicy.text7": "",
    "privacyPolicy.text8": "",
    "privacyPolicy.text9": "",
    "privacyPolicy.text10": "",
    "__12": "",
    "termsOfUse.text1": "Imigomo Yokusebenzisa",
    "termsOfUse.text2": "Inqubomgomo",
    "termsOfUse.text3": "Ubumfihlo bakho bubalulekile kithi. Sicela ufunde",
    "termsOfUse.text4": "Inqubomgomo yobumfihlo",
    "termsOfUse.text5": "ukuze uthole olunye ulwazi.",
    "termsOfUse.text6": "izibopho zakho siqu",
    "termsOfUse.text7": "Wena, umsebenzisi, wedwa onesibophezelo sanoma nokuqinisekisa akho nemithetho nezintela e kwendawo yakho. Wena wedwa onesibophezelo sanoma ukuphepha kwakho siqu.",
    "termsOfUse.text8": "Disclaimer iwaranti",
    "termsOfUse.text9": "Le sayithi tiniketwa \"NJENGOBA INJALO\" NGESISEKELO, NGAPHANDLE KWEMIBHALO YEMVUME NOMA IZIMO YILUPHI UHLOBO.",
    "termsOfUse.text10": "",
    "__13": "",
    "videoTalk.text1": "Qala Connect",
    "videoTalk.text2": "Misa Connect",
    "videoTalk.text3": "Abasebenzisi Online",
    "videoTalk.text4": "Qala ukukhuluma",
    "videoTalk.text5": "Lakho ozama ukukhuluma",
    "videoTalk.text6": "Ayikho enye umsebenzisi inthanethi ukhona",
    "videoTalk.text7": "Wena okhuluma naye",
    "videoTalk.text8": "Uqale ukukhuluma",
    "videoTalk.text9": "Bonisa Umbhalo Talk",
    "videoTalk.text10": "okufakwayo kombhalo ....",
    "videoTalk.text11": "Wena usakhuluma. Ingabe ufuna ukuba ngiyeke ukukhuluma?",
    "videoTalk.text12": "Ishayela umsebenzisi inqanyuliwe",
    "videoTalk.text13": "umsebenzisi uyakubiza. Ingabe uyamukela le kholi?",
    "videoTalk.text14": "ukwenqaba ikholi yakho",
    "videoTalk.text15": "ukucinga umsebenzisi inthanethi ...",
    "videoTalk.text16": "ukucinga umsebenzisi ...",
    "__14": "",
    "groupTalk.text1": "umdali",
    "groupTalk.text2": "incazelo",
    "groupTalk.text3": "usuku lokwakha",
    "groupTalk.text4": "Hlela ulwazi iqembu",
    "groupTalk.text5": "Ingabe ayikho inkinga ukususa usuka amalungu aleli qembu?",
    "groupTalk.text6": "Sukela Ilungu",
    "groupTalk.text7": "joyina iqembu",
    "groupTalk.text8": "Bonisa Amalungu Group",
    "groupTalk.text9": "Uyafuna sokujoyina leli qembu?",
    "groupTalk.text10": "Igama leqembu",
    "groupTalk.text11": "Emuva ohlwini",
    "groupTalk.text12": "Qinisekisa ukufaka kwakho",
    "groupTalk.text13": "Yakha Iqembu",
    "groupTalk.text14": "Buyekeza isigcawu",
    "groupTalk.text15": "Phuma kusukela Group",
    "groupTalk.text16": "Iya eliyisiqalo ukuze Ngemvume",
    "groupTalk.text17": "igama leqembu (izinhlamvu 5-100)",
    "groupTalk.text18": "incazelo iqembu (nezinhlamvu ezingaphansi kuka-500)"
  }
]