// import _ from 'lodash';

// import * as firebase from "firebase/app";

// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/database";
// import "firebase/firestore";


export default function AppStorege() {
  // this.event = new Event();

  // let active = false;
  this.xxx = '';
  this.yyy = 'YYY';
  this.browserHistory = [];
  
  // this.user = '';
  // this.have_xxac = '';
  // this.state = '';
  // this.pass2fa = '';
  // this.connectSpaceDestination = {
  //   uid: '',
  //   displayName: '',
  //   imageData: '',
  //   accountId: '',
  //   email: '',
  //   info: '',
  // };
  // this.userData = '';
  // this.fbUserToken = '';
  // this.deferredPrompt = '';
  // // this.connectSpaceDestinationImageData = '';
  // // this.connectSpaceDestinationDisplayName = '';


  ///add handlers


  
}

